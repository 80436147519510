
export const BooleanToggle = ({ toggleState, trueBg = '#eee', falseBg = '#fff' }) => {
    const { toggle, setToggle } = toggleState

    const toggleLeftStyle = {
        width: '3em',
        height: '1.5em',
        borderRadius: '25px',
        padding: '.1em',
        backgroundColor: trueBg,
        border: '1px solid #333',
        margin:'auto',
        cursor:'pointer'
    }

    const toggleRightStyle = {
        width: '3em',
        height: '1.5em',
        borderRadius: '25px',
        padding: '.1em',
        backgroundColor: falseBg,
        border: '1px solid #333',
        margin:'auto',
        cursor:'pointer'

    }

    const toggleButtonLeftStyle = {
        height: '1em',
        width: '1em',
        margin: '.1em',
        backgroundColor: '#333',
        borderRadius: '50%',
        float: 'left'
    }

    const toggleButtonRightStyle = {
        flex: '1',
        height: '1em',
        width: '1em',
        margin: '.1em',
        backgroundColor: '#333',
        borderRadius: '50%',
        float: 'right'
    }

    return (
        <div style={toggle ? toggleLeftStyle : toggleRightStyle} onClick={() => setToggle(!toggle)}>
            <div style={toggle ? toggleButtonLeftStyle : toggleButtonRightStyle}></div>
        </div>
    )
}