
const generateGoogleMapsLink = (address) => {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
};

const generateGoogleMapsLinkFromCoordinates = (lat, lng) => {
    return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
};


export {
    generateGoogleMapsLink ,
    generateGoogleMapsLinkFromCoordinates
}