import { Container, Card, Row, Col, Form, Button, Spinner, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, useRef } from 'react'
import Api from "../../utils/Api";
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import AlertDisplay from "../../components/AlertDisplay";
import AddressAutocomplete from "../../components/AddressAutocomplete";


function AccessProfileUpdate() {
    const navigate = useNavigate();
    const { user, idToken } = useAuth();
    const { id } = useParams();
    const [set, didSet] = useState();
    const [roles, setRoles] = useState({});
    const [profile, setProfile] = useState({});
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const addressAutocompleteRef = useRef(null);
    const [fullAddress, setFullAddress] = useState('');
    const fullAddressState = { fullAddress, setFullAddress }

    useEffect(() => {
        setProfile({ ...profile, fullAddress: fullAddress })
    }, [fullAddress])


    async function handleUpdate() {
        try {
            await Api.patch(`/access/profile/${id}`, { ...profile }, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success', }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error updating', }])
        }
    }

    async function handleDelete() {
        try {
            await Api.delete(`/access/profile/${id}`, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success', }])
            navigate(`/access/profiles`)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error deleting', }])
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                const r = await Api.get('/access/roles/shared', idToken);
                setRoles(r)
                const p = await Api.get(`/access/profile/${id}`, idToken);
                setProfile(p)
                setFullAddress(p.fullAddress);
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error', }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    })



    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <br />
            <Container>
                <Card>
                    <Card.Header>
                        <b>Profile Update</b>
                    </Card.Header>
                    {set ?
                        <>
                            <Card.Body>
                                <Row>
                                    <Col sm={12} md={6} lg={3}>
                                        Role
                                        <Form.Select
                                            type="text"
                                            onChange={e => setProfile({ ...profile, role: JSON.parse(e.target.value) })}
                                        >
                                            <option></option>
                                            {roles && roles.map((role, i) => (
                                                <option key={i} value={JSON.stringify(role)} selected={role.name === profile?.role?.name ? true : false}>{role.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                    <Col sm={12} md={6} lg={3}>
                                        Email <br />
                                        <Form.Control
                                            type="text"
                                            value={profile?.email}
                                            readOnly={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col sm={12} md={6} lg={3}>
                                        First Name
                                        <Form.Control
                                            type="text"
                                            value={profile?.firstname}
                                            onChange={e => setProfile({ ...profile, firstname: e.target.value })}
                                        />
                                    </Col>
                                    <Col sm={12} md={6} lg={3}>
                                        Last Name
                                        <Form.Control
                                            type="text"
                                            value={profile?.lastname}
                                            onChange={e => setProfile({ ...profile, lastname: e.target.value })}
                                        />
                                    </Col>
                                    <Col sm={12} md={6} lg={3}>
                                        Phone
                                        <Form.Control
                                            type="text"
                                            value={profile?.phoneNumber}
                                            onChange={e => setProfile({ ...profile, phoneNumber: e.target.value })}
                                        />
                                    </Col>
                                    <Col sm={12} md={6} lg={3}>
                                        Birthday
                                        <Form.Control
                                            type="date"
                                            value={profile?.birthday}
                                            onChange={e => setProfile({ ...profile, birthday: e.target.value })}
                                        />
                                    </Col>
                                    <Col>
                                        Address
                                        <AddressAutocomplete ref={addressAutocompleteRef} fullAddressState={fullAddressState} />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <Button variant="primary" onClick={() => handleUpdate()}>Update</Button>
                                    </Col>
                                    <Col xs="auto">
                                        <Button variant="danger" onClick={() => handleDelete()}>Delete</Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </>
                        :
                        <Card.Body>
                            <Spinner />
                        </Card.Body>
                    }

                </Card>
            </Container>
        </Container>
    );
}

export default AccessProfileUpdate;