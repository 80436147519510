import './LogisticsLoading.css'

function LogisticsLoading({message}) {
    return (
        <div style={{minHeight:'600px', minWidth:'600px', textAlign:'center'}}>
            <div className='tetrominos'>
                <div className='tetromino box1'></div>
                <div className='tetromino box2'></div>
                <div className='tetromino box3'></div>
                <div className='tetromino box4'></div>
                <div className='box5'>{message && message}</div>
            </div>
        </div>
    )
}


export default LogisticsLoading;