import React, { useState, useEffect } from 'react';
import { Accordion, Button, Card, Col, Container, Form, Row, Spinner, Table } from 'react-bootstrap'
import AlertDisplay from '../../components/AlertDisplay'
import { useAuth } from '../../context/auth'
import Api from '../../utils/Api'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';


const initialQuery = {
    page: 1,
    pageSize: 10
}


function Requests() {
    const { idToken } = useAuth()
    const [set, didSet] = useState();
    const [requests, setRequests] = useState();

    const [alerts, setAlerts] = useState([])
    const alertState = { alerts, setAlerts }

    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const [query, setQuery] = useState(() => {
        const savedQuery = localStorage.getItem(`@mctms-requests-dashboard-query`);
        return savedQuery !== null ? JSON.parse(savedQuery) : initialQuery;
    });

    async function fetchRequests() {
        didSet(false)
        try {
            const queryStr = new URLSearchParams(query).toString();
            const data = await Api.get(`/requests?${queryStr}`, idToken)
            setTotalRecords(data.totalRecords)
            setTotalPages(data.totalPages)
            setRequests(data.records)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching logs' }]);
        } finally {
            didSet(true)
        }
    }

    useEffect(() => {
        fetchRequests()
    }, [query])

    return (
        <Container fluid>
            <AlertDisplay alertState={alertState} />
            <Row>
                <Col xs="auto">
                    Records {totalRecords} <br /> Page {query.page} / {totalPages}
                </Col>
                <Col xs="auto">
                    Newer <br />
                    <Button variant={'primary'} onClick={() => setQuery({ ...query, page: query.page - 1 >= 1 ? query.page - 1 : query.page })}><FaArrowLeft /></Button>
                </Col>
                <Col xs="auto">
                    Older <br />
                    <Button variant={'primary'} onClick={() => setQuery({ ...query, page: query.page + 1 <= totalPages ? query.page + 1 : query.page })}><FaArrowRight /></Button>
                </Col>
                <Col xs="auto">
                    Page Size
                    <Form.Control as="select" value={query.pageSize} onChange={(e) => setQuery({ ...query, pageSize: e.target.value })}>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </Form.Control>
                </Col>
            </Row>
            <br />
            {!set ?
                <Spinner />
                :
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Row</th>
                            <th>Allowed</th>
                            <th>IP</th>
                            <th>Endpoint</th>
                            <th>Method</th>
                            <th>Content Type</th>
                            <th>Content Length</th>
                            <th>Port</th>
                            <th>Destination</th>
                            <th>Timestamp</th>
                        </tr>
                    </thead>
                    <tbody>
                        {requests ? requests.map((req, i) => (
                            <tr eventKey={`${i}`}>
                                <td>#{i + 1}</td>
                                <td>{req.allowed}</td>
                                <td>{req.ip}</td>
                                <td>{req.endpoint}</td>
                                <td>{req.method}</td>
                                <td>{req.headers['content-type']}</td>
                                <td>{req.headers['content-length']}</td>
                                <td>{req.headers['x-forwarded-port']}</td>
                                <td>{req.headers['urldestiny']}</td>
                                <td>{req.timestamp}</td>
                                {/* <td>
                                    <pre>{JSON.stringify(req)}</pre>
                                </td> */}
                            </tr>
                        )) : <tr>
                            <td colSpan={'100%'}>
                                No requests...
                            </td>
                        </tr>}
                    </tbody>

                </Table>

            }
        </Container>)
}

export default Requests;