import React, { createContext, useContext, useEffect, useState } from "react";

const LOCAL_STORAGE_KEY = "@mcu-app-settings";

const SettingsContext = createContext();

export function useSettings() {
  return useContext(SettingsContext);
}

export function SettingsProvider({ children }) {
  const [settings, setSettings] = useState({
    timezone:'America/New_York',
    theme:'light',
    language:'english',
  });

  useEffect(() => {
    const storedSettings = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedSettings) {
      try {
        setSettings(JSON.parse(storedSettings));
      } catch (error) {
        console.error(`Failed to parse stored settings:`, error);
      }
    }
  }, []);

  function set(key, value) {
    const newSettings = { ...settings, [key]: value };
    setSettings(newSettings);
    try {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newSettings));
    } catch (error) {
      console.error(`Failed to store settings:`, error);
    }
  }

  function unset(key) {
    const newSettings = { ...settings };
    delete newSettings[key];
    setSettings(newSettings);
    try {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newSettings));
    } catch (error) {
      console.error(`Failed to store settings:`, error);
    }
  }

  return (
    <SettingsContext.Provider value={{ settings, set, unset }}>
      {children}
    </SettingsContext.Provider>
  );
}
