import { Card, Container } from "react-bootstrap"


function LoadTenderCustomer () {
    <Container style={{ margin: '0', padding:'0' }}>
        <Card style={{borderRadius:'0px'}}>
            <Card.Header>
                Customers
            </Card.Header>
            <Card.Body>
                
            </Card.Body>
        </Card>
    </Container>
}

export default LoadTenderCustomer