import { Container, Card, Row, Col, Form, Button, Table, Accordion } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from "react";
import Api from "../../utils/Api";
import { useAuth } from '../../context/auth';
import appRoutes from "../../context/approutes";
import { multidimensionalGroupBy } from '../../utils/Array.helper'
import AlertDisplay from "../../components/AlertDisplay";

function AccessRoleForm() {
    const { idToken } = useAuth();
    const [formdata, setFormdata] = useState({})
    const [permissions, setPermissions] = useState({});
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const routeGroups = multidimensionalGroupBy(appRoutes, ['feature']);

    async function handlePost() {
        try {
            const data = { ...formdata, permissions: permissions };
            const res = await Api.post('/access/roles', data, idToken)
            setAlerts([...alerts, { variant: 'success', message: JSON.stringify(res) }])
            setFormdata({})
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: JSON.stringify(error) }])
        }
    }

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <br />
            <Container>
                <Card>
                    <Card.Header><b>Role Form</b></Card.Header>
                    <Card.Body>
                        <Row>
                            <Col sm={12} md={6} lg={3}>
                                Role Name:
                                <Form.Control type="text" onChange={e => setFormdata({ ...formdata, name: e.target.value })} />
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                Share Role:
                                <Form.Select value={formdata.accessibility} onChange={e => setFormdata({ ...formdata, accessibility: e.target.value })}>
                                    <option></option>
                                    <option value={'Allow'}>Allow</option>
                                    <option value={'Block'}>Block</option>
                                </Form.Select>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Body>
                        <Accordion>
                            {Object.keys(routeGroups).map((feature, i) => (
                                <Accordion.Item eventKey={i} key={i}>
                                    <Accordion.Header style={{ backgroundColor: '#efefef' }}>
                                        <div style={{ display: 'inline-block' }}>
                                            {feature} - {Object.keys(Object.entries(permissions)
                                                .filter(([key, val]) =>
                                                    routeGroups[feature].some(approute => approute.path === key) && val === true
                                                )
                                                .reduce((acc, [key, val]) => {
                                                    acc[key] = val;
                                                    return acc;
                                                }, {})).length} / {routeGroups[feature].length}
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Table striped bordered hover style={{ whiteSpace: 'nowrap' }}>
                                            <thead>
                                                <tr>
                                                    <th>Access</th>
                                                    <th>Name</th>
                                                    <th>Detail</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {routeGroups[feature].map((approute, j) => (
                                                    <tr key={j}>
                                                        <td style={{ width: '50px' }}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                checked={permissions[approute.path]}
                                                                value={permissions[approute.path]}
                                                                onChange={() => setPermissions({ ...permissions, [approute.path]: (permissions[approute.path] ? false : true) })}
                                                            />
                                                        </td>
                                                        <td style={{ width: '150px' }}>{approute.name}</td>
                                                        <td>{approute.detail}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                        <Button variant="primary" onClick={() => handlePost()}>Create</Button>
                    </Card.Body>
                </Card>
            </Container>

        </Container>
    );
}

export default AccessRoleForm;