import { Container, Card, Row, Col, Form, Button, Alert } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from "react";
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';
import { FUEL_TYPES, LICENSE_TYPES, VEHICLE_CATEGORIES } from "../../context/appdata";
import AlertDisplay from "../../components/AlertDisplay";

const initialFormState = {
    assignment: '',
    domicle: '',
}

function VehicleForm() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [formdata, setFormdata] = useState(initialFormState)
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [assignments, setAssignments] = useState([]);
    const [domiciles, setDomiciles] = useState([]);

    async function handlePost() {
        try {
            await Api.post('/vehicles', formdata, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success' }])
            setFormdata(initialFormState)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error creating' }])
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Api.get('/assignments/live', idToken)
                setAssignments(data)
                const domiciles = await Api.get('/locations/live/Domicile', idToken)
                setDomiciles(domiciles)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    }, [set, idToken, alerts,])

    return (
        <Container fluid>
            <br />
            <Container>
                <Card>
                    <Card.Header><b>Vehicle Form</b></Card.Header>
                    {alerts.length > 0 &&
                        <AlertDisplay alertState={alertState} />
                    }
                    <Card.Body>
                        <Row>
                            <Col md={6} lg={3}>
                                Unit Number
                                <Form.Control type="text" value={formdata.unitnumber} onChange={e => setFormdata({ ...formdata, unitnumber: e.target.value })} />
                            </Col>
                            <Col md={6} lg={3}>
                                VIN
                                <Form.Control type="text" value={formdata.vin} onChange={e => setFormdata({ ...formdata, vin: e.target.value })} />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col sm={12} md={6} lg={3}>
                                Assignment
                                <Form.Select value={JSON.stringify(formdata.assignment)} onChange={e => setFormdata({ ...formdata, assignment: JSON.parse(e.target.value) })}>
                                    <option>Select</option>
                                    {assignments && assignments.map(assignment => (
                                        <option key={assignment._id} value={JSON.stringify(assignment)}>{assignment.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                Domicile
                                <Form.Select value={JSON.stringify(formdata.domicile)} onChange={e => setFormdata({ ...formdata, domicile: JSON.parse(e.target.value) })}>
                                    <option>Select</option>
                                    {domiciles && domiciles.map(domicile => (
                                        <option key={domicile._id} value={JSON.stringify(domicile)}>{domicile.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col sm={12} md={6} lg={3}>
                                Vehicle Type
                                <Form.Select value={formdata.category} onChange={e => setFormdata({ ...formdata, category: e.target.value })}>
                                    <option>Select</option>
                                    {VEHICLE_CATEGORIES.map((category, i) => (
                                        <option key={i}>{category.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                Fuel Type
                                <Form.Select value={formdata.fueltype} onChange={e => setFormdata({ ...formdata, fueltype: e.target.value })}>
                                    <option>Select</option>
                                    {FUEL_TYPES.map((fuel, i) => (
                                        <option key={i}>{fuel.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                License Required
                                <Form.Select value={formdata.licensereq} onChange={e => setFormdata({ ...formdata, licensereq: e.target.value })}>
                                    <option>Select</option>
                                    {LICENSE_TYPES.map((license, i) => (
                                        <option key={i}>{license.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col sm={12} md={6} lg={3}>
                                Year
                                <Form.Control type="number" value={formdata.year} onChange={e => setFormdata({ ...formdata, year: e.target.value })} />
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                Make
                                <Form.Control type="text" value={formdata.make} onChange={e => setFormdata({ ...formdata, make: e.target.value })} />
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                Model
                                <Form.Control type="text" value={formdata.model} onChange={e => setFormdata({ ...formdata, model: e.target.value })} />
                            </Col>
                        </Row>
                        <br />
                        <Button variant="primary" onClick={() => handlePost()}>Create</Button>
                    </Card.Body>
                </Card>
            </Container>
        </Container>
    );
}

export default VehicleForm;