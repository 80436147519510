import React, { useRef, useImperativeHandle, useEffect } from 'react';
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import { Form } from 'react-bootstrap';

const libraries = ["places"];

const AddressAutocomplete = React.forwardRef(({ fullAddressState }, ref) => {
  const autocompleteRef = useRef(null);

  const { fullAddress, setFullAddress } = fullAddressState;

  const handlePlaceSelect = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      const address = place.formatted_address;
      setFullAddress(address);
    }
  };

  useImperativeHandle(ref, () => ({
    getPlace: () => {
      if (autocompleteRef.current) {
        return autocompleteRef.current.getPlace();
      }
      return null;
    }
  }));
  
  useEffect(() => {
    console.log('Effect triggered');
    const autocompleteContainer = document.querySelector('.pac-container');
    if (autocompleteContainer) {
      console.log('Autocomplete container found');
      autocompleteContainer.style.zIndex = '100000000'; // Adjust the z-index value as needed
    } else {
      console.log('Autocomplete container not found');
    }
  }, [fullAddress]);

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={libraries}>
      <Autocomplete
        onLoad={autocomplete => {
          autocompleteRef.current = autocomplete;

        }}
        onPlaceChanged={handlePlaceSelect}
      >
        <Form.Control
          autoComplete="off"
          type="text"
          id="protected_field"
          name="fullAddress"
          placeholder="Enter address"
          value={fullAddress}
          onChange={e => setFullAddress(e.target.value)}
          ref={ref}
        />
      </Autocomplete>
    </LoadScript>
  );
});

export default AddressAutocomplete;
