import { useEffect, useState } from "react";
import AlertDisplay from "../../components/AlertDisplay";
import { useAuth } from "../../context/auth";
import Api from "../../utils/Api";
import { Button, Container, Form } from "react-bootstrap";
import * as XLSX from 'xlsx';
import { DateTime } from "luxon";


function InjuryUpload() {
    const {idToken} = useAuth();
    const [alerts, setAlerts] = useState([])
    const alertState = {alerts, setAlerts}
    const [selectedFile, setSelectedFile] = useState(null);
    const [incomingData, setIncomingData] = useState([]);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    // pull data from uploaded spreadsheet
    useEffect(() => {
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const arrayBuffer = e.target.result;
                const workbook = XLSX.read(arrayBuffer, { type: 'buffer' });
                const sheetNameList = workbook.SheetNames;
                const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNameList[0]]);
                setIncomingData(jsonData);
                console.log(jsonData);
            };
            reader.readAsArrayBuffer(selectedFile);
        }
    }, [selectedFile]);

    const cleanInjuries = (data) => {
        const cleanedData = data.map((row) => {
            const dateOfInjury = DateTime.fromMillis((row.DateOfInjury - 1) * 24 * 60 * 60 * 1000, { zone: 'utc' }).toISODate();
            const dateOfBirth = DateTime.fromMillis((row.BirthDate - 1) * 24 * 60 * 60 * 1000, { zone: 'utc' }).toISODate();
            const timeOfInjury = DateTime.fromMillis(row.TimeOfInjury * 24 * 60 * 60 * 1000, { zone: 'utc' }).toISOTime();
            
            return {
                firstName: row.Name_First || '',
                lastName: row.Name_Last || '',
                homeAddressLine1: row.Address_Line1 || '',
                homeAddressLine2: row.Address_Line2 || '',
                homeAddressCity: row.Address_City || '',
                homeAddressState: row.Address_State || '',
                homeAddressZip: row.Address_PostalCode || '',
                phone: row.HomePhone || '',
                rateOfPay: row.RateOfPay || '',
                dateOfBirth,
                ssn: row.SocialSecurity || '',
                supervisorFirstName: row.SupervisorsName_First || '',
                supervisorLastName: row.SupervisorsName_Last || '',
                dateOfInjury,
                timeOfInjury,
                injuryAddressLine1: '',
                injuryAddressCity: '',
                injuryAddressState: '',
                injuryAddressZip: '',
                returnedToWork: row.HasEmployeeReturnedToWork2 === 'Yes' ? 'Yes' : 'No',
                lostTimeAtWork: row.LostTimeAtWork || '',
                witness: row.ProvideNamesOfWitnessesToTheAccidentinjury2 ? 'Yes' : 'No',
                witnessName: row.ProvideNamesOfWitnessesToTheAccidentinjury2 || '',
                witnessPhone: '',
                affectedBodyParts: row.DescribeInjuryWhatPartsOfTheBodyWereEffectedWhatTypeOfInjury || '',
                incidentDescription: row.DescribeWhatTheEmployeeWasDoingAndHowTheInjuryOccured || '',
                firstAidGiven: row.DescribeAnyFirstAidGivenAtTheSceneOfTheAccideninjury || '',
                treatedInER: row.WasInjuryTreatedInAEmergencyRoom === 'Yes' ? 'Yes' : 'No',
                takenByAmbulance: row.TakenByAmbulance === 'Yes' ? 'Yes' : 'No',
                hospitalName: row.NameOfMedicalProvider || '',
                hospitalCity: row.Address2_City || '',
                hospitalState: row.Address2_State || '',
                signature: row.EmployeeOrSupervisorSignature === 'Captured' ? true : '',
                dateOfHire: '',
                injuryType: '',
                incidentDetails: '',
                bodyPartInjured: '',
                preventable: '',
                oshaRecordable: '',
                medicalTreatment: row.TreatmentReceived || '',
                settingType: '',
                rootCause: '',
                daysOffWork: row.DaysPerWeek || '',
                attorneyRepresented: '',
                comments: ''
            };
        })

        return cleanedData;
    }

    async function handleUpload() {
        try {
            const cleanData = cleanInjuries(incomingData)
            const formattedInjuries = [...cleanData];
            const res = await Api.post('/safety/injuries/upload', {injuries: [...formattedInjuries]}, idToken)
            console.log(res)
            setAlerts([...alerts, {variant:"success",message:'Successfully uploaded'}])
        } catch {
            setAlerts([...alerts, {variant:"warning",message:'An error occured during upload'}])
        }
    }

    return (
        <Container fluid>
            <AlertDisplay alertState={alertState}/>
            <Form>
                <Form.Group>
                    <Form.Label>Upload Injuries Spreadsheet:</Form.Label>
                    <Form.Control type="file" onChange={handleFileChange} />
                </Form.Group>
                <Button variant="primary" onClick={handleUpload} style={{ marginTop: '10px'}}>
                    Upload
                </Button>
            </Form>
        </Container>
    )
}


export default InjuryUpload