
import React, { useEffect } from "react"
import { Alert, Badge, Card, Col, Row } from "react-bootstrap"

const AlertDisplay = ({ alertState }) => {
    const { alerts, setAlerts } = alertState;
    const containerStyle = {
        // border: '1px solid #aaa',
        borderRadius: '0px',
        zIndex: 100000,
        position: 'fixed',  // Use 'fixed' to position relative to the viewport
        bottom: '0px',      // Push it to the bottom
        right: '0px',       // Push it to the right
        textAlign: 'right',
        margin: '1em',
        maxHeight: '500px',
        overflowY: 'auto',
    };

    useEffect(() => {
        if (alerts.length > 0) {
            const timers = alerts.map((_, index) =>
                setTimeout(() => {
                    setAlerts((prevAlerts) => prevAlerts.filter((_, i) => i !== index));
                }, 5000)
            );

            return () => {
                timers.forEach(clearTimeout);
            };
        }
    }, [alerts, setAlerts]);

    return (
        (alerts && alerts.length > 0) ?
            <Card style={containerStyle}>
                <Card.Header style={{ margin: 0, padding: 0, borderRadius:'0px', background:'#fbb70e'}}>
                    <Row style={{ margin: 0, padding: 0 }}>
                        <Col style={{ margin: 0, padding: 0 }}>
                            <Card.Title style={{ margin: '0.5em', float: 'left', fontSize:'1.5em'}}>Alerts</Card.Title>
                        </Col>
                        <Col style={{ margin: 0, padding: 0 }}>
                            <Badge bg={'danger'} style={{ margin: '1em', cursor: 'pointer', float: 'right' }} onClick={() => setAlerts([])}>X</Badge>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body style={{ margin: 0, padding: 0, background:'#fff7e6' }}>
                    {alerts.map((alrt, i) => (
                        <Alert
                            style={{ margin: '1em' }}
                            key={i}
                            variant={alrt.variant}
                            dismissible
                            onClick={() => {
                                alerts.length > 1
                                    ? setAlerts(alerts.filter(index => index !== i))
                                    : setAlerts([])
                            }}
                        >{alrt.message}
                        </Alert>
                    ))}
                </Card.Body>
            </Card>
            :
            ''
    )
}

export default AlertDisplay