import React, { useState, useEffect } from 'react';
import Api from '../../utils/Api';
import { Container, Form, Card, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { useAuth } from '../../context/auth'
import AlertDisplay from '../../components/AlertDisplay';
const Weather = () => {
    const { idToken } = useAuth();
    const [load, didLoad] = useState(false);
    const [forecast, setForecast] = useState([]);
    const [locations, setLocations] = useState([]);
    const [location, setLocation] = useState({});
    const [alerts, setAlerts] = useState([]);
    const alertState = {alerts, setAlerts}

    const fetchForecast = async (lat, lng) => {
        try {
            const weatherData = await fetch('https://api.weather.gov/points/39.8007000000000000001,-76.9830000000000001')
            setForecast(weatherData)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured getting forecast.' }])
        }
        didLoad(true)
    }

    async function changeLocation(locObj) {
        try {
            setLocation(locObj)
            const {latitude, longitude} = locObj
            await fetchForecast(latitude, longitude);
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured.' }])
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const locs = await Api.get(`/locations/live`, idToken)
                setLocations(locs)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }])
            }
            didLoad(true)
        }
        if (!load) {
            fetchData();
        }
    }, [alerts,idToken,load]);

    return (
        <Container fluid>
            <br />
            <Card>
                <Card.Header>
                    <Row className="justify-content-space-evenly align-items-center">
                        <Col sm={12} md={4} lg={6}>
                            <Card.Title>Weather</Card.Title>
                        </Col>
                        <Col sm={12} md={8} lg={6}>
                            <Form.Select defaultValue={location || ""} onChange={(e) => e.target.value !== "" && changeLocation(JSON.parse(e.target.value))}
                            >
                                <option value="">Location...</option>
                                {locations.map((loc, i) => (
                                    <option key={i} value={JSON.stringify(loc)}>{loc.name}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                </Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                <Card.Body>
                    {load
                        ?
                        <>
                            <pre>
                                {JSON.stringify(location, "", 2)}
                            </pre>
                            <pre>
                                {JSON.stringify(forecast, "", 2)}
                            </pre>
                        </>
                        :
                        <Spinner />
                    }

                </Card.Body>
            </Card>
        </Container>
    );
};

export default Weather;
