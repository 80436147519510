import { Container, Card, Row, Col, Form, Button, Alert } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import { useAuth } from '../../context/auth';

import Api from '../../utils/Api';
import AlertDisplay from "../../components/AlertDisplay";

const initialFormState = {
    name: '',
    detail: '',
    sunday: '',
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    saturday: '',
    hasSpecialOps: '',
    rules: []
}

function FrequencyForm({ feature }) {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [formdata, setFormdata] = useState(initialFormState)
    const [specialops, setSpecialOps] = useState();
    const [rules, setRules] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }


    function setRule(i, k, v) {
        rules[i][k] = v
        setFormdata({ ...formdata, rules: [...rules] })
    }

    function removeRule(index) {
        const filtered = rules.filter((_, i) => {
            return i !== index
        })
        setRules([...filtered])
        setFormdata({ ...formdata, rules: [...filtered] })
    }

    async function handlePost() {
        try {
            await Api.post('/frequencies', formdata, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success' }])
            setFormdata(initialFormState)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Create error : ' + error.error }])
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Api.get('/specialops/live', idToken)
                setSpecialOps(data)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    }, [set, idToken, alerts,])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <br />
            <Container>
                <Card>
                    <Card.Header><b>Frequency Form</b></Card.Header>
                    <Card.Body>
                        <Row>
                            <Col>
                                Name
                                <Form.Control type="text" value={formdata.name} onChange={(e) => { setFormdata({ ...formdata, name: e.target.value }) }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Detail
                                <Form.Control
                                    as="textarea"
                                    style={{ height: '100px' }}
                                    value={formdata.detail}
                                    onChange={(e) => { setFormdata({ ...formdata, detail: e.target.value }) }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Card.Header>
                            <Card.Title>Routine</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Form.Check label={'Sunday'} checked={formdata.sunday} onClick={() => { setFormdata({ ...formdata, sunday: (formdata.sunday ? false : true) }) }} />
                                </Col>
                                <Col>
                                    <Form.Check label={'Monday'} checked={formdata.monday} onClick={() => { setFormdata({ ...formdata, monday: (formdata.monday ? false : true) }) }} />
                                </Col>
                                <Col>
                                    <Form.Check label={'Tuesday'} checked={formdata.tuesday} onClick={() => { setFormdata({ ...formdata, tuesday: (formdata.tuesday ? false : true) }) }} />
                                </Col>
                                <Col>
                                    <Form.Check label={'Wednesday'} checked={formdata.wednesday} onClick={() => { setFormdata({ ...formdata, wednesday: (formdata.wednesday ? false : true) }) }} />
                                </Col>
                                <Col>
                                    <Form.Check label={'Thursday'} checked={formdata.thursday} onClick={() => { setFormdata({ ...formdata, thursday: (formdata.thursday ? false : true) }) }} />
                                </Col>
                                <Col>
                                    <Form.Check label={'Friday'} checked={formdata.friday} onClick={() => { setFormdata({ ...formdata, friday: (formdata.friday ? false : true) }) }} />
                                </Col>
                                <Col>
                                    <Form.Check label={'Saturday'} checked={formdata.saturday} onClick={() => { setFormdata({ ...formdata, saturday: (formdata.saturday ? false : true) }) }} />
                                </Col>
                            </Row>
                        </Card.Body>
                        <br />
                        <Card.Body>
                            <Form.Check label={'Special operations'} checked={formdata.hasSpecialOps} onClick={() => { setFormdata({ ...formdata, hasSpecialOps: (formdata.hasSpecialOps ? false : true) }) }} />
                        </Card.Body>
                        <br />
                        {formdata.hasSpecialOps &&
                            <>
                                <Card.Header>
                                    <Row>
                                        <Col>
                                            <Card.Title>Special Operation Rules</Card.Title>
                                        </Col>
                                        <Col>
                                            <Button variant="primary" className="float-end" onClick={() => setRules([...rules, { specialop: '', rule: '' }])}>Add Rule</Button>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col> Special Operation </Col>
                                        <Col> Rule </Col>
                                        <Col xs={6}>{' '}</Col>
                                    </Row>
                                    {rules.map((rule, i) => (
                                        <Row key={i}>
                                            <Col>
                                                <Form.Select value={JSON.stringify(rule.specialop)} onChange={e => setRule(i, 'specialop', JSON.parse(e.target.value))}>
                                                    <option>Select</option>
                                                    {specialops && specialops.map(specialop => (
                                                        <option key={specialop._id} value={JSON.stringify(specialop)}>{specialop.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                            <Col>
                                                <Form.Select value={rule.rule} onChange={e => setRule(i, 'rule', e.target.value)}>
                                                    <option>Select</option>
                                                    <option value="dnr">Does Not Run</option>
                                                    <option value="run">Run</option>
                                                </Form.Select>
                                            </Col>
                                            <Col xs={6}>
                                                <Button variant="warning" onClick={() => removeRule(i)}>
                                                    <FaTrash />
                                                </Button>
                                            </Col>
                                        </Row>
                                    ))}
                                </Card.Body>
                            </>
                        }
                        <br />
                        <Button variant="primary" onClick={() => handlePost()}>Create</Button>
                    </Card.Body>
                </Card>
            </Container>
        </Container>
    );
}

export default FrequencyForm;