import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth";
import Api from "../../utils/Api";
import { useState, useEffect } from "react";
import { Container, Card, Alert } from "react-bootstrap";
import AlertDisplay from "../../components/AlertDisplay";
import LogisticsLoading from "../../components/LogisticsLoading";


function LoadTenderMobileTrack() {

    const { ShipmentId } = useParams();
    const { idToken } = useAuth();
    const [didFetch, setDidFetch] = useState();
    const [loadtender, setLoadTender] = useState({});
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [position, setPosition] = useState({})
    const [gpsData, setGpsData] = useState([])
    const [gpsError, setGpsError] = useState(null);

    async function fetchShipment() {
        setDidFetch(false)
        try {
            const load = await Api.get(`/loadtender/${ShipmentId}`, idToken);
            setLoadTender(load)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching load.' }])
        } finally {
            setDidFetch(true);
        }
    }

    useEffect(() => {
        if (!didFetch) {
            fetchShipment();
        }
    }, [didFetch])

    useEffect(() => {
      const watchId = navigator.geolocation.watchPosition(
        (pos) => {
          const fix = {
            timestamp: pos.timestamp,
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
            heading: pos.coords.heading,
            accuracy: pos.coords.accuracy,
            altitude: pos.coords.altitude,
            altitudeAccuracy: pos.coords.altitudeAccuracy,
          };
          setPosition(fix);
          setGpsData((prevData) => [...prevData, fix]);
          setGpsError(null);
        },
        (error) => {
          setGpsError(error.message);
        }
      );
  
      return () => {
        navigator.geolocation.clearWatch(watchId);
      };
    }, []);
  
    useEffect(() => {
      if (!navigator.geolocation) {
        setGpsError('Geolocation is not supported by this browser.');
      } else if (!navigator.permissions || !navigator.permissions.query) {
        setGpsError(
          'This browser does not support the Permissions API, so we cannot check your geolocation permission.'
        );
      } else {
        navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
          if (permissionStatus.state === 'denied') {
            setGpsError('You have denied access to your location.');
          } else if (permissionStatus.state === 'prompt') {
            setGpsError('Please grant access to your location.');
          }
        });
      }
    }, []);

    useEffect(() => {
        console.log('Mount get fix');
        const watchId = navigator.geolocation.watchPosition(
          (pos) => {
            const fix = {
                timestamp : pos.timestamp,
                latitude : pos.coords.latitude,
                longitude : pos.coords.longitude,
                heading: pos.coords.heading,
                accuracy : pos.coords.accuracy,
                altitude : pos.coords.altitude,
                altitudeAccuracy : pos.coords.altitudeAccuracy,
            }
            setPosition(fix);
            setGpsData((prevData) => [...prevData, fix]);
            setGpsError(null);
          },
          (error) => {
            setGpsError(error.message);
          }
        );
    
        return () => {
          console.log('Unmount clear fix');
          navigator.geolocation.clearWatch(watchId);
        };
      }, []);


    return (
        <Container fluid style={{ padding: 0, margin: 0 }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{ border: 0, padding: 0 }}>
                <Card.Header>
                    Shipment ID : {ShipmentId}
                </Card.Header>
                {gpsError &&
                    <Alert variant="warning" style={{ margin: 0, borderRadius:0 }}>
                        {JSON.stringify(gpsError)}
                    </Alert>
                }
                {!didFetch ?
                    <LogisticsLoading message={'Loading'} />
                    :
                    <>
                        <Card.Body>
                            {JSON.stringify(position, "", 2)}
                        </Card.Body>
                        <Card.Body>
                            {JSON.stringify(gpsData.length, "", 2)}
                        </Card.Body>
                        <Card.Body>
                            {JSON.stringify(Object.keys(loadtender), "", 2)}
                        </Card.Body>
                    </>


                }
            </Card>
        </Container>
    )
}


export default LoadTenderMobileTrack;