import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import { Container, Card, Spinner, Row, Col, Button, Table, } from "react-bootstrap";
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';
import AlertDisplay from '../../components/AlertDisplay';
import TrafficSignal from '../../components/TrafficSignal';
import { formatDateStrToMMDDYYHHMMTZ } from '../../utils/DateTimeFormat.helper';
import { BooleanToggle } from '../../components/BooleanToggle';


function Job() {
    const { idToken } = useAuth();
    const [set1, didSet1] = useState();
    const [set2, didSet2] = useState();
    const [set3, didSet3] = useState();
    const [controllerStatus, setControllerStatus] = useState();
    const [agenda, setAgenda] = useState([]);
    const [agendaControl, setAgendaControl] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const [status, setStatus] = useState();
    const alertState = { alerts, setAlerts }

    const fetchAgendaJobs = async () => {
        try {
            const agenda = await Api.get('/jobs/agenda', idToken);
            const cleanAgenda = agenda.map((job, i) => {
                return {
                    _id: job?._id || '',
                    name: job?.name || '',
                    type: job?.type || '',
                    endDate: job?.endDate || '',
                    lastModifiedBy: job?.lastModifiedBy || '',
                    nextRunAt: job?.nextRunAt || '',
                    priority: job?.priority || '',
                    repeatInterval: job?.repeatInterval || '',
                    startDate: job?.startDate || '',
                    lockedAt: job?.lockedAt || '',
                    lastRunAt: job?.lastRunAt || '',
                    lastFinishedAt: job?.lastFinishedAt || ''
                }
            })
            const control = await Api.get('/jobs/agenda-control', idToken);
            const cleanControl = control.map((ctrl) => {
                return {
                    _id: ctrl?._id || '',
                    name: ctrl?.name || '',
                    on: ctrl?.on || false
                }
            })
            setAgenda(cleanAgenda)
            setAgendaControl(cleanControl)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error fetching agenda', }])
        } finally {
            didSet2(true)
        }
    }

    const fetchAgendaStatus = async () => {
        try {
            const data = await Api.get('/jobs/status', idToken);
            setStatus(data)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error fetching agenda status', }])
        } finally {
            didSet3(true)
        }
    }

    async function handleJobToggle(job) {
        try {
            const matchedControl = agendaControl.find(ctrl => ctrl.name === job.name);
            const updateService = {
                name: job.name,
                on: false
            }
            if (matchedControl) {
                updateService.on = !matchedControl.on
            }

            await Api.post(`/jobs/agenda-control`, { ...updateService }, idToken)
            didSet2(false)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error updating service status.' }])
        }
    }

    function findJobExecution(job) {
        const matchedControl = agendaControl.find(ctrl => ctrl.name === job.name);
        if (matchedControl?.on) {
            return true
        }
        return false
    }

    useEffect(() => {
        if (!set2) {
            fetchAgendaJobs()
        }
        if (!set3) {
            fetchAgendaStatus();
        }
    }, [idToken, set2, set3, alerts])

    useEffect(() => {
        fetchAgendaStatus();
        const intervalId = setInterval(fetchAgendaStatus, 15000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <Container>
            <AlertDisplay alertState={alertState} />
            <br />
            <Card>
                <Card.Header>
                    <Row>
                        <Col xs="auto">
                            <Card.Title>
                                Jobs
                            </Card.Title>
                        </Col>
                        <Col xs="auto">
                            {(!set2 || !set3) && <Spinner size="sm" />}
                        </Col>
                    </Row>
                </Card.Header>
                <Table responsive>
                    <thead>
                        <tr>
                            <th style={{textAlign:'center'}}>
                                ON | OFF
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                Planned
                            </th>
                            <th>
                                Next Execution
                            </th>
                            <th>
                                Running
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {set3 ? status?.jobs?.map((job, i) => (
                            <tr key={i} style={{ alignItems: 'center', }}>
                                <td>
                                    <BooleanToggle toggleState={{
                                        toggle: findJobExecution(job),
                                        setToggle: () => handleJobToggle(job),
                                    }}
                                        trueBg={'#bcdbbc'}
                                        falseBg={'#f8aeb4'}
                                    />
                                </td>
                                <td><b>{job?.name}</b></td>
                                <td>{job?.status}</td>
                                <td>{job?.status === 'Scheduled' && formatDateStrToMMDDYYHHMMTZ(job?.nextRunAt)}</td>
                                <td>
                                    <div>
                                        <TrafficSignal status={job?.executing ? 'true' : 'false'} />
                                    </div>
                                </td>
                            </tr>
                        )) ??
                            <tr>
                                <td colSpan={'100%'}><h4>No jobs in queue.</h4></td>
                            </tr>
                            : <Spinner />
                        }
                    </tbody>

                </Table>

            </Card>
        </Container>
    )
}

export default Job;