import React  from 'react';
import { Container, Card, Button,} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
// import Api from '../../utils/Api';
// import { useAuth } from '../../context/auth';

function GeoTab() {
    // const { idToken } = useAuth();
    // const [set, didSet] = useState();
    // const [syncro, isSyncro] = useState();
    // const [syncdata, setSyncData] = useState();
    // const [alerts, setAlerts] = useState([]);

    // async function sync() {
    //     try {
    //         isSyncro(true)
    //         await Api.get('/samsara/sync', idToken);
    //         setAlerts([...alerts, { variant: 'success', message: 'GeoTab data syncronization successful.' }])
    //         didSet(false)
    //     } catch (error) {
    //         setAlerts([...alerts, { variant: 'warning', message: 'An error occured.' }])
    //     }
    //     isSyncro(false)
    // }

    // useEffect(() => {
    //     const fetchData = async () => {
    //         const data = await Api.get('/samsara/sync/preview', idToken);
    //         setSyncData(data);
    //         didSet(true)
    //     }
    //     if (!set) {
    //         fetchData()
    //     }
    // }, [set, idToken])

    return (
        <Container fluid>
            <br/>
            <Card>
                <Card.Header>
                    <b>GeoTab</b>
                </Card.Header>
                <Card.Body>
                    <Card.Subtitle>
                        Sync : 
                            Locations, 
                            Operators, 
                            Trailers, 
                            and Vehicles
                    </Card.Subtitle>
                </Card.Body>
                {/* {syncro && <Card.Body><Spinner /></Card.Body>}
                {alerts.length > 0 &&
                    <Card.Body>
                        {alerts.map((alrt, i) => (
                            <Alert variant={alrt.variant} dismissible onClick={() => setAlerts([])}>{alrt.message}</Alert>
                        ))}
                    </Card.Body>
                }
                <Card.Body className="d-flex justify-content-space-evenly align-items-center flex-wrap">
                    {set ?
                        <pre>
                            {JSON.stringify(syncdata, "", 2)}
                        </pre>
                        :
                        <Spinner />
                    }
                </Card.Body> */}
            </Card>
        </Container >
    );
}

export default GeoTab;