import { Badge, Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import DataDisplayV3 from "../../components/DataDisplayV3";
import { FaPlusCircle } from "react-icons/fa";
import LogisticsLoading from "../../components/LogisticsLoading";
import { useAuth } from "../../context/auth";
import { useEffect, useState } from "react";
import Api from "../../utils/Api";
import AlertDisplay from "../../components/AlertDisplay";


function Customers({ feature }) {
    const { idToken } = useAuth();
    const [didFetch, setDidFetch] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts };
    const [customers, setCustomers] = useState([]);
    const [query, setQuery] = useState({
        isActive: true
    });

    async function fetchCustomers() {
        try {
            const queryStr = new URLSearchParams(query).toString();
            const data = await Api.get(`/customers?${queryStr}`, idToken);
            const cleanData = data.map(customer => ({
                _id: customer._id,
                Name: customer.name,
                'USPS Contrcat': customer.uspsContract ? 'Yes' : 'No',
                Address: customer.address,
                Contacts: customer.contacts.map(contact => `Name: ${contact.name}\tPhone: ${contact.phone}\tEmail: ${contact.email}`).join('\n')
            }));
            
            setCustomers(cleanData);
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching data.' }])
        } finally {
            setDidFetch(true)
        }
    }

    useEffect(() => {
        if (!didFetch) {
            fetchCustomers();
        }
    }, [didFetch])


    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{ border: 0, borderRadius: 0 }}>
                <Card.Header>
                    <Card.Title>
                        <Row>
                            <Col>
                                Customers
                            </Col>
                            <Col style={{ textAlign: 'center' }}>
                                <Badge
                                    bg={query.isActive ? 'success' : 'danger'}
                                    onClick={() => {
                                        setQuery({ ...query, isActive: !query.isActive });
                                        setDidFetch(false)
                                    }}
                                >
                                    {query.isActive ? 'Active' : 'Inactive'}
                                </Badge>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                <Link to={`/${feature}/customer`}><FaPlusCircle /> Customers</Link>
                            </Col>
                        </Row>
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                {didFetch ?
                    <DataDisplayV3
                        dataSource={customers} lsKey='@sybrtms-freight-customers'
                        urlPath={`/${feature}/customer/`}
                        urlKey={'_id'}
                        popKeys={['_id']}
                        sourceName={'Feight Customers'}
                        addActions={[]}
                        addFunctions={[]}
                    />
                    :
                    <LogisticsLoading message="Loading"/>
                }
                </Card.Body>
            </Card>
        </Container>
    )
}



export default Customers;