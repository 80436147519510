import { Card, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { FaPlusCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import DataDisplayV3 from "../../components/DataDisplayV3";
import { useEffect, useState } from "react";
import Api from "../../utils/Api";
import AlertDisplay from "../../components/AlertDisplay";
import { useAuth } from "../../context/auth";

function Timecards({ feature, userType }) {
    const { idToken } = useAuth();
    const [timecards, setTimecards] = useState([]);
    const [didFetch, setDidFetch] = useState();
    const [query, setQuery] = useState({});
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    async function fetchTimecards() {
        setDidFetch(false)
        try {
            const queryStr = new URLSearchParams(query).toString();
            const data = await Api.get(`/payroll/timecards?${queryStr}`, idToken);
            setTimecards(data)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching timecards', }])
        } finally {
            setDidFetch(true)
        }
    }

    useEffect(() => {
        fetchTimecards();
    }, [query])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{ border: 0, borderRadius: 0 }}>
                <Card.Header>
                    <Card.Title>
                        <Row>
                            <Col>
                                Timecards
                            </Col>
                            <Col xs="auto">
                                <Link to={'/payroll/timecard'}><FaPlusCircle />Timecard</Link>
                            </Col>
                        </Row>
                    </Card.Title>
                </Card.Header>
                <Card.Header>
                    <Row>
                        <Col xs="auto">
                            <Card.Subtitle>
                                Period Start
                            </Card.Subtitle>
                            <Form.Control
                                type="date"
                                onChange={(e) => setQuery({ ...query, start: e.target.value })}
                            />
                        </Col>
                        <Col xs="auto">
                            <Card.Subtitle>
                                Period End
                            </Card.Subtitle>
                            <Form.Control
                                type="date"
                                onChange={(e) => setQuery({ ...query, end: e.target.value })}
                            />
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    {didFetch ?
                        <DataDisplayV3
                            dataSource={timecards}
                            lsKey='@mctms-timecards-display'
                            urlPath='/payroll/timecard/'
                            urlKey='_id'
                            popKeys={['_id']}
                            sourceName='TMS-Timecards'
                            addActions={[]}
                            addFunctions={[]}
                        />
                        :
                        <Spinner />
                    }

                </Card.Body>
            </Card>
        </Container>
    )
}

export default Timecards;