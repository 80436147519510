import React, { useState, useEffect } from 'react';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { Container, Card, Spinner, Alert } from 'react-bootstrap';
import sybrLogo from '../assets/sybr-logo-lg.png'

const VerifyEmail = () => {
  const [load, didLoad] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    const handleEmailVerification = async () => {
      try {
        if (isSignInWithEmailLink(auth, window.location.href)) {
          let email = window.localStorage.getItem(process.env.REACT_APP_FIREBASE_VERIFY_EMAIL_LS_KEY);
          if (!email) {
            email = window.prompt('Please provide your email for confirmation');
          }
          await signInWithEmailLink(auth, email, window.location.href);
          setSuccess(true);
        } else {
          // Invalid sign-in with email link
          // console.error('Invalid email verification link');
          setError('Invalid email verification link')
        }
      } catch (error) {
        // Handle any errors during verification
        // console.error('Error verifying email:', error);
        setError('Error verifying email.')
      }
    };

    handleEmailVerification();

    if (success) {
      window.location.href = '/'
    }

    return didLoad(true);
  }, [auth, success]);

  return (
    <Container className="d-flex align-items-center justify-content-center vh-100 m-0 p-0" fluid style={{ backgroundColor: '#3366cc' }}>
      <Card style={{ textAlign: 'center', width:'350px'}}>
        <Card.Img variant="top" src={sybrLogo} style={{width:'100%'}}/>
        <Card.Body>
          <Card.Title>Email Verification</Card.Title>
          {!load &&
            <p>
              <Spinner/> Verifying your email, please wait.
            </p>
          }
          {success && (
            <p>
              If you do not redirect automatically.<br/>
              <a href="/">Click here to continue to Mail Carrier TMS</a>
            </p>
          )}
          {error &&
          <>
            <Alert variant='danger'>{error}</Alert>
            <a href="/">Return to Login</a>
          </>
          }
        </Card.Body>
      </Card>
    </Container>
  );
};

export default VerifyEmail;