import React, { useState } from "react";
import { Container, Card, Form, Button, Row, Col, Alert } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';
import AlertDisplay from "../../components/AlertDisplay";

const initialFormState = {
    name: '',
    type: '',
    month: '',
    day: '',
    week: '',
    dow: '',
}

function SpecialOpForm() {
    const { idToken } = useAuth();
    const [formdata, setFormdata] = useState(initialFormState)
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    async function handlePost() {
        try {
            await Api.post('/specialops', formdata, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success' }])
            setFormdata(initialFormState)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error creating' }])
        }
    }

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <br />
            <Container>
                <Card >
                    <Card.Header><b>Special Operation Form</b></Card.Header>
                    <Card.Body>
                        <Row>
                            <Col>
                                Name
                                <Form.Control value={formdata.name} onChange={(e) => setFormdata({ ...formdata, name: e.target.value })} />
                            </Col>
                            <Col>
                                Type
                                <Form.Select value={formdata.type} onChange={e => setFormdata({ ...formdata, type: e.target.value })}>
                                    <option>Select</option>
                                    <option value={"md"}>Month/Day</option>
                                    <option value={"mwl"}>Month/Week/Day of Week</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        {formdata.type === "md" &&
                            <Row>
                                <Col>
                                    Month
                                    <Form.Select value={formdata.month} onChange={e => setFormdata({ ...formdata, month: e.target.value })}>
                                        <option>Select</option>
                                        <option>January</option>
                                        <option>February</option>
                                        <option>February</option>
                                        <option>March</option>
                                        <option>April</option>
                                        <option>May</option>
                                        <option>June</option>
                                        <option>July</option>
                                        <option>August</option>
                                        <option>September</option>
                                        <option>October</option>
                                        <option>November</option>
                                        <option>December</option>
                                    </Form.Select>
                                </Col>
                                <Col>
                                    Day
                                    <Form.Control type="number" value={formdata.day} min={1} max={31} onChange={(e) => setFormdata({ ...formdata, day: e.target.value })} />
                                </Col>
                            </Row>
                        }
                        {formdata.type === 'mwl' &&
                            <Row>
                                <Col>
                                    Month
                                    <Form.Select value={formdata.month} onChange={e => setFormdata({ ...formdata, month: e.target.value })}>
                                        <option>Select</option>
                                        <option>January</option>
                                        <option>February</option>
                                        <option>February</option>
                                        <option>March</option>
                                        <option>April</option>
                                        <option>May</option>
                                        <option>June</option>
                                        <option>July</option>
                                        <option>August</option>
                                        <option>September</option>
                                        <option>October</option>
                                        <option>November</option>
                                        <option>December</option>
                                    </Form.Select>
                                </Col>
                                <Col>
                                    Week Number
                                    <Form.Select value={formdata.week} onChange={e => setFormdata({ ...formdata, week: e.target.value })}>
                                        <option>Select</option>
                                        <option>First</option>
                                        <option>Second</option>
                                        <option>Third</option>
                                        <option>Fourth</option>
                                        <option>Last</option>

                                    </Form.Select>
                                </Col>
                                <Col>
                                    Day of Week
                                    <Form.Select value={formdata.dow} onChange={e => setFormdata({ ...formdata, dow: e.target.value })}>
                                        <option>Select</option>
                                        <option>Monday</option>
                                        <option>Tuesday</option>
                                        <option>Wednesday</option>
                                        <option>Thursday</option>
                                        <option>Friday</option>
                                        <option>Saturday</option>
                                        <option>Sunday</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                        }
                        <br />
                        <Button variant="primary" onClick={() => handlePost()}>Create</Button>
                    </Card.Body>
                </Card>
            </Container>
        </Container>
    );
}

export default SpecialOpForm;