
const ACTIVE_ARCHIVE_OPTIONS = ['True', 'False'];

const CONTACT_SERVICE_TYPES = [
    { name: 'Normal' },
    { name: 'Extra' },
    { name: 'Seasonal' },
    { name: 'Peak' },
    { name: 'Other' },
]

const CONTACT_PAYRATES_TYPES = [
    { name: 'Wage Detemination', detail:'',},
    { name: 'Health and Welfare', detail:'', },
    { name: 'Incentive', detail:'', },
    { name: 'Peak', detail:'',  },
]

const DISPATCH_STATUSES = [
    { code: -2, name: 'Omitted', variant: 'danger', },
    { code: -1, name: 'Canceled', variant: 'danger', },
    { code: 0, name: 'Created', variant: 'info',},
    { code: 1, name: 'Pre-Check', variant: 'secondary', },
    { code: 2, name: 'Checked In', variant: 'primary', },
    { code: 3, name: 'In Route', variant: 'primary', },
    { code: 4, name: 'Delayed', variant: 'warning', },
    { code: 5, name: 'Checked Out', variant: 'success', },
    { code: 6, name: 'Completed', variant: 'success', },
    // { code: 8, name: 'Proof of Delivery', variant: 'success', },
    // { code: 9, name: 'Reconciled', variant: 'dark', },
]

const ROUTE_ACTION = [
    { name: 'Load', detail:'Loading a trailer with freight',},
    { name: 'Unload', detail:'Unloading a trailer\'s freight',},
    { name: '', detail:'',},
    { name: 'Break', detail:'Off Duty Break',},
    { name: 'PTI', detail:'Pre/Post trip inspection',},
    { name: '', detail:'',},
    { name: 'Drop', detail:'Decoupling from a trailer',},
    { name: 'Hook', detail:'Coupling with a trailer',},
    { name: '', detail:'',},
    { name: 'Layover', detail:'Location for hours reset',},
    { name: 'Slide', detail:'Meet with another driver',},
    
]

const ROUTEPLAN_COVERAGE_DEFAULTS = [
    { name: 'OPEN', detail:'No one is assigned and this trip needs to be covered.'},
    { name: 'NO TRIP', detail:'The trip does not run on this day.'},
]

const ROUTPLAN_CREW_TYPE = ['Solo', 'Team'];
const ROUTPLAN_PERIOD_TYPE = ['Weekly', 'Biweekly']

const FUEL_TYPES = [
    { name: 'Gasoline' },
    { name: 'Diseal' },
    { name: 'Electric' },
    { name: 'Hydrogen' },
    { name: 'Propane' },
    { name: 'Natural Gas' },
    { name: 'Flex-fuel' },
]

const LICENSE_TYPES = [
    { name: 'Class A' },
    { name: 'Class B' },
    { name: 'Class C' },
]

const LICENSE_ENDORCEMENTS_TYPES = [
    { name: 'Oversized Load' },
    { name: 'HAZMAT' },
]

const LOCATION_CATEGORIES = [
    { name: 'Service Point' , detail: 'USPS or other delivery/pickup location. '},
    { name: 'Domicile', detail: 'Home / Yard / Terminal' },
    // { name: 'Check Point', detail: 'This is used to measure a trips progress.' },
    { name: 'Layover', detail: 'A location used for standby or HOS reset ' },
    { name: 'Relay', detail: 'A location two drivers will meet and change equipment.' },
]

const OPERATOR_WORKSTATUS_CATEGORIES = [
    { name: 'Able to Work' },
    { name: 'On Vacation' },
    { name: 'FMLA' },
    { name: 'Work Comp' },
]

const PTO_STATUSES = [
    "approved",
    "denied",
    "pending",
    "cancelled"
]

const TIMEZONE_OPTIONS = [
    { code: 'ET', name: 'America/New_York' },
    { code: 'CT', name: 'America/Chicago' },
    { code: 'MT', name: 'America/Denver' },
    { code: 'PT', name: 'America/Los_Angeles' },
]

const TIMEZONES = [
    "America/New_York",
    "America/Chicago",
    "America/Denver",
    "America/Los_Angeles"
]

const VEHICLE_CATEGORIES = [
    { name: 'Tractor - Day Cab', licenseRequirement:'Class A', hitchType: 'Fifth Wheel'},
    { name: 'Tractor - Sleeper', licenseRequirement:'Class A', hitchType: 'Fifth Wheel'},
    { name: 'Box Truck - Lift Gate', licenseRequirement:'Class B', },
    { name: 'Box Truck - No Lift Gate', licenseRequirement:'Class B', },
    { name: 'Sprinter Van', licenseRequirement:'Class C', },
    { name: 'Pickup Truck', licenseRequirement:'Class C', },
]

const TRAILER_CATEGORIES = [
    { name: 'Dry Van', hitchType: 'Fifth Wheel'},
    { name: 'Flatbed', hitchType: 'Fifth Wheel' },
    { name: 'Refrigerated', hitchType: 'Fifth Wheel' },
    { name: 'Tanker', hitchType: 'Fifth Wheel' },
    { name: 'Livestock', hitchType: 'Fifth Wheel' },
    { name: 'Specialty', hitchType: 'Fifth Wheel' },
]

const TRAILER_DOOR_CATEGORIES = [
    { name: 'No Door' },
    { name: 'Roll' },
    { name: 'Swing' },
]

const UNITED_STATES_CODES = [
    { value: "AL", label: "AL - Alabama", name: 'Alabama' },
    { value: "AK", label: "AK - Alaska", name: 'Alaska' },
    { value: "AZ", label: "AZ - Arizona", name: 'Arizona' },
    { value: "AR", label: "AR - Arkansas", name: 'Arkansas' },
    { value: "CA", label: "CA - California", name: 'California' },
    { value: "CO", label: "CO - Colorado", name: 'Colorado' },
    { value: "CT", label: "CT - Connecticut", name: 'Connecticut' },
    { value: "DE", label: "DE - Delaware", name: 'Delaware' },
    { value: "FL", label: "FL - Florida", name: 'Florida' },
    { value: "GA", label: "GA - Georgia", name: 'Georgia' },
    { value: "HI", label: "HI - Hawaii", name: 'Hawaii' },
    { value: "ID", label: "ID - Idaho", name: 'Idaho' },
    { value: "IL", label: "IL - Illinois", name: 'Illinois' },
    { value: "IN", label: "IN - Indiana", name: 'Indiana' },
    { value: "IA", label: "IA - Iowa", name: 'Iowa' },
    { value: "KS", label: "KS - Kansas", name: 'Kansas' },
    { value: "KY", label: "KY - Kentucky", name: 'Kentucky' },
    { value: "LA", label: "LA - Louisiana", name: 'Louisiana' },
    { value: "ME", label: "ME - Maine", name: 'Maine' },
    { value: "MD", label: "MD - Maryland", name: 'Maryland' },
    { value: "MA", label: "MA - Massachusetts", name: 'Massachusetts' },
    { value: "MI", label: "MI - Michigan", name: 'Michigan' },
    { value: "MN", label: "MN - Minnesota", name: 'Minnesota' },
    { value: "MS", label: "MS - Mississippi", name: 'Mississippi' },
    { value: "MO", label: "MO - Missouri", name: 'Missouri' },
    { value: "MT", label: "MT - Montana", name: 'Montana' },
    { value: "NE", label: "NE - Nebraska", name: 'Nebraska' },
    { value: "NV", label: "NV - Nevada", name: 'Nevada' },
    { value: "NH", label: "NH - New", name: 'New Hampshire' },
    { value: "NJ", label: "NJ - New", name: 'New Jersey' },
    { value: "NM", label: "NM - New", name: 'New Mexico' },
    { value: "NY", label: "NY - New", name: 'New York' },
    { value: "NC", label: "NC - North", name: 'North Carolina' },
    { value: "ND", label: "ND - North", name: 'North Dakota' },
    { value: "OH", label: "OH - Ohio", name: 'Ohio' },
    { value: "OK", label: "OK - Oklahoma", name: 'Oklahoma' },
    { value: "OR", label: "OR - Oregon", name: 'Oregon' },
    { value: "PA", label: "PA - Pennsylvania", name: 'Pennsylvania' },
    { value: "RI", label: "RI - Rhode , name:'Rhode'Island" },
    { value: "SC", label: "SC - South , name:'South'Carolina" },
    { value: "SD", label: "SD - South , name:'South'Dakota" },
    { value: "TN", label: "TN - Tennessee", name: 'Tennessee' },
    { value: "TX", label: "TX - Texas", name: 'Texas' },
    { value: "UT", label: "UT - Utah", name: 'Utah' },
    { value: "VT", label: "VT - Vermont", name: 'Vermont' },
    { value: "VA", label: "VA - Virginia", name: 'Virginia' },
    { value: "WA", label: "WA - Washington", name: 'Washington' },
    { value: "WV", label: "WV - West , name:'West'Virginia" },
    { value: "WI", label: "WI - Wisconsin", name: 'Wisconsin' },
    { value: "WY", label: "WY - Wyoming", name: 'Wyoming' }
];



const ROUTEPLAN_WK1_KEYS = [
    'wk1sun','wk1mon','wk1tue','wk1wed','wk1thu','wk1fri','wk1sat',
]

const ROUTEPLAN_WK2_KEYS = [
    'wk2sun','wk2mon','wk2tue','wk2wed','wk2thu','wk2fri','wk2sat',
]

const DATABASE_RECORD_STATUS = [
    "active",
    "archived",
]

export {
    ACTIVE_ARCHIVE_OPTIONS,
    CONTACT_SERVICE_TYPES,
    CONTACT_PAYRATES_TYPES,
    DATABASE_RECORD_STATUS,
    DISPATCH_STATUSES,
    ROUTE_ACTION,
    FUEL_TYPES,
    LICENSE_TYPES,
    LICENSE_ENDORCEMENTS_TYPES,
    LOCATION_CATEGORIES,
    OPERATOR_WORKSTATUS_CATEGORIES,
    PTO_STATUSES,
    ROUTEPLAN_COVERAGE_DEFAULTS,
    ROUTPLAN_CREW_TYPE,
    ROUTEPLAN_WK1_KEYS,
    ROUTEPLAN_WK2_KEYS,
    ROUTPLAN_PERIOD_TYPE,
    TIMEZONES,
    TIMEZONE_OPTIONS,
    TRAILER_CATEGORIES,
    TRAILER_DOOR_CATEGORIES,
    VEHICLE_CATEGORIES,
    UNITED_STATES_CODES
}