function compareValues(key, order = "asc") {
  return function (a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = (typeof a[key] === "string")
      ? a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === "string")
      ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return (order === "asc") ? (comparison * -1) : comparison;
  };
}


function multidimensionalGroupBy(arr, groupby) {
  const grouped = {};
  arr.sort(function (a, b) {
    return a[groupby[0]] - b[groupby[0]];
  });
  arr.forEach(function (a) {
    groupby.reduce(function (o, g, i) {
      o[a[g]] = o[a[g]] || (i + 1 === groupby.length ? [] : {});
      return o[a[g]];
    }, grouped).push(a);
  });
  return grouped;
}


export {
  compareValues,
  multidimensionalGroupBy
}