import { useEffect, useState } from "react";
import AlertDisplay from "../../components/AlertDisplay";
import { useAuth } from "../../context/auth";
import Api from "../../utils/Api";
import { Button, Container, Form } from "react-bootstrap";
import * as XLSX from 'xlsx';
import { DateTime } from "luxon";

function AccidentUpload() {
    const {idToken} = useAuth();
    const [alerts, setAlerts] = useState([])
    const alertState = {alerts, setAlerts}
    const [selectedFile, setSelectedFile] = useState(null);
    const [incomingData, setIncomingData] = useState([]);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    // pull data from uploaded spreadsheet
    useEffect(() => {
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const arrayBuffer = e.target.result;
                const workbook = XLSX.read(arrayBuffer, { type: 'buffer' });
                const sheetNameList = workbook.SheetNames;
                const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNameList[0]]);
                setIncomingData(jsonData);
                console.log(jsonData);
            };
            reader.readAsArrayBuffer(selectedFile);
        }
    }, [selectedFile]);

    const cleanAccidents = (data) => {
        const cleanedData = data.map((row) => {
            const date = DateTime.fromMillis((row.Date - 1) * 24 * 60 * 60 * 1000, { zone: 'utc' }).toISODate();
            const time = DateTime.fromMillis(row.Time * 24 * 60 * 60 * 1000, { zone: 'utc' }).toISOTime();
            const otherDriverInvolved = (
                row.OtherDriver_First ||
                row.OtherDriver_Last ||
                row.OtherDriversTelephoneNumber ||
                row.InsuranceInfo
            ) ? 'Yes' : 'No';

            return {
                date,
                time,
                reportedTo: row.AccidentReportedTo || '',
                accidentAddressLine1: row.AccidentLocation2_Line1 || '',
                accidentCity: row.AccidentLocation2_City || '',
                accidentState: row.AccidentLocation2_State || '',
                accidentZipCode: row.AccidentLocation2_PostalCode || '',
                roadConditions: row.RoadConditions || '',
                description: row.DescribeWhatHappened || '',
                damageDescription: row.IfYESPleaseDescribeDamage || '',
                companyDriverFirstName: row.MMSDriver_First || '',
                companyDriverLastName: row.MMSDriver_Last || '',
                companyDriverPhone: row.MMSDriverTelephoneNumber || '',
                unitNumber: row.UnitNumber || '',
                vin: row.Vin || '',
                vehicleYear: row.Year3 || '',
                vehicleMake: row.Make3 || '',
                damageToCompanyUnit: row.IsThereDamageToMMSUnit || '',
                otherDriverInvolved,
                otherDriverFirstName: row.OtherDriver_First || '',
                otherDriverLastName: row.OtherDriver_Last || '',
                otherDriverPhone: row.OtherDriversTelephoneNumber || '',
                otherDriverLicense: row.DriversLicenseNumberOtherDriver || '',
                otherVehicleYear: row.Year2 || '',
                otherVehicleMake: row.Make || '',
                otherVehicleVin: row.Vin2 || '',
                injuries: row.Injuries || '',
                injuriesDescription: row.IfYesPleaseDescribe || '',
                witness: row.PleaseChoose || '',
                witnessFirstName: row.Name2_First || '',
                witnessLastName: row.Name2_Last || '',
                witnessPhone: row.Phone3 || '',
                citations: row.CitationsIssued || '',
                tow: row.TowingCompanyCalled || '',
                towCompanyName: '',
                towCompanyPhone: '',
                signature: row.Signature === 'Captured' ? true : '',
                accidentType: row.PleaseChoose || '',
                preventable: '', 
                dotRecordable: '',
                roadType: '',
                rootCause: '', 
                cameraFootage: '',
                companyCost: '', 
                costType: '', 
                attorney: '', 
                driverPoints: '', 
                claimNumber: '', 
                claimStatus: '' 
            };
        });

        return cleanedData;
    }
    
    async function handleUpload() {
        try {
            const cleanData = cleanAccidents(incomingData)
            await Api.post('/safety/accidents/upload', {accidents:[...cleanData]}, idToken)
            setAlerts([...alerts, {variant:"success",message:'Successfully uploaded'}])
        } catch (error) {
            setAlerts([...alerts, {variant:"warning", message:'An error occurred during upload'}])
        }
    }

    return (
        <Container fluid>
            <AlertDisplay alertState={alertState}/>
            <Form>
                <Form.Group>
                    <Form.Label>Upload Spreadsheet:</Form.Label>
                    <Form.Control type="file" onChange={handleFileChange} />
                </Form.Group>
                <Button variant="primary" onClick={handleUpload}>
                    Upload
                </Button>
            </Form>
        </Container>
    )
}


export default AccidentUpload