import { Card, Container } from "react-bootstrap";



function LoadTenderGenerator(){
    return (
        <Container fluid style={{margin:0,padding:0}}>
            <Card style={{border:0, borderRadius:0}}>
                <Card.Header>
                    <Card.Title>
                        Load Generator
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    
                </Card.Body>
            </Card>
        </Container>
    )
}


export default LoadTenderGenerator;
