import { Container, Card, Spinner, Col, Row, Badge, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import React, { useState, useEffect } from 'react'
import { FaPlusCircle, } from 'react-icons/fa'
import { useAuth } from '../../context/auth';
import DataDisplay from "../../components/DataDisplay";
import { Link } from "react-router-dom";
import AlertDisplay from "../../components/AlertDisplay";
import DataDisplayV3 from "../../components/DataDisplayV3";


function Trailer({feature}) {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [trailers, setTrailers] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [query, setQuery] = useState({
        isActive: true
    });
    useEffect(() => {
        const fetchData = async () => {
            try {
                const queryParams = new URLSearchParams(query);
                const data = await Api.query('/trailers', queryParams, idToken);

                const cleanTrailers = data.map(trailer => {
                    return {
                        _id: trailer._id,
                        'Unit': trailer?.unitnumber,
                        'Year': trailer?.year,
                        'Make': trailer?.make,
                        'Model': trailer?.model,
                        'Length': trailer?.length,
                        'Assignment': trailer?.assignment?.name,
                        'Domicile': trailer?.domicile?.name,
                        'Provider': trailer?.provider,
                        'Active': trailer?.isActive ? '✅' : '❌',
                    }
                })

                setTrailers(cleanTrailers)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }])
            }
            didSet(true)
        }

        fetchData()

    }, [set, idToken, alerts,query])

    return (
        <Container fluid style={{margin:0,padding:0}}>
            <Card style={{ borderRadius: 0, border:0 }}>
                <Card.Header>
                    <Card.Title>
                        <Row>
                            <Col>Trailer</Col>
                            <Col style={{ textAlign: 'center' }}>
                                <Badge
                                    bg={query.isActive ? 'success' : 'danger'}
                                    onClick={() => { setQuery({ ...query, isActive: !query.isActive }); didSet(false) }}
                                >
                                    {query.isActive ? 'Active' : 'Inactive'}
                                </Badge>
                            </Col>
                            <Col>
                            <Link className="float-end" to={`/${feature}/trailer-form`}><FaPlusCircle /> Trailer</Link>
                            </Col>
                        </Row>
                    </Card.Title>
                </Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                {set ?
                    <Card.Body>
                        {trailers.length > 0 ?
                            <>
                                <DataDisplayV3
                                    dataSource={trailers}
                                    lsKey={'@mctms-trailers-display'}
                                    urlPath={`/${feature}/trailer/`}
                                    urlKey={'_id'}
                                    popKeys={['_id']}
                                />
                            </>
                            :
                            <>
                                No data.
                            </>
                        }
                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }
            </Card>
        </Container>
    );
}

export default Trailer;