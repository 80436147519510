import React, { useState, useEffect } from 'react';
import DataVisual from './components/DataVisual';
import { DateTime } from 'luxon';
import { Button, ButtonGroup, Card, Container, Dropdown } from 'react-bootstrap';
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';

const InjuryDataSection = () => {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [alerts, setAlerts] = useState([]);
    const [data, setData] = useState([]);
    const [selectedXField, setSelectedXField] = useState('');
    const [selectedLabel, setSelectedLabel] = useState('');
    const [processedAndSortedData, setProcessedAndSortedData] = useState([]);
    const [isMobileView, setIsMobileView] = useState(false);

    const options = [
        { key: 'injuryAddressCity', label: 'City' },
        { key: 'injuryAddressState', label: 'State' },
        { key: 'day', label: 'Day' },
        { key: 'age', label: 'Age' },
        { key: 'timeOfInjury', label: 'Time of Day' },
        { key: 'preventable', label: 'Preventable' },
        // { key: 'rootCause', label: 'Root Cause' },
        { key: 'injuryType', label: 'Injury Type' }
    ];

    async function fetchData() {
        try {
          const injuries = await Api.get(`/safety/injuries`, idToken);
          setData(injuries);
        } catch {
          setAlerts([...alerts, { type: 'warning', message: 'Failed to load injury data' }]);
        }
    }

    useEffect(() => {
      if (!set) {
        fetchData();
      }
    }, [set]);

    const calculateAgeBracket = (birthDate) => {
        const age = Math.floor(DateTime.now().diff(birthDate, 'years').years);
        if (age < 25) return 'Under 25';
        const ageBracket = `${Math.floor(age / 5) * 5}-${Math.floor(age / 5) * 5 + 4}`;
        return ageBracket;
    };
      
    const calculateTimeOfDay = (time) => {
        const hour = time.hour;
        const startHour = Math.floor(hour / 4) * 4;
        const startTime = String(startHour).padStart(2, '0') + '00'; // Format start hour as 'HH00'
        const endTime = String(startHour + 3).padStart(2, '0') + '59'; // Format end hour as 'HH00'
        return `${startTime}-${endTime}`;
    };
      
    const preProcessData = (data) => {
        return data.map(item => {
            const jsDate = new Date(`${item.dateOfInjury} ${item.timeOfInjury}`);      
            const luxonDateTime = DateTime.fromJSDate(jsDate);
            const dateOfInjury = DateTime.fromISO(item.dateOfInjury);
            const dateOfBirth = DateTime.fromISO(item.dateOfBirth);

            return {
                ...item,
                day: dateOfInjury.toFormat('cccc'),
                age: calculateAgeBracket(dateOfBirth),
                timeOfInjury: calculateTimeOfDay(luxonDateTime),
            };
        });
    };

    useEffect(() => {
        let processed = preProcessData(data);

        setProcessedAndSortedData(processed);
    }, [data, selectedXField]);

    useEffect(() => {
      const handleResize = () => {
          setIsMobileView(window.innerWidth < 768);
      };

      handleResize(); // Initial check
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Container fluid>
      <Card border="secondary">
        <Card.Header as="h4">
          Injury Data
        </Card.Header>
        {isMobileView ? (
            <Dropdown style={{ margin: '10px' }}>
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    {selectedLabel}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {options.map(({ key, label }) => (
                        <Dropdown.Item
                            key={key}
                            active={selectedXField === key}
                            onClick={() => { setSelectedXField(key); setSelectedLabel(label) }}
                        >
                            {label}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        ) : (
            <ButtonGroup style={{ margin: '10px' }}>
                {options.map(({ key, label }) => (
                    <Button
                        key={key}
                        variant={selectedXField === key ? 'primary' : 'secondary'}
                        onClick={() => { setSelectedXField(key); setSelectedLabel(label) }}
                        style={{ padding: '10px', border: '1px solid black' }}
                    >
                        {label}
                    </Button>
                ))}
            </ButtonGroup>
        )}
        <DataVisual
          data={processedAndSortedData}
          fields={{ x: selectedXField, y: '' }}
          chartType='bar'
          title={`Injuries by ${selectedLabel}`}
          showExportButtons={true}
        />

      </Card>
    </Container>
  );
};

export default InjuryDataSection;
