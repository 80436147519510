// Klienschmidt Glossary: Shipment Status Code – (214 – AT7)
const EDI_214_SHIPMENT_STATUS_CODES = [
    {
        "Code": "A3",
        "Description": "SHIPMENT RETURNED TO SHIPPER"
    },
    {
        "Code": "A7",
        "Description": "REFUSED BY CONSIGNEE"
    },
    {
        "Code": "A9",
        "Description": "SHIPMENT DAMAGED"
    },
    {
        "Code": "AA",
        "Description": "ETA / PICK-UP APPOINTMENT DATE AND/OR TIME"
    },
    {
        "Code": "AF",
        "Description": "CARRIER DEPARTED PICK-UP LOCATION WITH SHIPMENT"
    },
    {
        "Code": "AG",
        "Description": "ESTIMATED DELIVERY"
    },
    {
        "Code": "AH",
        "Description": "ATTEMPTED DELIVERY"
    },
    {
        "Code": "AI",
        "Description": "SHIPMENT HAS BEEN RECONSIGNED"
    },
    {
        "Code": "AJ",
        "Description": "TENDERED FOR DELIVERY"
    },
    {
        "Code": "AM",
        "Description": "LOADED ON TRUCK"
    },
    {
        "Code": "AN",
        "Description": "DIVERTED TO AIR CARRIER"
    },
    {
        "Code": "AP",
        "Description": "DELIVERY NOT COMPLETED"
    },
    {
        "Code": "AR",
        "Description": "RAIL ARRIVAL AT DESTINATION INTERMODAL RAMP"
    },
    {
        "Code": "AV",
        "Description": "AVAILABLE FOR DELIVERY"
    },
    {
        "Code": "B6",
        "Description": "ESTIMATED TO ARRIVE AT CARRIER TERMINAL"
    },
    {
        "Code": "BA",
        "Description": "CONNECTING LINE OR CARTAGE PICK-UP"
    },
    {
        "Code": "BC",
        "Description": "STORAGE IN TRANSIT"
    },
    {
        "Code": "C1",
        "Description": "ESTIMATED TO DEPART TERMINAL LOCATION"
    },
    {
        "Code": "CA",
        "Description": "SHIPMENT CANCELLED"
    },
    {
        "Code": "CD",
        "Description": "CARRIER DEPARTED DELIVERY LOCATION"
    },
    {
        "Code": "CL",
        "Description": "TRAILER CLOSED OUT"
    },
    {
        "Code": "CP",
        "Description": "COMPLETED LOADING AT PICK-UP LOCATION"
    },
    {
        "Code": "D1",
        "Description": "COMPLETED UNLOADING AT DELIVERY LOCATION"
    },
    {
        "Code": "I1",
        "Description": "IN-GATE"
    },
    {
        "Code": "J1",
        "Description": "DELIVERED TO CONNECTING LINE"
    },
    {
        "Code": "K1",
        "Description": "ARRIVED AT CUSTOMS"
    },
    {
        "Code": "L1",
        "Description": "LOADING"
    },
    {
        "Code": "OA",
        "Description": "OUT-GATE"
    },
    {
        "Code": "OO",
        "Description": "PAPERWORK RECEIVED - DID NOT RECEIVE SHIPMENT"
    },
    {
        "Code": "P1",
        "Description": "DEPARTED TERMINAL LOCATION"
    },
    {
        "Code": "PR",
        "Description": "U.S. CUSTOMS HOLD AT IN-BOND LOCATION"
    },
    {
        "Code": "R1",
        "Description": "RECEIVED FROM PRIOR CARRIER"
    },
    {
        "Code": "RL",
        "Description": "RAIL DEPARTURE FROM ORIGIN INTERMODAL RAMP"
    },
    {
        "Code": "S1",
        "Description": "TRAILER SPOTTED AT CONSIGNEE'S LOCATION"
    },
    {
        "Code": "SD",
        "Description": "SHIPMENT DELAYED"
    },
    {
        "Code": "X1",
        "Description": "ARRIVED AT DELIVERY LOCATION"
    },
    {
        "Code": "X2",
        "Description": "ESTIMATED DATE AND/OR TIME OF ARRIVAL AT CONSIGNEE"
    },
    {
        "Code": "X3",
        "Description": "ARRIVED AT PICK-UP LOCATION"
    },
    {
        "Code": "X4",
        "Description": "ARRIVED AT TERMINAL LOCATION"
    },
    {
        "Code": "X5",
        "Description": "ARRIVED AT DELIVERY LOCATION LOADING DOCK"
    },
    {
        "Code": "X6",
        "Description": "EN ROUTE TO DELIVERY LOCATION"
    },
    {
        "Code": "X8",
        "Description": "ARRIVED AT PICK-UP LOCATION LOADING DOCK"
    },
    {
        "Code": "XB",
        "Description": "SHIPMENT ACKNOWLEDGED"
    }
]
const EDI_214_SHIPMENT_STATUS_CODES_MAIL_SPECIFIC = [
    {
        "Code": "AA",
        "Description": "ETA / PICK-UP APPOINTMENT DATE AND/OR TIME"
    },
    {
        "Code": "X3",
        "Description": "ARRIVED AT PICK-UP LOCATION"
    },
    {
        "Code": "AF",
        "Description": "CARRIER DEPARTED PICK-UP LOCATION WITH SHIPMENT"
    },
    {
        "Code": "X6",
        "Description": "EN ROUTE TO DELIVERY LOCATION"
    },
    {
        "Code": "AG",
        "Description": "ESTIMATED DELIVERY"
    },
    {
        "Code": "X1",
        "Description": "ARRIVED AT DELIVERY LOCATION"
    },
    {
        "Code": "CD",
        "Description": "CARRIER DEPARTED DELIVERY LOCATION"
    },
]
const EDI_214_SHIPMENT_STATUS_CODES_MAIL_PICKUP_SPECIFIC = [
    {
        "Code": "AA",
        "Description": "ETA / PICK-UP APPOINTMENT DATE AND/OR TIME"
    },
    {
        "Code": "X3",
        "Description": "ARRIVED AT PICK-UP LOCATION"
    },
    {
        "Code": "AF",
        "Description": "CARRIER DEPARTED PICK-UP LOCATION WITH SHIPMENT"
    }
]
const EDI_214_SHIPMENT_STATUS_CODES_MAIL_DELIVERY_SPECIFIC = [
    {
        "Code": "AG",
        "Description": "ESTIMATED DELIVERY"
    },
    {
        "Code": "X1",
        "Description": "ARRIVED AT DELIVERY LOCATION"
    },
    {
        "Code": "CD",
        "Description": "CARRIER DEPARTED DELIVERY LOCATION"
    }
]
// Klienschmidt Glossary: Stop Off Detail/Stop Reason Code - (204/210 – S5) JSON TAG - "Reason"
const EDI_204_210_STOP_OFF_DETAIL_REASON_CODES = [
    {
        "Code": "AL",
        "Description": "ADVANCE LOADING"
    },
    {
        "Code": "CL",
        "Description": "COMPLETE"
    },
    {
        "Code": "CN",
        "Description": "CONSOLIDATE"
    },
    {
        "Code": "CU",
        "Description": "COMPLETE UNLOAD"
    },
    {
        "Code": "DR",
        "Description": "DERAMP AND RAMP FOR SUBSEQUENT LOADING"
    },
    {
        "Code": "DT",
        "Description": "DROP TRAILER"
    },
    {
        "Code": "HT",
        "Description": "HEAT THE SHIPMENT IN INSPECTION"
    },
    {
        "Code": "LD",
        "Description": "LOAD"
    },
    {
        "Code": "LE",
        "Description": "SPOT FOR LOAD EXCHANGE (EXPORT)"
    },
    {
        "Code": "PA",
        "Description": "PICK-UP PRE-LOADED EQUIPMENT"
    },
    {
        "Code": "PL",
        "Description": "PART LOAD"
    },
    {
        "Code": "PU",
        "Description": "PART UNLOAD"
    },
    {
        "Code": "RT",
        "Description": "RETRIEVAL OF TRAILER"
    },
    {
        "Code": "SL",
        "Description": "SPOT FOR LOAD"
    },
    {
        "Code": "SU",
        "Description": "SPOT FOR UNLOAD"
    },
    {
        "Code": "TL",
        "Description": "TRANSLOAD"
    },
    {
        "Code": "UL",
        "Description": "UNLOAD"
    },
    {
        "Code": "WL",
        "Description": "WEIGH LOADED"
    }
]

// const EDI_204_STOP_REASON_EDI_214_SHIPMENT_STATUS_CODES_MAIL_SPECIFIC = []

// Klienschmidt Glossary: Transaction Set Purpose Code - (204 – B2A) JSON TAG - "Type" & "TypeDesc"
const EDI_TRANSACTION_SET_PURPOSE_CODES = [
    {
        "Code": "0",
        "Description": "ORIGINAL"
    },
    {
        "Code": "1",
        "Description": "CANCELLATION"
    },
    {
        "Code": "2",
        "Description": "ADD"
    },
    {
        "Code": "3",
        "Description": "DELETE"
    },
    {
        "Code": "4",
        "Description": "CHANGE"
    },
    {
        "Code": "5",
        "Description": "REPLACE"
    },
    {
        "Code": "6",
        "Description": "CONFIRMATION"
    },
    {
        "Code": "7",
        "Description": "DUPLICATE"
    },
    {
        "Code": "8",
        "Description": "STATUS"
    },
    {
        "Code": "10",
        "Description": "NOT FOUND"
    },
    {
        "Code": "11",
        "Description": "RESPONSE"
    },
    {
        "Code": "12",
        "Description": "NOT PROCESSED"
    },
    {
        "Code": "13",
        "Description": "REQUEST"
    },
    {
        "Code": "26",
        "Description": "ADVANCE NOTIFICATION"
    },
    {
        "Code": "15",
        "Description": "RE-SUBMISSION"
    },
    {
        "Code": "16",
        "Description": "PROPOSED"
    },
    {
        "Code": "17",
        "Description": "CANCEL"
    },
    {
        "Code": "18",
        "Description": "REISSUE"
    },
    {
        "Code": "19",
        "Description": "SELLER INITIATED CHANGE"
    },
    {
        "Code": "20",
        "Description": "FINAL TRANSMISSION"
    },
    {
        "Code": "21",
        "Description": "TRANSACTION ON HOLD"
    },
    {
        "Code": "22",
        "Description": "INFORMATION COPY"
    },
    {
        "Code": "24",
        "Description": "DRAFT"
    },
    {
        "Code": "25",
        "Description": "INCREMENTAL"
    },
    {
        "Code": "27",
        "Description": "REPLACE - SPECIFIED BUYERS PARTS ONLY"
    },
    {
        "Code": "28",
        "Description": "VERIFY"
    },
    {
        "Code": "30",
        "Description": "QUERY"
    },
    {
        "Code": "31",
        "Description": "RENEWAL"
    },
    {
        "Code": "32",
        "Description": "ALLOWANCE/ADDITION"
    },
    {
        "Code": "33",
        "Description": "RECOVERY/DEDUCTION"
    },
    {
        "Code": "34",
        "Description": "REQUEST FOR PAYMENT"
    },
    {
        "Code": "35",
        "Description": "PAYMENT DECLINED"
    },
    {
        "Code": "36",
        "Description": "REQUEST AUTHORITY"
    },
    {
        "Code": "37",
        "Description": "AUTHORITY TO DEDUCT (REPLY)"
    },
    {
        "Code": "38",
        "Description": "AUTHORITY DECLINED (REPLY)"
    },
    {
        "Code": "39", "Description": "RESPONSE TO PROPOSED TRIP PLAN"
    },
    {
        "Code": "40",
        "Description": "COMMITMENT ADVICE"
    },
    {
        "Code": "41",
        "Description": "CORRECTED AND VERIFIED"
    },
    {
        "Code": "42",
        "Description": "TEMPORARY RECORD"
    },
    {
        "Code": "43",
        "Description": "REQUEST PERMISSION TO SERVICE"
    },
    {
        "Code": "44",
        "Description": "REJECTION"
    },
    {
        "Code": "45",
        "Description": "FOLLOW-UP"
    },
    {
        "Code": "46",
        "Description": "CANCELLATION WITH REFUND"
    },
    {
        "Code": "47",
        "Description": "TRANSFER"
    },
    {
        "Code": "48",
        "Description": "SUSPENDED"
    },
    {
        "Code": "49",
        "Description": "ORIGINAL - NO RESPONSE NECESSARY"
    },
    {
        "Code": "50",
        "Description": "REGISTER"
    },
    {
        "Code": "51",
        "Description": "HISTORICAL INQUIRY"
    },
    {
        "Code": "52",
        "Description": "RESPONSE TO HISTORICAL INQUIRY"
    },
    {
        "Code": "53",
        "Description": "COMPLETION"
    },
    {
        "Code": "54",
        "Description": "APPROVAL"
    },
    {
        "Code": "55",
        "Description": "EXCAVATION"
    },
    {
        "Code": "56",
        "Description": "EXPIRATION NOTIFICATION"
    },
    {
        "Code": "5C",
        "Description": "CHARGEABLE RESUBMISSION"
    },
    {
        "Code": "77",
        "Description": "SIMULATION EXERCISE"
    },
    {
        "Code": "CN",
        "Description": "COMPLETION NOTIFICATION"
    },
    {
        "Code": "CO",
        "Description": "CORRECTED"
    },
    {
        "Code": "EX",
        "Description": "FINAL LOADING CONFIGURATION"
    },
    {
        "Code": "GR",
        "Description": "GRANTED"
    },
    {
        "Code": "PR",
        "Description": "PROPOSED LOADING CONFIGURATION"
    },
    {
        "Code": "RH",
        "Description": "RELEASE HOLD"
    },
    {
        "Code": "RV",
        "Description": "REVISED LOADING CONFIGURATION"
    },
    {
        "Code": "SU",
        "Description": "STATUS UPDATE"
    },
    {
        "Code": "ZZ",
        "Description": "MUTUALLY DEFINED"
    }
]
// Glossary: Time Code - (204/990/214/210 – G62) JSON TAG - "TimeZone"
const EDI_TIMEZONE_CODES = [
    {
        "Code": "1",
        "Description": "EQUIVALENT TO ISO P01"
    },
    {
        "Code": "2",
        "Description": "EQUIVALENT TO ISO P02"
    },
    {
        "Code": "3",
        "Description": "EQUIVALENT TO ISO P03"
    },
    {
        "Code": "4",
        "Description": "EQUIVALENT TO ISO P04"
    },
    {
        "Code": "5",
        "Description": "EQUIVALENT TO ISO P05"
    },
    {
        "Code": "6",
        "Description": "EQUIVALENT TO ISO P06"
    },
    {
        "Code": "7",
        "Description": "EQUIVALENT TO ISO P07"
    },
    {
        "Code": "8",
        "Description": "EQUIVALENT TO ISO P08"
    },
    {
        "Code": "9",
        "Description": "EQUIVALENT TO ISO P09"
    },
    {
        "Code": "10",
        "Description": "EQUIVALENT TO ISO P10"
    },
    {
        "Code": "11",
        "Description": "EQUIVALENT TO ISO P11"
    },
    {
        "Code": "12",
        "Description": "EQUIVALENT TO ISO P12"
    },
    {
        "Code": "13",
        "Description": "EQUIVALENT TO ISO M12"
    },
    {
        "Code": "14",
        "Description": "EQUIVALENT TO ISO M11"
    },
    {
        "Code": "15",
        "Description": "EQUIVALENT TO ISO M10"
    },
    {
        "Code": "16",
        "Description": "EQUIVALENT TO ISO M09"
    },
    {
        "Code": "17",
        "Description": "EQUIVALENT TO ISO M08"
    },
    {
        "Code": "18",
        "Description": "EQUIVALENT TO ISO M07"
    },
    {
        "Code": "19",
        "Description": "EQUIVALENT TO ISO M06"
    },
    {
        "Code": "20",
        "Description": "EQUIVALENT TO ISO M05"
    },
    {
        "Code": "21",
        "Description": "EQUIVALENT TO ISO M04"
    },
    {
        "Code": "22",
        "Description": "EQUIVALENT TO ISO M03"
    },
    {
        "Code": "23",
        "Description": "EQUIVALENT TO ISO M02"
    },
    {
        "Code": "24",
        "Description": "EQUIVALENT TO ISO M01"
    },
    {
        "Code": "AD",
        "Description": "ALASKA DAYLIGHT TIME"
    },
    {
        "Code": "AS",
        "Description": "ALASKA STANDARD TIME"
    },
    {
        "Code": "CD",
        "Description": "CENTRAL DAYLIGHT TIME"
    },
    {
        "Code": "CS",
        "Description": "CENTRAL STANDARD TIME"
    },
    {
        "Code": "CT",
        "Description": "CENTRAL TIME"
    },

]
// Klienschmidt Glossary: Weight Unit Code - (204/214 – AT8, 210 – L3 & 990 – S5) JSON TAG - "WeightUnit”
const EDI_WEIGHT_CODES = [
    {
        "Code": "E",
        "Description": "METRIC TON"
    },
    {
        "Code": "G",
        "Description": "GRAMS"
    },
    {
        "Code": "K",
        "Description": "KILOGRAMS"
    },
    {
        "Code": "L",
        "Description": "POUNDS"
    },
    {
        "Code": "M",
        "Description": "MEASUREMENT TON"
    },
    {
        "Code": "O",
        "Description": "OUNCES"
    },
    {
        "Code": "S",
        "Description": "SHORT TON"
    },
    {
        "Code": "T",
        "Description": "LONG TON"
    }
]
// Klienschmidt Glossary: (204/210/990 – N7 & 214 – MS2) - EQUIPMENT CODE JSON TAG - "Equipment"
const EDI_EQUIPMENT_CODES = [
    {
        "Code": "20",
        "Description": "20 FT. IL CONTAINER (OPEN TOP)"
    },
    {
        "Code": "2B",
        "Description": "20 FT. IL CONTAINER (CLOSED TOP)"
    },
    {
        "Code": "2D",
        "Description": "CONTROL UNIT"
    },
    {
        "Code": "2E",
        "Description": "HELPER UNIT"
    },
    {
        "Code": "2F",
        "Description": "ROADRAILER"
    },
    {
        "Code": "2G",
        "Description": "CUT-IN HELPER"
    },
    {
        "Code": "40",
        "Description": "40 FT. IL CONTAINER (OPEN TOP)"
    },
    {
        "Code": "4B",
        "Description": "40 FT. IL CONTAINER (CLOSED TOP)"
    },
    {
        "Code": "AC",
        "Description": "CLOSED CONTAINER"
    },
    {
        "Code": "AF",
        "Description": "AIR FREIGHT (BREAK BULK)"
    },
    {
        "Code": "AL",
        "Description": "CONTAINER"
    },
    {
        "Code": "AP",
        "Description": "AIRCRAFT"
    },
    {
        "Code": "AT",
        "Description": "CLOSED CONTAINER (CONTROLLED TEMPERATURE)"
    },
    {
        "Code": "BC",
        "Description": "COVERED BARGE"
    },
    {
        "Code": "BE",
        "Description": "BILEVEL RAILCAR FULLY OPEN"
    },
    {
        "Code": "BF",
        "Description": "BILEVEL RAILCAR FULLY ENCLOSED"
    },
    {
        "Code": "BG",
        "Description": "BOGIE"
    },
    {
        "Code": "BH",
        "Description": "BILEVEL RAILCAR SCREENED WITH ROOF"
    },
    {
        "Code": "BJ",
        "Description": "BILEVEL RAILCAR SCREENED"
    },
    {
        "Code": "BK",
        "Description": "CONTAINER"
    },
    {
        "Code": "BO",
        "Description": "BARGE OPEN"
    },
    {
        "Code": "BR",
        "Description": "BARGE"
    },
    {
        "Code": "BX",
        "Description": "BOXCAR"
    },
    {
        "Code": "CA",
        "Description": "CABOOSE"
    },
    {
        "Code": "CB",
        "Description": "CHASSIS"
    },
    {
        "Code": "CC",
        "Description": "CONTAINER RESTING ON A CHASSIS"
    },
    {
        "Code": "CD",
        "Description": "CONTAINER WITH BAG HANGERS"
    },
    {
        "Code": "CG",
        "Description": "CONTAINER"
    },
    {
        "Code": "CH",
        "Description": "CHASSIS"
    },
    {
        "Code": "CI",
        "Description": "CONTAINER"
    },
    {
        "Code": "CJ",
        "Description": "CONTAINER"
    },
    {
        "Code": "CK",
        "Description": "CONTAINER"
    },
    {
        "Code": "CL",
        "Description": "CONTAINER (CLOSED TOP - LENGTH UNSPECIFIED)"
    },
    {
        "Code": "CM",
        "Description": "CONTAINER"
    },
    {
        "Code": "CN",
        "Description": "CONTAINER"
    },
    {
        "Code": "CP",
        "Description": "COIL CAR OPEN"
    },
    {
        "Code": "CQ",
        "Description": "CONTAINER"
    },
    {
        "Code": "CR",
        "Description": "COIL-CAR COVERED"
    },
    {
        "Code": "CS",
        "Description": "CONTAINER-LOW SIDE OPEN TOP"
    },
    {
        "Code": "CT",
        "Description": "CONTAINER-HIGH SIDE OPEN TOP"
    },
    {
        "Code": "CU",
        "Description": "CONTAINER (OPEN TOP - LENGTH UNSPECIFIED)"
    },
    {
        "Code": "CV",
        "Description": "CLOSED VAN"
    },
    {
        "Code": "CW",
        "Description": "CONTAINER"
    },
    {
        "Code": "CX",
        "Description": "CONTAINER"
    },
    {
        "Code": "CZ",
        "Description": "REFRIGERATED CONTAINER"
    },
    {
        "Code": "DD",
        "Description": "DOUBLE-DROP TRAILER"
    },
    {
        "Code": "DF",
        "Description": "CONTAINER WITH FLUSH DOORS"
    },
    {
        "Code": "DT",
        "Description": "DROP BACK TRAILER"
    },
    {
        "Code": "DX",
        "Description": "BOXCAR CUSHION UNDER FRAME OF"
    },
    {
        "Code": "FF",
        "Description": "FROZEN FOOD TRAILER"
    },
    {
        "Code": "FH",
        "Description": "FLAT BED TRAILER WITH HEADBOARDS"
    },
    {
        "Code": "FN",
        "Description": "FLAT BED TRAILER WITH NO HEADBOARDS"
    },
    {
        "Code": "FP",
        "Description": "FLATCAR WITH PEDESTAL"
    },
    {
        "Code": "FR",
        "Description": "FLAT BED TRAILER - REMOVABLE SIDES"
    },
    {
        "Code": "FS",
        "Description": "CONTAINER WITH FLOOR SECURING RINGS"
    },
    {
        "Code": "FT",
        "Description": "FLAT BED TRAILER"
    },
    {
        "Code": "FX",
        "Description": "BOXCAR CUSHION UNDER FRAME OF"
    },
    {
        "Code": "GS",
        "Description": "GENERATOR SET"
    },
    {
        "Code": "HB",
        "Description": "CONTAINER WITH HANGAR BARS"
    },
    {
        "Code": "HC",
        "Description": "HOPPER CAR (COVERED)"
    },
    {
        "Code": "HO",
        "Description": "HOPPER CAR (OPEN)"
    },
    {
        "Code": "HP",
        "Description": "HOPPER CAR (COVERED; PNEUMATIC DISCHARGE)"
    },
    {
        "Code": "HT",
        "Description": "HEAD OF TRAIN DEVICE"
    },
    {
        "Code": "HV",
        "Description": "HIGH CUBE VAN"
    },
    {
        "Code": "HY",
        "Description": "HYDRANT-CART"
    },
    {
        "Code": "ID",
        "Description": "IDLER CAR"
    },
    {
        "Code": "IX",
        "Description": "BOXCAR (INSULATED)"
    },
    {
        "Code": "LO",
        "Description": "LOCOMOTIVE"
    },
    {
        "Code": "LS",
        "Description": "HALF HEIGHT FLAT RACK"
    },
    {
        "Code": "LU",
        "Description": "LOAD/UNLOAD DEVICE ON EQUIPMENT"
    },
    {
        "Code": "NX",
        "Description": "BOXCAR (INTERIOR BULKHEADS)"
    },
    {
        "Code": "OB",
        "Description": "OCEAN VESSEL (BREAK BULK)"
    },
    {
        "Code": "OT",
        "Description": "OPEN-TOP/FLATBED TRAILER"
    },
    {
        "Code": "OV",
        "Description": "OPEN TOP VAN"
    },
    {
        "Code": "PL",
        "Description": "CONTAINER"
    },
    {
        "Code": "PP",
        "Description": "POWER PACK"
    },
    {
        "Code": "PT",
        "Description": "PROTECTED TRAILER"
    },
    {
        "Code": "PU",
        "Description": "PICK-UP TRUCK"
    },
    {
        "Code": "RA",
        "Description": "FIXED-RACK"
    },
    {
        "Code": "RC",
        "Description": "REFRIGERATED (REEFER) CAR"
    },
    {
        "Code": "RD",
        "Description": "FIXED-RACK"
    },
    {
        "Code": "RE",
        "Description": "FLAT CAR (END BULKHEADS)"
    },
    {
        "Code": "RF",
        "Description": "FLAT CAR"
    },
    {
        "Code": "RG",
        "Description": "GONDOLA COVERED"
    },
    {
        "Code": "RI",
        "Description": "GONDOLA CAR (COVERED - INTERIOR BULKHEADS)"
    },
    {
        "Code": "RO",
        "Description": "GONDOLA CAR (OPEN)"
    },
    {
        "Code": "RR",
        "Description": "RAIL CAR"
    },
    {
        "Code": "RS",
        "Description": "FIXED-RACK"
    },
    {
        "Code": "RT",
        "Description": "CONTROLLED TEMPERATURE TRAILER (REEFER)"
    },
    {
        "Code": "SA",
        "Description": "SADDLE"
    },
    {
        "Code": "SC",
        "Description": "SERVICE CAR"
    },
    {
        "Code": "SD",
        "Description": "SINGLE-DROP TRAILER"
    },
    {
        "Code": "SK",
        "Description": "STACK CAR"
    },
    {
        "Code": "SL",
        "Description": "CONTAINER"
    },
    {
        "Code": "SR",
        "Description": "STAK-RAK"
    },
    {
        "Code": "SS",
        "Description": "SPINE CAR"
    },
    {
        "Code": "ST",
        "Description": "STOCK CAR"
    },
    {
        "Code": "TG",
        "Description": "TANK CAR (GENERAL SERVICE)"
    },
    {
        "Code": "TP",
        "Description": "TANK CAR (PRESSURIZED)"
    },
    {
        "Code": "TR",
        "Description": "TRAILER ON FLAT CAR (TOFC)"
    },
    {
        "Code": "TT",
        "Description": "TOFC TRAILER"
    },
    {
        "Code": "TX",
        "Description": "TANK CAR (FIXED HEAD)"
    },
    {
        "Code": "VP",
        "Description": "COVERED HOPPER CAR (PNEUMATIC DISCHARGE; VACUUM TYPE)"
    },
    {
        "Code": "VR",
        "Description": "COVERED HOPPER CAR (GRAVITY DISCHARGE; ROUND HATCHES)"
    },
    {
        "Code": "WC",
        "Description": "WELL CAR"
    },
    {
        "Code": "ZV",
        "Description": "ROADRAILER"
    }
]

// Klienschmidt Glossary: SHIPMENT METHOD OF PAYMENT – (204 – B2 06) JSON TAG - "PaymentMethod"
const EDI_SHIPMENT_METHOD_OF_PAYMENT = [
    { 
        "Code": "11",
        "Description": "RULE 11 SHIPMENT" 
    },
    {
        "Code": "BP",
        "Description": "PAID BY BUYER"
    },
    {
        "Code": "CA",
        "Description": "ADVANCE COLLECT"
    },
    {
        "Code": "CC",
        "Description": "COLLECT"
    },
    {
        "Code": "CD",
        "Description": "COLLECT ON DELIVERY"
    },
    {
        "Code": "CF",
        "Description": "COLLECT"
    },
    {
        "Code": "DF",
        "Description": "DEFINED BY BUYER AND SELLER"
    },
    {
        "Code": "DE",
        "Description": "PER CONTRACT"
    },
    {
        "Code": "FO",
        "Description": "FOB PORT OF CALL"
    },
    {
        "Code": "HP",
        "Description": "HALF PREPAID"
    },
    {
        "Code": "MX",
        "Description": "MIXED"
    },
    {
        "Code": "NC",
        "Description": "SERVICE FREIGHT"
    },
    {
        "Code": "NR",
        "Description": "NON REVENUE"
    },
    {
        "Code": "PA",
        "Description": "ADVANCE PREPAID"
    },
    {
        "Code": "PB",
        "Description": "CUSTOMER PICK-UP/BACKHAUL"
    },
    {
        "Code": "PC",
        "Description": "PREPAID BUT CHARGED TO CUSTOMER"
    },
    {
        "Code": "PD",
        "Description": "PREPAID BY PROCESSOR"
    },
    {
        "Code": "PE",
        "Description": "PREPAID AND SUMMARY BILL"
    },
    {
        "Code": "PL",
        "Description": "PREPAID LOCAL"
    },
    {
        "Code": "PO",
        "Description": "PREPAID ONLY"
    },
    {
        "Code": "PP",
        "Description": "PREPAID (BY SELLER)"
    },
    {
        "Code": "PS",
        "Description": "PAID BY SELLER"
    },
    {
        "Code": "PU",
        "Description": "PICKUP"
    },
    {
        "Code": "RC",
        "Description": "RETURN CONTAINER FREIGHT PAID BY CUSTOMER"
    },
    {
        "Code": "RF",
        "Description": "RETURN CONTAINER FREIGHT FREE"
    },
    {
        "Code": "RS",
        "Description": "RETURN CONTAINER FREIGHT PAID BY SUPPLIER"
    },
    {
        "Code": "TP",
        "Description": "THIRD PARTY PAY"
    },
    {
        "Code": "WC",
        "Description": "WEIGHT CONDITION"
    }
]

// Glossary: STOP REASON CODE – (204 – S5 02) JSON TAG - "Reason"
const EDI_STOP_REASON_CODE = [
    {
        "Code": "68",
        "Description": "ADVANCE LOADING"
    },
    {
        "Code": "AL",
        "Description": "COMPLETE"
    },
    {
        "Code": "CN",
        "Description": "CONSOLIDATE"
    },
    {
        "Code": "CU",
        "Description": "COMPLETE UNLOAD"
    },
    {
        "Code": "DR",
        "Description": "DERAMP AND RAMP FOR SUBSEQUENT LOADING"
    },
    {
        "Code": "DT",
        "Description": "DROP TRAILER"
    },
    {
        "Code": "HT",
        "Description": "HEAT THE SHIPMENT IN INSPECTION"
    },
    {
        "Code": "LD",
        "Description": "LOAD"
    },
    {
        "Code": "LE",
        "Description": "SPOT FOR LOAD EXCHANGE (EXPORT)"
    },
    {
        "Code": "PA",
        "Description": "PICK-UP PRE-LOADED EQUIPMENT"
    },
    {
        "Code": "PL",
        "Description": "PART LOAD"
    },
    {
        "Code": "PU",
        "Description": "PART UNLOAD"
    },
    {
        "Code": "RT",
        "Description": "RETRIEVAL OF TRAILER"
    },
    {
        "Code": "SL",
        "Description": "SPOT FOR LOAD"
    },
    {
        "Code": "SU",
        "Description": "SPOT FOR UNLOAD"
    },
    {
        "Code": "TL",
        "Description": "TRANSLOAD"
    },
    {
        "Code": "UL",
        "Description": "UNLOAD"
    },
    {
        "Code": "WL",
        "Description": "WEIGH LOADED"
    }
]
// Klienschmidt Glossary: Business Instructions and Reference No. – (L11) JSON TAG - "Qualifier” & "QualDesc"
const EDI_BUSINESS_INSTRUCTIONS_REFERENCE_NO_CODES = [
    {
        "Code": "1 ",
        "Description": "AMERICAN BANKERS ASSOC. (ABA) TRANSIT/ROUTING NUMB "
    },
    {
        "Code": "2 ",
        "Description": "SOCIETY FOR WORLDWIDE INTERBANK FINANCIAL TELECOMM "
    },
    {
        "Code": "3 ",
        "Description": "CLEARING HOUSE INTERBANK PAYMENT SYSTEM (CHIPS) PA "
    },
    {
        "Code": "4 ",
        "Description": "CANADIAN FINANCIAL INSTITUTION BRANCH AND INSTITUT "
    },
    {
        "Code": "5 ",
        "Description": "CLEARING HOUSE INTERBANK PAYMENT SYSTEM (CHIPS) US "
    },
    {
        "Code": "6 ",
        "Description": "SYSTEM NUMBER "
    },
    {
        "Code": "7 ",
        "Description": "ADD-ON SYSTEM NUMBER "
    },
    {
        "Code": "8 ",
        "Description": "CARRIER ASSIGNED PACKAGE IDENTIFICATION NUMBER "
    },
    {
        "Code": "9 ",
        "Description": "CUSTOMS BAR CODE NUMBER "
    },
    {
        "Code": "0A ",
        "Description": "SUPERVISORY APPRAISER CERTIFICATION NUMBER "
    },
    {
        "Code": "0B ",
        "Description": "STATE LICENSE NUMBER "
    },
    {
        "Code": "0D ",
        "Description": "SUBJECT PROPERTY VERIFICATION SOURCE "
    },
    {
        "Code": "0E ",
        "Description": "SUBJECT PROPERTY REFERENCE NUMBER "
    },
    {
        "Code": "0F ",
        "Description": "SUBSCRIBER NUMBER "
    },
    {
        "Code": "0G ",
        "Description": "REVIEWER FILE NUMBER "
    },
    {
        "Code": "0H ",
        "Description": "COMPARABLE PROPERTY PENDING SALE REFERENCE NUMBER "
    },
    {
        "Code": "0I ",
        "Description": "COMPARABLE PROPERTY SALE REFERENCE NUMBER "
    },
    {
        "Code": "0J ",
        "Description": "SUBJECT PROPERTY NON-SALE REFERENCE NUMBER "
    },
    {
        "Code": "0K ",
        "Description": "POLICY FORM IDENTIFYING NUMBER "
    },
    {
        "Code": "0L ",
        "Description": "REFERENCED BY "
    },
    {
        "Code": "0M ",
        "Description": "MORTGAGE IDENTIFICATION NUMBER "
    },
    {
        "Code": "0N ",
        "Description": "ATTACHED TO "
    },
    {
        "Code": "0P ",
        "Description": "REAL ESTATE OWNED PROPERTY IDENTIFIER "
    },
    {
        "Code": "10 ",
        "Description": "ACCOUNT MANAGERS CODE "
    },
    {
        "Code": "11 ",
        "Description": "ACCOUNT NUMBER "
    },
    {
        "Code": "12 ",
        "Description": "BILLING ACCOUNT "
    },
    {
        "Code": "13 ",
        "Description": "HORIZONTAL COORDINATE "
    },
    {
        "Code": "14 ",
        "Description": "MASTER ACCOUNT NUMBER "
    },
    {
        "Code": "15 ",
        "Description": "VERTICAL COORDINATE "
    },
    {
        "Code": "16 ",
        "Description": "MILITARY INTERDEPARTMENTAL PURCHASE REQUEST (MIPR) "
    },
    {
        "Code": "17 ",
        "Description": "CLIENT REPORTING CATEGORY "
    },
    {
        "Code": "18 ",
        "Description": "PLAN NUMBER "
    },
    {
        "Code": "19 ",
        "Description": "DIVISION IDENTIFIER "
    },
    {
        "Code": "1A ",
        "Description": "BLUE CROSS PROVIDER NUMBER "
    },
    {
        "Code": "1B ",
        "Description": "BLUE SHIELD PROVIDER NUMBER "
    },
    {
        "Code": "1C ",
        "Description": "MEDICARE PROVIDER NUMBER "
    },
    {
        "Code": "1D ",
        "Description": "MEDICAID PROVIDER NUMBER "
    },
    {
        "Code": "1E ",
        "Description": "DENTIST LICENSE NUMBER "
    },
    {
        "Code": "1F ",
        "Description": "ANESTHESIA LICENSE NUMBER "
    },
    {
        "Code": "1G ",
        "Description": "PROVIDER UPIN NUMBER "
    },
    {
        "Code": "1H ",
        "Description": "CHAMPUS IDENTIFICATION NUMBER "
    },
    {
        "Code": "1I ",
        "Description": "DEPARTMENT OF DEFENSE IDENTIFICATION CODE (DODIC) "
    },
    {
        "Code": "1J ",
        "Description": "FACILITY ID NUMBER "
    },
    {
        "Code": "1K ",
        "Description": "PAYOR'S CLAIM NUMBER "
    },
    {
        "Code": "1L ",
        "Description": "GROUP OR POLICY NUMBER "
    },
    {
        "Code": "1M ",
        "Description": "PREFERRED PROVIDER ORGANIZATION SITE NUMBER "
    },
    {
        "Code": "1N ",
        "Description": "DIAGNOSIS RELATED GROUP (DRG) NUMBER "
    },
    {
        "Code": "1O ",
        "Description": "CONSOLIDATION SHIPMENT NUMBER "
    },
    {
        "Code": "1P ",
        "Description": "ACCESSORIAL STATUS CODE "
    },
    {
        "Code": "1Q ",
        "Description": "ERROR IDENTIFICATION CODE "
    },
    {
        "Code": "1R ",
        "Description": "STORAGE INFORMATION CODE "
    },
    {
        "Code": "1S ",
        "Description": "AMBULATORY PATIENT GROUP (APG) NUMBER "
    },
    {
        "Code": "1T ",
        "Description": "RESOURCE UTILIZATION GROUP (RUG) NUMBER "
    },
    {
        "Code": "1U ",
        "Description": "PAY GRADE "
    },
    {
        "Code": "1V ",
        "Description": "RELATED VENDOR ORDER NUMBER "
    },
    {
        "Code": "1W ",
        "Description": "MEMBER IDENTIFICATION NUMBER "
    },
    {
        "Code": "1X ",
        "Description": "CREDIT OR DEBIT ADJUSTMENT NUMBER "
    },
    {
        "Code": "1Y ",
        "Description": "REPAIR ACTION NUMBER "
    },
    {
        "Code": "1Z ",
        "Description": "FINANCIAL DETAIL CODE "
    },
    {
        "Code": "20 ",
        "Description": "REPAIR PART NUMBER "
    },
    {
        "Code": "21 ",
        "Description": "AMERICAN GAS ASSOCIATION EQUATION NUMBER "
    },
    {
        "Code": "22 ",
        "Description": "SPECIAL CHARGE OR ALLOWANCE CODE "
    },
    {
        "Code": "23 ",
        "Description": "CLIENT NUMBER "
    },
    {
        "Code": "24 ",
        "Description": "SHORT-TERM DISABILITY POLICY NUMBER "
    },
    {
        "Code": "25 ",
        "Description": "REASON NOT LOWEST COST CODE "
    },
    {
        "Code": "26 ",
        "Description": "UNION NUMBER "
    },
    {
        "Code": "27 ",
        "Description": "INSUROR POOL IDENTIFICATION NUMBER "
    },
    {
        "Code": "28 ",
        "Description": "EMPLOYEE IDENTIFICATION NUMBER "
    },
    {
        "Code": "29 ",
        "Description": "FORECLOSURE ACCOUNT NUMBER "
    },
    {
        "Code": "2A ",
        "Description": "IMPORT LICENSE NUMBER "
    },
    {
        "Code": "2B ",
        "Description": "TERMINAL RELEASE ORDER NUMBER "
    },
    {
        "Code": "2C ",
        "Description": "LONG-TERM DISABILITY POLICY NUMBER "
    },
    {
        "Code": "2D ",
        "Description": "AERONAUTICAL EQUIPMENT REFERENCE NUMBER (AERNO) "
    },
    {
        "Code": "2E ",
        "Description": "FOREIGN MILITARY SALES CASE NUMBER "
    },
    {
        "Code": "2F ",
        "Description": "CONSOLIDATED INVOICE NUMBER "
    },
    {
        "Code": "2G ",
        "Description": "AMENDMENT "
    },
    {
        "Code": "2H ",
        "Description": "ASSIGNED BY TRANSACTION SET SENDER "
    },
    {
        "Code": "2I ",
        "Description": "TRACKING NUMBER "
    },
    {
        "Code": "2J ",
        "Description": "FLOOR NUMBER "
    },
    {
        "Code": "2K ",
        "Description": "FOOD AND DRUG ADMINISTRATION (FDA) PRODUCT TYPE "
    },
    {
        "Code": "2L ",
        "Description": "ASSOCIATION OF AMERICAN RAILROADS (AAR) RAILWAY AC "
    },
    {
        "Code": "2M ",
        "Description": "FEDERAL COMMUNICATIONS COMMISSION (FCC) IDENTIFIER "
    },
    {
        "Code": "2N ",
        "Description": "FEDERAL COMMUNICATIONS COMMISSION (FCC) TRADE/BRAN "
    },
    {
        "Code": "2O ",
        "Description": "OCCUPATIONAL SAFETY AND HEALTH ADMINISTRATION (OSH "
    },
    {
        "Code": "2P ",
        "Description": "SUBDIVISION IDENTIFIER "
    },
    {
        "Code": "2Q ",
        "Description": "FOOD AND DRUG ADMINISTRATION (FDA) ACCESSION NUMBE "
    },
    {
        "Code": "2R ",
        "Description": "COUPON REDEMPTION NUMBER "
    },
    {
        "Code": "2S ",
        "Description": "CATALOG "
    },
    {
        "Code": "2T ",
        "Description": "SUB-SUBHOUSE BILL OF LADING "
    },
    {
        "Code": "2U ",
        "Description": "PAYER IDENTIFICATION NUMBER "
    },
    {
        "Code": "2V ",
        "Description": "SPECIAL GOVERNMENT ACCOUNTING CLASSIFICATION REFER "
    },
    {
        "Code": "2W ",
        "Description": "CHANGE ORDER AUTHORITY "
    },
    {
        "Code": "2X ",
        "Description": "SUPPLEMENTAL AGREEMENT AUTHORITY "
    },
    {
        "Code": "2Y ",
        "Description": "WAGE DETERMINATION "
    },
    {
        "Code": "2Z ",
        "Description": "U.S. CUSTOMS SERVICE (USCS) ANTI-DUMPING DUTY CASE "
    },
    {
        "Code": "30 ",
        "Description": "UNITED STATES GOVERNMENT VISA NUMBER "
    },
    {
        "Code": "31 ",
        "Description": "DOCKET NUMBER "
    },
    {
        "Code": "32 ",
        "Description": "CREDIT REPOSITORY CODE "
    },
    {
        "Code": "33 ",
        "Description": "LENDER CASE NUMBER "
    },
    {
        "Code": "34 ",
        "Description": "LOAN REQUEST NUMBER "
    },
    {
        "Code": "35 ",
        "Description": "MULTIFAMILY PROJECT NUMBER "
    },
    {
        "Code": "36 ",
        "Description": "UNDERWRITER IDENTIFICATION NUMBER "
    },
    {
        "Code": "37 ",
        "Description": "CONDOMINIUM IDENTIFICATION NUMBER "
    },
    {
        "Code": "38 ",
        "Description": "MASTER POLICY NUMBER "
    },
    {
        "Code": "39 ",
        "Description": "PROPOSAL NUMBER "
    },
    {
        "Code": "3A ",
        "Description": "SECTION OF THE NATIONAL HOUSING ACT CODE "
    },
    {
        "Code": "3B ",
        "Description": "SUPPLEMENTAL CLAIM NUMBER "
    },
    {
        "Code": "3C ",
        "Description": "PAYEE LOAN NUMBER "
    },
    {
        "Code": "3D ",
        "Description": "SERVICER LOAN NUMBER "
    },
    {
        "Code": "3E ",
        "Description": "INVESTOR LOAN NUMBER "
    },
    {
        "Code": "3F ",
        "Description": "SHOW IDENTIFICATION "
    },
    {
        "Code": "3G ",
        "Description": "CATASTROPHE NUMBER "
    },
    {
        "Code": "3H ",
        "Description": "CASE NUMBER "
    },
    {
        "Code": "3I ",
        "Description": "PRECINCT NUMBER "
    },
    {
        "Code": "3J ",
        "Description": "OFFICE NUMBER "
    },
    {
        "Code": "3K ",
        "Description": "PETROLEUM POOL CODE "
    },
    {
        "Code": "3L ",
        "Description": "BRANCH IDENTIFIER "
    },
    {
        "Code": "3M ",
        "Description": "FEDERAL COMMUNICATIONS COMMISSION (FCC) CONDITION "
    },
    {
        "Code": "3N ",
        "Description": "GAS CUSTODIAN IDENTIFICATION "
    },
    {
        "Code": "3O ",
        "Description": "U.S. CUSTOMS SERVICE (USCS) PRE-APPROVAL RULING NU "
    },
    {
        "Code": "3P ",
        "Description": "THIRD PARTY ORIGINATOR NUMBER "
    },
    {
        "Code": "3Q ",
        "Description": "FOOD AND DRUG ADMINISTRATION (FDA) PRODUCT CODE "
    },
    {
        "Code": "3R ",
        "Description": "U.S. CUSTOMS SERVICE (USCS) BINDING RULING NUMBER "
    },
    {
        "Code": "3S ",
        "Description": "PROVINCIAL (CANADIAN) SALES TAX EXEMPTION NUMBER "
    },
    {
        "Code": "3T ",
        "Description": "U.S. CUSTOMS SERVICE (USCS) PRE-CLASSIFICATION RUL "
    },
    {
        "Code": "3U ",
        "Description": "PROTRACTION NUMBER "
    },
    {
        "Code": "3V ",
        "Description": "FORMATION IDENTIFIER "
    },
    {
        "Code": "3W ",
        "Description": "U.S. CUSTOMS SERVICE (USCS) COMMERCIAL DESCRIPTION "
    },
    {
        "Code": "3X ",
        "Description": "SUBCONTRACT NUMBER "
    },
    {
        "Code": "3Y ",
        "Description": "RECEIVER ASSIGNED DROP ZONE "
    },
    {
        "Code": "3Z ",
        "Description": "CUSTOMS BROKER REFERENCE NUMBER "
    },
    {
        "Code": "40 ",
        "Description": "LEASE SCHEDULE NUMBER - REPLACEMENT "
    },
    {
        "Code": "41 ",
        "Description": "LEASE SCHEDULE NUMBER - PRIOR "
    },
    {
        "Code": "42 ",
        "Description": "PHONE CALLS "
    },
    {
        "Code": "43 ",
        "Description": "SUPPORTING DOCUMENT NUMBER "
    },
    {
        "Code": "44 ",
        "Description": "END USE NUMBER "
    },
    {
        "Code": "45 ",
        "Description": "OLD ACCOUNT NUMBER "
    },
    {
        "Code": "46 ",
        "Description": "OLD METER NUMBER "
    },
    {
        "Code": "47 ",
        "Description": "PLATE NUMBER "
    },
    {
        "Code": "48 ",
        "Description": "AGENCY'S STUDENT NUMBER. THIS IS THE NUMBER ASSIGN "
    },
    {
        "Code": "49 ",
        "Description": "FAMILY UNIT NUMBER "
    },
    {
        "Code": "4A ",
        "Description": "PERSONAL IDENTIFICATION NUMBER (PIN) "
    },
    {
        "Code": "4B ",
        "Description": "SHIPMENT ORIGIN CODE "
    },
    {
        "Code": "4C ",
        "Description": "SHIPMENT DESTINATION CODE "
    },
    {
        "Code": "4D ",
        "Description": "SHIPPING ZONE "
    },
    {
        "Code": "4E ",
        "Description": "CARRIER-ASSIGNED CONSIGNEE NUMBER "
    },
    {
        "Code": "4F ",
        "Description": "CARRIER-ASSIGNED SHIPPER NUMBER "
    },
    {
        "Code": "4G ",
        "Description": "PROVINCIAL TAX IDENTIFICATION "
    },
    {
        "Code": "4H ",
        "Description": "COMMERCIAL INVOICE NUMBER "
    },
    {
        "Code": "4I ",
        "Description": "BALANCE-DUE REFERENCE NUMBER "
    },
    {
        "Code": "4J ",
        "Description": "VEHICLE-RELATED SERVICES REFERENCE NUMBER "
    },
    {
        "Code": "4K ",
        "Description": "ACCESSORIAL RAIL DIVERSION REFERENCE NUMBER "
    },
    {
        "Code": "4L ",
        "Description": "LOCATION-SPECIFIC SERVICES REFERENCE NUMBER "
    },
    {
        "Code": "4M ",
        "Description": "SPECIAL MOVE REFERENCE NUMBER "
    },
    {
        "Code": "4N ",
        "Description": "SPECIAL PAYMENT REFERENCE NUMBER "
    },
    {
        "Code": "4O ",
        "Description": "CANADIAN GOODS & SERVICES OR QUEBEC SALES TAX REFE "
    },
    {
        "Code": "4P ",
        "Description": "AFFILIATION NUMBER "
    },
    {
        "Code": "4Q ",
        "Description": "CALL SIGN "
    },
    {
        "Code": "4R ",
        "Description": "RULE SECTION "
    },
    {
        "Code": "4S ",
        "Description": "PREFERRED CALL SIGN "
    },
    {
        "Code": "4T ",
        "Description": "NORTH AMERICAN DATUM STANDARD (NADS) "
    },
    {
        "Code": "4U ",
        "Description": "MARKET AREA "
    },
    {
        "Code": "4V ",
        "Description": "EMISSION DESIGNATOR "
    },
    {
        "Code": "4W ",
        "Description": "STUDY "
    },
    {
        "Code": "4X ",
        "Description": "LOG "
    },
    {
        "Code": "4Y ",
        "Description": "SUBHOUSE BILL OF LADING "
    },
    {
        "Code": "4Z ",
        "Description": "U.S. CUSTOMS SERVICE (USCS) COUNTERVAILING DUTY CA "
    },
    {
        "Code": "50 ",
        "Description": "STATE STUDENT IDENTIFICATION NUMBER "
    },
    {
        "Code": "51 ",
        "Description": "PICTURE NUMBER "
    },
    {
        "Code": "52 ",
        "Description": "SWIFT (MT 100) "
    },
    {
        "Code": "53 ",
        "Description": "SWIFT (MT 202) "
    },
    {
        "Code": "54 ",
        "Description": "FEDWIRE (FEDERAL WIRE TRANSFER) "
    },
    {
        "Code": "55 ",
        "Description": "SEQUENCE NUMBER "
    },
    {
        "Code": "56 ",
        "Description": "CORRECTED SOCIAL SECURITY NUMBER "
    },
    {
        "Code": "57 ",
        "Description": "PRIOR INCORRECT SOCIAL SECURITY NUMBER "
    },
    {
        "Code": "58 ",
        "Description": "CORRECTED BATCH NUMBER "
    },
    {
        "Code": "59 ",
        "Description": "PRIOR INCORRECT BATCH NUMBER "
    },
    {
        "Code": "5A ",
        "Description": "OFFENSE TRACKING "
    },
    {
        "Code": "5B ",
        "Description": "SUPPLEMENTAL ACCOUNT NUMBER "
    },
    {
        "Code": "5C ",
        "Description": "CONGRESSIONAL DISTRICT "
    },
    {
        "Code": "5D ",
        "Description": "LINE OF CREDIT CATEGORY "
    },
    {
        "Code": "5E ",
        "Description": "CONSUMER IDENTIFIER "
    },
    {
        "Code": "5F ",
        "Description": "WARRANT "
    },
    {
        "Code": "5G ",
        "Description": "COMPLAINT "
    },
    {
        "Code": "5H ",
        "Description": "INCIDENT "
    },
    {
        "Code": "5I ",
        "Description": "OFFENDER TRACKING "
    },
    {
        "Code": "5J ",
        "Description": "DRIVER'S LICENSE "
    },
    {
        "Code": "5K ",
        "Description": "COMMERCIAL DRIVER'S LICENSE "
    },
    {
        "Code": "5L ",
        "Description": "JURISDICTIONAL COMMUNITY NUMBER "
    },
    {
        "Code": "5M ",
        "Description": "PREVIOUS SEQUENCE "
    },
    {
        "Code": "5N ",
        "Description": "CITATION OF STATUTE "
    },
    {
        "Code": "5O ",
        "Description": "CITATION OF OPINION "
    },
    {
        "Code": "5P ",
        "Description": "NATIONAL CRIMINAL INFORMATION CENTER ORIGINATING A "
    },
    {
        "Code": "5Q ",
        "Description": "STATE CRIMINAL HISTORY REPOSITORY INDIVIDUAL IDENT "
    },
    {
        "Code": "5R ",
        "Description": "FEDERAL BUREAU OF INVESTIGATION INDIVIDUAL IDENTIF "
    },
    {
        "Code": "5S ",
        "Description": "PROCESSING AREA "
    },
    {
        "Code": "5T ",
        "Description": "PAYMENT LOCATION "
    },
    {
        "Code": "5U ",
        "Description": "FLOOD DATA IDENTIFIER "
    },
    {
        "Code": "5V ",
        "Description": "COUPON DISTRIBUTION METHOD "
    },
    {
        "Code": "5W ",
        "Description": "ORIGINAL UNIFORM COMMERCIAL CODE FILING NUMBER "
    },
    {
        "Code": "5X ",
        "Description": "AMENDED UNIFORM COMMERCIAL CODE FILING NUMBER "
    },
    {
        "Code": "5Y ",
        "Description": "CONTINUATION UNIFORM COMMERCIAL CODE FILING NUMBER "
    },
    {
        "Code": "5Z ",
        "Description": "UNIFORM COMMERCIAL CODE FILING COLLATERAL NUMBER "
    },
    {
        "Code": "60 ",
        "Description": "ACCOUNT SUFFIX CODE "
    },
    {
        "Code": "61 ",
        "Description": "TAXING AUTHORITY IDENTIFICATION NUMBER "
    },
    {
        "Code": "63 ",
        "Description": "PRIOR LOAN NUMBER "
    },
    {
        "Code": "64 ",
        "Description": "JURISDICTIONAL COMMUNITY NAME IDENTIFIER "
    },
    {
        "Code": "65 ",
        "Description": "TOTAL ORDER CYCLE NUMBER "
    },
    {
        "Code": "66 ",
        "Description": "PREVIOUS POLICY NUMBER "
    },
    {
        "Code": "67 ",
        "Description": "PREVIOUS CLAIM HISTORY IDENTIFIER "
    },
    {
        "Code": "68 ",
        "Description": "DENTAL INSURANCE ACCOUNT NUMBER "
    },
    {
        "Code": "69 ",
        "Description": "DENTAL INSURANCE POLICY NUMBER "
    },
    {
        "Code": "6A ",
        "Description": "CONSIGNEE REFERENCE NUMBER "
    },
    {
        "Code": "6B ",
        "Description": "U.S. CUSTOMS SERVICE (USCS) ENTRY NUMBER "
    },
    {
        "Code": "6C ",
        "Description": "U.S. CUSTOMS SERVICE (USCS) ENTRY TYPE CODE "
    },
    {
        "Code": "6D ",
        "Description": "U.S. CUSTOMS SERVICE (USCS) STATEMENT NUMBER "
    },
    {
        "Code": "6E ",
        "Description": "MAP REFERENCE "
    },
    {
        "Code": "6F ",
        "Description": "APPRAISER LICENSE "
    },
    {
        "Code": "6G ",
        "Description": "MAP NUMBER "
    },
    {
        "Code": "6H ",
        "Description": "COMPARABLE PROPERTY VERIFICATION SOURCE "
    },
    {
        "Code": "6I ",
        "Description": "COMPARABLE PROPERTY "
    },
    {
        "Code": "6J ",
        "Description": "CENSUS TRACT "
    },
    {
        "Code": "6K ",
        "Description": "ZONE "
    },
    {
        "Code": "6L ",
        "Description": "AGENT CONTRACT NUMBER "
    },
    {
        "Code": "6M ",
        "Description": "APPLICATION NUMBER "
    },
    {
        "Code": "6N ",
        "Description": "CLAIMANT NUMBER "
    },
    {
        "Code": "6O ",
        "Description": "CROSS REFERENCE NUMBER "
    },
    {
        "Code": "6P ",
        "Description": "GROUP NUMBER "
    },
    {
        "Code": "6Q ",
        "Description": "INSURANCE LICENSE NUMBER "
    },
    {
        "Code": "6R ",
        "Description": "PROVIDER CONTROL NUMBER "
    },
    {
        "Code": "6S ",
        "Description": "PROVIDER ORDER TICKET NUMBER "
    },
    {
        "Code": "6T ",
        "Description": "PILOT LICENSE NUMBER "
    },
    {
        "Code": "6U ",
        "Description": "QUESTION NUMBER "
    },
    {
        "Code": "6V ",
        "Description": "REISSUE CESSION NUMBER "
    },
    {
        "Code": "6W ",
        "Description": "SEQUENCE NUMBER "
    },
    {
        "Code": "6X ",
        "Description": "SPECIMEN IDENTIFIER "
    },
    {
        "Code": "6Y ",
        "Description": "EQUIPMENT INITIAL "
    },
    {
        "Code": "6Z ",
        "Description": "SECRETARIA DE COMERCIA Y FAMENTA INDUSTRIAL (SECOF "
    },
    {
        "Code": "70 ",
        "Description": "CALENDAR NUMBER "
    },
    {
        "Code": "71 ",
        "Description": "(WORKING) SHIFT NUMBER "
    },
    {
        "Code": "72 ",
        "Description": "SCHEDULE REFERENCE NUMBER "
    },
    {
        "Code": "73 ",
        "Description": "STATEMENT OF WORK (SOW) "
    },
    {
        "Code": "74 ",
        "Description": "WORK BREAKDOWN STRUCTURE (WBS) "
    },
    {
        "Code": "75 ",
        "Description": "ORGANIZATION BREAKDOWN STRUCTURE "
    },
    {
        "Code": "76 ",
        "Description": "MILESTONE "
    },
    {
        "Code": "77 ",
        "Description": "WORK PACKAGE "
    },
    {
        "Code": "78 ",
        "Description": "PLANNING PACKAGE "
    },
    {
        "Code": "79 ",
        "Description": "COST ACCOUNT "
    },
    {
        "Code": "7A ",
        "Description": "PURCHASE ORDER NUMBER INCLUDED IN ON-ORDER POSITIO "
    },
    {
        "Code": "7B ",
        "Description": "PURCHASE ORDER NUMBER OF SHIPMENT RECEIVED SINCE L "
    },
    {
        "Code": "7C ",
        "Description": "PURCHASE ORDER NUMBER OF ORDER RECEIVED SINCE LAST "
    },
    {
        "Code": "7D ",
        "Description": "TESTER IDENTIFICATION "
    },
    {
        "Code": "7E ",
        "Description": "COLLECTOR IDENTIFICATION "
    },
    {
        "Code": "7F ",
        "Description": "REPEAT LOCATION "
    },
    {
        "Code": "7G ",
        "Description": "DATA QUALITY REJECT REASON "
    },
    {
        "Code": "7H ",
        "Description": "ENVIRONMENTAL PROTECTION AGENCY (EPA) TEST TYPE PU "
    },
    {
        "Code": "7I ",
        "Description": "SUBSCRIBER AUTHORIZATION NUMBER "
    },
    {
        "Code": "7J ",
        "Description": "TOLL BILLING TELEPHONE REFERENCE NUMBER "
    },
    {
        "Code": "7K ",
        "Description": "LIST OF MATERIALS "
    },
    {
        "Code": "7L ",
        "Description": "QUALIFIED MATERIALS LIST "
    },
    {
        "Code": "7M ",
        "Description": "FRAME "
    },
    {
        "Code": "7N ",
        "Description": "PIGGYBACK "
    },
    {
        "Code": "7O ",
        "Description": "TRIPLEBACK "
    },
    {
        "Code": "7P ",
        "Description": "SHEET "
    },
    {
        "Code": "7Q ",
        "Description": "ENGINEERING CHANGE ORDER "
    },
    {
        "Code": "7R ",
        "Description": "REPRESENTATIVE IDENTIFICATION NUMBER "
    },
    {
        "Code": "7S ",
        "Description": "DRAWING TYPE "
    },
    {
        "Code": "7T ",
        "Description": "MASTER CONTRACT "
    },
    {
        "Code": "7U ",
        "Description": "RELATED TRANSACTION REFERENCE NUMBER "
    },
    {
        "Code": "7W ",
        "Description": "INTERCHANGE TRAIN IDENTIFICATION "
    },
    {
        "Code": "7X ",
        "Description": "HOME MORTGAGE DISCLOSURE ACT (HMDA) STATE CODE "
    },
    {
        "Code": "7Y ",
        "Description": "HOME MORTGAGE DISCLOSURE ACT (HMDA) COUNTY CODE "
    },
    {
        "Code": "7Z ",
        "Description": "HOME MORTGAGE DISCLOSURE ACT (HMDA) METROPOLITAN S "
    },
    {
        "Code": "80 ",
        "Description": "CHARGE NUMBER "
    },
    {
        "Code": "81 ",
        "Description": "SYMBOL NUMBER (FOR MILESTONE OR LOB REPORTS) "
    },
    {
        "Code": "82 ",
        "Description": "DATA ITEM DESCRIPTION (DID) REFERENCE "
    },
    {
        "Code": "83 ",
        "Description": "EXTENDED (OR EXHIBIT) LINE ITEM NUMBER (ELIN) "
    },
    {
        "Code": "84 ",
        "Description": "CONTRACTOR DATA REQUIREMENTS LIST (CDRL) "
    },
    {
        "Code": "85 ",
        "Description": "SUBCONTRACTOR DATA REQUIREMENTS (SDRL) "
    },
    {
        "Code": "86 ",
        "Description": "OPERATION NUMBER "
    },
    {
        "Code": "87 ",
        "Description": "FUNCTIONAL CATEGORY "
    },
    {
        "Code": "88 ",
        "Description": "WORK CENTER "
    },
    {
        "Code": "89 ",
        "Description": "ASSEMBLY NUMBER "
    },
    {
        "Code": "8A ",
        "Description": "HEALTH MAINTENANCE ORGANIZATION (HMO) AUTHORIZATIO "
    },
    {
        "Code": "8B ",
        "Description": "PREFERRED PROVIDER ORGANIZATION (PPO) AUTHORIZATIO "
    },
    {
        "Code": "8C ",
        "Description": "THIRD-PARTY ORGANIZATION (TPO) AUTHORIZATION NUMBE "
    },
    {
        "Code": "8D ",
        "Description": "CHEMICAL ABSTRACT SERVICE REGISTRY NUMBER "
    },
    {
        "Code": "8E ",
        "Description": "GUARANTOR LOAN NUMBER "
    },
    {
        "Code": "8F ",
        "Description": "SCHOOL LOAN NUMBER "
    },
    {
        "Code": "8G ",
        "Description": "AUTOMATED CLEARINGHOUSE (ACH) TRACE NUMBER "
    },
    {
        "Code": "8H ",
        "Description": "CHECK LIST NUMBER "
    },
    {
        "Code": "8I ",
        "Description": "FEDWIRE CONFIRMATION NUMBER "
    },
    {
        "Code": "8J ",
        "Description": "SOCIETY FOR WORLDWIDE INTERBANK FINANCIAL TELECOMM "
    },
    {
        "Code": "8K ",
        "Description": "DOMINION OF CANADA CODE "
    },
    {
        "Code": "8L ",
        "Description": "INTERNATIONAL STANDARD INDUSTRY CLASSIFICATION COD "
    },
    {
        "Code": "8M ",
        "Description": "ORIGINATING COMPANY IDENTIFIER "
    },
    {
        "Code": "8N ",
        "Description": "RECEIVING COMPANY IDENTIFIER "
    },
    {
        "Code": "8O ",
        "Description": "AUTOMATED CLEARING HOUSE (ACH) ENTRY DESCRIPTION "
    },
    {
        "Code": "8P ",
        "Description": "ORIGINATING DEPOSITORY FINANCIAL INSTITUTION IDENT "
    },
    {
        "Code": "8Q ",
        "Description": "RECEIVING DEPOSITORY FINANCIAL INSTITUTION IDENTIF "
    },
    {
        "Code": "8R ",
        "Description": "SECURITY TYPE "
    },
    {
        "Code": "8S ",
        "Description": "BROKER IDENTIFICATION "
    },
    {
        "Code": "8U ",
        "Description": "BANK ASSIGNED SECURITY IDENTIFIER "
    },
    {
        "Code": "8V ",
        "Description": "CREDIT REFERENCE "
    },
    {
        "Code": "8W ",
        "Description": "BANK TO BANK INFORMATION "
    },
    {
        "Code": "8X ",
        "Description": "TRANSACTION CATEGORY OR TYPE "
    },
    {
        "Code": "8Y ",
        "Description": "SAFEKEEPING ACCOUNT NUMBER "
    },
    {
        "Code": "8Z ",
        "Description": "ALTERNATE CLAUSE NUMBER "
    },
    {
        "Code": "90 ",
        "Description": "SUBASSEMBLY NUMBER "
    },
    {
        "Code": "91 ",
        "Description": "COST ELEMENT "
    },
    {
        "Code": "92 ",
        "Description": "CHANGE DOCUMENT NUMBER "
    },
    {
        "Code": "93 ",
        "Description": "FUNDS AUTHORIZATION "
    },
    {
        "Code": "94 ",
        "Description": "FILE IDENTIFICATION NUMBER "
    },
    {
        "Code": "95 ",
        "Description": "COMMITTEE ON UNIFORM SECURITIES IDENTIFICATION PRO "
    },
    {
        "Code": "96 ",
        "Description": "STOCK CERTIFICATE NUMBER "
    },
    {
        "Code": "97 ",
        "Description": "PACKAGE NUMBER "
    },
    {
        "Code": "98 ",
        "Description": "CONTAINER/PACKAGING SPECIFICATION NUMBER "
    },
    {
        "Code": "99 ",
        "Description": "RATE CONFERENCE ID CODE "
    },
    {
        "Code": "9A ",
        "Description": "REPRICED CLAIM REFERENCE NUMBER "
    },
    {
        "Code": "9B ",
        "Description": "REPRICED LINE ITEM REFERENCE NUMBER "
    },
    {
        "Code": "9C ",
        "Description": "ADJUSTED REPRICED CLAIM REFERENCE NUMBER "
    },
    {
        "Code": "9D ",
        "Description": "ADJUSTED REPRICED LINE ITEM REFERENCE NUMBER "
    },
    {
        "Code": "9E ",
        "Description": "REPLACEMENT CLAIM NUMBER "
    },
    {
        "Code": "9F ",
        "Description": "REFERRAL NUMBER "
    },
    {
        "Code": "9G ",
        "Description": "DEPARTMENT OF DEFENSE FORM 250 REQUIREMENT CODE "
    },
    {
        "Code": "9H ",
        "Description": "PACKAGING GROUP NUMBER "
    },
    {
        "Code": "9I ",
        "Description": "AUTOMATED CLEARING HOUSE (ACH) STANDARD ENTRY CLAS "
    },
    {
        "Code": "9J ",
        "Description": "PENSION CONTRACT "
    },
    {
        "Code": "9K ",
        "Description": "SERVICER "
    },
    {
        "Code": "9L ",
        "Description": "SERVICE BUREAU "
    },
    {
        "Code": "9M ",
        "Description": "CLEARING HOUSE INTERBANK PAYMENTS SYSTEM (CHIPS) S "
    },
    {
        "Code": "9N ",
        "Description": "INVESTOR "
    },
    {
        "Code": "9P ",
        "Description": "LOAN TYPE "
    },
    {
        "Code": "9Q ",
        "Description": "POOL SUFFIX "
    },
    {
        "Code": "9R ",
        "Description": "JOB ORDER NUMBER "
    },
    {
        "Code": "9S ",
        "Description": "DELIVERY REGION "
    },
    {
        "Code": "9T ",
        "Description": "TENOR "
    },
    {
        "Code": "9U ",
        "Description": "LOAN FEATURE CODE "
    },
    {
        "Code": "9V ",
        "Description": "PAYMENT CATEGORY "
    },
    {
        "Code": "9W ",
        "Description": "PAYER CATEGORY "
    },
    {
        "Code": "9X ",
        "Description": "ACCOUNT CATEGORY "
    },
    {
        "Code": "9Y ",
        "Description": "BANK ASSIGNED BANKERS REFERENCE NUMBER "
    },
    {
        "Code": "9Z ",
        "Description": "CHAMBER OF COMMERCE NUMBER "
    },
    {
        "Code": "A0 ",
        "Description": "ADVERTISER NUMBER "
    },
    {
        "Code": "A1 ",
        "Description": "ANALYSIS NUMBER/TEST NUMBER "
    },
    {
        "Code": "A2 ",
        "Description": "DISABILITY INSURANCE ACCOUNT NUMBER "
    },
    {
        "Code": "A3 ",
        "Description": "ASSIGNMENT NUMBER "
    },
    {
        "Code": "A4 ",
        "Description": "DISABILITY INSURANCE POLICY NUMBER "
    },
    {
        "Code": "A5 ",
        "Description": "EDUCATIONAL INSTITUTION IDENTIFICATION NUMBER "
    },
    {
        "Code": "A6 ",
        "Description": "EMPLOYEE IDENTIFICATION NUMBER "
    },
    {
        "Code": "A7 ",
        "Description": "FLEXIBLE SPENDING ACCOUNT (FSA) INSURANCE ACCOUNT "
    },
    {
        "Code": "A8 ",
        "Description": "FLEXIBLE SPENDING ACCOUNT (FSA) INSURANCE POLICY N "
    },
    {
        "Code": "A9 ",
        "Description": "HEALTH INSURANCE ACCOUNT NUMBER "
    },
    {
        "Code": "AA ",
        "Description": "ACCOUNTS RECEIVABLE STATEMENT NUMBER "
    },
    {
        "Code": "AAA ",
        "Description": "DISTRIBUTOR'S SPLIT AGENT NUMBER "
    },
    {
        "Code": "AAB ",
        "Description": "FUND MANAGER'S REFERENCE NUMBER "
    },
    {
        "Code": "AAC ",
        "Description": "AGENCY HIERARCHICAL LEVEL "
    },
    {
        "Code": "AAD ",
        "Description": "OFFICER LICENSE NUMBER "
    },
    {
        "Code": "AAE ",
        "Description": "PREVIOUS DISTRIBUTOR NUMBER "
    },
    {
        "Code": "AAF ",
        "Description": "INTERVIEWER ID "
    },
    {
        "Code": "AAG ",
        "Description": "MILITARY ID "
    },
    {
        "Code": "AAH ",
        "Description": "OPTION POLICY NUMBER "
    },
    {
        "Code": "AAI ",
        "Description": "PAYROLL ACCOUNT NUMBER "
    },
    {
        "Code": "AAJ ",
        "Description": "PRIOR CONTRACT NUMBER "
    },
    {
        "Code": "AAK ",
        "Description": "WORKSITE NUMBER "
    },
    {
        "Code": "AAL ",
        "Description": "AGENT NUMBER "
    },
    {
        "Code": "AAM ",
        "Description": "TREATY IDENTIFIER "
    },
    {
        "Code": "AAN ",
        "Description": "ASSOCIATED CASE CONTROL NUMBER "
    },
    {
        "Code": "AAO ",
        "Description": "CARRIER ASSIGNED CODE "
    },
    {
        "Code": "AAP ",
        "Description": "DEALER NUMBER "
    },
    {
        "Code": "AAQ ",
        "Description": "DIRECTORY NUMBER "
    },
    {
        "Code": "AAR ",
        "Description": "DISTRIBUTOR ASSIGNED TRANSACTION NUMBER "
    },
    {
        "Code": "AAS ",
        "Description": "DISTRIBUTOR ASSIGNED ORDER NUMBER "
    },
    {
        "Code": "AAT ",
        "Description": "DISTRIBUTOR'S ACCOUNT NUMBER "
    },
    {
        "Code": "AAU ",
        "Description": "GENERAL AGENCY NUMBER "
    },
    {
        "Code": "AAV ",
        "Description": "LABORATORY NUMBER "
    },
    {
        "Code": "AAW ",
        "Description": "AGENCY ASSIGNED NUMBER "
    },
    {
        "Code": "AAX ",
        "Description": "LIST BILL NUMBER "
    },
    {
        "Code": "AAY ",
        "Description": "ACCOUNTING PERIOD REFERENCE "
    },
    {
        "Code": "AAZ ",
        "Description": "PARAMEDICAL ID NUMBER "
    },
    {
        "Code": "AB ",
        "Description": "ACCEPTABLE SOURCE PURCHASER ID "
    },
    {
        "Code": "ABA ",
        "Description": "PAYROLL NUMBER "
    },
    {
        "Code": "ABB ",
        "Description": "PERSONAL ID NUMBER "
    },
    {
        "Code": "ABC ",
        "Description": "POLICY LINK NUMBER "
    },
    {
        "Code": "ABD ",
        "Description": "SECONDARY POLICY NUMBER "
    },
    {
        "Code": "ABE ",
        "Description": "SPECIAL QUOTE NUMBER "
    },
    {
        "Code": "ABF ",
        "Description": "NATIONAL PROPERTY REGISTRY SYSTEM LEVEL 1 "
    },
    {
        "Code": "ABG ",
        "Description": "NATIONAL PROPERTY REGISTRY SYSTEM LEVEL 2 "
    },
    {
        "Code": "ABH ",
        "Description": "INVESTOR ASSIGNED IDENTIFICATION NUMBER "
    },
    {
        "Code": "ABJ ",
        "Description": "GINNIE MAE (GOVERNMENT NATIONAL MORTGAGE ASSOCIATI "
    },
    {
        "Code": "ABK ",
        "Description": "MORTGAGE ELECTRONIC REGISTRATION SYSTEM ORGANIZATI "
    },
    {
        "Code": "ABL ",
        "Description": "SELLER LOAN NUMBER "
    },
    {
        "Code": "ABM ",
        "Description": "SUB-SERVICER LOAN NUMBER "
    },
    {
        "Code": "ABN ",
        "Description": "NATIONAL PROPERTY REGISTRY SYSTEM LEVEL 3 "
    },
    {
        "Code": "ABO ",
        "Description": "STATE HAZARDOUS WASTE ENTITY IDENTIFIER "
    },
    {
        "Code": "ABP ",
        "Description": "BANKRUPTCY PROCEDURE NUMBER "
    },
    {
        "Code": "ABQ ",
        "Description": "NATIONAL BUSINESS IDENTIFICATION NUMBER "
    },
    {
        "Code": "ABR ",
        "Description": "PRIOR DATA UNIVERSAL NUMBER SYSTEM (D-U-N-S) NUMBE "
    },
    {
        "Code": "ABS ",
        "Description": "VESSEL NAME "
    },
    {
        "Code": "ABT ",
        "Description": "SECURITY INSTRUMENT NUMBER "
    },
    {
        "Code": "ABU ",
        "Description": "ASSIGNMENT RECORDING NUMBER "
    },
    {
        "Code": "ABV ",
        "Description": "BOOK NUMBER "
    },
    {
        "Code": "ABY ",
        "Description": "HEALTH CARE FINANCING ADMINISTRATION NATIONAL PAYE "
    },
    {
        "Code": "AC ",
        "Description": "AIR CARGO TRANSFER MANIFEST "
    },
    {
        "Code": "ACA ",
        "Description": "GROWTH FACTOR REFERENCE "
    },
    {
        "Code": "ACB ",
        "Description": "REGION "
    },
    {
        "Code": "ACC ",
        "Description": "STATUS "
    },
    {
        "Code": "ACD ",
        "Description": "CLASS CODE "
    },
    {
        "Code": "ACE ",
        "Description": "SERVICE REQUEST NUMBER "
    },
    {
        "Code": "ACF ",
        "Description": "SUPPLEMENT NUMBER "
    },
    {
        "Code": "ACG ",
        "Description": "PREVIOUS TICKET NUMBER "
    },
    {
        "Code": "ACH ",
        "Description": "ONE CALL AGENCY TICKET NUMBER "
    },
    {
        "Code": "ACI ",
        "Description": "TICKET NUMBER "
    },
    {
        "Code": "ACJ ",
        "Description": "BILL OF MATERIAL REVISION NUMBER "
    },
    {
        "Code": "ACK ",
        "Description": "DRAWING REVISION NUMBER "
    },
    {
        "Code": "ACR ",
        "Description": "AUTOMATED CLEARINGHOUSE (ACH) RETURN/NOTIFICATION "
    },
    {
        "Code": "ACS ",
        "Description": "SOCIETY OF PROPERTY INFORMATION COMPILERS AND ANAL "
    },
    {
        "Code": "ACT ",
        "Description": "ACCOUNTING CODE "
    },
    {
        "Code": "AD ",
        "Description": "ACCEPTABLE SOURCE DUNS NUMBER "
    },
    {
        "Code": "ADA ",
        "Description": "AGENCY FOR INTERNATIONAL DEVELOPMENT ACQUISITION R "
    },
    {
        "Code": "ADB ",
        "Description": "MASTER PROPERTY NUMBER "
    },
    {
        "Code": "ADC ",
        "Description": "PROJECT PROPERTY NUMBER "
    },
    {
        "Code": "ADD ",
        "Description": "UNIT PROPERTY NUMBER "
    },
    {
        "Code": "ADE ",
        "Description": "ASSOCIATED PROPERTY NUMBER "
    },
    {
        "Code": "ADF ",
        "Description": "ASSOCIATED NUMBER FOR LIMITED COMMON ELEMENT PARKI "
    },
    {
        "Code": "ADG ",
        "Description": "ASSOCIATED NUMBER FOR UNIT PARKING "
    },
    {
        "Code": "ADH ",
        "Description": "ASSOCIATED NUMBER FOR JOINED UNIT NOT RE-SUBDIVIDE "
    },
    {
        "Code": "ADI ",
        "Description": "PROCESSOR IDENTIFICATION NUMBER "
    },
    {
        "Code": "ADM ",
        "Description": "AIR DIMENSION CODE "
    },
    {
        "Code": "AE ",
        "Description": "AUTHORIZATION FOR EXPENSE (AFE) NUMBER "
    },
    {
        "Code": "AEA ",
        "Description": "NUMERO DE CEDULA DE IDENTIDAD (CIN) NUMBER "
    },
    {
        "Code": "AEB ",
        "Description": "COMPANY'S REGISTRY OFFICE (CRO) NUMBER "
    },
    {
        "Code": "AEC ",
        "Description": "GOVERNMENT REGISTRATION NUMBER "
    },
    {
        "Code": "AED ",
        "Description": "JUDICIAL NUMBER "
    },
    {
        "Code": "AEE ",
        "Description": "NUMERO DE IDENTIFICACION TRIBUTARIA (NIT) "
    },
    {
        "Code": "AEF ",
        "Description": "PASSPORT NUMBER "
    },
    {
        "Code": "AEG ",
        "Description": "PATRON NUMBER "
    },
    {
        "Code": "AEH ",
        "Description": "REGISTRO INFORMACION FISCAL (RIF) "
    },
    {
        "Code": "AEI ",
        "Description": "REGISTRO UNICO DE CONTRIBUYENTE (RUC) "
    },
    {
        "Code": "AEJ ",
        "Description": "SUPERINTENDENCIA DE INVERSIONES EXTRANJERAS (SIEX) "
    },
    {
        "Code": "AEK ",
        "Description": "TOKYO SHOKO RESEARCH BUSINESS IDENTIFIER "
    },
    {
        "Code": "AEL ",
        "Description": "REGISTRO NACIONAL DE CONTRIBUYENTE (RNC) "
    },
    {
        "Code": "AEM ",
        "Description": "DISTRIBUTION CENTER NUMBER "
    },
    {
        "Code": "AF ",
        "Description": "AIRLINES FLIGHT IDENTIFICATION NUMBER "
    },
    {
        "Code": "AG ",
        "Description": "AGENT'S SHIPMENT NUMBER "
    },
    {
        "Code": "AH ",
        "Description": "AGREEMENT NUMBER "
    },
    {
        "Code": "AHC ",
        "Description": "AIR HANDLING CODE "
    },
    {
        "Code": "AI ",
        "Description": "ASSOCIATED INVOICES "
    },
    {
        "Code": "AJ ",
        "Description": "ACCOUNTS RECEIVABLE CUSTOMER ACCOUNT "
    },
    {
        "Code": "AK ",
        "Description": "SENDING COMPANY AUDIT NUMBER (AUTOMATED CLEARINGHO "
    },
    {
        "Code": "AL ",
        "Description": "ACCOUNTING (EQUIPMENT) LOCATION NUMBER "
    },
    {
        "Code": "ALC ",
        "Description": "AGENCY LOCATION CODE "
    },
    {
        "Code": "ALG ",
        "Description": "TITLE COMPANY CODE BOOK REFERENCE "
    },
    {
        "Code": "ALH ",
        "Description": "TITLE DOCUMENT SCHEDULE "
    },
    {
        "Code": "ALI ",
        "Description": "RECORDING NUMBER "
    },
    {
        "Code": "ALJ ",
        "Description": "TITLE POLICY NUMBER "
    },
    {
        "Code": "ALT ",
        "Description": "ALTERATION NUMBER "
    },
    {
        "Code": "AM ",
        "Description": "ADJUSTMENT MEMO (CHARGE BACK) "
    },
    {
        "Code": "AN ",
        "Description": "ASSOCIATED PURCHASE ORDERS "
    },
    {
        "Code": "AO ",
        "Description": "APPOINTMENT NUMBER "
    },
    {
        "Code": "AP ",
        "Description": "ACCOUNTS RECEIVABLE NUMBER "
    },
    {
        "Code": "API ",
        "Description": "AMERICAN PETROLEUM INSTITUTE (API) DEDUCTION CODE "
    },
    {
        "Code": "AQ ",
        "Description": "ACCESS CODE "
    },
    {
        "Code": "AR ",
        "Description": "ARRIVAL CODE "
    },
    {
        "Code": "AS ",
        "Description": "ACCEPTABLE SOURCE SUPPLIER ID "
    },
    {
        "Code": "ASL ",
        "Description": "ATOMIC SAFETY AND LICENSING BOARD PANEL (ASLBP) NU "
    },
    {
        "Code": "ASP ",
        "Description": "ANIMAL SPECIES "
    },
    {
        "Code": "AST ",
        "Description": "ANIMAL STRAIN "
    },
    {
        "Code": "AT ",
        "Description": "APPROPRIATION NUMBER "
    },
    {
        "Code": "ATC ",
        "Description": "MAINTENANCE AVAILABILITY TYPE "
    },
    {
        "Code": "AU ",
        "Description": "AUTHORIZATION TO MEET COMPETITION NUMBER "
    },
    {
        "Code": "AV ",
        "Description": "HEALTH INSURANCE RATING ACCOUNT NUMBER "
    },
    {
        "Code": "AW ",
        "Description": "AIR WAYBILL NUMBER "
    },
    {
        "Code": "AX ",
        "Description": "GOVERNMENT ACCOUNTING CLASS REFERENCE NUMBER (ACRN "
    },
    {
        "Code": "AY ",
        "Description": "FLOOR PLAN APPROVAL NUMBER "
    },
    {
        "Code": "AZ ",
        "Description": "HEALTH INSURANCE POLICY NUMBER "
    },
    {
        "Code": "B1 ",
        "Description": "LESSEE BILL CODE NUMBER "
    },
    {
        "Code": "B2 ",
        "Description": "AXLE RATIO "
    },
    {
        "Code": "B3 ",
        "Description": "PREFERRED PROVIDER ORGANIZATION NUMBER "
    },
    {
        "Code": "B4 ",
        "Description": "BILATERAL CAR SERVICE AGREEMENTS "
    },
    {
        "Code": "B5 ",
        "Description": "HEALTH INSURANCE RATING SUFFIX CODE "
    },
    {
        "Code": "B6 ",
        "Description": "LIFE INSURANCE BILLING ACCOUNT NUMBER "
    },
    {
        "Code": "B7 ",
        "Description": "LIFE INSURANCE POLICY NUMBER "
    },
    {
        "Code": "B8 ",
        "Description": "LIFE INSURANCE BILLING SUFFIX CODE "
    },
    {
        "Code": "B9 ",
        "Description": "RETIREMENT PLAN ACCOUNT NUMBER "
    },
    {
        "Code": "BA ",
        "Description": "RETIREMENT PLAN POLICY NUMBER "
    },
    {
        "Code": "BAA ",
        "Description": "FRANCHISE TAX ACCOUNT NUMBER "
    },
    {
        "Code": "BAB ",
        "Description": "CERTIFICATE OF INCORPORATION NUMBER "
    },
    {
        "Code": "BAC ",
        "Description": "BEAM ASSEMBLY CODE "
    },
    {
        "Code": "BAD ",
        "Description": "STATE TAX IDENTIFICATION NUMBER "
    },
    {
        "Code": "BAE ",
        "Description": "CHARTER NUMBER "
    },
    {
        "Code": "BAF ",
        "Description": "RECEIPT NUMBER "
    },
    {
        "Code": "BAG ",
        "Description": "WITHDRAWAL ACCOUNT NUMBER "
    },
    {
        "Code": "BAH ",
        "Description": "DEPOSIT ACCOUNT NUMBER "
    },
    {
        "Code": "BAI ",
        "Description": "BUSINESS IDENTIFICATION NUMBER "
    },
    {
        "Code": "BB ",
        "Description": "AUTHORIZATION NUMBER "
    },
    {
        "Code": "BC ",
        "Description": "BUYER'S CONTRACT NUMBER "
    },
    {
        "Code": "BCI ",
        "Description": "BASIC CONTRACT LINE ITEM NUMBER "
    },
    {
        "Code": "BD ",
        "Description": "BID NUMBER "
    },
    {
        "Code": "BE ",
        "Description": "BUSINESS ACTIVITY "
    },
    {
        "Code": "BF ",
        "Description": "BILLING CENTER IDENTIFICATION "
    },
    {
        "Code": "BG ",
        "Description": "BEGINNING SERIAL NUMBER "
    },
    {
        "Code": "BH ",
        "Description": "LEASE SCHEDULE NUMBER - BLANKET "
    },
    {
        "Code": "BI ",
        "Description": "BONDED CARRIER INTERNAL REVENUE SERVICE IDENTIFICA "
    },
    {
        "Code": "BJ ",
        "Description": "CARRIER'S CUSTOMS BOND NUMBER "
    },
    {
        "Code": "BK ",
        "Description": "BROKER'S ORDER NUMBER "
    },
    {
        "Code": "BKT ",
        "Description": "BANK TELEGRAPHIC NUMBER "
    },
    {
        "Code": "BL ",
        "Description": "GOVERNMENT BILL OF LADING "
    },
    {
        "Code": "BLT ",
        "Description": "BILLING TYPE "
    },
    {
        "Code": "BM ",
        "Description": "BILL OF LADING NUMBER "
    },
    {
        "Code": "BMM ",
        "Description": "BEGIN MILE MARKER "
    },
    {
        "Code": "BN ",
        "Description": "BOOKING NUMBER "
    },
    {
        "Code": "BO ",
        "Description": "BIN LOCATION NUMBER "
    },
    {
        "Code": "BOI ",
        "Description": "BINARY OBJECT IDENTIFIER "
    },
    {
        "Code": "BP ",
        "Description": "ADJUSTMENT CONTROL NUMBER "
    },
    {
        "Code": "BQ ",
        "Description": "HEALTH MAINTENANCE ORGANIZATION CODE NUMBER "
    },
    {
        "Code": "BR ",
        "Description": "BROKER OR SALES OFFICE NUMBER "
    },
    {
        "Code": "BS ",
        "Description": "SPLIT BOOKING NUMBER "
    },
    {
        "Code": "BT ",
        "Description": "BATCH NUMBER "
    },
    {
        "Code": "BU ",
        "Description": "BUYER'S APPROVAL MARK "
    },
    {
        "Code": "BV ",
        "Description": "PURCHASE ORDER LINE ITEM IDENTIFIER (BUYER) "
    },
    {
        "Code": "BW ",
        "Description": "BLENDED WITH BATCH NUMBER "
    },
    {
        "Code": "BX ",
        "Description": "BUYER'S SHIPMENT MARK NUMBER "
    },
    {
        "Code": "BY ",
        "Description": "REPAIR CATEGORY NUMBER "
    },
    {
        "Code": "BZ ",
        "Description": "COMPLAINT CODE "
    },
    {
        "Code": "C0 ",
        "Description": "CANADIAN SOCIAL INSURANCE NUMBER "
    },
    {
        "Code": "C1 ",
        "Description": "CUSTOMER MATERIAL SPECIFICATION NUMBER "
    },
    {
        "Code": "C2 ",
        "Description": "CUSTOMER PROCESS SPECIFICATION NUMBER "
    },
    {
        "Code": "C3 ",
        "Description": "CUSTOMER SPECIFICATION NUMBER "
    },
    {
        "Code": "C4 ",
        "Description": "CHANGE NUMBER "
    },
    {
        "Code": "C5 ",
        "Description": "CUSTOMER TRACKING NUMBER FOR LOANED MATERIALS "
    },
    {
        "Code": "C6 ",
        "Description": "CARNET NUMBER "
    },
    {
        "Code": "C7 ",
        "Description": "CONTRACT LINE ITEM NUMBER "
    },
    {
        "Code": "C8 ",
        "Description": "CORRECTED CONTRACT NUMBER "
    },
    {
        "Code": "C9 ",
        "Description": "PREVIOUS CREDIT/DEBIT ADJUSTMENT NUMBER "
    },
    {
        "Code": "CA ",
        "Description": "COST ALLOCATION REFERENCE "
    },
    {
        "Code": "CB ",
        "Description": "COMBINED SHIPMENT "
    },
    {
        "Code": "CBG ",
        "Description": "CENSUS BLOCK GROUP "
    },
    {
        "Code": "CC ",
        "Description": "CONTRACT CO-OP NUMBER "
    },
    {
        "Code": "CD ",
        "Description": "CREDIT NOTE NUMBER "
    },
    {
        "Code": "CDN ",
        "Description": "CITIZENSHIP DOCUMENT NUMBER "
    },
    {
        "Code": "CE ",
        "Description": "CLASS OF CONTRACT CODE "
    },
    {
        "Code": "CF ",
        "Description": "FLEET REFERENCE NUMBER "
    },
    {
        "Code": "CG ",
        "Description": "CONSIGNEE'S ORDER NUMBER "
    },
    {
        "Code": "CH ",
        "Description": "CUSTOMER CATALOG NUMBER "
    },
    {
        "Code": "CI ",
        "Description": "UNIQUE CONSIGNMENT IDENTIFIER "
    },
    {
        "Code": "CIR ",
        "Description": "CIRCUIT NUMBER "
    },
    {
        "Code": "CIT ",
        "Description": "CITATION "
    },
    {
        "Code": "CJ ",
        "Description": "CLAUSE NUMBER "
    },
    {
        "Code": "CK ",
        "Description": "CHECK NUMBER "
    },
    {
        "Code": "CL ",
        "Description": "SELLER'S CREDIT MEMO "
    },
    {
        "Code": "CM ",
        "Description": "BUYER'S CREDIT MEMO "
    },
    {
        "Code": "CMN ",
        "Description": "CONTINUOUS MOVE NUMBER "
    },
    {
        "Code": "CMP ",
        "Description": "CUSTOMER MAINTENANCE PERIOD SEQUENCE NUMBER "
    },
    {
        "Code": "CMT ",
        "Description": "COMPONENT "
    },
    {
        "Code": "CN ",
        "Description": "CARRIER'S REFERENCE NUMBER (PRO/INVOICE) "
    },
    {
        "Code": "CNO ",
        "Description": "COMMITMENT NUMBER "
    },
    {
        "Code": "CO ",
        "Description": "CUSTOMER ORDER NUMBER "
    },
    {
        "Code": "COL ",
        "Description": "COLLOCATION INDICATOR "
    },
    {
        "Code": "COT ",
        "Description": "CERTIFICATE OF TRANSPORTATION "
    },
    {
        "Code": "CP ",
        "Description": "CONDITION OF PURCHASE DOCUMENT NUMBER "
    },
    {
        "Code": "CPA ",
        "Description": "CANADIAN PROVINCE OPERATING AUTHORITY NUMBER "
    },
    {
        "Code": "CPT ",
        "Description": "CURRENT PROCEDURAL TERMINOLOGY CODE "
    },
    {
        "Code": "CQ ",
        "Description": "CUSTOMSHOUSE BROKER LICENSE NUMBER "
    },
    {
        "Code": "CR ",
        "Description": "CUSTOMER REFERENCE NUMBER "
    },
    {
        "Code": "CRN ",
        "Description": "CASUALTY REPORT NUMBER "
    },
    {
        "Code": "CRS ",
        "Description": "CASUALTY REPORT SERIAL NUMBER "
    },
    {
        "Code": "CS ",
        "Description": "CONDITION OF SALE DOCUMENT NUMBER "
    },
    {
        "Code": "CSC ",
        "Description": "CS54 KEY TRAIN INDICATOR CODE "
    },
    {
        "Code": "CSG ",
        "Description": "CS54 KEY TRAIN INDICATOR GROUP NAME "
    },
    {
        "Code": "CST ",
        "Description": "CENSUS STATE CODE "
    },
    {
        "Code": "CT ",
        "Description": "CONTRACT NUMBER "
    },
    {
        "Code": "CTS ",
        "Description": "CENSUS TRACT SUFFIX "
    },
    {
        "Code": "CU ",
        "Description": "CLEAR TEXT CLAUSE "
    },
    {
        "Code": "CV ",
        "Description": "COIL NUMBER "
    },
    {
        "Code": "CW ",
        "Description": "CANADIAN WHEAT BOARD PERMIT NUMBER "
    },
    {
        "Code": "CX ",
        "Description": "CONSIGNMENT CLASSIFICATION ID "
    },
    {
        "Code": "CY ",
        "Description": "COMMERCIAL REGISTRATION NUMBER "
    },
    {
        "Code": "CYC ",
        "Description": "PERIODICITY CODE "
    },
    {
        "Code": "CZ ",
        "Description": "CONTRACT RIDER NUMBER (USED IN CONJUNCTION WITH CO "
    },
    {
        "Code": "D0 ",
        "Description": "DATA RELIABILITY CODE "
    },
    {
        "Code": "D1 ",
        "Description": "DRUG ENFORCEMENT ADMINISTRATION ORDER BLANK NUMBER "
    },
    {
        "Code": "D2 ",
        "Description": "SUPPLIER DOCUMENT IDENTIFICATION NUMBER "
    },
    {
        "Code": "D3 ",
        "Description": "NATIONAL ASSOCIATION OF BOARDS OF PHARMACY NUMBER "
    },
    {
        "Code": "D4 ",
        "Description": "CUT NUMBER "
    },
    {
        "Code": "D5 ",
        "Description": "DYE LOT NUMBER "
    },
    {
        "Code": "D6 ",
        "Description": "DUPLICATE BILL NUMBER "
    },
    {
        "Code": "D7 ",
        "Description": "COVERAGE CODE "
    },
    {
        "Code": "D8 ",
        "Description": "LOSS REPORT NUMBER "
    },
    {
        "Code": "D9 ",
        "Description": "CLAIM NUMBER "
    },
    {
        "Code": "DA ",
        "Description": "DOMICILE BRANCH NUMBER "
    },
    {
        "Code": "DB ",
        "Description": "BUYER'S DEBIT MEMO "
    },
    {
        "Code": "DC ",
        "Description": "DEALER PURCHASE ORDER NUMBER "
    },
    {
        "Code": "DD ",
        "Description": "DOCUMENT IDENTIFICATION CODE "
    },
    {
        "Code": "DE ",
        "Description": "DEPOSITOR NUMBER "
    },
    {
        "Code": "DF ",
        "Description": "DEFENSE FEDERAL ACQUISITION REGULATIONS (DFAR) "
    },
    {
        "Code": "DG ",
        "Description": "DRAWING NUMBER "
    },
    {
        "Code": "DH ",
        "Description": "DRUG ENFORCEMENT ADMINISTRATION NUMBER "
    },
    {
        "Code": "DHH ",
        "Description": "DEPARTMENT OF HEALTH AND HUMAN SERVICES ACQUISITIO "
    },
    {
        "Code": "DI ",
        "Description": "DISTRIBUTOR INVOICE NUMBER "
    },
    {
        "Code": "DIS ",
        "Description": "DISTRICT NUMBER "
    },
    {
        "Code": "DJ ",
        "Description": "DELIVERY TICKET NUMBER "
    },
    {
        "Code": "DK ",
        "Description": "DOCK NUMBER "
    },
    {
        "Code": "DL ",
        "Description": "SELLER'S DEBIT MEMO "
    },
    {
        "Code": "DM ",
        "Description": "ASSOCIATED PRODUCT NUMBER "
    },
    {
        "Code": "DN ",
        "Description": "DRAFT NUMBER "
    },
    {
        "Code": "DNR ",
        "Description": "DEPOSIT NUMBER "
    },
    {
        "Code": "DNS ",
        "Description": "D-U-N-S+4 "
    },
    {
        "Code": "DO ",
        "Description": "DELIVERY ORDER NUMBER "
    },
    {
        "Code": "DOA ",
        "Description": "DEPARTMENT OF AGRICULTURE ACQUISITION REGULATION ( "
    },
    {
        "Code": "DOC ",
        "Description": "DEPARTMENT OF COMMERCE ACQUISITION REGULATION (CAR "
    },
    {
        "Code": "DOE ",
        "Description": "DEPARTMENT OF ENERGY ACQUISITION REGULATION (DEAR) "
    },
    {
        "Code": "DOI ",
        "Description": "DEPARTMENT OF INTERIOR ACQUISITION REGULATION (DIA "
    },
    {
        "Code": "DOJ ",
        "Description": "DEPARTMENT OF JUSTICE ACQUISITION REGULATION (JAR) "
    },
    {
        "Code": "DOL ",
        "Description": "DEPARTMENT OF LABOR ACQUISITION REGULATION (DOLAR) "
    },
    {
        "Code": "DON ",
        "Description": "DENSITY ORDER NUMBER "
    },
    {
        "Code": "DOS ",
        "Description": "DEPARTMENT OF STATE ACQUISITION REGULATION (DOSAR) "
    },
    {
        "Code": "DOT ",
        "Description": "DEPARTMENT OF TRANSPORTATION ACQUISITION REGULATIO "
    },
    {
        "Code": "DP ",
        "Description": "DEPARTMENT NUMBER "
    },
    {
        "Code": "DQ ",
        "Description": "DELIVERY QUOTE NUMBER "
    },
    {
        "Code": "DR ",
        "Description": "DOCK RECEIPT NUMBER "
    },
    {
        "Code": "DRN ",
        "Description": "DRAINHOLE NUMBER "
    },
    {
        "Code": "DS ",
        "Description": "DEFENSE PRIORITIES ALLOCATION SYSTEM (DPAS) PRIORI "
    },
    {
        "Code": "DSC ",
        "Description": "DEPARTURE FROM SPECIFICATION CLASS CODE "
    },
    {
        "Code": "DSI ",
        "Description": "DEPARTURE FROM SPECIFICATION NUMBER "
    },
    {
        "Code": "DST ",
        "Description": "DEPARTURE FROM SPECIFICATION TYPE CODE "
    },
    {
        "Code": "DT ",
        "Description": "DOWNSTREAM SHIPPER CONTRACT NUMBER "
    },
    {
        "Code": "DTS ",
        "Description": "DEPARTMENT OF THE TREASURY ACQUISITION/PROCUREMENT "
    },
    {
        "Code": "DU ",
        "Description": "DEPENDENTS INFORMATION "
    },
    {
        "Code": "DUN ",
        "Description": "D-U-N-S NUMBER DUN & BRADSTREET "
    },
    {
        "Code": "DV ",
        "Description": "DIVERSION AUTHORITY NUMBER "
    },
    {
        "Code": "DW ",
        "Description": "DEPOSIT SEQUENCE NUMBER "
    },
    {
        "Code": "DX ",
        "Description": "DEPARTMENT/AGENCY NUMBER "
    },
    {
        "Code": "DY ",
        "Description": "DEPARTMENT OF DEFENSE TRANSPORTATION SERVICE CODE "
    },
    {
        "Code": "DZ ",
        "Description": "CERTIFIED REGISTERED NURSE ANESTHETIST (CRNA) PROV "
    },
    {
        "Code": "E1 ",
        "Description": "EMERGENCY ORDER NUMBER "
    },
    {
        "Code": "E2 ",
        "Description": "PART CAUSING REPAIR NUMBER "
    },
    {
        "Code": "E3 ",
        "Description": "EXPANSION ON EFFECT OF CHANGE NUMBER "
    },
    {
        "Code": "E4 ",
        "Description": "CHARGE CARD NUMBER "
    },
    {
        "Code": "E5 ",
        "Description": "CLAIMANT'S CLAIM NUMBER "
    },
    {
        "Code": "E6 ",
        "Description": "BACKOUT PROCEDURE CODE "
    },
    {
        "Code": "E7 ",
        "Description": "SERVICE BULLETIN NUMBER "
    },
    {
        "Code": "E8 ",
        "Description": "SERVICE CONTRACT (COVERAGE) NUMBER "
    },
    {
        "Code": "E9 ",
        "Description": "ATTACHMENT CODE "
    },
    {
        "Code": "EA ",
        "Description": "MEDICAL RECORD IDENTIFICATION NUMBER "
    },
    {
        "Code": "EB ",
        "Description": "EMBARGO PERMIT NUMBER "
    },
    {
        "Code": "EC ",
        "Description": "CIRCULAR "
    },
    {
        "Code": "ED ",
        "Description": "EXPORT DECLARATION "
    },
    {
        "Code": "EDA ",
        "Description": "DEPARTMENT OF EDUCATION ACQUISITION REGULATION (ED "
    },
    {
        "Code": "EE ",
        "Description": "ELECTION DISTRICT "
    },
    {
        "Code": "EF ",
        "Description": "ELECTRONIC FUNDS TRANSFER ID NUMBER "
    },
    {
        "Code": "EG ",
        "Description": "ENDING SERIAL NUMBER "
    },
    {
        "Code": "EH ",
        "Description": "FINANCIAL CLASSIFICATION CODE "
    },
    {
        "Code": "EI ",
        "Description": "EMPLOYER'S IDENTIFICATION NUMBER "
    },
    {
        "Code": "EJ ",
        "Description": "PATIENT ACCOUNT NUMBER "
    },
    {
        "Code": "EK ",
        "Description": "HEALTHCARE MANPOWER SHORTAGE AREA (HMSA) FACILITY "
    },
    {
        "Code": "EL ",
        "Description": "ELECTRONIC DEVICE PIN NUMBER "
    },
    {
        "Code": "EM ",
        "Description": "ELECTRONIC PAYMENT REFERENCE NUMBER "
    },
    {
        "Code": "EMM ",
        "Description": "END MILE MARKER "
    },
    {
        "Code": "EN ",
        "Description": "EMBARGO NUMBER "
    },
    {
        "Code": "END ",
        "Description": "ENDORSEMENT NUMBER "
    },
    {
        "Code": "EO ",
        "Description": "SUBMITTER IDENTIFICATION NUMBER "
    },
    {
        "Code": "EP ",
        "Description": "EXPORT PERMIT NUMBER "
    },
    {
        "Code": "EPA ",
        "Description": "ENVIRONMENTAL PROTECTION AGENCY ACQUISITION REGULA "
    },
    {
        "Code": "EPB ",
        "Description": "ENVIRONMENTAL PROTECTION AGENCY TRANSPORTER IDENTI "
    },
    {
        "Code": "EQ ",
        "Description": "EQUIPMENT NUMBER "
    },
    {
        "Code": "ER ",
        "Description": "CONTAINER OR EQUIPMENT RECEIPT NUMBER "
    },
    {
        "Code": "ES ",
        "Description": "EMPLOYER'S SOCIAL SECURITY NUMBER "
    },
    {
        "Code": "ESN ",
        "Description": "ESTIMATE SEQUENCE NUMBER "
    },
    {
        "Code": "ET ",
        "Description": "EXCESS TRANSPORTATION "
    },
    {
        "Code": "EU ",
        "Description": "END USER'S PURCHASE ORDER NUMBER "
    },
    {
        "Code": "EV ",
        "Description": "RECEIVER IDENTIFICATION NUMBER "
    },
    {
        "Code": "EW ",
        "Description": "MAMMOGRAPHY CERTIFICATION NUMBER "
    },
    {
        "Code": "EX ",
        "Description": "ESTIMATE NUMBER "
    },
    {
        "Code": "EY ",
        "Description": "RECEIVER SUB-IDENTIFICATION NUMBER "
    },
    {
        "Code": "EZ ",
        "Description": "ELECTRONIC DATA INTERCHANGE AGREEMENT NUMBER "
    },
    {
        "Code": "F1 ",
        "Description": "VERSION CODE - NATIONAL "
    },
    {
        "Code": "F2 ",
        "Description": "VERSION CODE - LOCAL "
    },
    {
        "Code": "F3 ",
        "Description": "SUBMISSION NUMBER "
    },
    {
        "Code": "F4 ",
        "Description": "FACILITY CERTIFICATION NUMBER "
    },
    {
        "Code": "F5 ",
        "Description": "MEDICARE VERSION CODE "
    },
    {
        "Code": "F6 ",
        "Description": "HEALTH INSURANCE CLAIM (HIC) NUMBER "
    },
    {
        "Code": "F7 ",
        "Description": "NEW HEALTH INSURANCE CLAIM (HIC) NUMBER "
    },
    {
        "Code": "F8 ",
        "Description": "ORIGINAL REFERENCE NUMBER "
    },
    {
        "Code": "F9 ",
        "Description": "FREIGHT PAYOR REFERENCE NUMBER "
    },
    {
        "Code": "FA ",
        "Description": "FEDERAL ACQUISITION REGULATIONS (FAR) "
    },
    {
        "Code": "FB ",
        "Description": "FILE TRANSFER FORM NUMBER "
    },
    {
        "Code": "FC ",
        "Description": "FILER CODE ISSUED BY CUSTOMS "
    },
    {
        "Code": "FCN ",
        "Description": "ASSIGNED CONTRACT NUMBER "
    },
    {
        "Code": "FD ",
        "Description": "FILER CODE ISSUED BY BUREAU OF CENSUS "
    },
    {
        "Code": "FE ",
        "Description": "FAILURE MECHANISM NUMBER "
    },
    {
        "Code": "FF ",
        "Description": "FILM NUMBER "
    },
    {
        "Code": "FG ",
        "Description": "FUND IDENTIFICATION NUMBER "
    },
    {
        "Code": "FH ",
        "Description": "CLINIC NUMBER "
    },
    {
        "Code": "FI ",
        "Description": "FILE IDENTIFIER "
    },
    {
        "Code": "FJ ",
        "Description": "LINE ITEM CONTROL NUMBER "
    },
    {
        "Code": "FK ",
        "Description": "FINISH LOT NUMBER "
    },
    {
        "Code": "FL ",
        "Description": "FINE LINE CLASSIFICATION "
    },
    {
        "Code": "FLZ ",
        "Description": "FLOOD ZONE "
    },
    {
        "Code": "FM ",
        "Description": "FEDERAL MARITIME COMMISSION (FMC) FORWARDERS NUMBE "
    },
    {
        "Code": "FMP ",
        "Description": "FACILITY MEASUREMENT POINT NUMBER "
    },
    {
        "Code": "FN ",
        "Description": "FORWARDER'S/AGENT'S REFERENCE NUMBER "
    },
    {
        "Code": "FND ",
        "Description": "FINDER NUMBER "
    },
    {
        "Code": "FO ",
        "Description": "DRUG FORMULARY NUMBER "
    },
    {
        "Code": "FP ",
        "Description": "FORESTRY PERMIT NUMBER "
    },
    {
        "Code": "FQ ",
        "Description": "FORM NUMBER "
    },
    {
        "Code": "FR ",
        "Description": "FREIGHT BILL NUMBER "
    },
    {
        "Code": "FS ",
        "Description": "FINAL SEQUENCE NUMBER "
    },
    {
        "Code": "FSN ",
        "Description": "ASSIGNED SEQUENCE NUMBER "
    },
    {
        "Code": "FT ",
        "Description": "FOREIGN TRADE ZONE "
    },
    {
        "Code": "FTN ",
        "Description": "PREMARKET NOTIFICATION NUMBER "
    },
    {
        "Code": "FU ",
        "Description": "FUND CODE "
    },
    {
        "Code": "FV ",
        "Description": "HEALTH MAINTENANCE ORGANIZATION (HMO) REFERENCE NU "
    },
    {
        "Code": "FW ",
        "Description": "STATE LICENSE IDENTIFICATION NUMBER "
    },
    {
        "Code": "FWC ",
        "Description": "FINAL WORK CANDIDATE NUMBER "
    },
    {
        "Code": "FX ",
        "Description": "FAILURE ANALYSIS REPORT NUMBER "
    },
    {
        "Code": "FY ",
        "Description": "CLAIM OFFICE NUMBER "
    },
    {
        "Code": "FZ ",
        "Description": "PROCESSOR'S INVOICE NUMBER "
    },
    {
        "Code": "G1 ",
        "Description": "PRIOR AUTHORIZATION NUMBER "
    },
    {
        "Code": "G2 ",
        "Description": "PROVIDER COMMERCIAL NUMBER "
    },
    {
        "Code": "G3 ",
        "Description": "PREDETERMINATION OF BENEFITS IDENTIFICATION NUMBER "
    },
    {
        "Code": "G4 ",
        "Description": "PEER REVIEW ORGANIZATION (PRO) APPROVAL NUMBER "
    },
    {
        "Code": "G5 ",
        "Description": "PROVIDER SITE NUMBER "
    },
    {
        "Code": "G6 ",
        "Description": "PAYER ASSIGNED RESUBMISSION REFERENCE NUMBER "
    },
    {
        "Code": "G7 ",
        "Description": "RESUBMISSION REASON CODE "
    },
    {
        "Code": "G8 ",
        "Description": "RESUBMISSION NUMBER "
    },
    {
        "Code": "G9 ",
        "Description": "SECONDARY EMPLOYEE IDENTIFICATION NUMBER "
    },
    {
        "Code": "GA ",
        "Description": "GOVERNMENT ADVANCE PROGRESS "
    },
    {
        "Code": "GB ",
        "Description": "GRAIN BLOCK NUMBER "
    },
    {
        "Code": "GC ",
        "Description": "GOVERNMENT CONTRACT NUMBER "
    },
    {
        "Code": "GD ",
        "Description": "RETURN GOODS BILL OF LADING NUMBER "
    },
    {
        "Code": "GE ",
        "Description": "GEOGRAPHIC NUMBER "
    },
    {
        "Code": "GF ",
        "Description": "SPECIALTY LICENSE NUMBER "
    },
    {
        "Code": "GG ",
        "Description": "GAUGE TICKET NUMBER "
    },
    {
        "Code": "GH ",
        "Description": "IDENTIFICATION CARD SERIAL NUMBER "
    },
    {
        "Code": "GI ",
        "Description": "SECONDARY PROVIDER NUMBER "
    },
    {
        "Code": "GJ ",
        "Description": "CORNBORE CERTIFICATION NUMBER "
    },
    {
        "Code": "GK ",
        "Description": "THIRD PARTY REFERENCE NUMBER "
    },
    {
        "Code": "GL ",
        "Description": "GEOGRAPHIC DESTINATION ZONE NUMBER "
    },
    {
        "Code": "GM ",
        "Description": "LOAN ACQUISITION NUMBER "
    },
    {
        "Code": "GN ",
        "Description": "FOLDER NUMBER "
    },
    {
        "Code": "GO ",
        "Description": "EXHIBIT IDENTIFIER "
    },
    {
        "Code": "GP ",
        "Description": "GOVERNMENT PRIORITY NUMBER "
    },
    {
        "Code": "GQ ",
        "Description": "INTERNAL PURCHASE ORDER RELEASE NUMBER "
    },
    {
        "Code": "GR ",
        "Description": "GRAIN ORDER REFERENCE NUMBER "
    },
    {
        "Code": "49 ",
        "Description": ""
    },
    {
        "Code": "GS ",
        "Description": "GENERAL SERVICES ADMINISTRATION REGULATIONS (GSAR) "
    },
    {
        "Code": "GT ",
        "Description": "GOODS AND SERVICE TAX REGISTRATION NUMBER "
    },
    {
        "Code": "GU ",
        "Description": "INTERNAL PURCHASE ORDER ITEM NUMBER "
    },
    {
        "Code": "GV ",
        "Description": "THIRD PARTY PURCHASE ORDER NUMBER "
    },
    {
        "Code": "GW ",
        "Description": "THIRD PARTY PURCHASE ORDER RELEASE NUMBER "
    },
    {
        "Code": "GWS ",
        "Description": "GROUP WORK CANDIDATE SEQUENCE NUMBER "
    },
    {
        "Code": "GX ",
        "Description": "THIRD PARTY PURCHASE ORDER ITEM NUMBER "
    },
    {
        "Code": "GY ",
        "Description": "EMPTY REPOSITIONING NUMBER "
    },
    {
        "Code": "GZ ",
        "Description": "GENERAL LEDGER ACCOUNT "
    },
    {
        "Code": "H1 ",
        "Description": "HIGH FABRICATION AUTHORIZATION NUMBER "
    },
    {
        "Code": "H2 ",
        "Description": "HIGH RAW MATERIAL AUTHORIZATION NUMBER "
    },
    {
        "Code": "H3 ",
        "Description": "GRAVITY SOURCE METER NUMBER "
    },
    {
        "Code": "H4 ",
        "Description": "FEDERAL INFORMATION RESOURCES MANAGEMENT REGULATIO "
    },
    {
        "Code": "H5 ",
        "Description": "SPECIAL CLAUSE "
    },
    {
        "Code": "H6 ",
        "Description": "QUALITY CLAUSE "
    },
    {
        "Code": "H7 ",
        "Description": "STANDARD CLAUSE "
    },
    {
        "Code": "H8 ",
        "Description": "HOME MORTGAGE DISCLOSURE ACT (HMDA) CENSUS TRACT "
    },
    {
        "Code": "H9 ",
        "Description": "PAYMENT HISTORY REFERENCE NUMBER "
    },
    {
        "Code": "HA ",
        "Description": "COMPETENT AUTHORITY "
    },
    {
        "Code": "HB ",
        "Description": "BILL & HOLD INVOICE NUMBER "
    },
    {
        "Code": "HC ",
        "Description": "HEAT CODE "
    },
    {
        "Code": "HD ",
        "Description": "DEPARTMENT OF TRANSPORTATION HAZARDOUS NUMBER "
    },
    {
        "Code": "HE ",
        "Description": "HAZARDOUS EXEMPTION NUMBER "
    },
    {
        "Code": "HF ",
        "Description": "ENGINEERING DATA LIST "
    },
    {
        "Code": "HG ",
        "Description": "CIVIL ACTION NUMBER "
    },
    {
        "Code": "HH ",
        "Description": "FISCAL CODE "
    },
    {
        "Code": "HHT ",
        "Description": "TYPE OF HOUSEHOLD GOODS CODE "
    },
    {
        "Code": "HI ",
        "Description": "HEALTH INDUSTRY NUMBER (HIN) "
    },
    {
        "Code": "HJ ",
        "Description": "IDENTITY CARD NUMBER "
    },
    {
        "Code": "HK ",
        "Description": "JUDGMENT NUMBER "
    },
    {
        "Code": "HL ",
        "Description": "SIREN NUMBER "
    },
    {
        "Code": "HM ",
        "Description": "SIRET NUMBER "
    },
    {
        "Code": "HMB ",
        "Description": "HOME MORTGAGE DISCLOSURE ACT BLOCK NUMBER AREA "
    },
    {
        "Code": "HN ",
        "Description": "HAZARDOUS CERTIFICATION NUMBER "
    },
    {
        "Code": "HO ",
        "Description": "SHIPPER'S HAZARDOUS NUMBER "
    },
    {
        "Code": "HP ",
        "Description": "PACK & HOLD INVOICE NUMBER "
    },
    {
        "Code": "HPI ",
        "Description": "HEALTH CARE FINANCING ADMINISTRATION NATIONAL PROV "
    },
    {
        "Code": "HQ ",
        "Description": "REINSURANCE REFERENCE "
    },
    {
        "Code": "HR ",
        "Description": "HORSEPOWER "
    },
    {
        "Code": "HS ",
        "Description": "HARMONIZED CODE SYSTEM (CANADA) "
    },
    {
        "Code": "HT ",
        "Description": "CODE OF FEDERAL REGULATIONS "
    },
    {
        "Code": "HU ",
        "Description": "TYPE OF ESCROW NUMBER "
    },
    {
        "Code": "HUD ",
        "Description": "DEPARTMENT OF HOUSING AND URBAN DEVELOPMENT ACQUIS "
    },
    {
        "Code": "HV ",
        "Description": "ESCROW FILE NUMBER "
    },
    {
        "Code": "HW ",
        "Description": "HIGH/WIDE FILE NUMBER "
    },
    {
        "Code": "HX ",
        "Description": "AUTO LOSS ITEM NUMBER "
    },
    {
        "Code": "HY ",
        "Description": "PROPERTY LOSS ITEM NUMBER "
    },
    {
        "Code": "HZ ",
        "Description": "TAX AGENCY NUMBER (MERS [MORTGAGE ELECTRONIC REGIS "
    },
    {
        "Code": "I1 ",
        "Description": "OWNING BUREAU IDENTIFICATION NUMBER "
    },
    {
        "Code": "I2 ",
        "Description": "INTERSTATE COMMERCE COMMISSION (ICC) ACCOUNT NUMBE "
    },
    {
        "Code": "I3 ",
        "Description": "NON-AMERICAN IDENTIFICATION NUMBER "
    },
    {
        "Code": "I4 ",
        "Description": "CREDIT COUNSELING IDENTIFICATION NUMBER "
    },
    {
        "Code": "I5 ",
        "Description": "INVOICE IDENTIFICATION "
    },
    {
        "Code": "I7 ",
        "Description": "CREDIT REPORT NUMBER "
    },
    {
        "Code": "I8 ",
        "Description": "SOCIAL INSURANCE NUMBER "
    },
    {
        "Code": "I9 ",
        "Description": "POLLUTANT "
    },
    {
        "Code": "IA ",
        "Description": "INTERNAL VENDOR NUMBER "
    },
    {
        "Code": "IB ",
        "Description": "IN BOND NUMBER "
    },
    {
        "Code": "IC ",
        "Description": "INBOUND-TO PARTY "
    },
    {
        "Code": "ICD ",
        "Description": "ICD-9-CM (INTERNATIONAL CLASSIFICATION OF DISEASES "
    },
    {
        "Code": "ID ",
        "Description": "INSURANCE CERTIFICATE NUMBER "
    },
    {
        "Code": "IE ",
        "Description": "INTERCHANGE AGREEMENT NUMBER "
    },
    {
        "Code": "IF ",
        "Description": "ISSUE NUMBER "
    },
    {
        "Code": "IFT ",
        "Description": "INTERNATIONAL FUEL TAX AGREEMENT ACCOUNT NUMBER "
    },
    {
        "Code": "IG ",
        "Description": "INSURANCE POLICY NUMBER "
    },
    {
        "Code": "IH ",
        "Description": "INITIAL DEALER CLAIM NUMBER "
    },
    {
        "Code": "II ",
        "Description": "INITIAL SAMPLE INSPECTION REPORT NUMBER "
    },
    {
        "Code": "IID ",
        "Description": "IMAGE IDENTIFIER "
    },
    {
        "Code": "IJ ",
        "Description": "STANDARD INDUSTRY CLASSIFICATION (SIC) CODE "
    },
    {
        "Code": "IK ",
        "Description": "INVOICE NUMBER "
    },
    {
        "Code": "IL ",
        "Description": "INTERNAL ORDER NUMBER "
    },
    {
        "Code": "IM ",
        "Description": "INTERGOVERNMENTAL MARITIME ORGANIZATION (IMO) NUMB "
    },
    {
        "Code": "IMP ",
        "Description": "INTEGRATED MASTER PLAN (IMP) "
    },
    {
        "Code": "IMS ",
        "Description": "INTEGRATED MASTER SCHEDULE (IMS) "
    },
    {
        "Code": "IN ",
        "Description": "CONSIGNEE'S INVOICE NUMBER "
    },
    {
        "Code": "IND ",
        "Description": "INVESTIGATORIAL NEW DRUG NUMBER "
    },
    {
        "Code": "IO ",
        "Description": "INBOUND-TO OR OUTBOUND-FROM PARTY "
    },
    {
        "Code": "IP ",
        "Description": "INSPECTION REPORT NUMBER "
    },
    {
        "Code": "IQ ",
        "Description": "END ITEM "
    },
    {
        "Code": "IR ",
        "Description": "INTRA PLANT ROUTING "
    },
    {
        "Code": "IRN ",
        "Description": "IMPORTER'S REFERENCE NUMBER TO LETTER OF CREDIT "
    },
    {
        "Code": "IRP ",
        "Description": "INTERNATIONAL REGISTRATION PLAN ACCOUNT NUMBER "
    },
    {
        "Code": "IS ",
        "Description": "INVOICE NUMBER SUFFIX "
    },
    {
        "Code": "ISC ",
        "Description": "INTERNATIONAL STANDARD INDUSTRIAL CLASSIFICATION ( "
    },
    {
        "Code": "ISN ",
        "Description": "INTERNATIONAL REGISTRATION PLAN STICKER NUMBER "
    },
    {
        "Code": "ISS ",
        "Description": "INSPECTION AND SURVEY SEQUENCE NUMBER "
    },
    {
        "Code": "IT ",
        "Description": "INTERNAL CUSTOMER NUMBER "
    },
    {
        "Code": "IU ",
        "Description": "BARGE PERMIT NUMBER "
    },
    {
        "Code": "IV ",
        "Description": "SELLER'S INVOICE NUMBER "
    },
    {
        "Code": "IW ",
        "Description": "PART INTERCHANGEABILITY "
    },
    {
        "Code": "IX ",
        "Description": "ITEM NUMBER "
    },
    {
        "Code": "IZ ",
        "Description": "INSURED PARCEL POST NUMBER "
    },
    {
        "Code": "J0 ",
        "Description": "PROCEEDING "
    },
    {
        "Code": "J1 ",
        "Description": "CREDITOR "
    },
    {
        "Code": "J2 ",
        "Description": "ATTORNEY "
    },
    {
        "Code": "J3 ",
        "Description": "JUDGE "
    },
    {
        "Code": "J4 ",
        "Description": "TRUSTEE "
    },
    {
        "Code": "J5 ",
        "Description": "ORIGINATING CASE "
    },
    {
        "Code": "J6 ",
        "Description": "ADVERSARY CASE "
    },
    {
        "Code": "J7 ",
        "Description": "LEAD CASE "
    },
    {
        "Code": "J8 ",
        "Description": "JOINTLY ADMINISTERED CASE "
    },
    {
        "Code": "J9 ",
        "Description": "SUBSTANTIVELY CONSOLIDATED CASE "
    },
    {
        "Code": "JA ",
        "Description": "BEGINNING JOB SEQUENCE NUMBER "
    },
    {
        "Code": "JB ",
        "Description": "JOB (PROJECT) NUMBER "
    },
    {
        "Code": "JC ",
        "Description": "REVIEW "
    },
    {
        "Code": "JD ",
        "Description": "USER IDENTIFICATION "
    },
    {
        "Code": "JE ",
        "Description": "ENDING JOB SEQUENCE NUMBER "
    },
    {
        "Code": "JF ",
        "Description": "AUTOMATED UNDERWRITING REFERENCE NUMBER "
    },
    {
        "Code": "JH ",
        "Description": "TAG "
    },
    {
        "Code": "JI ",
        "Description": "MULTIPLE LISTING SERVICE AREA "
    },
    {
        "Code": "JK ",
        "Description": "MULTIPLE LISTING SERVICE SUB-AREA "
    },
    {
        "Code": "JL ",
        "Description": "PACKET "
    },
    {
        "Code": "JM ",
        "Description": "MULTIPLE LISTING SERVICE MAP X COORDINATE "
    },
    {
        "Code": "JN ",
        "Description": "MULTIPLE LISTING SERVICE MAP Y COORDINATE "
    },
    {
        "Code": "JO ",
        "Description": "MULTIPLE LISTING NUMBER "
    },
    {
        "Code": "JP ",
        "Description": "MULTIPLE LISTING SERVICE BOOK TYPE "
    },
    {
        "Code": "JQ ",
        "Description": "ELEVATION "
    },
    {
        "Code": "JR ",
        "Description": "PROPERTY COMPONENT LOCATION "
    },
    {
        "Code": "JS ",
        "Description": "JOB SEQUENCE NUMBER "
    },
    {
        "Code": "JT ",
        "Description": "PRIOR TAX IDENTIFICATION NUMBER (TIN) "
    },
    {
        "Code": "JU ",
        "Description": "PRIOR PHONE NUMBER "
    },
    {
        "Code": "JV ",
        "Description": "PRIOR HEALTH INDUSTRY NUMBER "
    },
    {
        "Code": "JW ",
        "Description": "PRIOR UNIVERSAL PROVIDER IDENTIFICATION NUMBER (UP "
    },
    {
        "Code": "JX ",
        "Description": "PRIOR POSTAL ZIP CODE "
    },
    {
        "Code": "JY ",
        "Description": "ORIGIN OF SHIPMENT HARMONIZED-BASED CODE "
    },
    {
        "Code": "JZ ",
        "Description": "GOVERNING CLASS CODE "
    },
    {
        "Code": "K0 ",
        "Description": "APPROVAL CODE "
    },
    {
        "Code": "K1 ",
        "Description": "FOREIGN MILITARY SALES NOTICE NUMBER "
    },
    {
        "Code": "K2 ",
        "Description": "CERTIFIED MAIL NUMBER "
    },
    {
        "Code": "K3 ",
        "Description": "REGISTERED MAIL NUMBER "
    },
    {
        "Code": "K4 ",
        "Description": "CRITICALITY DESIGNATOR "
    },
    {
        "Code": "K5 ",
        "Description": "TASK ORDER "
    },
    {
        "Code": "K6 ",
        "Description": "PURCHASE DESCRIPTION "
    },
    {
        "Code": "K7 ",
        "Description": "PARAGRAPH NUMBER "
    },
    {
        "Code": "K8 ",
        "Description": "PROJECT PARAGRAPH NUMBER "
    },
    {
        "Code": "K9 ",
        "Description": "INQUIRY REQUEST NUMBER "
    },
    {
        "Code": "KA ",
        "Description": "DISTRIBUTION LIST "
    },
    {
        "Code": "KB ",
        "Description": "BEGINNING KANBAN SERIAL NUMBER "
    },
    {
        "Code": "KC ",
        "Description": "EXHIBIT DISTRIBUTION LIST "
    },
    {
        "Code": "KD ",
        "Description": "SPECIAL INSTRUCTIONS NUMBER "
    },
    {
        "Code": "KE ",
        "Description": "ENDING KANBAN SERIAL NUMBER "
    },
    {
        "Code": "KG ",
        "Description": "FORECLOSING STATUS "
    },
    {
        "Code": "KH ",
        "Description": "TYPE OF LAW SUIT "
    },
    {
        "Code": "KI ",
        "Description": "TYPE OF OUTSTANDING JUDGMENT "
    },
    {
        "Code": "KJ ",
        "Description": "TAX LIEN JURISDICTION "
    },
    {
        "Code": "KK ",
        "Description": "DELIVERY REFERENCE "
    },
    {
        "Code": "KL ",
        "Description": "CONTRACT REFERENCE "
    },
    {
        "Code": "KM ",
        "Description": "RENTAL ACCOUNT NUMBER "
    },
    {
        "Code": "KN ",
        "Description": "CENSUS AUTOMATED FILES ID "
    },
    {
        "Code": "KO ",
        "Description": "CUSTOMS DRAWBACK ENTRY NUMBER "
    },
    {
        "Code": "KP ",
        "Description": "HEALTH CERTIFICATE NUMBER "
    },
    {
        "Code": "KQ ",
        "Description": "PROCURING AGENCY "
    },
    {
        "Code": "KR ",
        "Description": "RESPONSE TO A REQUEST FOR QUOTATION REFERENCE "
    },
    {
        "Code": "KS ",
        "Description": "SOLICITATION "
    },
    {
        "Code": "KT ",
        "Description": "REQUEST FOR QUOTATION REFERENCE "
    },
    {
        "Code": "KU ",
        "Description": "OFFICE SYMBOL "
    },
    {
        "Code": "KV ",
        "Description": "DISTRIBUTION STATEMENT CODE "
    },
    {
        "Code": "KW ",
        "Description": "CERTIFICATION "
    },
    {
        "Code": "KX ",
        "Description": "REPRESENTATION "
    },
    {
        "Code": "KY ",
        "Description": "SITE SPECIFIC PROCEDURES "
    },
    {
        "Code": "KZ ",
        "Description": "MASTER SOLICITATION PROCEDURES "
    },
    {
        "Code": "L1 ",
        "Description": "LETTERS OR NOTES "
    },
    {
        "Code": "L2 ",
        "Description": "LOCATION ON PRODUCT CODE "
    },
    {
        "Code": "L3 ",
        "Description": "LABOR OPERATION NUMBER "
    },
    {
        "Code": "L4 ",
        "Description": "PROPOSAL PARAGRAPH NUMBER "
    },
    {
        "Code": "L5 ",
        "Description": "SUBEXHIBIT LINE ITEM NUMBER "
    },
    {
        "Code": "L6 ",
        "Description": "SUBCONTRACT LINE ITEM NUMBER "
    },
    {
        "Code": "L7 ",
        "Description": "CUSTOMER'S RELEASE NUMBER "
    },
    {
        "Code": "L8 ",
        "Description": "CONSIGNEE'S RELEASE NUMBER "
    },
    {
        "Code": "L9 ",
        "Description": "CUSTOMER'S PART NUMBER "
    },
    {
        "Code": "LA ",
        "Description": "SHIPPING LABEL SERIAL NUMBER "
    },
    {
        "Code": "LB ",
        "Description": "LOCKBOX "
    },
    {
        "Code": "LC ",
        "Description": "LEASE NUMBER "
    },
    {
        "Code": "LD ",
        "Description": "LOAN NUMBER "
    },
    {
        "Code": "LE ",
        "Description": "LENDER ENTITY NUMBER "
    },
    {
        "Code": "LEN ",
        "Description": "LOCATION EXCEPTION ORDER NUMBER "
    },
    {
        "Code": "LF ",
        "Description": "ASSEMBLY LINE FEED LOCATION "
    },
    {
        "Code": "LG ",
        "Description": "LEASE SCHEDULE NUMBER "
    },
    {
        "Code": "LH ",
        "Description": "LONGITUDE EXPRESSED IN SECONDS "
    },
    {
        "Code": "LI ",
        "Description": "LINE ITEM IDENTIFIER (SELLER'S) "
    },
    {
        "Code": "LIC ",
        "Description": "HEALTH INDUSTRY BUSINESS COMMUNICATIONS COUNCIL (H "
    },
    {
        "Code": "LJ ",
        "Description": "LOCAL JURISDICTION "
    },
    {
        "Code": "LK ",
        "Description": "LONGITUDE EXPRESSED IN DEGREES "
    },
    {
        "Code": "LL ",
        "Description": "LATITUDE EXPRESSED IN SECONDS "
    },
    {
        "Code": "LM ",
        "Description": "PRODUCT PERIOD FOR WHICH LABOR COSTS ARE FIRM "
    },
    {
        "Code": "LN ",
        "Description": "NON PICKUP LIMITED TARIFF NUMBER "
    },
    {
        "Code": "LO ",
        "Description": "LOAD PLANNING NUMBER "
    },
    {
        "Code": "LOI ",
        "Description": "LOGICAL OBSERVATION IDENTIFIER NAMES AND CODES (LO "
    },
    {
        "Code": "LP ",
        "Description": "FOR PICKUP LIMITED FREIGHT TARIFF NUMBER "
    },
    {
        "Code": "LQ ",
        "Description": "LATITUDE EXPRESSED IN DEGREES "
    },
    {
        "Code": "LR ",
        "Description": "LOCAL STUDENT IDENTIFICATION NUMBER "
    },
    {
        "Code": "LS ",
        "Description": "BAR-CODED SERIAL NUMBER "
    },
    {
        "Code": "LSD ",
        "Description": "LOGISTICS SUPPORT DOCUMENTATION TYPE CODE "
    },
    {
        "Code": "LT ",
        "Description": "LOT NUMBER "
    },
    {
        "Code": "LU ",
        "Description": "LOCATION NUMBER "
    },
    {
        "Code": "LV ",
        "Description": "LICENSE PLATE NUMBER "
    },
    {
        "Code": "LVO ",
        "Description": "LEVYING OFFICER IDENTIFICATION "
    },
    {
        "Code": "LW ",
        "Description": "LOCATION WITHIN EQUIPMENT "
    },
    {
        "Code": "LX ",
        "Description": "QUALIFIED PRODUCTS LIST "
    },
    {
        "Code": "LY ",
        "Description": "DESTINATION OF SHIPMENT HARMONIZED-BASED CODE "
    },
    {
        "Code": "LZ ",
        "Description": "LENDER ACCOUNT NUMBER "
    },
    {
        "Code": "M1 ",
        "Description": "MATERIAL STORAGE LOCATION "
    },
    {
        "Code": "M2 ",
        "Description": "MAJOR FORCE PROGRAM "
    },
    {
        "Code": "M3 ",
        "Description": "CROP YEAR "
    },
    {
        "Code": "M5 ",
        "Description": "LEASE AGREEMENT AMENDMENT NUMBER - MASTER "
    },
    {
        "Code": "M6 ",
        "Description": "MILITARY ORDNANCE SECURITY RISK NUMBER "
    },
    {
        "Code": "M7 ",
        "Description": "MEDICAL ASSISTANCE CATEGORY "
    },
    {
        "Code": "M8 ",
        "Description": "LIMITED PARTNERSHIP IDENTIFICATION NUMBER "
    },
    {
        "Code": "M9 ",
        "Description": "TAX SHELTER NUMBER "
    },
    {
        "Code": "MA ",
        "Description": "SHIP NOTICE/MANIFEST NUMBER "
    },
    {
        "Code": "MB ",
        "Description": "MASTER BILL OF LADING "
    },
    {
        "Code": "MBX ",
        "Description": "MAILBOX "
    },
    {
        "Code": "MC ",
        "Description": "MICROFILM NUMBER "
    },
    {
        "Code": "MCI ",
        "Description": "MOTOR CARRIER IDENTIFICATION NUMBER "
    },
    {
        "Code": "MD ",
        "Description": "MAGAZINE CODE "
    },
    {
        "Code": "MDN ",
        "Description": "HAZARDOUS WASTE MANIFEST DOCUMENT NUMBER "
    },
    {
        "Code": "ME ",
        "Description": "MESSAGE ADDRESS OR ID "
    },
    {
        "Code": "MF ",
        "Description": "MANUFACTURERS PART NUMBER "
    },
    {
        "Code": "MG ",
        "Description": "METER NUMBER "
    },
    {
        "Code": "MH ",
        "Description": "MANUFACTURING ORDER NUMBER "
    },
    {
        "Code": "MI ",
        "Description": "MILL ORDER NUMBER "
    },
    {
        "Code": "MJ ",
        "Description": "MODEL NUMBER "
    },
    {
        "Code": "MK ",
        "Description": "MANIFEST KEY NUMBER "
    },
    {
        "Code": "ML ",
        "Description": "MILITARY RANK/CIVILIAN PAY GRADE NUMBER "
    },
    {
        "Code": "MM ",
        "Description": "MASTER LEASE AGREEMENT NUMBER "
    },
    {
        "Code": "MN ",
        "Description": "MICR NUMBER "
    },
    {
        "Code": "MO ",
        "Description": "MANUFACTURING OPERATION NUMBER "
    },
    {
        "Code": "MP ",
        "Description": "MULTIPLE P.O.S OF AN INVOICE "
    },
    {
        "Code": "MQ ",
        "Description": "METER PROVING REPORT NUMBER "
    },
    {
        "Code": "MR ",
        "Description": "MERCHANDISE TYPE CODE "
    },
    {
        "Code": "MS ",
        "Description": "MANUFACTURER'S MATERIAL SAFETY DATA SHEET NUMBER "
    },
    {
        "Code": "MSL ",
        "Description": "MAIL SLOT "
    },
    {
        "Code": "MT ",
        "Description": "METER TICKET NUMBER "
    },
    {
        "Code": "MU ",
        "Description": "MILITARY SPECIFICATION (MILSPEC) NUMBER "
    },
    {
        "Code": "MV ",
        "Description": "MIGRANT NUMBER "
    },
    {
        "Code": "MW ",
        "Description": "MILITARY CALL NUMBER "
    },
    {
        "Code": "MX ",
        "Description": "MATERIAL CHANGE NOTICE NUMBER "
    },
    {
        "Code": "MY ",
        "Description": "MODEL YEAR NUMBER "
    },
    {
        "Code": "MZ ",
        "Description": "MAINTENANCE REQUEST NUMBER "
    },
    {
        "Code": "MZO ",
        "Description": "MULTIPLE ZONE ORDER NUMBER "
    },
    {
        "Code": "N0 ",
        "Description": "NOMINATION NUMBER "
    },
    {
        "Code": "N1 ",
        "Description": "LOCAL SCHOOL COURSE NUMBER "
    },
    {
        "Code": "N2 ",
        "Description": "LOCAL SCHOOL DISTRICT COURSE NUMBER "
    },
    {
        "Code": "N3 ",
        "Description": "STATEWIDE COURSE NUMBER "
    },
    {
        "Code": "N4 ",
        "Description": "UNITED STATES DEPARTMENT OF EDUCATION "
    },
    {
        "Code": "N5 ",
        "Description": "PROVIDER PLAN NETWORK IDENTIFICATION NUMBER "
    },
    {
        "Code": "N6 ",
        "Description": "PLAN NETWORK IDENTIFICATION NUMBER "
    },
    {
        "Code": "N7 ",
        "Description": "FACILITY NETWORK IDENTIFICATION NUMBER "
    },
    {
        "Code": "N8 ",
        "Description": "SECONDARY HEALTH INSURANCE IDENTIFICATION NUMBER "
    },
    {
        "Code": "N9 ",
        "Description": "DATA AUTHENTICATION NUMBER "
    },
    {
        "Code": "NA ",
        "Description": "NORTH AMERICAN HAZARDOUS CLASSIFICATION NUMBER "
    },
    {
        "Code": "NAS ",
        "Description": "NATIONAL AERONAUTICS AND SPACE ADMINISTRATION FAR "
    },
    {
        "Code": "NB ",
        "Description": "LETTER OF CREDIT NUMBER "
    },
    {
        "Code": "NC ",
        "Description": "SECONDARY COVERAGE COMPANY NUMBER "
    },
    {
        "Code": "ND ",
        "Description": "LETTER OF CREDIT DRAFT NUMBER "
    },
    {
        "Code": "NDA ",
        "Description": "ABBREVIATED NEW DRUG APPLICATION NUMBER "
    },
    {
        "Code": "NDB ",
        "Description": "NEW DRUG APPLICATION NUMBER "
    },
    {
        "Code": "NE ",
        "Description": "LEASE RIDER NUMBER "
    },
    {
        "Code": "NF ",
        "Description": "NATIONAL ASSOCIATION OF INSURANCE COMMISSIONERS (N "
    },
    {
        "Code": "NFC ",
        "Description": "NATIONAL FLOOD INSURANCE PROGRAM COMMUNITY NAME "
    },
    {
        "Code": "NFD ",
        "Description": "NATIONAL FLOOD INSURANCE PROGRAM COUNTY "
    },
    {
        "Code": "NFM ",
        "Description": "NATIONAL FLOOD INSURANCE PROGRAM MAP NUMBER "
    },
    {
        "Code": "NFN ",
        "Description": "NATIONAL FLOOD INSURANCE PROGRAM COMMUNITY NUMBER "
    },
    {
        "Code": "NFS ",
        "Description": "NATIONAL FLOOD INSURANCE PROGRAM STATE "
    },
    {
        "Code": "NG ",
        "Description": "NATURAL GAS POLICY ACT CATEGORY CODE "
    },
    {
        "Code": "NH ",
        "Description": "RATE CARD NUMBER "
    },
    {
        "Code": "NI ",
        "Description": "MILITARY STANDARD (MIL-STD) NUMBER "
    },
    {
        "Code": "NJ ",
        "Description": "TECHNICAL DOCUMENT NUMBER "
    },
    {
        "Code": "NK ",
        "Description": "PRIOR CASE "
    },
    {
        "Code": "NL ",
        "Description": "TECHNICAL ORDER NUMBER "
    },
    {
        "Code": "NM ",
        "Description": "DISCOUNTER REGISTRATION NUMBER "
    },
    {
        "Code": "NN ",
        "Description": "NONCONFORMANCE REPORT NUMBER "
    },
    {
        "Code": "NO ",
        "Description": "NO OT5 AUTHORITY-ZERO MILEAGE RATE "
    },
    {
        "Code": "NP ",
        "Description": "PARTIAL PAYMENT NUMBER "
    },
    {
        "Code": "NQ ",
        "Description": "MEDICAID RECIPIENT IDENTIFICATION NUMBER "
    },
    {
        "Code": "NR ",
        "Description": "PROGRESS PAYMENT NUMBER "
    },
    {
        "Code": "NS ",
        "Description": "NATIONAL STOCK NUMBER "
    },
    {
        "Code": "NT ",
        "Description": "ADMINISTRATOR'S REFERENCE NUMBER "
    },
    {
        "Code": "NU ",
        "Description": "PENDING CASE "
    },
    {
        "Code": "NW ",
        "Description": "ASSOCIATED POLICY NUMBER "
    },
    {
        "Code": "NX ",
        "Description": "RELATED NONCONFORMANCE NUMBER "
    },
    {
        "Code": "NY ",
        "Description": "AGENT CLAIM NUMBER "
    },
    {
        "Code": "NZ ",
        "Description": "CRITICAL APPLICATION "
    },
    {
        "Code": "O1 ",
        "Description": "OUTER CONTINENTAL SHELF AREA CODE "
    },
    {
        "Code": "O2 ",
        "Description": "OUTER CONTINENTAL SHELF BLOCK NUMBER "
    },
    {
        "Code": "O5 ",
        "Description": "OT5 AUTHORITY-CONDITION OR RESTRICTION ON CAR HIRE "
    },
    {
        "Code": "O7 ",
        "Description": "ON-LINE PROCUREMENT AND ACCOUNTING CONTROL (OPAC) "
    },
    {
        "Code": "O8 ",
        "Description": "ORIGINAL FILING "
    },
    {
        "Code": "O9 ",
        "Description": "CONTINUATION FILING "
    },
    {
        "Code": "OA ",
        "Description": "OUTLET NUMBER "
    },
    {
        "Code": "OB ",
        "Description": "OCEAN BILL OF LADING "
    },
    {
        "Code": "OC ",
        "Description": "OCEAN CONTAINER NUMBER "
    },
    {
        "Code": "OD ",
        "Description": "ORIGINAL RETURN REQUEST REFERENCE NUMBER "
    },
    {
        "Code": "OE ",
        "Description": "OPEN AND PREPAID STATION LIST NUMBER "
    },
    {
        "Code": "OF ",
        "Description": "OPERATOR IDENTIFICATION NUMBER "
    },
    {
        "Code": "OG ",
        "Description": "TERMINATION FILING "
    },
    {
        "Code": "OH ",
        "Description": "ORIGIN HOUSE "
    },
    {
        "Code": "OI ",
        "Description": "ORIGINAL INVOICE NUMBER "
    },
    {
        "Code": "OJ ",
        "Description": "AMENDMENT FILING "
    },
    {
        "Code": "OK ",
        "Description": "OFFER GROUP "
    },
    {
        "Code": "OL ",
        "Description": "ORIGINAL SHIPPER'S BILL OF LADING NUMBER "
    },
    {
        "Code": "OM ",
        "Description": "OCEAN MANIFEST "
    },
    {
        "Code": "ON ",
        "Description": "DEALER ORDER NUMBER "
    },
    {
        "Code": "OP ",
        "Description": "ORIGINAL PURCHASE ORDER "
    },
    {
        "Code": "OQ ",
        "Description": "ORDER NUMBER "
    },
    {
        "Code": "OR ",
        "Description": "ORDER/PARAGRAPH NUMBER "
    },
    {
        "Code": "OS ",
        "Description": "OUTBOUND-FROM PARTY "
    },
    {
        "Code": "OT ",
        "Description": "SALES ALLOWANCE NUMBER "
    },
    {
        "Code": "NG ",
        "Description": "NATURAL GAS POLICY ACT CATEGORY CODE "
    },
    {
        "Code": "NH ",
        "Description": "RATE CARD NUMBER "
    },
    {
        "Code": "NI ",
        "Description": "MILITARY STANDARD (MIL-STD) NUMBER "
    },
    {
        "Code": "NJ ",
        "Description": "TECHNICAL DOCUMENT NUMBER "
    },
    {
        "Code": "NK ",
        "Description": "PRIOR CASE "
    },
    {
        "Code": "NL ",
        "Description": "TECHNICAL ORDER NUMBER "
    },
    {
        "Code": "NM ",
        "Description": "DISCOUNTER REGISTRATION NUMBER "
    },
    {
        "Code": "NN ",
        "Description": "NONCONFORMANCE REPORT NUMBER "
    },
    {
        "Code": "NO ",
        "Description": "NO OT5 AUTHORITY-ZERO MILEAGE RATE "
    },
    {
        "Code": "NP ",
        "Description": "PARTIAL PAYMENT NUMBER "
    },
    {
        "Code": "NQ ",
        "Description": "MEDICAID RECIPIENT IDENTIFICATION NUMBER "
    },
    {
        "Code": "NR ",
        "Description": "PROGRESS PAYMENT NUMBER "
    },
    {
        "Code": "NS ",
        "Description": "NATIONAL STOCK NUMBER "
    },
    {
        "Code": "NT ",
        "Description": "ADMINISTRATOR'S REFERENCE NUMBER "
    },
    {
        "Code": "NU ",
        "Description": "PENDING CASE "
    },
    {
        "Code": "NW ",
        "Description": "ASSOCIATED POLICY NUMBER "
    },
    {
        "Code": "NX ",
        "Description": "RELATED NONCONFORMANCE NUMBER "
    },
    {
        "Code": "NY ",
        "Description": "AGENT CLAIM NUMBER "
    },
    {
        "Code": "NZ ",
        "Description": "CRITICAL APPLICATION "
    },
    {
        "Code": "O1 ",
        "Description": "OUTER CONTINENTAL SHELF AREA CODE "
    },
    {
        "Code": "O2 ",
        "Description": "OUTER CONTINENTAL SHELF BLOCK NUMBER "
    },
    {
        "Code": "O5 ",
        "Description": "OT5 AUTHORITY-CONDITION OR RESTRICTION ON CAR HIRE "
    },
    {
        "Code": "O7 ",
        "Description": "ON-LINE PROCUREMENT AND ACCOUNTING CONTROL (OPAC) "
    },
    {
        "Code": "O8 ",
        "Description": "ORIGINAL FILING "
    },
    {
        "Code": "O9 ",
        "Description": "CONTINUATION FILING "
    },
    {
        "Code": "OA ",
        "Description": "OUTLET NUMBER "
    },
    {
        "Code": "OB ",
        "Description": "OCEAN BILL OF LADING "
    },
    {
        "Code": "OC ",
        "Description": "OCEAN CONTAINER NUMBER "
    },
    {
        "Code": "OD ",
        "Description": "ORIGINAL RETURN REQUEST REFERENCE NUMBER "
    },
    {
        "Code": "OE ",
        "Description": "OPEN AND PREPAID STATION LIST NUMBER "
    },
    {
        "Code": "OF ",
        "Description": "OPERATOR IDENTIFICATION NUMBER "
    },
    {
        "Code": "OG ",
        "Description": "TERMINATION FILING "
    },
    {
        "Code": "OH ",
        "Description": "ORIGIN HOUSE "
    },
    {
        "Code": "OI ",
        "Description": "ORIGINAL INVOICE NUMBER "
    },
    {
        "Code": "OJ ",
        "Description": "AMENDMENT FILING "
    },
    {
        "Code": "OK ",
        "Description": "OFFER GROUP "
    },
    {
        "Code": "OL ",
        "Description": "ORIGINAL SHIPPER'S BILL OF LADING NUMBER "
    },
    {
        "Code": "OM ",
        "Description": "OCEAN MANIFEST "
    },
    {
        "Code": "ON ",
        "Description": "DEALER ORDER NUMBER "
    },
    {
        "Code": "OP ",
        "Description": "ORIGINAL PURCHASE ORDER "
    },
    {
        "Code": "OQ ",
        "Description": "ORDER NUMBER "
    },
    {
        "Code": "OR ",
        "Description": "ORDER/PARAGRAPH NUMBER "
    },
    {
        "Code": "OS ",
        "Description": "OUTBOUND-FROM PARTY "
    },
    {
        "Code": "OT ",
        "Description": "SALES ALLOWANCE NUMBER "
    },
    {
        "Code": "PO ",
        "Description": "PURCHASE ORDER NUMBER "
    },
    {
        "Code": "POL ",
        "Description": "POLICY NUMBER "
    },
    {
        "Code": "PP ",
        "Description": "PURCHASE ORDER REVISION NUMBER "
    },
    {
        "Code": "PQ ",
        "Description": "PAYEE IDENTIFICATION "
    },
    {
        "Code": "PR ",
        "Description": "PRICE QUOTE NUMBER "
    },
    {
        "Code": "PRS ",
        "Description": "PREVIOUSLY REPORTED SOCIAL SECURITY NUMBER "
    },
    {
        "Code": "PRT ",
        "Description": "PRODUCT TYPE "
    },
    {
        "Code": "PS ",
        "Description": "PURCHASE ORDER NUMBER SUFFIX "
    },
    {
        "Code": "PSI ",
        "Description": "PREVIOUS SHIPMENT IDENTIFICATION NUMBER - CONTINUO "
    },
    {
        "Code": "PSL ",
        "Description": "NEXT SHIPMENT IDENTIFICATION NUMBER - CONTINUOUS M "
    },
    {
        "Code": "PSM ",
        "Description": "CREDIT CARD "
    },
    {
        "Code": "PSN ",
        "Description": "PROPOSED SEQUENCE NUMBER "
    },
    {
        "Code": "PT ",
        "Description": "PURCHASE OPTION AGREEMENT "
    },
    {
        "Code": "PTC ",
        "Description": "PATENT TYPE "
    },
    {
        "Code": "PU ",
        "Description": "PREVIOUS BILL OF LADING NUMBER "
    },
    {
        "Code": "PV ",
        "Description": "PRODUCT CHANGE INFORMATION NUMBER "
    },
    {
        "Code": "PW ",
        "Description": "PRIOR PURCHASE ORDER NUMBER "
    },
    {
        "Code": "PWC ",
        "Description": "PRELIMINARY WORK CANDIDATE NUMBER "
    },
    {
        "Code": "PWS ",
        "Description": "PROPOSED WORK CANDIDATE SEQUENCE NUMBER "
    },
    {
        "Code": "PX ",
        "Description": "PREVIOUS INVOICE NUMBER "
    },
    {
        "Code": "PY ",
        "Description": "PAYEE'S FINANCIAL INSTITUTION ACCOUNT NUMBER FOR C "
    },
    {
        "Code": "PZ ",
        "Description": "PRODUCT CHANGE NOTICE NUMBER "
    },
    {
        "Code": "Q1 ",
        "Description": "QUOTE NUMBER "
    },
    {
        "Code": "Q2 ",
        "Description": "STARTING PACKAGE NUMBER "
    },
    {
        "Code": "Q3 ",
        "Description": "ENDING PACKAGE NUMBER "
    },
    {
        "Code": "Q4 ",
        "Description": "PRIOR IDENTIFIER NUMBER "
    },
    {
        "Code": "Q5 ",
        "Description": "PROPERTY CONTROL NUMBER "
    },
    {
        "Code": "Q6 ",
        "Description": "RECALL NUMBER "
    },
    {
        "Code": "Q7 ",
        "Description": "RECEIVER CLAIM NUMBER "
    },
    {
        "Code": "Q8 ",
        "Description": "REGISTRATION NUMBER "
    },
    {
        "Code": "Q9 ",
        "Description": "REPAIR ORDER NUMBER "
    },
    {
        "Code": "QA ",
        "Description": "PRESS IDENTIFIER "
    },
    {
        "Code": "QB ",
        "Description": "PRESS FORM IDENTIFIER "
    },
    {
        "Code": "QC ",
        "Description": "PRODUCT SPECIFICATION DOCUMENT NUMBER "
    },
    {
        "Code": "QD ",
        "Description": "REPLACEMENT DRUG ENFORCEMENT ADMINISTRATION NUMBER "
    },
    {
        "Code": "QE ",
        "Description": "REPLACEMENT CUSTOMER REFERENCE NUMBER "
    },
    {
        "Code": "QF ",
        "Description": "QUALITY DISPOSITION AREA IDENTIFIER "
    },
    {
        "Code": "QG ",
        "Description": "REPLACEMENT ASSEMBLY MODEL NUMBER "
    },
    {
        "Code": "QH ",
        "Description": "REPLACEMENT ASSEMBLY SERIAL NUMBER "
    },
    {
        "Code": "QI ",
        "Description": "QUALITY INSPECTION AREA IDENTIFIER "
    },
    {
        "Code": "QJ ",
        "Description": "RETURN MATERIAL AUTHORIZATION NUMBER "
    },
    {
        "Code": "QK ",
        "Description": "SALES PROGRAM NUMBER "
    },
    {
        "Code": "QL ",
        "Description": "SERVICE AUTHORIZATION NUMBER "
    },
    {
        "Code": "QM ",
        "Description": "QUALITY REVIEW MATERIAL CRIB IDENTIFIER "
    },
    {
        "Code": "QN ",
        "Description": "STOP SEQUENCE NUMBER "
    },
    {
        "Code": "QO ",
        "Description": "SERVICE ESTIMATE NUMBER "
    },
    {
        "Code": "QP ",
        "Description": "SUBSTITUTE PART NUMBER "
    },
    {
        "Code": "QQ ",
        "Description": "UNIT NUMBER "
    },
    {
        "Code": "QR ",
        "Description": "QUALITY REPORT NUMBER "
    },
    {
        "Code": "QS ",
        "Description": "WARRANTY COVERAGE CODE "
    },
    {
        "Code": "QT ",
        "Description": "WARRANTY REGISTRATION NUMBER "
    },
    {
        "Code": "QU ",
        "Description": "CHANGE VERIFICATION PROCEDURE CODE "
    },
    {
        "Code": "QV ",
        "Description": "MAJOR SYSTEM AFFECTED CODE "
    },
    {
        "Code": "QW ",
        "Description": "NEW PART NUMBER "
    },
    {
        "Code": "QX ",
        "Description": "OLD PART NUMBER "
    },
    {
        "Code": "QY ",
        "Description": "SERVICE PERFORMED CODE "
    },
    {
        "Code": "QZ ",
        "Description": "REFERENCE DRAWING NUMBER "
    },
    {
        "Code": "R0 ",
        "Description": "REGIRISTO FEDERAL DE CONTRIBUYENTES (MEXICAN FEDER "
    },
    {
        "Code": "R1 ",
        "Description": "CURRENT REVISION NUMBER "
    },
    {
        "Code": "R2 ",
        "Description": "CANCELED REVISION NUMBER "
    },
    {
        "Code": "R3 ",
        "Description": "CORRECTION NUMBER "
    },
    {
        "Code": "R4 ",
        "Description": "TARIFF SECTION NUMBER "
    },
    {
        "Code": "R5 ",
        "Description": "TARIFF PAGE NUMBER "
    },
    {
        "Code": "R6 ",
        "Description": "TARIFF RULE NUMBER "
    },
    {
        "Code": "R7 ",
        "Description": "ACCOUNTS RECEIVABLE OPEN ITEM "
    },
    {
        "Code": "R8 ",
        "Description": "RENTAL AGREEMENT NUMBER "
    },
    {
        "Code": "R9 ",
        "Description": "REJECTION NUMBER "
    },
    {
        "Code": "RA ",
        "Description": "REPETITIVE CARGO SHIPMENT NUMBER "
    },
    {
        "Code": "RAA ",
        "Description": "RESTRICTED AVAILABILITY AUTHORIZATION "
    },
    {
        "Code": "RAN ",
        "Description": "RESTRICTED AVAILABILITY NUMBER "
    },
    {
        "Code": "RB ",
        "Description": "RATE CODE NUMBER "
    },
    {
        "Code": "RC ",
        "Description": "RAIL ROUTING CODE "
    },
    {
        "Code": "RD ",
        "Description": "REEL NUMBER "
    },
    {
        "Code": "RE ",
        "Description": "RELEASE NUMBER "
    },
    {
        "Code": "REC ",
        "Description": "RELATED CASE "
    },
    {
        "Code": "RF ",
        "Description": "EXPORT REFERENCE NUMBER "
    },
    {
        "Code": "RG ",
        "Description": "ROUTE ORDER NUMBER-DOMESTIC "
    },
    {
        "Code": "RGI ",
        "Description": "REGULATORY GUIDELINE IDENTIFIER "
    },
    {
        "Code": "RH ",
        "Description": "ROUTE ORDER NUMBER-EXPORT "
    },
    {
        "Code": "RI ",
        "Description": "RELEASE INVOICE NUMBER FOR PRIOR BILL AND HOLD "
    },
    {
        "Code": "RIG ",
        "Description": "RIG NUMBER "
    },
    {
        "Code": "RJ ",
        "Description": "ROUTE ORDER NUMBER-EMERGENCY "
    },
    {
        "Code": "RK ",
        "Description": "RACK TYPE NUMBER "
    },
    {
        "Code": "RL ",
        "Description": "RESERVE ASSEMBLY LINE FEED LOCATION "
    },
    {
        "Code": "RM ",
        "Description": "RAW MATERIAL SUPPLIER DUN & BRADSTREET NUMBER "
    },
    {
        "Code": "RN ",
        "Description": "RUN NUMBER "
    },
    {
        "Code": "RO ",
        "Description": "REPETITIVE BOOKING NUMBER "
    },
    {
        "Code": "RP ",
        "Description": "REPETITIVE PATTERN CODE "
    },
    {
        "Code": "RPP ",
        "Description": "RELATIVE PRIORITY "
    },
    {
        "Code": "RPT ",
        "Description": "REPORT NUMBER "
    },
    {
        "Code": "RQ ",
        "Description": "PURCHASE REQUISITION NUMBER "
    },
    {
        "Code": "RR ",
        "Description": "PAYER'S FINANCIAL INSTITUTION TRANSIT ROUTING NUMB "
    },
    {
        "Code": "RRS ",
        "Description": "RECONCILIATION REPORT SECTION IDENTIFICATION CODE "
    },
    {
        "Code": "RS ",
        "Description": "RETURNABLE CONTAINER SERIAL NUMBER "
    },
    {
        "Code": "RSN ",
        "Description": "RESERVATION NUMBER "
    },
    {
        "Code": "RT ",
        "Description": "PAYEE'S FINANCIAL INSTITUTION TRANSIT ROUTING NUMB "
    },
    {
        "Code": "RU ",
        "Description": "ROUTE NUMBER "
    },
    {
        "Code": "RV ",
        "Description": "RECEIVING NUMBER "
    },
    {
        "Code": "RW ",
        "Description": "REPETITIVE WAYBILL CODE (ORIGIN CARRIER "
    },
    {
        "Code": "RX ",
        "Description": "RESUBMIT NUMBER "
    },
    {
        "Code": "RY ",
        "Description": "REBATE NUMBER "
    },
    {
        "Code": "RZ ",
        "Description": "RETURNED GOODS AUTHORIZATION NUMBER "
    },
    {
        "Code": "S0 ",
        "Description": "SPECIAL APPROVAL "
    },
    {
        "Code": "S1 ",
        "Description": "ENGINEERING SPECIFICATION NUMBER "
    },
    {
        "Code": "S2 ",
        "Description": "DATA SOURCE "
    },
    {
        "Code": "S3 ",
        "Description": "SPECIFICATION NUMBER "
    },
    {
        "Code": "S4 ",
        "Description": "SHIPPERS BOND NUMBER "
    },
    {
        "Code": "S5 ",
        "Description": "ROUTING INSTRUCTION NUMBER "
    },
    {
        "Code": "S6 ",
        "Description": "STOCK NUMBER "
    },
    {
        "Code": "S7 ",
        "Description": "STACK TRAIN IDENTIFICATION "
    },
    {
        "Code": "S8 ",
        "Description": "SEAL OFF NUMBER "
    },
    {
        "Code": "S9 ",
        "Description": "SEAL ON NUMBER "
    },
    {
        "Code": "SA ",
        "Description": "SALESPERSON "
    },
    {
        "Code": "SB ",
        "Description": "SALES REGION NUMBER "
    },
    {
        "Code": "SBN ",
        "Description": "SURETY BOND NUMBER "
    },
    {
        "Code": "SC ",
        "Description": "SHIPPER CAR ORDER NUMBER "
    },
    {
        "Code": "SCA ",
        "Description": "STANDARD CARRIER ALPHA CODE (SCAC) "
    },
    {
        "Code": "SD ",
        "Description": "SUBDAY NUMBER "
    },
    {
        "Code": "SE ",
        "Description": "SERIAL NUMBER "
    },
    {
        "Code": "SEK ",
        "Description": "SEARCH KEY "
    },
    {
        "Code": "SES ",
        "Description": "SESSION "
    },
    {
        "Code": "SF ",
        "Description": "SHIP FROM "
    },
    {
        "Code": "SG ",
        "Description": "SAVINGS "
    },
    {
        "Code": "SH ",
        "Description": "SENDER DEFINED CLAUSE "
    },
    {
        "Code": "SHL ",
        "Description": "SHELF LIFE INDICATOR "
    },
    {
        "Code": "SI ",
        "Description": "SHIPPER'S IDENTIFYING NUMBER FOR SHIPMENT (SID) "
    },
    {
        "Code": "SJ ",
        "Description": "SET NUMBER "
    },
    {
        "Code": "SK ",
        "Description": "SERVICE CHANGE NUMBER "
    },
    {
        "Code": "SL ",
        "Description": "SALES/TERRITORY CODE "
    },
    {
        "Code": "SM ",
        "Description": "SALES OFFICE NUMBER "
    },
    {
        "Code": "SN ",
        "Description": "SEAL NUMBER "
    },
    {
        "Code": "SNH ",
        "Description": "SYSTEMATIZED NOMENCLATURE OF HUMAN AND VETERINARY "
    },
    {
        "Code": "SNV ",
        "Description": "STATE NON-RESIDENT VIOLATOR COMPACT "
    },
    {
        "Code": "SO ",
        "Description": "SHIPPER'S ORDER (INVOICE NUMBER) "
    },
    {
        "Code": "SP ",
        "Description": "SCAN LINE "
    },
    {
        "Code": "SPL ",
        "Description": "STANDARD POINT LOCATION CODE (SPLC) "
    },
    {
        "Code": "SPN ",
        "Description": "THEATER SCREEN NUMBER "
    },
    {
        "Code": "SQ ",
        "Description": "CONTAINER SEQUENCE NUMBER "
    },
    {
        "Code": "SR ",
        "Description": "SALES RESPONSIBILITY "
    },
    {
        "Code": "SS ",
        "Description": "SPLIT SHIPMENT NUMBER "
    },
    {
        "Code": "ST ",
        "Description": "STORE NUMBER "
    },
    {
        "Code": "STB ",
        "Description": "STANDARD TRANSPORTATION COMMODITY CODE (STCC) BRID "
    },
    {
        "Code": "STR ",
        "Description": "STANDARD TRANSPORTATION COMMODITY CODE (STCC) REPL "
    },
    {
        "Code": "SU ",
        "Description": "SPECIAL PROCESSING CODE "
    },
    {
        "Code": "SUB ",
        "Description": "TITLE REFERENCE "
    },
    {
        "Code": "SUO ",
        "Description": "SPACING UNIT ORDER NUMBER "
    },
    {
        "Code": "SV ",
        "Description": "SERVICE CHARGE NUMBER "
    },
    {
        "Code": "SW ",
        "Description": "SELLER'S SALE NUMBER "
    },
    {
        "Code": "SX ",
        "Description": "SERVICE INTERRUPT TRACKING NUMBER "
    },
    {
        "Code": "SY ",
        "Description": "SOCIAL SECURITY NUMBER "
    },
    {
        "Code": "SZ ",
        "Description": "SPECIFICATION REVISION "
    },
    {
        "Code": "T0 ",
        "Description": "DEALER TYPE IDENTIFICATION "
    },
    {
        "Code": "T1 ",
        "Description": "TAX EXCHANGE CODE "
    },
    {
        "Code": "T2 ",
        "Description": "TAX FORM CODE "
    },
    {
        "Code": "T3 ",
        "Description": "TAX SCHEDULE CODE "
    },
    {
        "Code": "T4 ",
        "Description": "SIGNAL CODE "
    },
    {
        "Code": "T5 ",
        "Description": "TRAILER USE AGREEMENTS "
    },
    {
        "Code": "T6 ",
        "Description": "TAX FILING "
    },
    {
        "Code": "T7 ",
        "Description": "AFFECTED SUBSYSTEM CODE "
    },
    {
        "Code": "T8 ",
        "Description": "DESCRIPTION OF CHANGE CODE "
    },
    {
        "Code": "T9 ",
        "Description": "DOCUMENTATION AFFECTED NUMBER "
    },
    {
        "Code": "TA ",
        "Description": "TELECOMMUNICATION CIRCUIT SUPPLEMENTAL ID "
    },
    {
        "Code": "TB ",
        "Description": "TRUCKER'S BILL OF LADING "
    },
    {
        "Code": "TC ",
        "Description": "VENDOR TERMS "
    },
    {
        "Code": "TD ",
        "Description": "REASON FOR CHANGE "
    },
    {
        "Code": "TDT ",
        "Description": "TECHNICAL DOCUMENTATION TYPE "
    },
    {
        "Code": "TE ",
        "Description": "FEDERAL MARITIME COMMISSION (FMC) TARIFF NUMBER "
    },
    {
        "Code": "TF ",
        "Description": "TRANSFER NUMBER "
    },
    {
        "Code": "TG ",
        "Description": "TRANSPORTATION CONTROL NUMBER (TCN) "
    },
    {
        "Code": "TH ",
        "Description": "TRANSPORTATION ACCOUNT CODE (TAC) "
    },
    {
        "Code": "TI ",
        "Description": "TIR NUMBER "
    },
    {
        "Code": "TIP ",
        "Description": "TECHNICAL INFORMATION PACKAGE "
    },
    {
        "Code": "TJ ",
        "Description": "FEDERAL TAXPAYER'S IDENTIFICATION NUMBER "
    },
    {
        "Code": "TK ",
        "Description": "TANK NUMBER "
    },
    {
        "Code": "TL ",
        "Description": "TAX LICENSE EXEMPTION "
    },
    {
        "Code": "TM ",
        "Description": "TRAVEL MANIFEST (ACI OR OTR) "
    },
    {
        "Code": "TN ",
        "Description": "TRANSACTION REFERENCE NUMBER "
    },
    {
        "Code": "TO ",
        "Description": "TERMINAL OPERATOR NUMBER "
    },
    {
        "Code": "TOC ",
        "Description": "TYPE OF COMMENT "
    },
    {
        "Code": "TP ",
        "Description": "TEST SPECIFICATION NUMBER "
    },
    {
        "Code": "TPN ",
        "Description": "TRANSPONDER NUMBER "
    },
    {
        "Code": "TQ ",
        "Description": "TRACER ACTION REQUEST NUMBER "
    },
    {
        "Code": "TR ",
        "Description": "GOVERNMENT TRANSPORTATION REQUEST "
    },
    {
        "Code": "TS ",
        "Description": "TARIFF NUMBER "
    },
    {
        "Code": "TSN ",
        "Description": "TEMPLATE SEQUENCE NUMBER "
    },
    {
        "Code": "TT ",
        "Description": "TERMINAL CODE "
    },
    {
        "Code": "TU ",
        "Description": "TRIAL LOCATION CODE "
    },
    {
        "Code": "TV ",
        "Description": "LINE OF BUSINESS "
    },
    {
        "Code": "TW ",
        "Description": "TAX WORKSHEET "
    },
    {
        "Code": "TX ",
        "Description": "TAX EXEMPT NUMBER "
    },
    {
        "Code": "TY ",
        "Description": "POLICY TYPE "
    },
    {
        "Code": "TZ ",
        "Description": "TOTAL CYCLE NUMBER "
    },
    {
        "Code": "U0 ",
        "Description": "CONSOLIDATOR'S RECEIPT NUMBER "
    },
    {
        "Code": "U1 ",
        "Description": "REGIONAL ACCOUNT NUMBER "
    },
    {
        "Code": "U2 ",
        "Description": "TERM "
    },
    {
        "Code": "U3 ",
        "Description": "UNIQUE SUPPLIER IDENTIFICATION NUMBER (USIN) "
    },
    {
        "Code": "U4 ",
        "Description": "UNPAID INSTALLMENT REFERENCE NUMBER "
    },
    {
        "Code": "U5 ",
        "Description": "SUCCESSOR ACCOUNT "
    },
    {
        "Code": "U6 ",
        "Description": "PREDECESSOR ACCOUNT "
    },
    {
        "Code": "U8 ",
        "Description": "MORTGAGE BACKED SECURITY (MBS) LOAN NUMBER "
    },
    {
        "Code": "U9 ",
        "Description": "MORTGAGE BACKED SECURITY (MBS) POOL NUMBER "
    },
    {
        "Code": "UA ",
        "Description": "MORTGAGE NUMBER "
    },
    {
        "Code": "UB ",
        "Description": "UNACCEPTABLE SOURCE PURCHASER ID "
    },
    {
        "Code": "UC ",
        "Description": "MORTGAGE INSURANCE INDICATOR NUMBER "
    },
    {
        "Code": "UD ",
        "Description": "UNACCEPTABLE SOURCE DUNS NUMBER "
    },
    {
        "Code": "UE ",
        "Description": "SECONDARY COVERAGE CERTIFICATE NUMBER "
    },
    {
        "Code": "UF ",
        "Description": "MORTGAGE INSURANCE COMPANY NUMBER "
    },
    {
        "Code": "UG ",
        "Description": "U.S. GOVERNMENT TRANSPORTATION CONTROL NUMBER "
    },
    {
        "Code": "UH ",
        "Description": "REMOVAL NUMBER "
    },
    {
        "Code": "UI ",
        "Description": "PREVIOUS COURSE NUMBER "
    },
    {
        "Code": "UJ ",
        "Description": "CURRENT OR LATEST COURSE NUMBER "
    },
    {
        "Code": "UK ",
        "Description": "EQUIVALENT COURSE NUMBER AT REQUESTING INSTITUTION "
    },
    {
        "Code": "UL ",
        "Description": "CROSS-LISTED COURSE NUMBER "
    },
    {
        "Code": "UM ",
        "Description": "QUARTER QUARTER SECTION NUMBER "
    },
    {
        "Code": "UN ",
        "Description": "UNITED NATIONS HAZARDOUS CLASSIFICATION NUMBER "
    },
    {
        "Code": "UO ",
        "Description": "QUARTER QUARTER SPOT NUMBER "
    },
    {
        "Code": "UP ",
        "Description": "UPSTREAM SHIPPER CONTRACT NUMBER "
    },
    {
        "Code": "UQ ",
        "Description": "SECTION NUMBER "
    },
    {
        "Code": "UR ",
        "Description": "UNIT RELIEF NUMBER "
    },
    {
        "Code": "URL ",
        "Description": "UNIFORM RESOURCE LOCATOR "
    },
    {
        "Code": "US ",
        "Description": "UNACCEPTABLE SOURCE SUPPLIER ID "
    },
    {
        "Code": "UT ",
        "Description": "UNIT TRAIN "
    },
    {
        "Code": "UU ",
        "Description": "TOWNSHIP NUMBER "
    },
    {
        "Code": "UV ",
        "Description": "RANGE NUMBER "
    },
    {
        "Code": "UW ",
        "Description": "STATE SENATE DISTRICT "
    },
    {
        "Code": "UX ",
        "Description": "STATE ASSEMBLY DISTRICT "
    },
    {
        "Code": "UY ",
        "Description": "FEDERAL NATIONAL MORTGAGE ASSOCIATION (FANNIE MAE) "
    },
    {
        "Code": "UZ ",
        "Description": "STATE LEGISLATIVE DISTRICT "
    },
    {
        "Code": "V0 ",
        "Description": "VERSION "
    },
    {
        "Code": "V1 ",
        "Description": "VOLUME PURCHASE AGREEMENT NUMBER "
    },
    {
        "Code": "V2 ",
        "Description": "VISA TYPE "
    },
    {
        "Code": "V3 ",
        "Description": "VOYAGE NUMBER "
    },
    {
        "Code": "V4 ",
        "Description": "STATE DEPARTMENT I-20 FORM NUMBER "
    },
    {
        "Code": "V5 ",
        "Description": "STATE DEPARTMENT IAP-66 FORM NUMBER "
    },
    {
        "Code": "V6 ",
        "Description": "NORTH AMERICAN FREE TRADE AGREEMENT (NAFTA) COMPLI "
    },
    {
        "Code": "V7 ",
        "Description": "JUDICIAL DISTRICT "
    },
    {
        "Code": "V8 ",
        "Description": "INSTITUTION NUMBER "
    },
    {
        "Code": "V9 ",
        "Description": "SUBSERVICER "
    },
    {
        "Code": "VA ",
        "Description": "VESSEL AGENT NUMBER "
    },
    {
        "Code": "VB ",
        "Description": "DEPARTMENT OF VETERANS AFFAIRS ACQUISITION REGULAT "
    },
    {
        "Code": "VC ",
        "Description": "VENDOR CONTRACT NUMBER "
    },
    {
        "Code": "VD ",
        "Description": "VOLUME NUMBER "
    },
    {
        "Code": "VE ",
        "Description": "VENDOR ABBREVIATION CODE "
    },
    {
        "Code": "VF ",
        "Description": "VENDOR CHANGE IDENTIFICATION CODE "
    },
    {
        "Code": "VG ",
        "Description": "VENDOR CHANGE PROCEDURE CODE "
    },
    {
        "Code": "VH ",
        "Description": "COUNTY LEGISLATIVE DISTRICT "
    },
    {
        "Code": "VI ",
        "Description": "POOL NUMBER "
    },
    {
        "Code": "VJ ",
        "Description": "INVESTOR NOTE HOLDER IDENTIFICATION "
    },
    {
        "Code": "VK ",
        "Description": "INSTITUTION NOTE HOLDER IDENTIFICATION "
    },
    {
        "Code": "VL ",
        "Description": "THIRD PARTY NOTE HOLDER IDENTIFICATION "
    },
    {
        "Code": "VM ",
        "Description": "WARD "
    },
    {
        "Code": "VN ",
        "Description": "VENDOR ORDER NUMBER "
    },
    {
        "Code": "VO ",
        "Description": "INSTITUTION LOAN NUMBER "
    },
    {
        "Code": "VP ",
        "Description": "VENDOR PRODUCT NUMBER "
    },
    {
        "Code": "VQ ",
        "Description": "RELATED CONTRACT LINE ITEM NUMBER "
    },
    {
        "Code": "VR ",
        "Description": "VENDOR ID NUMBER "
    },
    {
        "Code": "VS ",
        "Description": "VENDOR ORDER NUMBER SUFFIX "
    },
    {
        "Code": "VT ",
        "Description": "MOTOR VEHICLE ID NUMBER "
    },
    {
        "Code": "VU ",
        "Description": "PREPARER'S VERIFICATION NUMBER "
    },
    {
        "Code": "VV ",
        "Description": "VOUCHER "
    },
    {
        "Code": "VW ",
        "Description": "STANDARD "
    },
    {
        "Code": "VX ",
        "Description": "VALUE-ADDED TAX REGISTRATION NUMBER (EUROPE) "
    },
    {
        "Code": "VY ",
        "Description": "LINK SEQUENCE NUMBER "
    },
    {
        "Code": "VZ ",
        "Description": "SPONSOR'S REFERENCE NUMBER "
    },
    {
        "Code": "W1 ",
        "Description": "DISPOSAL TURN-IN DOCUMENT NUMBER "
    },
    {
        "Code": "W2 ",
        "Description": "WEAPON SYSTEM NUMBER "
    },
    {
        "Code": "W3 ",
        "Description": "MANUFACTURING DIRECTIVE NUMBER "
    },
    {
        "Code": "W4 ",
        "Description": "PROCUREMENT REQUEST NUMBER "
    },
    {
        "Code": "W5 ",
        "Description": "INSPECTOR IDENTIFICATION NUMBER "
    },
    {
        "Code": "W6 ",
        "Description": "FEDERAL SUPPLY SCHEDULE NUMBER "
    },
    {
        "Code": "W7 ",
        "Description": "COMMERCIAL AND GOVERNMENT ENTITY (CAGE) CODE "
    },
    {
        "Code": "W8 ",
        "Description": "SUFFIX "
    },
    {
        "Code": "W9 ",
        "Description": "SPECIAL PACKAGING INSTRUCTION NUMBER "
    },
    {
        "Code": "WA ",
        "Description": "LABOR OR AFFILIATION IDENTIFICATION "
    },
    {
        "Code": "WB ",
        "Description": "AMERICAN PETROLEUM INSTITUTE (API) WELL "
    },
    {
        "Code": "WC ",
        "Description": "CONTRACT OPTION NUMBER "
    },
    {
        "Code": "WCS ",
        "Description": "WORK CANDIDATE SEQUENCE NUMBER "
    },
    {
        "Code": "WD ",
        "Description": "REVIEW PERIOD NUMBER "
    },
    {
        "Code": "WDR ",
        "Description": "WITHDRAWAL RECORD "
    },
    {
        "Code": "WE ",
        "Description": "WELL CLASSIFICATION CODE "
    },
    {
        "Code": "WF ",
        "Description": "LOCALLY ASSIGNED CONTROL NUMBER "
    },
    {
        "Code": "WG ",
        "Description": "VENDOR'S PREVIOUS JOB NUMBER "
    },
    {
        "Code": "WH ",
        "Description": "MASTER REFERENCE (LINK) NUMBER "
    },
    {
        "Code": "WI ",
        "Description": "WAIVER "
    },
    {
        "Code": "WJ ",
        "Description": "PRE-AWARD SURVEY "
    },
    {
        "Code": "WK ",
        "Description": "TYPE OF SCIENCE CODE "
    },
    {
        "Code": "WL ",
        "Description": "FEDERAL SUPPLY CLASSIFICATION CODE "
    },
    {
        "Code": "WM ",
        "Description": "WEIGHT AGREEMENT NUMBER "
    },
    {
        "Code": "WN ",
        "Description": "WELL NUMBER "
    },
    {
        "Code": "WO ",
        "Description": "WORK ORDER NUMBER "
    },
    {
        "Code": "WP ",
        "Description": "WAREHOUSE PICK TICKET NUMBER "
    },
    {
        "Code": "WQ ",
        "Description": "INTERIM FUNDING ORGANIZATION LOAN NUMBER "
    },
    {
        "Code": "WR ",
        "Description": "WAREHOUSE RECEIPT NUMBER "
    },
    {
        "Code": "WS ",
        "Description": "WAREHOUSE STORAGE LOCATION NUMBER "
    },
    {
        "Code": "WT ",
        "Description": "BROKER'S REFERENCE NUMBER "
    },
    {
        "Code": "WU ",
        "Description": "VESSEL "
    },
    {
        "Code": "WV ",
        "Description": "DEALER IDENTIFICATION "
    },
    {
        "Code": "WW ",
        "Description": "DEPOSITORY TRUST COMPANY IDENTIFICATION "
    },
    {
        "Code": "WX ",
        "Description": "DISTRIBUTOR'S ACCOUNT IDENTIFICATION "
    },
    {
        "Code": "WY ",
        "Description": "WAYBILL NUMBER "
    },
    {
        "Code": "WZ ",
        "Description": "DISTRIBUTOR'S REPRESENTATIVE IDENTIFICATION "
    },
    {
        "Code": "X0 ",
        "Description": "DEBTOR'S ACCOUNT "
    },
    {
        "Code": "X1 ",
        "Description": "PROVIDER CLAIM NUMBER "
    },
    {
        "Code": "X2 ",
        "Description": "SPECIFICATION CLASS NUMBER "
    },
    {
        "Code": "X3 ",
        "Description": "DEFECT CODE NUMBER "
    },
    {
        "Code": "X4 ",
        "Description": "CLINICAL LABORATORY IMPROVEMENT AMENDMENT NUMBER "
    },
    {
        "Code": "X5 ",
        "Description": "STATE INDUSTRIAL ACCIDENT PROVIDER NUMBER "
    },
    {
        "Code": "X6 ",
        "Description": "ORIGINAL VOUCHER NUMBER "
    },
    {
        "Code": "X7 ",
        "Description": "BATCH SEQUENCE NUMBER "
    },
    {
        "Code": "X8 ",
        "Description": "SECONDARY SUFFIX CODE INDICATOR "
    },
    {
        "Code": "X9 ",
        "Description": "INTERNAL CONTROL NUMBER "
    },
    {
        "Code": "XA ",
        "Description": "SUBSTITUTE NATIONAL STOCK NUMBER "
    },
    {
        "Code": "XB ",
        "Description": "SUBSTITUTE MANUFACTURER'S PART NUMBER "
    },
    {
        "Code": "XC ",
        "Description": "CARGO CONTROL NUMBER "
    },
    {
        "Code": "XD ",
        "Description": "SUBSISTENCE IDENTIFICATION NUMBER "
    },
    {
        "Code": "XE ",
        "Description": "TRANSPORTATION PRIORITY NUMBER "
    },
    {
        "Code": "XF ",
        "Description": "GOVERNMENT BILL OF LADING OFFICE CODE "
    },
    {
        "Code": "XG ",
        "Description": "AIRLINE TICKET NUMBER "
    },
    {
        "Code": "XH ",
        "Description": "CONTRACT AUDITOR ID NUMBER "
    },
    {
        "Code": "XI ",
        "Description": "FEDERAL HOME LOAN MORTGAGE CORPORATION LOAN NUMBER "
    },
    {
        "Code": "XJ ",
        "Description": "FEDERAL HOME LOAN MORTGAGE CORPORATION DEFAULT/FOR "
    },
    {
        "Code": "XK ",
        "Description": "MORTGAGEE LOAN NUMBER "
    },
    {
        "Code": "XL ",
        "Description": "INSURED'S LOAN NUMBER "
    },
    {
        "Code": "XM ",
        "Description": "ISSUER NUMBER "
    },
    {
        "Code": "XN ",
        "Description": "TITLE XIX IDENTIFIER NUMBER "
    },
    {
        "Code": "XO ",
        "Description": "SAMPLE NUMBER "
    },
    {
        "Code": "XP ",
        "Description": "PREVIOUS CARGO CONTROL NUMBER "
    },
    {
        "Code": "XQ ",
        "Description": "PIER NUMBER "
    },
    {
        "Code": "XR ",
        "Description": "RAILROAD COMMISSION RECORD NUMBER "
    },
    {
        "Code": "XS ",
        "Description": "GAS ANALYSIS SOURCE METER NUMBER "
    },
    {
        "Code": "XT ",
        "Description": "TOXICOLOGY ID "
    },
    {
        "Code": "XU ",
        "Description": "UNIVERSAL TRANSVERSE MERCATOR - NORTH "
    },
    {
        "Code": "XV ",
        "Description": "UNIVERSAL TRANSVERSE MERCATOR - EAST "
    },
    {
        "Code": "XW ",
        "Description": "UNIVERSAL TRANSVERSE MERCATOR - ZONE "
    },
    {
        "Code": "XX ",
        "Description": "RATING PERIOD "
    },
    {
        "Code": "XY ",
        "Description": "OTHER UNLISTED TYPE OF REFERENCE NUMBER "
    },
    {
        "Code": "XZ ",
        "Description": "PHARMACY PRESCRIPTION NUMBER "
    },
    {
        "Code": "Y0 ",
        "Description": "DEBTOR "
    },
    {
        "Code": "Y1 ",
        "Description": "CLAIM ADMINISTRATOR CLAIM NUMBER "
    },
    {
        "Code": "Y2 ",
        "Description": "THIRD-PARTY ADMINISTRATOR CLAIM NUMBER "
    },
    {
        "Code": "Y3 ",
        "Description": "CONTRACT HOLDER CLAIM NUMBER "
    },
    {
        "Code": "Y4 ",
        "Description": "AGENCY CLAIM NUMBER "
    },
    {
        "Code": "Y5 ",
        "Description": "DELIVERY TRAILER MANIFEST "
    },
    {
        "Code": "Y6 ",
        "Description": "SORT AND SEGREGATE "
    },
    {
        "Code": "Y7 ",
        "Description": "PROCESSING AREA "
    },
    {
        "Code": "Y8 ",
        "Description": "USER ID "
    },
    {
        "Code": "Y9 ",
        "Description": "CURRENT CERTIFICATE NUMBER "
    },
    {
        "Code": "YA ",
        "Description": "PRIOR CERTIFICATE NUMBER "
    },
    {
        "Code": "YB ",
        "Description": "REVISION NUMBER "
    },
    {
        "Code": "YC ",
        "Description": "TRACT "
    },
    {
        "Code": "YD ",
        "Description": "BUYER IDENTIFICATION "
    },
    {
        "Code": "YE ",
        "Description": "RAILROAD COMMISSION OIL NUMBER "
    },
    {
        "Code": "YF ",
        "Description": "LESSEE IDENTIFICATION "
    },
    {
        "Code": "YH ",
        "Description": "OPERATOR ASSIGNED UNIT NUMBER "
    },
    {
        "Code": "YI ",
        "Description": "REFINER IDENTIFICATION "
    },
    {
        "Code": "YJ ",
        "Description": "REVENUE SOURCE "
    },
    {
        "Code": "YK ",
        "Description": "RENT PAYOR IDENTIFICATION "
    },
    {
        "Code": "YL ",
        "Description": "ALLOWANCE RECIPIENT IDENTIFICATION "
    },
    {
        "Code": "YM ",
        "Description": "RESOURCE SCREENING REFERENCE "
    },
    {
        "Code": "YN ",
        "Description": "RECEIVER ID QUALIFIER "
    },
    {
        "Code": "YO ",
        "Description": "FORMATION "
    },
    {
        "Code": "YP ",
        "Description": "SELLING ARRANGEMENT "
    },
    {
        "Code": "YQ ",
        "Description": "MINIMUM ROYALTY PAYOR IDENTIFICATION "
    },
    {
        "Code": "YR ",
        "Description": "OPERATOR LEASE NUMBER "
    },
    {
        "Code": "YS ",
        "Description": "YARD POSITION "
    },
    {
        "Code": "YT ",
        "Description": "REPORTER IDENTIFICATION "
    },
    {
        "Code": "YV ",
        "Description": "PARTICIPATING AREA "
    },
    {
        "Code": "YW ",
        "Description": "ENGINEERING CHANGE PROPOSAL "
    },
    {
        "Code": "YX ",
        "Description": "GEOGRAPHIC SCORE "
    },
    {
        "Code": "YY ",
        "Description": "GEOGRAPHIC KEY "
    },
    {
        "Code": "YZ ",
        "Description": "GEOGRAPHIC INDEX "
    },
    {
        "Code": "Z1 ",
        "Description": "SAFETY OF SHIP CERTIFICATE "
    },
    {
        "Code": "Z2 ",
        "Description": "SAFETY OF RADIO CERTIFICATE "
    },
    {
        "Code": "Z3 ",
        "Description": "SAFETY EQUIPMENT CERTIFICATE "
    },
    {
        "Code": "Z4 ",
        "Description": "CIVIL LIABILITIES OF OIL CERTIFICATE "
    },
    {
        "Code": "Z5 ",
        "Description": "LOAD LINE CERTIFICATE "
    },
    {
        "Code": "Z6 ",
        "Description": "DERAT CERTIFICATE "
    },
    {
        "Code": "Z7 ",
        "Description": "MARITIME DECLARATION OF HEALTH "
    },
    {
        "Code": "Z8 ",
        "Description": "FEDERAL HOUSING ADMINISTRATION CASE NUMBER "
    },
    {
        "Code": "Z9 ",
        "Description": "VETERANS AFFAIRS CASE NUMBER "
    },
    {
        "Code": "ZA ",
        "Description": "SUPPLIER "
    },
    {
        "Code": "ZB ",
        "Description": "ULTIMATE CONSIGNEE "
    },
    {
        "Code": "ZC ",
        "Description": "CONNECTING CARRIER "
    },
    {
        "Code": "ZD ",
        "Description": "FAMILY MEMBER IDENTIFICATION "
    },
    {
        "Code": "ZE ",
        "Description": "COAL AUTHORITY NUMBER "
    },
    {
        "Code": "ZF ",
        "Description": "CONTRACTOR ESTABLISHMENT CODE (CEC) "
    },
    {
        "Code": "ZG ",
        "Description": "SALES REPRESENTATIVE ORDER NUMBER "
    },
    {
        "Code": "ZH ",
        "Description": "CARRIER ASSIGNED REFERENCE NUMBER "
    },
    {
        "Code": "ZI ",
        "Description": "REFERENCE VERSION NUMBER "
    },
    {
        "Code": "ZJ ",
        "Description": "UNIVERSAL RAILROAD REVENUE WAYBILL IDENTIFIED NUMB "
    },
    {
        "Code": "ZK ",
        "Description": "DUPLICATE WAYBILL IN ROUTE "
    },
    {
        "Code": "ZL ",
        "Description": "DUPLICATE WAYBILL NOT IN ROUTE "
    },
    {
        "Code": "ZM ",
        "Description": "MANUFACTURER NUMBER "
    },
    {
        "Code": "ZN ",
        "Description": "AGENCY CASE NUMBER "
    },
    {
        "Code": "ZO ",
        "Description": "MAKEGOOD COMMERCIAL LINE NUMBER "
    },
    {
        "Code": "ZP ",
        "Description": "SPOUSE TIE "
    },
    {
        "Code": "ZQ ",
        "Description": "NON-SPOUSE TIE "
    },
    {
        "Code": "ZR ",
        "Description": "SUPPLIER (REPLACEMENT) "
    },
    {
        "Code": "ZS ",
        "Description": "SOFTWARE APPLICATION NUMBER "
    },
    {
        "Code": "ZT ",
        "Description": "MILLING IN TRANSIT "
    },
    {
        "Code": "ZU ",
        "Description": "FIELD "
    },
    {
        "Code": "ZV ",
        "Description": "BLOCK "
    },
    {
        "Code": "ZW ",
        "Description": "AREA "
    },
    {
        "Code": "ZX ",
        "Description": "COUNTY CODE "
    },
    {
        "Code": "ZY ",
        "Description": "REFERENCED PATTERN IDENTIFICATION "
    },
    {
        "Code": "ZZ ",
        "Description": "MUTUALLY DEFINED "
    }
]

export {
    EDI_214_SHIPMENT_STATUS_CODES,
    EDI_214_SHIPMENT_STATUS_CODES_MAIL_SPECIFIC,
    EDI_214_SHIPMENT_STATUS_CODES_MAIL_PICKUP_SPECIFIC,
    EDI_214_SHIPMENT_STATUS_CODES_MAIL_DELIVERY_SPECIFIC,
    EDI_204_210_STOP_OFF_DETAIL_REASON_CODES,
    EDI_TRANSACTION_SET_PURPOSE_CODES,
    EDI_TIMEZONE_CODES,
    EDI_WEIGHT_CODES,
    EDI_EQUIPMENT_CODES,
    EDI_BUSINESS_INSTRUCTIONS_REFERENCE_NO_CODES,
    EDI_SHIPMENT_METHOD_OF_PAYMENT,
    EDI_STOP_REASON_CODE
}