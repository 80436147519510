import { useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";

function LoadTenderReconciliationUSPS() {

    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileChange = (event) => {
        setSelectedFiles([...event.target.files]);
    };

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     if (selectedFiles.length > 0) {
    //         // Handle file upload logic here
    //         selectedFiles.forEach(file => {
    //             console.log('File selected:', file.name);
    //         });
    //     }
    // };

    return (
        <Container fluid style={{ margin: '0', padding: '0' }}>
            <Card style={{ margin: '0', padding: '0', borderRadius: '0', border: 0 }}>
                <Card.Header>
                    <Row>
                        <Col>
                            <Card.Title>
                                Load Tender Reconciliation
                            </Card.Title>
                        </Col>
                        <Col xs="auto">
                            <input
                                type="file"
                                id="custom-file"
                                label={
                                    selectedFiles.length > 0
                                        ? `${selectedFiles.length} file(s) selected`
                                        : "Choose files"
                                }
                                onChange={handleFileChange}
                                multiple
                            />
                            <Button variant="primary" >
                                Upload
                            </Button>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>

                </Card.Body>
            </Card>
        </Container>
    )
}

export default LoadTenderReconciliationUSPS;