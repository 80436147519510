import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import { Navbar, Nav, Image, Container, NavDropdown, Row, Col, Badge, Tab, Tabs } from 'react-bootstrap';
import { useRole } from "../context/role";
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/auth';
import ConfirmationModal from '../components/ConfirmationModal';
import { multidimensionalGroupBy } from '../utils/Array.helper';
import LOGO from '../assets/logo.png'
import SYBR_LOGO from '../assets/sybr-logo.png'

import { appFeatureGroups } from '../context/appfeaturegroups'

function FeatureBar() {
    const { user, logout } = useAuth();
    const [showModal, setShowModal] = useState();
    const { userRole, checkNavPermissions } = useRole();
    const [activeFeature, setActiveFeature] = useState('');

    const featureGroups = appFeatureGroups;

    const allowedFeatures = checkNavPermissions(userRole, featureGroups);

    function filterFeatures(data) {
        const filteredData = [];
        const length = data.length;

        for (let i = 0; i < length; i++) {
            const currentItem = data[i];
            const currentFeature = currentItem.feature;
            const prevItem = i > 0 ? data[i - 1] : null;
            const prevFeature = prevItem?.feature ?? null;
            const nextItem = i < length - 1 ? data[i + 1] : null;
            const nextFeature = nextItem?.feature ?? null;

            // Check if currentItem has an href
            if (currentItem.href) {
                filteredData.push(currentItem);
            } else {
                if (currentFeature === prevFeature && currentFeature === nextFeature) {
                    // Check if there is a prevItem or nextItem with an href
                    if ((prevItem && prevItem.href) || (nextItem && nextItem.href)) {
                        filteredData.push(currentItem);
                    }
                }
            }
        }

        return filteredData;
    }

    const filteredFeatures = filterFeatures(allowedFeatures);

    const groups = multidimensionalGroupBy(filteredFeatures, ['feature'])

    const containerStyle = {
        backgroundColor: '#f9f9f9',
        borderBottom: '1px solid #ccc'
    }

    const navigate = useNavigate();
    const navigateTo = (path) => {
        navigate(path);
    }

    async function handleLogout() {
        await logout()
    }

    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 1024);
        };

        // Listen for resize events
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Container fluid style={containerStyle}>
            <Navbar expand="lg" >
                <Container fluid>
                    <Navbar.Brand>
                        <Link to="/">
                            <Image src={SYBR_LOGO} alt={'SYBR Logo'} style={{ minHeight: '3vh', maxHeight: '5vh', margin: '0 0.25em' }} />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {Object.keys(groups).map((feature, i) => (
                                <NavDropdown key={i} title={feature} id="basic-nav-dropdown">
                                    {groups[feature].map((endpoint, j) => (
                                        (endpoint?.href) ?
                                            <NavDropdown.Item key={j} onClick={() => {
                                                navigateTo(endpoint?.href);
                                                setActiveFeature(feature);
                                            }}>{endpoint.icon} {endpoint.text}</NavDropdown.Item>
                                            :
                                            <NavDropdown.Divider key={j} />
                                    ))}
                                </NavDropdown>
                            ))}
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            {userRole.name} : {user.email}
                        </Navbar.Text>
                        <Image src={LOGO} alt={'Logo'} style={{ minHeight: '3vh', maxHeight: '5vh', margin: '0 0.25em' }} onClick={() => setShowModal(true)} />
                        <ConfirmationModal show={showModal} onHide={() => setShowModal(false)} onConfirm={() => handleLogout()} message={'Are you sure you want to logout?'} />
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {isLargeScreen && (
                Object.keys(groups).map((feature, i) => (
                    activeFeature === feature &&
                    <Row style={{ borderTop: '1px solid #ccc' }}>
                        {
                            groups[feature].map((endpoint, j) => (
                                (endpoint?.href) &&
                                <Col
                                    xs="auto"
                                    key={j}
                                    onClick={() => {
                                        navigateTo(endpoint?.href);
                                        setActiveFeature(feature);
                                    }}
                                    style={{ margin: 0, padding: 0 }}
                                >
                                    <span style={
                                        endpoint.href === window.location.pathname
                                            ? { color: '#5f5f5f', backgroundColor: '#a0def8', border: '1px solid #5f5f5f', padding:'0.1em 0.25em' }
                                            : { color: '#5f5f5f', backgroundColor: '#e9ecef', border: '1px solid #5f5f5f', padding:'0.1em 0.25em' }
                                    }>
                                        {endpoint.icon} {endpoint.text}
                                    </span>
                                </Col>
                            ))
                        }
                    </Row>
                ))
            )}
        </Container>
    )
}

export default FeatureBar;

