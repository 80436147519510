import React, { useEffect, useState, useCallback } from 'react';
import { Container, Card, Spinner, Alert, Modal, Button, Row, Badge, Col, Form, Table, ToggleButton } from 'react-bootstrap';
import { RxCrossCircled, RxCheckCircled } from 'react-icons/rx';
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';

// Import images for services
import DOL_IMG from '../../assets/b2b/dol.png';
import EIA_IMG from '../../assets/b2b/eia.png';
import KLEINSCHMIDT_IMG from '../../assets/b2b/kleinschmidt.jpg';
import SAMSARA_IMG from '../../assets/b2b/samsara.png';
import MOTIVE_IMG from '../../assets/b2b/motive.png';
import BREAKTHROUGH_IMG from '../../assets/b2b/breakthrough-fuel.png';
import GOOGLEMAPS_IMG from '../../assets/b2b/googlemaps.png';
import LOADCNXN_IMG from '../../assets/b2b/LoadCnxnLogo.png';
import FOURKITES_IMG from '../../assets/b2b/fourkites.png';
import JUSTRANSFORM_IMG from '../../assets/b2b/justransform.png';
import PCMILER_IMG from '../../assets/b2b/pcmiler.png';
import NWS_IMG from '../../assets/b2b/nationalweatherservice.png';
import TESLA_IMG from '../../assets/b2b/tesla.jpg';
import GEOTAB_IMG from '../../assets/b2b/geotab.jpg';
import LYTX_IMG from '../../assets/b2b/lytx.png';
import FMCSA_IMG from '../../assets/b2b/fmcsa.png';
import SMTP_IMG from '../../assets/b2b/smtp.png'
import AWS_S3_IMG from '../../assets/b2b/aws-s3-logo.png'
import USPS_IMG from '../../assets/b2b/USPS_Eagle-Symbol-web-size.png'
import TWILIO_IMG from '../../assets/b2b/twilio-logo.png'
import AlertDisplay from '../../components/AlertDisplay';
import { BooleanToggle } from '../../components/BooleanToggle';



function IOT() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [alerts, setAlerts] = useState([]);
    const [activeModel, setActiveModal] = useState();
    const alertState = { alerts, setAlerts };

    // breakthroughfuel
    const [newBreakthroughfuelKey, setNewBreakthroughfuelKey] = useState({
        apiUsername: '',
        apiPassword: '',
    })
    const [breakthroughfuelKeys, setBreakthroughfuelKeys] = useState([])
    async function handleGetBreakthroughfuelApiKeys() {
        try {
            const keys = await Api.get('/iot/breakthroughfuel', idToken)
            setBreakthroughfuelKeys(keys)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'No Breakthroughfuel API Keys', }])
        }
    }
    async function handlePostBreakthroughfuelApiKey() {
        try {
            if (newBreakthroughfuelKey) {
                await Api.post('/iot/breakthroughfuel', { ...newBreakthroughfuelKey }, idToken)
                setAlerts([...alerts, { variant: 'success', message: 'Created Breakthroughfuel API Key', }])
                setNewBreakthroughfuelKey({ apiUsername: '', apiPassword: '', })
            }
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error creating Breakthroughfuel API Key', }])
        } finally {
            handleGetBreakthroughfuelApiKeys()
        }
    }
    async function handleDeleteBreakthroughfuelApiKey(id) {
        try {
            await Api.delete(`/iot/breakthroughfuel/${id}`, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Deleted key', }])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error deleting key', }])
        } finally {
            handleGetBreakthroughfuelApiKeys()
        }
    }
    // GOOGLEMAPS
    const [newGoogleMapsKey, setNewGoogleMapsKey] = useState()
    const [googlemapsKeys, setGoogleMapsKeys] = useState([])
    async function handleGetGoogleMapsApiKeys() {
        try {
            const keys = await Api.get('/iot/googlemaps', idToken)
            setGoogleMapsKeys(keys)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'No GoogleMaps API Keys', }])
        }
    }
    async function handlePostGoogleMapsApiKey() {
        try {
            if (newGoogleMapsKey) {
                await Api.post('/iot/googlemaps', { apiKey: newGoogleMapsKey }, idToken)
                setAlerts([...alerts, { variant: 'success', message: 'Created GoogleMaps API Key', }])
                setNewGoogleMapsKey('')
            }
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error creating GoogleMaps API Key', }])
        } finally {
            handleGetGoogleMapsApiKeys()
        }
    }
    async function handleDeleteGoogleMapsApiKey(id) {
        try {
            await Api.delete(`/iot/googlemaps/${id}`, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Deleted key', }])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error deleting key', }])
        } finally {
            handleGetGoogleMapsApiKeys()
        }
    }
    // fourkties
    const [newFourkitesKey, setNewFourkitesKey] = useState()
    const [fourkitesKeys, setFourkitesKeys] = useState([])
    async function handleGetFourkitesApiKeys() {
        try {
            const keys = await Api.get('/iot/fourkites', idToken)
            setFourkitesKeys(keys)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'No Fourkites API Keys', }])
        }
    }
    async function handlePostFourkitesApiKey() {
        try {
            if (newFourkitesKey) {
                await Api.post('/iot/fourkites', { apiKey: newFourkitesKey }, idToken)
                setAlerts([...alerts, { variant: 'success', message: 'Created Fourkites API Key', }])
                setNewFourkitesKey('')
            }
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error creating Fourkites API Key', }])
        } finally {
            handleGetFourkitesApiKeys()
        }
    }
    async function handleDeleteFourkitesApiKey(id) {
        try {
            await Api.delete(`/iot/fourkites/${id}`, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Deleted key', }])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error deleting key', }])
        } finally {
            handleGetFourkitesApiKeys()
        }
    }
    // justransform
    const [newJustransformKey, setNewJustransformKey] = useState({
        apiUsername: '',
        apiPassword: '',
    })
    const [justransformKeys, setJustransformKeys] = useState([])
    async function handleGetJustransformApiKeys() {
        try {
            const keys = await Api.get('/iot/justransform', idToken)
            setJustransformKeys(keys)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'No Justransform API Keys', }])
        }
    }
    async function handlePostJustransformApiKey() {
        try {
            if (newJustransformKey) {
                await Api.post('/iot/justransform', { ...newJustransformKey }, idToken)
                setAlerts([...alerts, { variant: 'success', message: 'Created Justransform API Key', }])
                setNewJustransformKey({
                    apiUsername: '',
                    apiPassword: '',
                })
            }
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error creating Justransform API Key', }])
        } finally {
            handleGetJustransformApiKeys()
        }
    }
    async function handleDeleteJustransformApiKey(id) {
        try {
            await Api.delete(`/iot/justransform/${id}`, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Deleted key', }])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error deleting key', }])
        } finally {
            handleGetJustransformApiKeys()
        }
    }
    // KLEINSCHMIDT
    const [newKleinschmidtKey, setNewKleinschmidtKey] = useState()
    const [kleinschmidtKeys, setKleinschmidtKeys] = useState([])
    async function handleGetKleinschmidtApiKeys() {
        try {
            const keys = await Api.get('/iot/kleinschmidt', idToken)
            setKleinschmidtKeys(keys)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'No Kleinschmidt API Keys', }])
        }
    }
    async function handlePostKleinschmidtApiKey() {
        try {
            if (newKleinschmidtKey) {
                await Api.post('/iot/kleinschmidt', { apiKey: newKleinschmidtKey }, idToken)
                setAlerts([...alerts, { variant: 'success', message: 'Created Kleinschmidt API Key', }])
                setNewKleinschmidtKey('')
            }
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error creating Kleinschmidt API Key', }])
        } finally {
            handleGetKleinschmidtApiKeys()
        }
    }
    async function handleDeleteKleinschmidtApiKey(id) {
        try {
            await Api.delete(`/iot/kleinschmidt/${id}`, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Deleted key', }])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error deleting key', }])
        } finally {
            handleGetKleinschmidtApiKeys()
        }
    }
    // LOADCNXN
    const [newLoadCnxnKey, setNewLoadCnxnKey] = useState({
        apiClient: '',
        apiUsername: '',
        apiPassword: '',
        apiGrantType: '',
    })
    const [loadcnxnKeys, setLoadCnxnKeys] = useState([])
    async function handleGetLoadCnxnApiKeys() {
        try {
            const keys = await Api.get('/iot/loadcnxn', idToken)
            setLoadCnxnKeys(keys)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'No LoadCnxn API Keys', }])
        }
    }
    async function handlePostLoadCnxnApiKey() {
        try {
            if (newLoadCnxnKey) {
                await Api.post('/iot/loadcnxn', { ...newLoadCnxnKey }, idToken)
                setAlerts([...alerts, { variant: 'success', message: 'Created LoadCnxn API Key', }])
                setNewLoadCnxnKey({
                    apiClient: '',
                    apiUsername: '',
                    apiPassword: '',
                    apiGrantType: '',
                })
            }
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error creating LoadCnxn API Key', }])
        } finally {
            handleGetLoadCnxnApiKeys()
        }
    }
    async function handleDeleteLoadCnxnApiKey(id) {
        try {
            await Api.delete(`/iot/loadcnxn/${id}`, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Deleted key', }])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error deleting key', }])
        } finally {
            handleGetLoadCnxnApiKeys()
        }
    }
    //  motive
    const [newMotiveKey, setNewMotiveKey] = useState()
    const [motiveKeys, setMotiveKeys] = useState([])
    async function handleGetMotiveApiKeys() {
        try {
            const keys = await Api.get('/iot/motive', idToken)
            setMotiveKeys(keys)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'No Motive API Keys', }])
        }
    }
    async function handlePostMotiveApiKey() {
        try {
            if (newMotiveKey) {
                await Api.post('/iot/motive', { apiKey: newMotiveKey }, idToken)
                setAlerts([...alerts, { variant: 'success', message: 'Created Motive API Key', }])
                setNewMotiveKey()
            }
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error creating Motive API Key', }])
        } finally {
            handleGetMotiveApiKeys()
        }
    }
    async function handleDeleteMotiveApiKey(id) {
        try {
            await Api.delete(`/iot/motive/${id}`, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Deleted key', }])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error deleting key', }])
        } finally {
            handleGetMotiveApiKeys('')
        }
    }
    //  samsara
    const [newSamsaraKey, setNewSamsaraKey] = useState()
    const [samsaraKeys, setSamsaraKeys] = useState([])
    async function handleGetSamsaraApiKeys() {
        try {
            const keys = await Api.get('/iot/samsara', idToken)
            setSamsaraKeys(keys)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'No Samsara API Keys', }])
        }
    }

    async function handlePostSamsaraApiKey() {
        try {
            if (newSamsaraKey) {
                await Api.post('/iot/samsara', { apiKey: newSamsaraKey }, idToken)
                setAlerts([...alerts, { variant: 'success', message: 'Created Samsara API Key', }])
                setNewSamsaraKey('')
            }
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error creating Samsara API Key', }])
        } finally {
            handleGetSamsaraApiKeys()
        }
    }

    async function handleDeleteSamsaraApiKey(id) {
        try {
            await Api.delete(`/iot/samsara/${id}`, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Deleted key', }])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error deleting key', }])
        } finally {
            handleGetSamsaraApiKeys()
        }
    }
    //  amazon S3
    const [newAmazonS3Key, setNewAmazonS3Key] = useState({
        region: '',
        accessKey: '',
        secretKey: '',
        name: '',
    })
    const [amazons3Keys, setAmazonS3Keys] = useState([])
    async function handleGetAmazonS3ApiKeys() {
        try {
            const keys = await Api.get('/iot/amazonS3', idToken)
            setAmazonS3Keys(keys)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'No AmazonS3 API Keys', }])
        }
    }

    async function handlePostAmazonS3ApiKey() {
        try {
            if (newAmazonS3Key) {
                await Api.post('/iot/amazonS3', { ...newAmazonS3Key }, idToken)
                setAlerts([...alerts, { variant: 'success', message: 'Created AmazonS3 API Key', }])
                setNewAmazonS3Key({
                    region: '',
                    accessKey: '',
                    secretKey: '',
                    name: '',
                })
            }
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error creating AmazonS3 API Key', }])
        } finally {
            handleGetAmazonS3ApiKeys()
        }
    }

    async function handleDeleteAmazonS3ApiKey(id) {
        try {
            await Api.delete(`/iot/amazonS3/${id}`, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Deleted key', }])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error deleting key', }])
        } finally {
            handleGetAmazonS3ApiKeys()
        }
    }
    //  SMPT
    const [newSMTPKey, setNewSMTPKey] = useState({
        apiKey: '',
        channel: ''
    })
    const [smtpKeys, setSMTPKeys] = useState([])
    async function handleGetSMTPApiKeys() {
        try {
            const keys = await Api.get('/iot/smtp', idToken)
            setSMTPKeys(keys)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'No SMTP API Keys', }])
        }
    }


    async function handlePostSMTPApiKey() {
        try {
            if (newSMTPKey) {
                await Api.post('/iot/twilio', { ...newSMTPKey }, idToken)
                setAlerts([...alerts, { variant: 'success', message: 'Created SMTP API Key', }])
                setNewSMTPKey({
                    username: '',
                    password: ''
                })
            }
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error creating Twilio API Key', }])
        } finally {
            handleGetSMTPApiKeys()
        }
    }

    async function handleDeleteSMTPApiKey(id) {
        try {
            await Api.delete(`/iot/amazonS3/${id}`, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Deleted key', }])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error deleting key', }])
        } finally {
            handleGetSMTPApiKeys()
        }
    }

    // TWILIO
    //  twilio
    const [newTwilioKey, setNewTwilioKey] = useState({
        apiKey: '',
        channel: ''
    })
    const [twilioKeys, setTwilioKeys] = useState([])
    async function handleGetTwilioApiKeys() {
        try {
            const keys = await Api.get('/iot/twilio', idToken)
            setTwilioKeys(keys)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'No Twilio API Keys', }])
        }
    }

    async function handlePostTwilioApiKey() {
        try {
            if (newTwilioKey) {
                await Api.post('/iot/twilio', { ...newTwilioKey }, idToken)
                setAlerts([...alerts, { variant: 'success', message: 'Created Twilio API Key', }])
                setNewTwilioKey({
                    username: '',
                    password: ''
                })
            }
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error creating Twilio API Key', }])
        } finally {
            handleGetTwilioApiKeys()
        }
    }

    async function handleDeleteTwilioApiKey(id) {
        try {
            await Api.delete(`/iot/amazonS3/${id}`, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Deleted key', }])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error deleting key', }])
        } finally {
            handleGetTwilioApiKeys()
        }
    }


    //-----
    //-----
    async function handleServiceToggle(service) {
        try {
            console.log(service)
            const updateService = {
                name: service.name,
                on: service?.on[0] ? false : true
            }
            await Api.post(`/iot/servicestatus`, { ...updateService }, idToken)
            didSet(false)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error updating service status.' }])
        }
    }

    //
    const services = [
        {
            name: 'Samsara',
            didLoad: useState(),
            on: useState(),
            state: useState(),
            colorState: useState('#fbb514'),
            messageState: useState('Unchecked'),
            image: SAMSARA_IMG,
            statusCheck: async () => Api.get('/samsara/statuscheck', idToken),
            healthCheck: async () => Api.get('/samsara/healthcheck', idToken),
            handleGet: async () => handleGetSamsaraApiKeys()
        },
        {
            name: 'Motive',
            didLoad: useState(),
            on: useState(),
            state: useState(),
            colorState: useState('#fbb514'),
            messageState: useState('Unchecked'),
            image: MOTIVE_IMG,
            statusCheck: async () => Api.get('/motive/statuscheck', idToken),
            healthCheck: async () => Api.get('/motive/healthcheck', idToken),
            handleGet: async () => handleGetMotiveApiKeys()
        },
        {
            name: 'LoadCnxn',
            didLoad: useState(),
            on: useState(),
            state: useState(),
            colorState: useState('#fbb514'),
            messageState: useState('Unchecked'),
            image: USPS_IMG,
            statusCheck: async () => Api.get('/loadcnxn/statuscheck', idToken),
            healthCheck: async () => Api.get('/loadcnxn/healthcheck', idToken),
            handleGet: async () => handleGetLoadCnxnApiKeys()
        },
        // {
        //     name: 'Breakthrough Fuel',
        //     on: useState(),
        //     state: useState(),
        //     colorState: useState('#fbb514'),
        //     messageState: useState('Unchecked'),
        //     image: BREAKTHROUGH_IMG,
        //     statusCheck: async () => Api.get('/breakthroughfuel/statuscheck', idToken),
        //     healthCheck: async () => Api.get('/breakthroughfuel/healthcheck', idToken),
        //     handleGet: async () => handleGetBreakthroughfuelApiKeys()
        // },
        {
            name: 'Fourkites',
            didLoad: useState(),
            on: useState(),
            state: useState(),
            colorState: useState('#fbb514'),
            messageState: useState('Unchecked'),
            image: FOURKITES_IMG,
            statusCheck: async () => Api.get('/fourkites/statuscheck', idToken),
            healthCheck: async () => Api.get('/fourkites/healthcheck', idToken),
            handleGet: async () => handleGetFourkitesApiKeys()
        },
        {
            name: 'Justransform',
            didLoad: useState(),
            on: useState(),
            state: useState(),
            colorState: useState('#fbb514'),
            messageState: useState('Unchecked'),
            image: JUSTRANSFORM_IMG,
            statusCheck: async () => Api.get('/justransform/statuscheck', idToken),
            healthCheck: async () => Api.get('/justransform/healthcheck', idToken),
            handleGet: async () => handleGetJustransformApiKeys()
        },
        {
            name: 'Google Maps',
            didLoad: useState(),
            on: useState(),
            state: useState(),
            colorState: useState('#fbb514'),
            messageState: useState('Unchecked'),
            image: GOOGLEMAPS_IMG,
            statusCheck: async () => Api.get('/googlemaps/statuscheck', idToken),
            healthCheck: async () => Api.get('/googlemaps/healthcheck', idToken),
            handleGet: async () => handleGetGoogleMapsApiKeys()

        },
        {
            name: 'LoadCnxn',
            didLoad: useState(),
            on: useState(),
            state: useState(),
            colorState: useState('#fbb514'),
            messageState: useState('Unchecked'),
            image: LOADCNXN_IMG,
            statusCheck: async () => Api.get('/loadcnxn/statuscheck', idToken),
            healthCheck: async () => Api.get('/loadcnxn/healthcheck', idToken),
            handleGet: async () => handleGetLoadCnxnApiKeys()
        },
        {
            name: 'Kleinschmidt',
            didLoad: useState(),
            on: useState(),
            state: useState(),
            colorState: useState('#fbb514'),
            messageState: useState('Unchecked'),
            image: KLEINSCHMIDT_IMG,
            statusCheck: async () => Api.get('/kleinschmidt/statuscheck', idToken),
            healthCheck: async () => Api.get('/kleinschmidt/healthcheck', idToken),
            handleGet: async () => handleGetKleinschmidtApiKeys()
        },
        {
            name: 'SMTP',
            didLoad: useState(),
            on: useState(),
            state: useState(),
            colorState: useState('#fbb514'),
            messageState: useState('Unchecked'),
            image: SMTP_IMG,
            statusCheck: async () => Api.get('/smtp/statuscheck', idToken),
            healthCheck: async () => Api.get('/smtp/healthcheck', idToken),
            handleGet: async () => handleGetSMTPApiKeys()
        },
        {
            name: 'Twilio',
            didLoad: useState(),
            on: useState(),
            state: useState(),
            colorState: useState('#fbb514'),
            messageState: useState('Unchecked'),
            image: TWILIO_IMG,
            statusCheck: async () => Api.get('/twilio/statuscheck', idToken),
            healthCheck: async () => Api.get('/twilio/healthcheck', idToken),
            handleGet: async () => handleGetTwilioApiKeys()
        },
        {
            name: 'Amazon S3',
            didLoad: useState(),
            on: useState(),
            state: useState(),
            colorState: useState('#fbb514'),
            messageState: useState('Unchecked'),
            image: AWS_S3_IMG,
            statusCheck: async () => Api.get('/storage/statuscheck', idToken),
            healthCheck: async () => Api.get('/storage/healthcheck', idToken),
            handleGet: async () => handleGetAmazonS3ApiKeys()
        },
        // {
        //     name: 'DOL',
        //     state: useState(),
        //     colorState: useState('#fbb514'),
        //     messageState: useState('Unchecked'),
        //     healthCheck: async () => Api.get('/dol/healthcheck', idToken),
        //     image: DOL_IMG,
        // },
        // {
        //     name: 'EIA',
        //     state: useState(),
        //     colorState: useState('#fbb514'),
        //     messageState: useState('Unchecked'),
        //     healthCheck: async () => Api.get('/fuel/healthcheck', idToken),
        //     image: EIA_IMG,
        // },
        // {
        //     name: 'FMCSA',
        //     state: useState(),
        //     colorState: useState('#fbb514'),
        //     messageState: useState('Unchecked'),
        //     healthCheck: async () => Api.get('/fmcsa/healthcheck', idToken),
        //     image: FMCSA_IMG,
        // },
        // {
        //     name: 'National Weather Service',
        //     state: useState(),
        //     colorState: useState('#fbb514'),
        //     messageState: useState('Unchecked'),
        //     healthCheck: async () => Api.get('/nws/healthcheck', idToken),
        //     image: NWS_IMG,
        // },
        // {
        //     name: 'Lytx',
        //     state: useState(<Spinner size='sm'/>),
        //     colorState: useState('#fbb514'),
        //     messageState: useState('Unchecked'),
        //     healthCheck: async () => Api.get('/lytx/healthcheck', idToken),
        //     image: LYTX_IMG,
        // },
        // {
        //     name: 'Geotab',
        //     state: useState(<Spinner size='sm'/>),
        //     colorState: useState('#fbb514'),
        //     messageState: useState('Unchecked'),
        //     healthCheck: async () => Api.get('/geotab/healthcheck', idToken),
        //     image: GEOTAB_IMG,
        // },
    ];

    async function checkServiceKeyHealth(service) {
        service.messageState[1](<Spinner size='sm' />);
        service.colorState[1]('#ccc');
        service.state[1]('');
        try {
            const healthy = await service.healthCheck();
            service.state[1](healthy ? <RxCheckCircled /> : <RxCrossCircled />);
            service.colorState[1](healthy ? '#488e5c' : '#dc3912');
            service.messageState[1](healthy ? 'Established' : 'Error');
        } catch {
            service.state[1](<RxCrossCircled />);
            service.colorState[1]('#dc3912');
            service.messageState[1]('Did not establish');
        }
    }

    useEffect(() => {
        const checkHealth = async () => {
            for (const service of services) {
                try {
                    const status = await service.statusCheck();
                    if (status.on === true) {
                        service.on[1](status.on);

                    } else {
                        service.on[1](status.on);

                    }
                    service.didLoad[1](true)
                } catch (error) {
                    setAlerts([...alerts, { variant: 'warning', message: `${service.name} error` }]);
                }

            }
            didSet(true);
        }

        if (!set) {
            checkHealth();
        }
    }, [set])

    // Function to generate service cards dynamically

    const generateServiceCards = () => {
        return services.map((service, index) => (
            <Card key={index} style={{ width: '18rem', margin: '1em' }}>
                <Card.Img src={service.image} variant="top" style={{ padding: '1em', height: '11rem', objectFit: 'contain' }} />
                {service?.didLoad[0] ?
                    <>
                        <Card.Header>
                            <Row>
                                <Col>
                                    <BooleanToggle toggleState={{
                                        toggle: service.on[0],
                                        setToggle: () => handleServiceToggle(service),
                                    }}
                                        trueBg={'#bcdbbc'}
                                        falseBg={'#f8aeb4'}
                                    />
                                </Col>
                                <Col>
                                    {service.on[0] ? 'On' : 'Off'}
                                </Col>
                                <Col>
                                    {service?.on[0] &&
                                        <Badge style={{ cursor: 'pointer' }} onClick={() => { setActiveModal(service); service.handleGet() }}>Keys</Badge>
                                    }
                                </Col>
                            </Row>
                            {service?.on[0] &&
                                <>
                                    <hr />
                                    <Row>
                                        <Col>
                                            Connection: <span className='float-end' style={{ color: service.colorState[0] }}>{service.messageState[0]} {service.state[0]}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Badge
                                                bg="secondary"
                                                onClick={() => checkServiceKeyHealth(service)}
                                                style={{ width: '100%', cursor: 'pointer' }}
                                            >Health Check</Badge>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </Card.Header>
                    </>
                    :
                    <Row>
                        <Col>
                            <Spinner />
                        </Col>
                    </Row>
                }

                {/* <Card.Header>
                    <Card.Subtitle>
                        
                    </Card.Subtitle>
                </Card.Header> */}
            </Card>
        ));
    };

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            {/* AMAZON S3 */}
            <Modal show={activeModel?.name === 'Amazon S3' ? true : false} size="xl">
                <Modal.Header>
                    <Row>
                        <Col>
                            <Modal.Title>
                                {activeModel?.name}
                            </Modal.Title>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Region</th>
                                <th>Access</th>
                                <th>Secret</th>
                                <th>Name</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {amazons3Keys.map((apiKey, i) => (
                                <tr key={i}>

                                    <td>{apiKey.region}</td>
                                    <td>{apiKey.accessKey}</td>
                                    <td>{apiKey.secretKey}</td>
                                    <td>{apiKey.name}</td>
                                    <td><Button variant='danger' onClick={() => handleDeleteAmazonS3ApiKey(apiKey._id)}>Delete</Button></td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    <Form.Control type="text" placeholder="Enter region here..." value={newAmazonS3Key.region} onChange={e => setNewAmazonS3Key({ ...newAmazonS3Key, region: e.target.value })} />
                                </td>
                                <td>
                                    <Form.Control type="text" placeholder="Enter access key here..." value={newAmazonS3Key.accessKey} onChange={e => setNewAmazonS3Key({ ...newAmazonS3Key, accessKey: e.target.value })} />
                                </td>
                                <td>
                                    <Form.Control type="text" placeholder="Enter secret key here..." value={newAmazonS3Key.secretKey} onChange={e => setNewAmazonS3Key({ ...newAmazonS3Key, secretKey: e.target.value })} />
                                </td>
                                <td>
                                    <Form.Control type="text" placeholder="Enter name here..." value={newAmazonS3Key.name} onChange={e => setNewAmazonS3Key({ ...newAmazonS3Key, name: e.target.value })} />
                                </td>
                                <td>
                                    <Button variant='primary' onClick={() => handlePostAmazonS3ApiKey()}>
                                        Create
                                    </Button>
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setActiveModal()}>Close</Button>
                </Modal.Footer>
            </Modal>
            {/* BREAKTHROUGH FUEL */}
            <Modal show={activeModel?.name === 'Breakthrough Fuel' ? true : false} size="lg">
                <Modal.Header>
                    <Row>
                        <Col>
                            <Modal.Title>
                                {activeModel?.name}
                            </Modal.Title>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th colSpan={'100%'}>API Key</th>
                            </tr>
                        </thead>
                        <tbody>
                            {breakthroughfuelKeys.map((apiKey, i) => (
                                <tr key={i}>
                                    <td>{apiKey.apiUsername}</td>
                                    <td>{apiKey.apiPassword}</td>
                                    <td><Button variant='danger' onClick={() => handleDeleteBreakthroughfuelApiKey(apiKey._id)}>Delete</Button></td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    <Form.Control type="text" placeholder="Enter username here..." value={newBreakthroughfuelKey.apiUsername} onChange={e => setNewBreakthroughfuelKey({ ...newBreakthroughfuelKey, apiUsername: e.target.value })} />
                                </td>
                                <td>
                                    <Form.Control type="text" placeholder="Enter password here..." value={newBreakthroughfuelKey.apiPassword} onChange={e => setNewBreakthroughfuelKey({ ...newBreakthroughfuelKey, apiPassword: e.target.value })} />
                                </td>
                                <td>
                                    <Button variant='primary' onClick={() => handlePostBreakthroughfuelApiKey()}>
                                        Create
                                    </Button>
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setActiveModal()}>Close</Button>
                </Modal.Footer>
            </Modal>
            {/* GOOGLEMAPS */}
            <Modal show={activeModel?.name === 'Google Maps' ? true : false} size="lg">
                <Modal.Header>
                    <Row>
                        <Col>
                            <Modal.Title>
                                {activeModel?.name}
                            </Modal.Title>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th colSpan={'100%'}>API Key</th>
                            </tr>
                        </thead>
                        <tbody>
                            {googlemapsKeys.map((apiKey, i) => (
                                <tr key={i}>
                                    <td>{apiKey.apiKey}</td>
                                    <td><Button variant='danger' onClick={() => handleDeleteGoogleMapsApiKey(apiKey._id)}>Delete</Button></td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    <Form.Control type="text" placeholder="Enter apikey here..." value={newGoogleMapsKey} onChange={e => setNewGoogleMapsKey(e.target.value)} />
                                </td>
                                <td>
                                    <Button variant='primary' onClick={() => handlePostGoogleMapsApiKey()}>
                                        Create
                                    </Button>
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setActiveModal()}>Close</Button>
                </Modal.Footer>
            </Modal>
            {/* FOURKTIES */}
            <Modal show={activeModel?.name === 'Fourkites' ? true : false} size="lg">
                <Modal.Header>
                    <Row>
                        <Col>
                            <Modal.Title>
                                {activeModel?.name}
                            </Modal.Title>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th colSpan={'100%'}>API Key</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fourkitesKeys.map((apiKey, i) => (
                                <tr key={i}>
                                    <td>{apiKey.apiKey}</td>
                                    <td><Button variant='danger' onClick={() => handleDeleteFourkitesApiKey(apiKey._id)}>Delete</Button></td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    <Form.Control type="text" placeholder="Enter apikey here..." value={newFourkitesKey} onChange={e => setNewFourkitesKey(e.target.value)} />
                                </td>
                                <td>
                                    <Button variant='primary' onClick={() => handlePostFourkitesApiKey()}>
                                        Create
                                    </Button>
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setActiveModal()}>Close</Button>
                </Modal.Footer>
            </Modal>
            {/* JUSTRANSFORM */}
            <Modal show={activeModel?.name === 'Justransform' ? true : false} size="lg">
                <Modal.Header>
                    <Row>
                        <Col>
                            <Modal.Title>
                                {activeModel?.name}
                            </Modal.Title>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Password</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {justransformKeys.map((apiKey, i) => (
                                <tr key={i}>
                                    <td>{apiKey.apiUsername}</td>
                                    <td>{apiKey.apiPassword}</td>
                                    <td><Button variant='danger' onClick={() => handleDeleteJustransformApiKey(apiKey._id)}>Delete</Button></td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    <Form.Control type="text" placeholder="Enter username here..." value={newJustransformKey.apiUsername} onChange={e => setNewJustransformKey({ ...newJustransformKey, apiUsername: e.target.value })} />
                                </td>
                                <td>
                                    <Form.Control type="text" placeholder="Enter password here..." value={newJustransformKey.apiPassword} onChange={e => setNewJustransformKey({ ...newJustransformKey, apiPassword: e.target.value })} />
                                </td>
                                <td>
                                    <Button variant='primary' onClick={() => handlePostJustransformApiKey()}>
                                        Create
                                    </Button>
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setActiveModal()}>Close</Button>
                </Modal.Footer>
            </Modal>
            {/* KLEINSCHMIDT */}
            <Modal show={activeModel?.name === 'Kleinschmidt' ? true : false} size="lg">
                <Modal.Header>
                    <Row>
                        <Col>
                            <Modal.Title>
                                {activeModel?.name}
                            </Modal.Title>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th colSpan={'100%'}>API Key</th>
                            </tr>
                        </thead>
                        <tbody>
                            {kleinschmidtKeys.map((apiKey, i) => (
                                <tr key={i}>
                                    <td>{apiKey.apiKey}</td>
                                    <td><Button variant='danger' onClick={() => handleDeleteKleinschmidtApiKey(apiKey._id)}>Delete</Button></td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    <Form.Control type="text" placeholder="Enter apikey here..." value={newKleinschmidtKey} onChange={e => setNewKleinschmidtKey(e.target.value)} />
                                </td>
                                <td>
                                    <Button variant='primary' onClick={() => handlePostKleinschmidtApiKey()}>
                                        Create
                                    </Button>
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setActiveModal()}>Close</Button>
                </Modal.Footer>
            </Modal>
            {/* LOADCNXN */}
            <Modal show={activeModel?.name === 'LoadCnxn' ? true : false} size="xl">
                <Modal.Header>
                    <Row>
                        <Col>
                            <Modal.Title>
                                {activeModel?.name}
                            </Modal.Title>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col></Col>
                        <Col xs="auto"><img src={LOADCNXN_IMG} alt="LoadCnxn" height="100px" /></Col>
                        <Col xs="auto">
                            <Row>
                                <Col xs="auto"><h1>→</h1></Col>
                            </Row>
                            <Row>
                                <Col xs="auto"><h1>←</h1></Col>
                            </Row>
                        </Col>
                        <Col xs="auto"><img src={USPS_IMG} alt="USPS" height="100px" /></Col>
                        <Col></Col>
                    </Row>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Client</th>
                                <th>Username</th>
                                <th>Password</th>
                                <th>Grant Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loadcnxnKeys.map((apiKey, i) => (
                                <tr key={i}>
                                    <td>{apiKey.apiClient}</td>
                                    <td>{apiKey.apiUsername}</td>
                                    <td>{apiKey.apiPassword}</td>
                                    <td>{apiKey.apiGrantType}</td>
                                    <td><Button variant='danger' onClick={() => handleDeleteLoadCnxnApiKey(apiKey._id)}>Delete</Button></td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    <Form.Control type="text" placeholder="Enter client here..." value={newLoadCnxnKey.apiClient} onChange={e => setNewLoadCnxnKey({ ...newLoadCnxnKey, apiClient: e.target.value })} />
                                </td>
                                <td>
                                    <Form.Control type="text" placeholder="Enter username here..." value={newLoadCnxnKey.apiUsername} onChange={e => setNewLoadCnxnKey({ ...newLoadCnxnKey, apiUsername: e.target.value })} />
                                </td>
                                <td>
                                    <Form.Control type="text" placeholder="Enter password here..." value={newLoadCnxnKey.apiPassword} onChange={e => setNewLoadCnxnKey({ ...newLoadCnxnKey, apiPassword: e.target.value })} />
                                </td>
                                <td>
                                    <Form.Control type="text" placeholder="Enter grant type here..." value={newLoadCnxnKey.apiGrantType} onChange={e => setNewLoadCnxnKey({ ...newLoadCnxnKey, apiGrantType: e.target.value })} />
                                </td>
                                <td>
                                    <Button variant='primary' onClick={() => handlePostLoadCnxnApiKey()}>
                                        Create
                                    </Button>
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setActiveModal()}>Close</Button>
                </Modal.Footer>
            </Modal>
            {/* MOTIVE */}
            <Modal show={activeModel?.name === 'Motive' ? true : false} size="lg">
                <Modal.Header>
                    <Row>
                        <Col>
                            <Modal.Title>
                                {activeModel?.name}
                            </Modal.Title>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th colSpan={'100%'}>API Key</th>
                            </tr>
                        </thead>
                        <tbody>
                            {motiveKeys.map((apiKey, i) => (
                                <tr key={i}>
                                    <td>{apiKey.apiKey}</td>
                                    <td><Button variant='danger' onClick={() => handleDeleteMotiveApiKey(apiKey._id)}>Delete</Button></td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    <Form.Control type="text" placeholder="Enter key here..." value={newMotiveKey} onChange={e => setNewMotiveKey(e.target.value)} />
                                </td>
                                <td>
                                    <Button variant='primary' onClick={() => handlePostMotiveApiKey()}>
                                        Create
                                    </Button>
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setActiveModal()}>Close</Button>
                </Modal.Footer>
            </Modal>
            {/* SAMSARA */}
            <Modal show={activeModel?.name === 'Samsara' ? true : false} size="lg">
                <Modal.Header>
                    <Row>
                        <Col>
                            <Modal.Title>
                                {activeModel?.name}
                            </Modal.Title>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th colSpan={'100%'}>API Key</th>
                            </tr>
                        </thead>
                        <tbody>
                            {samsaraKeys.map((apiKey, i) => (
                                <tr key={i}>
                                    <td>{apiKey.apiKey}</td>
                                    <td><Button variant='danger' onClick={() => handleDeleteSamsaraApiKey(apiKey._id)}>Delete</Button></td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    <Form.Control type="text" placeholder="Enter key here..." value={newSamsaraKey} onChange={e => setNewSamsaraKey(e.target.value)} />
                                </td>
                                <td>
                                    <Button variant='primary' onClick={() => handlePostSamsaraApiKey()}>
                                        Create
                                    </Button>
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setActiveModal()}>Close</Button>
                </Modal.Footer>
            </Modal>
            {/* SMTP */}
            <Modal show={activeModel?.name === 'SMTP' ? true : false} size="lg">
                <Modal.Header>
                    <Row>
                        <Col>
                            <Modal.Title>
                                {activeModel?.name}
                            </Modal.Title>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>API Key</th>
                                <th>Channel</th>
                            </tr>
                        </thead>
                        <tbody>
                            {smtpKeys.map((apiKey, i) => (
                                <tr key={i}>
                                    <td>{apiKey.apiKey}</td>
                                    <td>{apiKey.channel}</td>
                                    <td><Button variant='danger' onClick={() => handleDeleteSMTPApiKey(apiKey._id)}>Delete</Button></td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    <Form.Control type="text" placeholder="Enter key here..." value={newSMTPKey.apiKey} onChange={e => setNewSMTPKey({ ...newSMTPKey, apiKey: e.target.value })} />
                                </td>
                                <td>
                                    <Form.Control type="text" placeholder="Enter channel here..." value={newSMTPKey.channel} onChange={e => setNewSMTPKey({ ...newSMTPKey, channel: e.target.value })} />
                                </td>
                                <td>
                                    <Button variant='primary' onClick={() => handlePostSMTPApiKey()}>
                                        Create
                                    </Button>
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setActiveModal()}>Close</Button>
                </Modal.Footer>
            </Modal>
            {/* TWILIO */}
            <Modal show={activeModel?.name === 'Twilio' ? true : false} size="lg">
                <Modal.Header>
                    <Row>
                        <Col>
                            <Modal.Title>
                                {activeModel?.name}
                            </Modal.Title>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Account</th>
                                <th>Token</th>
                            </tr>
                        </thead>
                        <tbody>
                            {twilioKeys.map((apiKey, i) => (
                                <tr key={i}>
                                    <td>{apiKey.accountSid}</td>
                                    <td>{apiKey.authToken}</td>
                                    <td><Button variant='danger' onClick={() => handleDeleteTwilioApiKey(apiKey._id)}>Delete</Button></td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    <Form.Control type="text" placeholder="Enter account here..." value={newTwilioKey.accountSid} onChange={e => setNewTwilioKey({ ...newTwilioKey, accountSid: e.target.value })} />
                                </td>
                                <td>
                                    <Form.Control type="text" placeholder="Enter token here..." value={newTwilioKey.authToken} onChange={e => setNewTwilioKey({ ...newTwilioKey, authToken: e.target.value })} />
                                </td>
                                <td>
                                    <Button variant='primary' onClick={() => handlePostTwilioApiKey()}>
                                        Create
                                    </Button>
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setActiveModal()}>Close</Button>
                </Modal.Footer>
            </Modal>



            {/* Display */}
            <Card style={{ border: 0, borderRadius: 0 }}>
                <Card.Header>
                    <b>IoT - Internet of Things</b> {!set && <><Spinner size='sm' /> Loading...</>}
                </Card.Header>
                <AlertDisplay alertState={alertState} />
                <Card.Body className="d-flex justify-content-space-evenly align-items-center flex-wrap">
                    {generateServiceCards()}
                </Card.Body>
            </Card>
        </Container>
    );
}

export default IOT;
