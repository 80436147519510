import React, { createContext, useContext, useState } from "react";

const SubscriptionContext = createContext();

export function useSubscription() {
  return useContext(SubscriptionContext);
}

export function SubscriptionProvider({ children }) {
  const [subscription, setSubscription] = useState(null);

  function subscribe(subscription) {
    setSubscription(subscription);
  }

  function unsubscribe() {
    setSubscription(null);
  }

  return (
    <SubscriptionContext.Provider value={{ subscription, subscribe, unsubscribe }}>
      {children}
    </SubscriptionContext.Provider>
  );
}