import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/auth';
import AlertDisplay from '../../components/AlertDisplay';
import { Container, Spinner } from 'react-bootstrap';
import Api from '../../utils/Api';
import DataDisplay from '../../components/DataDisplay';

const AccidentSection = () => {
  const { idToken } = useAuth();
  const [set, didSet] = useState()
  const [data, setData] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const alertState = {alerts, setAlerts}

  async function fetchAccidentData(){
    try {
      const accidentData = await Api.get('/safety/accidents', idToken)
      if(accidentData && accidentData.length > 0){
        setData(accidentData)
      }
    } catch {
      setAlerts([...alerts, {vairant:'warning',message:'An error occured fetching accidents'}])
    } finally {
      didSet(true)
    }
  }
  
  useEffect(()=>{
    if(!set){
      fetchAccidentData();
    }
  },[set])

  return (
    <Container fluid>
      <AlertDisplay alertState={alertState} />
      {!set ?
        <Spinner />
        :
        <DataDisplay
          sourceName={'MCTMS-Accident-Data'}
          dataSource={data}
          lsKey={'@mctms-dafet-accident-data-display'}
          urlKey='_id'
          urlPath='/safety/accidents/update-form/'
        />
      }
    </Container>
  );
};

export default AccidentSection;
 