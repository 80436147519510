import React from 'react';
import { Container, Spinner } from "react-bootstrap";

function Loading() {
    
  return (
    <Container className="d-flex justify-content-center align-items-center vw-100 vh-100">
        <Spinner/>
    </Container>
  );
}

export default Loading