import React, { useState } from 'react';
import { Form, Button, Container, Card, Spinner } from 'react-bootstrap';
import { useAuth } from '../context/auth';
import AlertDisplay from '../components/AlertDisplay';
import Api from '../utils/Api';

const FeatureRequest = () => {
    const { user, idToken } = useAuth();
    const [featureRequest, setFeatureRequest] = useState('');
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [isSending, setIsSending] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSending(true)
        try {
            await Api.post('/email/feature-request', { email: user.email, message: featureRequest, }, idToken)
            setFeatureRequest('');
            setAlerts([...alerts, { variant: 'success', message: `Sent` }]);
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: `Error submitting request:${error}` }]);
        } finally {
            setIsSending(false)
        }
    };

    return (
        <Container>
            <AlertDisplay alertState={alertState} />
            <br/>
            <Card>
                <Card.Header>
                    Feature Request
                </Card.Header>
                {isSending ?
                    <Spinner />
                :
                    <Card.Body>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter your email"
                                    value={user.email}
                                    required
                                    readOnly
                                    disabled
                                />
                            </Form.Group>
                            <Form.Group controlId="formFeatureRequest">
                                <Form.Label>Feature description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Describe the feature..."
                                    value={featureRequest}
                                    onChange={(e) => setFeatureRequest(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </Form>
                    </Card.Body>
                }

            </Card>
        </Container>

    );
};

export default FeatureRequest;
