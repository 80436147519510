import { useState, useEffect } from 'react';

const useGeolocation = () => {
  const [position, setPosition] = useState({
    latitude: null,
    longitude: null,
    altitude: null,
    altitudeAccuracy: null,
    accuracy: null,
    heading: null,
    speed: null,
    timestamp: null,
    error: null,
    permissionDenied: false,
  });

  useEffect(() => {
    let watchId;

    const checkPermissionAndWatch = async () => {
      try {
        const result = await navigator.permissions.query({ name: 'geolocation' });
        if (result.state === 'denied') {
          setPosition((prevState) => ({
            ...prevState,
            error: 'Location access denied by user.',
            permissionDenied: true,
          }));
          return;
        }

        if (!navigator.geolocation) {
          setPosition((prevState) => ({
            ...prevState,
            error: 'Geolocation is not supported by this browser.',
          }));
          return;
        }

        const onSuccess = (position) => {
          setPosition({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            altitude: position.coords.altitude,
            altitudeAccuracy: position.coords.altitudeAccuracy,
            accuracy: position.coords.accuracy,
            heading: position.coords.heading,
            speed: position.coords.speed,
            timestamp: position.timestamp,
            error: null,
            permissionDenied: false,
          });
        };

        const onError = (error) => {
          setPosition((prevState) => ({
            ...prevState,
            error: error.message,
            permissionDenied: error.code === error.PERMISSION_DENIED,
          }));
        };

        watchId = navigator.geolocation.watchPosition(onSuccess, onError);
      } catch (err) {
        setPosition((prevState) => ({
          ...prevState,
          error: 'Error checking permission: ' + err.message,
        }));
      }
    };

    checkPermissionAndWatch();

    return () => {
      if (watchId !== undefined) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, []);

  return position;
};

export default useGeolocation;
