import React, { useRef, useEffect,  } from 'react';
import * as d3 from 'd3';
import { useNavigate } from 'react-router-dom';

function ShipmentGanttChartV3({ loadtenderTimelineData, feature }) {
    const chartRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {

        function isShipmentCovered(entry) {
            const covered = entry?.Coverage;
            return covered
        }

        function isShipmentCanceled(entry) {
            const canceled = entry?.type === 'Canceled' ? true : false
            return canceled
        }

        function isShipmentRejected(entry) {
            const rejected = entry?.type === 'Rejected' ? true : false
            return rejected
        }

        function handleShipmentClick(entry) {
            const shipmentId = entry?.target?.__data__?.ShipmentId;
            if (shipmentId) {
                const url = `/${feature}/${shipmentId}`;
                navigate(url);
            }
        }

        if (!chartRef.current || !loadtenderTimelineData) return;

        // Clear the previous chart and its event listeners
        const svgContainer = d3.select(chartRef.current);
        svgContainer.selectAll('svg').remove();

        // Group trips by contract number and sort by start date
        const groupedData = loadtenderTimelineData.reduce((acc, entry) => {
            const contractNumber = entry.contract;
            if (!acc[contractNumber]) {
                acc[contractNumber] = [];
            }
            acc[contractNumber].push(entry);
            return acc;
        }, {});

        // Convert grouped data into an array of arrays
        const groupedTrips = Object.values(groupedData);

        // Flatten grouped trips array
        const data = groupedTrips.reduce((acc, trips) => [...acc, ...trips], []);

        data.sort((a, b) => new Date(a.startdate) - new Date(b.startdate));

        const margin = { top: 20, right: 30, bottom: 60, left: 40 };
        const containerWidth = chartRef.current.clientWidth;
        const containerHeight = data.length * 40 + margin.bottom;

        const width = containerWidth - margin.left - margin.right;
        const height = containerHeight - margin.top - margin.bottom;

        const svg = svgContainer
            .append('svg')
            .attr('width', '100%')
            .attr('height', containerHeight)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        const xScale = d3.scaleTime()
            .domain([d3.min(data, d => new Date(d.startdate)), d3.max(data, d => new Date(d.enddate))])
            .range([0, width]);

        const yScale = d3.scaleBand()
            .domain(data.map((d, i) => i))
            .range([0, height])
            .padding(0.1);

        svg.selectAll('.bar')
            .data(data)
            .enter()
            .append('rect')
            .attr('class', 'bar')
            .attr('x', d => xScale(new Date(d.startdate)))
            .attr('y', (d, i) => yScale(i))
            .attr('width', d => xScale(new Date(d.enddate)) - xScale(new Date(d.startdate)))
            .attr('height', yScale.bandwidth())
            .style('cursor', 'pointer')
            .style('fill', data =>
                    isShipmentRejected(data) ? 'rgba(87, 90, 95, 0.75)' :
                    isShipmentCanceled(data)
                    ? 'rgba(255, 0, 0, 0.75)' : isShipmentCovered(data) 
                    ? 'rgba(110,182,255,0.75)' : 'rgba(251,183,14,0.75)') // Change the fill color conditionally
            .on('click', data => handleShipmentClick(data));


        svg.selectAll('.timeline-text')
            .data(data)
            .enter()
            .append('text')
            .attr('class', 'timeline-text')
            .attr('x', d => xScale(new Date(d.startdate)) + 5)
            .attr('y', (d, i) => yScale(i) + yScale.bandwidth() / 2)
            .text(d => d.contract + ' - ' + d.trip + ' | ' + d.ShipmentId + ' | ' + d.Coverage)
            .style('fill', 'black')
            .style('cursor', 'pointer')
            .attr('alignment-baseline', 'middle')
            .on('click', data => handleShipmentClick(data));

        const startTime = d3.min(data, d => new Date(d.startdate));
        const endTime = d3.max(data, d => new Date(d.enddate));
        const hourIncrements = d3.timeHour.every(1);
        const linePoints = d3.timeHours(startTime, endTime, hourIncrements);

        svg.selectAll('.vertical-line')
            .data(linePoints)
            .enter()
            .append('line')
            .attr('class', 'vertical-line')
            .attr('x1', d => xScale(d))
            .attr('x2', d => xScale(d))
            .attr('y1', 0)
            .attr('y2', height)
            .style('stroke', 'red');

        svg.append('marker')
            .attr('id', 'top-square')
            .attr('markerWidth', 50)
            .attr('markerHeight', 20)
            .attr('refX', 25)
            .attr('refY', 10)
            .append('rect')
            .attr('width', 50)
            .attr('height', 20)
            .attr('fill', 'red');

        svg.select('#top-square')
            .append('text')
            .text('NOW')
            .attr('x', 7)
            .attr('y', 15)
            .attr('font-size', '1em')
            .attr('fill', 'black');

        svg.append('marker')
            .attr('id', 'bottom-square')
            .attr('markerWidth', 50)
            .attr('markerHeight', 20)
            .attr('refX', 25)
            .attr('refY', 10)
            .append('rect')
            .attr('width', 50)
            .attr('height', 20)
            .attr('fill', 'red');

        svg.select('#bottom-square')
            .append('text')
            .text('NOW')
            .attr('x', 7)
            .attr('y', 15)
            .attr('font-size', '1em')
            .attr('fill', 'black');

        // Add a red vertical line for the current time
        const currentTime = new Date();
        svg.append('line')
            .attr('class', 'current-time-line')
            .attr('x1', xScale(currentTime))
            .attr('x2', xScale(currentTime))
            .attr('y1', 0)
            .attr('y2', height)
            .style('stroke', 'red')
            .style('stroke-dasharray', '7')
            .attr('marker-start', 'url(#top-square)')
            .attr('marker-end', 'url(#bottom-square)');

        const timeFormat = d3.timeFormat('%m/%d/%y %H:%M');
        const xAxis = d3.axisBottom(xScale)
            .ticks(5)
            .tickFormat(timeFormat);

        svg.append('g')
            .attr('class', 'x-axis')
            .attr('transform', `translate(0,${height})`)
            .style('font-size', '1em')
            .call(xAxis);

        svg.append('g')
            .attr('class', 'x-axis')
            .attr('transform', `translate(0,0)`)
            .style('font-size', '1em')
            .call(xAxis)
            .selectAll('text')
            .attr('dy', '-1em');

        svg.append('g')
            .attr('class', 'y-axis')
            .call(d3.axisLeft(yScale).tickFormat(() => ''));

    }, [feature, loadtenderTimelineData, navigate]);

    return (
        <div>
            <div ref={chartRef} style={{ width: '100%' }}></div>
        </div>
    );
}

export default ShipmentGanttChartV3;
