import { Container, Card, Row, Col, Spinner, Alert, Badge, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import React, { useState, useEffect } from 'react'
import { FaPlusCircle } from 'react-icons/fa'
import { useAuth } from '../../context/auth';
import DataDisplay from "../../components/DataDisplay";
import { Link } from "react-router-dom";
import AlertDisplay from "../../components/AlertDisplay";
import DataDisplayV3 from "../../components/DataDisplayV3";

function Frequency({feature}) {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [frequencies, setFrequencies] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [query, setQuery] = useState({
        isActive: true
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const queryParams = new URLSearchParams(query);
                const data = await Api.query('/frequencies', queryParams, idToken);
                const cleanFrequencies = data.map(frequency => {
                    return {
                        _id: frequency._id,
                        'Name': frequency.name,
                        'Sun': frequency.sunday ? '✔' : 'ˣ',
                        'Mon': frequency.monday ? '✔' : 'ˣ',
                        'Tue': frequency.tuesday ? '✔' : 'ˣ',
                        'Wed': frequency.wednesday ? '✔' : 'ˣ',
                        'Thu': frequency.thursday ? '✔' : 'ˣ',
                        'Fri': frequency.friday ? '✔' : 'ˣ',
                        'Sat': frequency.saturday ? '✔' : 'ˣ',
                        'Special Ops': frequency.hasSpecialOps ? '🚨' : '−',
                        'Detail': frequency.detail,
                        'Active': frequency.isActive ? '✅' : '❌',
                    }
                })

                setFrequencies(cleanFrequencies)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error. ' + JSON.stringify(error) }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    }, [set, idToken, alerts, query])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{ borderRadius: 0, border:0 }}>
                <Card.Header>
                    <Card.Title>
                        <Row>
                            <Col>Frequency</Col>
                            <Col style={{ textAlign: 'center' }}>
                                <Badge
                                    bg={query.isActive ? 'success' : 'danger'}
                                    onClick={() => { setQuery({ ...query, isActive: !query.isActive }); didSet(false) }}
                                >
                                    {query.isActive ? 'Active' : 'Inactive'}
                                </Badge>
                            </Col>
                            <Col>
                                <Link className="float-end" to={`/${feature}/frequency-form`}><FaPlusCircle /> Frequency</Link>
                            </Col>
                        </Row>
                    </Card.Title>
                </Card.Header>
                {set ?
                    <Card.Body>
                        {frequencies.length > 0 ?
                            <>
                                <DataDisplayV3
                                    dataSource={frequencies}
                                    lsKey={'@mctms-frequencies-display'}
                                    urlPath={`/${feature}/frequency/`}
                                    urlKey={'_id'}
                                    popKeys={['_id']}
                                />
                            </>
                            :
                            <>
                                No data.
                            </>
                        }
                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

            </Card>
        </Container>
    );
}

export default Frequency;