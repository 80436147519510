import { Container } from "react-bootstrap";

function LoadTenderTemplates(){
    return (
        <Container fluid style={{ margin: '0', padding:'0' }}>
            List of uniquely occuring shipments / and a list of who has previously ran the trip
        </Container>
    )
}

export default LoadTenderTemplates;