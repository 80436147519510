import 'bootstrap/dist/css/bootstrap.min.css';
import Api from "../../utils/Api";
import { useAuth } from "../../context/auth";
import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import DataVisual from './components/DataVisual';
import { DateTime } from 'luxon';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import AlertDisplay from '../../components/AlertDisplay';
import { Button, ButtonGroup, Card, Col, Container, Form, Row } from 'react-bootstrap';

function SafetyDashboard() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = {alerts, setAlerts};
    const [injuryDataProcessed, setInjuryDataProcessed] = useState({});
    const [accidentDataProcessed, setAccidentDataProcessed] = useState({});
    const [selectedDataSource, setSelectedDataSource] = useState('all');    
    const [injuryData, setInjuryData] = useState([]);
    const [accidentData, setAccidentData] = useState([]);
    const [columnCount, setColumnCount] = useState(3);

    const injuryOptions = [
        { key: 'injuryAddressCity', label: 'City' },
        { key: 'injuryAddressState', label: 'State' },
        { key: 'day', label: 'Day' },
        { key: 'age', label: 'Age' },
        { key: 'timeOfInjury', label: 'Time of Day' },
        { key: 'preventable', label: 'Preventable' },
        { key: 'injuryType', label: 'Injury Type' }
    ];

    const accidentOptions = [
        { key: 'accidentCity', label: 'City' },
        { key: 'accidentState', label: 'State' },
        { key: 'companyCost', label: 'Cost' },
        { key: 'accidentType', label: 'Type' },
        { key: 'timeOfAccident', label: 'Time of Day' },
        { key: 'roadType', label: 'Road Type' },
        { key: 'dotRecordable', label: 'DOT Recordable' },
        { key: 'preventable', label: 'Preventable' },
        { key: 'driverAge', label: 'Driver Age' },
    ];

    async function fetchSafetyData() {
        try {
            const allAccidents = await Api.get(`/safety/accidents`, idToken);
            const allInjuries = await Api.get(`/safety/injuries`, idToken); 
            setAccidentData(allAccidents)
            setInjuryData(allInjuries)
        } catch {
            setAlerts([...alerts, {variant:'warning', message:'An error occured getting accidents and injuries.'}])
        } finally {
            didSet(true)
        }
    }

    useEffect(() => {
        if(!set){
            fetchSafetyData();
        }
    }, [set])

    useEffect(() => {
        let processed = {};
        injuryOptions.forEach(({key, label}) => {
            processed[key] = aggregateData(injuryData, key);
        });
        setInjuryDataProcessed(processed);
    }, [injuryData]);

    useEffect(() => {
        let processed = {};
        accidentOptions.forEach(({key, label}) => {
            processed[key] = aggregateData(accidentData, key);
        });
        setAccidentDataProcessed(processed);
    }, [accidentData]);

    const calculateAgeBracket = (birthDate) => {
        const age = Math.floor(DateTime.now().diff(birthDate, 'years').years);
        if (age < 25) return 'Under 25';
        if (age >= 70) return '70+';
        const ageBracket = `${Math.floor(age / 5) * 5}-${Math.floor(age / 5) * 5 + 4}`;
        return ageBracket;
    };

    const calculateTimeOfDay = (time) => {
        const hour = time.hour;
        const startHour = Math.floor(hour / 4) * 4;
        const startTime = String(startHour).padStart(2, '0') + '00'; // Format start hour as 'HH00'
        const endTime = String(startHour + 3).padStart(2, '0') + '59'; // Format end hour as 'HH00'
        return `${startTime}-${endTime}`;
    };

    const aggregateData = (data, field) => {
        if (field === 'day') {
            const dataWithDay = data.map(item => {
                const date = new Date(item.dateOfInjury);
                const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' });
                return { ...item, dayOfWeek };
            });
            const daysOfWeekOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

            const groupedData = Array.from(d3.rollup(dataWithDay, v => v.length, d => d.dayOfWeek), ([key, value]) => ({ key, value }));

            // Sort the grouped data based on the order of days in the week
            const sortedData = daysOfWeekOrder.reverse().map(day => ({
                key: day,
                value: (groupedData.find(item => item.key === day) || { value: 0 }).value
            }));

            return sortedData;
        }
        else if (field === 'companyCost') {
            return Array.from(d3.rollup(data, v => d3.sum(v, d => d.companyCost), d => d.accidentCity), ([key, value]) => ({ key, value })).sort((a, b) => d3.ascending(a.value, b.value));
        }
        else if (field === 'age') {
            const ageBracketOrder = ['Under 25', '25-29', '30-34', '35-39', '40-44', '45-49', '50-54', '55-59', '60-64', '65-69', '70+'];

            // Create an initial array with all age groups and set their initial values to 0
            const allAgeGroups = ageBracketOrder.map(ageGroup => ({ key: ageGroup, value: 0 }));

            data.forEach(item => {
                const dateOfBirth = DateTime.fromISO(item.dateOfBirth);
                const ageBracket = calculateAgeBracket(dateOfBirth);
                // Find the corresponding age group in the allAgeGroups array and increment its value
                const ageGroupIndex = allAgeGroups.findIndex(ageGroup => ageGroup.key === ageBracket);
                if (ageGroupIndex !== -1) {
                    allAgeGroups[ageGroupIndex].value++;
                }
            });

            return allAgeGroups;
        }
        else if (field === 'driverAge') {
            const ageBracketOrder = ['Under 25', '25-29', '30-34', '35-39', '40-44', '45-49', '50-54', '55-59', '60-64', '65-69', '70+'];

            // Create an initial array with all age groups and set their initial values to 0
            const allAgeGroups = ageBracketOrder.map(ageGroup => ({ key: ageGroup, value: 0 }));

            data.forEach(item => {
                const dateOfBirth = DateTime.fromISO(item.companyDriverBirthDate);
                const ageBracket = calculateAgeBracket(dateOfBirth);
                // Find the corresponding age group in the allAgeGroups array and increment its value
                const ageGroupIndex = allAgeGroups.findIndex(ageGroup => ageGroup.key === ageBracket);
                if (ageGroupIndex !== -1) {
                    allAgeGroups[ageGroupIndex].value++;
                }
            });

            return allAgeGroups;
        }
        else if (field === 'timeOfInjury') {
            const timeRanges = ['0000-0359', '0400-0759', '0800-1159', '1200-1559', '1600-1959', '2000-2359'];
            const allTimeRanges = timeRanges.reverse().map(timeRange => ({ key: timeRange, value: 0 }));

            data.forEach(item => {
                const timeOfInjury = DateTime.fromISO(item.timeOfInjury);
                item.timeOfDay = calculateTimeOfDay(timeOfInjury);

                const timeOfInjuryIndex = allTimeRanges.findIndex(timeRange => timeRange.key === item.timeOfDay);
                if (timeOfInjuryIndex !== -1) {
                    allTimeRanges[timeOfInjuryIndex].value++;
                }
            });

            return allTimeRanges;
        } else if (field === 'timeOfAccident') {
            const timeRanges = ['0000-0359', '0400-0759', '0800-1159', '1200-1559', '1600-1959', '2000-2359'];
            const allTimeRanges = timeRanges.reverse().map(timeRange => ({ key: timeRange, value: 0 }));

            data.forEach(item => {
                const timeOfAccident = DateTime.fromISO(item.time);
                item.timeOfDay = calculateTimeOfDay(timeOfAccident);

                const timeOfAccidentIndex = allTimeRanges.findIndex(timeRange => timeRange.key === item.timeOfDay);
                if (timeOfAccidentIndex !== -1) {
                    allTimeRanges[timeOfAccidentIndex].value++;
                }
            });

            return allTimeRanges;
        }
        else {
            return Array.from(d3.rollup(data, v => v.length, d => d[field]), ([key, value]) => ({ key, value })).sort((a, b) => d3.ascending(a.value, b.value));
        }
    };

    const renderGridItems = (dataProcessed, options, dataSource) => {
        const columnWidth = 12 / columnCount;
        return options.map(option => (
          <Col key={option.key} xs={12} md={columnWidth} lg={columnWidth} className='chart-grid-item'>
            <div>
              <DataVisual
                data={dataProcessed[option.key]}
                fields={{ x: option.key === 'cost' ? 'key' : 'key', y: option.key === 'cost' ? 'value' : 'value' }}
                title={`${dataSource} Frequency by ${option.label}`}
                aggregateData={true}
              />
            </div>
          </Col>
        ));
    };

    const handleColumnCountChange = (count) => {
        setColumnCount(count);
    }

    const handleDataSourceChange = (value) => {
        setSelectedDataSource(value);
    }

    const handlePrint = () => {
        const inputElements = document.querySelectorAll('#grid-section .chart-grid-item');
        const pdf = new jsPDF('portrait');
        let count = 0;

        const addChartToPDF = (inputElement, index) => {
            html2canvas(inputElement, { scale: 3 }).then((canvas) => {
                const imgData = canvas.toDataURL('image/jpeg', 1.0);
                const imgWidth = pdf.internal.pageSize.getWidth() - 40;
                const imgHeight = (pdf.internal.pageSize.getHeight() / 2) - 10;

                if (index % 2 === 0 && index !== 0) {
                    pdf.addPage();
                }

                const yPos = (index % 2) * (pdf.internal.pageSize.getHeight() / 2) + 10;
                pdf.addImage(imgData, 'JPEG', 0, yPos, imgWidth, imgHeight);

                if (count < inputElements.length - 1) {
                    addChartToPDF(inputElements[++count], count);
                } else {
                    pdf.save('data_overview.pdf');
                }
            });
        };

        if (inputElements.length > 0) {
            addChartToPDF(inputElements[count], count);
        }
    };

    return (
        <Container>
            <AlertDisplay alertState={alertState}/>

            <Card border="secondary">
                <Card.Header as="h4">
                    Safety Dashboard - Overview
                </Card.Header>

                <Row>
                    <Col sm={12} md={4}>
                        <div className="d-flex justify-content-center justify-content-md-start">
                            <ButtonGroup style={{ margin: '10px', width: '100%' }}>    
                                <Button
                                    variant={selectedDataSource === 'all' ? 'primary' : 'secondary'}
                                    onClick={() => handleDataSourceChange('all')}
                                    style={{ flex: 1, border: '1px solid black'}}
                                >
                                    All
                                </Button>
                                <Button
                                    variant={selectedDataSource === 'injury' ? 'primary' : 'secondary'}
                                    onClick={() => handleDataSourceChange('injury')}
                                    style={{ flex: 1, border: '1px solid black'}}
                                >
                                    Injury
                                </Button>
                                <Button
                                    variant={selectedDataSource === 'accident' ? 'primary' : 'secondary'}
                                    onClick={() => handleDataSourceChange('accident')}
                                    style={{ flex: 1, border: '1px solid black'}}
                                >
                                    Accident
                                </Button>
                            </ButtonGroup>
                        </div>
                    </Col>
                    <Col sm={12} md={4}>
                        <div className="d-flex justify-content-center justify-content-md-start">
                            <div style={{ margin: '10px', textAlign: 'center' }}>
                                <Form.Label style={{ marginTop: "5px", fontWeight: 'bold'}} className="d-none d-md-block">Columns: </Form.Label>
                            </div>
                            <ButtonGroup style={{ margin: '10px', width: '100%' }} className="d-none d-md-block">
                                <Button
                                    variant={columnCount === 1 ? 'primary' : 'secondary'}
                                    onClick={() => handleColumnCountChange(1)}
                                    style={{ flex: 1, border: '1px solid black'}}
                                >
                                    1
                                </Button>
                                <Button
                                    variant={columnCount === 2 ? 'primary' : 'secondary'}
                                    onClick={() => handleColumnCountChange(2)}
                                    style={{ flex: 1, border: '1px solid black'}}
                                >
                                    2
                                </Button>
                                <Button
                                    variant={columnCount === 3 ? 'primary' : 'secondary'}
                                    onClick={() => handleColumnCountChange(3)}
                                    style={{ flex: 1, border: '1px solid black'}}
                                >
                                    3
                                </Button>
                            </ButtonGroup>
                        </div>
                    </Col>
                    <Col>
                        <div style={{ textAlign: 'right', margin: '10px' }}>
                            <Button onClick={handlePrint} style={{ flex: 1, border: '1px solid black'}}>Print to PDF</Button>
                        </div>
                    </Col>
                </Row>

                <Row id="grid-section">
                    {selectedDataSource === 'all' && (
                    <>
                        {renderGridItems(injuryDataProcessed, injuryOptions, 'Injury')}
                        {renderGridItems(accidentDataProcessed, accidentOptions, 'Accident')}
                    </>
                    )}
                    {selectedDataSource === 'injury' && (
                    <>
                        {renderGridItems(injuryDataProcessed, injuryOptions, 'Injury')}
                    </>
                    )}
                    {selectedDataSource === 'accident' && (
                    <>
                        {renderGridItems(accidentDataProcessed, accidentOptions, 'Accident')}
                    </>
                    )}
                </Row>
            </Card>
        </Container>
    );
};

export default SafetyDashboard;



