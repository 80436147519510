import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';

const PhoneNumberInput = ({ textSize, readOnly, disabled }) => {
  const [phoneNumber, setPhoneNumber] = useState('');

  const MAX_LENGTH = 14; // Maximum allowed length including formatting characters

  const formatPhoneNumber = (input) => {
    // Remove all non-digit characters from the input
    const cleaned = ('' + input).replace(/\D/g, '');
    
    // Format the phone number as (123) 456-7890
    const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    return formatted;
  };

  const handleInputChange = (event) => {
    const input = event.target.value;

    // Check if the input length exceeds the maximum allowed length
    if (input.length > MAX_LENGTH) {
      return; // Do nothing if the input exceeds the limit
    }

    // Format and update the phone number
    const formattedNumber = formatPhoneNumber(input);
    setPhoneNumber(formattedNumber);
  };

  return (
    <Form.Group controlId="phoneNumber">
      <Form.Control
        type="text"
        size={textSize}
        value={phoneNumber}
        onChange={handleInputChange}
        maxLength={MAX_LENGTH}
        placeholder="(123) 456-7890"
        readOnly={readOnly}
        disabled={disabled}

      />
    </Form.Group>
  );
};

export default PhoneNumberInput;