import React, { useState, useEffect } from 'react'
import { Container, Card,  Spinner, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';
import { useSettings } from '../../context/settings';
import AlertDisplay from '../../components/AlertDisplay';
import DataDisplayV3 from '../../components/DataDisplayV3';

function mapArraysByKey(array1, array2, key) {
    const result = [];

    array1.forEach((obj1) => {
        const matchedObj2 = array2.find((obj2) => obj2[key] === obj1[key]);

        if (matchedObj2) {
            result.push([obj1, matchedObj2]);
        } else {
            result.push([obj1, undefined]);
        }
    });

    array2.forEach((obj2) => {
        const matchedObj1 = array1.find((obj1) => obj1[key] === obj2[key]);

        if (!matchedObj1) {
            result.push([undefined, obj2]);
        }
    });

    return result;
}

function gmtToLocal(gmtTimestamp, timezone) {
    const gmtDate = new Date(gmtTimestamp);
    const localDate = new Intl.DateTimeFormat('en-US', {
        timeZone: timezone,
        dateStyle: 'full',
        timeStyle: 'full',
    }).format(gmtDate);
    console.log(localDate)
    return localDate;
}

function AccessProfile() {
    const { idToken } = useAuth();
    const { settings } = useSettings();
    const [set, didSet] = useState();
    const [profiles, setProfiles] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [showprofiles, setShowProfiles] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const p = await Api.get('/access/profiles', idToken);
                const u = await Api.get('/firebase/users', idToken);

                const usersprofiles = mapArraysByKey(u, p, 'uid')

                function getArraysDefined(usersprofiles) {
                    const undefinedArrays = usersprofiles.filter(([f, s]) => f !== undefined && s !== undefined);
                    return undefinedArrays;
                }

                function getArraysUndefined(usersprofiles) {
                    const undefinedArrays = usersprofiles.filter(([_, s]) => s === undefined);
                    return undefinedArrays;
                }

                const profilesArray = getArraysDefined(usersprofiles);
                const newUserArray = getArraysUndefined(usersprofiles);
                //
                const cleanProfiles = profilesArray.map(([user, profile]) => {
                    const lastSignInTime = gmtToLocal(user?.metadata?.lastSignInTime, settings.timezone);
                    const lastRefreshTime = gmtToLocal(user?.metadata?.lastRefreshTime, settings.timezone);
                    const createdTime = gmtToLocal(user?.metadata?.creationTime, settings.timezone);
                    return {
                        _id: profile._id,
                        'Name': `${profile.firstname} ${profile.lastname}`,
                        'Email': user.email,
                        'Role': profile.role.name,
                        'Last Refreseh': lastRefreshTime,
                        'Last Signin': lastSignInTime,
                        'Created': createdTime,
                        'Disabled': user.disabled
                    }
                })
                setProfiles(cleanProfiles)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error', }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    }, [idToken, set, alerts, settings])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{ border: 0, borderRadius: 0 }}>
                <Card.Header>
                    <Card.Title>
                        Users x {profiles.length}
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    {!set ? <Spinner /> :
                        <DataDisplayV3
                            dataSource={profiles}
                            urlPath={'/access/profile/'}
                            urlKey={'_id'}
                            lsKey={'@mctms-access-profiles-table'}
                            popKeys={['_id']}
                        />
                    }
                </Card.Body>
            </Card>
        </Container>
    )
}

export default AccessProfile;