import React from "react";
import { Alert, Container, } from "react-bootstrap";

function ServerOffline() {
    
  return (
    <Container className="d-flex justify-content-center align-items-center vh-100">
        <Alert variant='danger'> {(process.env.NODE_ENV).toUpperCase()} Server offline. Please try to reconnect later.</Alert>
    </Container>
  );
}

export default ServerOffline