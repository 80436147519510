import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { Button, Col, Row } from 'react-bootstrap';

const DataVisual = ({ data, fields, title, showExportButtons, aggregateData = false }) => {
  const d3Container = useRef(null);

  // A helper function to wrap SVG text
  function wrap(text, width, leftPadding) {
    text.each(function() {
      var text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.1, // ems
          y = text.attr("y"),
          dy = parseFloat(text.attr("dy") || 0),
          dx = leftPadding || -10;

          var tspan = text.text(null)
                    .append("tspan")
                    .attr("x", 0)
                    .attr("y", y)
                    .attr("dy", dy + "em")
                    .attr("dx", dx + "px");
      
      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text.append("tspan")
                    .attr("x", 0)
                    .attr("y", y)
                    .attr("dy", ++lineNumber * lineHeight + dy + "em")
                    .attr("dx", dx + "px")
                    .text(word);
        }
      }
    });
  }

  useEffect(() => {
    if (data && d3Container.current) {
      // Clear the previous SVG
      d3.select(d3Container.current).selectAll("*").remove();
  
      let processedData;
      if (!aggregateData) {

        console.log('fields.x', fields.x);
        console.log(data);
        if (fields.x === 'companyCost') {
          // Aggregate the total cost per city for the 'cost' field
          const costByCity = d3.rollup(data, v => d3.sum(v, d => d.companyCost), d => d.accidentCity);
          processedData = Array.from(costByCity, ([key, value]) => ({ key, value })).sort((a, b) => d3.ascending(a.value, b.value));

        } else if (fields.x === 'timeOfInjury') {
          const timeRanges = ['0000-0359', '0400-0759', '0800-1159', '1200-1559', '1600-1959', '2000-2359'];
          const allTimeRanges = timeRanges.reverse().map(timeRange => ({ key: timeRange, value: 0 }));
          const aggregatedData = d3.rollup(data, v => v.length, d => d.timeOfInjury);
          timeRanges.forEach(timeRange => {
            const value = aggregatedData.get(timeRange) || 0;
            allTimeRanges.find(item => item.key === timeRange).value = value;
          });
          processedData = allTimeRanges;

        } else if (fields.x === 'timeOfDay') {
          const timeRanges = ['0000-0359', '0400-0759', '0800-1159', '1200-1559', '1600-1959', '2000-2359'];
          const allTimeRanges = timeRanges.reverse().map(timeRange => ({ key: timeRange, value: 0 }));
          const aggregatedData = d3.rollup(data, v => v.length, d => d.timeOfDay);
          timeRanges.forEach(timeRange => {
            const value = aggregatedData.get(timeRange) || 0;
            allTimeRanges.find(item => item.key === timeRange).value = value;
          });
          processedData = allTimeRanges;

        } else if (fields.x === 'day') {
            const dataWithDay = data.map(item => {
              const date = new Date(item.dateOfInjury);
              const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' });
              return { ...item, dayOfWeek };
            });
          const daysOfWeekOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

          const groupedData = Array.from(d3.rollup(dataWithDay, v => v.length, d => d.dayOfWeek), ([key, value]) => ({ key, value }));

          // Sort the grouped data based on the order of days in the week
          const sortedData = daysOfWeekOrder.reverse().map(day => ({
            key: day,
            value: (groupedData.find(item => item.key === day) || { value: 0 }).value
          }));

          processedData = sortedData;

        } else if (fields.x === 'age') {
          const ageBracketOrder = ['Under 25', '25-29', '30-34', '35-39', '40-44', '45-49', '50-54', '55-59', '60-64', '65-69', '70+'];
          const allAgeGroups = ageBracketOrder.reverse().map(ageGroup => ({ key: ageGroup, value: 0 }));
          
          const aggregatedData = d3.rollup(data, v => v.length, d => d.age);
          ageBracketOrder.forEach(ageBracket => {
            const value = aggregatedData.get(ageBracket) || 0;
            allAgeGroups.find(item => item.key === ageBracket).value = value;
          });
          processedData = allAgeGroups;
        
        } else if (fields.x === 'driverAge') {
          const ageBracketOrder = ['Under 25', '25-29', '30-34', '35-39', '40-44', '45-49', '50-54', '55-59', '60-64', '65-69', '70+'];
          const allAgeGroups = ageBracketOrder.reverse().map(ageGroup => ({ key: ageGroup, value: 0 }));
          
          const aggregatedData = d3.rollup(data, v => v.length, d => d.driverAge);
          ageBracketOrder.forEach(ageBracket => {
            const value = aggregatedData.get(ageBracket) || 0;
            allAgeGroups.find(item => item.key === ageBracket).value = value;
          });
          processedData = allAgeGroups;

        } else {
          // Aggregate the count of accidents for other fields
          const aggregatedData = d3.rollup(data, v => v.length, d => d[fields.x]);
          processedData = Array.from(aggregatedData, ([key, value]) => ({ key, value })).sort((a, b) => d3.ascending(a.value, b.value));
        }
        
      } else {
        processedData = data;
      }

      // Define margins and dimensions
      const margin = { top: 50, right: 70, bottom: 50, left: 150 };
      const width = 460 - margin.left - margin.right;
      const height = 400 - margin.top - margin.bottom;

      // Create an SVG element
      const svg = d3.select(d3Container.current)
        .append("svg")
        .attr("id", "svg-container")
        .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`) // Set the viewBox attribute for responsiveness
        .attr("preserveAspectRatio", "xMidYMid meet") // Preserve aspect ratio
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Add a border to the SVG element
      // svg.append("rect")
      //   .attr("width", width)
      //   .attr("height", height)
      //   .style("fill", "none")
      //   .style("stroke", "black")
      //   .style("stroke-width", 1);
    
      // Y Axis
      const y = d3.scaleBand()
        .range([height, 0])
        .padding(0.5)
        .domain(processedData.map(d => d.key));
      svg.append("g")
        .call(d3.axisLeft(y))
        .selectAll("text")
          .attr('dx', '-10px')
          .style('font-size', '14px')
          .style('font-weight', 'bold')
          .style('text-anchor', 'end')
          .style('text-transform', 'capitalize')
        .call(wrap, margin.left - 20); // Wrap text labels
  
      // X Axis
      const x = d3.scaleLinear()
        .range([0, width])
        .domain([0, d3.max(processedData, d => d.value)]);
      // if (fields.x !== 'cost') {
      //   // Only use tickValues if the field is not 'cost'
      //   const maxValue = d3.max(processedData, d => d.value);
      //   const tickValues = d3.range(maxValue + 1);

      //   svg.append("g")
      //     .attr("transform", `translate(0,${height})`)
      //     .call(d3.axisBottom(x).tickValues(tickValues).tickFormat(d3.format('d')));
      // } else {
      //   // For 'cost', let D3 handle the ticks, but ensure they are whole numbers
      //   svg.append("g")
      //     .attr("transform", `translate(0,${height})`)
      //     .call(d3.axisBottom(x).tickFormat(d3.format('.0f'))); // '.0f' format specifies no decimal places
      // }
  
      // Append the rectangles for the bar chart
      const bars = svg.selectAll(".bar")
        .data(processedData, d => d.key);

      bars
        .transition()
        .duration(750) // duration of the transition
        .attr("y", d => y(d.key))
        .attr("height", y.bandwidth())
        .attr("x", 0)
        .attr("width", d => x(d.value));

      bars.enter()
        .append("rect")
        .attr("class", "bar")
        .attr("y", d => y(d.key))
        .attr("height", y.bandwidth())
        .attr("x", 0)
        .transition()
        .duration(750)
        .attr("width", d => x(d.value))
        .attr("fill", "#ffb45a");

      // Handle exiting elements
      bars.exit()
        .transition()
        .duration(750)
        .attr("width", 0) // transition width to 0 before removing
        .remove();

      // Add text labels to the bars
      svg.selectAll(".label")
        .data(processedData)
        .enter().append("text")
        .attr("class", "label")
        .attr("y", d => y(d.key) + y.bandwidth() / 2 + 4) // Center text in the bar
        .attr("x", d => x(d.value) + 3) // Offset text to the right of the bar
        .text(d => fields.x === 'companyCost' ? `$${d.value}` : d.value); // Check if 'cost' and prepend '$'
  
      // Add chart title
      svg.append("text")
        .attr("x", (width / 2))
        .attr("y", 0 - (margin.top / 2))
        .attr("text-anchor", "middle")
        .style("font-size", "16px")
        .text(title)
        .style('font-weight', 'bold');
    }
  }, [data, fields, title]);
  

  const handleDownloadImage = (fileType) => {
      const svgElement = d3Container.current.querySelector('svg');
      const serializer = new XMLSerializer();
      const svgString = serializer.serializeToString(svgElement);
      const svgBlob = new Blob([svgString], { type: "image/svg+xml;charset=utf-8" });
      const url = URL.createObjectURL(svgBlob);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();

      img.onload = () => {
        const svgViewBox = svgElement.viewBox.baseVal;
        const scaleFactor = 2; // Adjust this factor for higher or lower resolution
        canvas.width = svgViewBox.width * scaleFactor;
        canvas.height = svgViewBox.height * scaleFactor;

        ctx.scale(scaleFactor, scaleFactor);
        ctx.fillStyle = '#ffffff'; // Fill with white for JPEG; transparent for PNG
        ctx.fillRect(0, 0, canvas.width, canvas.height); // Fill the canvas with white background for JPEG

        ctx.drawImage(img, 0, 0, svgViewBox.width, svgViewBox.height);
        URL.revokeObjectURL(url);

        let dataUrl;
        let fileExtension;

        if (fileType === 'png') {
          dataUrl = canvas.toDataURL("image/png");
          fileExtension = 'png';
        } else if (fileType === 'jpeg') {
          dataUrl = canvas.toDataURL("image/jpeg", 0.92);
          fileExtension = 'jpeg';
        }

        const downloadLink = document.createElement('a');
        downloadLink.href = dataUrl;
        downloadLink.download = `bar-chart.${fileExtension}`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      };

      img.src = url;
  };    

  return (
    <div style={{ marginInline: '10px' }}>
      {showExportButtons && (
        <Row>
          <Col>
            <Button block onClick={() => handleDownloadImage('png')} style={{ border: '1px solid black', marginRight: '3px'}}>Download as PNG</Button>           
            <Button block onClick={() => handleDownloadImage('jpeg')} style={{ border: '1px solid black' }}>Download as JPEG</Button>          
          </Col>
        </Row>
      )}
      <div ref={d3Container} id="data_visual" />
    </div>
    
  );
};

export default DataVisual;
