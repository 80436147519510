import React from 'react';
import { Line } from 'react-chartjs-2';
import { CategoryScale, LinearScale, LineController, PointElement, LineElement, TimeScale, Chart } from 'chart.js';
import 'chartjs-adapter-date-fns';

// Register the modules
Chart.register(CategoryScale, LinearScale, LineController, PointElement, LineElement, TimeScale,);

const DriverHOSLog = ({ hoslogs }) => {

    const labels = [];
    const dataValues = [];

    hoslogs.forEach((log) => {
        let startTime = new Date(log.logStartTime);
        let endTime = new Date(log.logEndTime);

        const statusValue = getStatusValue(log.hosStatusType);
        labels.push(startTime.toISOString());
        dataValues.push(statusValue);
        labels.push(endTime.toISOString());
        dataValues.push(statusValue);
    });

    function getStatusValue(statusType) {
        switch (statusType) {
            case "offDuty":
                return 5;
            case "sleeperBerth":
                return 4;
            case "driving":
                return 3;
            case "onDuty":
                return 2;
            case "yardMove":
                return 1;
            case "personalConveyance":
                return 0;
            default:
                return -1; // or any other default value
        }
    }

    const data = {
        labels: labels,
        datasets: [{
            label: 'Duty Status',
            data: dataValues,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0 // Set to 0 for straight lines.
        }]
    };

    const options = {
        scales: {
            x: {
                type: 'time',
                position: 'bottom',
                title: {
                    display: true,
                    text: 'Time'
                },
                time: {
                    unit: 'hour', // Setting the unit to hour
                    displayFormats: {
                        hour: 'HH:mm' // Display format in 24-hour clock
                    }
                },
                ticks: {
                    stepSize: 1  // Define 1-hour increments
                }
            },
            y: {
                beginAtZero: true,
                ticks: {
                    // Create custom ticks to replace number values with their corresponding duty status
                    callback: function (value) {
                        switch (value) {
                            case 5:
                                return 'Off Duty';
                            case 4:
                                return 'Sleeper Berth';
                            case 3:
                                return 'Driving';
                            case 2:
                                return 'On Duty';
                            case 1:
                                return 'Yard Move';
                            case 0:
                                return 'Personal Conveyance';
                            default:
                                return null;
                        }
                    }
                }
            }
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'HOS Log Duty Status Timeline'
            },
            tooltip: {
                callbacks: {
                    title: function (tooltipItems) {
                        const item = tooltipItems[0];
                        return new Date(item.label).toLocaleString();
                    }
                }
            }
        }
    };

    return <Line data={data} options={options} />;
};

export default DriverHOSLog;
