import { Button, Card, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import { useAuth } from "../../context/auth";
import AlertDisplay from "../../components/AlertDisplay";
import { useEffect, useMemo, useState } from "react";
import Api from "../../utils/Api";
import { findUSPSNASSCode } from "../../context/uspsdata";
import LogisticsLoading from "../../components/LogisticsLoading";
import { formatDateStrToMMDDYY, formatDateStrToMMDDYYHHMM } from "../../utils/DateTimeFormat.helper";
import ScatterPlot from "../../components/LoadTenderScatterPlot";
import { Link } from "react-router-dom";
import { FaEye, FaOpenid } from "react-icons/fa";
import CalendarChart from "../../components/CalendarChart";

const DriverScoreCard = ({ feature, driver, loads, start, end }) => {

    function millisecondsToHHMM(milliseconds) {
        const hours = Math.floor(milliseconds / 3600000);
        milliseconds %= 3600000;
        const minutes = Math.floor(milliseconds / 60000);
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        return `${formattedHours}:${formattedMinutes}`;
    }

    const cellCenterStyle = {
        textAlign: 'center',
        padding: '0px 0.5em'
    }

    const cellRightStyle = {
        textAlign: 'right',
        padding: '0px 0.5em'
    }

    const cellCenterStyleGreen = {
        textAlign: 'center',
        padding: '0px 0.5em',
        backgroundColor: '#bcdbbc'
    }

    const cellCenterStyleRed = {
        textAlign: 'center',
        padding: '0px 0.5em',
        backgroundColor: '#f39c9c'
    }

    const cellCenterStyleBlue = {
        textAlign: 'center',
        padding: '0px 0.5em',
        backgroundColor: '#6eb6ff'
    }

    const cellCenterStyleYellow = {
        textAlign: 'center',
        padding: '0px 0.5em',
        backgroundColor: '#f8f39c'
    }

    const callCenterOrangeStyle = {
        textAlign: 'center',
        padding: '0px 0.5em',
        backgroundColor: '#ffc107'
    }


    function checkAudit(load) {
        let total = 0;
        if (load.coverage.length === 0) {
            total = total + load.edi204[load.edi204.length - 1].Stops.length
        } else {
            const audits = Object.keys(load.ediAudit);
            for (let i = 0; i < audits.length; i++) {
                const stopKeys = Object.keys(load.ediAudit[audits[i]].ediStops)
                if (stopKeys.length === 0) {
                    total = total + load.edi204[load.edi204.length - 1].Stops.length
                }
            }
        }


        return total;
    }

    function calculateEarly(load) {
        let total = 0;
        const audits = Object.keys(load.ediAudit);
        for (let i = 0; i < audits.length; i++) {
            const stopKeys = Object.keys(load.ediAudit[audits[i]].ediStops)
            for (let j = 0; j < stopKeys.length; j++) {
                const via = load.ediAudit[audits[i]].ediStops[stopKeys[j]]
                if (Number(via.eldOnPremiseArriveDiffMin) >= 0) {
                    total++;
                }
            }
        }

        return total;
    }

    function calculateLate(load) {
        let total = 0;
        const audits = Object.keys(load.ediAudit);
        for (let i = 0; i < audits.length; i++) {
            const stopKeys = Object.keys(load.ediAudit[audits[i]].ediStops)
            for (let j = 0; j < stopKeys.length; j++) {
                const via = load.ediAudit[audits[i]].ediStops[stopKeys[j]]
                if (Number(via.eldOnPremiseArriveDiffMin) < 0) {
                    total++;
                }
            }
        }

        return total;
    }

    function calculateMissed(load) {
        let total = 0;
        const audits = Object.keys(load.ediAudit);
        for (let i = 0; i < audits.length; i++) {
            const stopKeys = Object.keys(load.ediAudit[audits[i]].ediStops)
            for (let j = 0; j < stopKeys.length; j++) {
                const via = load.ediAudit[audits[i]].ediStops[stopKeys[j]]
                if ((!via?.eldOnPremiseArriveDiffMin)) {
                    total++;
                }
            }
        }

        return total;
    }

    function loadTenderScatterChartData(shipments) {
        const chartData = [];
        for (const load of shipments) {
            const ShipmentId = load?.ShipmentId;
            const Contract = load?.Contract;
            const Trip = load?.Trip;
            const loadDate = new Date(load?.StartTime).toDateString()
            if (load?.ediAudit && load?.ediAudit[0]?.ediStops) {
                const keys = Object.keys(load?.ediAudit[0]?.ediStops)
                if (keys.length > 0) {
                    for (const key of keys) {
                        const via = load?.ediAudit[0]?.ediStops[key]
                        const { eldOnPremiseArriveDiffMin, eldOnPremiseDepartDiffMin } = via;
                        const arrive = Number(eldOnPremiseArriveDiffMin);
                        const depart = Number(eldOnPremiseDepartDiffMin);



                        // Check if arrive and depart are valid numbers
                        if (!isNaN(arrive) && !isNaN(depart)) {
                            // Determine the color based on arrive and depart values
                            chartData.push({
                                date: loadDate,
                                arrivalTimeDifference: arrive,
                                departureTimeDifference: depart,
                                contractNumber: Contract,
                                tripNumber: Trip,
                                shipmentId: ShipmentId
                            });
                        }
                    }
                }
            }
        }

        return chartData;
    }

    function calendarChartData(loads) {
        const ops = [];
        const startDates = loads.map(load => new Date(load?.StartTime));

        for (let i = 0; i < startDates.length; i++) {
            ops.push([startDates[i], 1]);
        }

        return ops;
    }

    return (
        <Container>
            <Card>
                <Card.Header>
                    <Row>
                        <Col>
                            <h2>{driver}</h2>
                        </Col>
                        <Col xs="auto">
                            {formatDateStrToMMDDYY(start)} to {formatDateStrToMMDDYY(end)}
                        </Col>
                    </Row>
                </Card.Header>
                {loads ?
                    <>
                        <Card.Body>
                            <h3>Stop Arrival and Departure Scatter Plot</h3>
                            <ScatterPlot redirect={feature} data={loadTenderScatterChartData(loads)} width={300} height={300} />
                        </Card.Body>
                        <Card.Body>
                            <CalendarChart title={'Operations'} opdata={calendarChartData(loads)} adjust={1.25} />
                        </Card.Body>
                        <Card.Body>
                            <h3>Stop Performance</h3>
                            <Row>
                                <Col style={callCenterOrangeStyle}>
                                    <h4>Check</h4>
                                    <h3>
                                        {(((loads.reduce((a, b) => a + checkAudit(b), 0))) / ((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%
                                    </h3>
                                </Col>
                                <Col style={cellCenterStyleGreen}>
                                    <h4>Early</h4>
                                    <h3>
                                        {(((loads.reduce((a, b) => a + calculateEarly(b), 0))) / ((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%
                                    </h3>
                                </Col>
                                <Col style={cellCenterStyleYellow}>
                                    <h4>Late</h4>
                                    <h3>
                                        {(((loads.reduce((a, b) => a + calculateLate(b), 0))) / ((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%
                                    </h3>
                                </Col>
                                <Col style={cellCenterStyleRed}>
                                    <h4>Not Detected</h4>
                                    <h3>
                                        {(((loads.reduce((a, b) => a + calculateMissed(b), 0))) / ((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%
                                    </h3>
                                </Col>
                                <Col style={cellCenterStyle}>
                                    <h4>Tracking</h4>
                                    <h3>
                                        {
                                            ((((loads.reduce((a, b) => a + calculateEarly(b), 0))) / ((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) +
                                                ((loads.reduce((a, b) => a + calculateLate(b), 0))) / ((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0)))) * 100).toFixed(1)
                                        }%
                                    </h3>
                                </Col>
                            </Row>
                            <Table>
                                <thead>
                                    <tr>
                                        <th style={cellCenterStyle}></th>
                                        <th style={cellCenterStyle}>Shipment Id</th>
                                        <th style={cellCenterStyle}>Start</th>
                                        <th style={cellCenterStyle}>End</th>
                                        <th style={cellCenterStyle}>SOP</th>
                                        <th style={cellCenterStyle}>Contract-Trip</th>
                                        <th style={cellCenterStyle}>Stops</th>
                                        <th style={cellCenterStyle}>Check</th>
                                        <th style={cellCenterStyle}>Early</th>
                                        <th style={cellCenterStyle}>Late</th>
                                        <th style={cellCenterStyle}>Not Detected</th>
                                        <th style={cellCenterStyle}>Tracking</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th style={cellCenterStyle}></th>
                                        <th style={cellCenterStyle}>{(loads.reduce((a, b) => a + 1, 0))}</th>
                                        <th style={cellCenterStyle}></th>
                                        <th style={cellCenterStyle}></th>
                                        <th style={cellCenterStyle}>{millisecondsToHHMM(loads.reduce((a, b) => a + b.SOPmilli, 0))}</th>
                                        <th style={cellCenterStyle}></th>
                                        <th style={cellCenterStyle}>{((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0)))}</th>
                                        <th style={cellCenterStyle}>{(((loads.reduce((a, b) => a + checkAudit(b), 0))) / ((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%</th>
                                        <th style={cellCenterStyle}>{(((loads.reduce((a, b) => a + calculateEarly(b), 0))) / ((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%</th>
                                        <th style={cellCenterStyle}>{(((loads.reduce((a, b) => a + calculateLate(b), 0))) / ((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%</th>
                                        <th style={cellCenterStyle}>{(((loads.reduce((a, b) => a + calculateMissed(b), 0))) / ((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%</th>
                                        <th style={cellCenterStyle}>{
                                            ((((loads.reduce((a, b) => a + calculateEarly(b), 0))) / ((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) +
                                                ((loads.reduce((a, b) => a + calculateLate(b), 0))) / ((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0)))) * 100).toFixed(1)
                                        }%</th>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {loads.map((load, i) => (
                                        <tr>
                                            <td><Link to={`${feature}/${load?.ShipmentId}`} target="_blank"><FaEye /></Link></td>
                                            <td>{load?.ShipmentId}</td>
                                            <td style={cellCenterStyle}>{formatDateStrToMMDDYYHHMM(load.StartTime)}</td>
                                            <td style={cellCenterStyle}>{formatDateStrToMMDDYYHHMM(load.EndTime)}</td>
                                            <td style={cellCenterStyle}>{millisecondsToHHMM(load.SOPmilli)}</td>
                                            <td style={cellCenterStyle}>{(load.Contract)}-{(load.Trip)}</td>
                                            <td style={cellCenterStyle}>{load.edi204[0].Stops.length}</td>
                                            <td style={checkAudit(load) > 0 ? callCenterOrangeStyle : cellCenterStyle}>{checkAudit(load)}</td>
                                            <td style={calculateEarly(load) > 0 ? cellCenterStyleGreen : cellCenterStyle}>{calculateEarly(load)}</td>
                                            <td style={calculateLate(load) > 0 ? cellCenterStyleYellow : cellCenterStyle}>{calculateLate(load)}</td>
                                            <td style={calculateMissed(load) > 0 ? cellCenterStyleRed : cellCenterStyle}>{calculateMissed(load)}</td>
                                            <td style={cellCenterStyle}>{(((calculateEarly(load) + calculateLate(load)) / load.edi204[0].Stops.length) * 100).toFixed()}%</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                        </Card.Body>
                    </>
                    :
                    <Card.Body>
                        No Shipments
                    </Card.Body>
                }
            </Card>

        </Container>
    )
}

function DriverLoadTenders({ view, feature }) {
    const { user, idToken } = useAuth();
    const [drivers, setDrivers] = useState([]);
    const [profile, setProfile] = useState();
    const [set, didSet] = useState();
    const [fetched, didFetch] = useState();
    const [load, didLoad] = useState();
    const [loads, setLoads] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }


    const [samsaraDrivers, setSamsaraDrivers] = useState();
    const [samsaraDriversByLocation, setSamsaraDriversByLocation] = useState();
    const [searchTerm, setSearchTerm] = useState('');

    const [operatorlocation, setOperatorLocation] = useState('All');
    const [operatoroption, setOperatorOption] = useState();

    const filteredSamsaraDrivers = useMemo(() => {
        if (samsaraDrivers && samsaraDrivers.length > 0) {
            return samsaraDrivers.filter(item =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        } else {
            return [];
        }
    }, [samsaraDrivers, searchTerm]);

    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    const formattedStartDate = sevenDaysAgo.toISOString().split('T')[0];

    const savedQuery = JSON.parse(localStorage.getItem('@tms-driver-score-card'));

    const [query, setQuery] = useState({
        driver: view === 'driver' ? `${profile?.firstname} ${profile?.lastname}` : savedQuery ? savedQuery?.driver : '',
        dateSpan: savedQuery ? savedQuery.dateSpan : 7,
        startDate: savedQuery ? savedQuery.startDate : formattedStartDate,
        endDate: savedQuery ? savedQuery.endDate : new Date().toISOString().split('T')[0],
        page: savedQuery ? savedQuery.page : 1,
        pageSize: savedQuery ? savedQuery.pageSize : 10,
    });


    useEffect(() => {
        localStorage.setItem('@tms-driver-score-card', JSON.stringify(query));
    }, [query]);

    function setQueryDates(value) {
        const endDate = new Date();
        const startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - value);

        setQuery(prevQuery => ({
            ...prevQuery,
            dateSpan: value,
            startDate: startDate.toISOString().split('T')[0],
            endDate: endDate.toISOString().split('T')[0]
        }));
    }


    async function fetchProfile() {
        try {
            const userProfile = await Api.get(`/access/profile/uid/${user?.uid}`, idToken);
            setProfile(userProfile)
            if (view === 'driver') {
                setQuery({ ...query, driver: `${userProfile?.firstname} ${userProfile?.lastname}` })
            }
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Profile error.', }])
        }
    }

    async function fetchDriverLoadTenders() {
        didFetch(false)
        try {
            const queryStr = new URLSearchParams(query).toString();
            const data = await Api.get(`/loadtenders/shipments/report?${queryStr}`, idToken);
            setLoads(data);
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Unable to fetch loads.', }])
        } finally {
            didFetch(true)
        }
    }

    async function fetchCoverage() {
        try {
            // const ediDrivers = await Api.get(`/operators/live`, idToken);

            const driversdata = await Api.get(`/samsara/drivers-active`, idToken);
            const drivers = driversdata?.data;
            const groupedData = drivers.reduce((groups, record) => {
                const homeTerminalName = record?.carrierSettings?.homeTerminalName;
                if (!groups[homeTerminalName]) {
                    groups[homeTerminalName] = [];
                }
                groups[homeTerminalName].push(record);
                return groups;
            }, {});

            const sortedKeys = Object.keys(groupedData).sort();

            const sortedGroupedData = {};
            for (const key of sortedKeys) {
                sortedGroupedData[key] = groupedData[key];
            }

            setSamsaraDrivers(drivers)
            setSamsaraDriversByLocation(sortedGroupedData)

            setDrivers(drivers)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Unable to fetch drivers.', }])
        }
    }

    useEffect(() => {
        if (user && !load) {
            fetchProfile();
        }

        return () => didLoad(true);
    }, [user, profile, load])

    useEffect(() => {
        if (!set) {
            fetchCoverage();
        }

        return () => didSet(true);
    }, [set])

    useEffect(() => {
        fetchDriverLoadTenders()
    }, [view, query])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            {!load ?
                <LogisticsLoading message={'Loading report...'} />
                :
                <Card style={{ border: 0, borderRadius: 0 }}>
                    <Card.Header>
                        <Card.Title>
                            Driver Load Tenders
                        </Card.Title>
                    </Card.Header>
                    <Card.Header>
                        <Row>
                            <Col xs="auto">
                                Location
                                <Form.Select onChange={(e) => setOperatorLocation(e.target.value)}>
                                    <option>All</option>
                                    {samsaraDriversByLocation && Object.keys(samsaraDriversByLocation).map((key, k) => (
                                        <option key={k} value={key}>{key ? key : 'Undefined'}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col xs="auto">
                                Driver
                                {operatorlocation === 'All'
                                    ?
                                    <Row>
                                        <Col>
                                            <Form.Control
                                                type="text"
                                                placeholder="Search..."
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                            />
                                            {searchTerm.trim('') !== '' &&
                                                <div style={{ maxHeight: '200px', overflowY: 'auto', margin: '0.5em', padding: '0.1em' }}>
                                                    {filteredSamsaraDrivers.map((item, i) => (
                                                        <Row key={i} style={{ margin: '0.1em' }}>
                                                            <Button variant="secondary" onClick={() => {
                                                                setOperatorOption(item);
                                                                setSearchTerm(item.name);
                                                                setQuery({ ...query, driver: item.name })
                                                            }}
                                                            >{item.name}</Button>
                                                        </Row>
                                                    ))}
                                                </div>
                                            }
                                        </Col>
                                        {searchTerm.trim('') !== '' &&
                                            <Col xs="auto">
                                                <Button variant="warning" onClick={() => setSearchTerm('')}>
                                                    X
                                                </Button>
                                            </Col>
                                        }
                                    </Row>
                                    :
                                    <Form.Select onChange={(e) => {
                                        if (e.target.value !== '') {
                                            setOperatorOption(e.target.value)
                                            setQuery({ ...query, driver: e.target.value })
                                        }
                                    }}>
                                        <option value=''>Select...</option>
                                        {samsaraDriversByLocation[operatorlocation] && samsaraDriversByLocation[operatorlocation].sort((a, b) => a.name.localeCompare(b.name)).map((driver, i) => (
                                            <option key={i} value={driver.name}>{driver.name}</option>
                                        ))}
                                    </Form.Select>
                                }
                            </Col>
                            {/* 
                            <Col xs="auto">
                                Driver
                                <Form.Control
                                    as="select"
                                    value={query?.driver}
                                    onChange={e => e.target.value === 'All'
                                        ? setQuery({ ...query, driver: 'All' })
                                        : setQuery({ ...query, driver: e.target.value })
                                    }
                                    readOnly={view === 'driver' ? true : false}
                                    disabled={view === 'driver' ? true : false}
                                >
                                    <option></option>
                                    {drivers.sort((a, b) => {
                                        if (a.name < b.name) return -1;
                                        if (a.name > b.name) return 1;
                                        return 0;
                                    }).map((driver, i) => (
                                        <option key={i} value={`${driver.name}`}>{driver.name}</option>
                                    ))}
                                </Form.Control>
                            </Col> */}
                            <Col xs="auto">
                                Previous
                                <Form.Control
                                    value={query?.dateSpan}
                                    as="select"
                                    onChange={e => setQueryDates(e.target.value)}
                                >
                                    <option>7</option>
                                    <option>30</option>
                                    <option>60</option>
                                    <option>90</option>
                                </Form.Control>
                            </Col>
                            <Col xs="auto">
                                Start
                                <Form.Control type="date" value={query?.startDate} readOnly disabled />
                            </Col>
                            <Col xs="auto">
                                End
                                <Form.Control type="date" value={query?.endDate} readOnly disabled />
                            </Col>
                        </Row>

                    </Card.Header>
                    <Card.Body>
                        {!fetched ?
                            <LogisticsLoading message={'Loading report...'} />
                            :
                            <>
                                {loads.length > 0 ?
                                    <DriverScoreCard feature={feature} driver={query?.driver} loads={loads} start={query?.startDate} end={query?.endDate} />
                                    :
                                    <h3>
                                        No shipment data
                                    </h3>
                                }
                            </>
                        }
                    </Card.Body>
                </Card>
            }

        </Container>
    )
}


export default DriverLoadTenders;