// move all date/time objects here and refactor throughout app
import { TIMEZONE_OPTIONS } from "../context/appdata";


function getTimezoneCode(timezoneName) {
    const timezoneIndex = TIMEZONE_OPTIONS.findIndex(option => option.name === timezoneName);

    if (timezoneIndex !== -1) {
        return TIMEZONE_OPTIONS[timezoneIndex].code;
    }

    return null;
}


export {
    getTimezoneCode
}