import { useState, useEffect } from "react";
import { Card, Container, Spinner, Button, Row, Col, Alert, Form } from "react-bootstrap";
import Api from "../../utils/Api";
import { useAuth } from "../../context/auth"
import LoadEyeBoxComponent from "./LoadEye.box.component";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai'
import { BiFirstPage, BiLastPage } from 'react-icons/bi'


function LoadEye() {
  const { idToken } = useAuth();
  const [set, didSet] = useState();
  const [devicedata, setDeviceData] = useState();
  const [sensorplacement, setSensorPlacement] = useState();
  const [record, setRecord] = useState(0);
  const [alert, setAlert] = useState()

  const handleFirstClick = () => {
    setRecord(0);
  };

  const handleLastClick = () => {
    setRecord(devicedata.length - 1);
  };

  const handleNextClick = () => {
    if (record < devicedata.length - 1) {
      setRecord(record + 1);
    }
  };

  const handlePreviousClick = () => {
    if (record > 0) {
      setRecord(record - 1);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await Api.get('/loadeye/devicedata', idToken);
      const sensorInstalls = [50, 150, 250, 350, 455, 555, 655, 760, 860];
      setDeviceData(data);
      setSensorPlacement(sensorInstalls);
      didSet(true);
    };

    if (!set) {
      fetchData()
    }
  }, [set, idToken]);


  return (
    <Container fluid>
      <br />
      <Card>
        <Card.Header>
          <Card.Title>Load Eye * Prototype *</Card.Title>
        </Card.Header>
        {alert &&
          <Card.Body>
            <Alert variant="warning" onClick={() => setAlert()} dismissible>{alert}</Alert>
          </Card.Body>
        }
        <Card.Body>
          {set ?
            <>
              <Row>
                <Col style={{textAlign:'center'}}>
                  <Button onClick={handleFirstClick} style={{ margin: '1em' }}> <BiFirstPage /> First</Button>
                  <Button onClick={handlePreviousClick} style={{ margin: '1em' }}><AiOutlineArrowLeft /> Pervious</Button>
                  <span>
                    {record + 1} / {devicedata.length}
                  </span>
                  <Button onClick={handleNextClick} style={{ margin: '1em' }}>Next <AiOutlineArrowRight /></Button>
                  <Button onClick={handleLastClick} style={{ margin: '1em' }}>Last <BiLastPage /></Button>
                </Col>
              </Row>
              <Row>
                <Col xs="auto">
                  {devicedata[0].timestamp}
                </Col>
                <Col>
                  <Form.Control
                    type="range"
                    min={0}
                    max={devicedata.length - 1}
                    value={record}
                    onChange={(e) => setRecord(parseInt(e.target.value))}
                  />
                </Col>
                <Col xs="auto">
                  {devicedata[devicedata.length - 1].timestamp}

                </Col>
              </Row>
              <LoadEyeBoxComponent devicedata={devicedata[record]} sensorplacement={sensorplacement} box={{ height: 250, length: 880 }} />
            </> :
            <>
              <Spinner />
            </>
          }
        </Card.Body>

      </Card>
    </Container>
  )
}

export default LoadEye;