import USPS_NASS_CODES from './usps-service-point-data.json'

function findUSPSNASSCode(code){
    const nasscode = USPS_NASS_CODES.find(nasscode => nasscode['Location ID'] === code);
    if (nasscode) {
        return nasscode['Location Name'];
    } else {
        return code;
    }
}

function findUSPSNASSCodeState(code){
  const nasscode = USPS_NASS_CODES.find(nasscode => nasscode['Location ID'] === code);
  if (nasscode) {
    const words = nasscode['Address'].trim().split(/\s+/); 
    if (words.length >= 3) {
      return words[words.length - 3];
    } else {
      return "Unknown";
    }
      
  } else {
      return code;
  }
}



function findUSPSTimeZoneOffsetHours(code){
    const nasscode = USPS_NASS_CODES.find(nasscode => nasscode['Location ID'] === code);
    if (nasscode) {
        return Number(nasscode['Time Zone Offset (Hours)']);
    } else {
        return code;
    }
}

function findUSPSAddress(code){
    const nasscode = USPS_NASS_CODES.find(nasscode => nasscode['Location ID'] === code);
    if (nasscode) {
        return nasscode['Address'];
    } else {
        return code;
    }
}

function findUSPSOffsetTimezoneIANA(offset) {
  switch (offset) {
    case 0:
      return 'ET'; // Eastern Time (ET)
    case -1:
      return 'CT'; // Central Time (CT)
    case -2:
      return 'MT'; // Mountain Time (MT)
    case -3:
      return 'MST'; // Arizona Time (no DST)
    case -4:
      return 'PT'; // Pacific Time (PT)
    case -5:
      return 'AKT'; // Alaska Time (AKT)
    default:
      return '--'; // Handle other cases as needed
  }
}

function formatUSPSDateTime(code, uspstime){
  try{
    const offset = findUSPSTimeZoneOffsetHours(code);
    const locationTz = findUSPSOffsetTimezoneIANA(offset)
    const inputTime = new Date(uspstime);
    const month = String(inputTime.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(inputTime.getDate()).padStart(2, '0');
    const year = String(inputTime.getFullYear()).slice(-2); // Get the last 2 digits of the year
    const hours = String(inputTime.getHours()).padStart(2, '0');
    const minutes = String(inputTime.getMinutes()).padStart(2, '0');
    const formattedTime = `${month}/${day}/${year} ${hours}:${minutes} ${locationTz}`;
    return formattedTime
  } catch {
    return uspstime;
  }
}

function findUSPSNassCodeTimezoneIANA(code) {
  const nasscode = USPS_NASS_CODES.find(nasscode => nasscode['Location ID'] === code);
  if (nasscode) {
    const offset = Number(nasscode['Time Zone Offset (Hours)']);
    switch (offset) {
      case 0:
        return 'America/New_York'; // Eastern Time (ET)
      case -1:
        return 'America/Chicago'; // Central Time (CT)
      case -2:
        return 'America/Denver'; // Mountain Time (MT)
      case -3:
        return 'America/Phoenix'; // Arizona Time (no DST)
      case -4:
        return 'America/Los_Angeles'; // Pacific Time (PT)
      case -5:
        return 'America/Anchorage'; // Alaska Time (AKT)
      default:
        return '--'; // Handle other cases as needed
    }
  } else {
    return code;
  }
}

export {
    USPS_NASS_CODES,
    findUSPSNASSCode,
    findUSPSTimeZoneOffsetHours,
    findUSPSAddress,
    formatUSPSDateTime,
    findUSPSNASSCodeState,
    findUSPSNassCodeTimezoneIANA
}