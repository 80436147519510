import React, { useState, useRef } from 'react';

const VirtualizedLoadTenderTable = ({ loadtenders }) => {
    const [startIndex, setStartIndex] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const visibleRowCount = 15; // Fixed number of visible rows
    const rowHeight = 35;
    const tableRef = useRef(null);

    const handleScroll = () => {
        if (tableRef.current) {
            const newStartIndex = Math.floor(tableRef.current.scrollTop / rowHeight);
            setStartIndex(newStartIndex);
        }
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setStartIndex(0); // Reset to the start when a new search is made
    };

    const filteredLocations = loadtenders.length > 0 ? loadtenders.filter() : [];

    const endIndex = startIndex + visibleRowCount;
    const visibleData = filteredLocations.slice(startIndex, endIndex);


    return (
        <div>
            <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder={`Search ${loadtenders.length} by ____`}
                style={{ marginBottom: '10px', padding: '8px', width: '100%' }}
            />
            <div
                ref={tableRef}
                onScroll={handleScroll}
                style={{
                    height: `${visibleRowCount * rowHeight}px`, // Fixed height based on number of visible rows
                    overflowY: 'auto',
                    border: '1px solid #ddd',
                }}
            >
                <table style={{ width: '100%', tableLayout: 'fixed' }}>
                    <thead>
                        <tr>
                            <th style={{ width: '15%' }}>Shipment ID</th>
                            <th style={{ width: '25%' }}></th>
                        </tr>
                    </thead>
                    <tbody style={{ position: 'relative', height: `${filteredLocations.length * rowHeight}px` }}>
                        {visibleData.map((loadtender, index) => (
                            <tr
                                key={loadtender['Shipment ID']}
                                style={{
                                    position: 'absolute',
                                    top: `${(startIndex + index) * rowHeight}px`,
                                    height: `${rowHeight}px`,
                                    width: '100%',
                                    display: 'flex',
                                }}
                            >
                                    <td>{index + 1}</td>
                                    <td>{index + 1}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default VirtualizedLoadTenderTable;
