import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

function getTimeStamp() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const milliseconds = String(now.getMilliseconds()).padStart(3, '0');

    return `${year}${month}${day}_${hours}${minutes}${seconds}${milliseconds}`; 
}

const generateExcel = (data, name) => {
    name = name || 'MCTMS';
    const timestamp = getTimeStamp();
    const filename = `${name}_${timestamp}.xlsx`;

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, filename);
};

const generateCSV = (data, name) => {
    name = name || 'MCTMS';
    const timestamp = getTimeStamp();
    const filename = `${name}_${timestamp}.csv`;

    const header = Object.keys(data[0]).join(',');
    const rows = data.map(obj => Object.values(obj).join(',')).join('\n');
    const csv = [header, ...rows].join('\n');

    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
};

const generatePDF = (data, name) => {    
    name = name || 'MCTMS';
    const timestamp = getTimeStamp();
    const filename = `${name}_${timestamp}.pdf`;

    const doc = new jsPDF('landscape');
    const headers = Object.keys(data[0]).filter(key => key !== '_id');

    const threshold = 5;
    const isVerticalTable = headers.length > threshold;

    let bodyContent = [];
    const totalObjects = data.length;

    if (isVerticalTable) {
        data.forEach((d, index) => {
            bodyContent.push([`File: ${name}`, `Index: ${index + 1} of ${totalObjects}`])

            const values = Object.entries(d).filter(([key]) => key !== '_id');
            bodyContent.push(...values, [" "," "]);
        });
    } else {
        bodyContent = data.map(obj => {
            const { _id, ...rest } = obj;
            return Object.values(rest);
        });
    }

    if (isVerticalTable) {
        doc.autoTable({
            body: bodyContent,
            theme: 'grid',
            styles: {
                fontSize: 8,
                valign: 'middle',
                halign: 'left', 
                lineWidth: 0.5,
            },
            columnStyles: {
                0: { cellWidth: '30%', halign: 'left' },
                1: { cellWidth: '70%', halign: 'left' },  
            },
            alternateRowStyles: {
                fillColor: [235, 235, 235]
            },
            showHead: 'never',
            didParseCell: (data) => {
                const cellContent = data.cell.raw;
            
                // Check explicitly for blank rows
                if (data.column.index === 0 && cellContent === " ") {
                    data.cell.styles.lineWidth = 0;
                    data.cell.styles.fillColor = null;
                    return;
                } else if (data.column.index === 1 && cellContent === " ") {
                    data.cell.styles.lineWidth = 0;
                    data.cell.styles.fillColor = null;
                    return;
                }
            
                if (!cellContent || typeof cellContent !== 'string') {
                    return;
                }

                if (cellContent.startsWith(`File:`) || cellContent.startsWith(`Index`)) {
                    data.cell.styles.textColor = 255;
                    data.cell.styles.fillColor = [41, 128, 185];
                    data.cell.styles.fontSize = 10;
                }
            }
        });   


    // let bodyContent;

    // if (isVerticalTable) {
    //     bodyContent = headers.map((header, index) => {
    //         return data.map(d => d[header]);
    //     });
    // } else {
    //     bodyContent = data.map(obj => {
    //         const { _id, ...rest } = obj;
    //         return Object.values(rest);
    //     });
    // }

    // if (isVerticalTable) {
    //     doc.autoTable({
    //         head: [data.map(d => d.name)],
    //         body: bodyContent,
    //         theme: 'grid',
    //         styles: {
    //             fontSize: 8,
    //             valign: 'middle',
    //             halign: 'center',
    //             lineWidth: 0.5,
    //         },
    //         columnStyles: {
    //             0: { cellWidth: 'auto', halign: 'left' }, // specify column (0-based index) specific styles
    //         },
    //         // headStyles: {
    //         //     fillColor: [41, 128, 185], // color for the header row
    //         //     textColor: 255, // text color for header, 255 indicates white color
    //         //     fontSize: 12,
    //         // },
    //         alternateRowStyles: {
    //             fillColor: [235, 235, 235] 
    //         }
    //     });
    } else {
        doc.autoTable({
            head: [headers],
            body: bodyContent,
            theme: 'grid',
            styles: {
                fontSize: 8,
                valign: 'middle',
                halign: 'center',
                lineWidth: 0.5,
            },
            columnStyles: {
                0: { cellWidth: 'auto'}, // specify column (0-based index) specific styles
            },
            headStyles: {
                fillColor: [41, 128, 185], // color for the header row
                textColor: 255, // text color for header, 255 indicates white color
                fontSize: 12,
            },
            alternateRowStyles: {
                fillColor: [235, 235, 235] 
            }
        });
    }
    doc.save(filename);
};

// const generatePDF = () => {
//     window.print();
// }

export { generateExcel, generateCSV, generatePDF };
