import React, { useState, useEffect } from 'react'
import { Container, Card, Button, Row, Col,  Table, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useRole } from "../context/role";
import { Link } from 'react-router-dom';
import { FaBookmark, FaMapPin, FaStore, FaTag, FaTrailer, FaTruck } from 'react-icons/fa';
import { FaBuildingFlag, FaTruckFront } from 'react-icons/fa6';
import { HiOutlineIdentification } from 'react-icons/hi';
import { FiRepeat } from 'react-icons/fi';


function DataPage() {

    const { userRole, checkNavPermissions } = useRole();

    const [toggle, setToggle] = useState(false);
    useEffect(() => {
        const savedToggle = localStorage.getItem('@tms-featurepage-toggle');
        if (savedToggle !== null) {
            setToggle(JSON.parse(savedToggle));
        }
    }, []);
    useEffect(() => {
        localStorage.setItem('@tms-featurepage-toggle', JSON.stringify(toggle));
    }, [toggle]);

    const features = [
        { href: "/data/companies", icon: <FaBuildingFlag />, text: "Company", detail: 'Use this tab to view your companies.' },
        { href: "/data/assignments", icon: "", text: "Assignments", detail: 'Use this tab to manage your trading partners.' },
        // { href: "/data/tradingpartners", icon: <FaHandshake />, text: "Trading Partners", detail: "Business to Business connections." },
        { href: "/data/customers", icon: <FaStore />, text: "Customers", detail: "Manage customers/contracts" },
        { href: "/data/carriers", icon: <FaTruckFront />, text: "3PL Carriers", detail: 'Use this tab to manage your third party logistics providers.' },
        { href: "/data/location", icon: <FaMapPin />, text: "Locations", detail: 'Use this tab to manage your locations.' },
        { href: "/data/drivers", icon: <HiOutlineIdentification />, text: "Drivers", detail: ' Use this tab to manage your drivers.  ' },
        { href: "/data/vehicles", icon: <FaTruck />, text: "Vehicles", detail: 'Use this tab to manage your vehicles.' },
        { href: "/data/trailers", icon: <FaTrailer />, text: "Trailers", detail: 'Use this tab to manage your trailers.  ' },
        { href: "/data/frequency", icon: <FiRepeat />, text: "Frequency", detail: 'Use the frequencies tab to manage operation frequencies.   ' },
        { href: "/data/specialop", icon: <FaBookmark />, text: "Special Ops", detail: 'Use this tab to manage special dates. (eg. Christmas)  ' },
        { href: "/data/tags", icon: <FaTag />, text: "Tags", detail: 'Use this tab to manage your custom Tags. ' },
    ]
    const allowedFeatures = checkNavPermissions(userRole, features)

    const cardStyle = {
        textAlign: 'center',
        width: '250px',
        height: '250px'
    }

    const colStyle = {
        margin: '0.25em',
    }

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <Card style={{ border: 0, borderRadius: 0 }}>
                <Card.Header>
                    <Row>
                        <Col>
                            <Card.Title>Data</Card.Title>
                        </Col>
                        <Col xs="auto">
                            <Button variant='primary' onClick={() => setToggle(!toggle)}>Toggle {!toggle ? 'Card' : 'Table'}</Button>
                        </Col>
                    </Row>
                </Card.Header>
                {toggle ?
                    <Card.Body>
                        <Row style={{justifyContent:'space-evenly'}}>
                            {allowedFeatures.map((feature, i) => (
                                <Col xs="auto" style={colStyle}>
                                    <Card style={cardStyle}>
                                        <Card.Header>
                                            <b>{feature.text}</b>
                                        </Card.Header>
                                        <Card.Body key={i}>
                                            <Row>
                                                <Col style={{ marginBottom: '1em' }}>
                                                    <h1>{feature.icon}</h1>
                                                </Col>
                                            </Row>
                                            <Card.Text>
                                                {feature.detail}
                                            </Card.Text>
                                            <Link to={feature.href}>Go to {feature.text}</Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Card.Body>
                    :
                    <Card.Body>
                        <Table responsive>
                            <tbody>
                                {allowedFeatures.map((feature, i) => (
                                    <tr>
                                        <td>
                                            <h3>{feature.icon}</h3>
                                        </td>
                                        <td> <Link to={feature.href}>{feature.text}</Link></td>
                                        <td>{feature.text}</td>
                                        <td>{feature.detail}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                }
            </Card>
        </Container>
    )
}

export default DataPage;

