import { Container, Card, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, useRef } from "react";
import Api from "../../utils/Api";
import { useAuth } from '../../context/auth';
import { useNavigate, useParams } from 'react-router-dom';
import AlertDisplay from "../../components/AlertDisplay";
import AddressAutocomplete from "../../components/AddressAutocomplete";

const initialFormState = {
    firstname: '',
    lastname: '',
    role: '',
}

function AccessNewUserForm() {
    const navigate = useNavigate();
    const [set, didSet] = useState();
    const { idToken } = useAuth();
    const { uid } = useParams();
    const [roles, setRoles] = useState();
    const [user, setUser] = useState();
    const [formdata, setFormdata] = useState(initialFormState)
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const addressAutocompleteRef = useRef(null);
    const [fullAddress, setFullAddress] = useState('');
    const fullAddressState = { fullAddress, setFullAddress }

    useEffect(() => {
        setFormdata({ ...formdata, fullAddress: fullAddress })
    }, [fullAddress])

    async function handlePost() {
        try {
            const data = { ...formdata, 'uid': uid, email: user.email };
            await Api.post('/access/profiles', data, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success', }])
            navigate(`/access/new-users`)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error creating', }])
        }
    }

    const handleDelete = async () => {
        try {
            await Api.delete(`/access/delete-user/${uid}`, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success', }])
            navigate(`/access/new-users`)
        } catch (error) {
            console.error("Error deleting user:", error);
            setAlerts([...alerts, { variant: 'warning', message: 'Error deleting user' }]);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const r = await Api.get('/access/roles/shared', idToken);
                setRoles(r)
                const u = await Api.get(`/firebase/user/${uid}`, idToken);
                setUser(u)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error', }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    }, [idToken, set, uid, alerts])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <br />
            <Container>
                <Card>
                    <Card.Header><b>New User Profile Form</b></Card.Header>
                    {set ?
                        <Card.Body>
                            <Row>
                                <Col sm={12} md={6} lg={3}>
                                    Role
                                    <Form.Select
                                        type="text"
                                        onChange={e => setFormdata({ ...formdata, role: JSON.parse(e.target.value) })}
                                    >
                                        <option></option>
                                        {roles && roles.map((role, i) => (
                                            <option key={i} value={JSON.stringify(role)}>{role.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col sm={12} md={6} lg={3}>
                                    Email
                                    <Form.Control
                                        autoComplete="off"
                                        type="text"
                                        value={user.email}
                                        readOnly={true}
                                        disabled
                                    />
                                </Col>
                                <Col sm={12} md={6} lg={3}>
                                    First Name
                                    <Form.Control
                                        autoComplete="off"
                                        type="text"
                                        value={formdata.firstname}
                                        onChange={e => setFormdata({ ...formdata, firstname: e.target.value })}
                                    />
                                </Col>
                                <Col sm={12} md={6} lg={3}>
                                    Last Name
                                    <Form.Control
                                        autoComplete="off"
                                        type="text"
                                        value={formdata.lastname}
                                        onChange={e => setFormdata({ ...formdata, lastname: e.target.value })}
                                    />
                                </Col>
                                <Col sm={12} md={6} lg={3}>
                                    Phone
                                    <Form.Control
                                        autoComplete="off"
                                        type="text"
                                        value={formdata.phoneNumber}
                                        onChange={e => setFormdata({ ...formdata, phoneNumber: e.target.value })}
                                    />
                                </Col>
                                <Col sm={12} md={6} lg={3}>
                                    Birthday
                                    <Form.Control
                                        autoComplete="off"
                                        type="date"
                                        value={formdata.birthday}
                                        onChange={e => setFormdata({ ...formdata, birthday: e.target.value })}
                                    />
                                </Col>
                                <Col>
                                    Address
                                    <AddressAutocomplete ref={addressAutocompleteRef} fullAddressState={fullAddressState} />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <Button variant="primary" onClick={() => handlePost()}>Create</Button>
                                </Col>
                                <Col xs="auto">
                                    <Button variant="danger" onClick={() => handleDelete()}>Delete</Button>
                                </Col>
                            </Row>
                        </Card.Body>
                        :
                        <Card.Body>
                            <Spinner />
                        </Card.Body>
                    }

                </Card>
            </Container>
        </Container>
    );
}

export default AccessNewUserForm;