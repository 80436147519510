import React from 'react';
import { Chart } from 'react-google-charts';

function CalendarChart({ title, opdata, adjust }) {
  const height = 200 * adjust;

  const data = [
    ['Date', 'Value'],
  ];

  const options = {
    title: title,
    calendar: {
      cellSize: 20,
    },
    colorAxis: {
      colors: ['red', 'green'],
      values: [0, 1],
    },
    timeZone: 'UTC',
  };

  opdata.forEach(opdata => {
    let [date, op] = opdata;
    const d = new Date(date);
    const year = d.getUTCFullYear();
    const month = d.getUTCMonth();
    const day = d.getUTCDate();
    data.push([new Date(year, month, day), op])
  });

  const GChart = () => {
    return (
      <Chart
        chartType="Calendar"
        width={'100%'}
        height={`${height}px`}
        data={data}
        options={options}
      />
    )
  }

  return (
    <GChart />
  );
}

export default CalendarChart