function toRFC3339(date) {
    if (typeof date === "string") {
      date = new Date(date);
    }
    if (Object.prototype.toString.call(date) === "[object Date]") {
      return date.toISOString().replace(/\.\d{3}Z$/, "Z");
    } else {
      throw new Error("Invalid date format");
    }
  }

  function formatExcelDateToJSDate(serial) {
    var utc_days = Math.floor(serial - 25568);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);
  
    var fractional_day = serial - Math.floor(serial) + 0.0000001;
  
    var total_seconds = Math.floor(86400 * fractional_day);
  
    var seconds = total_seconds % 60;
  
    total_seconds -= seconds;
  
    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;
  
    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds).toISOString().slice(0, 10)
  }

function formatExcelDateToJSDateTime(serial) {
    var utc_days = Math.floor(serial - 25568);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);

    var fractional_day = serial - Math.floor(serial) + 0.0000001;

    var total_seconds = Math.floor(86400 * fractional_day);

    var seconds = total_seconds % 60;

    total_seconds -= seconds;

    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds).toLocaleString('en-US');
}

function utcTimestamp(){
  const currentTimestamp = Date.now(); // Get the current UTC timestamp in milliseconds
  const date = new Date(currentTimestamp);
  const utcYear = date.getUTCFullYear();
  const utcMonth = date.getUTCMonth() + 1; // Months are zero-based, so we add 1
  const utcDay = date.getUTCDate();
  const utcHour = date.getUTCHours();
  const utcMinute = date.getUTCMinutes();
  const utcSecond = date.getUTCSeconds();
  const utcTime = `${utcYear}-${utcMonth.toString().padStart(2, '0')}-${utcDay.toString().padStart(2, '0')} ${utcHour.toString().padStart(2, '0')}:${utcMinute.toString().padStart(2, '0')}:${utcSecond.toString().padStart(2, '0')}`;
  return utcTime  
}

function utcToTimezone(utcTimestampString, timezone) {
  const utcDate = new Date(utcTimestampString);
  const targetDateString = utcDate.toLocaleDateString('en-US', {
    timeZone: timezone,
    timeZoneName: 'short',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hourCycle: 'h23'
  });
  return targetDateString;
}

function parseUtcToStr(yyymmdd_hhmmss, timezone) {
  const [datePart, timePart] = yyymmdd_hhmmss.split(' ');
  const [year, month, day] = datePart.split('-');
  const [hour, minute, second] = timePart.split(':');
  
  const utcDate = new Date(Date.UTC(year, month - 1, day, hour, minute, second));
  const targetDateString = utcDate.toLocaleDateString('en-US', {
    timeZone: timezone,
    timeZoneName: 'short',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hourCycle: 'h23'
  });
  return targetDateString;
}

function getDayOfWeekFromDate(dateString) {
  let daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  let date = new Date(dateString);
  let dayOfWeek = daysOfWeek[date.getDay()];
  return dayOfWeek;
}

function formatDateFromDateStr(dateString) {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear().toString().substr(-2); // Extract last 2 digits of year
  return `${month}/${day}/${year}`;
}

function formatDateStrToDayOfWeek(YYYYMMDD) {
  let daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  let parts = YYYYMMDD.split('-');
  let date = new Date(parts[0], parts[1] - 1, parts[2]);
  let dayOfWeek = daysOfWeek[date.getDay()];
  return dayOfWeek;
}

function formatDateStrToMMDDYY(YYYYMMDD) {
    let parts = YYYYMMDD.split('-');
    let date = new Date(parts[0], parts[1] - 1, parts[2]);
    return (date.getMonth() + 1).toString().padStart(2, '0') + '/' 
           + date.getDate().toString().padStart(2, '0') + '/' 
           + date.getFullYear();
}

function formatDateStrToMMDDYYHHMM(YYYYMMDDTHHMMSS) {
  const date = new Date(YYYYMMDDTHHMMSS);
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear().toString().slice(-2); // Get the last two digits of the year
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${month}/${day}/${year} ${hours}:${minutes}`;
}

function formatDateStrToMMDDYYHHMMTZ(YYYYMMDDTHHMMSS) {
  const date = new Date(YYYYMMDDTHHMMSS);
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear().toString().slice(-2); // Get the last two digits of the year
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const shortTimeZone = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).format(date).split(' ').pop();
  return `${month}/${day}/${year} ${hours}:${minutes} ${shortTimeZone}`;
}

function formatDateStrToMMDDYYHHMMSSTZ(YYYYMMDDTHHMMSS) {
  const date = new Date(YYYYMMDDTHHMMSS);
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear().toString().slice(-2); // Get the last two digits of the year
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const shortTimeZone = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).format(date).split(' ').pop();
  return `${month}/${day}/${year} ${hours}:${minutes}:${seconds} ${shortTimeZone}`;
}

function millisecondsToHHMM(milliseconds) {
  const hours = Math.floor(milliseconds / 3600000);
  milliseconds %= 3600000;
  const minutes = Math.floor(milliseconds / 60000);
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}`;
}

export {
    toRFC3339,
    formatExcelDateToJSDate,
    formatExcelDateToJSDateTime,
    utcTimestamp,
    utcToTimezone,
    parseUtcToStr,
    formatDateStrToMMDDYY,
    formatDateStrToMMDDYYHHMM,
    formatDateStrToMMDDYYHHMMTZ,
    formatDateStrToMMDDYYHHMMSSTZ,
    formatDateStrToDayOfWeek,
    getDayOfWeekFromDate,
    formatDateFromDateStr,
    millisecondsToHHMM
}



