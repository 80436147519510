import { Container, Card, Row, Col, Form, Button, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from "react";
import Api from "../../utils/Api";
import { useAuth } from '../../context/auth';
import AlertDisplay from '../../components/AlertDisplay'
const initialFormState = {
    name: '',
}
function AssignmentForm() {
    const { idToken } = useAuth();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [formdata, setFormdata] = useState({ name: '', customers: [], users: [] })

    async function handlePost() {
        try {
            await Api.post('/assignments', formdata, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success' }]);
            setFormdata(initialFormState)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Create error' }]);
        }
    }

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <br />
            <Container>
                <Card>
                    <Card.Header><b>Assignment Form</b></Card.Header>
                    {alerts.length > 0 &&
                        <AlertDisplay alertState={alertState} />
                    }
                    <Card.Body>
                        <Row>
                            <Col>
                                Name
                                <Form.Control type="text" value={formdata.name} onChange={e => setFormdata({ ...formdata, name: e.target.value })} />
                            </Col>
                            <Col>
                                Contracts
                                <Form.Control type="text" value={formdata.name} onChange={e => setFormdata({ ...formdata, name: e.target.value })} />
                            </Col>
                            <Col>
                                Users
                                <Form.Control type="text" value={formdata.name} onChange={e => setFormdata({ ...formdata, name: e.target.value })} />
                            </Col>
                        </Row>
                        <br />
                        <Button variant="primary" onClick={() => handlePost()}>Create</Button>
                    </Card.Body>
                </Card>
            </Container>
        </Container>
    );
}

export default AssignmentForm;