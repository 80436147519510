
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Circle, Polygon, useMapEvents, Marker, Tooltip, Polyline, useMap } from 'react-leaflet';
import ReactDOMServer from 'react-dom/server';
import L, { DivIcon } from 'leaflet';
import { FaMapPin } from 'react-icons/fa';
import { FaLocationDot } from "react-icons/fa6";
import { Button, InputGroup, Row, Col, Form, ButtonGroup, Card } from 'react-bootstrap';
import 'leaflet/dist/leaflet.css';
import polyline from '@mapbox/polyline';
import axios from 'axios';

const GeofenceBrokerDisplay = ({ geofenceState, circleGeofenceState, brokeredTracking, loadtenderPlot, gpsPlot, }) => {
  const [geofenceType, setGeofenceType] = useState('polygon');
  const [mapType, setMapType] = useState('street');
  // const [geofences, setGeofences] = useState([]);
  const { geofences, setGeofences } = geofenceState;
  // const [circleGeofence, setCircleGeofence] = useState(null);
  const { circleGeofence, setCircleGeofence } = circleGeofenceState;
  const [activeGeofence, setActiveGeofence] = useState(null);
  const [routePolyline, setRoutePolyline] = useState(null);
  const [brokerPolyline, setBrokerPolyline] = useState(null);
  const [gpsRoutePolyline, setGpsRoutePolyline] = useState(null);
  const [showGpsRoute, setShowGpsRoute] = useState(true);
  // load
  const [routePins, setRoutePins] = useState(null);
  const [brokerPins, setBrokerPins] = useState(null);
  const [showLoadtenderRoute, setShowLoadtenderRoute] = useState(true);
  const [showBrokerTracking, setShowBrokerTracking] = useState(true);
  // hos


  const geofenceColors = ['#ff0000', '#00ff00', '#0000ff', '#ffff00', '#ff00ff', '#00ffff', '#909090', '#000000', '#880000', '#008800'];
  const googleStreetUrl = "https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}";
  const googleHybridUrl = "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}";

  const BATCH_SIZE = 100; // Example batch size, adjust based on API limits

  const getMapCenter = () => {
    if (loadtenderPlot && loadtenderPlot.length > 0 && loadtenderPlot[0].latitude && loadtenderPlot[0].longitude) {
      return [loadtenderPlot[0].latitude, loadtenderPlot[0].longitude];
    } else if (brokeredTracking && brokeredTracking.length > 0 && brokeredTracking[0].Latitude && brokeredTracking[0].Longitude) {
      return [brokeredTracking[0].Latitude, brokeredTracking[0].Longitude];
    } else {
      return [44, -103]
    }
  };

  const mapCenter = getMapCenter();

  const MapEvents = () => {
    useMapEvents({
      click(e) {
        if (geofenceType === 'circle') {
          const newRadius = circleGeofence?.radius || 500;
          const newCenter = [e.latlng.lat, e.latlng.lng];
          setCircleGeofence({
            center: newCenter,
            radius: newRadius,
            color: '#ffa100'
          });
        } else if (activeGeofence !== null) {
          const updatedGeofences = [...geofences];
          const geofence = updatedGeofences[activeGeofence];

          if (geofence?.type === 'polygon') {
            updatedGeofences[activeGeofence] = {
              ...geofence,
              vertices: [...geofence?.vertices, e.latlng]
            };
            setGeofences(updatedGeofences);
          }
        }
      }
    });
    return null;
  };

  //polygon functions
  const renderPolygonVertices = () => {
    return geofences?.flatMap((geofence, geofenceIndex) => {
      if (geofence?.type === 'polygon') {
        return geofence?.vertices.map((vertex, vertexIndex) => (
          <Marker
            key={`${geofence.id}-${vertexIndex}`}
            position={vertex}
            icon={vertexIcon}
          >
          </Marker>
        ));
      }
      return [];
    });
  };

  const vertexIcon = new DivIcon({
    html: ReactDOMServer.renderToString(<FaMapPin style={{ fontSize: '24px', color: 'red' }} />),
    className: '',
    iconSize: [24, 24],
    iconAnchor: [12, 24],
  });

  //Plotting functions
  // const fetchRoute = async (waypoints) => {
  //   const apiKey = '5b3ce3597851110001cf6248c1de2a36ce224b1187db6783aff57bf7';
  //   const url = 'https://api.openrouteservice.org/v2/directions/driving-car';

  //   try {
  //     const response = await fetch(url, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': apiKey
  //       },
  //       body: JSON.stringify({
  //         coordinates: waypoints,
  //         format: 'geojson'
  //       })
  //     });

  //     const data = await response.json();
  //     // console.log('API Response:', response.data)
  //     const encodedPolyline = data.routes[0].geometry;
  //     const decodedPolyline = polyline.decode(encodedPolyline);
  //     const latLngs = decodedPolyline.map(coord => [coord[0], coord[1]]);
  //     return latLngs;
  //   } catch (error) {
  //     console.error('Error fetching the route:', error);
  //     return null;
  //   }
  // };

  const SetViewToBounds = ({ bounds }) => {
    const map = useMap();
    useEffect(() => {
      if (bounds && bounds.isValid()) {
        map.fitBounds(bounds);
      }
    }, [map, bounds]);

    return null;
  };

  const bounds = new L.LatLngBounds(loadtenderPlot?.map(wp => [wp.latitude, wp.longitude]));

  const purplePinIcon = new DivIcon({
    html: ReactDOMServer.renderToString(<FaLocationDot style={{ fontSize: '24px', color: 'purple' }} />),
    className: '',
    iconSize: [24, 24],
    iconAnchor: [12, 24],
  });

  const bluePinIcon = new DivIcon({
    html: ReactDOMServer.renderToString(<FaLocationDot style={{ fontSize: '24px', color: 'blue' }} />),
    className: '',
    iconSize: [24, 24],
    iconAnchor: [12, 24],
  });

  const redPinIcon = new DivIcon({
    html: ReactDOMServer.renderToString(<FaLocationDot style={{ fontSize: '24px', color: 'red' }} />),
    className: '',
    iconSize: [24, 24],
    iconAnchor: [12, 24],
  });

  const orangePinIcon = new DivIcon({
    html: ReactDOMServer.renderToString(<FaLocationDot style={{ fontSize: '24px', color: 'orange' }} />),
    className: '',
    iconSize: [24, 24],
    iconAnchor: [12, 24],
  });

  const greenPinIcon = new DivIcon({
    html: ReactDOMServer.renderToString(<FaLocationDot style={{ fontSize: '24px', color: 'green' }} />),
    className: '',
    iconSize: [24, 24],
    iconAnchor: [12, 24],
  });

  const fetchRoute = async (waypoints) => {
    const batches = splitIntoBatches(waypoints, BATCH_SIZE);
    let combinedLatLngs = [];
    for (const batch of batches) {
      try {
        const latLngs = await fetchRouteBatch(batch);
        combinedLatLngs = combinedLatLngs.concat(latLngs);
      } catch (error) {
        console.error('Error fetching batch:', error);
      }
    }

    return combinedLatLngs;
  };

  const fetchRouteBatch = async (waypointsBatch) => {
    const apiKey = '5b3ce3597851110001cf6248c1de2a36ce224b1187db6783aff57bf7';
    const url = 'https://api.openrouteservice.org/v2/directions/driving-car';

    // const envApiKey = process.env.REACT_APP_OPEN_ROUTE_API_KEY;
    // const envUrl = process.env.REACT_APP_OPEN_ROUTE_API_KEY;


    const response = await axios.post(url, {
      coordinates: waypointsBatch,
      format: 'geojson'
    }, {
      headers: {
        'Authorization': apiKey
      }
    });

    const encodedPolyline = response.data.routes[0].geometry;
    const decodedPolyline = polyline.decode(encodedPolyline);
    return decodedPolyline.map(coord => [coord[0], coord[1]]);
  };

  const splitIntoBatches = (waypoints, batchSize) => {
    let batches = [];
    for (let i = 0; i < waypoints.length; i += batchSize) {
      const batch = waypoints.slice(i, i + batchSize);
      batches.push(batch);
    }
    return batches;
  };

  const renderRoute = async (waypoints, color, zIndex) => {
    if (!waypoints || waypoints.length === 0) return null;

    const transformedWaypoints = waypoints
      .filter(wp => wp.latitude !== 0 || wp.longitude !== 0)
      .map(wp => [wp.longitude, wp.latitude]);

    const routeCoordinates = await fetchRoute(transformedWaypoints);

    if (routeCoordinates) {
      return (
        <Polyline
          positions={routeCoordinates}
          pathOptions={{ color, zIndex }}
        />
      );
    }

    return null;
  };

  const renderBroker = async (waypoints, color, zIndex) => {
    if (!waypoints || waypoints.length === 0) return null;

    const transformedWaypoints = waypoints
      .filter(wp => wp.latitude !== 0 || wp.longitude !== 0)
      .map(wp => [wp.longitude, wp.latitude]);

    const routeCoordinates = await fetchRoute(transformedWaypoints);

    if (routeCoordinates) {
      return (
        <Polyline
          positions={routeCoordinates}
          pathOptions={{ color, zIndex }}
        />
      );
    }

    return null;
  };

  const renderRoutePins = (waypoints, pinIcon) => {
    if (!waypoints || waypoints.length === 0) return null;

    return waypoints.map((wp, index) => (
      <Marker
        key={`route-${index}`}
        position={[wp.latitude, wp.longitude]}
        icon={pinIcon}
      >
        <Tooltip direction="top" offset={[0, -20]} opacity={1} permanent>
          {wp.name}
        </Tooltip>
      </Marker>
    ));
  }

  const renderBrokerPins = (waypoints, pinIcon) => {
    if (!waypoints || waypoints.length === 0) return null;

    return waypoints.map((wp, index) => (
      <Marker
        key={`carrier-${index}`}
        position={[wp.Latitude, wp.Longitude]}
        icon={pinIcon}
      >
        <Tooltip direction="top" offset={[0, -20]} opacity={1} permanent>
          {new Date(wp.Timestamp).toLocaleString()}
        </Tooltip>
      </Marker>
    ));
  }

  useEffect(() => {
    if (loadtenderPlot) {
      renderRoute(loadtenderPlot, "purple", 1000).then(polyline => {
        setRoutePolyline(polyline);
      });
      const pins = renderRoutePins(loadtenderPlot, purplePinIcon)
      setRoutePins(pins);
    }

    if (brokeredTracking) {
      renderBroker(brokeredTracking, "red", 1000).then(polyline => {
        setBrokerPolyline(polyline);
      });
      const pins = renderBrokerPins(brokeredTracking, redPinIcon)
      setBrokerPins(pins);
    }

  }, [loadtenderPlot, brokeredTracking,]);

  return (
    <Card style={{ border: 0 }}>
      <MapContainer
        center={mapCenter}
        zoom={7}
        doubleClickZoom={false}
        // zoomControl={false}
        scrollWheelZoom={false}
        style={{ height: '600px', width: '100%' }}
      >
        <SetViewToBounds bounds={bounds} />
        <TileLayer
          attribution='Google Maps'
          url={mapType === 'street' ? googleStreetUrl : googleHybridUrl}
        />

        <MapEvents />

        {(circleGeofence && (Object.keys(circleGeofence).length > 0)) && (
          <Circle
            center={circleGeofence?.center}
            radius={circleGeofence?.radius}
            color={circleGeofence?.color}
          />
        )}

        {geofences.map((geofence) => (
          geofence?.type === 'polygon' && geofence?.vertices?.length >= 3 ?
            <Polygon key={geofence?.id} positions={geofence?.vertices} color={geofence?.color}>
              <Tooltip
                direction="center"
                offset={[0, 0]}
                opacity={0.9}
                permanent={true}
              >{geofence?.name}</Tooltip>
            </Polygon> :
            geofence?.type === 'polygon' && geofence?.vertices.length >= 3 ?
              <Polygon key={geofence?.id} positions={geofence?.vertices} color={geofence?.color}>
                <Tooltip
                  direction="center"
                  offset={[0, 0]}
                  opacity={0.9}
                  permanent={true}
                >{geofence?.name}</Tooltip>
              </Polygon> :
              null
        ))}

        {renderPolygonVertices()}
        {showLoadtenderRoute && routePins}
        {showLoadtenderRoute && routePolyline}
        {showBrokerTracking && brokerPins}
        {showBrokerTracking && brokerPolyline}
        {showGpsRoute && gpsRoutePolyline}
      </MapContainer>
      <Card.Body>
        <Row>
          <Col xs="auto">
            <ButtonGroup horizontal="true" className=''>
              <Button variant='primary' className='me-1' onClick={() => setMapType(currentMapType => currentMapType === 'street' ? 'hybrid' : 'street')}>
                {/* {mapType === 'street' ? 'Switch to Hybrid Map' : 'Switch to Street Map'} */}
                {/* Toggle Map View */}
                {mapType === 'street' ? <>Hybrid Map</> : <>Street Map</>}
              </Button>
            </ButtonGroup>
          </Col>
          <Col xs="auto">
            <div className="legend-line blue-line"></div>
            <Form.Check
              type="switch"
              id="loadtender-toggle"
              label="Load Tender Route"
              checked={showLoadtenderRoute}
              onChange={() => setShowLoadtenderRoute(!showLoadtenderRoute)}
            />
          </Col>
          <Col xs="auto">
            <div className="legend-line blue-line"></div>
            <Form.Check
              type="switch"
              id="loadtender-toggle"
              label="Carrier Tracking"
              checked={showBrokerTracking}
              onChange={() => setShowBrokerTracking(!showBrokerTracking)}
            />
          </Col>
        </Row>
        <>
          {geofences.map((geofence, index) => (
            geofence?.type === 'polygon' ? (
              <div key={geofence?.id} className='my-2'>
                <Row className="align-items-center g-2">
                  <Col xs={12} md={8} className='d-flex'>
                    <div style={{
                      width: '15px',
                      height: '38px',
                      backgroundColor: geofence?.color,
                    }}>
                    </div>
                    <InputGroup>
                      <InputGroup.Text id={`basic-addon${index}`}>Name</InputGroup.Text>
                      <Form.Control
                        placeholder="Name"
                        aria-label="Name"
                        aria-describedby={`basic-addon${index}`}
                        type="text"
                        value={geofence?.name || ''}
                        className=''
                        onChange={(e) => {
                          const updatedGeofences = [...geofences];
                          updatedGeofences[activeGeofence].name = e.target.value;
                          setGeofences(updatedGeofences);
                        }}
                        disabled
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </div>
            ) : null
          ))}
        </>
      </Card.Body>
    </Card>
  );
};

export default GeofenceBrokerDisplay;