import React from 'react'
import { Row, Col } from 'react-bootstrap';
import BOX_TRUCK from '../../assets/icons/boxtruck.png';
import { useSettings } from "../../context/settings";
const LoadEyeBoxComponent = ({ devicedata, sensorplacement, box }) => {
  const { settings } = useSettings();
  const imgStyle = {
    position:'relative',
    height:400,
  }

  const boxPlacement = {
    position:'relative',
    top:-400,
    left:75
  }

  const boxStyle = {
    display:'flex',
    alignItems: 'flex-end',
    margin:'1em',
  }

  const rowStyle={
    height: box.height,
    border:'1px solid black',
    alignItems: 'flex-end',
    backgroundColor:'#575a5f'
  }

  let sensorSpacing = 70;

  const calculateLoadPercentage = () => {
    let loadpercent = 0;
    let sectionsloaded = 0;
    const threshold = .075;
    for(let i = 0 ; i < devicedata.measurements.length; i++){
      const benchmark = (box.height - devicedata.measurements[i]) / box.height;
      if(benchmark > threshold){
        sectionsloaded++;
      }
    }
    loadpercent = (sectionsloaded/devicedata.measurements.length * 100).toFixed(0)
    return(loadpercent)
  }


  function convertUTCToTimezone(utcTimestamp, toTimezone) {
    const utcDateString = utcTimestamp.split(' ')[0];
    const utcTimeString = utcTimestamp.split(' ')[1];
  
    const [year, month, day] = utcDateString.split('-');
    const [hours, minutes, seconds] = utcTimeString.split(':');
  
    const utcDate = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
  
    const convertedTime = utcDate.toLocaleString('en-US', { timeZone: toTimezone, hour12: false });
  
    return convertedTime;
  }

  return (
      <div style={{position:'relative'}}>
        <Row style={{margin:'3em'}}>
            <Col sm={12} md={2}>
              <b>Device ID: </b>
              {devicedata.deviceid}
            </Col>
            <Col sm={12} md={2}>
              <b>Load %: </b>
              {calculateLoadPercentage()}
            </Col>
            <Col sm={12} md={4}>
              <b> Timestamp: </b>
              {convertUTCToTimezone(devicedata.timestamp, settings.timezone)}
            </Col>
            <Col sm={12} md={4}>
              <b> Position (LAT, LNG): </b>
              {devicedata.latitude}, {devicedata.longitude}
            </Col>
        </Row>  
        <img src={BOX_TRUCK} alt="boxtruck" style={imgStyle}/>
        <div style={boxPlacement}>
          <div style={boxStyle}>
            <Row style={rowStyle}>
              {devicedata.measurements && devicedata.measurements.map((measurement, i)=>(
                <Col style={{height: (box.height - measurement), width:sensorSpacing, border:'1px dotted black', backgroundColor:'#bcdbbc'}}></Col>
              ))}
            </Row>
          </div>
        </div>

      </div>

  );
};

export default LoadEyeBoxComponent;
