import React, { useState } from 'react';
import { Form, Button, Container, Card, Spinner } from 'react-bootstrap';
import { useAuth } from '../context/auth';
import AlertDisplay from '../components/AlertDisplay';
import Api from '../utils/Api';

const BugReport = () => {
    const { user, idToken } = useAuth();
    const [email, setEmail] = useState(user.email);
    const [bugDescription, setBugDescription] = useState('');
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [isSending, setIsSending] = useState();
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSending(true)
        try {
            await Api.post('/email/bug-report', { email: email, message: bugDescription, }, idToken)
            setBugDescription('');
            setAlerts([...alerts, { variant: 'success', message: `Sent` }]);
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: `Error submitting bug report:${error}` }]);
        } finally {
            setIsSending(false)
        }
    };

    return (
        <Container >
            <AlertDisplay alertState={alertState} />
            <br/>
            <Card>
                <Card.Header>
                    Bug Report
                </Card.Header>
                <Card.Body>

                {isSending ?
                    <Spinner />
                :
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter your email"
                                value={user.email}
                                required
                                readOnly
                                disabled
                            />
                        </Form.Group>

                        <Form.Group controlId="formBugDescription">
                            <Form.Label>Bug description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Describe the bug..."
                                value={bugDescription}
                                onChange={(e) => setBugDescription(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
}
                </Card.Body>
            </Card>
        </Container>


    );
};

export default BugReport;
