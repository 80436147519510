import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { FaPlusCircle } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataDisplayV3 from "../../components/DataDisplayV3";
import LogisticsLoading from "../../components/LogisticsLoading";
import Api from "../../utils/Api";
import { useAuth } from "../../context/auth";
import AlertDisplay from "../../components/AlertDisplay";

function LoadTenderForwardingRule({ feature }) {
    const { idToken } = useAuth();
    const { id } = useParams();
    const [forwardingRule, setForwardingRule] = useState({});
    const [didFetch, setDidFetch] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts };
    const [tenant, setTenant] = useState();
    const [shippers, setShippers] = useState();
    const [carriers, setCarriers] = useState();
    const [conditions, setConditions] = useState([]);
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    async function fetchData() {
        try {
            const tenantProfile = await Api.get('/tenant', idToken);
            setTenant(tenantProfile)

            const queryStr = new URLSearchParams({ isActive: 'true' }).toString();

            const shipperData = await Api.get(`/customers?${queryStr}`, idToken);
            setShippers(shipperData)

            const carrierData = await Api.get(`/carriers?${queryStr}`, idToken);
            setCarriers(carrierData)
        } catch {
            setAlerts([...alerts, { variant: 'warning', messagae: 'Error ocrrued fetching tenant.' }])
        }
    }


    async function handleFetchDataSample() {
        try {
            const queryStr = new URLSearchParams(forwardingRule).toString();
            const sampleData = await Api.get(`/forwarding-rule/sample?${queryStr}`, idToken)
            setData(sampleData)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error fetching sample.' }])
        }
    }

    async function handlePostForwardingRule() {
        try {
            await Api.post(`/loadtenders/forwarding-rules`, { ...forwardingRule }, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Created rule.' }])
            navigate(`/loadtender/forwarding`)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error creating rule.' }])
        }
    }

    async function handleUpdateForwardingRule() {
        try {
            await Api.patch(`/loadtenders/forwarding-rule/${id}`, { ...forwardingRule }, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Update rule.' }])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error updating rule.' }])
        }
    }

    async function handleDeleteForwardingRule() {
        try {
            await Api.delete(`/loadtenders/forwarding-rule/${id}`, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Deleted rule.' }])
            navigate(`/loadtender/forwarding`)
            setForwardingRule({})
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error deleting rule.' }])
        }
    }

    async function fetchForwardingRule() {
        try {
            const existingRule = await Api.get(`/loadtenders/forwarding-rule/${id}`, idToken);
            setForwardingRule(existingRule)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching data.' }])
        } finally {
            setDidFetch(true)
        }
    }


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setForwardingRule(prevState => {
            const updatedState = { ...prevState, [name]: value };
            const rulename = updatedState?.flow === 'carrier'
                ? `When ${updatedState?.flow} "${updatedState?.tradingPartnerSCAC}" sends "${updatedState?.SCAC}" a new ${updatedState?.transaction} with field "${updatedState?.field}" ${forwardingRule?.comparison} "${updatedState?.identifier}", forward it to "${updatedState?.customer}"`
                : `When ${updatedState?.flow} "${updatedState?.customer}" sends "${updatedState?.SCAC}" a new ${updatedState?.transaction} with field "${updatedState?.field}" ${forwardingRule?.comparison} "${updatedState?.identifier}", forward it to "${updatedState?.tradingPartnerSCAC}"`;

            return { ...updatedState, rulename };
        });
    };

    useEffect(() => {
        if (!didFetch) {
            fetchData()
        }
        if (!didFetch && id) {
            fetchForwardingRule();
        }
    }, [didFetch])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <br />
            <Container>
                <Card>
                    <Card.Header>
                        <Card.Title>
                            <Row>
                                <Col>
                                    Load Tender Forwarding Rule
                                </Col>
                            </Row>
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Row style={{ alignItems: 'center' }}>
                            <Col xs="auto">
                                <div>
                                    <h3><input
                                        type="radio"
                                        name="flow"
                                        value="customer"
                                        onClick={handleInputChange}
                                        checked={forwardingRule?.flow === 'customer'}
                                    /> Customer </h3>
                                </div>
                            </Col>
                            <Col xs="auto">
                                <div>
                                    <h3><input
                                        type="radio"
                                        name="flow"
                                        value="carrier"
                                        onClick={handleInputChange}
                                        checked={forwardingRule?.flow === 'carrier'}
                                    /> Carrier </h3>
                                </div>
                            </Col>
                            <Col xs="auto">
                                <Row style={{ alignItems: 'center', border: '1px solid #eee' }}>
                                    <Col xs="auto">
                                        <h4>
                                            Forwarding
                                        </h4>
                                    </Col>
                                    <Col xs="auto">
                                        <span style={forwardingRule?.forward
                                            ? { backgroundColor: 'green', borderRadius: '25%', padding: '0 .25em', margin: '0 .25em', fontSize: '1.25em' }
                                            : { backgroundColor: 'red', borderRadius: '25%', padding: '0 .25em', margin: '0 .25em', fontSize: '1.25em' }
                                        }>
                                            {forwardingRule?.forward ? 'On' : 'Off'}
                                        </span>
                                    </Col>
                                    <Col xs="auto">
                                        <Form.Check
                                            style={{ fontSize: '1.25em' }}
                                            name="forward"
                                            checked={forwardingRule?.forward}
                                            value={forwardingRule?.forward}
                                            onClick={() => setForwardingRule({ ...forwardingRule, forward: !forwardingRule?.forward })}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            Rule Name:
                            <Form.Control
                                type="text"
                                value={forwardingRule?.rulename}
                                readOnly
                                disabled
                            />
                        </Row>
                        {forwardingRule?.flow === 'customer' &&
                            <>
                                <Row>
                                    <Col xs="auto">
                                        <div>
                                            From Shipper
                                            <Form.Control
                                                as="select"
                                                name="customer"
                                                value={forwardingRule?.customer}
                                                onChange={handleInputChange}
                                            >
                                                <option></option>
                                                {shippers && shippers.length > 0 &&
                                                    <>
                                                        {shippers.map((shipper, i) => (
                                                            <option key={i} value={shipper?.cisa}>({shipper?.cisa}) {shipper?.name}</option>
                                                        ))}
                                                    </>
                                                }
                                            </Form.Control>
                                        </div>
                                        <div>
                                            To Company (Tenant)
                                            <Form.Control
                                                type="text"
                                                name="SCAC"
                                                value={forwardingRule?.SCAC}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div>
                                            Transaction
                                            <Form.Control
                                                as="select"
                                                name="transaction"
                                                value={forwardingRule?.transaction}
                                                onChange={handleInputChange}
                                            >
                                                <option></option>
                                                <option value="204">204</option>
                                                <option value="214">214</option>
                                                <option value="990">990</option>
                                            </Form.Control >
                                        </div>
                                        <div>
                                            <Row>
                                                <Col xs="auto">
                                                    Feild
                                                    <Form.Control
                                                        type="text"
                                                        name="field"
                                                        value={forwardingRule?.field}
                                                        onChange={handleInputChange} />
                                                </Col>
                                                <Col xs="auto">
                                                    Comparison
                                                    <Form.Control
                                                        as="select"
                                                        name="comparison"
                                                        value={forwardingRule?.comparison}
                                                        onChange={handleInputChange} >
                                                        <option></option>
                                                        <option value="equal to">equal to</option>
                                                        <option value="not equal to">not equal to</option>
                                                    </Form.Control>
                                                </Col>
                                                <Col xs="auto">
                                                    Value
                                                    <Form.Control
                                                        type="text"
                                                        name="identifier"
                                                        value={forwardingRule?.identifier}
                                                        onChange={handleInputChange} />
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            Forward To
                                            <Form.Control
                                                as="select"
                                                name="tradingPartnerSCAC"
                                                value={forwardingRule?.tradingPartnerSCAC}
                                                onChange={handleInputChange}
                                            >
                                                <option></option>
                                                {carriers && carriers.length > 0 &&
                                                    <>
                                                        {carriers.map((carrier, i) => (
                                                            <option key={i} value={carrier?.scac}>({carrier?.scac}) {carrier.name}</option>
                                                        ))}
                                                    </>
                                                }
                                            </Form.Control>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }

                        {forwardingRule?.flow === 'carrier' &&
                            <>
                                <Row>
                                    <Col xs="auto">
                                        <div>
                                            From Carrier
                                            <Form.Control
                                                as="select"
                                                name="tradingPartnerSCAC"
                                                value={forwardingRule?.tradingPartnerSCAC}
                                                onChange={handleInputChange}
                                            >
                                                <option></option>
                                                {carriers && carriers.length > 0 &&
                                                    <>
                                                        {carriers.map((carrier, i) => (
                                                            <option key={i} value={carrier?.scac}>({carrier?.scac}) {carrier.name}</option>
                                                        ))}
                                                    </>
                                                }
                                            </Form.Control>
                                        </div>
                                        <div>
                                            To Company (Tenant)
                                            <Form.Control
                                                type="text"
                                                name="SCAC"
                                                value={forwardingRule?.SCAC}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div>
                                            Transaction
                                            <Form.Control
                                                as="select"
                                                name="transaction"
                                                value={forwardingRule?.transaction}
                                                onChange={handleInputChange}
                                            >
                                                <option></option>
                                                <option value="204">204</option>
                                                <option value="214">214</option>
                                                <option value="990">990</option>
                                            </Form.Control >
                                        </div>
                                        <div>
                                            <Row>
                                                <Col xs="auto">
                                                    Feild
                                                    <Form.Control
                                                        type="text"
                                                        name="field"
                                                        value={forwardingRule?.field}
                                                        onChange={handleInputChange} />
                                                </Col>
                                                <Col xs="auto">
                                                    Comparison
                                                    <Form.Control
                                                        as="select"
                                                        name="comparison"
                                                        value={forwardingRule?.comparison}
                                                        onChange={handleInputChange} >
                                                        <option></option>
                                                        <option value="Equal To">Equal To</option>
                                                        <option value="Not Equal To">Not Equal To</option>
                                                    </Form.Control>
                                                </Col>
                                                <Col xs="auto">
                                                    Value
                                                    <Form.Control
                                                        type="text"
                                                        name="identifier"
                                                        value={forwardingRule?.identifier}
                                                        onChange={handleInputChange} />
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            Forward To Shipper
                                            <Form.Control
                                                as="select"
                                                name="customer"
                                                value={forwardingRule?.customer}
                                                onChange={handleInputChange}
                                            >
                                                <option></option>
                                                {shippers && shippers.length > 0 &&
                                                    <>
                                                        {shippers.map((shipper, i) => (
                                                            <option key={i} value={shipper?.cisa}>({shipper?.cisa}) {shipper?.name}</option>
                                                        ))}
                                                    </>
                                                }
                                            </Form.Control>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }
                        <br />
                        <Row>
                            <Col>
                                <Button variant="secondary" onClick={() => handleFetchDataSample()}>
                                    Fetch Data Sample
                                </Button>
                            </Col>
                            <Col xs="auto">
                                {id ?
                                    <Row>
                                        <Col>
                                            <Button variant="primary" onClick={() => handleUpdateForwardingRule()}>
                                                Update
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button variant="danger" onClick={() => handleDeleteForwardingRule()}>
                                                Delete
                                            </Button>
                                        </Col>
                                    </Row>
                                    :
                                    <Button variant="primary" onClick={() => handlePostForwardingRule()}>
                                        Create
                                    </Button>
                                }
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Body>
                        <table>
                            <tbody>
                                {data.length > 0 ?
                                    data.map((record, i) => (
                                        <tr key={i}>
                                            <td>
                                                {JSON.stringify(record)}
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <td colSpan={'100%'}>
                                            No data sample.
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </Card.Body>
                </Card>
            </Container>
        </Container>
    )
}

export default LoadTenderForwardingRule;