function limitCharacters(text, limit) {
    if (text.length <= limit) {
        return text;
    } else {
        return text.slice(0, limit) + '...';
    }
}


function adjustStringLength(inputString, desiredLength, fillCharacter = ' ', alignLeft = true) {
    if (inputString.length >= desiredLength) {
      return inputString.substring(0, desiredLength);
    }
  
    const fillCount = desiredLength - inputString.length;
    const fill = fillCharacter.repeat(fillCount);
  
    if (alignLeft) {
      return inputString + fill;
    } else {
      return fill + inputString;
    }
  }
  

export {
    limitCharacters,
    adjustStringLength
}