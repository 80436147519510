import React from "react";
import { Container, Card, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

function NotFound() {
    return (
        <Container fluid>
            <br />
            <Card>
                <Card.Header>Not Found</Card.Header>
                <Card.Body>
                    <Card.Title>Something went wrong.</Card.Title>
                    <Card.Text>
                        Please use the tabs above to navigate away.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default NotFound;