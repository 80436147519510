import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import {
    FaBookmark, FaTruck, FaFileContract,
    FaWpforms, FaUserLock, FaUserPlus, FaBell, FaBug,
    FaChartBar, FaUserCog, FaMapPin, FaExclamation, FaUserClock, FaUpload,
    FaTag, FaStore, FaDatabase, FaPlusCircle,
    FaVest,
    FaUserInjured,
    FaAtlas,
    FaIdBadge,
    FaCog,
    FaTrailer,
    FaBuilding,
    FaBalanceScale
} from 'react-icons/fa';
import { AiFillHome, AiOutlineSchedule, } from 'react-icons/ai';
import { TbReportAnalytics } from 'react-icons/tb';
import { MdGroupAdd, MdHelp, MdNotificationAdd, MdOutlineSensors } from 'react-icons/md';
import { TbNetwork } from 'react-icons/tb'
import { CgAssign, CgProfile } from "react-icons/cg";
import { FiRepeat, } from 'react-icons/fi';
import { FaCubes } from 'react-icons/fa';
import { FaUmbrellaBeach, FaRoad, FaCarCrash } from 'react-icons/fa';
import { GoReport } from 'react-icons/go';
import { BsCashCoin } from 'react-icons/bs'
import { TbPlusMinus } from 'react-icons/tb';
import { HiOutlineTag } from 'react-icons/hi';
import { HiUserGroup } from "react-icons/hi";
import { MdOutlineBadge } from "react-icons/md";
import { FaTruckFront } from 'react-icons/fa6';
import { HiOutlineDocumentReport } from "react-icons/hi";
import { CiBoxList, } from "react-icons/ci";
import { MdOutlinePersonalInjury } from "react-icons/md";
import { GiPoliceBadge } from "react-icons/gi";
import { FaBuildingUser } from "react-icons/fa6";
import { RiDashboard3Line, } from "react-icons/ri";
import { HiOutlineIdentification } from "react-icons/hi2";
import { GrHomeOption, GrSchedules, GrScorecard } from 'react-icons/gr';
import { IoReturnUpForwardSharp } from 'react-icons/io5';


const appFeatureGroups = [
    { feature: 'Home', href: "/", icon: <AiFillHome />, text: "Home" },
    { feature: 'Home', href: "/profile", icon: <FaUserCog />, text: "Profile" },
    { feature: 'Home', href: "/settings", icon: <FaCog />, text: "Settings" },
    { feature: 'Home', href: "/bug-report", icon: <FaBug />, text: "Bug Report" },
    { feature: 'Home', href: "/feature-request", icon: <FaExclamation />, text: "Feature Request" },
    { feature: 'Home', href: "/help", icon: <MdHelp />, text: "Help" },

    { feature: 'Admin', href: "/tenant", icon: <FaBuilding />, text: "Tenant" },
    { feature: 'Admin', href: "/access", icon: <FaUserLock />, text: "Admin" },
    { feature: 'Admin', }, // represents a line or break
    { feature: 'Admin', href: "/access/roles", icon: <MdOutlineBadge />, text: "Roles" },
    { feature: 'Admin', href: "/access/profiles", icon: <CgProfile />, text: "Users" },
    { feature: 'Admin', },
    { feature: 'Admin', href: "/access/new-users", icon: <FaUserPlus />, text: "New Users" },
    { feature: 'Admin', href: "/access/active-users", icon: <FaUserClock />, text: "Active Users" },
    { feature: 'Admin', href: "/access/stale-users", icon: <FaUserCog />, text: "Stale Users" },
    { feature: 'Admin', },
    { feature: 'Admin', href: "/iot", icon: <MdOutlineSensors />, text: "IoT Integrations" },
    { feature: 'Admin', href: "/tmskeys", icon: <MdOutlineSensors />, text: "TMS IoT Keys" },
    { feature: 'Admin', href: "/whitelist", icon: <MdOutlineSensors />, text: "TMS Whitelist" },
    { feature: 'Admin', },
    { feature: 'Admin', href: "/job", icon: <TbReportAnalytics />, text: "Jobs" },
    { feature: 'Admin', href: "/log", icon: <CiBoxList />, text: "Log" },
    { feature: 'Admin', href: "/requests", icon: <CiBoxList />, text: "Requests" },

    { feature: 'Alarms', href: "/alarms", icon: <FaBell />, text: "Alarms" },
    { feature: 'Alarms', href: "/alarms-form", icon: <MdNotificationAdd />, text: "New Alarm" },
    { feature: 'Alarms', href: "/alarms/groups", icon: <HiUserGroup />, text: "Groups" },
    { feature: 'Alarms', href: "/alarms/info", icon: <GrHomeOption />, text: "Options" },
    { feature: 'Alarms', href: "/alarms/reports", icon: <HiOutlineDocumentReport />, text: "Reports" },

    { feature: 'Data', href: "/data", icon: <FaDatabase />, text: "Data" },
    { feature: 'Data' },
    { feature: 'Data', href: "/data/assignments", icon: <CgAssign />, text: "Assignments" },
    { feature: 'Data', href: "/data/customers", icon: <FaStore />, text: "Customers" },
    { feature: 'Data', href: "/data/carriers", icon: <FaTruckFront />, text: "Carriers" },
    { feature: 'Data' },
    { feature: 'Data', href: "/data/drivers", icon: <HiOutlineIdentification />, text: "Drivers" },
    { feature: 'Data', href: "/data/vehicles", icon: <FaTruck />, text: "Vehicles" },
    { feature: 'Data', href: "/data/trailers", icon: <FaTrailer />, text: "Trailers" },
    { feature: 'Data', href: "/data/location", icon: <FaMapPin />, text: "Locations" },
    { feature: 'Data' },
    { feature: 'Data', href: "/data/frequency", icon: <FiRepeat />, text: "Frequency" },
    { feature: 'Data', href: "/data/specialop", icon: <FaBookmark />, text: "Special Ops" },
    { feature: 'Data' },
    { feature: 'Data', href: "/data/tags", icon: <FaTag />, text: "Tags" },

    { feature: 'Driver', href: "/driverportal", icon: <FaIdBadge />, text: "Driver" },
    { feature: 'Driver', href: "/driver/profile", icon: <CgProfile />, text: "Driver Profile" },
    { feature: 'Driver', href: "/driver/accident-form", icon: <FaCarCrash />, text: "Accident Form" },
    { feature: 'Driver', href: "/driver/accident-form", icon: <FaCarCrash />, text: "Accident Form" },
    { feature: 'Driver', href: "/driver/injury-form", icon: <FaUserInjured />, text: "Injury Form" },
    { feature: 'Driver', href: "/driver/score-card", icon: <GrScorecard />, text: "Driver Score Card" },

    { feature: 'Forms', href: "/forms", icon: <FaWpforms />, text: "Forms" },
    { feature: 'Forms', href: "/form/dailyreport", icon: <GoReport />, text: "Daily Report" },
    { feature: 'Forms', href: "/form/employeereimbursement", icon: <BsCashCoin />, text: "Emp. Reimbusement" },
    { feature: 'Forms', href: "/form/pto", icon: <FaUmbrellaBeach />, text: "PTO" },
    { feature: 'Forms', href: "/form/purchaseorder", icon: <HiOutlineTag />, text: "Purchase Order" },
    { feature: 'Forms', href: "/form/roadtest", icon: <FaRoad />, text: "Road Test" },
    { feature: 'Forms', href: "/form/thirdpartyvehicle", icon: <TbPlusMinus />, text: "3rd Party Vehicles" },



    { feature: 'Load Tender', href: "/loadtender", icon: <TbNetwork />, text: "Load Tender" },
    { feature: 'Load Tender' },
    { feature: 'Load Tender', href: "/loadtender/dashboard", icon: <RiDashboard3Line />, text: "Dashboard" },
    { feature: 'Load Tender', href: "/loadtender/accounting", icon: <HiOutlineDocumentReport />, text: "Accounting" },
    { feature: 'Load Tender', href: "/loadtender/performance", icon: <FaChartBar />, text: "Performance" },
    { feature: 'Load Tender', href: "/loadtender/reconciliation", icon: <FaBalanceScale />, text: "Reconciliation" },
    { feature: 'Load Tender' },
    { feature: 'Load Tender', href: "/loadtender/quickcover", icon: <AiOutlineSchedule />, text: "Unscheduled" },
    { feature: 'Load Tender', href: "/loadtender/scheduler", icon: <FaCubes />, text: "New Schedule" },
    { feature: 'Load Tender', href: "/loadtender/schedules", icon: <AiOutlineSchedule />, text: "Schedules" },
    { feature: 'Load Tender', href: "/loadtender/forwarding", icon: <IoReturnUpForwardSharp />, text: "Forwarding" },
    { feature: 'Load Tender' },
    { feature: 'Load Tender', href: "/loadtender/manifest", icon: <GrSchedules />, text: "Manifest" },
    { feature: 'Load Tender', href: "/loadtender/atlas", icon: <FaAtlas />, text: "Load Atlas" },
    { feature: 'Load Tender', href: "/loadtender/new-load", icon: <FaPlusCircle />, text: "New Load" },

    { feature: 'Manager', href: "/manager", icon: <FaBuildingUser />, text: "Manager Portal" },
    { feature: 'Manager', href: "/manager/contract-score-cards", icon: <FaFileContract />, text: "Contract Score Cards" },
    { feature: 'Manager', href: "/manager/carrier-score-cards", icon: <FaBuildingUser />, text: "Carrier Score Card" },
    { feature: 'Manager', href: "/manager/driver-score-cards", icon: <CgProfile />, text: "Driver Score Card" },

    { feature: 'Notifications', href: "/notification", icon: <FaBell />, text: "Notifications" },
    { feature: 'Notifications', href: "/notification-form", icon: <MdNotificationAdd />, text: "New Notification" },
    { feature: 'Notifications', href: "/notification/group-form", icon: <MdGroupAdd />, text: "New Group" },
    { feature: 'Notifications', href: "/notification/groups", icon: <HiUserGroup />, text: "Groups" },
    { feature: 'Notifications', href: "/notification/info", icon: <GrHomeOption />, text: "Options" },
    { feature: 'Notifications', href: "/notification/reports", icon: <HiOutlineDocumentReport />, text: "Reports" },

    { feature: 'Safety', href: "/safety", icon: <FaVest />, text: "Safety" },
    { feature: 'Safety', href: "/safety/dashboard", icon: <GiPoliceBadge />, text: "Safety Dashboard" },
    { feature: 'Safety' },
    { feature: 'Safety', href: "/safety/accidents/data-output", icon: <FaCarCrash />, text: "Accident Graphs" },
    { feature: 'Safety', href: "/safety/accidents/list-of-forms", icon: <FaCarCrash />, text: "Accident List" },
    { feature: 'Safety', href: "/safety/accidents/new-form", icon: <FaCarCrash />, text: "New Accident" },
    { feature: 'Safety', href: "/safety/accidents/upload", icon: <FaUpload />, text: "Upload Accidents" },
    { feature: 'Safety' },
    { feature: 'Safety', href: "/safety/injuries/data-output", icon: <MdOutlinePersonalInjury />, text: "Injury Graphs" },
    { feature: 'Safety', href: "/safety/injuries/list-of-forms", icon: <MdOutlinePersonalInjury />, text: "Injury List" },
    { feature: 'Safety', href: "/safety/injuries/new-form", icon: <MdOutlinePersonalInjury />, text: "New Injury" },
    { feature: 'Safety', href: "/safety/injuries/upload", icon: <FaUpload />, text: "Upload Injuries" },
];

export {
    appFeatureGroups
}