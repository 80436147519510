import React, { createContext } from 'react';
import { useContext } from 'react';


// Create Context
export const PrintContext = createContext();

// Export the hook
export const usePrint = () => useContext(PrintContext);

// Context Provider Component
export const PrintProvider = ({ children, handlePrint }) => {
  return (
    <PrintContext.Provider value={handlePrint}>
      {children}
    </PrintContext.Provider>
  );
};
