import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import { useAuth } from "../../context/auth";
import Api from "../../utils/Api";
import AlertDisplay from "../../components/AlertDisplay";
import { useParams } from "react-router-dom";


const initialTimecard = {
    employee: {},
    datetimeStart: '',
    datetimeEnd: '',
    totalhours: '',
    vehicles: [],
    shipments: [],
    audit: [],
    notes: [],
    totalPay: '',
}

function Timecard({ feature, form }) {
    const { user, id } = useParams();
    const { idToken } = useAuth();
    const [timecard, setTimecard] = useState(initialTimecard);
    const [didFetch, setDidFetch] = useState();
    const [alerts, setAlerts] = useState([])
    const alertState = { alerts, setAlerts }

    async function createTimecard() {
        try {
            await Api.post('/payroll/timecards', timecard, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'Timecard created', }])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured creating timecard', }])
        }
    }

    async function fetchTimecard() {
        setDidFetch(false)
        try {
            const data = await Api.get(`/payroll/timecard/${id}`, idToken);
            setTimecard(data)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching timecard', }])
        } finally {
            setDidFetch(true)
        }
    }

    useEffect(() => {
        if (id) {
            fetchTimecard();
        }
    }, [id])

    return (
        <Container style={{ margin: 0, padding: 0 }} fluid>
            <AlertDisplay alertState={alertState} />
            <Card style={{ border: 0, borderRadius: 0 }}>
                <Card.Header>
                    <Card.Title>
                        Timecard
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    {id && !didFetch ?
                        <Spinner />
                        :
                        <div>
                            <Row>
                                <Col xs="auto">
                                    <Card.Subtitle>Employee</Card.Subtitle>
                                    <Form.Control as="select">
                                        <option value=""> Employee</option>
                                    </Form.Control>
                                </Col>
                                <Col xs="auto">
                                    <Card.Subtitle>Start Work</Card.Subtitle>
                                    <Form.Control type="datetime-local" />
                                </Col>
                                <Col xs="auto">
                                    <Card.Subtitle>End Work</Card.Subtitle>
                                    <Form.Control type="datetime-local" />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>

                                </Col>
                            </Row>
                        </div>
                    }
                </Card.Body>
                <Card.Body>
                    {form === 'create' &&
                        <>
                            <Row>
                                <Col>
                                    <Button onClick={() => createTimecard()}>Create</Button>
                                </Col>
                            </Row>
                        </>
                    }
                    {form === 'update' &&
                        <>
                            <Row>
                                <Col>
                                    <Button>Update</Button>
                                </Col>
                            </Row>
                        </>
                    }
                </Card.Body>
            </Card>
        </Container>
    )
}


export default Timecard;