import React, { useEffect, useState, } from 'react';
import { Container, Card, Button, Form, Table } from 'react-bootstrap';
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';
import AlertDisplay from '../../components/AlertDisplay';
import LogisticsLoading from '../../components/LogisticsLoading';

function TMSWhitelist() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts };

    const [newWhitelist, setNewWhitelist] = useState({ address: '', detail: '' })
    const [whitelistKeys, setWhitelists] = useState([])
    async function handleGetWhitelists() {
        try {
            const keys = await Api.get('/iot/whitelist', idToken)
            setWhitelists(keys)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'No whitelist address', }])
        } finally {
            didSet(true)
        }
    }

    async function handlePostWhitelist() {
        try {
            if (newWhitelist) {
                await Api.post('/iot/whitelist', { ...newWhitelist }, idToken)
                setAlerts([...alerts, { variant: 'success', message: 'Created whitelist address', }])
                setNewWhitelist({ address: '', detail: '', })
            }
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error creating whitelist address', }])
        } finally {
            handleGetWhitelists()
        }
    }

    async function handleDeleteWhitelist(id) {
        try {
            await Api.delete(`/iot/whitelist/${id}`, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Deleted key', }])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error deleting key', }])
        } finally {
            handleGetWhitelists()
        }
    }

    useEffect(() => {
        if (!set) {
            handleGetWhitelists()
        }
    }, [set])


    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <br />
            <Container>
                <Card>
                    <Card.Header>
                        <b>IoT - Internet of Things</b>
                    </Card.Header>
                    <Card.Body>
                        {!set ?
                            <LogisticsLoading message={'Loading...'} />
                            :
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>Whitelist</th>
                                        <th>Detail</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {whitelistKeys.map((apiKey, i) => (
                                        <tr key={i}>
                                            <td>{apiKey.address}</td>
                                            <td>{apiKey.detail}</td>
                                            <td><Button variant='danger' onClick={() => handleDeleteWhitelist(apiKey._id)}>Delete</Button></td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>
                                            <Form.Control type="text" placeholder="Enter address here..." value={newWhitelist.address} onChange={e => setNewWhitelist({ ...newWhitelist, address: e.target.value })} />
                                        </td>
                                        <td>
                                            <Form.Control type="text" placeholder="Enter detail here..." value={newWhitelist.detail} onChange={e => setNewWhitelist({ ...newWhitelist, detail: e.target.value })} />
                                        </td>
                                        <td>
                                            <Button variant='primary' onClick={() => handlePostWhitelist()}>
                                                Create
                                            </Button>
                                        </td>
                                    </tr>
                                </tfoot>
                            </Table>
                        }
                    </Card.Body>
                </Card>
            </Container>
        </Container>
    );
}

export default TMSWhitelist;
