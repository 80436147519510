import { Container, Card,  Spinner, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import Api from "../../utils/Api";
import { useAuth } from '../../context/auth';
import appRoutes from "../../context/approutes";
import { multidimensionalGroupBy } from '../../utils/Array.helper'
import AlertDisplay from "../../components/AlertDisplay";
import DataDisplayV3 from "../../components/DataDisplayV3";

function AccessActive() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [activeUsers, setActiveUsers] = useState([]);

    const routeGroups = multidimensionalGroupBy(appRoutes, ['feature']);
    const routeGroupKeys = Object.keys(routeGroups);
    routeGroupKeys.sort((a, b) => {
        return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
    });


    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Api.get(`/access/active-users/1440`, idToken);
                const cleanUsers = data?.map(user => ({
                    Email: user.Email,
                    'Last Refresh': user['Last Refresh'],
                    'Last Signin': user['Last Signin']
                })) || [];
                setActiveUsers(cleanUsers);
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'An error occured getting active users', }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    })



    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{ border: 0, borderRadius: 0 }}>
                <Card.Header>
                    <b>Today's Active Users</b>
                </Card.Header>
                <Card.Body>
                    {(set) ?
                        <DataDisplayV3
                            lsKey={'@mctms-active-user-display'}
                            sourceName={'Active Users'}
                            dataSource={activeUsers}
                            urlKey="_id"
                            urlPath="/access/profile/"
                        />
                        :

                        <Spinner />
                    }
                </Card.Body>

            </Card>
        </Container>
    );
}

export default AccessActive;