import React, { useEffect, useState } from "react";
import logo from '../../assets/logo.png'
import sybrlogo from '../../assets/sybr-logo-2.png'
import { Container, Card, Row, Col, Button, Modal, Form, Spinner } from "react-bootstrap";
import AlertDisplay from "../../components/AlertDisplay";
import LogisticsLoading from "../../components/LogisticsLoading";
import { findUSPSNASSCode, formatUSPSDateTime } from "../../context/uspsdata";
import useGeolocation from "../../hooks/useGeolocation";
import GeofencePinDisplay from "../../components/GeofencePinDisplay";
import { FaMapPin } from "react-icons/fa";

function LoadTenderPublic() {
    const [set, didSet] = useState();
    const [loadtender, setLoadTender] = useState();
    const [mobileTracking, setMobileTracking] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    const {
        latitude,
        longitude,
        altitude,
        altitudeAccuracy,
        accuracy,
        heading,
        speed,
        timestamp,
        error,
        permissionDenied,
    } = useGeolocation();

    const uiContainerStyle = {
        minHeight: '100vh',
        maxHeight: '100vh',
        minWidth: '100vw',
        maxWidth: '100vw',
        padding: "0px",
        margin: "0px",
        overflowX: 'hidden',
        '@media print': {
            display: 'none',
        }
    }

    function extract204ContractFromStops(Stops) {
        if (Stops) {
            const extractedReferences = Stops.map(via => via?.References.map(referance => referance?.Identifier));
            const extractedValues = extractUniqueValues(extractedReferences)
            const extractedData = extractPrefixes(extractedValues)
            const uniqueData = [...new Set(extractedData)];
            return uniqueData.join(', \n').replace('_', ' ')
        }
    }

    function extractUniqueValues(arr) {
        const uniqueValues = new Set();

        arr.forEach(subArray => {
            subArray.forEach(value => {
                uniqueValues.add(value);
            });
        });

        return Array.from(uniqueValues);
    }

    function extractPrefixes(arr) {
        return arr.map(item => {
            const parts = item.split("_"); // Split the string into parts using the underscore character
            if (parts.length >= 2) {
                return parts.slice(0, 2).join("_"); // Combine the first two parts with an underscore
            } else {
                return item; // If there are fewer than 2 parts, return the original item
            }
        });
    }

    async function fetchLoadTender() {
        try {
            const queryParams = new URLSearchParams(window.location.search);
            const q = queryParams.get("q");
            const url = `${process.env.REACT_APP_API_BASE_URL}/public/loadtender?q=${q}`
            const response = await fetch(url, { method: 'get' });
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }
            const data = await response.json();
            setLoadTender(data)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured', }])
        } finally {
            didSet(true)
        }
    }

    async function fetchMobileTracking() {
        try {
            const queryParams = new URLSearchParams(window.location.search);
            const q = queryParams.get("q");
            const url = `${process.env.REACT_APP_API_BASE_URL}/public/mobileTracking?q=${q}`
            const response = await fetch(url, { method: 'get' });
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }
            const data = await response.json();
            setMobileTracking(data)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured', }])
        } finally {
            didSet(true)
        }
    }

    async function handleRemoteCheckin() {
        try {
            if (permissionDenied) {
                setAlerts([...alerts, { variant: 'warning', message: 'Enable location permissions.', }])
                return;
            }

            if (!latitude || !longitude) {
                setAlerts([...alerts, { variant: 'warning', message: 'Position not located.', }])
                return;
            }

            const formattedDate = new Date(timestamp).toISOString();

            const mobileUpdate = {
                "Status": "checkin",
                "StopNumber": "",
                "SCAC": loadtender.SCAC,
                "ShipmentId": loadtender.ShipmentId,
                "Name": form.Name,
                "UnitNumber": form.UnitNumber,
                "Provider": "SYBRTMS",
                "Timestamp": formattedDate,
                "Accuracy": accuracy,
                "Latitude": latitude,
                "Longitude": longitude,
                "Speed": speed,
                "Heading": heading,
                "Altitude": altitude,
                "AltitudeAccuracy": altitudeAccuracy
            }

            const url = `${process.env.REACT_APP_API_BASE_URL}/public/mobileTracking`
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(mobileUpdate),
            });

            if (response.ok) {
                // The request was successful
                // const data = await response.json(); // Parse the response data if needed
                setAlerts([...alerts, { variant: 'success', message: 'Checkin successful', }])
            } else {
                // The request failed
                // console.error('Error:', response.status, response.statusText);
                setAlerts([...alerts, { variant: 'warning', message: 'Cannot remote checkin. Try again', }])
            }
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Cannot remote checkin. Try again', }])
        }
    }

    async function handleStopArrive() {
        try {
            if (permissionDenied) {
                setAlerts([...alerts, { variant: 'warning', message: 'Enable location permissions.', }])
                return;
            }

            if (!latitude || !longitude) {
                setAlerts([...alerts, { variant: 'warning', message: 'Position not located.', }])
                return;
            }

            if (form.Name === '' || form.UnitNumber === '') {
                setAlerts([...alerts, { variant: 'warning', message: 'Enter your Name and Unit Number.', }])
                return;
            }

            const formattedDate = new Date(timestamp).toISOString();

            const mobileUpdate = {
                "Status": "arrive",
                "StopNumber": arriveModal,
                "SCAC": loadtender.SCAC,
                "ShipmentId": loadtender.ShipmentId,
                "Name": form.Name,
                "UnitNumber": form.UnitNumber,
                "Provider": "SYBRTMS",
                "Timestamp": formattedDate,
                "Accuracy": accuracy,
                "Latitude": latitude,
                "Longitude": longitude,
                "Speed": speed,
                "Heading": heading,
                "Altitude": altitude,
                "AltitudeAccuracy": altitudeAccuracy
            }

            const url = `${process.env.REACT_APP_API_BASE_URL}/public/mobileTracking`
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(mobileUpdate),
            });

            if (response.ok) {
                // The request was successful
                // const data = await response.json(); // Parse the response data if needed
                setAlerts([...alerts, { variant: 'success', message: 'Message sent successfully', }])
            } else {
                // The request failed
                // console.error('Error:', response.status, response.statusText);
                setAlerts([...alerts, { variant: 'warning', message: 'Cannot remote checkin. Try again', }])
            }
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Cannot remote checkin. Try again', }])
        }
    }

    useEffect(() => {
        if (!set) {
            fetchLoadTender()
        }
    }, [set])


    const [checkinModal, setCheckinModal] = useState();
    const [arriveModal, setArriveModal] = useState();

    const [form, setForm] = useState({
        Name: '',
        UnitNumber: '',
    })

    const [geofences, setGeofences] = useState([]);
    const geofenceState = { geofences, setGeofences };
    const [circleGeofence, setCircleGeofence] = useState([]);
    const circleGeofenceState = { circleGeofence, setCircleGeofence };

    return (
        <Container style={uiContainerStyle}>
            <AlertDisplay alertState={alertState} />
            <Row
                style={{
                    padding: "1px",
                    margin: "0px",
                    color: '#fcb716',
                    fontSize: '11px',
                    fontWeight: 'bold',
                    background: '#233f7c',
                    alignItems: 'center'
                }}
            >
                <Col style={{ textAlign: 'center' }}>
                    <span id="tmsTop">SYBR ® - MADE FOR THIS - All Rights Reserved</span>
                </Col>
            </Row>
            <Row
                style={{
                    padding: "1px",
                    margin: "0px",
                    borderTop: '1px solid #bbb',
                    color: '#fcb716',
                    fontSize: '1.2em',
                    fontWeight: 'bold',
                    background: '#233f7c',
                    alignItems: 'center'
                }}
            >
                <Col style={{ textAlign: 'left' }}>
                    <img src={logo} alt="Company Logo" style={{ height: '50px' }} />
                    {` ${process.env.REACT_APP_COMPANY_NAME} `}
                </Col>
                <Col style={{ textAlign: 'right' }}>
                    <a href="https://sybrtms.com" target="_blank" rel="noopener noreferrer">
                        <img src={sybrlogo} alt="SYBR Logo" style={{ height: '50px' }} />
                    </a>
                </Col>
            </Row>
            {(permissionDenied || error) &&
                <Row
                    style={{
                        padding: "1px",
                        margin: "0px",
                        border: '1px solid #dc3912',
                        color: '#dc3912',
                        fontSize: '1.2em',
                        fontWeight: 'bold',
                        background: '#f8f39c',
                        alignItems: 'center'
                    }}
                >
                    <Col>
                        {permissionDenied &&
                            <p>Location access was denied. Please enable location permissions.</p>
                        }
                        {error &&
                            <p>Error: {error}</p>
                        }
                    </Col>
                </Row>
            }
            <Card style={{ margin: '0', border: '0', borderRadius: '0' }}>
                {!set && loadtender ?
                    <LogisticsLoading
                        message={'Loading...'}
                    />
                    :
                    <>
                        <Modal show={checkinModal} size="fullscreen">
                            <Modal.Header>
                                <Card.Title>
                                    Mobile Checkin
                                </Card.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col xs="12">
                                        Name:
                                        <Form.Control type="text" value={form.Name} onChange={(e) => setForm({ ...form, Name: e.target.value })} />
                                    </Col>
                                    <Col xs="12">
                                        Unit Number:
                                        <Form.Control type="text" value={form.UnitNumber} onChange={(e) => setForm({ ...form, UnitNumber: e.target.value })} />
                                    </Col>
                                    <Col xs="12">
                                        Latitude
                                        <Form.Control type="text" value={latitude} disabled />
                                    </Col>
                                    <Col xs="12">
                                        Longitude
                                        <Form.Control type="text" value={longitude} disabled />
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="success" onClick={() => handleRemoteCheckin()}>
                                    Check In
                                </Button>
                                <Button variant="secondary" onClick={() => setCheckinModal(false)}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={arriveModal} size="fullscreen">
                            <Modal.Header>
                                <Card.Title>
                                    Stop {arriveModal}
                                </Card.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col xs="12">
                                        Name:
                                        <Form.Control type="text" value={form.Name} onChange={(e) => setForm({ ...form, Name: e.target.value })} />
                                    </Col>
                                    <Col xs="12">
                                        Unit Number:
                                        <Form.Control type="text" value={form.UnitNumber} onChange={(e) => setForm({ ...form, UnitNumber: e.target.value })} />
                                    </Col>
                                    <Col xs="12">
                                        Latitude
                                        <Form.Control type="text" value={latitude} disabled />
                                    </Col>
                                    <Col xs="12">
                                        Longitude
                                        <Form.Control type="text" value={longitude} disabled />
                                    </Col>
                                </Row>
                                {latitude && longitude &&
                                    <GeofencePinDisplay
                                        geofenceState={geofenceState}
                                        circleGeofenceState={circleGeofenceState}
                                        loadtenderPlot={[{ name: 'Location', latitude: latitude, longitude: longitude }]}
                                    />
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="success" onClick={() => handleStopArrive()}>
                                    Arrive
                                </Button>
                                <Button variant="secondary" onClick={() => setArriveModal()}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Card.Header>
                            <Row>
                                <Col>
                                    {(!latitude || !longitude) ?
                                        <>
                                            Locating... <Spinner size="md" />
                                        </>
                                        :
                                        <>
                                            <FaMapPin /> Located: {latitude}, {longitude}
                                        </>
                                    }
                                </Col>
                            </Row>
                        </Card.Header>
                        {loadtender?.ShipmentId &&
                            <Card.Header>
                                <Row>
                                    <Col xs="auto">
                                        <Button variant="primary" onClick={() => didSet(false)}>
                                            Check for Updates
                                        </Button>
                                    </Col>
                                    <Col xs="auto">
                                        <Button variant="success" onClick={() => setCheckinModal(true)}>
                                            Mobile Check In
                                        </Button>
                                    </Col>
                                </Row>

                            </Card.Header>
                        }
                        <Card.Header>
                            <Card.Title>
                                <table>
                                    <tbody>

                                        <tr>
                                            <th style={{ padding: '0em 1em' }}>Shipment  </th>
                                            <td>{loadtender?.ShipmentId ?? '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ padding: '0em 1em' }}>Customer </th>
                                            <td> {loadtender?.Customer ?? '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ padding: '0em 1em' }}>Carrier SCAC </th>
                                            <td> {loadtender?.SCAC ?? '-'}</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </Card.Title>
                        </Card.Header>
                        <Card.Header>
                            <Card.Title>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th style={{ padding: '0em 1em' }}>Contract-Trip</th>
                                            <td> {extract204ContractFromStops(loadtender?.Stops)}<br /></td>
                                        </tr>
                                        <tr>
                                            <th style={{ padding: '0em 1em' }}>Equipment</th>
                                            <td> {loadtender?.Equipment}<br /></td>
                                        </tr>
                                        <tr>
                                            <th colSpan={'100%'} style={{ padding: '0em 1em' }}>
                                                {loadtender?.Distance}
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </Card.Title>
                        </Card.Header>
                        <>
                            {(loadtender?.Stops && loadtender?.Stops.length > 0) && loadtender?.Stops.map((via, i) => (
                                <React.Fragment key={i}>
                                    <Card.Header>
                                        <Card.Title>
                                            <Row style={{ alignItems: 'center', padding: '0em .5em' }}>
                                                <Col xs="auto">
                                                    <b>
                                                        Stop {i + 1}
                                                    </b>
                                                </Col>
                                                <Col xs="auto">
                                                    <Button variant="warning" onClick={() => setArriveModal(i + 1)}>
                                                        Arrive
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th style={{ padding: '0em 1em' }}>Name</th>
                                                    <td>{findUSPSNASSCode(via.Name)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ padding: '0em 1em' }}>Address</th>
                                                    <td>
                                                        <a target="_blank" rel="noreferrer" href={`https://www.google.com/maps/search/${via.Address1} ${via.City} ${via.State} ${via.Zip}`}>
                                                            {`${via.Address1} ${via.City} ${via.State} ${via.Zip}`}
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th style={{ padding: '0em 1em' }}>Arrive</th>
                                                    <td>{formatUSPSDateTime(via.Name, via.RequestedDate)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ padding: '0em 1em' }}>Depart</th>
                                                    <td>{formatUSPSDateTime(via.Name, via.LastRequestedDate)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Card.Body>
                                </React.Fragment>
                            ))}
                        </>
                    </>
                }
            </Card>
        </Container>
    )
}


export default LoadTenderPublic;