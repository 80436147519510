import { Card, Container, Table } from "react-bootstrap";
import Api from "../../utils/Api";
import { useSettings } from "../../context/settings";
import { useAuth } from "../../context/auth";
import { useEffect, useState } from "react";
import DataDisplayV3 from "../../components/DataDisplayV3";


function LoadTenderAtlas({ feature }) {
    const [set, didSet] = useState();
    const { settings } = useSettings();
    const { idToken } = useAuth();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    const [atlas, setAtlas] = useState([]);

    async function fetchDrafts() {
        // console.log('fetch')
        try {
            const data = await Api.get('/loadtenders/drafts', idToken)
            console.log( Object.keys(data[0]))
            const cleanData = data.map((draft) => {
                return {
                    '_id': draft._id,
                    'Name': draft.DraftName,
                    'CustomerISA': draft.customer.cisa,
                    'Customer': draft.customer.name,
                    'Carrier': draft.SCAC,
                    'Frequency': draft.frequency.name,
                    'Start Ops': draft.startOps,
                    'End Ops': draft.endOps,
                    'Equipment': draft.equipment,
                    'Rate': draft.Rate,
                    'Hours': draft.hours,
                    'Miles': draft.miles,
                }
            })
            setAtlas(cleanData)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching data.', }])
        } finally {
            didSet(true)
        }
    }

    useEffect(() => {
        if (!set) {
            fetchDrafts();
        }
    }, [set])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <Card style={{ borderRadius: 0, border: 0 }}>
                <Card.Header style={{ borderRadius: 0 }}>
                    <Card.Title>Load Atlas</Card.Title>
                </Card.Header>
                <Card.Body>
                    <DataDisplayV3
                        lsKey={`@tms-loadtender-atlas-data`}
                        sourceName={`TMS-Load-Tender-Atlas`}
                        dataSource={atlas}
                        urlKey="_id"
                        popKeys={['_id']}
                        urlPath={feature}
                    />
                </Card.Body>
            </Card>
        </Container>
    )
}

export default LoadTenderAtlas;