import { Alert, Badge, Button, Card, Col, Container, Form, Image, Row, Table } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';
import { FaTrashCan } from "react-icons/fa6";
import SignatureModal from '../../components/SignatureModal';
import PhoneNumberInput from '../../components/PhoneNumberInput';
import { useNavigate, useParams } from 'react-router-dom';
import LOGO from '../../assets/logo.png'
import PrintPDF from '../../components/PrintPDF';
import AddressAutocomplete from '../../components/AddressAutocomplete';
import LoadTenderSearchModal from './LoadTender.SearchModal';
import AlertDisplay from '../../components/AlertDisplay';
import { useAuth } from '../../context/auth';
import Api from '../../utils/Api';
import { findUSPSNASSCode } from '../../context/uspsdata';

// feature = driver = driver confirmation
// feature = carrier = rate confirmation




function LoadTenderLoadConfirmation({ form, feature }) {
    return (
        <Container fluid>
            {/* <pre>
                {JSON.stringify(form, "", 2)}
            </pre> */}
            <PrintPDF sourceName={`MailCarrierTMS-Load-Confirmation-${form?.ShipmentId}`}>
                <div>
                    {form?.response &&
                        <>
                            {form.response === 'Accepted' ?
                                <Alert variant="success">
                                    <Row>
                                        <Col>{form?.firstname} {form?.lastname} | Accepted | {form?.createdUtcTimestamp}</Col>
                                    </Row>
                                </Alert> :
                                form.response === 'Rejected' ?
                                    <Alert variant="danger">
                                        <Row>
                                            <Col>{form?.firstname} {form?.lastname} | Rejected | {form?.createdUtcTimestamp}</Col>
                                        </Row>
                                    </Alert> :
                                    (form.response === 'Created' && feature === 'carrier') ?
                                        <Alert variant="warning">
                                            <Row>
                                                <Col>{form?.billToFirstName} {form?.billToLastName} | Created | {form?.createdUtcTimestamp}</Col>
                                            </Row>
                                        </Alert> :
                                        (form.response === 'Created' && feature === 'driver') ?
                                            <Alert variant="warning">
                                                <Row>
                                                    <Col>{form?.requestorFirstName} {form?.requestorLastName} | Created | {form?.createdUtcTimestamp}</Col>
                                                </Row>
                                            </Alert> :
                                            form.response
                            }
                        </>
                    }
                </div>
                <Card className='my-3 p-2' style={{ borderRadius: 0 }}>
                    <div className='header'>
                        <Row style={{ backgroundColor: '#eee', padding: 0, margin: 0 }}>
                            <h2>{`${feature} Load Confirmation`.toUpperCase()}</h2>
                        </Row>
                        <Row>
                            <Col>
                                {(feature === 'carrier') &&
                                    <>
                                        <h4>Bill To</h4>
                                        <pre>
                                            <table>
                                                <tr>
                                                    <th>Name</th>
                                                    <td>{form?.company?.name}</td>
                                                </tr>
                                                <tr>
                                                    <th>Address</th>
                                                    <td>{form?.company?.address}</td>
                                                </tr>
                                            </table>
                                        </pre>
                                    </>
                                }

                            </Col>
                            <Col xs="auto" style={{ padding: '1em', margin: 0 }}>
                                <Image src={LOGO} style={{ maxHeight: '100px', maxWidth: '100%' }} />
                            </Col>
                        </Row>
                    </div>


                    {(feature === 'carrier') &&
                        <>
                            <pre>
                                <table>
                                    <tr>
                                        <th>POC</th>
                                        <td>{form?.carrier?.firstname} {form?.carrier?.lastname}</td>
                                    </tr>
                                    <tr>
                                        <th>Name</th>
                                        <td>{form?.carrier?.name}</td>
                                    </tr>
                                    <tr>
                                        <th>Address</th>
                                        <td>{form?.carrier?.address}</td>
                                    </tr>
                                    <tr>
                                        <th>MC</th>
                                        <td>{form?.carrier?.mcNumber}</td>
                                    </tr>
                                    <tr>
                                        <th>DOT</th>
                                        <td>{form?.carrier?.dotNumber}</td>
                                    </tr>
                                    <tr>
                                        <th>SCAC</th>
                                        <td>{form?.carrier?.scac}</td>
                                    </tr>
                                </table>
                            </pre>
                        </>
                    }

                    {/* Desktop Table View */}
                    <div className='d-none d-md-block'>
                        <Table bordered hover size='sm'>
                            <thead className="table-secondary">
                                <tr>
                                    <th className='small'>{feature.toUpperCase()}</th>
                                    <th className='small'>{feature.toUpperCase()} Phone</th>
                                    <th className='small'>Email</th>
                                    <th className='small'>Equipment</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <Row>
                                            <Col>
                                                <Form.Control
                                                    type='text'
                                                    size='sm'
                                                    name='firstname'
                                                    value={form?.firstname}
                                                    readOnly={true}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    type='text'
                                                    size='sm'
                                                    name='lastname'
                                                    value={form?.lastname}
                                                    readOnly={true}
                                                />
                                            </Col>
                                        </Row>
                                    </td>
                                    <td>
                                        <PhoneNumberInput
                                            textSize={'sm'}
                                            name='phone'
                                            value={form?.phone}
                                            readOnly={true}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type='text'
                                            size='sm'
                                            name='email'
                                            value={form?.email}
                                            readOnly={true}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type='text'
                                            size='sm'
                                            name='equipment'
                                            value={form?.equipment}
                                            readOnly={true}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                            <thead className="table-secondary">
                                <tr >
                                    <th className='small'>Shipment</th>
                                    <th className='small'>Date</th>
                                    <th className='small'>Miles</th>
                                    <th className='small'>Weight (lbs)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <Form.Control
                                            type='text'
                                            size='sm'
                                            name='orderNumber'
                                            value={form?.ShipmentId}
                                            readOnly={true}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type='date'
                                            size='sm'
                                            name='date'
                                            value={form?.date}
                                            readOnly={true}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type='number'
                                            size='sm'
                                            name='miles'
                                            value={form?.miles}
                                            readOnly={true}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type='number'
                                            size='sm'
                                            name='weight'
                                            value={form?.weight}
                                            readOnly={true}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        {form?.stops && form.stops.map((via, i) => (
                            <Table bordered hover size='sm' key={i} className={`small`} >
                                <thead className='table-secondary'>
                                    <tr>
                                        <th colSpan={3}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                                                <span>Stop {i + 1}</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={3}>
                                            <b>{via.name}</b> | <a target="_blank" href={`https://www.google.com/maps/search/${via.fullAddress}`}>{via.fullAddress}</a>
                                        </td>
                                    </tr>
                                </tbody>
                                <thead className='table-secondary' >
                                    <tr>
                                        <th>Arrival Date/Time</th>
                                        <th>Departure Date/Time</th>
                                        <th>Special Instructions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{via?.arrive}</td>
                                        <td>{via?.depart}</td>
                                        <td>{via?.specialInstructions}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        ))}
                        {/* {tables} */}
                    </div>

                    {/* Card View for mobile */}
                    <div className='d-md-none'>
                        <Col>
                            <Table bordered hover size='sm' style={{ border: '1px solid grey' }}>
                                <tbody className='small'>
                                    <tr>
                                        <th className="table-secondary">{feature.toUpperCase()}:</th>
                                        <td>
                                            <Form.Control type='text' size='sm'
                                                value={`${form?.firstname} ${form?.lastname}`}
                                                readOnly={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="table-secondary">{feature.toUpperCase()} Phone:</th>
                                        <td>
                                            <Form.Control
                                                value={`${form?.phone}`}
                                                readOnly={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="table-secondary">Equipment:</th>
                                        <td>
                                            <Form.Control type='text' size='sm'
                                                value={`${form?.equipment}`}
                                                readOnly={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="table-secondary">Shipment Id</th>
                                        <td>
                                            <Form.Control type='text' size='sm'
                                                value={`${form?.ShipmentId}`}
                                                readOnly={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="table-secondary">Date</th>
                                        <td>
                                            <Form.Control type='date' size='sm'
                                                value={form?.date}
                                                readOnly={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="table-secondary">Miles</th>
                                        <td>
                                            <Form.Control type='number' size='sm'
                                                value={form?.miles}
                                                readOnly={true}
                                            /></td>
                                    </tr>
                                    <tr>
                                        <th className="table-secondary">Weight</th>
                                        <td><Form.Control type='number' size='sm'
                                            value={form?.weight}
                                            readOnly={true}
                                            disabled={true} />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Table bordered hover size='sm' style={{ border: '1px solid grey' }}>
                                <tbody className='small'>
                                    {form?.stops && form.stops.map((via, i) => (
                                        <tr>
                                            <th className="table-secondary">
                                                <span>Stop {i + 1}</span>
                                            </th>
                                            <td>
                                                <p>{via?.name}<br /><a target="_blank" href={`https://www.google.com/maps/search/${via?.fullAddress}`}>{via?.fullAddress}</a></p>
                                                <p>ARRIVE : {via?.arrive}</p>
                                                <p>DEPART : {via?.depart}</p>
                                                <p>SPCL INSTRUCTIONS: {via?.specialInstructions}</p>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </div>

                    <div className='Terms my-2'>
                        <Table bordered hover size='sm'>
                            <thead className='table-secondary'>
                                <tr>
                                    <th>Terms:</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {form?.terms}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    {(feature === 'driver' && form?.response) &&
                        <>
                            <h2>
                                {form.response === 'Accepted' ?
                                    <Alert variant="success">
                                        <Row>
                                            <Col>Accepted<br />{form.firstname} {form.lastname}<br />{form?.createdUtcTimestamp}</Col>
                                            <Col xs="auto">
                                                <img
                                                    src={form.signatureDriver}
                                                    alt="Signature"
                                                    style={{ maxHeight: '100px', maxWidth: '100%' }}
                                                />
                                            </Col>
                                        </Row>
                                    </Alert> :
                                    form.response === 'Rejected' ?
                                        <Alert variant="danger">
                                            <Row>

                                                <Col>Rejected<br />{form.firstname} {form.lastname}<br />{form?.createdUtcTimestamp}</Col>
                                                <Col xs="auto">
                                                    <img
                                                        src={form.signatureDriver}
                                                        alt="Signature"
                                                        style={{ maxHeight: '100px', maxWidth: '100%' }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Alert> :
                                        form.response === 'Created' ?
                                            <Alert variant="warning">
                                                <Row>

                                                    <Col>Created<br />{form?.requestorFirstName} {form?.requestorLastName}<br />{form?.createdUtcTimestamp}</Col>
                                                    <Col xs="auto">
                                                        <img
                                                            src={form?.requestorSignature}
                                                            alt="Signature"
                                                            style={{ maxHeight: '100px', maxWidth: '100%' }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Alert> :
                                            form?.response}
                            </h2>
                        </>
                    }



                    {(feature === 'carrier') &&
                        <>
                            <h2>
                                {form.response === 'Accepted' ?
                                    <Alert variant="success">
                                        <Row>
                                            <Col>Accepted<br />{form.firstname} {form.lastname}<br />{form?.createdUtcTimestamp}</Col>
                                            <Col xs="auto">
                                                <img
                                                    src={form.signatureCarrier}
                                                    alt="Signature"
                                                    style={{ maxHeight: '100px', maxWidth: '100%' }}
                                                />
                                            </Col>
                                        </Row>
                                    </Alert> :
                                    form.response === 'Rejected' ?
                                        <Alert variant="danger">
                                            <Row>
                                                <Col>Rejected<br />{form.firstname} {form.lastname}<br />{form?.createdUtcTimestamp}</Col>
                                                <Col xs="auto">
                                                    <img
                                                        src={form.signatureCarrier}
                                                        alt="Signature"
                                                        style={{ maxHeight: '100px', maxWidth: '100%' }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Alert> :
                                        form.response === 'Created' ?
                                            <Alert variant="warning">
                                                <Row>
                                                    <Col>Created <br /> {form?.billToFirstName} {form?.billToLastName}<br />{form?.createdUtcTimestamp}</Col>
                                                    <Col xs="auto">
                                                        <img
                                                            src={form.signatureBillTo}
                                                            alt="Signature"
                                                            style={{ maxHeight: '100px', maxWidth: '100%' }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Alert> :
                                            form.response}
                            </h2>
                        </>
                    }


                </Card >
            </PrintPDF >
        </Container >
    );
}

export default LoadTenderLoadConfirmation;