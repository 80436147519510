import { Container, Card, Row, Col, Spinner, Form, Button, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, } from 'react'
import Api from "../../utils/Api";
import { useAuth } from '../../context/auth';
import AlertDisplay from "../../components/AlertDisplay";
import ConfirmationDangerModal from "../../components/ConfirmationDangerModal";
import { useNavigate, useParams } from 'react-router-dom';
import { FaTrash } from "react-icons/fa";
import { TIMEZONE_OPTIONS } from "../../context/appdata";

const initialSchedule = {
    time: '08:00',
    timeZone: 'ET',
    sunday: true,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true
}

function AlarmUpdate() {
    const { idToken } = useAuth();
    const { id } = useParams();
    const navigate = useNavigate();
    const [set, didSet] = useState();
    const [notification, setNotification] = useState({});
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [showModal, setShowModal] = useState();
    const [groupIds, setGroupIds] = useState([]);
    const [schedules, setSchedules] = useState([]);
    const [groups, setGroups] = useState();
    const [reports, setReports] = useState([]);
    const [report, setReport] = useState({});

    function handleGroupIds(id) {
        const exists = groupIds.includes(id);

        if (!exists) {
            setGroupIds(prevIds => [...prevIds, id]);
        } else {
            const updatedIds = groupIds.filter(groupId => groupId !== id);
            setGroupIds(updatedIds);
        }
    }

    function handleScheduleChange(val, i, key) {
        const updatedSchedules = schedules.map((schedule, index) => {
            if (index === i) {
                return { ...schedule, [key]: val };
            }
            return schedule;
        });
        setSchedules(updatedSchedules);
    }

    useEffect(() => {
        setNotification(prevForm => ({ ...prevForm, groupIds }));
    }, [groupIds]);

    useEffect(() => {
        setNotification(prevForm => ({ ...prevForm, schedules }));
    }, [schedules]);

    async function handleUpdate() {
        try {
            const updateNotification = { ...notification, report: report.name, callbackName: report.callbackName }
            await Api.patch(`/notification/${id}`, updateNotification, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Notification updated.' }])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured.' }])
        }
    }

    async function handleDeleteNotification() {
        try {
            await Api.delete(`/notification/${id}`, idToken)
            setShowModal(false)
            setAlerts([...alerts, { variant: 'success', message: 'Notification deleted.' }])
            navigate('/notification')
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured.' }])
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Api.get(`/notification/${id}`, idToken);
                setNotification(data);
                setSchedules(data.schedules);
                setGroupIds(data.groupIds);
                const reportData = await Api.get('/reports', idToken)
                setReports(reportData)
                const groupData = await Api.get('/access/groups', idToken)
                setGroups(groupData)
                const selectedReport = reportData.filter((rpt => rpt.name === data.report))
                selectedReport.length > 0 && setReport(selectedReport[0])
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    }, [set, id, idToken, alerts])



    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <ConfirmationDangerModal
                show={showModal}
                onHide={() => setShowModal(false)}
                onConfirm={() => handleDeleteNotification()}
                message={'Are you sure you want to delete this notification?'}
            />
            <Card style={{ borderRadius: 0, border: 0 }}>
                <Card.Header>
                    Alarm Update
                </Card.Header>
                {set ?
                    <>
                        <Card.Body>
                            <Row>
                                <Col>
                                    Select Report
                                    <Form.Control
                                        as="select"
                                        value={JSON.stringify(report)}
                                        onChange={(e) => setReport(JSON.parse(e.target.value))}>
                                        <option></option>
                                        {reports.map((rpt, i) => (
                                            <option key={i} value={JSON.stringify(rpt)}>{rpt.name} - {rpt.description}</option>
                                        ))}
                                    </Form.Control>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    Select Groups
                                    {groups.map((group, i) => (
                                        <Card key={i} style={{ marginBottom: '1em' }}>
                                            <Card.Header>
                                                <Row>
                                                    <Col xs="auto">
                                                        <Form.Check
                                                            checked={groupIds.includes(group._id)}
                                                            onClick={() => handleGroupIds(group._id)}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        {group.name} - {group.detail}
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    {group.members.map((mbr, j) => (
                                                        <Col xs="auto" key={j}>
                                                            {mbr}
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    ))}
                                </Col>
                            </Row>
                            {schedules.length > 0 ?
                                schedules.map((schedule, i) => (
                                    <Row key={i} style={{ alignItems: 'center', marginTop: '1em', paddingTop: '0.25em', paddingBottom: '0.25em' }}>
                                        <Col xs="auto">
                                            <Form.Control
                                                type="time"
                                                value={schedule.time}
                                                onChange={(e) => handleScheduleChange(e.target.value, i, 'time')}
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <Form.Select
                                                value={schedule.timeZone}
                                                onChange={(e) => handleScheduleChange(e.target.value, i, 'timeZone')}>
                                                <option></option>
                                                {TIMEZONE_OPTIONS.map((tz, i) => (
                                                    <option key={i} value={tz.code}>{tz.code}</option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                        <Col xs="auto">
                                            Sunday
                                            <Form.Check checked={schedule.sunday} onClick={(e) => handleScheduleChange(!schedule.sunday, i, 'sunday')} />
                                        </Col>
                                        <Col xs="auto">
                                            Monday
                                            <Form.Check checked={schedule.monday} onClick={(e) => handleScheduleChange(!schedule.monday, i, 'monday')} />
                                        </Col>
                                        <Col xs="auto">
                                            Tuesday
                                            <Form.Check checked={schedule.tuesday} onClick={(e) => handleScheduleChange(!schedule.tuesday, i, 'tuesday')} />
                                        </Col>
                                        <Col xs="auto">
                                            Wednesday
                                            <Form.Check checked={schedule.wednesday} onClick={(e) => handleScheduleChange(!schedule.wednesday, i, 'wednesday')} />
                                        </Col>
                                        <Col xs="auto">
                                            Thursday
                                            <Form.Check checked={schedule.thursday} onClick={(e) => handleScheduleChange(!schedule.thursday, i, 'thursday')} />
                                        </Col>
                                        <Col xs="auto">
                                            Friday
                                            <Form.Check checked={schedule.friday} onClick={(e) => handleScheduleChange(!schedule.friday, i, 'friday')} />
                                        </Col>
                                        <Col xs="auto">
                                            Saturday
                                            <Form.Check checked={schedule.saturday} onClick={(e) => handleScheduleChange(!schedule.saturday, i, 'saturday')} />
                                        </Col>
                                        <Col xs="auto">
                                            <Button variant="warning" onClick={() => setSchedules(schedules.filter((_, index) => index !== i))}><FaTrash /></Button>
                                        </Col>
                                    </Row>
                                ))
                                : 'No schedules.'}
                            <Row style={{ alignItems: 'center', marginTop: '1em', paddingTop: '0.25em', paddingBottom: '0.25em' }}>
                                <Col xs="auto">
                                    <Button variant="secondary" onClick={() => setSchedules([...schedules, initialSchedule])}>Schedule +</Button>
                                </Col>
                                <Col xs="auto">
                                    <Button variant="primary" onClick={() => handleUpdate()}>Update</Button>
                                </Col>
                                <Col xs="auto">
                                    <Button variant="danger" onClick={() => setShowModal(true)}>Delete</Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }
            </Card>
        </Container>
    );
}

export default AlarmUpdate;