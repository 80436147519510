import React, { createContext, useEffect, useState, useContext } from 'react';
import { useAuth } from './auth';
import Api from '../utils/Api';
import { Container, Alert, Spinner, Button} from 'react-bootstrap';
export const RoleContext = createContext();

export function useRole() {
  return useContext(RoleContext);
}

const RoleProvider = ({ children }) => {
  const { user, idToken } = useAuth();
  const [set, didSet] = useState();
  const [userRole, setUserRole] = useState(null);
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);

  function checkPermission(userRole) {
    for (const route in userRole.permissions) {
      const pattern = new RegExp('^' + route.replace(/:[^/]+/g, '[^/]+') + '$');
      if (pattern.test(window.location.pathname)) {
        const hasPermission = userRole.permissions[route];
        return hasPermission;
      }
    }

    return false;
  }

  function checkHref(userRole, href) {
    const permissions = userRole.permissions;
    if (permissions.hasOwnProperty(href)) {
      return permissions[href];
    }

    return false;
  }

  const checkNavPermissions = (userRole, navOptions) => {
    const filteredOptions = navOptions.filter((option) => {
      if(option?.href){
        const hasPermission = checkHref(userRole, option.href);
        return hasPermission;
      } else {
        return true;
      }
    });

    return filteredOptions;
  };

  const handleRefresh = async () => {
    window.location.reload();
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!user) {
          return;
        }
        
        const uid = user.uid;
        if (uid && idToken) {
          const profileResponse = await Api.get(`/access/profile/uid/${uid}`, idToken);
          
          if (profileResponse && profileResponse.role && profileResponse.role._id) {
            const roleResponse = await Api.get(`/access/role/${profileResponse.role._id}`, idToken);
            setUserRole(roleResponse);
          } else {
            throw new Error("Invalid profile response structure.");
          }
        }
      } catch (err) {
        if (err.message === 'Not found') {
          setInfo(`
            Your account is pending creation. 
            Accounts are created during regular business hours and you will be notified via email when your account is created.
            You may wait here or:
          `);
        } else {
          setError('Please try again in 5 minutes.');
        }
        setUserRole(null);
      }
      didSet(true);
    };
  
    if (!set) {
      fetchData();
    }
  
  }, [user, set, userRole, idToken]);
  

  if (user && !set) {
    return (
      <Container className='d-flex justify-content-center align-items-center' style={{ minHeight: '100vh' }}>
        <Spinner /> 
      </Container>
    )
  }

  if (user && error) {
    return (
      <Container className='d-flex justify-content-center align-items-center' style={{ minHeight: '100vh' }}>
        <Alert variant="warning" style={{ maxWidth: '400px' }}>
          {error} <Button variant="link" onClick={handleRefresh}> Refresh Screen</Button>
        </Alert>
      </Container>
    );
  }

  if (user && !userRole) {
    return (
      <Container className='d-flex justify-content-center align-items-center' style={{ minHeight: '100vh' }}>
        <Alert variant="primary" style={{ maxWidth: '400px' }}>
          {info} <Button variant="link" onClick={handleRefresh}> Refresh Screen</Button>
          <a href="/" >Return home</a>
        </Alert>
      </Container>
    );
  }

  if (user && userRole && !userRole.isActive) {
    return (
      <Container className='d-flex justify-content-center align-items-center' style={{ minHeight: '100vh' }}>
        <Alert variant='warning' style={{ maxWidth: '400px' }}>You role is inactive. Please contact your admin to activate your role.</Alert>
      </Container>
    );
  }

  if (user && userRole && userRole.isActive) {
    const hasPermission = checkPermission(userRole);
    if (!hasPermission) {
      return (
        <Container className='d-flex justify-content-center align-items-center' style={{ minHeight: '100vh' }}>
          <Alert variant='warning' style={{ maxWidth: '400px' }}> This feature is not available. <br />
            <a href="/" >Return home</a>
          </Alert>
        </Container>
      );
    }
  }

  return (
    <RoleContext.Provider value={{ userRole, checkNavPermissions }}>
      {children}
    </RoleContext.Provider>
  );
};


export default RoleProvider;
