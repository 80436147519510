import React, { useState } from 'react';
import { MapContainer, TileLayer, Circle, Polygon, useMapEvents, Marker, Popup, Tooltip } from 'react-leaflet';
import ReactDOMServer from 'react-dom/server';
import { DivIcon } from 'leaflet';
import { FaMapPin, FaRegTrashAlt, FaUndo, FaRedo, FaDrawPolygon } from 'react-icons/fa';
import { LuCircleDot } from 'react-icons/lu';
import { Button, InputGroup, Row, Col, Form, ButtonGroup, Card } from 'react-bootstrap';
import 'leaflet/dist/leaflet.css';

const GeofencePlotter = ({ fullAddress, geofenceState, circleGeofenceState, readOnly }) => {
  const [geofenceType, setGeofenceType] = useState('polygon');
  const [history, setHistory] = useState([]);
  const [future, setFuture] = useState([]);
  const [mapType, setMapType] = useState('street');
  // const [geofences, setGeofences] = useState([]);
  const { geofences, setGeofences } = geofenceState;
  // const [circleGeofence, setCircleGeofence] = useState(null);
  const { circleGeofence, setCircleGeofence } = circleGeofenceState;
  const [activeGeofence, setActiveGeofence] = useState(null);
  const [geofenceCount, setGeofenceCount] = useState(0);

  const geofenceColors = ['#ff0000', '#00ff00', '#0000ff', '#ffff00', '#ff00ff', '#00ffff', '#909090', '#000000', '#880000', '#008800'];
  const googleStreetUrl = "https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}";
  const googleHybridUrl = "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}";

  const MapEvents = () => {
    useMapEvents({
      click(e) {
        if (geofenceType === 'circle') {
          updateHistory();
          const newRadius = circleGeofence?.radius || 403;
          const newCenter = [e.latlng.lat, e.latlng.lng];
          setCircleGeofence({
            center: newCenter,
            radius: newRadius,
            color: '#ffa100'
          });
        } else if (activeGeofence !== null) {
          updateHistory();
          const updatedGeofences = [...geofences];
          const geofence = updatedGeofences[activeGeofence];

          if (geofence?.type === 'polygon') {
            updatedGeofences[activeGeofence] = {
              ...geofence,
              vertices: [...geofence?.vertices, e.latlng]
            };
            setGeofences(updatedGeofences);
          }
        }
      }
    });
    return null;
  };

  const addGeofence = () => {
    if (geofences.length < 10) {
      setGeofenceCount(geofenceCount + 1);
      const newGeofence = {
        id: Date.now(),
        name: `Geofence ${geofenceCount + 1}`,
        type: geofenceType,
        vertices: [],
        color: geofenceColors[geofenceCount % geofenceColors.length],
      };
      const newGeofences = [...geofences, newGeofence];
      setGeofences(newGeofences);
      setActiveGeofence(newGeofences.length - 1);
    }
  };

  const deleteGeofence = (index) => {
    setGeofences(geofences.filter((_, i) => i !== index));
    setActiveGeofence(null);
    updateHistory();
  };

  const toggleEditGeofence = (index) => {
    setActiveGeofence(activeGeofence === index ? null : index);
  };

  // circle functions

  const handleCircleLatChange = (e) => {
    const newCenter = [parseFloat(e.target.value), circleGeofence.center[1]];
    const updatedCircleGeofence = { ...circleGeofence, center: newCenter };
    setCircleGeofence(updatedCircleGeofence);
    updateHistory(updatedCircleGeofence);
  };

  const handleCircleLngChange = (e) => {
    const newCenter = [circleGeofence.center[0], parseFloat(e.target.value)];
    const updatedCircleGeofence = { ...circleGeofence, center: newCenter };
    setCircleGeofence(updatedCircleGeofence);
    updateHistory(updatedCircleGeofence);
  };

  const handleCircleRadiusChange = (e) => {
    const updatedCircleGeofence = { ...circleGeofence, radius: parseFloat(e.target.value) };
    setCircleGeofence(updatedCircleGeofence);
    updateHistory(updatedCircleGeofence);
  };

  //polygon functions
  const renderPolygonVertices = () => {
    return geofences?.flatMap((geofence, geofenceIndex) => {
      if (geofence?.type === 'polygon') {
        return geofence.vertices.map((vertex, vertexIndex) => (
          <Marker
            key={`${geofence.id}-${vertexIndex}`}
            position={vertex}
            icon={vertexIcon}
            draggable={activeGeofence === geofenceIndex}
            eventHandlers={{
              dragend: (event) => moveVertex(geofenceIndex, vertexIndex, event.target.getLatLng()),
            }}
          >
            {activeGeofence === geofenceIndex && (
              <Popup>
                <button onClick={(e) => {
                  e.stopPropagation();
                  deleteVertex(geofenceIndex, vertexIndex, e);
                }}>
                  Delete Vertex
                </button>
              </Popup>
            )}
          </Marker>
        ));
      }
      return [];
    });
  };

  const moveVertex = (geofenceIndex, vertexIndex, newLatLng) => {
    if (activeGeofence !== geofenceIndex) return;

    updateHistory([...geofences]);
    const updatedGeofences = geofences.map((geofence, index) => {
      if (index === geofenceIndex) {
        const newVertices = geofence.vertices.map((vertex, vIndex) =>
          vIndex === vertexIndex ? newLatLng : vertex
        );
        return { ...geofence, vertices: newVertices };
      }
      return geofence;
    });
    setGeofences(updatedGeofences);
  };

  const deleteVertex = (geofenceIndex, vertexIndex, event) => {
    if (activeGeofence !== geofenceIndex) return;

    event.stopPropagation();
    updateHistory([...geofences]);
    const updatedGeofences = geofences.map((geofence, index) => {
      if (index === geofenceIndex) {
        const newVertices = geofence.vertices.filter((_, vIndex) => vIndex !== vertexIndex);
        return { ...geofence, vertices: newVertices };
      }
      return geofence;
    });
    setGeofences(updatedGeofences);
  };

  const vertexIcon = new DivIcon({
    html: ReactDOMServer.renderToString(<FaMapPin style={{ fontSize: '24px', color: 'red' }} />),
    className: '',
    iconSize: [24, 24],
    iconAnchor: [12, 24],
  });

  //general functions
  const toggleGeofenceType = () => {
    setGeofenceType(geofenceType === 'circle' ? 'polygon' : 'circle');
  };

  const updateHistory = () => {
    setHistory(prevHistory => [...prevHistory, { geofences, circleGeofence }]);
    setFuture([]);
    // console.log(geofences)
  };

  const undoLastAction = () => {
    if (history.length > 0) {
      const lastState = history.pop();
      setFuture([{ geofences, circleGeofence }, ...future]);
      setGeofences(lastState.geofences);
      setCircleGeofence(lastState.circleGeofence);
    }
  };

  const redoLastAction = () => {
    if (future.length > 0) {
      const nextState = future.shift();
      setHistory([...history, { geofences, circleGeofence }]);
      setGeofences(nextState.geofences);
      setCircleGeofence(nextState.circleGeofence);;
    }
  };

  const clearMap = () => {
    updateHistory();
    setGeofences([]);
    setCircleGeofence(null);
    setActiveGeofence(null);
    setGeofenceCount(0);
  };

  return (
    <Card style={{ borderRadius: '0' }}>
      <MapContainer
        center={[38, -98.5795]}
        zoom={5}
        doubleClickZoom={false}
        zoomControl={false}
        style={{ height: '600px', width: '100%' }}
      >
        <TileLayer
          attribution='Google Maps'
          url={mapType === 'street' ? googleStreetUrl : googleHybridUrl}
        />

        <MapEvents />

        {(circleGeofence && (Object.keys(circleGeofence).length > 0)) && (
          <Circle
            center={circleGeofence?.center}
            radius={circleGeofence?.radius || 1}
            color={circleGeofence?.color}
          />
        )}

        {geofences.map((geofence) => (
          geofence?.type === 'polygon' && geofence?.vertices?.length >= 3 ?
            <Polygon key={geofence?.id} positions={geofence?.vertices} color={geofence?.color}>
              <Tooltip
                direction="center"
                offset={[0, 0]}
                opacity={0.9}
                permanent={true}
              >{geofence?.name}</Tooltip>
            </Polygon> :
            geofence?.type === 'polygon' && geofence?.vertices.length >= 3 ?
              <Polygon key={geofence?.id} positions={geofence?.vertices} color={geofence?.color}>
                <Tooltip
                  direction="center"
                  offset={[0, 0]}
                  opacity={0.9}
                  permanent={true}
                >{geofence?.name}</Tooltip>
              </Polygon> :
              null
        ))}

        {renderPolygonVertices()}
      </MapContainer>
      {!readOnly &&
        <>
          <Card.Body>
            <ButtonGroup horizontal="true" className=''>
              <Button variant='primary' className='me-1' onClick={toggleGeofenceType}>
                {/* {geofenceType === 'circle' ? 'Switch to Polygon' : 'Switch to Circle'} */}
                {/* Toggle Geofence */}
                {geofenceType === 'circle' ? <><FaDrawPolygon /> GeoFence</> : <><LuCircleDot /> Radius</>}
              </Button>
              <Button variant='primary' className='me-1' onClick={clearMap}>
                <FaRegTrashAlt />
              </Button>
              <Button variant='primary' className='me-1' onClick={undoLastAction} disabled={history.length === 0}>
                <FaUndo />
              </Button>
              <Button variant='primary' className='me-1' onClick={redoLastAction} disabled={future.length === 0}>
                <FaRedo />
              </Button>
              <Button variant='primary' className='me-1' onClick={() => setMapType(currentMapType => currentMapType === 'street' ? 'hybrid' : 'street')}>
                {/* {mapType === 'street' ? 'Switch to Hybrid Map' : 'Switch to Street Map'} */}
                {/* Toggle Map View */}
                {mapType === 'street' ? <> Hybrid Map</> : <> Street Map</>}
              </Button>
            </ButtonGroup>

            {geofenceType === 'circle' && circleGeofence && (
              <Row className='my-2'>
                <Col md={4} xs={12}>
                  <InputGroup className="mb-2 mb-md-0">
                    <InputGroup.Text id="basic-addon1">Latitude</InputGroup.Text>
                    <Form.Control
                      placeholder="Latitude"
                      aria-label="Latitude"
                      aria-describedby="basic-addon1"
                      type="number"
                      value={circleGeofence?.center?.[0] || ''}
                      onChange={handleCircleLatChange}
                      disabled={!circleGeofence}
                    />
                  </InputGroup>
                </Col>
                <Col md={4} xs={12}>
                  <InputGroup className="mb-2 mb-md-0">
                    <InputGroup.Text id="basic-addon2">Longitude</InputGroup.Text>
                    <Form.Control
                      placeholder="Longitude"
                      aria-label="Longitude"
                      aria-describedby="basic-addon2"
                      type="number"
                      value={circleGeofence?.center?.[1] || ''}
                      onChange={handleCircleLngChange}
                      disabled={!circleGeofence}
                    />
                  </InputGroup>
                </Col>
                <Col md={4} xs={12}>
                  <InputGroup className="mb-2 mb-md-0">
                    <InputGroup.Text id="basic-addon3">Radius</InputGroup.Text>
                    <Form.Control
                      placeholder="Radius (meters)"
                      aria-label="Radius (meters)"
                      aria-describedby="basic-addon3"
                      type="number"
                      value={circleGeofence?.radius || 1}
                      onChange={handleCircleRadiusChange}
                      disabled={!circleGeofence}
                    />
                  </InputGroup>
                </Col>
              </Row>
            )}

            {geofenceType === 'polygon' && (
              <>
                {geofences.map((geofence, index) => (
                  geofence?.type === 'polygon' ? (
                    <div key={geofence?.id} style={{ display: 'flex' }} className='my-2'>
                      <div style={{
                        width: '20px',
                        backgroundColor: geofence?.color,
                        alignSelf: 'stretch',
                      }}>
                      </div>
                      <InputGroup>
                        <InputGroup.Text id={`basic-addon${index}`}>Name</InputGroup.Text>
                        <Form.Control
                          placeholder="Name"
                          aria-label="Name"
                          aria-describedby={`basic-addon${index}`}
                          type="text"
                          value={geofence?.name || ''}
                          className='me-2'
                          onChange={(e) => {
                            const updatedGeofences = [...geofences];
                            updatedGeofences[activeGeofence].name = e.target.value;
                            setGeofences(updatedGeofences);
                          }}
                          disabled={index !== activeGeofence}
                        />
                      </InputGroup>
                      <Button onClick={() => toggleEditGeofence(index)} className='me-2'>
                        {activeGeofence === index ? 'Done' : 'Edit'}
                      </Button>
                      <Button onClick={() => deleteGeofence(index)}>
                        Delete
                      </Button>
                    </div>
                  ) : null
                ))}
                <Row className='mt-2'>
                  <Col>
                    {geofences.length < 10 && (
                      <Button onClick={addGeofence}>Add Geofence</Button>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </Card.Body>
        </>
      }
    </Card>
  );
};

export default GeofencePlotter;