import { Container, Card, Spinner, Row, Col, Badge, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import React, { useState, useEffect } from 'react'
import { FaPlusCircle, } from 'react-icons/fa'
import { useAuth } from '../../context/auth';
import { Link } from "react-router-dom";
import AlertDisplay from "../../components/AlertDisplay";
import DataDisplayV3 from "../../components/DataDisplayV3";
import DataDisplay from "../../components/DataDisplay";
import VirtualizedTable from "../../components/VirtualizeTable";

function Location({ feature }) {
    const { idToken } = useAuth();
    const [set1, didSet1] = useState();
    const [set2, didSet2] = useState();
    const [set3, didSet3] = useState();

    const [toggle, setToggle] = useState(() => {
        const savedToggle = localStorage.getItem('@sybrtms-locationview-toggle');
        return savedToggle !== null ? JSON.parse(savedToggle) : true;
    });

    const [showUSPSLocationView, setShowUSPSLocationView] = useState(() => {
        return localStorage.getItem('@sybrtms-showUSPSLocationView') || 'Used';
    });

    useEffect(() => {
        localStorage.setItem('@sybrtms-locationview-toggle', JSON.stringify(toggle));
    }, [toggle]);

    useEffect(() => {
        localStorage.setItem('@sybrtms-showUSPSLocationView', showUSPSLocationView);
    }, [showUSPSLocationView]);

    const [locations, setLocations] = useState([]);
    const [uspsLocations, setUSPSLocations] = useState([]);
    const [loadtenderLocationNasscodes, setLoadTenderLocationNasscodes] = useState([]);
    const [nasscodesExist, setNasscodesExist] = useState([]);
    const [nasscodesDoNotExist, setNasscodesDoNotExist] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const [query, setQuery] = useState({
        isActive: true
    });



    useEffect(() => {
        const fetchData = async () => {
            try {
                const queryParams = new URLSearchParams(query);
                const data = await Api.query('/locations', queryParams, idToken);
                if (!data || !Array.isArray(data)) {
                    throw new Error("Received invalid data from server.");
                }
                const cleanLocations = data.map(location => {

                    const address = location?.fullAddress || `${location.address1} ${location.address2} ${location.city} ${location.state} ${location.zipcode}`
                    return {
                        _id: location._id,
                        'Name': location.name,
                        'Nasscode': location.nasscode,
                        'Type': location.type,
                        'Address': address,
                        'Provider': location.provider,
                        'Active': location.isActive ? '✅' : '❌',
                    }
                })

                setLocations(cleanLocations)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Error fetching locations' }]);
            } finally {
                didSet1(true)
            }
        }

        const fetchUSPSData = async () => {
            try {
                const data = await Api.get('/usps/servicepoints', idToken);
                if (!data || !Array.isArray(data)) {
                    throw new Error("Received invalid data from server.");
                }
                setUSPSLocations(data)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Error fetching USPS service points' }]);
            } finally {
                didSet2(true)
            }
        }

        const fetchLoadTenderNasscodes = async () => {
            try {
                const data = await Api.get('/loadtenders/usps-loadtenders', idToken);
                const filterUspsLocations = uspsLocations.filter(location => data.includes(location['Location ID']))
                setLoadTenderLocationNasscodes(filterUspsLocations)
            } catch {
                setAlerts([...alerts, { variant: 'warning', message: 'Error fetching USPS Load Tender service points' }]);
            } finally {
                didSet3(true)
            }
        }

        if (!set1) {
            fetchData()
        }

        if (!set2) {
            fetchUSPSData()
        }

        if (!set3) {
            fetchLoadTenderNasscodes()
        }
    }, [set1, set2, set3, idToken, alerts, query])


    function filterForExistingUSPSNasscodes() {
        if (locations.length > 0 && uspsLocations.length > 0 && loadtenderLocationNasscodes.length > 0) {
            const ltNasscodeArray = loadtenderLocationNasscodes.reduce((a, b) => {
                a.push(b['Location ID']);
                return a;
            }, []);
            const existingLocations = locations.filter(location => ltNasscodeArray.includes(location['Nasscode']))
            const existingLocationsArray = existingLocations.reduce((a, b) => {
                a.push(b['Nasscode']);
                return a;
            }, []);

            const existingRecords = uspsLocations.filter(location => existingLocationsArray.includes(location['Location ID']));
            const doesNotExist = ltNasscodeArray.filter(nasscode => !existingLocationsArray.includes(nasscode))
            const doesNotExistRecords = uspsLocations.filter(location => doesNotExist.includes(location['Location ID'])) //.filter(location => existingLocations.includes(location['Location ID']))
            setNasscodesExist(existingRecords)
            setNasscodesDoNotExist(doesNotExistRecords)
            console.log('Filter for existsing USPS locations')
            console.log('Existing', existingLocations.length)
            console.log('Existing', existingLocationsArray)
            console.log('Existing Records:', existingRecords)
            console.log('Does Not Exist', doesNotExist.length)
            console.log('Does Not Exist Records:', doesNotExistRecords)
        }

    }

    useEffect(() => {
        filterForExistingUSPSNasscodes()
    }, [locations, uspsLocations, loadtenderLocationNasscodes])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <Card style={{ borderRadius: 0, border: 0 }}>
                <Card.Header>
                    <Card.Title>
                        <Row>
                            <Col>
                                <Card.Title>
                                    <Row>
                                        <Col xs="auto">
                                            <Badge
                                                bg={'success'}
                                                onClick={() => setToggle(true)}
                                            >
                                                {'TMS'}
                                            </Badge>
                                        </Col>
                                        <Col xs="auto">
                                            <Badge
                                                bg={'primary'}
                                                onClick={() => setToggle(false)}
                                            >
                                                {'USPS'}
                                            </Badge>
                                        </Col>
                                    </Row>
                                </Card.Title>
                            </Col>
                            {toggle &&
                                <>
                                    <Col style={{ textAlign: 'center' }}>
                                        <Badge
                                            bg={query.isActive ? 'success' : 'danger'}
                                            onClick={() => {
                                                setQuery({ ...query, isActive: !query.isActive });
                                                didSet1(false);
                                                didSet2(false);
                                                didSet3(false);
                                            }}
                                        >
                                            {query.isActive ? 'Active' : 'Inactive'}
                                        </Badge>
                                    </Col>
                                    <Col>
                                        <Link className="float-end" to={`/${feature}/location-form`}><FaPlusCircle /> Location</Link>
                                    </Col>
                                </>
                            }
                        </Row>
                    </Card.Title>
                </Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={{ alerts, setAlerts }} />
                }
                {(set1 && set2 && set3) ?
                    (
                        toggle &&
                        <Card.Body>
                            {locations.length > 0 ?
                                <>
                                    <DataDisplayV3
                                        dataSource={locations}
                                        lsKey={'@mctms-locations-display'}
                                        urlPath={`/${feature}/location/`}
                                        urlKey={'_id'}
                                        popKeys={['_id']}
                                    />
                                </>
                                :
                                <>
                                    No data.
                                </>
                            }
                        </Card.Body>
                    )
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

                {(set2) &&
                    <Card.Body>
                        <Row>
                            <Col xs="auto">
                                <Badge
                                    onClick={() => setShowUSPSLocationView('All')}>
                                    All
                                </Badge>
                            </Col>
                            <Col xs="auto">
                                <Badge
                                    onClick={() => setShowUSPSLocationView('Used')}>
                                    Used
                                </Badge>
                            </Col>
                            <Col xs="auto">
                                <Badge
                                    bg="success"
                                    onClick={() => setShowUSPSLocationView('Exists')}>
                                    Exists
                                </Badge>
                            </Col>
                            <Col xs="auto">
                                <Badge
                                    bg="warning"
                                    onClick={() => setShowUSPSLocationView('DNE')}>
                                    Does Not Exist
                                </Badge>
                            </Col>
                        </Row>

                        {showUSPSLocationView === 'All' &&
                            <VirtualizedTable
                                uspsLocations={uspsLocations}
                            />

                        }
                        {showUSPSLocationView === 'Used' &&
                            <VirtualizedTable
                                uspsLocations={loadtenderLocationNasscodes}
                            />
                        }
                        {showUSPSLocationView === 'Exists' &&
                            <VirtualizedTable
                                uspsLocations={nasscodesExist}
                            />
                        }
                        {showUSPSLocationView === 'DNE' &&
                            <VirtualizedTable
                                uspsLocations={nasscodesDoNotExist}
                            />
                        }
                    </Card.Body>

                }
            </Card>
        </Container>
    );
}

export default Location;