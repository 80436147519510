import React, { useEffect, useState } from 'react';
import { Container, Card, Button, Spinner, Alert, Table, Form, Row, Col, Accordion } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';
import { LICENSE_TYPES, LOCATION_CATEGORIES, VEHICLE_CATEGORIES } from "../../context/appdata";
function Samsara() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [syncdata, setSyncData] = useState();
    const [alerts, setAlerts] = useState([]);
    const [assignments, setAssignments] = useState();
    const [domiciles, setDomiciles] = useState();

    // todo : refactor these functions 🤣
    function setSamsaraLocationType(index, value) {
        const samsaraLocation = syncdata.locations.data[index];
        samsaraLocation['type'] = value;
    }

    function setSamsaraOperatorAssignment(index, value) {
        const samsaraOperator = syncdata.operators.data[index];
        samsaraOperator['assignment'] = value;
    }

    function setSamsaraOperatorDomicile(index, value) {
        const samsaraOperator = syncdata.operators.data[index];
        samsaraOperator['domicile'] = value;
    }

    function setSamsaraOperatorLicenseType(index, value) {
        const samsaraOperator = syncdata.operators.data[index];
        samsaraOperator['licensetype'] = value;
    }


    function setSamsaraVehicleAssignment(index, value) {
        const samsaraVehicle = syncdata.vehicles.data[index];
        samsaraVehicle['assignment'] = value;
    }

    function setSamsaraVehicleDomicile(index, value) {
        const samsaraVehicle = syncdata.vehicles.data[index];
        samsaraVehicle['domicile'] = value;
    }

    function setSamsaraVehicleLicenseRequired(index, value) {
        const samsaraVehicle = syncdata.vehicles.data[index];
        samsaraVehicle['licenserequired'] = value;
    }

    function setSamsaraVehicleUnitType(index, value) {
        const samsaraVehicle = syncdata.vehicles.data[index];
        samsaraVehicle['unittype'] = value;
    }


    function setSamsaraTrailerAssignment(index, value) {
        const samsaraTrailer = syncdata.trailers.data[index];
        samsaraTrailer['assignment'] = value;
    }

    function setSamsaraTrailerDomicile(index, value) {
        const samsaraTrailer = syncdata.trailers.data[index];
        samsaraTrailer['domicile'] = value;
    }

    function setSamsaraTrailerLength(index, value) {
        const samsaraTrailer = syncdata.trailers.data[index];
        samsaraTrailer['length'] = value;
    }


    async function syncSamsaraLocation(index) {
        try {
            const samsaraLocation = syncdata.locations.data[index];
            const location = {
                name: samsaraLocation.name,
                type: samsaraLocation.type,
                provider: samsaraLocation.provider,
                providerId: samsaraLocation.providerId,
                address1: samsaraLocation.address1,
                address2: '',
                city: samsaraLocation.city,
                state: samsaraLocation.state,
                zipcode: samsaraLocation.zipcode,
                latitude: '',
                longitude: '',
                geofence: samsaraLocation.geofence,
            }
            console.log(location)
            await Api.post('/locations/provider', location, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success', }])
            didSet(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error creating location' }])
        }
    }

    async function syncSamsaraDriver(index) {
        try {
            const samsaraDriver = syncdata.operators.data[index];
            const operator = {
                firstname: samsaraDriver.firstname,
                lastname: samsaraDriver.lastname,
                provider: samsaraDriver.provider,
                providerId: samsaraDriver.providerId,
                phone: samsaraDriver.phone,
                licenseNumber: samsaraDriver.licenseNumber,
                licenseState: samsaraDriver.licenseState,
                licensetype: samsaraDriver.licensetype,
                assignment: samsaraDriver.assignment,
                domicile: samsaraDriver.domicile,
            }
            console.log(samsaraDriver)
            await Api.post('/operators/provider', operator, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success', }])
            didSet(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error creating driver' }])
        }
    }

    async function syncSamsaraVehicle(index) {
        try {
            const samsaraVehicle = syncdata.vehicles.data[index];
            const vehicle = {
                provider: samsaraVehicle.provider,
                providerId: samsaraVehicle.providerId,
                providerData: samsaraVehicle.providerData,
                assignment: samsaraVehicle.assignment,
                domicile: samsaraVehicle.domicile,
                unit: samsaraVehicle.data.name,
                vin: samsaraVehicle.data.vin,
            }
            await Api.post('/vehicles/provider', vehicle, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success', }])
            didSet(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error creating vehicle. '+JSON.stringify(error.error)  }])
        }
    }

    async function syncSamsaraTrailer(index) {
        try {
            const samsaraTrailer = syncdata.trailers.data[index];
            const trailer = {
                provider: samsaraTrailer.provider,
                providerId: samsaraTrailer.providerId,
                providerData: samsaraTrailer.data,
                unit: samsaraTrailer.data.name,
                length : samsaraTrailer.length,
                assignment: samsaraTrailer.assignment,
                domicile: samsaraTrailer.domicile,
            }
            await Api.post('/trailers/provider', trailer, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success', }])
            didSet(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error creating trailer. '+JSON.stringify(error.error) }])
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Api.get('/samsara/sync/preview', idToken);
                setSyncData(data);
                const a = await Api.get('/assignments/live', idToken);
                setAssignments(a);
                const d = await Api.get('/locations/live/Domicile', idToken);
                setDomiciles(d);
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error', }])
            }

            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    }, [set, idToken, alerts,])

    return (
        <Container fluid style={{margin:0, padding:0}}>
            <Card style={{border:0,borderRadius:0}}>
                <Card.Header>
                    <Card.Title>
                        Samsara ELD
                        {/* <Button style={{ float: 'right' }} variant="link" onClick={() => sync()}>Sync Data</Button> */}
                    </Card.Title>
                </Card.Header>
                {alerts.length > 0 &&
                    alerts.map((alrt, i) => (
                        <Alert key={i} variant={alrt.variant} dismissible onClick={() => setAlerts(alerts.filter(index => index !== i))}>{alrt.message}</Alert>
                    ))
                }
                {set ?
                    <>
                        <Accordion>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Location SYNC</Accordion.Header>
                                <Accordion.Body>
                                    <Card.Body>
                                        Samara : {syncdata?.locations['Samsara Ct']}<br />
                                        MCTMS :{syncdata?.locations['MCTMS Ct']}<br />
                                        Match : {syncdata?.locations['Matching Ct']}<br />
                                        Download :{syncdata?.locations['Download Ct']}<br />
                                    </Card.Body>
                                    {syncdata?.locations?.data ?
                                        syncdata?.locations?.data.map((loc, i) => (
                                            <Card key={i} style={{ margin: '1em' }}>
                                                <Card.Header>
                                                    {loc.name}
                                                </Card.Header>
                                                <Card.Body>
                                                    <Row>
                                                        <Col sm={8} md={6}>
                                                            Type:
                                                            <Form.Select onChange={(e) => setSamsaraLocationType(i, e.target.value)}>
                                                                <option>Select</option>
                                                                {LOCATION_CATEGORIES.map((category, i) => (
                                                                    <option key={i} value={category.name}>{category.name} - {category.detail}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </Col>
                                                        <Col sm={4} md={6}>
                                                            <br />
                                                            <Button variant="primary" onClick={() => syncSamsaraLocation(i)}>+ Add Location</Button>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12} lg={4}>
                                                            Name <Form.Control value={loc.name} readOnly disabled />
                                                        </Col>
                                                        <Col sm={12} lg={8}>
                                                            Address <Form.Control value={`${loc.address1} ${loc.address2} ${loc.city} ${loc.state} ${loc.zipcode}`} readOnly disabled />
                                                        </Col>
                                                        <Col sm={12}>
                                                            Geofence  <pre>{JSON.stringify(loc.geofence, "", 2)}</pre>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        ))
                                        :
                                        <tr>
                                            <td>No Data</td>
                                        </tr>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Driver SYNC</Accordion.Header>
                                <Accordion.Body>
                                    <Card.Body>
                                        Samara : {syncdata?.operators['Samsara Ct']}<br />
                                        MCTMS :{syncdata?.operators['MCTMS Ct']}<br />
                                        Match : {syncdata?.operators['Matching Ct']}<br />
                                        Download :{syncdata?.operators['Download Ct']}<br />
                                    </Card.Body>

                                    {syncdata?.operators?.data ?
                                        syncdata?.operators?.data.map((driver, i) => (
                                            <Card key={i} style={{ margin: '1em' }}>
                                                <Card.Header>
                                                    {driver.firstname} {driver.lastname}
                                                </Card.Header>
                                                <Card.Body>
                                                    <Row>
                                                        <Col sm={12} md={5} lg={4}>
                                                            Assignment :
                                                            <Form.Select onChange={(e) => setSamsaraOperatorAssignment(i, JSON.parse(e.target.value))}>
                                                                <option>Select</option>
                                                                {assignments && assignments.map((assignment, i) => (
                                                                    <option key={i} value={JSON.stringify(assignment)}>{assignment.name}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </Col>
                                                        <Col sm={12} md={5} lg={4}>
                                                            Domicile :
                                                            <Form.Select onChange={(e) => setSamsaraOperatorDomicile(i, JSON.parse(e.target.value))}>
                                                                <option>Select</option>
                                                                {domiciles && domiciles.map((domicile, i) => (
                                                                    <option key={i} value={JSON.stringify(domicile)}>{domicile.name}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </Col>
                                                        <Col sm={12} md={5} lg={4}>
                                                            License Type :
                                                            <Form.Select onChange={(e) => setSamsaraOperatorLicenseType(i, e.target.value)}>
                                                                <option>Select</option>
                                                                {LICENSE_TYPES.map((code, i) => (
                                                                    <option key={i} value={code.name}>{code.name}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </Col>
                                                        <Col sm={12} md={2} lg={4}>
                                                            <br />
                                                            <Button variant="primary" onClick={() => syncSamsaraDriver(i)}>+ Add Driver</Button>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12} md={6} lg={2}>
                                                            First Name <Form.Control type="text" value={driver.firstname} readOnly disabled />
                                                        </Col>
                                                        <Col sm={12} md={6} lg={2}>
                                                            Last Name <Form.Control type="text" value={driver.lastname} readOnly disabled />
                                                        </Col>
                                                        <Col sm={12} md={6} lg={2}>
                                                            License <Form.Control type="text" value={driver.licenseNumber} readOnly disabled />
                                                        </Col>
                                                        <Col sm={12} md={6} lg={2}>
                                                            State <Form.Control type="text" value={driver.licenseState} readOnly disabled />
                                                        </Col>
                                                        <Col sm={12} md={6} lg={2}>
                                                            Phone <Form.Control type="text" value={driver.phone} readOnly disabled />
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        ))
                                        :
                                        <tr>
                                            <td>No Data</td>
                                        </tr>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Vehicle SYNC</Accordion.Header>
                                <Accordion.Body>
                                    <Card.Body>
                                        Samara : {syncdata?.vehicles['Samsara Ct']}<br />
                                        MCTMS :{syncdata?.vehicles['MCTMS Ct']}<br />
                                        Match : {syncdata?.vehicles['Matching Ct']}<br />
                                        Download :{syncdata?.vehicles['Download Ct']}<br />
                                    </Card.Body>
                                    {syncdata?.vehicles?.data ?
                                        syncdata?.vehicles?.data.map((vehicle, i) => (
                                            <Card key={i} style={{ margin: '1em' }}>
                                                <Card.Header>
                                                    {vehicle.data.name}
                                                </Card.Header>
                                                <Card.Body>
                                                    <Row>
                                                        <Col sm={12} md={6} lg={4}>
                                                            Unit <Form.Control type="text" value={vehicle.data.name} readOnly disabled />
                                                        </Col>
                                                        <Col sm={12} md={6} lg={4}>
                                                            VIN <Form.Control type="text" value={vehicle.data.vin} readOnly disabled />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12} md={4}>
                                                            Assignment :
                                                            <Form.Select onChange={(e) => setSamsaraVehicleAssignment(i, JSON.parse(e.target.value))}>
                                                                <option>Select</option>
                                                                {assignments && assignments.map((assignment, i) => (
                                                                    <option key={i} value={JSON.stringify(assignment)}>{assignment.name}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </Col>
                                                        <Col sm={12} md={4}>
                                                            Domicile :
                                                            <Form.Select onChange={(e) => setSamsaraVehicleDomicile(i, JSON.parse(e.target.value))}>
                                                                <option>Select</option>
                                                                {domiciles && domiciles.map((domicile, i) => (
                                                                    <option key={i} value={JSON.stringify(domicile)}>{domicile.name}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12} md={4}>
                                                            Unit Type :
                                                            <Form.Select onChange={(e) => setSamsaraVehicleUnitType(i, e.target.value)}>
                                                                <option>Select</option>
                                                                {VEHICLE_CATEGORIES.map((code, i) => (
                                                                    <option key={i} value={code.name}>{code.name}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </Col>
                                                        <Col sm={12} md={4} >
                                                            License Required :
                                                            <Form.Select onChange={(e) => setSamsaraVehicleLicenseRequired(i, e.target.value)}>
                                                                <option>Select</option>
                                                                {LICENSE_TYPES.map((code, i) => (
                                                                    <option key={i} value={code.name}>{code.name}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </Col>
                                                        <Col sm={12} md={2} lg={4}>
                                                            <br />
                                                            <Button variant="primary" onClick={() => syncSamsaraVehicle(i)}>+ Add Vehicle</Button>
                                                        </Col>
                                                    </Row>
                                                    <Row>

                                                        <Col>
                                                            <pre>
                                                                {JSON.stringify(vehicle, "", 2)}
                                                            </pre>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        ))
                                        :
                                        <tr>
                                            <td>No Data</td>
                                        </tr>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Trailer SYNC</Accordion.Header>
                                <Accordion.Body>
                                    <Card.Body>
                                        Samara : {syncdata?.trailers['Samsara Ct']}<br />
                                        MCTMS :{syncdata?.trailers['MCTMS Ct']}<br />
                                        Match : {syncdata?.trailers['Matching Ct']}<br />
                                        Download :{syncdata?.trailers['Download Ct']}<br />
                                    </Card.Body>
                                    {syncdata?.trailers?.data ?
                                        syncdata?.trailers?.data.map((trailer, i) => (
                                            <Card key={i} style={{ margin: '1em' }}>
                                                <Card.Header>
                                                    {trailer.data.name}
                                                </Card.Header>
                                                <Card.Body>
                                                    <Row>
                                                        <Col sm={12} md={6} lg={3}>
                                                            Unit <Form.Control type="text" value={trailer.data.name} readOnly disabled />
                                                        </Col>
                                                        <Col sm={12} md={6} lg={3}>
                                                            Length <Form.Control type="text" onChange={(e) => setSamsaraTrailerLength(i, e.target.value)}/>
                                                        </Col>
                                                        <Col sm={12} md={6} lg={3}>
                                                            Assignment :
                                                            <Form.Select onChange={(e) => setSamsaraTrailerAssignment(i, JSON.parse(e.target.value))}>
                                                                <option>Select</option>
                                                                {assignments && assignments.map((assignment, i) => (
                                                                    <option key={i} value={JSON.stringify(assignment)}>{assignment.name}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </Col>
                                                        <Col sm={12} md={6} lg={3}>
                                                            Domicile :
                                                            <Form.Select onChange={(e) => setSamsaraTrailerDomicile(i, JSON.parse(e.target.value))}>
                                                                <option>Select</option>
                                                                {domiciles && domiciles.map((domicile, i) => (
                                                                    <option key={i} value={JSON.stringify(domicile)}>{domicile.name}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </Col>
                                                        <Col sm={12} md={6} lg={3}>
                                                            <br />
                                                            <Button variant="primary" onClick={() => syncSamsaraTrailer(i)}>+ Add Trailer</Button>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <pre>
                                                                {JSON.stringify(trailer, "", 2)}
                                                            </pre>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        ))
                                        :
                                        <tr>
                                            <td>No Data</td>
                                        </tr>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </>
                    :
                    <Spinner />
                }
                {/* 
                    <pre>
                        {JSON.stringify(syncdata, "", 2)}
                    </pre> 
                */}
            </Card>
        </Container >
    );
}

export default Samsara;