import { Container, Card, Row, Col, Form, Button, Alert } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';
import { LICENSE_TYPES } from "../../context/appdata";
import AlertDisplay from "../../components/AlertDisplay";

const initalFormState = {
    operatortype: 'driver',
    firstname: '',
    lastname: '',
    licenseclass: '',
    assignment: {},
    domicile: {},
}

function OperatorForm() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [formdata, setFormdata] = useState(initalFormState)
    const [assignments, setAssignments] = useState([]);
    const [domiciles, setDomiciles] = useState([]);
    const [alerts, setAlerts] = useState([])
    const alertState = { alerts, setAlerts }

    async function handlePost() {
        try {
            await Api.post('/operators', formdata, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success' }])
            setFormdata(initalFormState)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Create error' }])
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const assignments = await Api.get('/assignments/live', idToken);
                setAssignments(assignments)
                const domiciles = await Api.get('/locations/live/Domicile', idToken);
                setDomiciles(domiciles)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    }, [set, idToken, alerts])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <br />
            <Container>
                <Card>
                    <Card.Header><b>Driver Form</b></Card.Header>
                    {alerts.length > 0 &&
                        <AlertDisplay alertState={alertState} />
                    }
                    <Card.Body>
                        <Row>
                            <Col sm={12} md={6} lg={3}>
                                First Name
                                <Form.Control type="text" value={formdata.firstname} onChange={e => setFormdata({ ...formdata, firstname: e.target.value })} />
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                Last Name
                                <Form.Control type="text" value={formdata.lastname} onChange={e => setFormdata({ ...formdata, lastname: e.target.value })} />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6} lg={3}>
                                License Class
                                <Form.Select value={formdata.licensetype} onChange={e => setFormdata({ ...formdata, licensetype: e.target.value })}>
                                    <option>Select</option>
                                    {LICENSE_TYPES.map((code, i) => (
                                        <option key={i} value={code.name}>{code.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                Assignment
                                <Form.Select value={JSON.stringify(formdata.assignment)} onChange={e => setFormdata({ ...formdata, assignment: JSON.parse(e.target.value) })}>
                                    <option>Select</option>
                                    {assignments && assignments.map(assignment => (
                                        <option key={assignment._id} value={JSON.stringify(assignment)}>{assignment.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                Domicile
                                <Form.Select value={JSON.stringify(formdata.domicile)} onChange={e => setFormdata({ ...formdata, domicile: JSON.parse(e.target.value) })}>
                                    <option>Select</option>
                                    {domiciles && domiciles.map(domicile => (
                                        <option key={domicile._id} value={JSON.stringify(domicile)}>{domicile.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        <br />
                        <Button variant="primary" onClick={() => handlePost()}>Create</Button>
                    </Card.Body>
                </Card>
            </Container>
        </Container>
    );
}

export default OperatorForm;