import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import { Container, Card, Spinner, Col, Row, Table, } from "react-bootstrap";
import Api from '../../utils/Api';
import { FaPlusCircle } from 'react-icons/fa'
import { useAuth } from '../../context/auth';
import { useSettings } from '../../context/settings';
import { utcToTimezone } from '../../utils/DateTimeFormat.helper';
import DataDisplay from '../../components/DataDisplay';
import { Link } from 'react-router-dom';
import AlertDisplay from '../../components/AlertDisplay';

function NotificationReports() {
    const { idToken } = useAuth();
    const { settings } = useSettings();
    const [set, didSet] = useState();
    const [reports, setReports] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Api.get('/reports', idToken);
                setReports(data)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: JSON.stringify(error) }]);
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    }, [idToken, set, alerts, settings])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{ borderRadius: 0, border: 0 }}>
                <Card.Header>
                    <Row>
                        <Col>
                            <Card.Title>Notification Reports</Card.Title>
                        </Col>
                    </Row>
                </Card.Header>
                {set ?
                    <>
                        <Card.Body>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Report</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reports.map((report, i) => (
                                        <tr>
                                            <td style={{padding:'0.5em',}}>{i + 1}</td>
                                            <td style={{padding:'0.5em',}}>{report.name}</td>
                                            <td style={{padding:'0.5em',}}>{report.description}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

            </Card>
        </Container>
    )
}

export default NotificationReports;