import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from "react";
import { Container, Card, Row, Col, } from "react-bootstrap";
import { useRole } from "../context/role";
import logo from '../assets/logo.png'
import Api from "../utils/Api";
import { useAuth } from "../context/auth";
import { Link } from 'react-router-dom';
import AlertDisplay from '../components/AlertDisplay';
import LogisticsLoading from '../components/LogisticsLoading';
import { appFeatureGroups } from '../context/appfeaturegroups';
import { multidimensionalGroupBy } from '../utils/Array.helper';

function Home() {
    const { user, idToken } = useAuth();
    const [load, setLoad] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [apihealth, setApiHealth] = useState();
    const [newUsers, setNewUsers] = useState([]);
    const [liveUsers, setLiveUsers] = useState([]);
    const [todaysUsers, setTodaysUsers] = useState([]);
    const [weeklyUsers, setWeeklyUsers] = useState([]);

    async function fetchNewUsers() {
        try {
            const newUserData = await Api.get('/access/new-users', idToken);
            setNewUsers(newUserData);
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching new users.', }])
        }
    }

    async function fetchActiveUsers() {
        try {
            const liveUserData = await Api.get('/access/active-users/15', idToken);
            setLiveUsers(liveUserData);
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching live users.', }])
        }
    }

    async function fetchTodaysUsers() {
        try {
            const liveUserData = await Api.get('/access/active-users/1440', idToken);
            setTodaysUsers(liveUserData);
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching live users.', }])
        }
    }

    async function fetchWeeklyUsers() {
        try {
            const liveUserData = await Api.get('/access/active-users/10080', idToken);
            setWeeklyUsers(liveUserData);
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching live users.', }])
        }
    }

    const fetchApi = async () => {
        const res = await Api.get('/healthcheck');
        setApiHealth(res)
    }

    useEffect(() => {
        if (!load) {
            fetchNewUsers();
            fetchActiveUsers();
            fetchTodaysUsers();
            fetchWeeklyUsers();
            fetchApi();
            setLoad(true)
        }
    }, [load])

    const { userRole, checkNavPermissions } = useRole();

    const features = appFeatureGroups

    const allowedFeatures = checkNavPermissions(userRole, features)

    function filterFeatures(data) {
        const filteredData = [];
        const length = data.length;

        for (let i = 0; i < length; i++) {
            const currentItem = data[i];
            const currentFeature = currentItem.feature;
            const prevItem = i > 0 ? data[i - 1] : null;
            const prevFeature = prevItem?.feature ?? null;
            const nextItem = i < length - 1 ? data[i + 1] : null;
            const nextFeature = nextItem?.feature ?? null;

            // Check if currentItem has an href
            if (currentItem.href) {
                filteredData.push(currentItem);
            } else {
                if (currentFeature === prevFeature && currentFeature === nextFeature) {
                    // Check if there is a prevItem or nextItem with an href
                    if ((prevItem && prevItem.href) || (nextItem && nextItem.href)) {
                        filteredData.push(currentItem);
                    }
                }
            }
        }

        return filteredData;
    }

    const filteredFeatures = filterFeatures(allowedFeatures);

    const groups = multidimensionalGroupBy(filteredFeatures, ['feature'])

    return (
        <Container>
            <AlertDisplay alertState={alertState} />
            <br />
            {!load ?
                <LogisticsLoading message={'Loading...'} />
                :
                <>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col xs={12} md={6} lg={4} xl={3} style={{ textAlign: 'center' }}>
                                    <img
                                        src={logo}
                                        alt="logo"
                                        style={{ width: "225px", height: "auto", }}
                                    />
                                </Col>
                                <Col xs={12} md={6} lg={4} xl={3}>
                                    <Card.Header>
                                        <b>{(process.env.REACT_APP_CLIENT_NAME)}</b>
                                    </Card.Header>
                                    <Card.Text>
                                        User : {user.email} <br />
                                        Environment : {(process.env.NODE_ENV)} <br /> <br />
                                        Users Active Recently : {liveUsers.length} <br />
                                        Last 24 Hours : {todaysUsers.length} <br />
                                        Previous Week : {todaysUsers.length}
                                    </Card.Text>
                                </Col>

                                {Object.keys(groups).map((feature, i) => (
                                    <Col xs={12} md={6} lg={4} xl={3} key={i}>
                                        <Card.Header>
                                            {(feature).toUpperCase()}
                                        </Card.Header>
                                        <ul title={feature} id="basic-nav-dropdown">
                                            {groups[feature].map(endpoint => (
                                                (endpoint?.href) &&
                                                <li><Link to={(endpoint?.href)}>{endpoint.icon} {endpoint.text}</Link></li>
                                            ))}
                                        </ul>
                                    </Col>
                                ))}
                            </Row>
                        </Card.Body>
                    </Card>
                </>
            }
        </Container>
    );
}

export default Home;