import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Modal, ModalBody, ModalHeader, Row, Spinner } from "react-bootstrap";
import Tag from "../../components/TagButton";
import { CompactPicker } from "react-color";
import AlertDisplay from "../../components/AlertDisplay";
import { useAuth } from "../../context/auth";
import Api from "../../utils/Api";
import ConfirmationDangerModal from "../../components/ConfirmationDangerModal";

const initialTag = {
    name: 'Tag name',
    fontColorHEX: '#333',
    backgroundColorHEX: '#fff'
}

function Tags({ feature }) {
    const { idToken } = useAuth();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    const [showTagsModal, setShowTagsModal] = useState(false);

    const [didFetchTags, setDidFetchTags] = useState(false);

    const [tags, setTags] = useState([]);

    const [tag, setTag] = useState(initialTag);

    const [selectedTag, setSelectedTag] = useState([]);

    const [removeTagModal, setRemoveTagModal] = useState(false);

    async function handleTagCreate() {
        try {
            await Api.post('/edi/tags', tag, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'Tag created successfully.' }])
            setDidFetchTags(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured creating tag.' }])
        }
    }

    async function handleTagDelete(id) {
        try {
            await Api.delete(`/edi/tag/${id}`, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'Tag deleted successfully.' }])
            setDidFetchTags(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured deleting tag.' }])
        } finally {
            setRemoveTagModal(false)
        }
    }

    function handleSelectTag(id) {
        setRemoveTagModal(true);
        setSelectedTag(id);
    }

    useEffect(() => {
        const fetchTags = async () => {
            console.log('Fetch Tags')
            try {
                const tagData = await Api.get('/edi/tags', idToken);
                setSelectedTag(tagData);
                setTags(tagData)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching tags.' }])
            } finally {
                setDidFetchTags(true)
            }
        }
        if (!didFetchTags) {
            fetchTags();
        }
    }, [didFetchTags, alerts, idToken])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <ConfirmationDangerModal
                show={removeTagModal}
                onHide={() => setRemoveTagModal(false)}
                onConfirm={() => handleTagDelete(selectedTag)}
                message={'Are you sure you want to delete this tag?'}
            />
            <br />
            <Container>
                <Card>
                    <Card.Header>
                        <Card.Title>
                            Tags
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col xs="auto">
                                <label>Create Tag</label>
                                <Form.Control
                                    style={{
                                        color: tag.fontColorHEX,
                                        backgroundColor: tag.backgroundColorHEX,
                                        borderColor: tag.fontColorHEX,
                                    }}
                                    type="text"
                                    placeholder='Name the tag'
                                    value={tag.name}
                                    onChange={e => setTag({ ...tag, name: e.target.value })}
                                />
                            </Col>
                            <Col xs="auto">
                                <CompactPicker // Use TwitterPicker component for color selection
                                    color={tag.backgroundColorHEX}
                                    onChangeComplete={(color) => setTag({ ...tag, backgroundColorHEX: color.hex })}
                                />
                                <br />
                                Background Color
                            </Col>
                            <Col xs="auto">
                                <CompactPicker // Use TwitterPicker component for color selection
                                    color={tag.fontColorHEX}
                                    onChangeComplete={(color) => setTag({ ...tag, fontColorHEX: color.hex })}
                                /><br />
                                Text Color
                            </Col>
                            <Col xs="auto">
                                <Button onClick={() => handleTagCreate()}>
                                    Create
                                </Button>
                            </Col>
                        </Row>
                        <hr />
                        <i>Select a tag to remove it.</i>
                        {didFetchTags ?
                            <Row>
                                {tags.length > 0 && tags.sort((a, b) => (a.name.localeCompare(b.name))).map((t, i) => (
                                    <Col xs="auto" key={i} style={{ margin: '0.1em', padding: '0.25em' }} onClick={() => handleSelectTag(t._id)}>
                                        <Tag
                                            name={t.name}
                                            fontColorHEX={t.fontColorHEX}
                                            backgroundColorHEX={t.backgroundColorHEX}
                                        />
                                    </Col>
                                ))}
                            </Row>
                            :
                            <Spinner />
                        }
                    </Card.Body>
                </Card>
            </Container>
        </Container>
    )
}


export default Tags;