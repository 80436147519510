import React, { useState, useEffect } from 'react';
import DataVisual from './components/DataVisual';
import { DateTime } from 'luxon';
import { Button, ButtonGroup, Card, Container, Dropdown } from 'react-bootstrap';
import { useAuth } from '../../context/auth';
import Api from '../../utils/Api';

const AccidentDataSection = () => {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [alerts, setAlerts] = useState([]);
    const [data, setData] = useState([]);
    const [selectedXField, setSelectedXField] = useState('');
    const [selectedLabel, setSelectedLabel] = useState('');
    const [processedAndSortedData, setProcessedAndSortedData] = useState([]);
    const [isMobileView, setIsMobileView] = useState(false);

    const options = [
      { key: 'accidentCity', label: 'City' },
      { key: 'accidentState', label: 'State' },
      { key: 'companyCost', label: 'Cost' },
      { key: 'accidentType', label: 'Type' },
      { key: 'timeOfDay', label: 'Time of Day' },
      { key: 'roadType', label: 'Road Type' },
      { key: 'dotRecordable', label: 'DOT Recordable' },
      { key: 'preventable', label: 'Preventable' },
      { key: 'driverAge', label: 'Driver Age' },
    ];

    const daysOfWeekOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    async function fetchData() {
      try {
        const injuries = await Api.get(`/safety/accidents`, idToken);
        setData(injuries);
      } catch {
        setAlerts([...alerts, { type: 'warning', message: 'Failed to load injury data' }]);
      }
    }

    useEffect(() => {
      if (!set) {
        fetchData();
      }
    }, [set]);

    const sortDaysOfWeek = (a, b) => {
        return daysOfWeekOrder.indexOf(a.dayOfWeek) - daysOfWeekOrder.indexOf(b.dayOfWeek);
    };

    const calculateAgeBracket = (birthDate) => {
      const age = Math.floor(DateTime.now().diff(birthDate, 'years').years);
      if (age < 25) return 'Under 25';
      const ageBracket = `${Math.floor(age / 5) * 5}-${Math.floor(age / 5) * 5 + 4}`;
      return ageBracket;
    };

    const calculateTimeOfDay = (time) => {
      const hour = time.hour;
      const startHour = Math.floor(hour / 4) * 4;
      const startTime = String(startHour).padStart(2, '0') + '00'; // Format start hour as 'HH00'
      const endTime = String(startHour + 3).padStart(2, '0') + '59'; // Format end hour as 'HH00'
      return `${startTime}-${endTime}`;
    };      

    const preProcessData = (data) => {
        return data.map(item => {
          const jsDate = new Date(`${item.date} ${item.time}`);      
          const luxonDateTime = DateTime.fromJSDate(jsDate);
          const dateOfBirth = DateTime.fromISO(item.companyDriverBirthDate);
      
          return {
            ...item,
            timeOfDay: calculateTimeOfDay(luxonDateTime),
            driverAge: calculateAgeBracket(dateOfBirth),
          };
        });
      };      

    useEffect(() => {
        let processed = preProcessData(data);

        if (selectedXField === 'dayOfWeek') {
            processed = processed.sort(sortDaysOfWeek);
        }

        setProcessedAndSortedData(processed);
    }, [data, selectedXField]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 768);
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

  return (
    <Container fluid>
      <Card border="secondary">
        <Card.Header as="h4">
          Accident Data
        </Card.Header>
        {isMobileView ? (
            <Dropdown style={{ margin: '10px' }}>
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    {selectedLabel}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {options.map(({ key, label }) => (
                        <Dropdown.Item
                            key={key}
                            active={selectedXField === key}
                            onClick={() => { setSelectedXField(key); setSelectedLabel(label) }}
                        >
                            {label}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        ) : (
            <ButtonGroup style={{ margin: '10px' }}>
                {options.map(({ key, label }) => (
                    <Button
                        key={key}
                        variant={selectedXField === key ? 'primary' : 'secondary'}
                        onClick={() => { setSelectedXField(key); setSelectedLabel(label) }}
                        style={{ padding: '10px', border: '1px solid black' }}
                    >
                        {label}
                    </Button>
                ))}
            </ButtonGroup>
        )}
        <DataVisual
          data={processedAndSortedData}
          fields={{ x: selectedXField, y: '' }}
          chartType='bar'
          title={`Accidents by ${selectedLabel}`}
          showExportButtons={true}
        />

      </Card>
    </Container>
    
  );
};

export default AccidentDataSection;
