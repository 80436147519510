import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';
import AlertDisplay from '../../components/AlertDisplay';
import { FaTrash } from 'react-icons/fa';
import { TIMEZONE_OPTIONS } from '../../context/appdata';

const initialForm = {
    name: '',
    report: '',
    groupIds: '',
    schedules: []
}

const initialSchedule = {
    time: '08:00',
    timeZone: 'ET',
    sunday: true,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true
}

const AlarmForm = () => {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [form, setForm] = useState(initialForm);
    const [groupIds, setGroupIds] = useState([]);
    const [schedules, setSchedules] = useState([]);
    const [groups, setGroups] = useState([]);
    const [report, setReport] = useState({});
    const [reports, setReports] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    function handleGroupIds(id) {
        const exists = groupIds.includes(id);

        if (!exists) {
            setGroupIds(prevIds => [...prevIds, id]);
        } else {
            const updatedIds = groupIds.filter(groupId => groupId !== id);
            setGroupIds(updatedIds);
        }
    }

    function handleScheduleChange(val, i, key) {
        const updatedSchedules = schedules.map((schedule, index) => {
            if (index === i) {
                return { ...schedule, [key]: val };
            }
            return schedule;
        });
        setSchedules(updatedSchedules);
    }

    useEffect(() => {
        setForm(prevForm => ({ ...prevForm, groupIds }));
    }, [groupIds]);

    useEffect(() => {
        setForm(prevForm => ({ ...prevForm, schedules }));
    }, [schedules]);

    async function handleSubmit() {
        try {
            const newNotification = { ...form, report: report.name, callbackName: report.callbackName };
            await Api.post('/notifications', { ...newNotification }, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'Notification created.' }])
            setForm(initialForm)
            setSchedules([])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured.' }])
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const reportData = await Api.get('/reports', idToken)
                setReports(reportData)
                const groupData = await Api.get('/access/groups', idToken)
                setGroups(groupData)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }])
            } finally {
                didSet(true)
            }

        }

        if (!set) {
            fetchData()
        }
    }, [set, idToken, alerts])

    useEffect(()=>{

    },[report, groupIds])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{ borderRadius: 0, border: 0 }}>
                <Card.Header>
                    Alarm Form
                </Card.Header>
                {!set ? <Spinner /> :
                    <>
                        <Card.Body>
                            <Row>
                                <Col>
                                    Select Report
                                    <Form.Control as="select" onChange={(e) =>
                                        setReport(JSON.parse(e.target.value))}>
                                        <option></option>
                                        {reports.map((report, i) => (
                                            <option key={i} value={JSON.stringify(report)}>
                                                {report.name} - {report.description}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    Select Groups
                                    {groups.length > 0 ? groups.map((group, i) => (
                                        <Card key={i} style={{marginBottom:'1em'}}>
                                            <Card.Header>
                                                <Row>
                                                    <Col xs="auto">
                                                        <Form.Check
                                                            checked={groupIds.includes(group._id)}
                                                            onClick={() => handleGroupIds(group._id)}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        {group.name} - {group.detail}
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    {group.members.map((mbr, j) => (
                                                        <Col xs="auto" key={j}>
                                                            {mbr}
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    )) : 'Create a new Group'}
                                </Col>
                            </Row>
                            {schedules.length > 0 ?
                                schedules.map((schedule, i) => (
                                    <Row key={i} style={{ alignItems: 'center', marginTop: '1em', paddingTop: '0.25em', paddingBottom: '0.25em' }}>
                                        <Col xs="auto">
                                            <Form.Control
                                                type="time"
                                                value={schedule.time}
                                                onChange={(e) => handleScheduleChange(e.target.value, i, 'time')}
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <Form.Select 
                                                value={schedule.timeZone}
                                                onChange={(e) => handleScheduleChange(e.target.value, i, 'timeZone')}>
                                                <option></option>
                                                {TIMEZONE_OPTIONS.map((tz,i)=>(
                                                    <option key={i} value={tz.code}>{tz.code}</option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                        <Col xs="auto">
                                            Sunday
                                            <Form.Check checked={schedule.sunday} onClick={(e) => handleScheduleChange(!schedule.sunday, i, 'sunday')} />
                                        </Col>
                                        <Col xs="auto">
                                            Monday
                                            <Form.Check checked={schedule.monday} onClick={(e) => handleScheduleChange(!schedule.monday, i, 'monday')} />
                                        </Col>
                                        <Col xs="auto">
                                            Tuesday
                                            <Form.Check checked={schedule.tuesday} onClick={(e) => handleScheduleChange(!schedule.tuesday, i, 'tuesday')} />
                                        </Col>
                                        <Col xs="auto">
                                            Wednesday
                                            <Form.Check checked={schedule.wednesday} onClick={(e) => handleScheduleChange(!schedule.wednesday, i, 'wednesday')} />
                                        </Col>
                                        <Col xs="auto">
                                            Thursday
                                            <Form.Check checked={schedule.thursday} onClick={(e) => handleScheduleChange(!schedule.thursday, i, 'thursday')} />
                                        </Col>
                                        <Col xs="auto">
                                            Friday
                                            <Form.Check checked={schedule.friday} onClick={(e) => handleScheduleChange(!schedule.friday, i, 'friday')} />
                                        </Col>
                                        <Col xs="auto">
                                            Saturday
                                            <Form.Check checked={schedule.saturday} onClick={(e) => handleScheduleChange(!schedule.saturday, i, 'saturday')} />
                                        </Col>
                                        <Col xs="auto">
                                            <Button variant="warning" onClick={() => setSchedules(schedules.filter((_, index) => index !== i))}><FaTrash /></Button>
                                        </Col>
                                    </Row>
                                ))
                                : 'No schedules.'}
                            <Row style={{ alignItems: 'center', marginTop: '1em', paddingTop: '0.25em', paddingBottom: '0.25em' }}>
                                <Col xs="auto">
                                    <Button variant="secondary" onClick={() => setSchedules([...schedules, initialSchedule])}>Schedule +</Button>
                                </Col>
                                <Col xs="auto">
                                    <Button variant='primary' onClick={() => handleSubmit()}>Create</Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </>
                }
            </Card>

        </Container>
    )
}

export default AlarmForm;