import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/auth';
import AlertDisplay from '../../components/AlertDisplay';
import { Container, Spinner } from 'react-bootstrap';
import Api from '../../utils/Api';
import DataDisplay from '../../components/DataDisplay';

const InjurySection = () => {
  const { idToken } = useAuth();
  const [set, didSet] = useState()
  const [data, setData] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const alertState = { alerts, setAlerts }

  async function fetchInjuryData() {
    try {
      const injuryData = await Api.get('/safety/injuries', idToken)
      if (injuryData && injuryData.length > 0) {
        setData(injuryData)
      }
    } catch {
      setAlerts([...alerts, { vairant: 'warning', message: 'An error occured fetching injuries' }])
    } finally {
      didSet(true)
    }
  }

  useEffect(() => {
    if (!set) {
      fetchInjuryData();
    }
  }, [set])

  return (
    <Container fluid>
      <AlertDisplay alertState={alertState} />
      {!set ?
        <Spinner />
        :
        <DataDisplay
          sourceName={'MCTMS-Injury-Data'}
          dataSource={data}
          lsKey={'@mctms-safety-injury-data-display'}
          urlKey='_id'
          urlPath='/safety/injuries/update-form/'
        />
      }
    </Container>
  );
};

export default InjurySection;
