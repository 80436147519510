import { Alert, Badge, Button, Card, Col, Container, Form, Image, Row, Table } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';
import { FaTrashCan } from "react-icons/fa6";
import SignatureModal from '../../components/SignatureModal';
import PhoneNumberInput from '../../components/PhoneNumberInput';
import { useNavigate, useParams } from 'react-router-dom';
import LOGO from '../../assets/logo.png'
import PrintPDF from '../../components/PrintPDF';
import AddressAutocomplete from '../../components/AddressAutocomplete';
import LoadTenderSearchModal from './LoadTender.SearchModal';
import AlertDisplay from '../../components/AlertDisplay';
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';
import { findUSPSNASSCode } from '../../context/uspsdata';

const initialFormState = {
    ShipmentId: '',
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    signatureCarrier: '',
    equipment: '',
    totalAmount: '',
    orderNumber: '',
    date: '',
    miles: '',
    weight: '',
    billToFirstName: '',
    billToLastName: '',
    signatureBillTo: '',
    stops: [],
}

const initialStop = {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipcode: '',
    date: '',
    arrivalTime: '',
    departureTime: '',
}


// feature = create, read, confirm, accepted, rejected

function LoadTenderRateConfirmation({ feature }) {
    const navigate = useNavigate();
    const { ShipmentId, confirmationId } = useParams();
    const { idToken, user } = useAuth();
    const [profile, setProfile] = useState();
    const [loadtender, setLoadTender] = useState({});
    const [didFetch, setDidFetch] = useState();
    const [didLoad, setDidLoad] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts };
    const [form, setForm] = useState(initialFormState);
    const [stops, setStops] = useState([]);
    const [stopsAnimation, setStopsAnimation] = useState(false);
    const [showSignatureModal, setShowSignatureModal] = useState(false);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [newLock, setNewLock] = useState();
    const [signatureType, setSignatureType] = useState(null);

    const [fullAddress, setFullAddress] = useState('');
    const fullAddressState = { fullAddress, setFullAddress };
    const addressAutocompleteRef = useRef(null);

    const [customers, setCustomers] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [carriers, setCarriers] = useState([]);

    function handleAddStop() {
        const stopCount = stops.length;
        const lastStop = stopCount - 1;
        if (stopCount > 0) {
            const newStop = {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zipCode: '',
                arrivalDate: '',
                arrivalTime: '',
                departureDate: '',
                departureTime: '',
                specialInstructions: ''
            }
            setStops([...stops, newStop])
        } else {
            setStops([...stops, initialStop])
        }
        setStopsAnimation(true);
    }

    function handleStopUpdate(e, i, propertyName) {
        const updatedStops = [...stops];
        updatedStops[i] = { ...updatedStops[i], [propertyName]: e.target.value };
        setStops(updatedStops);

        setForm(prevForm => ({
            ...prevForm,
            stops: updatedStops,
        }));
    }

    function handleRemoveStop(index) {
        const remainingStops = stops.filter((_, i) => index !== i);
        setStops([...remainingStops])
        setForm(prevForm => ({
            ...prevForm,
            stops: [...remainingStops],
        }));
    }

    function handleFormChange(e) {
        const { name, value } = e.target;

        setForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    function handleSignatureClick(type) {
        setSignatureType(type)
        setShowSignatureModal(true);
    }

    const handleSaveSignature = (signatureDataURL, signatureType) => {
        setForm(prevForm => ({
            ...prevForm,
            [signatureType]: signatureDataURL
        }));
        setShowSignatureModal(false);
    };

    const handleClearSignature = (signatureType) => {
        setForm(prevForm => ({
            ...prevForm,
            [signatureType]: ''
        }));
    };

    const handleSignatureType = (type) => {
        setSignatureType(type);
        setShowSignatureModal(true);
    };

    async function fetchLoadTender() {
        try {
            const load = await Api.get(`/loadtender/${ShipmentId}`, idToken);
            setLoadTender(load)
            const equipment = load?.edi204[0]?.Equipment;
            const weight = load?.edi204[0]?.Weight;
            const vias = load?.edi204[0]?.Stops.map(via => {
                return {
                    name: findUSPSNASSCode(via?.Name),
                    fullAddress: `${via?.Address1}${via?.Address2 ? ' ' + via?.Address2 : ''} ${via?.City} ${via?.State} ${via?.Zip} ${via?.Country}`,
                    arrive: via?.RequestedDate,
                    depart: via?.LastRequestedDate,
                }
            });

            setForm({
                ...form,
                equipment: equipment,
                weight: weight,
                date: '',
                stops: vias,
                miles: '',
            })
            setStops(vias)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'an error occured getting load' }])
        } finally {
            setDidLoad(true)
        }
    }

    async function fetchRateConfirmation() {
        try {
            const confirmMessage = await Api.get(`/loadtender/ratecon/${confirmationId}`, idToken);
            setForm(confirmMessage)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'an error occured getting confirmation' }])
        } finally {
            setDidFetch(true)
        }
    }

    async function handleCreate() {
        try {
            const ratecon = {
                response: 'Accepted',
                ShipmentId: ShipmentId,
                email: user.email,
                phone: form.phone,
                firstname: form.firstname,
                lastname: form.lastname,
                equipment: form.equipment,
                signatureCarrier: form.signatureCarrier,
                stops: stops,
            }
            const result = await Api.post(`/loadtender/${ShipmentId}/rateconfirmation`, ratecon, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'success', }])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured.', }])
        }
    }

    async function handleAccept() {
        try {
            const rateAccepted = {
                response: 'Accepted',
                ShipmentId: ShipmentId,
                email: user.email,
                phone: form.phone,
                firstname: form.firstname,
                lastname: form.lastname,
                equipment: form.equipment,
                signatureCarrier: form.signatureCarrier,
                stops: stops,
            }
            const result = await Api.post(`/loadtender/${ShipmentId}/rateaccepted`, rateAccepted, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'success', }])
            navigate(`/carrier/loadtender/${ShipmentId}/confirm/${result._id}`)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured.', }])
        }
    }

    async function handleReject() {
        try {
            const rateRejected = {
                response: 'Rejected',
                ShipmentId: ShipmentId,
                email: user.email,
                phone: form.phone,
                firstname: form.firstname,
                lastname: form.lastname,
                equipment: form.equipment,
                signatureCarrier: form.signatureCarrier,
                stops: stops,
            }
            const result = await Api.post(`/loadtender/${ShipmentId}/raterejected`, rateRejected, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'success', }])
            navigate(`/carrier/loadtender/${ShipmentId}/confirm/${result._id}`)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured.', }])
        }
    }

    async function fetchProfile() {
        try {
            console.log('fetch profile')
            const data = await Api.get(`/access/profile/uid/${user.uid}`, idToken);
            setProfile(data)
            setForm({
                ...form,
                firstname: data?.firstname,
                lastname: data?.lastname,
                phone: data?.phone,
            })
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'an error occured getting profile' }])
        }
    }

    const fetchCompanies = async () => {
        try {
            const companiesData = await Api.get(`/companies/active`, idToken);
            setCompanies(companiesData);

        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error fetching active companies' }])
        }
    }

    const fetchCustomers = async () => {
        try {
            const customerData = await Api.get(`/customers/active`, idToken);
            setCustomers(customerData);

        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error fetching active customers' }])
        }
    }

    const fetch3PLCarriers = async () => {
        try {
            const carrierData = await Api.get(`/carriers/active`, idToken);
            setCarriers(carrierData);

        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error fetching active carriers' }])
        }
    }

    useEffect(() => {
        if (!profile) {
            fetchProfile();
        }
        if (!didLoad && ShipmentId) {
            fetchLoadTender();
            fetchCustomers();
            fetchCompanies();
            fetch3PLCarriers();
        }
        if (!didFetch && confirmationId) {
            fetchRateConfirmation();
        }
    }, [profile, didLoad, didFetch, ShipmentId, confirmationId])


    const billTo = {
        customer: '',
        fullAddress: '',
        phone: '',
        email: '',
    }

    return (
        <Container>
            <AlertDisplay alertState={alertState} />
            <LoadTenderSearchModal
                show={showSearchModal}
                onHide={() => setShowSearchModal(false)}
            />
            <PrintPDF sourceName={`MailCarrierTMS-Rate-Confirmation-${loadtender?.ShipmentId}`}>
                <Card className='my-3 p-2' style={{ borderRadius: 0 }}>
                    <div className='header'>
                        <Row style={{ backgroundColor: '#eee', padding: 0, margin: 0 }}>
                            <h2>Carrier's Shipping Instructions & Rate Confirmation</h2>
                        </Row>
                        <Row>
                            <Col>
                                <h4>Bill To:</h4>
                                <Form.Control as="select">
                                    <option value="">Select Company...</option>
                                </Form.Control>
                            </Col>
                            <Col xs="auto" style={{ padding: '1em', margin: 0 }}>
                                <Image src={LOGO} style={{ maxHeight: '200px', maxWidth: '100%' }} />
                            </Col>
                        </Row>
                    </div>

                    {/* Desktop Table View */}
                    <div className='d-none d-md-block'>
                        <Table bordered hover size='sm'>
                            <thead className="table-secondary">
                                <tr >
                                    <th className='small'>Carrier</th>
                                    <th className='small'>Carrier Phone</th>
                                    <th className='small'>Equipment</th>
                                    <th className='small'>Total Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <Form.Control
                                            as="select"
                                            name='carrier'
                                            value={form?.carrier}
                                            onChange={handleFormChange}
                                            readOnly={['confirm', 'read'].includes(feature)}
                                            disabled={['confirm', 'read'].includes(feature)}
                                        >
                                            <option value="">Select Company...</option>
                                        </Form.Control>
                                    </td>
                                    <td>
                                        <PhoneNumberInput
                                            textSize={'sm'}
                                            name='phone'
                                            value={form?.phone}
                                            onChange={handleFormChange}
                                            readOnly={['confirm', 'read'].includes(feature)}
                                            disabled={['confirm', 'read'].includes(feature)}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type='text'
                                            size='sm'
                                            name='equipment'
                                            value={form?.equipment}
                                            onChange={handleFormChange}
                                            readOnly={['confirm', 'read'].includes(feature)}
                                            disabled={['confirm', 'read'].includes(feature)}

                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type='text'
                                            size='sm'
                                            name='totalAmount'
                                            value={form?.totalAmount}
                                            onChange={handleFormChange}
                                            readOnly={['confirm', 'read'].includes(feature)}
                                            disabled={['confirm', 'read'].includes(feature)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                            <thead className="table-secondary">
                                <tr >
                                    <th className='small'>Shipment
                                        {['create'].includes(feature) &&
                                            <Badge
                                                bg="success"
                                                style={{ float: 'right', cursor: 'pointer' }}
                                                onClick={() => setNewLock(true)}
                                                readOnly={['confirm', 'read'].includes(feature)}
                                                disabled={['confirm', 'read'].includes(feature)}
                                            >
                                                New
                                            </Badge>
                                        }
                                        {['create'].includes(feature) &&
                                            <Badge
                                                bg="primary"
                                                style={{ float: 'right', cursor: 'pointer' }}
                                                onClick={() => setShowSearchModal(true)}
                                                readOnly={['confirm', 'read'].includes(feature)}
                                                disabled={['confirm', 'read'].includes(feature)}
                                            >
                                                Pick
                                            </Badge>
                                        }

                                    </th>
                                    <th className='small'>Date</th>
                                    <th className='small'>Miles</th>
                                    <th className='small'>Weight (lbs)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <Form.Control
                                            type='text'
                                            size='sm'
                                            name='ShipmentId'
                                            value={ShipmentId}
                                            onChange={handleFormChange}
                                            readOnly={['confirm', 'read'].includes(feature)}
                                            disabled={['confirm', 'read'].includes(feature)}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type='date'
                                            size='sm'
                                            name='date'
                                            value={form?.date}
                                            onChange={handleFormChange}
                                            readOnly={['confirm', 'read'].includes(feature)}
                                            disabled={['confirm', 'read'].includes(feature)}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type='number'
                                            size='sm'
                                            name='miles'
                                            value={form?.miles}
                                            onChange={handleFormChange}
                                            readOnly={['confirm', 'read'].includes(feature)}
                                            disabled={['confirm', 'read'].includes(feature)}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type='number'
                                            size='sm'
                                            name='weight'
                                            value={form?.weight}
                                            onChange={handleFormChange}
                                            readOnly={['confirm', 'read'].includes(feature)}
                                            disabled={['confirm', 'read'].includes(feature)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        {stops.map((via, i) => (
                            <Table bordered hover size='sm' key={i} className={`small`} >
                                <thead className='table-secondary'>
                                    <tr>
                                        <th colSpan={3}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                                                <span>Stop {i + 1}</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={3}>
                                            {/* <AddressAutocomplete
                                                ref={addressAutocompleteRef}
                                                fullAddressState={fullAddressState}
                                            /> */}
                                        </td>
                                    </tr>
                                </tbody>
                                <thead className='table-secondary' >
                                    <tr>
                                        <th>Arrival Date/Time</th>
                                        <th>Departure Date/Time</th>
                                        <th>Special Instructions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        ))}
                        {/* {tables} */}
                    </div>

                    {/* Card View for mobile */}
                    <div className='d-md-none'>
                        <Col>
                            <Table bordered hover size='sm' style={{ border: '1px solid grey' }}>
                                <tbody className='small'>
                                    <tr>
                                        <th className="table-secondary">Driver:</th>
                                        <td>
                                            <Form.Control type='text' size='sm'
                                                value={form.firstname}
                                                readOnly={['confirm', 'read'].includes(feature)}
                                                disabled={['confirm', 'read'].includes(feature)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="table-secondary">Driver Phone:</th>
                                        <td>
                                            <Form.Control type='text' size='sm'
                                                value={form.phone}
                                                readOnly={['confirm', 'read'].includes(feature)}
                                                disabled={['confirm', 'read'].includes(feature)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="table-secondary">Equipment:</th>
                                        <td>
                                            <Form.Control type='text' size='sm'
                                                value={form.equipment}
                                                readOnly={['confirm', 'read'].includes(feature)}
                                                disabled={['confirm', 'read'].includes(feature)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="table-secondary">Order #</th>
                                        <td>
                                            <Form.Control type='text' size='sm'
                                                value={form.ShipmentId}
                                                readOnly={['confirm', 'read'].includes(feature)}
                                                disabled={['confirm', 'read'].includes(feature)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="table-secondary">Date</th>
                                        <td>
                                            <Form.Control type='date' size='sm'
                                                value={form.date}
                                                readOnly={['confirm', 'read'].includes(feature)}
                                                disabled={['confirm', 'read'].includes(feature)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="table-secondary">Miles</th>
                                        <td>
                                            <Form.Control type='number' size='sm'
                                                value={form.miles}
                                                readOnly={['confirm', 'read'].includes(feature)}
                                                disabled={['confirm', 'read'].includes(feature)} /></td>
                                    </tr>
                                    <tr>
                                        <th className="table-secondary">Weight</th>
                                        <td><Form.Control type='number' size='sm'
                                            value={form.weight}
                                            readOnly={['confirm', 'read'].includes(feature)}
                                            disabled={['confirm', 'read'].includes(feature)}
                                        />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Table bordered hover size='sm' style={{ border: '1px solid grey' }}>
                                <tbody className='small'>
                                    {stops.map((via, i) => (
                                        <tr>
                                            <th className="table-secondary">
                                                <span>Stop {i + 1}</span>
                                            </th>
                                            <td>
                                                <p>{via?.name}</p>
                                                <p>{via?.fullAddress}</p>
                                                <p>{via?.arrive}</p>
                                                <p>{via?.depart}</p>
                                                <p>{via?.specialInstructions}</p>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </div>

                    {feature === 'create' &&
                        <Button onClick={() => handleAddStop()}>Add Stop</Button>
                    }

                    <div className='Terms my-2'>
                        <Table bordered hover size='sm'>
                            <thead className='table-secondary'>
                                <tr>
                                    <th>Terms:</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {['confirm', 'read'].includes(feature) ?
                                            form.terms
                                            :
                                            <Form.Control
                                                as='textarea'
                                                rows={3}
                                                name='terms'
                                                value={form?.terms}
                                                onChange={handleFormChange}
                                                readOnly={['confirm', 'read'].includes(feature)}
                                                disabled={['confirm', 'read'].includes(feature)}
                                            />
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    {feature === 'create' &&
                        <>
                            <div className='mt-2'>
                                <Row>
                                    <Row>
                                        <Col>
                                            (Bill To Representative)
                                        </Col>
                                    </Row>
                                    <Col sm={4}>
                                        <Form.Group>
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='billtoFirstName'
                                                value={form.billtoFirstName}
                                                placeholder='Enter first name'
                                                onChange={handleFormChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group>
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='billtoLastName'
                                                value={form.billtoLastName}
                                                placeholder='Enter last name'
                                                onChange={handleFormChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group controlId="signature">
                                            <Form.Label>Signature</Form.Label>
                                            <div style={{ height: '38px', display: 'flex', alignItems: 'center' }}>
                                                {form.signatureProvider ? (
                                                    <div
                                                        style={{ border: '1px solid #ced4da', borderRadius: '6px', height: '100%', width: '100%', cursor: 'pointer', display: 'flex', justifyContent: 'center' }}
                                                        onClick={() => handleSignatureClick('billto')}
                                                    >
                                                        <img
                                                            src={form.signatureProvider}
                                                            alt="Signature"
                                                            style={{ height: '100%' }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Click to sign"
                                                        readOnly
                                                        onClick={() => handleSignatureClick('billto')}
                                                        value={form.signatureProvider}
                                                    />
                                                )}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    }

                    {feature === 'confirm' &&
                        <>
                            <div className='mt-2'>
                                <Row>
                                    <Row>
                                        <Col>
                                            (Bill To)
                                        </Col>
                                    </Row>
                                    <Col sm={4}>
                                        <Form.Group>
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='billToFirstName'
                                                value={form?.billToFirstName}
                                                readOnly={true}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group>
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='billToLastName'
                                                value={form?.billToLastName}
                                                readOnly={true}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group controlId="signature">
                                            <Form.Label>Signature</Form.Label>
                                            <div style={{ height: '38px', display: 'flex', alignItems: 'center' }}>
                                                <img
                                                    src={form?.signatureBillTo}
                                                    alt="Bill To Signature"
                                                    style={{ height: '100%' }}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row>
                                    <Col>
                                        (Carrier)
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={4}>
                                        <Form.Group>
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='carrierFirstName'
                                                value={form.carrierFirstName}
                                                placeholder='Enter first name'
                                                onChange={handleFormChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group>
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='carrierLastName'
                                                value={form.carrierLastName}
                                                placeholder='Enter last name'
                                                onChange={handleFormChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group controlId="signature">
                                            <Form.Label>Signature</Form.Label>
                                            <div style={{ height: '38px', display: 'flex', alignItems: 'center' }}>
                                                {form.signatureCarrier ? (
                                                    <div
                                                        style={{ border: '1px solid #ced4da', borderRadius: '6px', height: '100%', width: '100%', cursor: 'pointer', display: 'flex', justifyContent: 'center' }}
                                                        onClick={() => handleSignatureClick('carrier')}
                                                    >
                                                        <img
                                                            src={form?.signatureCarrier}
                                                            alt="Signature"
                                                            style={{ height: '100%' }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Click to sign"
                                                        readOnly
                                                        onClick={() => handleSignatureClick('carrier')}
                                                        value={form?.signatureCarrier}
                                                    />
                                                )}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    }

                    <div>
                        {form?.response &&
                            <>
                                {form.response === 'Accepted' ?
                                    <Alert variant="success">
                                        <Row>
                                            <Col>Accepted | {form?.createdUtcTimestamp}</Col>
                                        </Row>
                                    </Alert> :
                                    form.response === 'Rejected' ?
                                        <Alert variant="danger">
                                            <Row>
                                                <Col>Rejected | {form?.createdUtcTimestamp}</Col>
                                            </Row>
                                        </Alert> :
                                        form.response}

                            </>
                        }
                    </div>

                    <Row className='mt-3'>
                        {feature === 'create' &&
                            <Col xs="auto">
                                <Button variant='primary' onClick={handleCreate}>Create</Button>
                            </Col>
                        }
                        {(feature === 'confirm' && form?.signatureCarrier) &&
                            <>
                                <Col>
                                    <Button variant='danger' onClick={handleReject}>Reject</Button>
                                </Col>
                                <Col xs="auto">
                                    <Button variant='success' onClick={handleAccept}>Accept</Button>
                                </Col>
                            </>
                        }
                    </Row>

                    {(form?.signatureCarrier) &&
                        <Alert variant='warning'>
                            You must sign to Accept/Reject
                        </Alert>
                    }

                    <SignatureModal
                        show={showSignatureModal}
                        onHide={() => setShowSignatureModal(false)}
                        onSave={(signatureDataURL) => handleSaveSignature(signatureDataURL, 'signatureBillTo')}
                        onClear={() => handleClearSignature('signatureBillTo')}
                        savedSignature={form?.signatureBillTo}
                    />


                    <SignatureModal
                        show={showSignatureModal}
                        onHide={() => setShowSignatureModal(false)}
                        onSave={(signatureDataURL) => handleSaveSignature(signatureDataURL, 'signatureCarrier')}
                        onClear={() => handleClearSignature('signatureCarrier')}
                        savedSignature={form?.signatureCarrier}
                    />



                </Card>
            </PrintPDF>

        </Container >
    );
}

export default LoadTenderRateConfirmation;