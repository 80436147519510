import React, { useState, useEffect } from 'react'
import { Container, Card, Row, Col, Spinner, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';
import AlertDisplay from '../../components/AlertDisplay';
import DataDisplayV3 from '../../components/DataDisplayV3';

function mapArraysByKey(array1, array2, key) {
    const result = [];

    array1.forEach((obj1) => {
        const matchedObj2 = array2.find((obj2) => obj2[key] === obj1[key]);

        if (matchedObj2) {
            result.push([obj1, matchedObj2]);
        } else {
            result.push([obj1, undefined]);
        }
    });

    array2.forEach((obj2) => {
        const matchedObj1 = array1.find((obj1) => obj1[key] === obj2[key]);

        if (!matchedObj1) {
            result.push([undefined, obj2]);
        }
    });

    return result;
}

function AccessGroup() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [groups, setGroups] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [showgroups, setShowGroups] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const groups = await Api.get('/access/groups', idToken);

                const cleanGroups = [];
                for (let i = 0; i < groups.length; i++) {
                    const cleaned = {
                        _id: groups[i]._id,
                        'Name ': groups[i].name,
                        'Members ': `${groups[i].members.length}/${groups.length}`,
                        'Active ': groups[i].isActive ? '✅' : '❌',
                    }
                    cleanGroups.push(cleaned)
                }
                setGroups(cleanGroups)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error', }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    }, [idToken, set, alerts])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{ borderRadius: 0, border: 0 }}>
                <Card.Header>
                    <Card.Title>Groups x {groups.length}
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    {set ? (
                        <>
                            {groups && groups.length > 0 ? (
                                <DataDisplayV3
                                    dataSource={groups}
                                    urlPath={'/notification/group/'}
                                    urlKey={'_id'}
                                    lsKey={'@mctms-access-groups-table'}
                                    popKeys={['_id']}
                                />
                            ) : (
                                <Row>
                                    <Col>No groups.</Col>
                                </Row>
                            )}
                        </>
                    ) : (
                        <Spinner />
                    )}
                </Card.Body>
            </Card>

        </Container>
    )
}

export default AccessGroup;