import React, { useState, useEffect, useCallback } from 'react'
import Api from "../../utils/Api";
import { Button, Card, Col, Container, Form, Modal, ModalBody, ModalHeader, Row, Spinner, Table } from 'react-bootstrap';
import AlertDisplay from '../../components/AlertDisplay';
import { useAuth } from '../../context/auth';
import { useLocation } from 'react-router-dom';

function LoadTenderSearchModal({ show, onHide, shipmentIdState }) {
    const { idToken } = useAuth();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const location = useLocation();
    const [set, didSet] = useState();
    const queryParams = new URLSearchParams(location.search);
    const shipmentId = queryParams.get('ShipmentId');
    const [ShipmentId, setShipmentId] = useState(shipmentId || '');
    const [isSearching, setIsSearching] = useState(false);
    const [shipment, setShipment] = useState();


    const fetchShipment = useCallback(async () => {
        try {
            setIsSearching(true);
            if (ShipmentId.trim('') !== '') {
                const data = await Api.get(`/edi/shipment/${ShipmentId}`, idToken);
                setShipment(data);
            } else {
                setAlerts([...alerts, { variant: 'info', message: 'Enter a Shipment Id' }]);
            }
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occurred.' }]);
        } finally {
            setIsSearching(false);
            didSet(true)
        }
    }, [ShipmentId, idToken, alerts]);


    useEffect(() => {
        if (shipmentId && !set) {
            fetchShipment();
        }
    }, [set, shipmentId, fetchShipment]);

    return (
        <Modal show={show}>
            <ModalHeader onHide={onHide} closeButton>
                
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        {isSearching ?
                            <Spinner />
                            :
                            (shipment && shipment.length > 0) ?
                                <pre>
                                    {JSON.stringify(shipment, "", 2)}
                                </pre>
                                :
                                <h3>
                                    Shipment ID not found.
                                </h3>
                        }
                    </Col>
                </Row>
            </ModalBody>
        </Modal>

    )
}


export default LoadTenderSearchModal;