import Access from '../features/access/Access';
// import AccessNav from '../features/access/Access.nav';
import AccessProfileForm from '../features/access/Access.profile.form';
import AccessProfileUpdate from '../features/access/Access.profile.update';
import AccessRoleForm from '../features/access/Access.role.form';
import AccessRoleUpdate from '../features/access/Access.role.update';
import Assignment from '../features/assignment/Assignment';
import AssignmentForm from '../features/assignment/Assignment.form';
import AssignmentUpdate from '../features/assignment/Assignment.update';
import IOT from '../features/iot/IOT';
import Frequency from '../features/frequency/Frequency';
import FrequencyForm from '../features/frequency/Frequency.form';
import FrequencyUpdate from '../features/frequency/Frequency.update';
// import Fuel from '../features/fuel/PADDFuel';
// import HCR from '../features/hcr/HCR';
import Home from '../views/Home'
import LoadEye from '../features/loadeye/LoadEye';
// import LoadTender from '../features/loadtender/LoadTender';
// import LoadTenderNav from '../features/loadtender/LoadTender.nav';
// import LoadTenderActive from '../features/loadtender/LoadTender.active';
// import LoadTenderCompleted from '../features/loadtender/LoadTender.completed';
// import LoadTenderDashboard from '../features/loadtender/LoadTender.dashboard';
// import LoadTenderRejected from '../features/loadtender/LoadTender.rejected';
// import LoadTenderRequests from '../features/loadtender/LoadTender.requests';
// import LoadTenderResponse from '../features/loadtender/LoadTender.response';
// import LoadTenderUpdate from '../features/loadtender/LoadTender.update';
// import LoadTenderView from '../features/loadtender/LoadTender.view';
import Location from '../features/location/Location';
import LocationForm from '../features/location/Location.form';
import LocationUpdate from '../features/location/Location.update';
// import Manifest from '../features/manifest/Manifest';
import Operator from '../features/operator/Operator';
import OperatorForm from '../features/operator/Operator.form';
import OperatorUpdate from '../features/operator/Operator.update';

import Profile from '../views/Profile';

import Settings from '../features/settings/Settings'
import SpecialOp from '../features/specialop/SpecialOp';
import SpecialOpForm from '../features/specialop/SpecialOp.form';
import SpecialOpUpdate from '../features/specialop/SpecialOp.update';

import Trailer from '../features/trailer/Trailer';
import TrailerForm from '../features/trailer/Trailer.form';
import TrailerUpdate from '../features/trailer/Trailer.update';
import Vehicle from '../features/vehicle/Vehicle';
import VehicleForm from '../features/vehicle/Vehicle.form';
import VehicleUpdate from '../features/vehicle/Vehicle.update';

import AccessNewUsers from '../features/access/Access.newusers';
import AccessNewUserForm from '../features/access/Access.newuser.form';

import AccessGroup from '../features/alarms/Access.group';
import AccessGroupForm from '../features/alarms/Access.group.form';
import AccessGroupUpdate from '../features/alarms/Access.group.update';

import Weather from '../features/weather/Weather';
import Samsara from '../features/eld/Samsara';
import Notifcation from '../features/notification/Notification';
import NotifcationForm from '../features/notification/Notification.form';
import Job from '../features/job/Job';
import Help from '../views/Help';
import AccessRole from '../features/access/Access.role';
import AccessProfile from '../features/access/Access.profile';
import GeoTab from '../features/eld/GeoTab';
import AccessStale from '../features/access/Access.stale';
import LoadTenderUpdateV3 from '../features/loadtender/LoadTender.update.v3';
import LoadTenderPerformanceUSPS from '../features/loadtender/LoadTender.performance.usps';
import Log from '../features/logs/Log';
import Requests from '../features/logs/Requests';
import AccidentDataSection from '../features/safety/AccidentDataSection';
import AccidentFormSection from '../features/safety/AccidentFormSection';
import InjuryDataSection from '../features/safety/InjuryDataSection';
import InjurySection from '../features/safety/InjurySection';
import AccidentSection from '../features/safety/AccidentSection';
import InjuryFormSection from '../features/safety/InjuryFormSection';
import AccidentUpdate from '../features/safety/AccidentUpdate';
import InjuryUpdate from '../features/safety/InjuryUpdate';
import DriverProfile from '../features/driver/Driver.Profile';
import LoadTenderDashboardUSPS from '../features/loadtender/LoadTender.dashboard.usps';
import DriverAccident from '../features/driver/Driver.Accident';
import DriverInjury from '../features/driver/Driver.Injury';
import DriverLoadTenders from '../features/driver/Driver.LoadTenders';
import LoadTenderTemplates from '../features/loadtender/LoadTender.templates';
import LoadTenderCustomer from '../features/loadtender/LoadTender.customer';
import AccessActive from '../features/access/Access.active';
import BugReport from '../views/BugReport';
import FeatureRequest from '../views/FeatureRequest';
import NotificationUpdate from '../features/notification/Notification.update';
import NotificationReports from '../features/notification/Notification.reports';
import AccidentUpload from '../features/safety/AccidentUpload';
import InjuryUpload from '../features/safety/InjuryUpload';
import LoadTenderScheduler from '../features/loadtender/LoadTender.Scheduler';
import LoadTenderSchedules from '../features/loadtender/LoadTender.Schedules';
import Carriers from '../features/carrier/Carriers';
import Carrier from '../features/carrier/Carrier';
import Customers from '../features/customer/Customers';
import Customer from '../features/customer/Customer';
import PADDFuel from '../features/fuel/PADDFuel';
import WholesaleFuel from '../features/fuel/WholesaleFuel';
import Company from '../features/company/Company';
import DataPage from '../views/DataPage';
import Tags from '../features/tags/Tags';
import PayrollDashboard from '../features/payroll/Dashboard';
import Timecards from '../features/payroll/Timecards';
import Timecard from '../features/payroll/Timecard';
import LoadTenderNewLoad from '../features/loadtender/LoadTender.newload';
import LoadTenderGenerator from '../features/loadtender/LoadTender.generator';
import LoadTenderRateConfirmation from '../features/loadtender/LoadTender.RateConfirmation';
import LoadTenderMobileTrack from '../features/loadtender/LoadTender.MobileTrack';
import LoadTenderDriverConfirmation from '../features/loadtender/LoadTender.DriverConfirmation';
import LoadTenderPage from '../features/loadtender/LoadTenderPage';
import SafetyPage from '../features/safety/SafetyPage';
import SafetyDashboard from '../features/safety/Safety.dashboard';
import DriverPortalPage from '../features/driver/DriverPortalPage';
import CarrierPortalPage from '../features/carrier/CarrierPortalPage';
import NotificationPage from '../features/notification/NotificationPage';
import ManagerPage from '../features/manager/ManagerPage';
import AlarmPage from '../features/alarms/AlarmPage';
import AlarmReports from '../features/alarms/Alarm.reports';
import Alarm from '../features/alarms/Alarm';
import AlarmForm from '../features/alarms/Alarm.form';
import AlarmUpdate from '../features/alarms/Alarm.update';
import DriverLoadTender from '../features/driver/Driver.LoadTender';
import DriverSchedule from '../features/driver/Driver.Schedule';
import ManagerContractScoreCard from '../features/manager/Manager.contractscorecard';
import LoadTenderAtlas from '../features/loadtender/LoadTender.Atlas';
import LoadTenderQuickCover from '../features/loadtender/LoadTender.QuickCover';
import LoadTenderForwardingRules from '../features/loadtender/LoadTender.ForwardingRules';
import LoadTenderForwardingRule from '../features/loadtender/LoadTender.ForwardingRule';
import TMSKeys from '../features/iot/TMSKeys';
import CarrierLoadTenders from '../features/manager/CarrierLoadTenders';
import TMSWhitelist from '../features/iot/TMSWhitelist';
import Tenant from '../views/Tenant';
import LoadTenderAccountingUSPS from '../features/loadtender/LoadTender.accounting.usps';
import LoadTenderReconciliationUSPS from '../features/loadtender/Loadtender.reconciliation.usps';

const appRoutes = [
    { feature: 'Home', path: '/', name: 'Home', detail: 'Home page', element: <><Home /></> },
    { feature: 'Home', path: '/profile', name: 'Profile', detail: 'User profile', element: <><Profile /></> },
    { feature: 'Home', path: '/settings', name: 'Settings', detail: 'change app settings', element: <><Settings /></> },
    { feature: 'Home', path: '/help', name: 'Help', detail: 'View useful "How To" information.', element: <><Help /></> },
    { feature: 'Home', path: '/bug-report', name: 'Bug Report', detail: 'bug report', element: <><BugReport /></> },
    { feature: 'Home', path: '/feature-request', name: 'Feature Request', detail: 'feature request', element: <><FeatureRequest /></> },

    { feature: 'Data', path: '/data', name: 'Data Options', detail: '', element: <><DataPage /></> },
    // { feature: 'Data', path: '/data/companies', name: 'Company', detail: '', element: <><Companies feature={'data'}/></> },
    { feature: 'Data', path: '/data/assignments', name: 'Assignments', detail: '', element: <><Assignment feature={'data'} /></> },
    { feature: 'Data', path: '/data/assignment-form', name: 'Assignment Form', detail: 'create a new assignment', element: <><AssignmentForm /></> },
    { feature: 'Data', path: '/data/assignment/:id', name: 'Assignment', detail: 'view/update assignments', element: <><AssignmentUpdate /></> },
    { feature: 'Data', path: '/data/company', name: 'Create Company', detail: '', element: <><Company feature={'data'} method={'create'} /></> },
    { feature: 'Data', path: '/data/company/:id', name: 'Update Company', detail: '', element: <><Company feature={'data'} method={'update'} /></> },
    // { feature: 'Data', path: '/data/tradingpartners', name: 'Trading Partners', detail: '', element: <><TradingPartners feature={'data'} /></> },
    // { feature: 'Data', path: '/data/tradingpartner', name: 'Create Trading Partner', detail: '', element: <><TradingPartner feature={'data'} method={'create'} /></> },
    // { feature: 'Data', path: '/data/tradingpartner/:id', name: 'Update Trading Partner', detail: '', element: <><TradingPartner feature={'data'} method={'update'} /></> },
    { feature: 'Data', path: '/data/customers', name: 'Customers', detail: '', element: <><Customers feature={'data'} /></> },
    { feature: 'Data', path: '/data/customer', name: 'Create Customer', detail: '', element: <><Customer feature={'data'} method={'create'} /></> },
    { feature: 'Data', path: '/data/customer/:id', name: 'Update Customer', detail: '', element: <><Customer feature={'data'} method={'update'} /></> },
    { feature: 'Data', path: '/data/carriers', name: 'Carriers', detail: '', element: <><Carriers feature={'data'} /></> },
    { feature: 'Data', path: '/data/carrier', name: 'Create Carrier ', detail: '', element: <><Carrier feature={'data'} method={'create'} /></> },
    { feature: 'Data', path: '/data/carrier/:id', name: 'Update Carrier ', detail: '', element: <><Carrier feature={'data'} method={'update'} /></> },
    { feature: 'Data', path: '/data/location', name: 'Locations', detail: '', element: <><Location feature={'data'} /></> },
    { feature: 'Data', path: '/data/location-form', name: 'Location Form', detail: '', element: <><LocationForm feature={'data'} /></> },
    { feature: 'Data', path: '/data/location/:id', name: 'Location', detail: '', element: <><LocationUpdate feature={'data'} /></> },
    { feature: 'Data', path: '/data/location/usps/:nasscode', name: 'USPS Location Update', detail: '', element: <><LocationUpdate feature={'usps'} /></> },
    { feature: 'Data', path: '/data/drivers', name: 'Drivers', detail: '', element: <><Operator feature={'data'} /></> },
    { feature: 'Data', path: '/data/driver-form', name: 'Create Driver', detail: '', element: <><OperatorForm feature={'data'} /></> },
    { feature: 'Data', path: '/data/driver/:id', name: 'Update Driver', detail: '', element: <><OperatorUpdate feature={'data'} /></> },
    { feature: 'Data', path: '/data/vehicles', name: 'Vehicles', detail: '', element: <><Vehicle feature={'data'} /></> },
    { feature: 'Data', path: '/data/vehicle-form', name: 'Create Vehicle', detail: '', element: <><VehicleForm feature={'data'} /></> },
    { feature: 'Data', path: '/data/vehicle/:id', name: 'Update Vehicle', detail: '', element: <><VehicleUpdate feature={'data'} /></> },
    { feature: 'Data', path: '/data/trailers', name: 'Trailers', detail: '', element: <><Trailer feature={'data'} /></> },
    { feature: 'Data', path: '/data/trailer-form', name: 'Create Trailer', detail: '', element: <><TrailerForm feature={'data'} /></> },
    { feature: 'Data', path: '/data/trailer/:id', name: 'Update Trailer', detail: '', element: <><TrailerUpdate feature={'data'} /></> },
    { feature: 'Data', path: '/data/frequency', name: 'Frequencies', detail: '', element: <><Frequency feature={'data'} /></> },
    { feature: 'Data', path: '/data/frequency-form', name: 'Freq. Create', detail: '', element: <><FrequencyForm feature={'data'} /></> },
    { feature: 'Data', path: '/data/frequency/:id', name: 'Freq. Update', detail: '', element: <><FrequencyUpdate feature={'data'} /></> },
    { feature: 'Data', path: '/data/specialop', name: 'Special Ops', detail: '', element: <><SpecialOp feature={'data'} /></> },
    { feature: 'Data', path: '/data/specialop-form', name: 'Sops Create', detail: '', element: <><SpecialOpForm feature={'data'} /></> },
    { feature: 'Data', path: '/data/specialop/:id', name: 'SopsUpdate', detail: '', element: <><SpecialOpUpdate feature={'data'} /></> },
    { feature: 'Data', path: '/data/tags', name: 'Tags', detail: '', element: <><Tags feature={'data'} /></> },

    { feature: 'Load Tender', path: '/loadtender', name: 'Options', detail: 'load tender details', element: <><LoadTenderPage /></> },
    { feature: 'Load Tender', path: '/loadtender/:ShipmentId', name: 'Update Load Tender', detail: 'Update load tenders', element: <><LoadTenderUpdateV3 backPath={'/loadtender/dashboard'} /></> },
    { feature: 'Load Tender', path: '/loadtender/dashboard', name: 'Dashboard', detail: 'Dashboard', element: <><LoadTenderDashboardUSPS feature={'loadtender'} /></> },
    { feature: 'Load Tender', path: '/loadtender/manifest', name: 'Manifest', detail: 'Manifest', element: <>Manifest * based on contracts - do I have my loads?</> },
    { feature: 'Load Tender', path: '/loadtender/quickcover', name: 'Quick Cover', detail: '', element: <><LoadTenderQuickCover feature={'loadtender'} /></> },
    { feature: 'Load Tender', path: '/loadtender/atlas', name: 'Load Atlas', detail: 'All Routes Traveled', element: <><LoadTenderAtlas feature={'/loadtender/draft/'} /></> },
    { feature: 'Load Tender', path: '/loadtender/schedules', name: 'Schedules', detail: 'load tender schedules', element: <><LoadTenderSchedules feature={'loadtender'} /></> },
    { feature: 'Load Tender', path: '/loadtender/scheduler', name: 'Create Scheduler', detail: 'create load tender schedules', element: <><LoadTenderScheduler feature={'loadtender'} form={'create'} /></> },
    { feature: 'Load Tender', path: '/loadtender/schedule/:id', name: 'Update Scheduler', detail: 'update load tender schedules', element: <><LoadTenderScheduler feature={'loadtender'} form={'update'} /></> },
    { feature: 'Load Tender', path: '/loadtender/forwarding', name: 'Forward Rules', detail: '', element: <><LoadTenderForwardingRules feature={'loadtender'} form={'create'} /></> },
    { feature: 'Load Tender', path: '/loadtender/forwarding-new', name: 'New Forwarding Rule', detail: '', element: <><LoadTenderForwardingRule feature={'loadtender'} form={'create'} /></> },
    { feature: 'Load Tender', path: '/loadtender/forwarding/:id', name: 'Forward Rule', detail: '', element: <><LoadTenderForwardingRule feature={'loadtender'} form={'update'} /></> },
    { feature: 'Load Tender', path: '/loadtender/draft/:id', name: 'Load Template', detail: '', element: <><LoadTenderNewLoad form={'update'} /></> },
    { feature: 'Load Tender', path: '/loadtender/new-load', name: 'New Load', detail: '', element: <><LoadTenderNewLoad form={'create'} /></> },
    { feature: 'Load Tender', path: '/loadtender/brokered', name: 'Brokered Loads', detail: '3rd Party Coverage', element: <>Brokered Loads</> },
    { feature: 'Load Tender', path: '/loadtender/accounting', name: 'Accounting', detail: 'Coverage, EDI214 and Fourkites tracking reports with cost analysis', element: <><LoadTenderAccountingUSPS feature={'loadtender'} /></> },
    { feature: 'Load Tender', path: '/loadtender/performance', name: 'Performance', detail: 'Coverage, EDI214 and Fourkites tracking reports', element: <><LoadTenderPerformanceUSPS ReportType={'performance'} /></> },
    { feature: 'Load Tender', path: '/loadtender/reconciliation', name: 'Reconciliation', detail: 'Verify USPS paid you on a trip level', element: <><LoadTenderReconciliationUSPS /></> },
    { feature: 'Load Tender', path: '/loadtender/customers', name: 'Customers', detail: 'Customers', element: <><LoadTenderCustomer /></> },
    { feature: 'Load Tender', path: '/loadtender/ratecon', name: 'Rate Confirmation', detail: '', element: <><LoadTenderRateConfirmation feature={'create'} /></> },
    { feature: 'Load Tender', path: '/loadtender/ratecon/:id', name: 'View Rate Confirmation', detail: '', element: <><LoadTenderRateConfirmation feature={'read'} /></> },
    { feature: 'Load Tender', path: '/loadtender/generator', name: 'Generator', detail: '', element: <><LoadTenderGenerator /></> },
    { feature: 'Load Tender', path: '/loadtender/templates', name: 'Templates', detail: 'Templates', element: <><LoadTenderTemplates /></> },

    { feature: 'Loadeye', path: '/loadeye', name: 'Loadeye', detail: 'trailer tracking prototype', element: <><LoadEye /></> },

    { feature: 'Payroll', path: '/payroll', name: 'Payroll', detail: '', element: <><PayrollDashboard /></> },
    { feature: 'Payroll', path: '/payroll/dashboard', name: 'Dashboard', detail: '', element: <><PayrollDashboard /></> },
    { feature: 'Payroll', path: '/payroll/timecards', name: 'Timecards', detail: '', element: <><Timecards /></> },
    { feature: 'Payroll', path: '/payroll/timecard', name: 'Create Timecard', detail: '', element: <><Timecard form={'create'} /></> },
    { feature: 'Payroll', path: '/payroll/timecard/:id', name: 'Update Timecard', detail: '', element: <><Timecard form={'update'} /></> },
    { feature: 'Payroll', path: '/payroll/payrates', name: 'Pay Rates', detail: '', element: <></> },
    { feature: 'Payroll', path: '/payroll/payrate', name: 'Create Pay Rate', detail: '', element: <></> },
    { feature: 'Payroll', path: '/payroll/payrate/:id', name: 'Update Pay Rate', detail: '', element: <></> },

    { feature: 'Safety', path: '/safety', name: 'Safety', detail: '', element: <><SafetyPage /></> },
    { feature: 'Safety', path: '/safety/dashboard', name: 'Dashboard', detail: 'Deashboard', element: <><SafetyDashboard /></> },
    { feature: 'Safety', path: '/safety/accidents/list-of-forms', name: 'Accident List', detail: 'List of Accidents', element: <><AccidentSection /></> },
    { feature: 'Safety', path: '/safety/accidents/data-output', name: 'Accident Data', detail: 'Accident Data Graphics', element: <><AccidentDataSection /></> },
    { feature: 'Safety', path: '/safety/accidents/new-form', name: 'New Accident', detail: 'New Accident Form', element: <><AccidentFormSection /></> },
    { feature: 'Safety', path: '/safety/accidents/update-form/:id', name: 'Update Accident', detail: 'Update Accident Form', element: <><AccidentUpdate /></> },
    { feature: 'Safety', path: '/safety/accidents/upload', name: 'Upload Accidents', detail: 'Upload Accidents from Template', element: <><AccidentUpload /></> },
    { feature: 'Safety', path: '/safety/injuries/list-of-forms', name: 'Injury List', detail: 'List of Injuries', element: <><InjurySection /></> },
    { feature: 'Safety', path: '/safety/injuries/data-output', name: 'Injury Data', detail: 'Injury Data Graphics', element: <><InjuryDataSection /></> },
    { feature: 'Safety', path: '/safety/injuries/new-form', name: 'New Injury', detail: 'New Injury Form', element: <><InjuryFormSection /></> },
    { feature: 'Safety', path: '/safety/injuries/update-form/:id', name: 'Update Injury', detail: 'Update Injury Form', element: <><InjuryUpdate /></> },
    { feature: 'Safety', path: '/safety/injuries/upload', name: 'Upload Injuries', detail: 'Upload Injuries from Template', element: <><InjuryUpload /></> },

    { feature: 'Manager Portal', path: '/manager', name: 'Manager', detail: 'Manager view of driver profile.', element: <><ManagerPage /></> },
    { feature: 'Manager Portal', path: '/manager/contract-score-cards', name: 'Contract Score Card', detail: '', element: <><ManagerContractScoreCard view={'manager'} feature={'/loadtender'} /></> },
    { feature: 'Manager Portal', path: '/manager/carrier-score-cards', name: 'Carrier Score Cards', detail: '', element: <><CarrierLoadTenders view={'manager'} feature={'/loadtender'} /></> },
    { feature: 'Manager Portal', path: '/manager/driver-score-cards', name: 'Driver Score Cards', detail: '', element: <><DriverLoadTenders view={'manager'} feature={'/loadtender'} /></> },

    { feature: 'Driver Portal', path: '/driverportal', name: 'Driver Portal', detail: 'Driver Portal Navigation', element: <><DriverPortalPage /></> },
    { feature: 'Driver Portal', path: '/driver/profile', name: 'Driver Profile', detail: 'Driver Profile Navigation', element: <><DriverProfile /></> },
    { feature: 'Driver Portal', path: '/driver/score-card', name: 'Driver Score Card', detail: 'Driver\'s score card', element: <><DriverLoadTenders view={'driver'} feature={'/driver/loadtender'} /></> },
    { feature: 'Driver Portal', path: '/driver/schedule', name: 'Driver\'s Schedule', detail: 'Schedule', element: <><DriverSchedule view={'driver'} feature={'/driver/loadtender'} /></> },
    { feature: 'Driver Portal', path: '/driver/loadtender/:ShipmentId', name: 'Load', detail: '', element: <><DriverLoadTender /></> },
    { feature: 'Driver Portal', path: '/driver/loadtender/:ShipmentId/confirm', name: 'Confirm Load', detail: '', element: <><LoadTenderDriverConfirmation feature={'confirm'} /></> },
    { feature: 'Driver Portal', path: '/driver/loadtender/:ShipmentId/confirm/:confirmationId', name: 'Load Confirmation', detail: '', element: <><LoadTenderDriverConfirmation feature={'read'} /></> },
    { feature: 'Driver Portal', path: '/driver/loadtender/:ShipmentId/track', name: 'Mobile Track Load Tender', detail: '', element: <><LoadTenderMobileTrack /></> },
    { feature: 'Driver Portal', path: '/driver/accident-form', name: 'Accident Form', detail: 'Submit an Accident Form', element: <><DriverAccident /></> },
    { feature: 'Driver Portal', path: '/driver/injury-form', name: 'Injury Form', detail: 'Submit and Injury From', element: <><DriverInjury /></> },

    { feature: 'Carrier Portal', path: '/carrierportal', name: 'Carrier Portal', detail: '', element: <><CarrierPortalPage /></> },
    { feature: 'Carrier Portal', path: '/carrier/loadtenders', name: 'Load Tenders', detail: '', element: <><LoadTenderRateConfirmation feature={'confirm'} /></> },
    { feature: 'Carrier Portal', path: '/carrier/loadtender/:ShipmentId/confirm', name: 'Rate Confirmation', detail: '', element: <><LoadTenderRateConfirmation feature={'confirm'} /></> },
    { feature: 'Carrier Portal', path: '/carrier/loadtender/:ShipmentId/confirm/:conrfirmationId', name: 'Rate Confirmation Id', detail: '', element: <><LoadTenderRateConfirmation feature={'read'} /></> },
    { feature: 'Carrier Portal', path: '/carrier/loadtender/:ShipmentId/confirm/:conrfirmationId/update', name: 'Rate Confirmation Update', detail: '', element: <><LoadTenderRateConfirmation feature={'confirm'} /></> },
    { feature: 'Carrier Portal', path: '/carrier/loadtender/:ShipmentId/track', name: 'Mobile Track Load Tender', detail: '', element: <><LoadTenderRateConfirmation feature={'conf'} /></> },

    { feature: 'Alarms', path: '/alarms/info', name: 'Info', detail: '', element: <><AlarmPage /></> },
    { feature: 'Alarms', path: '/alarms/reports', name: 'Reports', detail: 'Report data descriptions for alarms', element: <><AlarmReports /></> },
    { feature: 'Alarms', path: '/alarms', name: 'Alarms', detail: 'change alarms settings', element: <><Alarm /></> },
    { feature: 'Alarms', path: '/alarms-form', name: 'Alarm Form', detail: 'Create alarms', element: <><AlarmForm /></> },
    { feature: 'Alarms', path: '/alarms/:id', name: 'Alarm Update', detail: 'Update notifcation', element: <><AlarmUpdate /></> },
    { feature: 'Alarms', path: '/alarms/groups', name: 'Groups', detail: 'Groups are used to categories sets of users.', element: <><AccessGroup /></> },
    { feature: 'Alarms', path: '/alarms/group-form', name: 'Group Form', detail: 'New Group', element: <><AccessGroupForm /></> },
    { feature: 'Alarms', path: '/alarms/group/:id', name: 'Group', detail: 'Update Group', element: <><AccessGroupUpdate /></> },

    { feature: 'Notification', path: '/notification/info', name: 'Info', detail: '', element: <><NotificationPage /></> },
    { feature: 'Notification', path: '/notification/reports', name: 'Reports', detail: 'Report data descriptions for notifications', element: <><NotificationReports /></> },
    { feature: 'Notification', path: '/notification', name: 'Notifications', detail: 'change notification settings', element: <><Notifcation /></> },
    { feature: 'Notification', path: '/notification-form', name: 'Notification Form', detail: 'Create notification', element: <><NotifcationForm /></> },
    { feature: 'Notification', path: '/notification/:id', name: 'Notification View', detail: 'Update notifcation', element: <><NotificationUpdate /></> },
    { feature: 'Notification', path: '/notification/groups', name: 'Groups', detail: 'Groups are used to categories sets of users.', element: <><AccessGroup /></> },
    { feature: 'Notification', path: '/notification/group-form', name: 'Group Form', detail: 'New Group', element: <><AccessGroupForm /></> },
    { feature: 'Notification', path: '/notification/group/:id', name: 'Group', detail: 'Update Group', element: <><AccessGroupUpdate /></> },

    { feature: 'IoT Integrations', path: '/iot', name: 'IoT', detail: 'Internet of Things connections', element: <><IOT /></> },
    { feature: 'IoT Integrations', path: '/tmskeys', name: 'TMS Keys', detail: 'TMS Keys', element: <><TMSKeys /></> },
    { feature: 'IoT Integrations', path: '/whitelist', name: 'TMS Whitelist', detail: 'TMS Whitelist', element: <><TMSWhitelist /></> },
    //
    { feature: 'IoT Integrations', path: '/eld/samsara', name: 'Samsara ELD Provider', detail: 'Samsara ELD', element: <><Samsara /></> },
    { feature: 'IoT Integrations', path: '/eld/geotab', name: 'Geotab ELD Provider', detail: 'Geotab ELD', element: <><GeoTab /></> },
    { feature: 'IoT Integrations', path: '/edi/kleinschmidt', name: 'Kleinschmidt EDI', detail: 'Kleinschmidt EDI', element: <><Weather /></> },
    { feature: 'IoT Integrations', path: '/fourkites', name: 'Four Kites', detail: 'Four Kites', element: <><LoadEye /></> },
    { feature: 'IoT Integrations', path: '/loadeye', name: 'Loadeye', detail: 'Loadeye trailer tracking prototype.', element: <><LoadEye /></> },
    { feature: 'IoT Integrations', path: '/fuel/padd', name: 'PADD Fuel', detail: 'See EIA fuel data.', element: <><PADDFuel /></> },
    { feature: 'IoT Integrations', path: '/fuel/wholesale', name: 'Wholesale Fuel', detail: 'See EIA fuel data.', element: <><WholesaleFuel /></> },
    { feature: 'IoT Integrations', path: '/weather', name: 'Weather', detail: 'View Weather', element: <><Weather /></> },

    { feature: 'Jobs', path: '/job', name: 'Jobs', detail: 'View a list of jobs', element: <><Job /></> },
    { feature: 'Logs', path: '/log', name: 'System Logs', detail: 'view system logs', element: <><Log /></> },
    { feature: 'Logs', path: '/requests', name: 'System Requests', detail: 'view system external requests', element: <><Requests /></> },
    // { feature: 'Logs', path: '/log/notifications', name: 'System Notifications', detail: 'view system notifications', element: <><NotificationLog /></> },

    { feature: 'Access', path: '/tenant', name: 'Tenant', detail: 'Tenant profile', element: <><Tenant /></> },
    { feature: 'Access', path: '/access', name: 'Access', detail: 'Control user access', element: <><Access /></> },
    { feature: 'Access', path: '/access/roles', name: 'Roles', detail: 'Roles grant access to features within the application.', element: <><AccessRole /></> },
    { feature: 'Access', path: '/access/profiles', name: 'Profiles', detail: 'People with access to the application.', element: <><AccessProfile /></> },
    { feature: 'Access', path: '/access/active-users', name: 'Active Users', detail: 'See a list of active users accounts.', element: <><AccessActive /></> },
    { feature: 'Access', path: '/access/new-users', name: 'New User Onboarding', detail: 'See a list of new users.', element: <><AccessNewUsers /></> },
    { feature: 'Access', path: '/access/stale-users', name: 'Stale Users', detail: 'See a list of stale users accounts.', element: <><AccessStale /></> },
    { feature: 'Access', path: '/access/new-user-form/:uid', name: 'Grant user access.', detail: 'Give new users a role.', element: <><AccessNewUserForm /></> },
    { feature: 'Access', path: '/access/profile-form/:uid', name: 'Profile Form', detail: 'New User Profile', element: <><AccessProfileForm /></> },
    { feature: 'Access', path: '/access/profile/:id', name: 'Profile', detail: 'Update Profile', element: <><AccessProfileUpdate /></> },
    { feature: 'Access', path: '/access/role-form', name: 'Role Form', detail: 'New Role', element: <><AccessRoleForm /></> },
    { feature: 'Access', path: '/access/role/:id', name: 'Role', detail: 'Update Role', element: <><AccessRoleUpdate /></> },

];


export default appRoutes;