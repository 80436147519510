import { Alert, Badge, Button, Card, Col, Container, Form, Image, Row, Table } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';
import { FaTrashCan } from "react-icons/fa6";
import SignatureModal from '../../components/SignatureModal';
import PhoneNumberInput from '../../components/PhoneNumberInput';
import { useNavigate, useParams } from 'react-router-dom';
import LOGO from '../../assets/logo.png'
import PrintPDF from '../../components/PrintPDF';
import AddressAutocomplete from '../../components/AddressAutocomplete';
import LoadTenderSearchModal from './LoadTender.SearchModal';
import AlertDisplay from '../../components/AlertDisplay';
import { useAuth } from '../../context/auth';
import Api from '../../utils/Api';
import { findUSPSNASSCode } from '../../context/uspsdata';

const initialFormState = {
    ShipmentId: '',
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    equipment: '',
    totalAmount: '',
    date: '',
    miles: '',
    weight: '',
    signatureDriver: '',
    signatureProvider: '',
    stops: [],
}

const initialStop = {
    fullAddress: '',
    arrive: '',
    depart: '',
}

function LoadTenderDriverConfirmation({ feature }) {
    const navigate = useNavigate();
    const { ShipmentId, confirmationId } = useParams();
    const { idToken, user } = useAuth();
    const [profile, setProfile] = useState();
    const [loadtender, setLoadTender] = useState({});
    const [didFetch, setDidFetch] = useState();
    const [didLoad, setDidLoad] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [form, setForm] = useState(initialFormState);
    const [stops, setStops] = useState([]);
    const [stopsAnimation, setStopsAnimation] = useState(false);
    const [showSignatureModal, setShowSignatureModal] = useState(false);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [signatureType, setSignatureType] = useState(null);

    const [fullAddress, setFullAddress] = useState('');
    const fullAddressState = { fullAddress, setFullAddress };
    const addressAutocompleteRef = useRef(null);

    function handleAddStop() {
        const stopCount = stops.length;
        const lastStop = stopCount - 1;
        if (stopCount > 0) {
            const newStop = {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zipCode: '',
                arrivalDate: '',
                arrivalTime: '',
                departureDate: '',
                departureTime: '',
                specialInstructions: ''
            }
            setStops([...stops, newStop])
        } else {
            setStops([...stops, initialStop])
        }
        setStopsAnimation(true);
    }

    function handleStopUpdate(e, i, propertyName) {
        const updatedStops = [...stops];
        updatedStops[i] = { ...updatedStops[i], [propertyName]: e.target.value };
        setStops(updatedStops);

        setForm(prevForm => ({
            ...prevForm,
            stops: updatedStops,
        }));
    }

    function handleRemoveStop(index) {
        const remainingStops = stops.filter((_, i) => index !== i);
        setStops([...remainingStops])
        setForm(prevForm => ({
            ...prevForm,
            stops: [...remainingStops],
        }));
    }

    function handleFormChange(e) {
        const { name, value } = e.target;

        setForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    function handleSignatureClick(type) {
        setSignatureType(type)
        setShowSignatureModal(true);
    }

    const handleSaveSignature = (signatureDataURL, signatureType) => {
        setForm(prevForm => ({
            ...prevForm,
            [signatureType]: signatureDataURL
        }));
        setShowSignatureModal(false);
    };

    const handleClearSignature = (signatureType) => {
        setForm(prevForm => ({
            ...prevForm,
            [signatureType]: ''
        }));
    };

    const handleSignatureType = (type) => {
        setSignatureType(type);
        setShowSignatureModal(true);
    };

    const buttonFeatures = ['confirm']
    const rejectFeatures = ['reject']

    async function handleAccept() {
        try {
            const loadAccept = {
                response: 'Accepted',
                ShipmentId: ShipmentId,
                email: user.email,
                phone: form.phone,
                firstname: form.firstname,
                lastname: form.lastname,
                equipment: form.equipment,
                signatureDriver: form.signatureDriver,
                stops: stops,
            }
            const result = await Api.post(`/loadtender/${ShipmentId}/driveraccepted`, loadAccept, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'success' }])
            navigate(`/driver/loadtender/${ShipmentId}/confirm/${result._id}`)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'an error occured send load confirmation' }])
        }
    }

    async function handleReject() {
        try {
            const loadReject = {
                response: 'Rejected',
                ShipmentId: ShipmentId,
                email: user.email,
                phone: form.phone,
                firstname: form.firstname,
                lastname: form.lastname,
                equipment: form.equipment,
                signatureDriver: form.signatureDriver,
                stops: stops,
            }
            const result = await Api.post(`/loadtender/${ShipmentId}/driverrejected`, loadReject, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'success' }])
            navigate(`/driver/loadtender/${ShipmentId}/confirm/${result._id}`)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'an error occured send load confirmation' }])
        }
    }

    async function fetchLoadTender() {
        try {
            const load = await Api.get(`/loadtender/${ShipmentId}`, idToken);
            setLoadTender(load)
            const equipment = load?.edi204[0]?.Equipment;
            const weight = load?.edi204[0]?.Weight;
            const vias = load?.edi204[0]?.Stops.map(via => {
                return {
                    name: findUSPSNASSCode(via?.Name),
                    fullAddress: `${via?.Address1}${via?.Address2 ? ' ' + via?.Address2 : ''} ${via?.City} ${via?.State} ${via?.Zip} ${via?.Country}`,
                    arrive: via?.RequestedDate,
                    depart: via?.LastRequestedDate,
                    specialInstructions: via?.specialInstructions
                }
            });

            setForm({
                ...form,
                equipment: equipment,
                weight: weight,
                date: '2023-01-02',
                stops: vias,
                miles: '',
            })
            setStops(vias)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'an error occured getting load' }])
        } finally {
            setDidLoad(true)
        }
    }

    async function fetchLoadConfirmation() {
        try {
            const confirmMessage = await Api.get(`/loadtender/drivercon/${confirmationId}`, idToken);
            setForm({ ...form, ...confirmMessage })
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'an error occured getting confirmation' }])
        } finally {
            setDidFetch(true)
        }
    }

    async function fetchProfile() {
        try {
            console.log('fetch profile')
            const data = await Api.get(`/access/profile/uid/${user.uid}`, idToken);
            setProfile(data)
            setForm({
                ...form,
                firstname: data?.firstname,
                lastname: data?.lastname,
                phone: data?.phone,
            })
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'an error occured getting profile' }])
        }
    }

    const billTo = {
        customer: '',
        fullAddress: '',
        phone: '',
        email: '',
    }

    useEffect(() => {
        if (!profile) {
            fetchProfile();
        }
        if (!didLoad && ShipmentId) {
            fetchLoadTender();
        }
        if (!didFetch && confirmationId) {
            fetchLoadConfirmation();
        }
    }, [profile, didLoad, didFetch, ShipmentId, confirmationId])

    return (
        <Container>
            <AlertDisplay alertState={alertState} />
            <LoadTenderSearchModal
                show={showSearchModal}
                onHide={() => setShowSearchModal(false)}
            />
            {profile &&
                <PrintPDF sourceName={`MailCarrierTMS-Driver-Confirmation-${loadtender?.ShipmentId}`}>
                    <Card className='my-3 p-2' style={{ borderRadius: 0 }}>
                        <div className='header'>
                            <Row style={{ backgroundColor: '#eee', padding: 0, margin: 0 }}>
                                <h2>Driver's Shipping Instructions</h2>
                            </Row>
                            <Row>
                                <Col>
                                    <strong className="small">Company:</strong>
                                    <p className="small mb-1">Mail Management Services, INC</p>
                                    <p className="small mb-1">1919 South 40th Street </p>
                                    <p className="small mb-1">Lincoln, NE 68506</p>
                                </Col>
                                <Col xs="auto" style={{ padding: '1em', margin: 0 }}>
                                    <Image src={LOGO} style={{ maxHeight: '100px', maxWidth: '100%' }} />
                                </Col>
                            </Row>
                        </div>
                        <div>
                            {form?.response &&
                                <>
                                    {form.response === 'Accepted' ?
                                        <Alert variant="success">
                                            <Row>
                                                <Col>Accepted | {form?.createdUtcTimestamp}</Col>
                                            </Row>
                                        </Alert> :
                                        form.response === 'Rejected' ?
                                            <Alert variant="danger">
                                                <Row>
                                                    <Col>Rejected | {form?.createdUtcTimestamp}</Col>
                                                </Row>
                                            </Alert> :
                                            form.response}

                                </>
                            }
                        </div>

                        {/* Desktop Table View */}
                        <div className='d-none d-md-block'>
                            <Table bordered hover size='sm'>
                                <thead className="table-secondary">
                                    <tr>
                                        <th className='small'>Driver</th>
                                        <th className='small'>Driver Phone</th>
                                        <th className='small'>Email</th>
                                        <th className='small'>Equipment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Row>
                                                <Col>
                                                    <Form.Control
                                                        type='text'
                                                        size='sm'
                                                        name='firstname'
                                                        value={form.firstname}
                                                        onChange={handleFormChange}
                                                        readOnly={['confirm', 'read'].includes(feature)}
                                                        disabled={['confirm', 'read'].includes(feature)}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Control
                                                        type='text'
                                                        size='sm'
                                                        name='lastname'
                                                        value={form.lastname}
                                                        onChange={handleFormChange}
                                                        readOnly={['confirm', 'read'].includes(feature)}
                                                        disabled={['confirm', 'read'].includes(feature)}
                                                    />
                                                </Col>
                                            </Row>
                                        </td>
                                        <td>
                                            <PhoneNumberInput
                                                textSize={'sm'}
                                                name='phone'
                                                value={form.phone}
                                                onChange={handleFormChange}
                                                readOnly={['confirm', 'read'].includes(feature)}
                                                disabled={['confirm', 'read'].includes(feature)}
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                type='text'
                                                size='sm'
                                                name='email'
                                                value={user.email}
                                                onChange={handleFormChange}
                                                readOnly={['confirm', 'read'].includes(feature)}
                                                disabled={['confirm', 'read'].includes(feature)}
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                type='text'
                                                size='sm'
                                                name='equipment'
                                                value={form.equipment}
                                                onChange={handleFormChange}
                                                readOnly={['confirm', 'read'].includes(feature)}
                                                disabled={['confirm', 'read'].includes(feature)}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                                <thead className="table-secondary">
                                    <tr >
                                        <th className='small'>Shipment

                                            {['create'].includes(feature) &&
                                                <Badge
                                                    bg="primary"
                                                    style={{ float: 'right', cursor: 'pointer' }}
                                                    onClick={() => setShowSearchModal(true)}
                                                    readOnly={['confirm', 'read'].includes(feature)}
                                                    disabled={['confirm', 'read'].includes(feature)}
                                                >
                                                    Pick
                                                </Badge>
                                            }

                                        </th>
                                        <th className='small'>Date</th>
                                        <th className='small'>Miles</th>
                                        <th className='small'>Weight (lbs)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Form.Control
                                                type='text'
                                                size='sm'
                                                name='orderNumber'
                                                value={ShipmentId}
                                                onChange={handleFormChange}
                                                readOnly={['confirm', 'read'].includes(feature)}
                                                disabled={['confirm', 'read'].includes(feature)}
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                type='date'
                                                size='sm'
                                                name='date'
                                                value={form.date}
                                                onChange={handleFormChange}
                                                readOnly={['confirm', 'read'].includes(feature)}
                                                disabled={['confirm', 'read'].includes(feature)}
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                type='number'
                                                size='sm'
                                                name='miles'
                                                value={form.miles}
                                                onChange={handleFormChange}
                                                readOnly={['confirm', 'read'].includes(feature)}
                                                disabled={['confirm', 'read'].includes(feature)}
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                type='number'
                                                size='sm'
                                                name='weight'
                                                value={form.weight}
                                                onChange={handleFormChange}
                                                readOnly={['confirm', 'read'].includes(feature)}
                                                disabled={['confirm', 'read'].includes(feature)}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            {stops.map((via, i) => (
                                <Table bordered hover size='sm' key={i} className={`small`} >
                                    <thead className='table-secondary'>
                                        <tr>
                                            <th colSpan={3}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                                                    <span>Stop {i + 1}</span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan={3}>
                                                <b>{via.name}</b> | <a target="_blank" href={`https://www.google.com/maps/search/${via.fullAddress}`}>{via.fullAddress}</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <thead className='table-secondary' >
                                        <tr>
                                            <th>Arrival Date/Time</th>
                                            <th>Departure Date/Time</th>
                                            <th>Special Instructions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{via?.arrive}</td>
                                            <td>{via?.depart}</td>
                                            <td>{via?.specialInstructions}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            ))}
                            {/* {tables} */}
                        </div>

                        {/* Card View for mobile */}
                        <div className='d-md-none'>
                            <Col>
                                <Table bordered hover size='sm' style={{ border: '1px solid grey' }}>
                                    <tbody className='small'>
                                        <tr>
                                            <th className="table-secondary">Driver:</th>
                                            <td>
                                                <Form.Control type='text' size='sm'
                                                    readOnly={['confirm', 'read'].includes(feature)}
                                                    disabled={['confirm', 'read'].includes(feature)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="table-secondary">Driver Phone:</th>
                                            <td>
                                                <Form.Control
                                                    type='text'
                                                    size='sm'
                                                    readOnly={['confirm', 'read'].includes(feature)}
                                                    disabled={['confirm', 'read'].includes(feature)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="table-secondary">Equipment:</th>
                                            <td>
                                                <Form.Control type='text' size='sm'
                                                    readOnly={['confirm', 'read'].includes(feature)}
                                                    disabled={['confirm', 'read'].includes(feature)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="table-secondary">Order #</th>
                                            <td>
                                                <Form.Control type='text' size='sm'
                                                    readOnly={['confirm', 'read'].includes(feature)}
                                                    disabled={['confirm', 'read'].includes(feature)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="table-secondary">Date</th>
                                            <td>
                                                <Form.Control type='date' size='sm'

                                                    readOnly={['confirm', 'read'].includes(feature)}
                                                    disabled={['confirm', 'read'].includes(feature)} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="table-secondary">Miles</th>
                                            <td>
                                                <Form.Control type='number' size='sm'
                                                    readOnly={['confirm', 'read'].includes(feature)}
                                                    disabled={['confirm', 'read'].includes(feature)} /></td>
                                        </tr>
                                        <tr>
                                            <th className="table-secondary">Weight</th>
                                            <td><Form.Control type='number' size='sm'
                                                readOnly={['confirm', 'read'].includes(feature)}
                                                disabled={['confirm', 'read'].includes(feature)} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <Table bordered hover size='sm' style={{ border: '1px solid grey' }}>
                                    <tbody className='small'>
                                        {stops.map((via, i) => (
                                            <tr>
                                                <th className="table-secondary">
                                                    <span>Stop {i + 1}</span>
                                                </th>
                                                <td>
                                                    <p>{via.name}<br />{via.fullAddress}</p>
                                                    <p>{via.arrive}</p>
                                                    <p>{via.depart}</p>
                                                    <p>Special Instructions</p>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </div>

                        <div className='Terms my-2'>
                            <Table bordered hover size='sm'>
                                <thead className='table-secondary'>
                                    <tr>
                                        <th>Terms:</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {form?.terms}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>

                        {
                            feature === 'create' &&
                            <>
                                <div className='mt-2'>
                                    <Row>
                                        <Row>
                                            <Col>
                                                (Bill To)
                                            </Col>
                                        </Row>
                                        <Col sm={4}>
                                            <Form.Group>
                                                <Form.Label>First Name</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    name='billtoFirstName'
                                                    value={form?.billtoFirstName}
                                                    placeholder='Enter first name'
                                                    onChange={handleFormChange}
                                                    readOnly={['confirm', 'read'].includes(feature)}
                                                    disabled={['confirm', 'read'].includes(feature)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Group>
                                                <Form.Label>Last Name</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    name='providerLastName'
                                                    value={form?.billtoLastName}
                                                    placeholder='Enter last name'
                                                    onChange={handleFormChange}
                                                    readOnly={['confirm', 'read'].includes(feature)}
                                                    disabled={['confirm', 'read'].includes(feature)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Group controlId="signature">
                                                <Form.Label>Signature</Form.Label>
                                                <div style={{ height: '38px', display: 'flex', alignItems: 'center' }}>
                                                    {form.signatureProvider ? (
                                                        <div
                                                            style={{ border: '1px solid #ced4da', borderRadius: '6px', height: '100%', width: '100%', cursor: 'pointer', display: 'flex', justifyContent: 'center' }}
                                                            onClick={() => handleSignatureClick('provider')}
                                                        >
                                                            <img
                                                                src={form.signatureProvider}
                                                                alt="Signature"
                                                                style={{ height: '100%' }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Click to sign"
                                                            readOnly
                                                            onClick={() => handleSignatureClick('provider')}
                                                            value={form.signatureProvider}
                                                        />
                                                    )}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            </>
                        }

                        {
                            feature === 'confirm' &&
                            <>
                                <div>
                                    <Row>
                                        <Col>
                                            (Driver)
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={4}>
                                            <Form.Group>
                                                <Form.Label>First Name</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    name='firstname'
                                                    value={form.firstname}
                                                    placeholder='Enter first name'
                                                    onChange={handleFormChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Group>
                                                <Form.Label>Last Name</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    name='lastname'
                                                    value={form.lastname}
                                                    placeholder='Enter last name'
                                                    onChange={handleFormChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Group controlId="signature">
                                                <Form.Label>Signature</Form.Label>
                                                <div style={{ height: '38px', display: 'flex', alignItems: 'center' }}>
                                                    {form.signatureDriver ? (
                                                        <div
                                                            style={{ border: '1px solid #ced4da', borderRadius: '6px', height: '100%', width: '100%', cursor: 'pointer', display: 'flex', justifyContent: 'center' }}
                                                            onClick={() => handleSignatureClick('driver')}
                                                        >
                                                            <img
                                                                src={form.signatureDriver}
                                                                alt="Signature"
                                                                style={{ height: '100%' }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Click to sign"
                                                            readOnly
                                                            onClick={() => handleSignatureClick('driver')}
                                                            value={form.signatureDriver}
                                                        />
                                                    )}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            </>
                        }
                        <br />
                        {!form?.signatureDriver &&
                            <Row>
                                <Col>
                                    <Alert variant="warning">You must sign before Accepting/Rejecting</Alert>
                                </Col>
                            </Row>
                        }
                        <Row className='mt-3'>
                            {(form?.signatureDriver && buttonFeatures.includes(feature)) &&
                                <>
                                    <Col>
                                        <Button variant='danger' onClick={handleReject}>Reject</Button>
                                    </Col>
                                    <Col xs="auto">
                                        <Button variant='success' onClick={handleAccept}>Accept</Button>
                                    </Col>
                                </>
                            }
                        </Row>
                        <div>
                            {form?.response &&
                                <>
                                    <h2>
                                        {form.response === 'Accepted' ?
                                            <Alert variant="success">
                                                <Row>
                                                    <Col>Accepted<br />{form.firstname} {form.lastname}<br />{form?.createdUtcTimestamp}</Col>
                                                    <Col xs="auto">
                                                        <img
                                                            src={form.signatureDriver}
                                                            alt="Signature"
                                                            style={{ height: '100px', }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Alert> :
                                            form.response === 'Rejected' ?
                                                <Alert variant="danger">
                                                    <Row>

                                                        <Col>Rejected<br />{form.firstname} {form.lastname}<br />{form?.createdUtcTimestamp}</Col>
                                                        <Col xs="auto">
                                                            <img
                                                                src={form.signatureDriver}
                                                                alt="Signature"
                                                                style={{ maxHeight: '100px', maxWidth: '100%' }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Alert> :
                                                form.response}
                                    </h2>
                                </>
                            }
                        </div>
                        <SignatureModal
                            show={showSignatureModal}
                            onHide={() => setShowSignatureModal(false)}
                            onSave={(signatureDataURL) => handleSaveSignature(signatureDataURL, 'signatureDriver')}
                            onClear={() => handleClearSignature('signatureDriver')}
                            savedSignature={form.signatureDriver}
                        />

                    </Card >
                </PrintPDF >
            }


        </Container >
    );
}

export default LoadTenderDriverConfirmation;