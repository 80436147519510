import { Container, Card, Button, Row, Col, Alert, Spinner } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import React, { useState, useEffect } from 'react'
import { useAuth } from '../../context/auth';
import AlertDisplay from "../../components/AlertDisplay";
import DataDisplayV3 from "../../components/DataDisplayV3";


function WholesaleFuel() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [toggleView, setToggleView] = useState();
    const [startdate, setStartDate] = useState('2024-02-24');
    const [enddate, setEndDate] = useState('2024-02-28');

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const query = {
                    start: startdate,
                    end: enddate,
                };
                const queryStr = new URLSearchParams(query).toString();
                const diesel = await Api.get(`/fuel/padd/diesel?${queryStr}`, idToken)
                setData(diesel.response.data)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }]);
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    },[alerts, idToken, startdate, enddate])

    return (
        <Container fluid style={{margin:0,padding:0}}>
            <AlertDisplay alertState={alertState} />
            <Card style={{border:0,borderRadius:0}}>
                <Card.Header>
                    <Row>
                        <Col>
                        <b>WholesaleFuel</b>
                        </Col>
                        <Col xs="auto">
                            Period
                            <input type="date" value={startdate} onChange={e => setStartDate(e.target.value)} />
                            <input type="date" value={enddate} onChange={e => setEndDate(e.target.value)} />
                        </Col>
                    </Row>
                </Card.Header>
                {isLoading ?
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                    :
                    <Card.Body>
                        {data ?
                            <DataDisplayV3
                                dataSource={data}
                                lsKey={'@mctms-fuel-display'}
                            />
                            :
                            <Spinner />
                        }
                    </Card.Body>
                }

            </Card>
        </Container>
    );
}

export default WholesaleFuel;