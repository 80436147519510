import { Card, Container, Spinner } from "react-bootstrap";
import { useAuth } from "../../context/auth";
import AlertDisplay from "../../components/AlertDisplay";
import { useEffect, useState } from "react";
import Api from "../../utils/Api";
import { Link, useParams } from "react-router-dom";



function DriverLoadTender() {
    const { id } = useParams();
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [load, setLoad] = useState({});
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    async function fetchLoadTender() {
        try {
            const data = await Api.get(`/loadtender/${id}`, idToken);
            setLoad(data);
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Unable to fetch loads.', }])
        } finally {
            didSet(true)
        }
    }

    useEffect(() => {
        if (!set) {
            fetchLoadTender()
        }
    }, [set])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{ border: 0, borderRadius: 0 }}>
                <Card.Header>
                    <Card.Title>
                        Load Tender Driver View
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Link to="/manager/driver-score-cards">Driver Score Cards</Link>
                </Card.Body>
                <Card.Body>
                    {!load ?
                        <Spinner />
                        :
                        <>
                            Load Tender Display
                            <pre>
                                {JSON.stringify(load, "", 2)}
                            </pre>
                            {/* <LoadTenderDisplay /> */}
                        </>
                    }
                </Card.Body>
            </Card>
        </Container>
    )
}


export default DriverLoadTender;