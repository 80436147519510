import React, { useRef } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { FaSave } from 'react-icons/fa';
import { PrintProvider } from '../context/printcontext';

const PrintPDF = ({ children, sourceName, header }) => {
  const pdfRef = useRef();


  function getTimeStamp() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const milliseconds = String(now.getMilliseconds()).padStart(3, '0');

    return `${year}${month}${day}_${hours}${minutes}${seconds}${milliseconds}`;
  }

  const handlePrint = async () => {
    const timestamp = getTimeStamp();
    const pdfContent = pdfRef.current;

    const scale = window.devicePixelRatio || 1;
    const viewportWidth = pdfContent.clientWidth;
    const viewportHeight = pdfContent.clientHeight;

    const canvas = await html2canvas(pdfContent, {
      scale: scale,
      useCORS: true,
      logging: true,
      allowTaint: false,
      scrollX: 0, 
      scrollY: 0,
    });
       
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [viewportWidth, viewportHeight]
    });

    const imgData = canvas.toDataURL('image/png', 1);
    const imgProps = doc.getImageProperties(imgData);
    const pdfWidth = doc.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    const padding = 8;
    doc.addImage(imgData, 'PNG', padding, 2 * padding, pdfWidth - (2 * padding), pdfHeight - (2 * padding));
    doc.setFontSize(8);
    doc.text(`Mail Carrier TMS - SYBR - All Rights Reserved - ${timestamp}`, padding, 1 * (padding));
    doc.text(`${window.location}`, padding, 2 * (padding));
    doc.save(`${sourceName}_${timestamp}.pdf`);
  };


  return (
    <PrintProvider>
      <Row style={{ marginTop: '0.5em', marginBottom: '0.5em', fontWeight: 'bold' }}>
        <Col>
          {header}
        </Col>
        <Col xs="auto">
          <Button onClick={() => handlePrint()}> <FaSave /> Save PDF</Button>
        </Col>
      </Row>
      <div ref={pdfRef} id="printPDF">
        {children}
      </div>
    </PrintProvider>
  );
};

export default PrintPDF;
