import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col, Card, OverlayTrigger, Tooltip, Spinner, Table } from 'react-bootstrap';
import { useAuth } from '../../context/auth';
import AlertDisplay from '../../components/AlertDisplay';
import Api from '../../utils/Api';
import { FaInfoCircle } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import TrafficSignal from '../../components/TrafficSignal';

const initialCarrier = {
    "name": "",
    "address": "",
    "address1": '',
    "city": '',
    "state": '',
    "zip": '',
    "country" : '',
    "scac": "",
    "dotNumber": "",
    "mcNumber": "",
    "contacts": [{
        "phone": "",
        "email": "",
        "website": ""
    }],
    "insurancePolicies": [{
        "insurance_provider": "",
        "policy_number": "",
        "coverage_type": "",
        "expiry_date": ""
    }],
    "tradingPartner": false,
};

const FreightCarrier = ({ feature, method }) => {
    const { idToken } = useAuth();
    const { id } = useParams();
    const [didFetch, setDidFetch] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts };

    const [carrier, setCarrier] = useState(initialCarrier);
    const [carrierNotFound, setCarrierNotFound] = useState();


    const handleInputChange = (e, index, type) => {
        const { name, value } = e.target;
        const updatedCarrier = { ...carrier };
        if (type === 'contacts' || type === 'insurancePolicies') {
            updatedCarrier[type][index][name] = value;
        } else if (type === 'address') {
            updatedCarrier[type][name] = value;
        } else {
            updatedCarrier[name] = value;
        }
        setCarrier(updatedCarrier);
    };

    const addEntry = (type) => {
        const updatedCarrier = { ...carrier };
        updatedCarrier[type].push({});
        setCarrier(updatedCarrier);
    };

    const removeEntry = (index, type) => {
        const updatedCarrier = { ...carrier };
        updatedCarrier[type].splice(index, 1);
        setCarrier(updatedCarrier);
    };

    async function handleCreate() {
        try {
            await Api.post('/carriers', carrier, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'Success' }])
            setCarrier({ ...initialCarrier });
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured.' }])
        }
    }

    async function handleUpdate() {
        try {
            await Api.patch(`/carrier/${id}`, carrier, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'Success' }])

        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured.' }])
        }
    }

    async function fetchCarrier() {
        try {
            const data = await Api.get(`/carrier/${id}`, idToken);
            setCarrier(data)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching carrier.' }])
            setCarrierNotFound(true)
        } finally {
            setDidFetch(true)
        }
    }

    useEffect(() => {
        if (id && !didFetch) {
            fetchCarrier()
        }
    }, [id, didFetch])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <br />
            <Container>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col>
                                <Card.Title>{`${method} Carrier`.toUpperCase()}</Card.Title>
                            </Col>
                        </Row>
                    </Card.Header>
                    {carrierNotFound ?
                        <Card.Body>
                            Carrier Not Found <Link to={'/data/carrier'} onClick={() => setCarrierNotFound(false)}>Create Carrier</Link>
                        </Card.Body>
                        :
                        (method === 'update' && !didFetch) ?
                            <Spinner />
                            :
                            <>
                                <Card.Body>
                                    <Form.Group controlId="name">
                                        <Form.Label>Carrier Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            value={carrier.name}
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="address">
                                        <Row>
                                            <Col xs="12" lg="8">
                                                Address
                                                <Form.Control
                                                    placeholder="Address"
                                                    value={carrier.address1}
                                                    onChange={(e) => setCarrier({ ...carrier, address1: e.target.value })}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12" md="6" lg="2">
                                                City
                                                <Form.Control
                                                    placeholder="City"
                                                    value={carrier.city}
                                                    onChange={(e) => setCarrier({ ...carrier, city: e.target.value })}
                                                />
                                            </Col>
                                            <Col xs="12" md="6" lg="2">
                                                State
                                                <Form.Control
                                                    placeholder="State"
                                                    value={carrier.state}
                                                    onChange={(e) => setCarrier({ ...carrier, state: e.target.value })}
                                                />
                                            </Col>
                                            <Col xs="12" md="6" lg="2">
                                                Zip
                                                <Form.Control
                                                    placeholder="Zip"
                                                    value={carrier.zip}
                                                    onChange={(e) => setCarrier({ ...carrier, zip: e.target.value })}
                                                />
                                            </Col>
                                            <Col xs="12" md="6" lg="2">
                                                Country
                                                <Form.Control
                                                    placeholder="Country"
                                                    value={carrier.country}
                                                    onChange={(e) => setCarrier({ ...carrier, country: e.target.value })}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>

                                    <Row>
                                        <Col xs="12" md="6" lg="2">
                                            <Form.Group controlId="dotNumber">
                                                <Form.Label>DOT Number
                                                    {carrier?.dotNumber.length === 7 &&
                                                        <a target="_blank" without rel="noreferrer" href={`https://safer.fmcsa.dot.gov/query.asp?searchtype=ANY&query_type=queryCarrierSnapshot&query_param=USDOT&query_string=${carrier.dotNumber}`}>
                                                            FMCSA Safer
                                                        </a>
                                                    }
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="dotNumber"
                                                    value={carrier.dotNumber}
                                                    onChange={(e) => handleInputChange(e)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs="12" md="6" lg="2">
                                            <Form.Group controlId="mcNumber">
                                                <Form.Label>MC Number</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="mcNumber"
                                                    value={carrier.mcNumber}
                                                    onChange={(e) => handleInputChange(e)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs="12" md="4">
                                            <Form.Group controlId="address">
                                                <Form.Label>Website</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="website"
                                                    placeholder="Website"
                                                    value={carrier.website}
                                                    onChange={(e) => handleInputChange(e)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="auto">
                                            <Form.Group controlId="tradingPartner">
                                                <Form.Label>Trading Partner</Form.Label>
                                                <Form.Check
                                                    name="tradingPartner"
                                                    checked={carrier?.tradingPartner}
                                                    onChange={(e) => setCarrier({ ...carrier, tradingPartner: !carrier.tradingPartner })}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs="auto">
                                            <Form.Group controlId="scac">
                                                <Form.Label>SCAC</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="scac"
                                                    value={carrier.scac}
                                                    onChange={(e) => handleInputChange(e)}
                                                    disabled={!carrier.tradingPartner}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs="auto">
                                            {
                                                carrier?.tradingPartner === true ?
                                                    "Business to business messaging is enabled. Loads assigned to this carrier will be sent over Electronic Data Interchange (EDI)"
                                                    :
                                                    "Loads sent to this carrier will be sent via Rate Confirmation email."
                                            }
                                            <TrafficSignal status={carrier?.tradingPartner === true ? "true" : "false"} />
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <hr />
                                <Card.Body>
                                    <Form.Group controlId="contacts">
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Contact's Name</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {carrier?.contacts && carrier?.contacts.map((contact, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <Form.Control
                                                                type="text"
                                                                name="name"
                                                                placeholder="Name"
                                                                value={contact.name}
                                                                onChange={(e) => handleInputChange(e, index, 'contacts')}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Form.Control
                                                                type="text"
                                                                name="email"
                                                                placeholder="Email"
                                                                value={contact.email}
                                                                onChange={(e) => handleInputChange(e, index, 'contacts')}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Form.Control
                                                                type="text"
                                                                name="phone"
                                                                placeholder="Phone"
                                                                value={contact.phone}
                                                                onChange={(e) => handleInputChange(e, index, 'contacts')}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Button variant="danger" onClick={() => removeEntry(index, 'contacts')}>Remove</Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                        <Button variant="secondary" onClick={() => addEntry('contacts')}>Add Contact</Button>
                                    </Form.Group>
                                </Card.Body>
                                <hr />
                                <Card.Body>
                                    <Form.Group controlId="insurancePolicies">
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Insurance Provider</th>
                                                    <th>Policy Number</th>
                                                    <th>Coverage Type</th>
                                                    <th>Experation Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {carrier?.insurancePolicies && carrier?.insurancePolicies.map((insurance, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <Form.Control
                                                                type="text"
                                                                name="insurance_provider"
                                                                placeholder="Insurance Provider"
                                                                value={insurance.insurance_provider}
                                                                onChange={(e) => handleInputChange(e, index, 'insurancePolicies')}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Form.Control
                                                                type="text"
                                                                name="policy_number"
                                                                placeholder="Policy Number"
                                                                value={insurance.policy_number}
                                                                onChange={(e) => handleInputChange(e, index, 'insurancePolicies')}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Form.Control
                                                                type="text"
                                                                name="coverage_type"
                                                                placeholder="Coverage Type"
                                                                value={insurance.coverage_type}
                                                                onChange={(e) => handleInputChange(e, index, 'insurancePolicies')}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Form.Control
                                                                type="date"
                                                                name="expiry_date"
                                                                placeholder="Expiry Date"
                                                                value={insurance.expiry_date}
                                                                onChange={(e) => handleInputChange(e, index, 'insurancePolicies')}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Button variant="danger" onClick={() => removeEntry(index, 'insurancePolicies')}>Remove</Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                        <Button variant="secondary" onClick={() => addEntry('insurancePolicies')}>Add Insurance</Button>
                                    </Form.Group>
                                </Card.Body>
                                <hr />
                                <Card.Body>
                                    <Row>

                                        {method === 'create' &&
                                            <Col xs="auto">
                                                <Button variant="primary" onClick={() => handleCreate()}>Create</Button>
                                            </Col>
                                        }
                                        {method === 'update' &&
                                            <>
                                                <Col xs="auto">
                                                    <Button variant="primary" onClick={() => handleUpdate()}>Update</Button>
                                                </Col>
                                                <Col xs="auto">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={
                                                            <Tooltip id="button-tooltip">
                                                                Active - determines whether or not the record should be
                                                                used by the software application.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div>

                                                            Active <FaInfoCircle /> : <Button variant={carrier.isActive ? 'success' : 'danger'} onClick={() => { setCarrier({ ...carrier, isActive: (carrier.isActive ? false : true) }) }}>{carrier.isActive ? 'True' : 'False'}</Button>
                                                        </div>
                                                    </OverlayTrigger>
                                                </Col>
                                            </>

                                        }
                                    </Row>
                                </Card.Body>
                            </>
                    }
                </Card>
            </Container>
        </Container>
    );
};

export default FreightCarrier;
