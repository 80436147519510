import { Card, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import { useAuth } from "../../context/auth";
import AlertDisplay from "../../components/AlertDisplay";
import { useEffect, useState } from "react";
import Api from "../../utils/Api";
import { findUSPSNASSCode } from "../../context/uspsdata";
import LogisticsLoading from "../../components/LogisticsLoading";
import { formatDateStrToMMDDYYHHMM } from "../../utils/DateTimeFormat.helper";
import ScatterPlot from "../../components/LoadTenderScatterPlot";
import { Link } from "react-router-dom";
import { FaEye, FaOpenid } from "react-icons/fa";
import CalendarChart from "../../components/CalendarChart";

const DriverScheduleCard = ({ feature, driver, loads, startDate, end }) => {

    return (
        <Container>
            <Card>
                <Card.Header>
                    <h2>{driver}</h2>
                </Card.Header>
                {loads ?
                    <>
                        <Card.Body>
                            <h3>Shipments: {loads.length}</h3>
                        </Card.Body>
                        <Card.Body>
                            <Table>
                                <tbody>
                                    {loads.map((load, i) => (
                                        <tr key={i}>
                                            <td><Link to={`${feature}/${load?.ShipmentId}`} target="_blank"><FaEye/></Link></td>
                                            <td>{load?.ShipmentId}</td>
                                            <td>{load?.RequestedDate}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                        </Card.Body>

                    </>
                    :
                    <Card.Body>
                        No Shipments
                    </Card.Body>
                }
            </Card>

        </Container>
    )
}



function DriverSchedule({ view, feature }) {
    const { user, idToken } = useAuth();
    const [drivers, setDrivers] = useState([]);
    const [profile, setProfile] = useState();
    const [set, didSet] = useState();
    const [fetched, didFetch] = useState();
    const [load, didLoad] = useState();
    const [loads, setLoads] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    const formattedStartDate = sevenDaysAgo.toISOString().split('T')[0];

    const savedQuery = JSON.parse(localStorage.getItem('@tms-driver-schedule'));

    const [query, setQuery] = useState({
        driver: view === 'driver' ? `${profile?.firstname} ${profile?.lastname}` : savedQuery ? savedQuery.driver : '',
        dateSpan: savedQuery ? savedQuery.dateSpan : 7,
        startDate: savedQuery ? savedQuery.startDate : formattedStartDate,
        endDate: savedQuery ? savedQuery.endDate : new Date().toISOString().split('T')[0],
        page: savedQuery ? savedQuery.page : 1,
        pageSize: savedQuery ? savedQuery.pageSize : 10,
    });


    useEffect(() => {
        localStorage.setItem('@tms-driver-schedule', JSON.stringify(query));
    }, [query]);

    function setQueryDates(value) {
        const endDate = new Date();
        const startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - value);

        setQuery(prevQuery => ({
            ...prevQuery,
            dateSpan: value,
            startDate: startDate.toISOString().split('T')[0],
            endDate: endDate.toISOString().split('T')[0]
        }));
    }


    async function fetchProfile() {
        try {
            const userProfile = await Api.get(`/access/profile/uid/${user?.uid}`, idToken);
            setProfile(userProfile)
            if (view === 'driver') {
                setQuery({ ...query, driver: `${userProfile?.firstname} ${userProfile?.lastname}` })
            }
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Profile error.', }])
        }
    }

    async function fetchDriverLoadTenders() {
        didFetch(false)
        try {
            const queryStr = new URLSearchParams(query).toString();
            const data = await Api.get(`/loadtenders/driver?${queryStr}`, idToken);
            console.log(data)
            setLoads(data);
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Unable to fetch loads.', }])
        } finally {
            didFetch(true)
        }
    }

    async function fetchCoverage() {
        try {
            const ediDrivers = await Api.get(`/operators/live`, idToken);
            setDrivers(ediDrivers)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Unable to fetch drivers.', }])
        }
    }

    useEffect(() => {
        if (user && !load) {
            fetchProfile();
        }

        return () => didLoad(true);
    }, [user, profile, load])

    useEffect(() => {
        if (!set) {
            fetchCoverage();
        }

        return () => didSet(true);
    }, [set])

    useEffect(() => {
        fetchDriverLoadTenders()
    }, [view, query])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            {!load ?
                <LogisticsLoading message={'Loading report...'} />
                :
                <Card style={{ border: 0, borderRadius: 0 }}>
                    <Card.Header>
                        <Card.Title>
                            Driver Load Tender Schedule
                        </Card.Title>
                    </Card.Header>
                    <Card.Header>
                        <Row>
                            <Col xs="auto">
                                Driver
                                <Form.Control
                                    as="select"
                                    value={query?.driver}
                                    onChange={e => e.target.value === 'All'
                                        ? setQuery({ ...query, driver: 'All' })
                                        : setQuery({ ...query, driver: e.target.value })
                                    }
                                    readOnly={view === 'driver' ? true : false}
                                    disabled={view === 'driver' ? true : false}
                                >
                                    <option></option>
                                    {drivers.sort((a, b) => {
                                        if (a < b) return -1;
                                        if (a > b) return 1;
                                        return 0;
                                    }).map((driver, i) => (
                                        <option key={i} value={`${driver.firstname} ${driver.lastname}`}>{driver.firstname} {driver.lastname}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col xs="auto">
                                Previous
                                <Form.Control
                                    value={query?.dateSpan}
                                    as="select"
                                    onChange={e => setQueryDates(e.target.value)}
                                >
                                    <option>7</option>
                                    <option>30</option>
                                    <option>60</option>
                                    <option>90</option>
                                </Form.Control>
                            </Col>
                            <Col xs="auto">
                                Start
                                <Form.Control type="date" value={query.startDate} readOnly disabled />
                            </Col>
                            <Col xs="auto">
                                End
                                <Form.Control type="date" value={query.endDate} readOnly disabled />
                            </Col>
                        </Row>

                    </Card.Header>
                    <Card.Body>
                        {!fetched ?
                            <LogisticsLoading message={'Loading report...'} />
                            :
                            <>
                                {loads.length > 0 ?
                                    <DriverScheduleCard feature={feature} driver={query.driver} loads={loads} startDate={query?.startDate} endDate={query?.end} />
                                    :
                                    <h3>
                                        No shipment data
                                    </h3>
                                }
                            </>
                        }
                    </Card.Body>
                </Card>
            }

        </Container>
    )
}


export default DriverSchedule;