import React, { useState, useEffect } from 'react'
import { Badge, Button, Card, Col, Container, Dropdown, Form, OverlayTrigger, Row, Spinner, Table, Tooltip } from 'react-bootstrap'
import Api from '../../utils/Api'
import { useAuth } from '../../context/auth';
import LogisticsLoading from '../../components/LogisticsLoading'
import { Link } from "react-router-dom";
import { formatDateStrToMMDDYYHHMM, } from '../../utils/DateTimeFormat.helper';
import { FaEdit, FaFileExport } from 'react-icons/fa';
import AlertDisplay from '../../components/AlertDisplay';
import { generateExcel, generatePDF } from '../../utils/FileGenerator';
import { BsFiletypePdf, BsFiletypeXlsx } from 'react-icons/bs';
import { IoTodayOutline } from "react-icons/io5";
import Chart from 'react-google-charts';
import ScatterPlot from '../../components/LoadTenderScatterPlot';
import { MdArrowBackIos, MdArrowForwardIos, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from 'react-icons/md';
import { LoadTenderFinancialSummary } from '../../components/LoadTenderFinancialSummary';
import Select from 'react-select';
import { AutoSizer, List } from 'react-virtualized';

function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    return `${year}-${month}-${day}`;
}

const today = new Date();  // today based on the clock

const initialQuery = {
    startDate: formatDate(today),
    endDate: formatDate(today),
    report: 'All',
    contract: 'All',
    driver: 'All',
    page: 1,
    pageSize: '-1'
}

function millisecondsToHHMM(milliseconds) {
    const hours = Math.floor(milliseconds / 3600000);
    milliseconds %= 3600000;
    const minutes = Math.floor(milliseconds / 60000);
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
}

function formatCentsToDollars(cents) {
    const dollars = cents / 100;
    return dollars.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}


const headerCenterStyle = {
    textAlign: 'center',
    padding: '0px 0.5em',
    backgroundColor: '#ccc',
}

const headerRightStyle = {
    textAlign: 'right',
    padding: '0px 0.5em',
    backgroundColor: '#ccc',
}

const cellCenterStyle = {
    textAlign: 'center',
    padding: '0px 0.5em'
}

const cellCenterStyleGreen = {
    textAlign: 'center',
    padding: '0px 0.5em',
    backgroundColor: '#bcdbbc'
}

const cellCenterStyleRed = {
    textAlign: 'center',
    padding: '0px 0.5em',
    backgroundColor: '#f39c9c'
}

const cellCenterStyleYellow = {
    textAlign: 'center',
    padding: '0px 0.5em',
    backgroundColor: '#f8f39c'
}

const callCenterOrangeStyle = {
    textAlign: 'center',
    padding: '0px 0.5em',
    backgroundColor: '#ffc107'
}

const cellRightStyle = {
    textAlign: 'right',
    padding: '0px 0.5em'
}

const rowEvenStyle = {
    backgroundColor: '#fff',
    border: '1px solid #ccc'
}

const rowOddStyle = {
    backgroundColor: '#e9ecef',
    border: '1px solid #ccc'
}

function LoadTenderPerformanceUSPS({ ReportType }) {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [data, setData] = useState([]);
    const [scatterData, setScatterData] = useState([]);
    const [printableData, setPrintableData] = useState();
    const [query, setQuery] = useState(() => {
        const savedQuery = localStorage.getItem(`@mctms-loadtender-reports-query`);
        return savedQuery !== null ? JSON.parse(savedQuery) : initialQuery;
    });

    const [view, setView] = useState('table');
    // const [drivers, setDrivers] = useState([]);
    // const [driversFetched, setDriversFetched] = useState([]);

    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    const [contracts, setContracts] = useState([]);
    const [contractsFetched, setContractsFetched] = useState([]);


    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');


    async function fetchContracts() {
        setContractsFetched(false)
        try {
            const queryStr = new URLSearchParams({ start: query?.startDate, end: query?.endDate }).toString()
            const ediContracts = await Api.get(`/edi204/contracts?${queryStr}`, idToken);
            const cleanContracts = [...new Set(ediContracts.filter(Contract => String(Contract).length === 5).map(Contract => Contract))]
            setContracts(cleanContracts)
        } catch (err) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured getting Contracts.' }])
        } finally {
            setContractsFetched(true)
        }
    }

    // async function fetchDrivers() {
    //     setDriversFetched(false)
    //     try {
    //         const queryStr = new URLSearchParams({ start: query?.startDate, end: query?.endDate }).toString()
    //         const ediDrivers = await Api.get(`/edi204/operators?${queryStr}`, idToken);
    //         setDrivers(ediDrivers)
    //     } catch (err) {
    //         setAlerts([...alerts, { variant: 'warning', message: 'An error occured getting Drivers.' }])
    //     } finally {
    //         setDriversFetched(true)
    //     }
    // }

    function formatDataForPrint(data) {
        return data.map((load, i) => ({
            'Shipment Id': `${load.ShipmentId}`,
            'Contract-Trip': `${load.Contract}-${load.Trip}`,
            'Coverage': `${load.coverage.flatMap(operator => operator.name).join('\n')}`,
            'Start Date': `${formatDateStrToMMDDYYHHMM(load.StartTime)}`,
            'End Date': `${formatDateStrToMMDDYYHHMM(load.EndTime)}`,
            'SOP Time': `${millisecondsToHHMM(load.SOPmilli)}`,
            'Stops': `${load.edi204[0].Stops.length}`,
            '204 Ct': `${load.edi204.length}`,
            '214 Ct': `${load.edi214.length}`,
            // 'Fourkite Updates': `${load.fourkiteShipmentUpdates.length}`,
        }))
    }

    const [loadingMessage, setLoadingMessage] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    async function fetchReport() {
        didSet(false)
        try {
            let cleanData = [];
            let resData = [];
            setLoadingMessage(`Processing`);
            const handlePageData = (data, currentPage, totalPages) => {
                // Clean and process the current batch of data
                const cleanedBatch = formatDataForPrint(data);
                cleanData = [...cleanData, ...cleanedBatch];
                resData = [...resData, ...data];
                setLoadingMessage(`Processed page ${currentPage} of ${totalPages}`);
            };

            await Api.getAllPages(`/loadtenders/shipments/report`, query, idToken, handlePageData);
            setLoadingMessage()
            setTotalRecords(cleanData.length);
            setTotalPages(1);
            setData(resData)
            setPrintableData(cleanData);
        } catch (err) {
            console.log(err)
        } finally {
            didSet(true)
        }
    }

    function incrementDate(dateStr, days) {
        const date = new Date(dateStr);
        // Add 24 hours instead of adding one day
        date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * days); // Add 24 hours in milliseconds
        const incrementedDate = date.toISOString().split('T')[0]; // Format back to YYYY-MM-DD
        return incrementedDate;
    }

    function decrementDate(dateStr, days) {
        const date = new Date(dateStr);
        // Subtract 24 hours instead of subtracting one day
        date.setTime(date.getTime() - 24 * 60 * 60 * 1000 * days); // Subtract 24 hours in milliseconds
        const decrementedDate = date.toISOString().split('T')[0]; // Format back to YYYY-MM-DD
        return decrementedDate;
    }

    function resetDays() {
        const newQueryStart = formatDate(today);
        const newQueryEnd = formatDate(today);
        setQuery({ ...query, startDate: newQueryStart, endDate: newQueryEnd })
    }

    function addDay() {
        const newQueryStart = incrementDate(query.startDate, 1);
        const newQueryEnd = incrementDate(query.endDate, 1);
        setQuery({ ...query, startDate: newQueryStart, endDate: newQueryEnd })
    }

    function subtractDay() {
        const newQueryStart = decrementDate(query.startDate, 1);
        const newQueryEnd = decrementDate(query.endDate, 1);
        setQuery({ ...query, startDate: newQueryStart, endDate: newQueryEnd })
    }

    function addWeek() {
        const newQueryStart = incrementDate(query.startDate, 7);
        const newQueryEnd = incrementDate(query.endDate, 7);
        setQuery({ ...query, startDate: newQueryStart, endDate: newQueryEnd })
    }

    function subtractWeek() {
        const newQueryStart = decrementDate(query.startDate, 7);
        const newQueryEnd = decrementDate(query.endDate, 7);
        setQuery({ ...query, startDate: newQueryStart, endDate: newQueryEnd })
    }

    function daySpanUp() {
        const maxDifference = 6; // Maximum difference between end and start
        const difference = (new Date(query.endDate) - new Date(query.startDate)) / (24 * 60 * 60 * 1000);
        if (difference >= maxDifference) return;

        const newQueryEnd = incrementDate(query.endDate, 1);
        setQuery({ ...query, endDate: newQueryEnd });
    }

    function daySpanDown() {
        const minDifference = 0; // Minimum difference between end and start
        const difference = (new Date(query.endDate) - new Date(query.startDate)) / (24 * 60 * 60 * 1000);
        if (difference <= minDifference) return;
        const newQueryEnd = incrementDate(query.endDate, -1);
        setQuery({ ...query, endDate: newQueryEnd });
    }

    function checkAudit(load) {
        let total = 0;
        if (load.coverage.length === 0) {
            total = total + load.edi204[load.edi204.length - 1].Stops.length
        } else {
            const audits = Object.keys(load.ediAudit);
            for (let i = 0; i < audits.length; i++) {
                const stopKeys = Object.keys(load.ediAudit[audits[i]].ediStops)
                if (stopKeys.length === 0) {
                    total = total + load.edi204[load.edi204.length - 1].Stops.length
                }
            }
        }


        return total;
    }

    function calculateEarly(load) {
        let total = 0;
        const audits = Object.keys(load.ediAudit);
        for (let i = 0; i < audits.length; i++) {
            const stopKeys = Object.keys(load.ediAudit[audits[i]].ediStops)
            for (let j = 0; j < stopKeys.length; j++) {
                const via = load.ediAudit[audits[i]].ediStops[stopKeys[j]]
                if (Number(via.eldOnPremiseArriveDiffMin) >= 0) {
                    total++;
                }
            }
        }

        return total;
    }

    function calculateLate(load) {
        let total = 0;
        const audits = Object.keys(load.ediAudit);
        for (let i = 0; i < audits.length; i++) {
            const stopKeys = Object.keys(load.ediAudit[audits[i]].ediStops)
            for (let j = 0; j < stopKeys.length; j++) {
                const via = load.ediAudit[audits[i]].ediStops[stopKeys[j]]
                if (Number(via.eldOnPremiseArriveDiffMin) < 0) {
                    total++;
                }
            }
        }

        return total;
    }

    function calculateMissed(load) {
        let total = 0;
        const audits = Object.keys(load.ediAudit);
        for (let i = 0; i < audits.length; i++) {
            const stopKeys = Object.keys(load.ediAudit[audits[i]].ediStops)
            for (let j = 0; j < stopKeys.length; j++) {
                const via = load.ediAudit[audits[i]].ediStops[stopKeys[j]]
                if ((!via?.eldOnPremiseArriveDiffMin)) {
                    total++;
                }
            }
        }

        return total;
    }

    useEffect(() => {
        if (!set) {
            fetchReport();
            fetchContracts()
            // fetchDrivers()
        }
    }, [set])


    // chart functions
    function loadTenderCoverageChart(shipments) {
        let unassigned = 0, covered = 0;

        for (const record of shipments) {
            if (record['coverage'].length !== 0) {
                covered++;
            } else {
                unassigned++;
            }
        }

        const chartData = [
            ['Coverage', 'Load Tender Ct.', { role: 'style' }],
            [`Open (${unassigned})`, unassigned, '#F39C9C'],
            [`Covered (${covered})`, covered, '#BCDBBC']
        ];

        return chartData;
    }

    function loadTenderStopPerformance(shipments) {
        let check = 0, early = 0, late = 0, missed = 0;

        for (const load of shipments) {
            check = check + checkAudit(load)
            early = early + calculateEarly(load)
            late = late + calculateLate(load)
            missed = missed + calculateMissed(load)
        }
        const chartData = [
            ['Coverage', 'Load Tender Ct.'],
            [`Check (${check})`, check],
            [`Early (${early})`, early],
            [`Late (${late})`, late],
            [`Not Detected (${missed})`, missed],
        ];

        return chartData;
    }

    function getColorForPoint(arrive, depart) {
        if (arrive >= 0 && depart >= 0) {
            return 'green'; // early, early
        } else if (arrive < 0 && depart < 0) {
            return 'red'; // late, late
        } else if (arrive >= 0 && depart < 0) {
            return 'blue'; // in early, out late
        } else if (arrive < 0 && depart >= 0) {
            return 'orange'; // in late, out early
        }
    }

    function loadTenderStopScatterChart(shipments) {
        const goal = [0, 0, 'gold'];
        const chartData = [['Arrival', 'Arrival, Departure', { role: 'style' }], goal];
        for (const load of shipments) {
            if (load?.ediAudit && load?.ediAudit[0]?.ediStops) {
                const keys = Object.keys(load?.ediAudit[0]?.ediStops)
                if (keys.length > 0) {
                    for (const key of keys) {
                        const via = load?.ediAudit[0]?.ediStops[key]
                        const { eldOnPremiseArriveDiffMin, eldOnPremiseDepartDiffMin } = via;
                        const arrive = Number(eldOnPremiseArriveDiffMin);
                        const depart = Number(eldOnPremiseDepartDiffMin);

                        // Check if arrive and depart are valid numbers
                        if (!isNaN(arrive) && !isNaN(depart)) {
                            // Determine the color based on arrive and depart values
                            const color = getColorForPoint(arrive, depart);
                            chartData.push([arrive, depart, color]);
                        }
                    }
                }
            }
        }

        return chartData;
    }


    function loadTenderScatterChartData(shipments) {
        const chartData = [];
        for (const load of shipments) {
            if (load?.ediAudit && load?.ediAudit[0]?.ediStops) {
                const keys = Object.keys(load?.ediAudit[0]?.ediStops)
                if (keys.length > 0) {
                    for (const key of keys) {
                        const ShipmentId = load?.ediAudit[0]?.ShipmentId;
                        const Contract = load?.ediAudit[0]?.Contract;
                        const Trip = load?.ediAudit[0]?.Trip;
                        const via = load?.ediAudit[0]?.ediStops[key]
                        const { eldOnPremiseArriveDiffMin, eldOnPremiseDepartDiffMin } = via;
                        const arrive = Number(eldOnPremiseArriveDiffMin);
                        const depart = Number(eldOnPremiseDepartDiffMin);



                        // Check if arrive and depart are valid numbers
                        if (!isNaN(arrive) && !isNaN(depart)) {
                            // Determine the color based on arrive and depart values
                            chartData.push({
                                arrivalTimeDifference: arrive,
                                departureTimeDifference: depart,
                                contractNumber: Contract,
                                tripNumber: Trip,
                                shipmentId: ShipmentId
                            });
                        }
                    }
                }
            }
        }

        return chartData;
    }

    const buttonStyle = {
        fontSize: '1em',
        border: '1px solid #2b5499',
        color: '#2b5499',
        margin: '0.2em'
    }


    const buttonActiveStyle = {
        fontSize: '1em',
        border: '1px solid #2b5499',
        background: '#2055a7',
        color: 'white',
        margin: '0.2em'
    }

    const selectStyle = {
        fontSize: '1em',
        border: '1px solid #2b5499',
        color: '#2b5499',
        margin: '0.2em'
    }

    const selectActiveStyle = {
        fontSize: '1em',
        border: '1px solid red',
        color: '#2b5499',
        margin: '0.2em'
    }

    function compare204Documents(doc1, doc2, keysToInclude = []) {
        const diffs = {};

        for (const key of keysToInclude) {
            if (typeof doc1[key] === 'object' && typeof doc2[key] === 'object') {
                const nestedDiffs = compare204Documents(doc1[key], doc2[key]);
                Object.assign(diffs, nestedDiffs);
            } else if (doc1[key] !== doc2[key]) {
                diffs[key] = [doc1[key], doc2[key]];
            }
        }

        return diffs;
    }

    function calculate204RateComparison(doc1, doc2, keysToInclude = []) {
        const diffs = compare204Documents(doc1, doc2, keysToInclude)

        const { Rate } = diffs

        return Rate && (Number(Rate[0]) / 100 - Number(Rate[1]) / 100).toFixed(2);
    }

    const handleSort = (key) => {
        if (sortBy === key) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(key);
            setSortDirection('asc');
        }
    };



    const sortedData = [...data].sort((a, b) => {
        if (sortBy) {
            // Ensure that a[sortBy] and b[sortBy] are strings before using replace
            let aValue = typeof a[sortBy] === 'string' ? parseFloat(a[sortBy].replace(/[^\d.]/g, '')) : a[sortBy];
            let bValue = typeof b[sortBy] === 'string' ? parseFloat(b[sortBy].replace(/[^\d.]/g, '')) : b[sortBy];

            // Check if the parsed values are valid numbers
            const aValueIsValid = !isNaN(aValue);
            const bValueIsValid = !isNaN(bValue);

            // If one of the values is not a valid number, handle accordingly
            if (!aValueIsValid && !bValueIsValid) return 0; // Both values are not valid numbers
            if (!aValueIsValid) return 1; // aValue is not a valid number
            if (!bValueIsValid) return -1; // bValue is not a valid number

            // Perform numerical comparison
            if (aValue < bValue) {
                return sortDirection === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortDirection === 'asc' ? 1 : -1;
            }
        }

        return 0; // Default: no sorting
    });


    const reportOptions = [
        { value: 'All', label: 'All Records' },
        { value: 'Problems', label: 'Problems' },
        { value: 'No Coverage', label: 'No Coverage' },
        { value: 'No EDI214 Updates', label: 'No EDI214 Updates' },
    ];

    const contractOptions = [
        { value: 'All', label: 'All Contracts' },
        { value: '_TOPS_', label: 'USPS FA' },
        ...contracts.map(contract => ({ value: contract, label: contract }))
    ];

    // const driverOptions = [
    //     { value: 'All', label: 'All Drivers' },
    //     ...drivers.sort((a, b) => a.localeCompare(b)).map(driver => ({
    //         value: driver,
    //         label: driver,
    //     }))
    // ];

    const tdOddStyle = {
        fontFamily: 'monospace',
        textAlign: 'left',
        verticalAlign: 'top',
        borderBottom: '1px solid #ccc',
        backgroundColor: '#f9f9f9',

    }
    const tdStyle = {
        fontFamily: 'monospace',
        textAlign: 'left',
        verticalAlign: 'top',
        borderBottom: '1px solid #ccc',
    }


    const [itemSize, setItemSize] = useState(25); // Default item size

    // Adjust item size based on window width
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {  // Check for small screen size (768px is common for mobile)
                setItemSize(75);           // Double the size on small screens
            } else {
                setItemSize(25);            // Default size on larger screens
            }
        };

        // Initial check
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Container fluid style={{ margin: '0', padding: '0' }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{ margin: '0', padding: '0', borderRadius: '0', border: 0 }}>
                <Card.Header>
                    <Row style={{ alignItems: 'center', }}>
                        <Col>
                            <b> {`Load Tender ${ReportType}`.toUpperCase()}</b>{' '}{loadingMessage}
                        </Col>
                        <Col style={{ textAlign: 'center' }}>
                            <button style={view === 'graph' ? buttonActiveStyle : buttonStyle} onClick={() => setView('graph')}>Graph</button>
                            <button style={view === 'table' ? buttonActiveStyle : buttonStyle} onClick={() => setView('table')}>Table</button>
                        </Col>
                        <Col>
                            <Row style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
                                <Col xs="auto">
                                    Records: {totalRecords}
                                </Col>
                                <Col xs="auto">
                                    Pages: {totalPages}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Header>
                    <Row style={{ alignItems: 'center', }}>
                        <Col xs="auto" style={{ textAlign: 'center' }}>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>
                                    Days in view.
                                </Tooltip>}
                            >
                                <span style={{ margin: '0 1em' }}>Period: ({((new Date(query?.endDate) - new Date(query?.startDate)) / (24 * 60 * 60 * 1000)) + 1}) Days</span>
                            </OverlayTrigger>
                            <Row style={{ textAlign: 'center' }}>
                                <Col style={{ margin: 0, padding: 0 }}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Go backwards a week
                                        </Tooltip>}
                                    >
                                        <button style={buttonStyle} onClick={() => subtractWeek()}><MdKeyboardDoubleArrowLeft /></button>
                                    </OverlayTrigger>
                                </Col>
                                <Col style={{ margin: 0, padding: 0 }}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Go backwards a day
                                        </Tooltip>}
                                    >
                                        <button style={buttonStyle} onClick={() => subtractDay()}><MdArrowBackIos /></button>
                                    </OverlayTrigger>
                                </Col>
                                <Col style={{ margin: 0, padding: 0 }}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Go to today
                                        </Tooltip>}
                                    >
                                        <button style={buttonStyle} onClick={() => resetDays()}><IoTodayOutline /></button>
                                    </OverlayTrigger>
                                </Col>
                                <Col style={{ margin: 0, padding: 0 }}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Go forward a day
                                        </Tooltip>}
                                    >
                                        <button style={buttonStyle} onClick={() => addDay()}><MdArrowForwardIos /></button>
                                    </OverlayTrigger>
                                </Col>
                                <Col style={{ margin: 0, padding: 0 }}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Go forward a week
                                        </Tooltip>}
                                    >
                                        <button style={buttonStyle} onClick={() => addWeek()}><MdKeyboardDoubleArrowRight /></button>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="auto">
                            <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
                                <Col xs="auto" style={{ marginRight: 0, paddingRight: 0 }}>
                                    <span style={{ fontSize: '0.75em', display: 'inline-block', transform: 'rotate(-90deg)' }}>Start</span>
                                </Col>
                                <Col style={{ marginLeft: 0, paddingLeft: 0 }}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Start date
                                        </Tooltip>}
                                    >
                                        <Form.Control type="date" style={selectStyle} value={query.startDate} onChange={e => setQuery({ ...query, startDate: e.target.value })} />
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="auto">
                            <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }} >
                                <Col xs="auto" style={{ marginRight: 0, paddingRight: 0 }}>
                                    <span style={{ fontSize: '0.75em', display: 'inline-block', transform: 'rotate(-90deg)' }}>End</span>
                                </Col>
                                <Col style={{ marginLeft: 0, paddingLeft: 0 }}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            End date
                                        </Tooltip>}
                                    >
                                        <Form.Control type="date" style={selectStyle} value={query.endDate} onChange={e => setQuery({ ...query, endDate: e.target.value })} />
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="auto">
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>
                                    Filter by report
                                </Tooltip>}
                            >
                                <Select
                                    value={reportOptions.find(option => option.value === query.report)}
                                    onChange={selectedOption => setQuery({ ...query, report: selectedOption.value })}
                                    options={reportOptions.sort((a, b) => a.label.localeCompare(b.label))}
                                    styles={{
                                        control: styles => ({ ...styles, ...(query.report === 'All' ? selectStyle : selectActiveStyle) }),
                                    }}
                                />
                            </OverlayTrigger>
                        </Col>
                        <Col xs="auto">
                            {!contractsFetched ?
                                <Spinner size='sm' />
                                :
                                <>
                                    <>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip>
                                                Filter by contract
                                            </Tooltip>}
                                        >
                                            <Select
                                                value={contractOptions.find(option => option.value === query.contract)}
                                                onChange={selectedOption => setQuery({ ...query, contract: selectedOption.value })}
                                                options={contractOptions.sort((a, b) => a.label.localeCompare(b.label))}
                                                styles={{
                                                    control: styles => ({ ...styles, ...(query.contract === 'All' ? selectStyle : selectActiveStyle) }),
                                                }}
                                            />
                                        </OverlayTrigger>
                                    </>
                                </>}
                        </Col>
                        <Col xs="auto">
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>
                                    Fetch the report.
                                </Tooltip>}
                            >
                                <Button onClick={()=>didSet(false)}>Fetch Report</Button>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    {!set ?
                        <LogisticsLoading message={'Loading report...'} />
                        :
                        <>
                            {data.length > 0 ?
                                <>
                                    {view === 'table' &&
                                        data.length > 1000 ?
                                        <div style={{ height: '70vh' }}>
                                            <Row>
                                                <Col>
                                                    <Badge bg="success" onClick={() => generateExcel(data, `LoadTender-Performance-Export-${query.start}-${query.end}`)}><BsFiletypeXlsx />Export to Excel</Badge>
                                                </Col>
                                                <Col></Col>
                                                <Col></Col>
                                                <Col></Col>
                                                <Col style={cellCenterStyle}>{data.length}</Col>
                                                <Col style={cellCenterStyle}>{((data.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0)))}</Col>
                                                <Col style={cellCenterStyle}>{(((data.reduce((a, b) => a + checkAudit(b), 0))) / ((data.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%</Col>
                                                <Col style={cellCenterStyle}>{(((data.reduce((a, b) => a + calculateEarly(b), 0))) / ((data.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%</Col>
                                                <Col style={cellCenterStyle}>{(((data.reduce((a, b) => a + calculateLate(b), 0))) / ((data.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%</Col>
                                                <Col style={cellCenterStyle}>{(((data.reduce((a, b) => a + calculateMissed(b), 0))) / ((data.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%</Col>
                                                <Col style={cellCenterStyle}>
                                                    {
                                                        ((((data.reduce((a, b) => a + calculateEarly(b), 0))) / ((data.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100) +
                                                            (((data.reduce((a, b) => a + calculateLate(b), 0))) / ((data.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100)).toFixed(1)
                                                    }%
                                                </Col>
                                            </Row>
                                            <AutoSizer>
                                                {({ height, width }) => (
                                                    <List
                                                        height={height}
                                                        rowCount={data.length}
                                                        rowHeight={itemSize}
                                                        width={width}
                                                        rowRenderer={({ index, key, style }) => (
                                                            <div key={key} style={style}>
                                                                <Row style={index % 2 === 0 ? tdStyle : tdOddStyle}>
                                                                    <Col></Col>
                                                                    <Col style={cellCenterStyle}>{index + 1}</Col>
                                                                    <Col style={cellCenterStyle}><Link to={`/loadtender/${data[index].ShipmentId}`} target='_blank'><FaEdit /></Link></Col>
                                                                    <Col style={cellCenterStyle}>{data[index].ShipmentId}</Col>
                                                                    <Col style={cellCenterStyle}>{data[index].Contract}-{data[index].Trip}</Col>
                                                                    <Col style={cellCenterStyle}>{data[index].edi204[0].Stops.length}</Col>
                                                                    <Col style={checkAudit(data[index]) > 0 ? callCenterOrangeStyle : cellCenterStyle}>{checkAudit(data[index])}</Col>
                                                                    <Col style={calculateEarly(data[index]) > 0 ? cellCenterStyleGreen : cellCenterStyle}>{calculateEarly(data[index])}</Col>
                                                                    <Col style={calculateLate(data[index]) > 0 ? cellCenterStyleYellow : cellCenterStyle}>{calculateLate(data[index])}</Col>
                                                                    <Col style={calculateMissed(data[index]) > 0 ? cellCenterStyleRed : cellCenterStyle}>{calculateMissed(data[index])}</Col>
                                                                    <Col style={cellCenterStyle}>
                                                                        {calculateEarly(data[index]) + calculateLate(data[index])}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )}
                                                    />
                                                )}
                                            </AutoSizer>
                                        </div>
                                        :
                                        <pre>
                                            <Table responsive striped>
                                                <thead>
                                                    <tr style={{ fontWeight: 'bold' }}>
                                                        <td style={cellCenterStyle}></td>
                                                        <td style={cellCenterStyle}>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id="tooltip-top">
                                                                    Count of ShipmentIds
                                                                </Tooltip>}
                                                            >
                                                                <span>Shipments</span>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td style={cellCenterStyle}></td>
                                                        <td style={cellCenterStyle}>Coverage</td>
                                                        <td style={cellCenterStyle}>First Start</td>
                                                        <td style={cellCenterStyle}>Last End</td>
                                                        <td style={cellRightStyle}>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id="tooltip-top">
                                                                    Sum of SOP Time
                                                                </Tooltip>}
                                                            >
                                                                <span>Sum HH:MM</span>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td style={cellCenterStyle}>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id="tooltip-top">
                                                                    This is average 204 received per shipment.
                                                                </Tooltip>}
                                                            >
                                                                <span>204 Avg</span>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td style={cellCenterStyle}>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id="tooltip-top">
                                                                    This is a count of 204s received for a shipment.
                                                                </Tooltip>}
                                                            >
                                                                <span>Stop Ct</span>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td style={cellCenterStyle}>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id="tooltip-top">
                                                                    This orange cells should be checked for accuracy. Wrong driver, not logged in, etc.
                                                                </Tooltip>}
                                                            >
                                                                <span>Check</span>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td style={cellCenterStyle}>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id="tooltip-top">
                                                                    Green cells indicate that a driver arrived early to a stop.
                                                                </Tooltip>}
                                                            >
                                                                <span>On Time</span>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td style={cellCenterStyle}>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id="tooltip-top">
                                                                    Yellow cells indicate that a driver arrived late to a stop.
                                                                </Tooltip>}
                                                            >
                                                                <span>Late</span>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td style={cellCenterStyle}>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id="tooltip-top">
                                                                    Red cells indicate that a driver was not detected at a stop. This could be issues like bad geofence, deadzone, etc.
                                                                </Tooltip>}
                                                            >
                                                                <span>Not Detected</span>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td style={cellCenterStyle}>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id="tooltip-top">
                                                                    This percentage represents the total percentage of shipment tracked.
                                                                </Tooltip>}
                                                            >
                                                                <span>Tracking</span>
                                                            </OverlayTrigger>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ fontWeight: 'bold' }}>
                                                        <td style={cellCenterStyle}></td>
                                                        <td style={cellCenterStyle}>{data.length}</td>
                                                        <td style={cellCenterStyle}></td>
                                                        <td style={cellCenterStyle}>{((data.reduce((a, b) => a + (b.coverage.length > 0 ? 1 : 0), 0) / data.length) * 100).toFixed(1)}%</td>
                                                        <td style={cellCenterStyle}>{formatDateStrToMMDDYYHHMM(data[0].StartTime)}</td>
                                                        <td style={cellCenterStyle}>{formatDateStrToMMDDYYHHMM(data[data.length - 1].EndTime)}</td>
                                                        <td style={cellRightStyle}>{millisecondsToHHMM(data.reduce((a, b) => a + b.SOPmilli, 0))}</td>
                                                        <td style={cellCenterStyle}>{((data.reduce((a, b) => a + (b.edi204.length), 0) / data.length)).toFixed(1)}</td>
                                                        <td style={cellCenterStyle}>{((data.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0)))}</td>
                                                        <td style={cellCenterStyle}>{(((data.reduce((a, b) => a + checkAudit(b), 0))) / ((data.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%</td>
                                                        <td style={cellCenterStyle}>{(((data.reduce((a, b) => a + calculateEarly(b), 0))) / ((data.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%</td>
                                                        <td style={cellCenterStyle}>{(((data.reduce((a, b) => a + calculateLate(b), 0))) / ((data.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%</td>
                                                        <td style={cellCenterStyle}>{(((data.reduce((a, b) => a + calculateMissed(b), 0))) / ((data.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%</td>
                                                        <td style={cellCenterStyle}>
                                                            {
                                                                ((((data.reduce((a, b) => a + calculateEarly(b), 0))) / ((data.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100) +
                                                                    (((data.reduce((a, b) => a + calculateLate(b), 0))) / ((data.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100)).toFixed(1)
                                                            }%
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <thead style={{ fontWeight: 'bold' }}>
                                                    <tr>
                                                        <th style={headerCenterStyle}>#</th>
                                                        <th style={headerCenterStyle} onClick={() => handleSort('ShipmentId')}>Shipment Id</th>
                                                        <th style={headerCenterStyle} onClick={() => handleSort('Contract')}>Contract-Trip</th>
                                                        <th style={headerCenterStyle} onClick={() => handleSort('Coverage')}>Coverage</th>
                                                        <th style={headerCenterStyle} onClick={() => handleSort('StartTime')}>Start Date</th>
                                                        <th style={headerCenterStyle} onClick={() => handleSort('EndTime')}>End Date</th>
                                                        <th style={headerRightStyle} onClick={() => handleSort('SOPmilli')}>SOP Time</th>
                                                        <th style={headerCenterStyle}>204 Versions</th>
                                                        <th style={headerCenterStyle}>Stops</th>
                                                        <th style={headerCenterStyle}>Check</th>
                                                        <th style={headerCenterStyle}>OnTime</th>
                                                        <th style={headerCenterStyle}>Late</th>
                                                        <th style={headerCenterStyle}>Not Detected</th>
                                                        <th style={headerCenterStyle}>Tracking</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {sortedData.map((load, i) => (
                                                        <tr key={i} style={i % 2 === 0 ? rowEvenStyle : rowOddStyle}>
                                                            <td>{i + 1}</td>
                                                            <td style={cellCenterStyle}>
                                                                <Link to={`/loadtender/${load.ShipmentId}`} target='_blank'>
                                                                    {load.ShipmentId}
                                                                </Link>
                                                            </td>
                                                            <td style={cellCenterStyle}>{load.Contract}-{load.Trip}</td>
                                                            <td style={cellCenterStyle}>
                                                                <pre>
                                                                    {load.coverage.flatMap(operator => operator.name).join('\n')}
                                                                </pre>
                                                            </td>
                                                            <td style={cellCenterStyle}>{formatDateStrToMMDDYYHHMM(load.StartTime)}</td>
                                                            <td style={cellCenterStyle}>{formatDateStrToMMDDYYHHMM(load.EndTime)}</td>
                                                            <td style={cellRightStyle}>{millisecondsToHHMM(load.SOPmilli)}</td>
                                                            <td style={cellCenterStyle}>{load.edi204.length}</td>
                                                            <td style={cellCenterStyle}>{load.edi204[0].Stops.length}</td>
                                                            <td style={checkAudit(load) > 0 ? callCenterOrangeStyle : cellCenterStyle}>{checkAudit(load)}</td>
                                                            <td style={calculateEarly(load) > 0 ? cellCenterStyleGreen : cellCenterStyle}>{calculateEarly(load)}</td>
                                                            <td style={calculateLate(load) > 0 ? cellCenterStyleYellow : cellCenterStyle}>{calculateLate(load)}</td>
                                                            <td style={calculateMissed(load) > 0 ? cellCenterStyleRed : cellCenterStyle}>{calculateMissed(load)}</td>
                                                            <td style={cellCenterStyle}>
                                                                {calculateEarly(load) + calculateLate(load)}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </pre>
                                    }
                                    {view === 'graph' &&
                                        <Row>
                                            <Col xs="auto">
                                                <ScatterPlot redirect={'/loadtender'} data={loadTenderScatterChartData(data)} width={400} height={300} />
                                            </Col>
                                            <Col xs="auto">
                                                <Chart
                                                    chartType={'PieChart'}
                                                    data={loadTenderStopPerformance(data)}
                                                    options={{
                                                        title: `Load Tender Stop Performance`,
                                                        colors: ['orange', 'green', 'yellow', 'red'],
                                                    }}
                                                    width="500px"
                                                    height="500px"
                                                />
                                            </Col>
                                            <Col xs="auto">
                                                <Chart
                                                    chartType={'PieChart'}
                                                    data={loadTenderCoverageChart(data)}
                                                    options={{
                                                        title: `Load Tender Coverage`,
                                                        colors: ['orange', 'green'],
                                                    }}
                                                    width="500px"
                                                    height="500px"
                                                />
                                            </Col>

                                        </Row>
                                    }
                                </>
                                :
                                <h3>No data</h3>
                            }
                        </>
                    }
                </Card.Body>
            </Card>
        </Container>
    )
}

export default LoadTenderPerformanceUSPS;