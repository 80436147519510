import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Api from "../../utils/Api";
import { useAuth } from "../../context/auth";
import { useRole } from "../../context/role";
import { Link } from "react-router-dom";


function DriverProfile() {
    const { user, idToken } = useAuth();
    const [load, setLoad] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [loadtenders, setLoadTenders] = useState([])

    async function fetchLoadTenders() {
        try {
            // const tenders = await Api.get(`/`, idToken);
            // setLoadTenders(tenders);
            setAlerts([...alerts, { variant: 'success', message: 'Bypassed, not currently fetching', }])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching new users.', }])
        }
    }

    useEffect(() => {
        if (!load) {
            fetchLoadTenders();
            // fetchTimecards();
        }
        return () => setLoad(true)
    }, [load])

    const { userRole, checkNavPermissions } = useRole();

    const features = [
        { href: "/driver/profile", icon: '', text: "Scheduled Loads", detail: 'My upcoming load tenders.' },
        { href: "/driver/profile", icon: '', text: "Covered Loads", detail: 'My load tender history.' },
        { href: "/driver/profile", icon: '', text: "Timecards", detail: 'My timecards' },
        { href: "/driver/profile", icon: '', text: "Accident Report", detail: 'My accident reports' },
        { href: "/driver/profile", icon: '', text: "Injury Report", detail: 'My injury reports' },
    ]

    const allowedFeatures = checkNavPermissions(userRole, features)


    const cardStyle = {
        maxWidth: '250px',
    }

    const colStyle = {
        margin: '0.25em',
    }

    return (
        <Container fluid>
            <Row>
                {allowedFeatures.map((feature, i) => (
                    <Col xs="auto" style={colStyle}>
                        <Card style={cardStyle}>
                            <Card.Header>
                                <b>{feature.icon} {feature.text}</b>
                            </Card.Header>
                            <Card.Body key={i}>
                                <Card.Text>
                                    {feature.detail}
                                </Card.Text>
                                <Link to={feature.href}>Go to {feature.text}</Link>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    )
}

export default DriverProfile;