import { useEffect, useState } from "react";
import AlertDisplay from "../../components/AlertDisplay";
import { FaArrowDown, FaArrowUp, FaBuilding, FaPaperPlane, FaPhone, FaTrash } from "react-icons/fa";
import { BooleanToggle } from "../../components/BooleanToggle";
import { Container, Card, Table, Col, Row, Badge, Button, Form, ToggleButton } from 'react-bootstrap'
import Api from "../../utils/Api";
import { useAuth } from "../../context/auth";
import FrequencyCalendarChart from "../../components/FrequencyCalendarChart";
import { TIMEZONE_OPTIONS } from '../../context/appdata';
import { useSettings } from "../../context/settings";
import { useParams } from "react-router-dom";
import { DateTime } from 'luxon';


const initial204 = {
    DraftName: '',
    Frequency: {},
    TransactionId: '',
    CustomerISA: '',
    SCAC: '',
    Type: '',
    TypeDesc: '',
    RespondBy: '',
    RespondByTimeZone: '',
    Equipment: '',
    EquipmentNumber: '',
    TotalPiecesOfEquipment: '',
    EquipLength: '',
    TransportationMethod: '',
    Pallets: '',
    Rate: '',
    Weight: '',
    WeightUnit: '',
    PaymentMethod: '',
    MiscReferenceNum: '',
    BillTo: {},
    Contact: {},
    Note: '',
    References: [],
    Stops: [],
    loadtenderLifeCycleStatus: '',
    createAt: '',
    updatedAt: '',
    frequency: {},
    company: {},
    customer: {},
    miles: '',
    rate: '',
    hours: '',
}

function LoadTenderNewLoad({ form }) {
    const { id } = useParams();
    const [shouldLoad, setShouldLoad] = useState(true)
    const { settings } = useSettings();
    const { idToken } = useAuth();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    const [draft204, setDraft204] = useState(initial204);
    const [stops, setStops] = useState([]);

    const [toggle, setToggle] = useState(false);
    const toggleState = { toggle, setToggle }

    const [dateToggle, setDateToggle] = useState(false);
    const dateToggleState = { toggle: dateToggle, setToggle: setDateToggle }
    async function createDraftLoadTender() {
        try {
            await Api.post(`/loadtenders/drafts`, draft204, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Draft saved' }])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Draft was NOT saved.' }])
        }
    }

    async function updateDraftLoadTender() {
        try {
            await Api.patch(`/loadtenders/draft/${id}`, draft204, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Draft updated' }])
        } catch (error) {
            console.log(error)
            setAlerts([...alerts, { variant: 'warning', message: 'Draft was NOT updated.' }])
        }
    }

    async function createLoadTenders() {
        try {
            // check if load has required fields
            console.log(draft204)
            const res = await Api.post(`/loadtenders/draft/${id}/generate`, draft204, idToken);
            console.log(res)
            setAlerts([...alerts, { variant: 'success', message: 'Load Tender created', res }])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Load Tender was NOT created.' }])
        }
    }

    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState();
    useEffect(() => {
        const updateDraft = {
            ...draft204,
            customer: customer,
            CustomerISA: customer?.cisa,
            BillTo: {
                "Name": customer?.name,
                "Address1": customer?.address1,
                "City": customer?.city,
                "State": customer?.state,
                "Zip": customer?.zip,
                "Country": customer?.country,
                "Type": null,
                "Code": null
            },
        }
        setDraft204(updateDraft)
    }, [customer])

    const [didFetchCustomers, setDidFetchCustomers] = useState();

    async function fetchCustomers() {
        try {
            const queryStr = new URLSearchParams({ isActive: 'true' }).toString()
            const data = await Api.get(`/customers?${queryStr}`, idToken)
            setCustomers(data)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching customers.' }])

        } finally {
            setDidFetchCustomers(true)
        }
    }
    useEffect(() => {
        if (!didFetchCustomers) {
            fetchCustomers()
        }
    }, [didFetchCustomers])
    //
    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState();
    useEffect(() => {
        const updateDraft = {
            ...draft204,
            company: company,
            SCAC: company?.scac,
        }
        setDraft204(updateDraft)
    }, [company])





    const [didFetchCompanies, setDidFetchCompanies] = useState();
    async function fetchCompanies() {
        try {
            const queryStr = new URLSearchParams({ isActive: 'true' }).toString()
            const data = await Api.get(`/companies?${queryStr}`, idToken)
            setCompanies(data)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching companies.' }])

        } finally {
            setDidFetchCompanies(true)
        }
    }
    useEffect(() => {
        if (!didFetchCompanies) {
            fetchCompanies()
        }
    }, [didFetchCompanies])
    //
    const [equipment, setEquipment] = useState('');
    useEffect(() => {
        setDraft204({ ...draft204, Equipment: equipment })
    }, [equipment])
    //
    const [frequencies, setFrequencies] = useState([]);
    const [frequency, setFrequency] = useState();
    useEffect(() => {
        setDraft204({ ...draft204, frequency: frequency })
    }, [frequency]);

    const [frequencyOps, setFrequencyOps] = useState();
    const [didFetchFrequencies, setDidFetchFrequencies] = useState();
    async function fetchFrequencies() {
        try {
            const data = await Api.get('/frequencies/live', idToken)
            setFrequencies(data)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching frequencies.' }])

        } finally {
            setDidFetchFrequencies(true)
        }
    }
    useEffect(() => {
        if (!didFetchFrequencies) {
            fetchFrequencies()
        }
    }, [didFetchFrequencies])
    //
    const [locations, setLocations] = useState([])
    const [didFetchLocations, setDidFetchLocations] = useState();
    async function fetchLocations() {
        try {
            const data = await Api.get('/locations/live', idToken)
            setLocations(data)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching locations.' }])
        } finally {
            setDidFetchLocations(true)
        }
    }
    useEffect(() => {
        if (!didFetchLocations) {
            fetchLocations()
        }
    }, [didFetchLocations])

    const [toggleLocation, setToggleLocation] = useState();
    const toggleLocationState = { toggle: toggleLocation, setToggle: setToggleLocation }
    const [fullAddress, setFullAddress] = useState('');
    const fullAddressState = { fullAddress, setFullAddress }

    async function handleFrequencyChange(freq) {
        try {
            if (startOps && endOps) {
                const startop = new Date(startOps).toISOString().split('T')[0];
                const endop = new Date(endOps).toISOString().split('T')[0];
                const operations = await Api.get(`/frequency/${freq._id}/digest/${startop}/${endop}`, idToken);
                setFrequency(freq)
                setFrequencyOps(operations)
            }
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching frequency.' }])
        }
    }


    function updateStop(index, key, value) {
        const stopUpdate = stops
        stopUpdate[index][key] = value
        setStops([...stopUpdate])
    }

    const [rate, setRate] = useState();
    useEffect(() => {
        console.log('Rate update')
        setDraft204({ ...draft204, Rate: (rate * 100).toFixed(0), })
    }, [rate])
    const [miles, setMiles] = useState();
    useEffect(() => {
        console.log('Miles update')
        setDraft204({ ...draft204, Distance: `TOTAL DISTANCE = ${miles} MI`, miles: miles })
    }, [miles])
    const [hours, setHours] = useState('');
    const [calculatedHours, setCalculatedHours] = useState('');
    const [calculatedDwell, setCalculatedDwell] = useState('');
    useEffect(() => {
        console.log('Hours update')
        setDraft204({ ...draft204, hours: hours })
    }, [hours])

    async function updateFrequencyOps() {
        try {
            if (startOps && endOps) {
                const startop = new Date(startOps).toISOString().split('T')[0];
                const endop = new Date(endOps).toISOString().split('T')[0];
                const operations = await Api.get(`/frequency/${frequency._id}/digest/${startop}/${endop}`, idToken);
                setFrequencyOps(operations)
            }
        } catch {
            // no update
            console.log('Ops update error')
        }
    }
    const [startOps, setStartOps] = useState('');
    const [endOps, setEndOps] = useState('');

    useEffect(() => {
        console.log('Ops update')
        updateFrequencyOps()
        setDraft204({ ...draft204, startOps: startOps, endOps: endOps })
    }, [startOps, endOps])

    useEffect(() => {
        console.log('Stops update')
        setDraft204({ ...draft204, Stops: stops })
        calculateRouteSopAndDwell()
    }, [stops])

    const [draftName, setDraftName] = useState('');
    useEffect(() => {
        setDraft204({ ...draft204, DraftName: draftName })
    }, [draftName]);

    const [fetchOps, setFetchOps] = useState(false)
    useEffect(() => {
        if (fetchOps) {
            updateFrequencyOps()
        }
        return () => setFetchOps(false)
    }, [fetchOps])


    async function fetchDraft() {
        console.log('fetch draft')
        try {
            const currentDraft = await Api.get(`/loadtenders/draft/${id}`, idToken);
            if (currentDraft) {
                setDraft204(currentDraft)
                setDraftName(currentDraft?.DraftName)
                setStops(currentDraft?.Stops)
                setCustomer(currentDraft?.customer)
                setCompany(currentDraft?.company)
                setStartOps(currentDraft?.startOps)
                setEndOps(currentDraft?.endOps)
                setFrequency(currentDraft?.frequency)
                setRate(currentDraft?.Rate)
                setMiles(currentDraft?.miles)
                setHours(currentDraft?.hours)
                setEquipment(currentDraft?.Equipment)
                setFetchOps(true)
            }

        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Failed to fetch draft.', }])
        } finally {
            setShouldLoad(false)
        }
    }

    function areTimesFormattedCorrectly(times) {
        // const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2} (CT|MT|PT|ET|America\/New_York|America\/Chicago|America\/Denver|America\/Los_Angeles)$/;

        const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(Z| ([A-Z]{2}|America\/(New_York|Chicago|Denver|Los_Angeles)))$/;

        return times.every(time => regex.test(time));
    }

    // function areTimesChronological(times) {
    //     for (let i = 0, j = i + 1; i < times.length - 1; i++, j++) {
    //         const timeString1 = times[i];
    //         const timeString2 = times[j];
    //         const [time1, tz1] = timeString1.split(' ');
    //         const [time2, tz2] = timeString2.split(' ');
    //         const currentTime = DateTime.fromISO(time1, { zone: tz1 });
    //         const nextTime = DateTime.fromISO(time2, { zone: tz2 });
    //         if (currentTime > nextTime) {
    //             setAlerts([...alerts, { variant: 'warning', message: 'Times are not chronologically ordered.' }])
    //             return false;
    //         }
    //     }
    //     return true;
    // }

    function calculateRouteSopAndDwell() {
        const baseDate = DateTime.now().toISODate(); // Use today's date as the base
        const times = stops.flatMap(stop => [`${baseDate}T${stop.startTime} ${stop.timezone}`, `${baseDate}T${stop.endTime} ${stop.timezone}`]);

        if (areTimesFormattedCorrectly(times)) {
            const diffs = [];
            const dwell = [];
            for (let i = 0; i < times.length - 1; i++) {
                const [dateTime1, tz1] = times[i].split(' ');
                const [dateTime2, tz2] = times[i + 1].split(' ');
                let date1 = DateTime.fromISO(dateTime1, { zone: tz1 });
                let date2 = DateTime.fromISO(dateTime2, { zone: tz2 });

                // Check for the same time and adjust for 24-hour duration if they are identical
                if (date2 <= date1) {
                    date2 = date1.plus({ days: 1 });
                }

                const diff = date2.diff(date1).milliseconds;
                diffs.push(diff);
                if (i % 2 === 0) {
                    dwell.push(diff); // Assuming dwell calculation between start-end of the same stop
                }
            }

            const totalDiff = diffs.reduce((acc, curr) => acc + curr, 0);
            const totalDwell = dwell.reduce((acc, curr) => acc + curr, 0);
            const totalHours = totalDiff / 3600000;
            const totalDwellHours = totalDwell / 3600000;

            const timelapse = totalHours.toFixed(2);
            const dwellapse = totalDwellHours.toFixed(2);
            setCalculatedHours(timelapse);
            setCalculatedDwell(dwellapse);
        } else {
            setCalculatedHours('NaN');
            setCalculatedDwell('NaN');
        }
    }

    useEffect(() => {
        if (shouldLoad && id) {
            fetchDraft()
        }
    }, [id, shouldLoad])


    function setSingleDay(date) {
        setStartOps(date)
        setEndOps(date)
    }

    useEffect(() => {
        console.log('Update operations')
        if (dateToggle === false) {
            setEndOps(startOps)
        }
        setFetchOps(true)
    }, [dateToggle])


    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <br />
            <Container>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col>
                                <h3>
                                    {!toggle ? 'Draft Load Tender' : 'Create Load Tender'}
                                </h3>
                            </Col>
                            {form === 'update' &&
                                <Col xs="auto">
                                    <Row>
                                        <Col xs="auto">
                                            Toggle Type:
                                        </Col>
                                        <Col xs="auto">
                                            <BooleanToggle toggleState={toggleState} trueBg="#bceebc" />
                                        </Col>
                                    </Row>
                                </Col>
                            }
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Col>
                            Name
                            <Form.Control
                                type="text"
                                placeholder="Name..."
                                value={draftName}
                                onChange={e => setDraftName(e.target.value)}
                            />
                        </Col>
                    </Card.Body>
                    <Card.Body>
                        <Row>
                            <Col xs="12" md="6">
                                <Row>
                                    <Col xs="auto">
                                        Customer :
                                        <Form.Control as="select" value={JSON.stringify(customer)} onChange={(e) => e.target.value !== '' && setCustomer(JSON.parse(e.target.value))}>
                                            <option value="">Customer</option>
                                            {customers.map((cst, j) => (
                                                <option key={j} value={JSON.stringify(cst)}>{cst.name}</option>
                                            ))}
                                        </Form.Control>
                                        {customer &&
                                            <>
                                                Contacts : <br />
                                                <Row>

                                                    {customer?.contacts && customer.contacts.map((contact, k) => (
                                                        <Col key={k} xs="auto">
                                                            <FaPhone /> {(contact.phone)}<br />
                                                            <FaPaperPlane />{(contact.email)}<br />
                                                            <FaBuilding />{(contact.address)}<br />
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </>
                                        }
                                    </Col>

                                </Row>
                            </Col>
                            <Col xs="12" md="6">
                                <Row>
                                    <Col xs="auto">
                                        Carrier :
                                        <Form.Control as="select" value={JSON.stringify(company)} onChange={(e) => e.target.value !== '' && setCompany(JSON.parse(e.target.value))}>
                                            <option value="">Company</option>
                                            {companies.map((cmp, j) => (
                                                <option key={j} value={JSON.stringify(cmp)}>{cmp.name}</option>
                                            ))}
                                        </Form.Control>
                                        {company &&
                                            <>
                                                Contact : <br />
                                                <FaPhone /> {(company.phone)}<br />
                                                <FaPaperPlane /> {(company.email)}<br />
                                                <FaBuilding />{(company.address)}<br />
                                            </>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col xs="auto">
                                Equipment :
                                <Form.Control
                                    type="text"
                                    value={equipment}
                                    onChange={(e) => setEquipment(e.target.value)}
                                />
                            </Col>
                            <Col xs="auto">
                                Frequency :
                                <Form.Control
                                    as="select"
                                    value={JSON.stringify(frequency)}
                                    onChange={e => e.target.value !== '' && handleFrequencyChange(JSON.parse(e.target.value))}
                                >
                                    <option value="">Frequency</option>
                                    {frequencies.map((freq, j) => (
                                        <option key={j} value={JSON.stringify(freq)}>{freq.name}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col xs="auto" style={{ alignItems: 'center' }}>
                                Range
                                <BooleanToggle toggleState={dateToggleState} />
                            </Col>
                            {!dateToggle ?
                                <>
                                    <Col xs="auto">
                                        Start Ops:<br />
                                        <Form.Control type="date" value={startOps} onChange={e => setStartOps(e.target.value)} />
                                    </Col>
                                    <Col xs="auto">
                                        End Ops:<br />
                                        <Form.Control type="date" value={endOps} onChange={e => setEndOps(e.target.value)} />
                                    </Col>
                                </>
                                :
                                <>
                                    <Col xs="auto">
                                        Day:<br />
                                        <Form.Control type="date" value={startOps} onChange={e => setSingleDay(e.target.value)} />
                                    </Col>
                                </>
                            }
                            <Col xs="auto">
                                Frequency Ops:<br />
                                {(frequency && frequencyOps && frequencyOps.length > 0) ?
                                    <>{frequencyOps.reduce((a, b) => a + b[1], 0)}/{frequencyOps.length}</>
                                    :
                                    <>...</>
                                }
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                {
                                    (frequency?.name && frequencyOps && frequencyOps.length > 0) ?
                                        <FrequencyCalendarChart
                                            title={`${frequency?.name}`}
                                            opdata={frequencyOps}
                                            adjust={Math.ceil(frequencyOps.length / 366).toFixed(0)}
                                        />
                                        :
                                        <h3><i>Select a frequency to display chart.</i></h3>
                                }
                            </Col>
                        </Row>
                    </Card.Body>
                    <br />
                    <Card.Header>
                        <h3>Route</h3>
                    </Card.Header>
                    <Card.Body>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th style={{ padding: 0, textAlign: 'center' }}>
                                        <Badge bg="primary" onClick={() => setStops([...stops, { via: {}, startTime: '', endTime: '', timezone: '', action: '' }])}>Add Stop</Badge>
                                    </th>
                                    <th style={{ padding: 0, }}>Trip</th>
                                    <th style={{ padding: 0, }}>Stop Location</th>
                                    <th style={{ padding: 0, }}>Arrive</th>
                                    <th style={{ padding: 0, }}>Depart</th>
                                    <th style={{ padding: 0, }}>Timezone</th>
                                    <th style={{ padding: 0, }}>Action</th>
                                    <th style={{ padding: 0, }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {stops && stops.length > 0 && stops.map((via, i) => (
                                    <tr key={i}>
                                        <td style={{ padding: 0, textAlign: 'center', alignItems: 'center' }}>{i + 1}</td>
                                        <td style={{ padding: 0, alignItems: 'center' }}>
                                            <input
                                                type="text"
                                                value={via.tripNumber}
                                                onChange={(e) => updateStop(i, 'tripNumber', e.target.value,)}
                                                style={{ width: '100%', height: '1.5em' }}
                                            />
                                        </td>
                                        <td style={{ padding: 0, alignItems: 'center' }}>
                                            <select
                                                style={{ width: '100%', height: '1.5em' }}
                                                value={JSON.stringify(stops[i].via)} onChange={(e) => updateStop(i, 'via', JSON.parse(e.target.value))} >
                                                <option value="">Location</option>
                                                {locations.map((location, j) => (
                                                    <option key={j} value={JSON.stringify(location)}>{location.name}</option>
                                                ))}
                                            </select>
                                        </td>
                                        <td style={{ padding: 0, alignItems: 'center' }}>
                                            <input style={{ width: '100%', height: '1.5em' }} type="time" value={via.startTime} onChange={(e) => updateStop(i, 'startTime', e.target.value,)} />
                                        </td>
                                        <td style={{ padding: 0, alignItems: 'center' }}>
                                            <input style={{ width: '100%', height: '1.5em' }} type="time" value={via.endTime} onChange={(e) => updateStop(i, 'endTime', e.target.value,)} />
                                        </td>
                                        <td style={{ padding: 0, alignItems: 'center' }}>
                                            <select
                                                style={{ width: '100%', height: '1.5em' }}
                                                value={via.timezone}
                                                defaultValue={settings.timezone}
                                                onChange={e => updateStop(i, 'timezone', e.target.value)} >
                                                <option value="">Select</option>
                                                {TIMEZONE_OPTIONS.map((tz, j) => (
                                                    <option key={j} value={tz.name}>{tz.code}</option>
                                                ))}
                                            </select>
                                        </td>
                                        <td style={{ padding: 0, alignItems: 'center' }}>
                                            <select style={{ width: '100%', height: '1.5em' }} value={via.action} onChange={(e) => updateStop(i, 'action', e.target.value)} >
                                                <option value="">Action</option>
                                                <option value="CL">Load</option>
                                                <option value="PU">Part Unload</option>
                                                <option value="CU">Complete Unload</option>
                                            </select>
                                        </td>
                                        <td style={{ padding: 0, alignItems: 'center', verticalAlign: 'top' }}>
                                            <Badge bg="secondary" style={{ margin: 0 }}> <FaArrowUp /></Badge>
                                            <Badge bg="secondary " style={{ margin: 0 }}> <FaArrowDown /></Badge>
                                            <Badge bg="warning" style={{ margin: 0 }} onClick={() => setStops([...stops.filter((_, index) => index !== i)])}><FaTrash /></Badge>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                    <Card.Body>
                        <Row>
               
                            <Col xs="auto">
                                Calculated Driving :
                                <Form.Control
                                    type="text"
                                    value={calculatedHours - calculatedDwell}
                                    disabled
                                />
                                <div>
                                    {`(x Frequency) = `}
                                    {(calculatedHours && calculatedDwell && frequency && frequencyOps && frequencyOps.length > 0) ?
                                        <>{(calculatedHours - calculatedDwell) * frequencyOps.reduce((a, b) => a + b[1], 0)}</>
                                        :
                                        <>-</>
                                    }
                                </div>
                            </Col>
                            <Col xs="auto">
                                Calculated Dwell :
                                <Form.Control
                                    type="text"
                                    value={calculatedDwell}
                                    disabled
                                />
                                <div>
                                    {`(x Frequency) = `}
                                    {(calculatedDwell && frequency && frequencyOps && frequencyOps.length > 0) ?
                                        <>{calculatedDwell * frequencyOps.reduce((a, b) => a + b[1], 0)}</>
                                        :
                                        <>-</>
                                    }
                                </div>
                            </Col>
                            <Col xs="auto">
                                Calculated Hours :
                                <Form.Control
                                    type="text"
                                    value={calculatedHours}
                                    disabled
                                />
                                <div>
                                    {`(x Frequency) = `}
                                    {(calculatedHours && frequency && frequencyOps && frequencyOps.length > 0) ?
                                        <>{calculatedHours * frequencyOps.reduce((a, b) => a + b[1], 0)}</>
                                        :
                                        <>-</>
                                    }
                                </div>
                            </Col>
                            <Col xs="auto">
                                Pay Hours :
                                <Form.Control type="text" value={hours} onChange={e => setHours(e.target.value)} />
                                <div>
                                    {`(x Frequency) = `}
                                    {(hours && frequency && frequencyOps && frequencyOps.length > 0) ?
                                        <>{(hours * frequencyOps.reduce((a, b) => a + b[1], 0)).toLocaleString('en-US')}</>
                                        :
                                        <>-</>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col xs="auto">
                                Trip Miles :
                                <Form.Control type="text" value={miles} onChange={e => setMiles(e.target.value)} />
                                <div>
                                    {`(x Frequency) = `}
                                    {(miles && frequency && frequencyOps && frequencyOps.length > 0) ?
                                        <>{(miles * frequencyOps.reduce((a, b) => a + b[1], 0)).toLocaleString('en-US')}</>
                                        :
                                        <>-</>
                                    }
                                </div>
                            </Col>
                            <Col xs="auto">
                                Trip Rate :
                                <Form.Control type="text" value={rate} onChange={e => setRate(e.target.value)} />
                                <div>
                                    {`(x Frequency) = `}
                                    {(rate && frequency && frequencyOps && frequencyOps.length > 0) ?
                                        <>{(rate * frequencyOps.reduce((a, b) => a + b[1], 0)).toLocaleString('en-US')}</>
                                        :
                                        <>-</>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col xs="auto">
                                Calculated Rate Per Mile :
                                <Form.Control
                                    type="text"
                                    value={(rate/miles).toFixed(2)}
                                    disabled
                                />

                            </Col>
                            <Col xs="auto">
                                Calculated Rate Per Hour:
                                <Form.Control
                                    type="text"
                                    value={(rate/hours).toFixed(2)}
                                    disabled
                                />

                            </Col>
                        </Row>
            
                    </Card.Body>
                    <br />
                    {toggle &&
                        <>
                            <Card.Header>
                                <h3>Forecast</h3>
                            </Card.Header>
                            <Card.Body>
                                <p>List of load tenders built from this schedule.</p>
                            </Card.Body>
                        </>

                    }
                    <Card.Body>
                        <Row>
                            {!toggle ?
                                <>
                                    {(form === 'create' &&
                                        <>
                                            <Col xs="auto">
                                                <Button variant="primary" onClick={() => createDraftLoadTender()}>Save Draft</Button> * This is a draft that does NOT get published as a load tender.
                                            </Col>
                                        </>
                                    )}
                                    {(form === 'update' &&
                                        <>
                                            <Col xs="auto">
                                                <Button variant="primary" onClick={() => updateDraftLoadTender()}>Update Draft</Button> * This is a draft that does NOT get published as a load tender.
                                            </Col>
                                        </>
                                    )}
                                </>
                                :
                                <Col xs="auto">
                                    <Button variant="success" onClick={() => createLoadTenders()}>Create Load Tenders</Button> * This load will be created for the operation period defined and according to the applied frequency.
                                </Col>
                            }
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </Container >
    )
}


export default LoadTenderNewLoad;