import { Container, Card, Row, Col, Spinner, Button, OverlayTrigger, Tooltip, Form, Badge, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import React, { useState, useEffect } from 'react'
import { useAuth } from '../../context/auth';
import { useSettings } from '../../context/settings'
import { FaArrowCircleDown, FaArrowLeft, FaArrowRight, FaEdit, FaSearch, FaSync, } from "react-icons/fa";
import { MdArrowBackIos, MdArrowForwardIos, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import { findUSPSNASSCode, findUSPSNASSCodeState, formatUSPSDateTime } from "../../context/uspsdata";
import { adjustStringLength } from "../../utils/String.helper";
import AlertDisplay from "../../components/AlertDisplay";
import { formatDateFromDateStr, formatDateStrToMMDDYYHHMM, getDayOfWeekFromDate } from "../../utils/DateTimeFormat.helper";
import { multidimensionalGroupBy } from "../../utils/Array.helper";
import LogisticsLoading from "../../components/LogisticsLoading";
import { IoTodayOutline } from "react-icons/io5";
import Select from 'react-select';
import { AutoSizer, List } from 'react-virtualized';
import { Link } from "react-router-dom";
import { generateExcel } from "../../utils/FileGenerator";
import { BsFiletypeXlsx } from "react-icons/bs";

//todo dedeup
const today = new Date();  // today based on the clock
const tomorrow = new Date(today); // based on today value
const yesterday = new Date(today); // based on today value
const minusSeven = new Date(today); // minus 7 days
const minusFourteen = new Date(today); // minus 14 days
const minusThirty = new Date(today); // minus 30 days
const minusSixty = new Date(today); // minus 30 days
const minusNinty = new Date(today); // minus 30 days
const plusSeven = new Date(today); // based on today value, plus 7 days
const plusFourteen = new Date(today); // based on today value, plus 14 days
const plusThirty = new Date(today); // based on today value, plus 30 days
const plusSixty = new Date(today); // based on today value, plus 30 days
const plusNinty = new Date(today); // based on today value, plus 30 days
const lastSunday = new Date(today); // based on today value, the last week's Sunday
const lastSaturday = new Date(today);// based on today value, the last week's Saturday
const thisSunday = new Date(today); // based on today value, the current week's Sunday
const thisSaturday = new Date(today);// based on today value, the current week's Saturday
const nextSunday = new Date(today);// based on today value, the next week's Sunday
const nextSaturday = new Date(today);// based on today value, the next week's Saturday
const newyears = new Date(today); // current calendar's first day of the year
const newyearseve = new Date(today); // current calendar's last day of the year
const currentYear = today.getFullYear();
const currentMonth = today.getMonth();
const nextMonth = new Date(today);
nextMonth.setMonth(currentMonth + 1);
const firstdayofthemonth = new Date(currentYear, currentMonth, 1);
const lastdayofthemonth = new Date(currentYear, currentMonth + 1, 0);
const firstdayoflastmonth = new Date(currentYear, currentMonth - 1, 1);
const lastdayoflastmonth = new Date(currentYear, currentMonth, 0);
const firstdayofnextmonth = new Date(currentYear, currentMonth + 1, 1);
const lastDayOfNextMonth = new Date(nextMonth);
lastDayOfNextMonth.setMonth(nextMonth.getMonth() + 1, 0);

tomorrow.setDate(today.getDate() + 1);
yesterday.setDate(today.getDate() - 1);
minusSeven.setDate(today.getDate() - 7);
minusFourteen.setDate(today.getDate() - 14);
minusThirty.setDate(today.getDate() - 30);
minusSixty.setDate(today.getDate() - 60);
minusNinty.setDate(today.getDate() - 90);
plusSeven.setDate(today.getDate() + 7);
plusFourteen.setDate(today.getDate() + 14);
plusThirty.setDate(today.getDate() + 30);
plusSixty.setDate(today.getDate() + 60);
plusNinty.setDate(today.getDate() + 90);
const year = new Date().getFullYear(); // Current year
const fiscalQuarters = {
    q1: { start: new Date(year, 0, 1), end: new Date(year, 2, 31) },
    q2: { start: new Date(year, 3, 1), end: new Date(year, 5, 30) },
    q3: { start: new Date(year, 6, 1), end: new Date(year, 8, 30) },
    q4: { start: new Date(year, 9, 1), end: new Date(year, 11, 31) }
};


// Calculate the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
const currentDayOfWeek = today.getDay();

// Calculate the date for the last week's Sunday and Saturday
lastSunday.setDate(today.getDate() - (currentDayOfWeek + 7));
lastSaturday.setDate(today.getDate() - (currentDayOfWeek + 1));

// Calculate the date for the current week's Sunday and Saturday
thisSunday.setDate(today.getDate() - currentDayOfWeek);
thisSaturday.setDate(today.getDate() + (6 - currentDayOfWeek));

// Calculate the date for the next week's Sunday and Saturday
nextSunday.setDate(today.getDate() + (7 - currentDayOfWeek));
nextSaturday.setDate(today.getDate() + (13 - currentDayOfWeek));

// Calculate the first day and last day of the current year
newyears.setMonth(0); // January (0 index)
newyears.setDate(1);

newyearseve.setMonth(11); // December (0 index)
newyearseve.setDate(31);

function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    return `${year}-${month}-${day}`;
}

const initialQuery = {
    start: formatDate(today),
    end: formatDate(today),
    report: 'All',
    loadtenderLifeCycleStatus: 'All',
    contract: 'All',
    state: 'All',
    city: 'All',
    driver: 'All',
    carrier: 'All',
    page: 1,
    pageSize: 10
}

function millisecondsToHHMM(milliseconds) {
    const hours = Math.floor(milliseconds / 3600000);
    milliseconds %= 3600000;
    const minutes = Math.floor(milliseconds / 60000);
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
}

function googleChartDataKeyCount(shipments = [], key = [], headers = []) {
    const Groups = multidimensionalGroupBy(shipments, key);
    const GraphData = Object.keys(Groups).sort().map(key => ([`${key} (${Groups[key].length})`, Groups[key].length]));
    const ChartData = [headers, ...GraphData];
    return ChartData
}

function LoadTenderAccountingUSPS({ feature }) {
    const { idToken } = useAuth();
    const { settings } = useSettings();

    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    const [set, didSet] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loadtenders, setLoadTenders] = useState([])
    const [loadtenderTimelineData, setLoadTenderTimelineData] = useState();
    const [loadtenderCoverageChartData, setLoadtenderCoverageChartData] = useState();
    const [contractChartData, setContractChartData] = useState();
    const [equipmentChartData, setEquipmentChartData] = useState();
    const [stateChartData, setStateChartData] = useState();
    const [dateChartData, setDateChartData] = useState();
    const [dowChartData, setDowChartData] = useState();

    const [search, setSearch] = useState();
    const [query, setQuery] = useState(() => {
        const savedQuery = localStorage.getItem(`@mctms-loadtender-accounting-query`);
        return savedQuery !== null ? JSON.parse(savedQuery) : initialQuery;
    });

    const [contracts, setContracts] = useState([]);
    const [contractsFetched, setContractsFetch] = useState(false);
    // const [drivers, setDrivers] = useState([]);
    // const [driversFetched, setDriversFetch] = useState(false);


    function incrementDate(dateStr, days) {
        const date = new Date(dateStr);
        // Add 24 hours instead of adding one day
        date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * days); // Add 24 hours in milliseconds
        const incrementedDate = date.toISOString().split('T')[0]; // Format back to YYYY-MM-DD
        return incrementedDate;
    }

    function decrementDate(dateStr, days) {
        const date = new Date(dateStr);
        // Subtract 24 hours instead of subtracting one day
        date.setTime(date.getTime() - 24 * 60 * 60 * 1000 * days); // Subtract 24 hours in milliseconds
        const decrementedDate = date.toISOString().split('T')[0]; // Format back to YYYY-MM-DD
        return decrementedDate;
    }

    function resetDays() {
        const newQueryStart = formatDate(today);
        const newQueryEnd = formatDate(today);
        setQuery({ ...query, start: newQueryStart, end: newQueryEnd })
    }

    function addDay() {
        const newQueryStart = incrementDate(query.start, 1);
        const newQueryEnd = incrementDate(query.end, 1);
        setQuery({ ...query, start: newQueryStart, end: newQueryEnd })
    }

    function subtractDay() {
        const newQueryStart = decrementDate(query.start, 1);
        const newQueryEnd = decrementDate(query.end, 1);
        setQuery({ ...query, start: newQueryStart, end: newQueryEnd })
    }

    function addWeek() {
        const newQueryStart = incrementDate(query.start, 7);
        const newQueryEnd = incrementDate(query.end, 7);
        setQuery({ ...query, start: newQueryStart, end: newQueryEnd })
    }

    function subtractWeek() {
        const newQueryStart = decrementDate(query.start, 7);
        const newQueryEnd = decrementDate(query.end, 7);
        setQuery({ ...query, start: newQueryStart, end: newQueryEnd })
    }

    function daySpanUp() {
        const maxDifference = 30; // Maximum difference between end and start
        const difference = (new Date(query.end) - new Date(query.start)) / (24 * 60 * 60 * 1000);
        if (difference >= maxDifference) return;

        const newQueryEnd = incrementDate(query.end, 1);
        setQuery({ ...query, end: newQueryEnd });
    }

    function daySpanDown() {
        const minDifference = 0; // Minimum difference between end and start
        const difference = (new Date(query.end) - new Date(query.start)) / (24 * 60 * 60 * 1000);
        if (difference <= minDifference) return;

        const newQueryEnd = incrementDate(query.end, -1);
        setQuery({ ...query, end: newQueryEnd });
    }
    async function searchShipment() {
        if (search.trim() !== '') {
            const targetUrl = `/${feature}/` + search;
            window.open(targetUrl, '_blank');
        } else {
            setAlerts([...alerts, { variant: 'warning', message: 'Search cannot be empty.' }])
        }

    }


    async function fetchContracts() {
        setContractsFetch(false)
        try {
            const queryStr = new URLSearchParams({ start: query?.start, end: query?.end, isActive: true }).toString();
            const ediContracts = await Api.get(`/edi204/contracts?${queryStr}`, idToken);
            const cleanContracts = [...new Set(ediContracts.filter(Contract => String(Contract).length === 5).map(Contract => Contract))]
            setContracts(cleanContracts)
        } catch (err) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured getting Contracts.' }])
        } finally {
            setContractsFetch(true)
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchContracts()
        }, 1250);
        return () => clearTimeout(timer);
    }, [query?.start, query?.end])



    function cleanLoadData(data) {

        const schedule = adjustStringLength('S#', '2', '\u0020', false)
            + ' | ' + adjustStringLength('LOCATION', '25', '\u0020', true)
            + ' | ' + adjustStringLength('ARRIVE', '18', '\u0020', true)
            + ' | ' + adjustStringLength('DEPART', '18', '\u0020', true)
            + ' | ' + adjustStringLength('REASON', '18', '\u0020', true);

        function renderTypeName(type) {
            switch (type) {
                case ('00'):
                    return 'Original'
                case ('01'):
                    return 'Canceled'
                case ('04'):
                    return 'Updated'
                default:
                    return 'Unknown'
            }
        }


        const cleanedData = data.map((load, i) => ({
            '#': (i + 1),
            'ShipmentId': load.ShipmentId,
            'Rate': `${(Number(load?.data[load.data.length - 1]?.Rate) / 100).toFixed(2)}`,
            'Type': `${renderTypeName(load?.data[load.data.length - 1]?.Type)}`,
            'Status': `${load.Status}`,
            'Contract': load.Contract,
            'Trip': load.Trip,
            'Contract-Trip': `${load.Contract}-${load.Trip}`,
            'Start Time': `${formatDateStrToMMDDYYHHMM(load.StartTime)}`,
            'End Time': `${formatDateStrToMMDDYYHHMM(load.EndTime)}`,
            'SOP Time': `${millisecondsToHHMM(load.SOPmilli)}`,
            'Coverage': `${load?.Coverage.flatMap((innerArray) => innerArray.map((obj) => obj.name)).join('\n')}${load?.Carriers.flatMap((innerArray) => innerArray.map((obj) => obj.name.length) > 0 ? '| ' : '')}${load?.Carriers.flatMap((innerArray) => innerArray.map((obj) => obj.name)).join('\n')}`,
            'Schedule': `${schedule + '\n' + load?.Stops[0].map(via => `${adjustStringLength(via?.StopNum, '2', '\u0020', false)
                } | ${adjustStringLength((findUSPSNASSCode(via?.Name) + ', ' + findUSPSNASSCodeState(via?.Name)), '25', '\u0020', true)
                } | ${adjustStringLength(formatUSPSDateTime(via?.Name, via?.RequestedDate, settings.timezone), '18', '\u0020', true)
                } | ${adjustStringLength(formatUSPSDateTime(via?.Name, via?.LastRequestedDate, settings.timezone), '18', '\u0020', true)
                } | ${adjustStringLength(via?.ReasonDesc, '18', ' ', true)
                }`).join('\n')
                }`,
            'Equipment': load.Equipment,
            'State': load.State,
            'StopCt': load?.Stops.length,
            'Date': `${formatDateFromDateStr(load.StartTime)}`,
            'Day of Week': `${getDayOfWeekFromDate(load.StartTime)}`,
        }));

        return cleanedData;
    }


    function loadtenderContractTripTimeline(shipments) {
        const data = [];
        for (let i = 0; i < shipments.length; i++) {
            const shipment = shipments[i];
            const lifecycleStatus = shipment.Status;
            data.push({
                type: lifecycleStatus === 'Rejected' ? 'Rejected' : shipment['Type'],
                contract: shipment['Contract'],
                trip: shipment['Trip'],
                startdate: (shipment['Start Time']),
                enddate: (shipment['End Time']),
                ShipmentId: shipment['ShipmentId'],
                Coverage: shipment['Coverage']
            });
        }
        return data;
    }


    function loadTenderCoverageChart(shipments) {
        let unassigned = 0, covered = 0;

        for (const record of shipments) {
            if (record['Coverage'].trim() !== '') {
                covered++;
            } else {
                unassigned++;
            }
        }

        const chartData = [['Coverage', 'Load Tender Ct.'], [`Open (${unassigned})`, unassigned], [`Covered (${covered})`, covered]];
        return chartData;
    }

    const [loadingMessage, setLoadingMessage] = useState('');
    async function fetchLoadTenders() {
        setIsDataLoading(true)
        try {
            let cleanData = [];
            setLoadingMessage(`Processing`);
            const handlePageData = (data, currentPage, totalPages) => {
                // Clean and process the current batch of data
                const cleanedBatch = cleanLoadData(data);
                cleanData = [...cleanData, ...cleanedBatch];

                setLoadingMessage(`Processed page ${currentPage} of ${totalPages}`);
            };

            if (query.pageSize === '-1') {
                // Fetch all pages in batches
                await Api.getAllPages(`/loadtenders`, query, idToken, handlePageData);
                setLoadingMessage()
                setTotalRecords(cleanData.length);
                setTotalPages(1); // All data was fetched, so only 1 "page" in the end
            } else {
                const queryStr = new URLSearchParams(query).toString();
                const result = await Api.get(`/loadtenders?${queryStr}`, idToken);

                setTotalRecords(result?.totalRecords);
                setTotalPages(result?.totalPages);
                cleanData = cleanLoadData(result?.data);
            }

            setLoadTenders(cleanData);

            const contractTripTimeline = loadtenderContractTripTimeline(cleanData)
            setLoadTenderTimelineData(contractTripTimeline)
            const loadtenderCoverageChart = loadTenderCoverageChart(cleanData);
            setLoadtenderCoverageChartData(loadtenderCoverageChart)
            const contractChart = googleChartDataKeyCount(cleanData, ['Contract'], ['Contract', 'Contract Ct.'])
            setContractChartData(contractChart)
            const equipmentChart = googleChartDataKeyCount(cleanData, ['Equipment'], ['Equipment', 'Equipment Ct.'])
            setEquipmentChartData(equipmentChart)
            const stateChart = googleChartDataKeyCount(cleanData, ['State'], ['State', 'State Ct.'])
            setStateChartData(stateChart)
            const dateChart = googleChartDataKeyCount(cleanData, ['Date'], ['Date', 'Date Ct.'])
            setDateChartData(dateChart)
            const dowChart = googleChartDataKeyCount(cleanData, ['Day of Week'], ['Day of Week', 'Day of Week Ct.']);
            const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

            const sortedDowChart = dowChart.slice(1).sort((a, b) => {
                const dayA = a[0].split(' ')[0];
                const dayB = b[0].split(' ')[0];
                return daysOfWeek.indexOf(dayA) - daysOfWeek.indexOf(dayB);
            });

            setDowChartData([['Day of Week', 'Day of Week Ct.'], ...sortedDowChart]);
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching shipments.' }])
        } finally {
            didSet(true)
            setIsDataLoading(false)
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchLoadTenders();
            localStorage.setItem(`@mctms-loadtender-accounting-query`, JSON.stringify(query));
        }, 1250);

        return () => clearTimeout(timer);
    }, [query]);

    useEffect(() => {
        if (!set) {
            fetchLoadTenders();
            fetchContracts();
        }
    }, [set])

    useEffect(() => {
        setQuery({ ...query, page: 1 })
    }, [query.pageSize])


    const containerStyle = {
        margin: '0px',
        padding: '0px',
    }

    const cardStyle = {
        margin: '0px',
        border: '0px',
        borderRadius: '0px',
        height: '70vh'
    }

    const selectStyle = {
        fontSize: '1em',
        border: '1px solid #2b5499',
        color: '#2b5499',
        margin: '0.2em'
    }

    const selectActiveStyle = {
        fontSize: '1em',
        border: '1px solid red',
        color: '#2b5499',
        margin: '0.2em'
    }

    const buttonStyle = {
        fontSize: '.75em',
        border: '1px solid #2b5499',
        color: '#2b5499',
        margin: '0.2em'
    }

    const largeButtonStyle = {
        fontSize: '1em',
        border: '1px solid #2b5499',
        color: '#fff',
        margin: '0.2em'
    }

    const largeWarningButtonStyle = {
        fontSize: '1em',
        margin: '0.2em'
    }

    const contractOptions = [
        { value: 'All', label: 'All Contracts' },
        { value: '_TOPS_', label: 'USPS FA' },
        ...contracts.map(contract => ({ value: contract, label: contract }))
    ];

    const statusOptions = [
        { value: 'All', label: ' All Statuses' },
        { value: 'Accepted', label: 'Accepted' },
        { value: 'Brokered', label: 'Brokered' },
    ];

    const reportOptions = [
        { value: 'All', label: 'All Coverage' },
        { value: 'Open', label: 'Open' },
        { value: 'Covered', label: 'Covered' },
    ];

    const tdOddStyle = {
        fontFamily: 'monospace',
        textAlign: 'left',
        verticalAlign: 'top',
        borderBottom: '1px solid #ccc',
        backgroundColor: '#f9f9f9',

    }
    const tdStyle = {
        fontFamily: 'monospace',
        textAlign: 'left',
        verticalAlign: 'top',
        borderBottom: '1px solid #ccc',
    }

    const [itemSize, setItemSize] = useState(25); // Default item size

    // Adjust item size based on window width
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {  // Check for small screen size (768px is common for mobile)
                setItemSize(75);           // Double the size on small screens
            } else {
                setItemSize(25);            // Default size on larger screens
            }
        };

        // Initial check
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Container fluid style={containerStyle}>
            <AlertDisplay alertState={alertState} />
            <Card style={cardStyle}>
                <Card.Header>
                    <Row >
                        <Col xs="12" lg="4" style={{ alignItems: 'center', textAlign: 'center' }}>
                            <b>Load Tender Accounting</b>
                            {isDataLoading && <>
                                <Spinner size="sm" /> {query.pageSize === '-1' && ` ${loadingMessage} `}
                            </>}
                        </Col>
                        <Col xs="12" lg="4" style={{ alignItems: 'center', textAlign: 'center' }}>
                            <Row style={{ alignItems: 'center', }}>
                                <Col xs="auto">
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Total number of shipments.
                                        </Tooltip>}
                                    >
                                        <span>
                                            Shipments {totalRecords}
                                        </span>
                                    </OverlayTrigger>
                                </Col>
                                <Col xs="auto">
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Current page location.
                                        </Tooltip>}
                                    >
                                        <span>
                                            Page {query.page} / {totalPages}
                                        </span>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Go back a page
                                        </Tooltip>}
                                    >
                                        <button style={buttonStyle} onClick={() => setQuery({ ...query, page: query.page - 1 >= 1 ? query.page - 1 : query.page })}><FaArrowLeft /></button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Go forward a page
                                        </Tooltip>}
                                    >
                                        <button style={buttonStyle} onClick={() => setQuery({ ...query, page: query.page + 1 <= totalPages ? query.page + 1 : query.page })}><FaArrowRight /></button>
                                    </OverlayTrigger>
                                </Col>
                                <Col xs="auto">
                                    Page Size
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Total number of records to display.
                                        </Tooltip>}
                                    >
                                        <select style={selectStyle} value={query.pageSize} onChange={(e) => setQuery({ ...query, pageSize: e.target.value })}>
                                            <option value={-1}>All</option>
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Header>
                    <Row style={{ alignItems: 'center', }}>
                        <Col xs="auto" style={{ textAlign: 'center' }}>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>
                                    Days in view.
                                </Tooltip>}
                            >
                                <span style={{ margin: '0 1em' }}>Period: ({((new Date(query?.end) - new Date(query?.start)) / (24 * 60 * 60 * 1000)) + 1}) Days</span>
                            </OverlayTrigger>
                            <Row style={{ textAlign: 'center' }}>
                                <Col style={{ margin: 0, padding: 0 }}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Go backwards a week
                                        </Tooltip>}
                                    >
                                        <button style={buttonStyle} onClick={() => subtractWeek()}><MdKeyboardDoubleArrowLeft /></button>
                                    </OverlayTrigger>
                                </Col>
                                <Col style={{ margin: 0, padding: 0 }}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Go backwards a day
                                        </Tooltip>}
                                    >
                                        <button style={buttonStyle} onClick={() => subtractDay()}><MdArrowBackIos /></button>
                                    </OverlayTrigger>
                                </Col>
                                <Col style={{ margin: 0, padding: 0 }}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Go to today
                                        </Tooltip>}
                                    >
                                        <button style={buttonStyle} onClick={() => resetDays()}><IoTodayOutline /></button>
                                    </OverlayTrigger>
                                </Col>
                                <Col style={{ margin: 0, padding: 0 }}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Go forward a day
                                        </Tooltip>}
                                    >
                                        <button style={buttonStyle} onClick={() => addDay()}><MdArrowForwardIos /></button>
                                    </OverlayTrigger>
                                </Col>
                                <Col style={{ margin: 0, padding: 0 }}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Go forward a week
                                        </Tooltip>}
                                    >
                                        <button style={buttonStyle} onClick={() => addWeek()}><MdKeyboardDoubleArrowRight /></button>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" md="auto">
                            <Row>
                                <Col xs="12" md="auto">
                                    <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
                                        <Col xs="auto" style={{ marginRight: 0, paddingRight: 0 }}>
                                            <span style={{ fontSize: '0.75em', display: 'inline-block', transform: 'rotate(-90deg)' }}>Start</span>
                                        </Col>
                                        <Col style={{ marginLeft: 0, paddingLeft: 0 }}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>
                                                    Start date
                                                </Tooltip>}
                                            >
                                                <Form.Control type="date" style={selectStyle} value={query.start} onChange={e => setQuery({ ...query, start: e.target.value })} />

                                            </OverlayTrigger>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs="12" md="auto">
                                    <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }} >
                                        <Col xs="auto" style={{ marginRight: 0, paddingRight: 0 }}>
                                            <span style={{ fontSize: '0.75em', display: 'inline-block', transform: 'rotate(-90deg)' }}>End</span>
                                        </Col>
                                        <Col style={{ marginLeft: 0, paddingLeft: 0 }}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>
                                                    End date
                                                </Tooltip>}
                                            >
                                                <Form.Control type="date" style={selectStyle} value={query.end} onChange={e => setQuery({ ...query, end: e.target.value })} />
                                            </OverlayTrigger>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" md="auto">
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>
                                    Filter by status
                                </Tooltip>}
                            >
                                <Select
                                    value={statusOptions.find(option => option.value === query.status)}
                                    onChange={selectedOption => setQuery({ ...query, status: selectedOption.value })}
                                    options={statusOptions.sort((a, b) => a.label.localeCompare(b.label))}
                                    styles={{
                                        control: styles => ({ ...styles, ...(query.status === 'All' ? selectStyle : selectActiveStyle) }),
                                    }}
                                />
                            </OverlayTrigger>
                        </Col>
                        <Col xs="12" md="auto" >
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>
                                    Filter by report
                                </Tooltip>}
                            >
                                <Select
                                    value={reportOptions.find(option => option.value === query.report)}
                                    onChange={selectedOption => setQuery({ ...query, report: selectedOption.value })}
                                    options={reportOptions.sort((a, b) => a.label.localeCompare(b.label))}
                                    styles={{
                                        control: styles => ({ ...styles, ...(query.report === 'All' ? selectStyle : selectActiveStyle) }),
                                    }}
                                />
                            </OverlayTrigger>
                        </Col>
                        <Col xs="12" md="auto" >
                            {!contractsFetched ?
                                <Spinner size="sm" />
                                :
                                <>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Filter by contract
                                        </Tooltip>}
                                    >
                                        <Select
                                            value={contractOptions.find(option => option.value === query.contract)}
                                            onChange={selectedOption => setQuery({ ...query, contract: selectedOption.value })}
                                            options={contractOptions.sort((a, b) => a.label.localeCompare(b.label))}
                                            styles={{
                                                control: styles => ({ ...styles, ...(query.contract === 'All' ? selectStyle : selectActiveStyle) }),
                                            }}
                                        />
                                    </OverlayTrigger>
                                </>
                            }

                        </Col>
                        <Col xs="auto">
                            <Row>
                                <Col xs="auto">
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Enter a ShipmentId and click search
                                        </Tooltip>}
                                    >
                                        <Form.Control style={selectStyle} type="search" placeholder="Search ShipmentId..." onChange={(e) => setSearch(e.target.value)} />
                                    </OverlayTrigger>
                                </Col>
                                <Col xs="auto" style={{ margin: 0, padding: 0 }}>
                                    <Button style={largeButtonStyle} onClick={() => searchShipment()}><FaSearch /></Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="auto">
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>
                                    Reset to defaults.
                                </Tooltip>}
                            >
                                <Button variant="warning" style={largeWarningButtonStyle} onClick={() => setQuery(initialQuery)}>
                                    <FaSync />
                                </Button>
                            </OverlayTrigger>
                        </Col>
                        <Col xs="auto">
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>
                                    Fetch data again.
                                </Tooltip>}
                            >
                                <Button variant="primary" style={largeButtonStyle} onClick={() => didSet(false)}>
                                    <FaArrowCircleDown />
                                </Button>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                </Card.Header>
                <>
                    {!set ?
                        <LogisticsLoading message={'Loading...'} />
                        :
                        <>
                            <Row>
                                <Col>
                                    <Badge bg="success" onClick={() => generateExcel(loadtenders, `LoadTender-Accounting-Export-${query.start}-${query.end}`)}><BsFiletypeXlsx />Export to Excel</Badge>
                                </Col>
                                <Col></Col>
                                <Col></Col>
                                <Col></Col>
                                <Col></Col>
                                <Col></Col>
                                <Col></Col>
                                <Col></Col>
                                <Col></Col>
                                <Col></Col>
                                <Col>{loadtenders.reduce((a, b) => a + Number(b.Rate), 0).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}</Col>
                            </Row>
                            <AutoSizer>
                                {({ height, width }) => (
                                    <List
                                        height={height}
                                        rowCount={loadtenders.length}
                                        rowHeight={itemSize}
                                        width={width}
                                        rowRenderer={({ index, key, style }) => (
                                            <div key={key} style={style}>
                                                <Row style={index % 2 === 0 ? tdStyle : tdOddStyle}>
                                                    <Col>{index + 1}</Col>
                                                    <Col><Link to={`/loadtender/${loadtenders[index].ShipmentId}`} target='_blank'><FaEdit /></Link></Col>
                                                    <Col>{loadtenders[index].ShipmentId}</Col>
                                                    <Col>{loadtenders[index]['Day of Week']}</Col>
                                                    <Col>{loadtenders[index]['Start Time']}</Col>
                                                    <Col>{loadtenders[index]['End Time']}</Col>
                                                    <Col>{loadtenders[index].Type}</Col>
                                                    <Col>{loadtenders[index].Status}</Col>
                                                    <Col>{loadtenders[index].Contract}</Col>
                                                    <Col>{loadtenders[index].Trip}</Col>
                                                    <Col>{(Number(loadtenders[index].Rate).toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                    }))}</Col>
                                                </Row>
                                            </div>
                                        )}
                                    />
                                )}
                            </AutoSizer>
                        </>
                    }
                </>
            </Card>
        </Container >
    )
}

export default LoadTenderAccountingUSPS;