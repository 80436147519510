import React, { useEffect, useState, useCallback } from 'react';
import { Container, Card, Spinner, Alert, Modal, Button, Row, Badge, Col, Form, Table } from 'react-bootstrap';
import { RxCrossCircled, RxCheckCircled } from 'react-icons/rx';
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';
import AlertDisplay from '../../components/AlertDisplay';
import LogisticsLoading from '../../components/LogisticsLoading';
import { v4 as uuidv4 } from 'uuid';



function TMSKeys() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts };

    const [newSYBRTMSKey, setNewSYBRTMSKey] = useState({ apiKey: '', detail: '', })
    const [sybrtmsKeys, setSYBRTMSKeys] = useState([])
    async function handleGetSYBRTMSApiKeys() {
        try {
            const keys = await Api.get('/iot/sybrtms', idToken)
            setSYBRTMSKeys(keys)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'No SYBRTMS API Keys', }])
        } finally {
            didSet(true)
        }
    }

    async function handlePostSYBRTMSApiKey() {
        try {
            if (newSYBRTMSKey) {
                await Api.post('/iot/sybrtms', { ...newSYBRTMSKey }, idToken)
                setAlerts([...alerts, { variant: 'success', message: 'Created SYBRTMS API Key', }])
                const uuid = uuidv4();
                setNewSYBRTMSKey({ apiKey: uuid, detail: '', })
            }
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error creating SYBRTMS API Key', }])
        } finally {
            handleGetSYBRTMSApiKeys()
        }
    }

    async function handleDeleteSYBRTMSApiKey(id) {
        try {
            await Api.delete(`/iot/sybrtms/${id}`, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Deleted key', }])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error deleting key', }])
        } finally {
            handleGetSYBRTMSApiKeys()
        }
    }

    useEffect(() => {
        if (!set) {
            handleGetSYBRTMSApiKeys()
        }
        const uuid = uuidv4();
        setNewSYBRTMSKey({ ...newSYBRTMSKey, apiKey: uuid })
    }, [set])


    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <br />
            <Container>
                <Card>
                    <Card.Header>
                        <b>IoT - Internet of Things</b>
                    </Card.Header>
                    <Card.Body>
                        {!set ?
                            <LogisticsLoading message={'Loading...'} />
                            :
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>API Key</th>
                                        <th>Detail</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sybrtmsKeys.map((apiKey, i) => (
                                        <tr key={i}>
                                            <td>{apiKey.apiKey}</td>
                                            <td>{apiKey.detail}</td>
                                            <td><Button variant='danger' onClick={() => handleDeleteSYBRTMSApiKey(apiKey._id)}>Delete</Button></td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>
                                            <Form.Control type="text"
                                                placeholder="Enter key here..."
                                                value={newSYBRTMSKey.apiKey}
                                                readOnly
                                                disabled
                                            />
                                        </td>
                                        <td>
                                            <Form.Control type="text" placeholder="Enter detail here..." value={newSYBRTMSKey.detail} onChange={e => setNewSYBRTMSKey({ ...newSYBRTMSKey, detail: e.target.value })} />
                                        </td>
                                        <td>
                                            <Button variant='primary' onClick={() => handlePostSYBRTMSApiKey()}>
                                                Create
                                            </Button>
                                        </td>
                                    </tr>
                                </tfoot>
                            </Table>
                        }
                    </Card.Body>
                </Card>
            </Container>
        </Container>
    );
}

export default TMSKeys;
