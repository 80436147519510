import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Container, Tabs, Tab } from 'react-bootstrap';
import appRoutes from '../context/approutes'
import ReactGA from 'react-ga'

const LocationHistoryContext = createContext();

function segmentsMatch(a, b) {
    if (a === b) return true;
    if (a === ':id' && b) return true;
    return false;
}

function pathsMatch(routePath, page) {
    const routeSegments = routePath.split('/');
    const pageSegments = page.split('/');

    if (routeSegments.length !== pageSegments.length) return false;

    for (let i = 0; i < routeSegments.length; i++) {
        if (!segmentsMatch(routeSegments[i], pageSegments[i])) {
            return false;
        }
    }
    return true;
}

function findRouteName(page) {
    const route = appRoutes.find(appRoute => pathsMatch(appRoute.path, page));
    return route ? route.name : '';
}

export function LocationHistoryProvider({ children }) {
    const [visitedPages, setVisitedPages] = useState([]);

    return (
        <LocationHistoryContext.Provider value={{ visitedPages, setVisitedPages }}>
            {children}
        </LocationHistoryContext.Provider>
    );
}

export function useLocationHistory() {
    return useContext(LocationHistoryContext);
}

export function NavigateHistory() {
    const [visitedPages, setVisitedPages] = useState(() => {
        const storedVisitedPages = JSON.parse(localStorage.getItem('@tms-visited-pages'));
        return storedVisitedPages || [];
    });

    const location = useLocation();

    useEffect(() => {
        setVisitedPages(prevVisitedPages => {
            const index = prevVisitedPages.indexOf(location.pathname);
            if (index !== -1) {
                const updatedPages = [location.pathname, ...prevVisitedPages.slice(0, index), ...prevVisitedPages.slice(index + 1, 19)];
                return updatedPages;
            } else {
                const newPages = [location.pathname, ...prevVisitedPages.slice(0, 19)];
                return newPages;
            }
        });
    }, [location.pathname]);

    useEffect(() => {
        localStorage.setItem('@tms-visited-pages', JSON.stringify(visitedPages));
    }, [visitedPages]);

    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);
        ReactGA.pageview(location.pathname + location.search);
    }, [location.pathname]);

    const formatRouteName = (page) => {
        return page.split('/').filter(Boolean).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' > ') || 'Home';
    };

    return (
        <Container fluid style={{ backgroundColor: '#f9f9f9', padding: '3px', margin: '0px',}}>
            <b><i style={{fontSize:'0.75em',}}>History</i></b>
            <Tabs
                id="controlled-tab-example"
                style={{ paddingTop: '0.1em' }}
                activeKey={''}
            >   
                {visitedPages.map((page, i) => (
                    <Tab eventKey={`feature-${i}`} title={
                        <>
                            <Link key={i} to={page} style={{ color: '#3366cc', fontSize: '0.75em', padding: '0.1em', margin: '0 1em', whiteSpace: 'nowrap' }}>
                                {i + 1}. {formatRouteName(page)}
                            </Link>
                        </>
                    }
                        style={{ padding: 0 }}
                    />
                ))}
            </Tabs>
        </Container>
    );
}
