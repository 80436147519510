import { Button, Card, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import { useAuth } from "../../context/auth";
import AlertDisplay from "../../components/AlertDisplay";
import { useEffect, useMemo, useState } from "react";
import Api from "../../utils/Api";
import { findUSPSNASSCode } from "../../context/uspsdata";
import LogisticsLoading from "../../components/LogisticsLoading";
import { formatDateStrToMMDDYY, formatDateStrToMMDDYYHHMM } from "../../utils/DateTimeFormat.helper";
import ScatterPlot from "../../components/LoadTenderScatterPlot";
import { Link } from "react-router-dom";
import { FaEye, FaOpenid } from "react-icons/fa";
import CalendarChart from "../../components/CalendarChart";
import Select from 'react-select';

const CarrierScoreCard = ({ feature, carrier, loads, start, end }) => {

    function millisecondsToHHMM(milliseconds) {
        const hours = Math.floor(milliseconds / 3600000);
        milliseconds %= 3600000;
        const minutes = Math.floor(milliseconds / 60000);
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        return `${formattedHours}:${formattedMinutes}`;
    }

    const cellCenterStyle = {
        textAlign: 'center',
        padding: '0px 0.5em'
    }

    const cellRightStyle = {
        textAlign: 'right',
        padding: '0px 0.5em'
    }

    const cellCenterStyleGreen = {
        textAlign: 'center',
        padding: '0px 0.5em',
        backgroundColor: '#bcdbbc'
    }

    const cellCenterStyleRed = {
        textAlign: 'center',
        padding: '0px 0.5em',
        backgroundColor: '#f39c9c'
    }

    const cellCenterStyleBlue = {
        textAlign: 'center',
        padding: '0px 0.5em',
        backgroundColor: '#6eb6ff'
    }

    const cellCenterStyleYellow = {
        textAlign: 'center',
        padding: '0px 0.5em',
        backgroundColor: '#f8f39c'
    }

    const callCenterOrangeStyle = {
        textAlign: 'center',
        padding: '0px 0.5em',
        backgroundColor: '#ffc107'
    }


    function checkAudit(load) {
        let total = 0;
        if (load.coverage.length === 0) {
            total = total + load.edi204[load.edi204.length - 1].Stops.length
        } else {
            const audits = Object.keys(load.ediAudit);
            for (let i = 0; i < audits.length; i++) {
                const stopKeys = Object.keys(load.ediAudit[audits[i]].ediStops)
                if (stopKeys.length === 0) {
                    total = total + load.edi204[load.edi204.length - 1].Stops.length
                }
            }
        }


        return total;
    }

    function calculateEarly(load) {
        let total = 0;
        const audits = Object.keys(load.ediAudit);
        for (let i = 0; i < audits.length; i++) {
            const stopKeys = Object.keys(load.ediAudit[audits[i]].ediStops)
            for (let j = 0; j < stopKeys.length; j++) {
                const via = load.ediAudit[audits[i]].ediStops[stopKeys[j]]
                if (Number(via.eldOnPremiseArriveDiffMin) >= 0) {
                    total++;
                }
            }
        }

        return total;
    }

    function calculateLate(load) {
        let total = 0;
        const audits = Object.keys(load.ediAudit);
        for (let i = 0; i < audits.length; i++) {
            const stopKeys = Object.keys(load.ediAudit[audits[i]].ediStops)
            for (let j = 0; j < stopKeys.length; j++) {
                const via = load.ediAudit[audits[i]].ediStops[stopKeys[j]]
                if (Number(via.eldOnPremiseArriveDiffMin) < 0) {
                    total++;
                }
            }
        }

        return total;
    }

    function calculateMissed(load) {
        let total = 0;
        const audits = Object.keys(load.ediAudit);
        for (let i = 0; i < audits.length; i++) {
            const stopKeys = Object.keys(load.ediAudit[audits[i]].ediStops)
            for (let j = 0; j < stopKeys.length; j++) {
                const via = load.ediAudit[audits[i]].ediStops[stopKeys[j]]
                if ((!via?.eldOnPremiseArriveDiffMin)) {
                    total++;
                }
            }
        }

        return total;
    }

    function loadTenderScatterChartData(shipments) {
        const chartData = [];
        for (const load of shipments) {
            const ShipmentId = load?.ShipmentId;
            const Contract = load?.Contract;
            const Trip = load?.Trip;
            const loadDate = new Date(load?.StartTime).toDateString()
            if (load?.ediAudit && load?.ediAudit[0]?.ediStops) {
                const keys = Object.keys(load?.ediAudit[0]?.ediStops)
                if (keys.length > 0) {
                    for (const key of keys) {
                        const via = load?.ediAudit[0]?.ediStops[key]
                        const { eldOnPremiseArriveDiffMin, eldOnPremiseDepartDiffMin } = via;
                        const arrive = Number(eldOnPremiseArriveDiffMin);
                        const depart = Number(eldOnPremiseDepartDiffMin);



                        // Check if arrive and depart are valid numbers
                        if (!isNaN(arrive) && !isNaN(depart)) {
                            // Determine the color based on arrive and depart values
                            chartData.push({
                                date: loadDate,
                                arrivalTimeDifference: arrive,
                                departureTimeDifference: depart,
                                contractNumber: Contract,
                                tripNumber: Trip,
                                shipmentId: ShipmentId
                            });
                        }
                    }
                }
            }
        }

        return chartData;
    }

    function calendarChartData(loads) {
        const ops = [];
        const startDates = loads.map(load => new Date(load?.StartTime));

        for (let i = 0; i < startDates.length; i++) {
            ops.push([startDates[i], 1]);
        }

        return ops;
    }

    return (
        <Container>
            <Card>
                <Card.Header>
                    <Row>
                        <Col>
                            <h2>{carrier}</h2>
                        </Col>
                        <Col xs="auto">
                            {formatDateStrToMMDDYY(start)} to {formatDateStrToMMDDYY(end)}
                        </Col>
                    </Row>
                </Card.Header>
                {loads ?
                    <>
                        <Card.Body>
                            <h3>Stop Arrival and Departure Scatter Plot</h3>
                            <ScatterPlot redirect={feature} data={loadTenderScatterChartData(loads)} width={300} height={300} />
                        </Card.Body>
                        <Card.Body>
                            <CalendarChart title={'Operations'} opdata={calendarChartData(loads)} adjust={1.25} />
                        </Card.Body>
                        <Card.Body>
                            <h3>Stop Performance</h3>
                            <Row>
                                <Col style={callCenterOrangeStyle}>
                                    <h4>Check</h4>
                                    <h3>
                                        {(((loads.reduce((a, b) => a + checkAudit(b), 0))) / ((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%
                                    </h3>
                                </Col>
                                <Col style={cellCenterStyleGreen}>
                                    <h4>Early</h4>
                                    <h3>
                                        {(((loads.reduce((a, b) => a + calculateEarly(b), 0))) / ((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%
                                    </h3>
                                </Col>
                                <Col style={cellCenterStyleYellow}>
                                    <h4>Late</h4>
                                    <h3>
                                        {(((loads.reduce((a, b) => a + calculateLate(b), 0))) / ((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%
                                    </h3>
                                </Col>
                                <Col style={cellCenterStyleRed}>
                                    <h4>Not Detected</h4>
                                    <h3>
                                        {(((loads.reduce((a, b) => a + calculateMissed(b), 0))) / ((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%
                                    </h3>
                                </Col>
                                <Col style={cellCenterStyle}>
                                    <h4>Tracking</h4>
                                    <h3>
                                        {
                                            ((((loads.reduce((a, b) => a + calculateEarly(b), 0))) / ((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) +
                                                ((loads.reduce((a, b) => a + calculateLate(b), 0))) / ((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0)))) * 100).toFixed(1)
                                        }%
                                    </h3>
                                </Col>
                            </Row>
                            <Table>
                                <thead>
                                    <tr>
                                        <th style={cellCenterStyle}></th>
                                        <th style={cellCenterStyle}>Shipment Id</th>
                                        <th style={cellCenterStyle}>Start</th>
                                        <th style={cellCenterStyle}>End</th>
                                        <th style={cellCenterStyle}>SOP</th>
                                        <th style={cellCenterStyle}>Contract-Trip</th>
                                        <th style={cellCenterStyle}>Stops</th>
                                        <th style={cellCenterStyle}>Check</th>
                                        <th style={cellCenterStyle}>Early</th>
                                        <th style={cellCenterStyle}>Late</th>
                                        <th style={cellCenterStyle}>Not Detected</th>
                                        <th style={cellCenterStyle}>Tracking</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th style={cellCenterStyle}></th>
                                        <th style={cellCenterStyle}>{(loads.reduce((a, b) => a + 1, 0))}</th>
                                        <th style={cellCenterStyle}></th>
                                        <th style={cellCenterStyle}></th>
                                        <th style={cellCenterStyle}>{millisecondsToHHMM(loads.reduce((a, b) => a + b.SOPmilli, 0))}</th>
                                        <th style={cellCenterStyle}></th>
                                        <th style={cellCenterStyle}>{((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0)))}</th>
                                        <th style={cellCenterStyle}>{(((loads.reduce((a, b) => a + checkAudit(b), 0))) / ((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%</th>
                                        <th style={cellCenterStyle}>{(((loads.reduce((a, b) => a + calculateEarly(b), 0))) / ((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%</th>
                                        <th style={cellCenterStyle}>{(((loads.reduce((a, b) => a + calculateLate(b), 0))) / ((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%</th>
                                        <th style={cellCenterStyle}>{(((loads.reduce((a, b) => a + calculateMissed(b), 0))) / ((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%</th>
                                        <th style={cellCenterStyle}>{
                                            ((((loads.reduce((a, b) => a + calculateEarly(b), 0))) / ((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) +
                                                ((loads.reduce((a, b) => a + calculateLate(b), 0))) / ((loads.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0)))) * 100).toFixed(1)
                                        }%</th>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {loads.map((load, i) => (
                                        <tr>
                                            <td><Link to={`${feature}/${load?.ShipmentId}`} target="_blank"><FaEye /></Link></td>
                                            <td>{load?.ShipmentId}</td>
                                            <td style={cellCenterStyle}>{formatDateStrToMMDDYYHHMM(load.StartTime)}</td>
                                            <td style={cellCenterStyle}>{formatDateStrToMMDDYYHHMM(load.EndTime)}</td>
                                            <td style={cellCenterStyle}>{millisecondsToHHMM(load.SOPmilli)}</td>
                                            <td style={cellCenterStyle}>{(load.Contract)}-{(load.Trip)}</td>
                                            <td style={cellCenterStyle}>{load.edi204[0].Stops.length}</td>
                                            <td style={checkAudit(load) > 0 ? callCenterOrangeStyle : cellCenterStyle}>{checkAudit(load)}</td>
                                            <td style={calculateEarly(load) > 0 ? cellCenterStyleGreen : cellCenterStyle}>{calculateEarly(load)}</td>
                                            <td style={calculateLate(load) > 0 ? cellCenterStyleYellow : cellCenterStyle}>{calculateLate(load)}</td>
                                            <td style={calculateMissed(load) > 0 ? cellCenterStyleRed : cellCenterStyle}>{calculateMissed(load)}</td>
                                            <td style={cellCenterStyle}>{(((calculateEarly(load) + calculateLate(load)) / load.edi204[0].Stops.length) * 100).toFixed()}%</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                        </Card.Body>
                    </>
                    :
                    <Card.Body>
                        No Shipments
                    </Card.Body>
                }
            </Card>

        </Container>
    )
}



function CarrierLoadTenders({ view, feature }) {
    const { idToken } = useAuth();
    const [carriers, setCarriers] = useState([]);
    const [set, didSet] = useState();
    const [fetched, didFetch] = useState();
    const [load, didLoad] = useState();
    const [loads, setLoads] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }



    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    const formattedStartDate = sevenDaysAgo.toISOString().split('T')[0];

    const savedQuery = JSON.parse(localStorage.getItem('@tms-carrier-score-card'));

    const [query, setQuery] = useState({
        carrier: savedQuery ? savedQuery?.carrier : '',
        dateSpan: savedQuery ? savedQuery.dateSpan : 7,
        startDate: savedQuery ? savedQuery.startDate : formattedStartDate,
        endDate: savedQuery ? savedQuery.endDate : new Date().toISOString().split('T')[0],
    });


    useEffect(() => {
        localStorage.setItem('@tms-carrier-score-card', JSON.stringify(query));
    }, [query]);

    function setQueryDates(value) {
        const endDate = new Date();
        const startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - value);

        setQuery(prevQuery => ({
            ...prevQuery,
            dateSpan: value,
            startDate: startDate.toISOString().split('T')[0],
            endDate: endDate.toISOString().split('T')[0]
        }));
    }


    const fetch3PLCarriers = async () => {
        try {
            const carrierData = await Api.get(`/carriers/active`, idToken);
            setCarriers(carrierData);
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error fetching active carriers' }])
        } finally {
            didSet(true);
        }
    }

    useEffect(() => {
        if (!set) {
            fetch3PLCarriers();
        }
    }, [set])


    async function fetchCarrierLoadTenders() {
        didFetch(false)
        try {
            const queryStr = new URLSearchParams(query).toString();
            const data = await Api.get(`/loadtenders/carrier-shipments?${queryStr}`, idToken);
            setLoads(data);
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Unable to fetch loads.', }])
        } finally {
            didFetch(true)
        }
    }

    useEffect(() => {
        fetchCarrierLoadTenders()
    }, [query])


    const carrierOptions = [
        ...carriers.map(carrier => ({ value: carrier.name, label: carrier.name }))
    ];

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            {!set ?
                <LogisticsLoading message={'Loading report...'} />
                :
                <Card style={{ border: 0, borderRadius: 0 }}>
                    <Card.Header>
                        <Card.Title>
                            Carrier Load Tenders
                        </Card.Title>
                    </Card.Header>
                    <Card.Header>
                        <Row>
                            <Col xs="auto">
                                Previous
                                <Form.Control
                                    value={query?.dateSpan}
                                    as="select"
                                    onChange={e => setQueryDates(e.target.value)}
                                >
                                    <option>7</option>
                                    <option>30</option>
                                    <option>60</option>
                                    <option>90</option>
                                </Form.Control>
                            </Col>
                            <Col xs="auto">
                                Start
                                <Form.Control type="date" value={query?.startDate} readOnly disabled />
                            </Col>
                            <Col xs="auto">
                                End
                                <Form.Control type="date" value={query?.endDate} readOnly disabled />
                            </Col>
                            <Col xs="auto">
                                Contract
                                <Select
                                    value={carrierOptions.find(option => option.value === query.carrier)}
                                    onChange={selectedOption => setQuery({ ...query, carrier: selectedOption.value })}
                                    options={carrierOptions.sort((a, b) => a.label.localeCompare(b.label))}
                                />
                            </Col>
                        </Row>

                    </Card.Header>
                    <Card.Body>
                        {!fetched ?
                            <LogisticsLoading message={'Loading report...'} />
                            :
                            <>
                                {loads.length > 0 ?
                                    <CarrierScoreCard feature={feature} carrier={query?.carrier} loads={loads} start={query?.startDate} end={query?.endDate} />
                                    :
                                    <h3>
                                        No shipment data
                                    </h3>
                                }
                            </>
                        }
                    </Card.Body>
                </Card>
            }

        </Container>
    )
}


export default CarrierLoadTenders;