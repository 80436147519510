import { useState, useEffect, createContext, useContext } from 'react';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, signOut, signInWithRedirect, getRedirectResult, sendSignInLinkToEmail } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { Container, Spinner, Alert, Button } from 'react-bootstrap';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  };

  const app = initializeApp(config);
  const auth = getAuth(app);

  async function login(email, password) {
    try {
      const res = await signInWithEmailAndPassword(auth, email, password);
      const idToken = await res.user.getIdToken();
      setIdToken(idToken);
      setUser(res.user);
    } catch (error) {
      setUser(null);
      setIdToken(null);
      setError(error);
    }
  }

  async function logout() {
    try {
      await signOut(auth);
      setIdToken(null);
      setUser(null);
    } catch (error) {
      setIdToken(null);
      setUser(null);
      setError(error);
    }
  }

  const handleMicrosoftLogin = async () => {
    try {
      const provider = new firebase.auth.OAuthProvider('microsoft.com');

      provider.setCustomParameters({
        tenant: process.env.REACT_APP_MICROSOFT_TENANT_ID,
        redirectUri: process.env.REACT_APP_MICROSOFT_REDIRECT_URI,
      });
      provider.addScope('email');
      provider.addScope('profile');
      await signInWithRedirect(auth, provider);
      const result = await getRedirectResult(auth);
      const user = result.user;

      if (user) {
        const idToken = await user.getIdToken();
        setUser(user);
        setIdToken(idToken);
      }
    } catch (error) {
      setUser(null);
      setIdToken(null);
      setError(error.message)
    }
  };

  async function sendEmailVerificationLink(email) {
    const actionCodeSettings = {
      url: process.env.REACT_APP_BASE_URL + '/verify',
      handleCodeInApp: true,
    };

    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    } catch (error) {
      setError(error);
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          const idToken = await user.getIdToken(true);
          setUser(user);
          setIdToken(idToken);

        } else {
          setUser(null);
          setIdToken(null);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, [auth, idToken, user]);


  if (isLoading) {
    return (
      <Container className='d-flex justify-content-center align-items-center' style={{ minHeight: '100vh' }}>
        <Spinner />
      </Container>
    );
  }


  if (error) {
    return (
      <Container className='d-flex justify-content-center align-items-center' style={{ minHeight: '100vh' }}>
        <Alert variant="warning" style={{ maxWidth: '400px' }}>
          Authentication error occured. <Button onClick={logout()}>Return to Sign-in</Button>
        </Alert>
      </Container>
    );
  }


  const value = { auth, user, idToken, setIdToken, login, logout, handleMicrosoftLogin, sendEmailVerificationLink};

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}
