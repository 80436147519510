import React, { useRef, useState } from 'react';
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';
import AlertDisplay from '../../components/AlertDisplay';
import { Button, Card, Col, Container, Form, FormLabel, Row, Spinner } from 'react-bootstrap';

const initialFormState = {
    firstName: '',
    lastName: '',
    homeAddressLine1: '',
    homeAddressLine2: '',
    homeAddressCity: '',
    homeAddressState: '',
    homeAddressZip: '',
    phone: '',
    rateOfPay: '',
    dateOfBirth: '',
    ssn: '',
    supervisorFirstName: '',
    supervisorLastName: '',
    dateOfInjury: '',
    timeOfInjury: '',
    injuryAddressLine1: '',
    injuryAddressCity: '',
    injuryAddressState: '',
    injuryAddressZip: '',
    returnedToWork: '',
    lostTimeAtWork: '',
    witness: '',
    affectedBodyParts: '',
    incidentDescription: '',
    firstAidGiven: '',
    treatedInER: '',
    takenByAmbulance: '',
    signature: '',
    dateOfHire: '',
    injuryType: '',
    incidentDetails: '',
    bodyPartInjured: '',
    preventable: '',
    oshaRecordable: '',
    medicalTreatment: '',
    settingType: '',
    rootCause: '',
    daysOffWork: '',
    attorneyRepresented: '',
    comments: ''
};

const witnessFields = [
    'witnessName',
    'witnessPhone'
];

const hospitalFields = [
    'hospitalName',
    'hospitalCity',
    'hospitalState'
];

const InjuryFormSection = () => {
    const { idToken } = useAuth();
    const [set, didSet] = useState(true);
    const [formData, setFormData] = useState({});
    const [alerts, setAlerts] = useState([])
    const [hideShowSSN, setHideShowSSN] = useState(false);
    const alertState = { alerts, setAlerts }
    const lastKeyPressed = useRef(null);

    const states = [
        "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia",
        "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky",
        "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri",
        "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina",
        "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota",
        "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming",
    ];

    const incidentDetailTypes = [
        "Contact w/ Objects or Equipment",
        "Injury While Entering/Exiting CMV",
        "Hit or Rear-Ended By Other",
        "Lifting, Carrying",
        "Other",
        "Pulling, Pushing",
        "Slips, Trips, Falls",
        "Fatigue/Drowsy/Asleep",
        "Lift Gate Related",
        "Injury Due to 'Daily Tasks'/Over Time",
        "Injured by USPS Employee"
    ];
    
    const injuryTypes = [
        "Amputations",
        "Bruises, Contusions",
        "Carpal Tunnel",
        "Chemical Burns & Corrosions",
        "Cuts, lacerations",
        "Fractures",
        "Heat Burns",
        "Dislocation",
        "Soreness, Pain",
        "Sprains, Strains, Tears",
        "Tendonitis"
    ];
    
    const bodyParts = [
        "Ankle",
        "Arm",
        "Back",
        "Finger",
        "Foot",
        "Hand",
        "Head",
        "Knee",
        "Leg",
        "Lower Extremities",
        "Neck",
        "Rib",
        "Shoulder",
        "Upper Extremities",
        "Wrist",
        "Multiple Body Part Injuries",
        "Hip"
    ];
    
    const settingTypes = [
        "Office",
        "Parking Lot",
        "Post Office Dock",
        "Street",
        "CMV"
    ];
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        // e.target.setCustomValidity('');

        let formattedValue = value;
        if (name === 'phone' || name === 'witnessPhone') {
            if (lastKeyPressed.current !== 'Backspace') {
                formattedValue = formatPhoneNumber(value);
            }
        }

        if (name === 'ssn') {
            formattedValue = formatSSN(value);
        }

        if (name === 'homeAddressZip' || name === 'injuryAddressZip') {
            formattedValue = value.substring(0, 5);
        }

        setFormData(prevState => ({
            ...prevState,
            [name]: formattedValue
        }));
    };

    const handleCheckBoxChange = (e) => {
        const { name, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: checked
        }));
    }

    const handleSubmit = async () => {
        didSet(false)
        try {
            const missingFields = Object.keys(initialFormState).filter((field) => formData[field] === null || formData[field] === undefined || formData[field] === '')
            
            if (formData.witness === 'Yes') {
                const missingWitnessFields = witnessFields.filter((field) => formData[field] === null || formData[field] === undefined || formData[field] === '');
                missingFields.push(...missingWitnessFields);
            }

            if (formData.treatedInER === 'Yes' || formData.takenByAmbulance === 'Yes') {
                const missingHospitalFields = hospitalFields.filter((field) => formData[field] === null || formData[field] === undefined || formData[field] === '');
                missingFields.push(...missingHospitalFields);
            }            
            
            if (missingFields.length > 0) {
                const missingFieldNames = missingFields.map((field) => field.replace(/([A-Z])/g, ' $1').trim().toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '));
                const missingFieldsMessage = missingFieldNames.join(', ');
                setAlerts([
                    ...alerts,
                    {
                        variant: "warning",
                        message: `The following fields are required: ${missingFieldsMessage}`,
                    }
                ]);
                return;
            }

            await Api.post('/safety/injuries', { ...formData }, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Successfully created injury reprot' }])
            setFormData({})
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured creating injury reprot' }])
        } finally {
            didSet(true)
        }
    };

    const handleResetForm = () => {
        setFormData(initialFormState);
    };

    // const handleInvalid = (e) => {
    //     e.preventDefault();

    //     if (!e.target.validity.valid) {
    //         e.target.setCustomValidity('Please enter a valid 5-digit zip code.');
    //     }
    // };

    const formatPhoneNumber = (value) => {
        // Remove all non-digits and trim to 10 digits
        const digits = value.replace(/\D/g, '').substring(0, 10);

        // Split the string into parts
        const part1 = digits.substring(0, 3);
        const part2 = digits.substring(3, 6);
        const part3 = digits.substring(6, 10);

        // Construct the phone number string based on available parts
        let formattedNumber = '';
        if (part1) formattedNumber = `(${part1}`;
        if (part2) formattedNumber += `) ${part2}`;
        if (part3) formattedNumber += `-${part3}`;
        return formattedNumber;
    };

    const formatSSN = (value) => {
        // Remove all non-digits and limit to 9 digits
        const digits = value.replace(/\D/g, '').substring(0, 9);

        // Split the string into parts
        const part1 = digits.substring(0, 3);
        const part2 = digits.substring(3, 5);
        const part3 = digits.substring(5, 9);

        // Construct the SSN string based on available parts
        let formattedSSN = '';
        if (part1) formattedSSN = `${part1}`;
        if (part2) formattedSSN += `-${part2}`;
        if (part3) formattedSSN += `-${part3}`;
        return formattedSSN;
    };

    // Track the last key pressed
    const handleKeyDown = (e) => {
        lastKeyPressed.current = e.key;
    };

    return (
        <Container>
            <AlertDisplay alertState={alertState} />
            {!set ?
                <Spinner />
                :
                <>
                    <Card border="secondary" style={{ marginBottom: '20px'}}>
                        <Card.Header>
                            <Card.Title as="h5">Injury Report Form</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={6}>
                                    <FormLabel>First Name</FormLabel>
                                    <Form.Control type='text' name='firstName' onChange={handleChange} value={formData.firstName || ''}/>
                                </Col>
                                <Col md={6}>
                                    <FormLabel>Last Name</FormLabel>
                                    <Form.Control type='text' name='lastName' onChange={handleChange} value={formData.lastName || ''} />
                                </Col>
                            </Row>
                            <Form.Group>
                                <FormLabel>Employee Home Address Line 1</FormLabel>
                                <Form.Control type='text' name='homeAddressLine1' onChange={handleChange} value={formData.homeAddressLine1 || ''} />
                            </Form.Group>
                            <Form.Group>
                                <FormLabel>Employee Home Address Line 2</FormLabel>
                                <Form.Control type='text' name='homeAddressLine2' onChange={handleChange} value={formData.homeAddressLine2 || ''} />
                            </Form.Group>   
                            <Row>
                                <Col md={4}>
                                    <FormLabel>City</FormLabel>
                                    <Form.Control type='text' name='homeAddressCity' onChange={handleChange} />
                                </Col>
                                <Col md={4}>
                                    <FormLabel>State</FormLabel>
                                    <Form.Control as='select' name='homeAddressState' onChange={handleChange}>
                                        <option value="" disabled selected>Select</option>
                                        {states.map(state => <option key={state} value={state}>{state}</option>)}
                                    </Form.Control>
                                </Col>
                                <Col md={4}>
                                    <FormLabel>Zip Code</FormLabel>
                                    <Form.Control 
                                        type='text' 
                                        name='homeAddressZip' 
                                        onChange={handleChange} 
                                        // onInvalid={handleInvalid} 
                                        required 
                                        pattern='\d{5}' 
                                        title='Please enter a valid 5-digit zip code.' 
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormLabel>Phone</FormLabel>
                                    <Form.Control type='text' name='phone' value={formData.phone || ''} onChange={handleChange} onKeyDown={handleKeyDown} />
                                </Col>
                                <Col md={6}>
                                    <FormLabel>Rate of Pay</FormLabel>
                                    <Form.Control type='text' name='rateOfPay' onChange={handleChange} value={formData.rateOfPay}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormLabel>Date of Birth</FormLabel>
                                    <Form.Control type='date' name='dateOfBirth' onChange={handleChange} value={formData.birthDate} />
                                </Col>
                                <Col md={6}>
                                    <Row>
                                        <Col>
                                            <FormLabel>Social Security Number</FormLabel>
                                            <Form.Control type={hideShowSSN ? 'password' : 'text'} name='ssn' value={formData.ssn || ''} onChange={handleChange}/>                                        
                                        </Col>
                                        <Col xs={'auto'}>
                                            <FormLabel>Hide/Show</FormLabel>
                                            <br />
                                            <Button variant='secondary' onClick={() => setHideShowSSN(!hideShowSSN)}>{hideShowSSN ? 'Show' : 'Hide'}</Button>
                                        </Col>

                                    </Row>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col md={6}>
                                    <FormLabel>Supervisor First Name</FormLabel>
                                    <Form.Control type='text' name='supervisorFirstName' onChange={handleChange} value={formData.supervisorFirstName || ''}/>
                                </Col>
                                <Col md={6}>
                                    <FormLabel>Supervisor Last Name</FormLabel>
                                    <Form.Control type='text' name='supervisorLastName' onChange={handleChange} value={formData.supervisorLastName || ''}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormLabel>Date of Injury</FormLabel>
                                    <Form.Control type='date' name='dateOfInjury' onChange={handleChange} value={formData.dateOfInjury || ''}/>
                                </Col>
                                <Col md={6}>
                                    <FormLabel>Time of Injury</FormLabel>
                                    <Form.Control type='time' name='timeOfInjury' onChange={handleChange} value={formData.timeOfInjury || ''}/>
                                </Col>
                            </Row>

                            <FormLabel>Injury Location Address Line 1</FormLabel>
                            <Form.Control type='text' name='injuryAddressLine1' onChange={handleChange} value={formData.injuryAddressLine1 || ''}/>

                            <FormLabel>Injury Location Address Line 2</FormLabel>
                            <Form.Control type='text' name='injuryAddressLine2' onChange={handleChange} value={formData.injuryAddressLine2 || ''}/>

                            <Row>
                                <Col md={4}>
                                    <FormLabel>Injury Location City</FormLabel>
                                    <Form.Control type='text' name='injuryAddressCity' onChange={handleChange} value={formData.injuryAddressCity || ''}/>
                                </Col>
                                <Col md={4}>
                                    <FormLabel>Injury Location State</FormLabel>
                                    <Form.Control as='select' name='injuryAddressState' onChange={handleChange} value={formData.injuryAddressState || ''}>
                                        <option value="" disabled selected>Select</option>
                                        {states.map(state => <option key={state} value={state}>{state}</option>)}
                                    </Form.Control>
                                </Col>
                                <Col md={4}>
                                    <FormLabel>Injury Location Zip Code</FormLabel>
                                    <Form.Control 
                                        type='text' 
                                        name='injuryAddressZip' 
                                        onChange={handleChange} 
                                        // onInvalid={handleInvalid} 
                                        value={formData.injuryAddressZip || ''}
                                        required 
                                        pattern='\d{5}' 
                                        title='Please enter a valid 5-digit zip code.'  
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <FormLabel>Has Employee Returned to Work?</FormLabel>
                                    <Form.Control as='select' name='returnedToWork' onChange={handleChange} value={formData.returnedToWork || ''}>
                                        <option value="" disabled selected>Select</option>
                                        <option value='Yes'>Yes</option>
                                        <option value='No'>No</option>
                                    </Form.Control>
                                </Col>
                                <Col md={6}>
                                    <FormLabel>Lost Time at Work</FormLabel>
                                    <Form.Control as="select" name='lostTimeAtWork' onChange={handleChange} value={formData.lostTimeAtWork || ''}>
                                        <option value="" disabled selected>Select</option>
                                        <option value='Yes'>Yes</option>
                                        <option value='No'>No</option>
                                    </Form.Control>
                                </Col>
                            </Row>

                            <FormLabel>Witness?</FormLabel>
                            <Form.Control as='select' name='witness' onChange={handleChange} value={formData.witness || ''}>
                                <option value="" disabled selected>Select</option>
                                <option value='Yes'>Yes</option>
                                <option value='No'>No</option>
                            </Form.Control>
                            
                            {formData.witness === 'Yes' && (
                                <>
                                    <Row>
                                        <Col md={6}>
                                            <FormLabel>Witness Name</FormLabel>
                                            <Form.Control type='text' name='witnessName' onChange={handleChange} value={formData.witnessName || ''}/>
                                        </Col>
                                        <Col md={6}>
                                            <FormLabel>Witness Phone</FormLabel>
                                            <Form.Control type='text' name='witnessPhone' onChange={handleChange} value={formData.witnessPhone || ''} />
                                        </Col>
                                    </Row>
                                </>
                            )}

                            <FormLabel>Affected Body Parts</FormLabel>
                            <Form.Control type='text' name='affectedBodyParts' onChange={handleChange} value={formData.affectedBodyParts || ''}/>

                            <FormLabel>Incident Description</FormLabel>
                            <Form.Control 
                                as="textarea" 
                                name='incidentDescription' 
                                onChange={handleChange}
                                value={formData.incidentDescription || ''}
                                rows={3}
                                placeholder="Please describe the incident in detail. Include equipment involved, description of the surrounding area, and any other relevant information."
                            />
                            <FormLabel>First Aid Given</FormLabel>
                            <Form.Control type='text' name='firstAidGiven' onChange={handleChange} value={formData.firstAidGiven || ''} />

                            <Row>
                                <Col md={6}>
                                    <FormLabel>Treated in ER?</FormLabel>
                                    <Form.Control as='select' name='treatedInER' onChange={handleChange} value={formData.treatedInER || ''}>
                                        <option value="" disabled selected>Select</option>
                                        <option value='Yes'>Yes</option>
                                        <option value='No'>No</option>
                                    </Form.Control>
                                </Col>
                                <Col md={6}>
                                    <FormLabel>Taken by Ambulance?</FormLabel>
                                    <Form.Control as='select' name='takenByAmbulance' onChange={handleChange} value={formData.takenByAmbulance || ''}>
                                        <option value="" disabled selected>Select</option>
                                        <option value='Yes'>Yes</option>
                                        <option value='No'>No</option>
                                    </Form.Control>
                                </Col>
                            </Row>
                            
                            {(formData.treatedInER === 'Yes' || formData.takenByAmbulance === 'Yes') && (
                                <>
                                    <FormLabel>Hospital Name</FormLabel>
                                    <Form.Control type='text' name='hospitalName' onChange={handleChange} value={formData.hospitalName || ''} />

                                    <Row>
                                        <Col md={6}>
                                            <FormLabel>Hospital City</FormLabel>
                                            <Form.Control type='text' name='hospitalCity' onChange={handleChange} value={formData.hospitalCity || ''}/>
                                        </Col>
                                        <Col md={6}>
                                            <FormLabel>Hospital State</FormLabel>
                                            <Form.Control as='select' name='hospitalState' onChange={handleChange} value={formData.hospitalState || ''}>
                                                <option value="" disabled selected>Select</option>
                                                {states.map(state => <option key={state} value={state}>{state}</option>)}
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                </>
                            )}

                            <Form.Group controlId="driverSignatureCheckbox">
                                <Form.Check
                                    type="checkbox"
                                    id="driverSignatureCheckbox"
                                    label="I acknowledge that the information provided above is accurate and true."
                                    name="signature"
                                    onChange={handleCheckBoxChange}
                                    checked={formData.signature}
                                    style={{ marginTop: '10px' }}
                                />
                            </Form.Group>
                        </Card.Body>
                    </Card>

                    {/*Amela's portion*/}
                    <Card border="secondary">
                        <Card.Header>
                            <Card.Title as="h5">Fields for supervisors to fill out:</Card.Title>
                        </Card.Header>
                        <Card.Body>

                            <FormLabel>Date of Hire</FormLabel>
                            <Form.Control type='date' name='dateOfHire' onChange={handleChange} value={formData.dateOfHire || ''}/>

                            <FormLabel>Injury Type</FormLabel>
                            <Form.Control as='select' name='injuryType' onChange={handleChange} value={formData.injuryType || ''}>
                                <option value="" disabled selected>Select</option>
                                {injuryTypes.map(option => <option key={option} value={option}>{option}</option>)}
                            </Form.Control>

                            <FormLabel>Incident Details</FormLabel>
                            <Form.Control as="select" name='incidentDetails' onChange={handleChange} value={formData.incidentDetails || ''}>
                                <option value="" disabled selected>Select</option>
                                {incidentDetailTypes.map(option => <option key={option} value={option}>{option}</option>)}
                            </Form.Control>

                            <FormLabel>Body Part(s) Injured</FormLabel>
                            <Form.Control as='select' name='bodyPartInjured' onChange={handleChange} value={formData.bodyPartInjured || ''}>
                                <option value="" disabled selected>Select</option>
                                {bodyParts.map(option => <option key={option} value={option}>{option}</option>)}
                            </Form.Control>

                            <Row>
                                <Col md={6}>
                                    <FormLabel>Preventable?</FormLabel>
                                    <Form.Control as='select' name='preventable' onChange={handleChange} value={formData.preventable || ''}>
                                        <option value="" disabled selected>Select</option>
                                        <option value='Yes'>Yes</option>
                                        <option value='No'>No</option>
                                    </Form.Control>
                                </Col>
                                <Col md={6}>
                                    <FormLabel>OSHA Recordable?</FormLabel>
                                    <Form.Control as='select' name='oshaRecordable' onChange={handleChange} value={formData.oshaRecordable || ''}>
                                        <option value="" disabled selected>Select</option>
                                        <option value='Yes'>Yes</option>
                                        <option value='No'>No</option>
                                        <option value="N/A">N/A</option>
                                    </Form.Control>
                                </Col>                        
                            </Row>

                            <FormLabel>Medical Treatment</FormLabel>
                            <Form.Control as='select' name='medicalTreatment' onChange={handleChange} value={formData.medicalTreatment || ''}>
                                <option value="" disabled selected>Select</option>
                                <option value='ER'>ER</option>
                                <option value='Doctor'>Doctor</option>
                                <option value='MedCor'>MedCor</option>
                                <option value='None'>None</option>
                            </Form.Control>

                            <FormLabel>Setting Type</FormLabel>
                            <Form.Control as='select' name='settingType' onChange={handleChange} value={formData.settingType || ''}>
                                <option value="" disabled selected>Select</option>
                                {settingTypes.map(option => <option key={option} value={option}>{option}</option>)}
                            </Form.Control>

                            <FormLabel>Root Cause</FormLabel>
                            <Form.Control type='text' name='rootCause' onChange={handleChange} value={formData.rootCause || ''}/>

                            <FormLabel># Days off work</FormLabel>
                            <Form.Control type='text' name='daysOffWork' onChange={handleChange} value={formData.daysOffWork || ''}/>

                            <FormLabel>Attorney represented?</FormLabel>
                            <Form.Control as='select' name='attorneyRepresented' onChange={handleChange} value={formData.attorneyRepresented || ''}>
                                <option value="" disabled selected>Select</option>
                                <option value='Yes'>Yes</option>
                                <option value='No'>No</option>
                            </Form.Control>

                            <FormLabel>Comments</FormLabel>
                            <Form.Control as='textarea' name='comments' onChange={handleChange} value={formData.comments || ''}/>
                            
                            <Button className="mt-2" type='submit' onClick={handleSubmit}>Submit</Button>
                            <Button className="mt-2" variant="warning" type="button" onClick={handleResetForm} style={{ marginLeft: '5px'}}>Reset Form</Button>

                        </Card.Body>
                    </Card>
                </>}    
        </Container>
    )
};

export default InjuryFormSection;