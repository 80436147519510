import React, { useEffect, useRef, useState } from 'react';
import {
    Container,
    Form,
    FormLabel,
    Button,
    Spinner,
    Row,
    Col,
    FormControl,
    DropdownButton,
    Dropdown,
    Accordion,
    Card,
} from 'react-bootstrap';
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';
import AlertDisplay from '../../components/AlertDisplay';
import { TiDelete } from "react-icons/ti";

const initialFormState = {
    date: '',
    time: '',
    reportedTo: '',
    accidentAddressLine1: '',
    accidentCity: '',
    accidentState: '',
    accidentZipCode: '',
    roadConditions: '',
    description: '',
    companyDriverFirstName: '',
    companyDriverLastName: '',
    companyDriverPhone: '',
    companyDriverBirthDate: '',
    unitNumber: '',
    vin: '',
    vehicleYear: '',
    vehicleMake: '',
    damageToCompanyUnit: '',
    otherDriverInvolved: '',
    injuries: '',
    witness: '',
    citations: '',
    tow: '',
    companyTow: '',
    signature: '',
    accidentType: '',
    preventable: '',
    dotRecordable: '',
    roadType: '',
    rootCause: '',
    cameraFootage: '',
    companyCost: '',
    costType: '',
    attorney: '',
    driverPoints: '',
    claimNumber: '',
    claimStatus: ''
};

const otherDriverFields = [
    'otherDriverFirstName',
    'otherDriverLastName',
    'otherDriverPhone',
    'otherDriverLicense',
    'otherVehicleYear',
    'otherVehicleMake',
    'otherVehicleVin'
];

const witnessFields = [
    'witnessFirstName',
    'witnessLastName',
    'witnessPhone'
];

const towFields =[
    'towCompanyName',
    'towCompanyPhone'
]

const AccidentFormSection = () => {
    const { idToken } = useAuth();
    const [set, didSet] = useState(true);
    const [load, setLoad] = useState(false);
    const [formData, setFormData] = useState(initialFormState);
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts };
    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [samsaraVehicles, setSamsaraVehicles] = useState();
    const [files, setFiles] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [labelText, setLabelText] = useState('Choose file');

    const inputRef = useRef(null);
    
    const states = [
        "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia",
        "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky",
        "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri",
        "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina",
        "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota",
        "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming",
    ];

    const accidentTypes = [
        "Animal Strike",
        "Backing",
        "Cargo-related",
        "Gas/Diesel/DEF Tank",
        "Hit or Rear-Ended By Other",
        "Hit Stationary Object",
        "Lane Change",
        "Other",
        "Rear End Collision",
        "Sideswipe",
        "Stolen",
        "Turning",
        "Weather Related",
        "Pedestrian",
        "Trailer Door Damage",
        "Fatigue/Drowsy/Asleep",
        "Unknown",
        "Lift Gate Related",
        "Truck or TRL Rooftop Damage",
        "Pulling Away From Dock",
        "USPS Personnel Damaged Company Eqp."
    ];    

    const fetchSamsaraVehicles = async () => {
        try {
            const vehiclesdata = await Api.get(`/samsara/fleet`, idToken);
            const vehicles = vehiclesdata?.data;

            const sortedVehicles = vehicles.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });

            console.log(sortedVehicles[1]);

            setSamsaraVehicles(sortedVehicles)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error fetching active drivers' }])
        } finally {
            setLoad(true);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCheckBoxChange = (e) => {
        const { name, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: checked
        }));
    }

    const handleSubmit = async () => {
        try {
          
          const missingFields = Object.keys(initialFormState).filter((field) => formData[field] === null || formData[field] === undefined || formData[field] === '' || formData[field] === false)
          
            if (formData.damageToCompanyUnit === 'Yes') {
                missingFields.push('damageDescription');
            }

            if (formData.injuries === 'Yes') {
                missingFields.push('injuriesDescription');
            }
          
            if (formData.otherDriverInvolved === 'Yes') {
                const missingOtherDriverFields = otherDriverFields.filter((field) => formData[field] === null || formData[field] === undefined || formData[field] === '' || formData[field] === false);
                missingFields.push(...missingOtherDriverFields);
            }

            if (formData.witness === 'Yes') {
                const missingWitnessFields = witnessFields.filter((field) => formData[field] === null || formData[field] === undefined || formData[field] === '' || formData[field] === false);
                missingFields.push(...missingWitnessFields);
            }

            if (formData.tow === 'Yes') {
                const missingTowFields = towFields.filter((field) => formData[field] === null || formData[field] === undefined || formData[field] === '' || formData[field] === false);
                missingFields.push(...missingTowFields);
            }

          console.log(missingFields)

            if (missingFields.length > 0) {
                // const missingFieldNames = missingFields.map((field) => field.replace(/([A-Z])/g, ' $1').trim());
                const missingFieldNames = missingFields.map((field) => field.replace(/([A-Z])/g, ' $1').trim().toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '));
                const missingFieldsMessage = missingFieldNames.join(', ');
                setAlerts([
                    ...alerts,
                    {
                        variant: "warning",
                        message: `The following fields are required: ${missingFieldsMessage}`,
                    }
                ]);
                return;
            }
    
          const formdata = new FormData();
          formdata.append('saveDir', 'safety-accidents');
          for (const file of files) {
            formdata.append('files', file);
          }
    
          const uploadResponse = await Api.uploadFiles('/storage/file', formdata, idToken);
    
          if (uploadResponse.ok) {
            const keys = uploadResponse.keys;
            const formResponse = await Api.post('/safety/accidents', { ...formData, attachments: keys }, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'Submission successful' }]);
            setFormData({});
            if (formResponse.message) {
              setAlerts([
                ...alerts,
                {
                  variant: "success",
                  message: `Success`,
                },
              ]);
            } else {
              setAlerts([
                ...alerts,
                {
                  variant: "warning",
                  message: `Warning record did not save, please submit again.`,
                },
              ]);
            }
          } else {
            setAlerts([
              ...alerts,
              {
                variant: "warning",
                message: `A network error occured and the files were unable to upload. Please try again`,
              },
            ]);
          }
        } catch (error) {
          setAlerts([
            ...alerts,
            {
              variant: "warning",
              message: `An error occured and the form was unable to submit. Please try again`,
            },
          ]);

        } finally {
            didSet(true);
        }
    };

    const handleResetForm = () => {
        setFormData(initialFormState);
        setLoad(false);
    };

    const handleInvalid = (e) => {
        e.preventDefault();

        if (!e.target.validity.valid) {
            e.target.setCustomValidity('Please enter a valid 5-digit zip code.');
        }
    };

    const handleSelectVehicle = (selectedName) => {
        const vehicle = samsaraVehicles.find(vehicle => vehicle.name === selectedName);
        setSelectedVehicle(selectedName);
        setFormData(prevState => ({
            ...prevState,
            unitNumber: selectedName,
            vin: vehicle?.vin || '',
            vehicleYear: vehicle?.year || '',
            vehicleMake: vehicle?.make || '',
        }));
    };

    const handleUnitNumberChange = (e) => {
        const enteredUnitNumber = e.target.value;
        setSelectedVehicle(enteredUnitNumber);
        setFormData(prevState => ({
            ...prevState,
            unitNumber: enteredUnitNumber,
            vin: '',
            vehicleYear: '',
            vehicleMake: '',
        }));
    };

    const handleClickInsideInput = (e) => {
        e.stopPropagation();
    };

    const handleFileChange = (e) => {
        e.preventDefault();
        const selectedFiles = Array.from(e.target.files);
        setFiles(selectedFiles);
        const newAttachments = selectedFiles.map((file) => ({
            name: file.name,
            size: file.size,
            type: file.type,
            url: URL.createObjectURL(file),
        }));
        setAttachments(newAttachments);
    };

    const handleRemoveFile = (index) => {
        if (index >= 0 && index < attachments.length) {
          const updatedAttachments = [...attachments];
          updatedAttachments.splice(index, 1);
          setAttachments(updatedAttachments);
      
          const updatedFiles = [...files];
          updatedFiles.splice(index, 1);
          setFiles(updatedFiles);
        }
    };      

    useEffect(() => {
        if (!load) {
            console.log('fetching vehicles');
            fetchSamsaraVehicles();
        }
    }, [load])

    useEffect(() => {
        setLabelText(
          attachments.length > 1
            ? `${attachments.length} files selected`
            : attachments[0]?.name || "Choose file"
        );
      }, [attachments]);


    return (
        <Container fluid className="mt-5">
            <AlertDisplay alertState={alertState} />
            {!set ? 
                <Spinner animation="border" />
             : 
                <>
                    <Card border="secondary" style={{ marginBottom: "20px"}}>
                        <Card.Header as="h4">Accident Report - Create New</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={6}>
                                    <Form.Group controlId="date">
                                        <FormLabel>Date of Accident</FormLabel>
                                        <FormControl type="date" name="date" onChange={handleChange} value={formData.date || ''} />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="time">
                                        <FormLabel>Time of Accident</FormLabel>
                                        <FormControl type="time" name="time" onChange={handleChange} value={formData.time || ''} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group controlId="reportedTo">
                                <FormLabel>Reported To</FormLabel>
                                <FormControl as="select" name="reportedTo" onChange={handleChange} value={formData.reportedTo || ''}>
                                    <option value="" disabled selected>Select...</option>
                                    <option value="Manager">Manager</option>
                                    <option value="Safety">Safety</option>
                                    <option value="HR">HR</option>
                                    <option value="Owner">Owner</option>
                                </FormControl>
                            </Form.Group>
                            <Form.Group controlId="accidentAddressLine1">
                                <FormLabel>Address Line 1</FormLabel>
                                <FormControl type="text" name="accidentAddressLine1" onChange={handleChange} value={formData.accidentAddressLine1 || ''} />
                            </Form.Group>
                            <Form.Group controlId="accidentAddressLine2">
                                <FormLabel>Address Line 2</FormLabel>
                                <FormControl type="text" name="accidentAddressLine2" onChange={handleChange} value={formData.accidentAddressLine2 || ''} />
                            </Form.Group>
                            <Row>
                                <Col md={4}>
                                    <Form.Group controlId="accidentCity">
                                        <FormLabel>City</FormLabel>
                                        <FormControl type="text" name="accidentCity" onChange={handleChange} value={formData.accidentCity || ''} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="accidentState">
                                        <FormLabel>State</FormLabel>
                                        <FormControl as="select" name="accidentState" onChange={handleChange} value={formData.accidentState || ''}>
                                            <option value="" disabled selected>Select State</option>
                                            {states.map(state => <option value={state} key={state} >{state}</option>)}
                                        </FormControl>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="accidentZipCode">
                                        <FormLabel>Zip Code</FormLabel>
                                        <FormControl
                                            type="text"
                                            name="accidentZipCode"
                                            onChange={handleChange}
                                            onInvalid={handleInvalid}
                                            value={formData.accidentZipCode || ''}
                                            pattern="^\d{5}$"
                                            maxLength={5}
                                            title="Enter a 5-digit zip code"
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <FormLabel>Road Conditions</FormLabel>
                                    <FormControl as="select" name="roadConditions" onChange={handleChange} value={formData.roadConditions || ''}>
                                        <option value="" disabled selected>Select...</option>
                                        <option value="Dry">Dry</option>
                                        <option value="Wet">Wet</option>
                                        <option value="Snow">Snow</option>
                                        <option value="Ice">Ice</option>
                                    </FormControl>
                                </Col>
                            </Row>       

                            <FormLabel>Describe what happened</FormLabel>
                            <FormControl as="textarea" name="description" onChange={handleChange} value={formData.description || ''} />

                            <Row>
                                <Col md={6}>
                                    <FormLabel>Company Driver First Name</FormLabel>
                                    <FormControl type="text" name="companyDriverFirstName" onChange={handleChange} value={formData.companyDriverFirstName || ''} />
                                </Col>
                                <Col md={6}>
                                    <FormLabel>Company Driver Last Name</FormLabel>
                                    <FormControl type="text" name="companyDriverLastName" onChange={handleChange} value={formData.companyDriverLastName || ''} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormLabel>Company Driver Phone</FormLabel>
                                    <FormControl type="text" name="companyDriverPhone" onChange={handleChange} value={formData.companyDriverPhone || ''} />
                                </Col>
                                <Col md={6}>
                                    <FormLabel>Company Driver Date of Birth</FormLabel>
                                    <FormControl type="date" name="companyDriverBirthDate" onChange={handleChange} value={formData.companyDriverBirthDate || ''} />
                                </Col>

                            </Row>

                            <Row>                                
                                <Col md={6}>
                                    <Form.Group controlId="unitNumber">
                                        <FormLabel>Unit Number</FormLabel>
                                        <DropdownButton
                                            id="dropdown-unitnumber"
                                            title={selectedVehicle || 'Select Unit Number'}
                                            onSelect={handleSelectVehicle}
                                        >
                                            <Dropdown.Item>
                                                <FormControl
                                                    type="text"
                                                    placeholder="Type to filter..."
                                                    value={selectedVehicle}
                                                    onChange={handleUnitNumberChange}
                                                    ref={inputRef}
                                                    onClick={handleClickInsideInput}
                                                />
                                            </Dropdown.Item>
                                            {samsaraVehicles && samsaraVehicles
                                                .filter(vehicle => vehicle.name.toLowerCase().includes(selectedVehicle.toLowerCase()))
                                                .map((vehicle, i) => (
                                                    <Dropdown.Item key={i} eventKey={vehicle.name}>
                                                        {vehicle.name}
                                                    </Dropdown.Item>
                                            ))}
                                        </DropdownButton>
                                    </Form.Group>
                                </Col>                        
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <FormLabel>VIN</FormLabel>
                                    <FormControl type="text" name="vin" onChange={handleChange} value={formData.vin || ''} />
                                </Col>  
                            </Row>

                            <Row>
                                <Col md={4}>
                                    <FormLabel>Vehicle Year</FormLabel>
                                    <FormControl type="text" name="vehicleYear" onChange={handleChange} value={formData.vehicleYear || ''} />
                                </Col>
                                <Col md={4}>
                                    <FormLabel>Vehicle Make</FormLabel>
                                    <FormControl type="text" name="vehicleMake" onChange={handleChange} value={formData.vehicleMake || ''} />
                                </Col>
                            </Row>
                            
                            <FormLabel>Is there damage to the Company's Unit?</FormLabel>
                            <FormControl as="select" name="damageToCompanyUnit" onChange={handleChange} value={formData.damageToCompanyUnit || ''}>
                                <option value="" disabled selected>Select...</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </FormControl>

                            <FormLabel>If YES, please describe the damage:</FormLabel>
                            <FormControl 
                                as="textarea" 
                                name="damageDescription" 
                                onChange={handleChange} 
                                value={formData.damageDescription || ''} 
                                disabled={formData.damageToCompanyUnit === 'No'}
                                required={formData.damageToCompanyUnit === 'Yes'}
                            />

                            <FormLabel>Attach images</FormLabel>
                            <FormControl 
                                type='file'
                                name='images'
                                label={labelText}
                                onChange={handleFileChange}
                                multiple
                            />

                            <Accordion>
                                {attachments.length > 0 && attachments.map((attachment, i) => {
                                        let content;
                                        const isImage = ['image/png', 'image/jpg', 'image/jpeg'].includes(attachment.type);
                                        const isPDF = attachment.type === 'application/pdf';

                                        if (isImage) {
                                            content = <img src={attachment.url} alt={`Attachment ${i}`} style={{ maxWidth: '25%', height: 'auto' }} />;
                                        } else if (isPDF) {
                                            content = <embed src={attachment.url} width="100%" height="600px" type="application/pdf" />
                                        } else {
                                            content = <p>Unsupported file type.</p>;
                                        }

                                        return (
                                            <Row style={{ alignItems: 'center' }} key={i}>
                                                <Col>
                                                    <Accordion.Item key={i} eventKey={i.toString()}>
                                                        <Accordion.Header>
                                                            <div>
                                                                <span>{attachment.name}</span>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body style={{ display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                                                            {content}
                                                            <Button
                                                                variant="danger"
                                                                size="sm"
                                                                onClick={() => handleRemoveFile(i)}
                                                                style={{
                                                                    border: 'none',
                                                                    padding: 3,
                                                                    cursor: 'pointer',
                                                                    marginLeft: 5,
                                                                }}
                                                            >
                                                                <TiDelete style={{ fontSize: '20px', marginBottom: 2 }} />
                                                                Remove Image
                                                            </Button>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Col>
                                            </Row>
                                        );
                                })}
                                {attachments.length === 0 && <p>No files selected.</p>}
                            </Accordion>                            

                            <FormLabel>Was another driver/vehicle involved?</FormLabel>
                            <FormControl as="select" name="otherDriverInvolved" onChange={handleChange} value={formData.otherDriverInvolved || ''}>
                                <option value="" disabled selected>Select...</option>                                
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </FormControl>

                            {formData.otherDriverInvolved === 'Yes' && (
                                <>
                                    <Row>
                                        <Col md={4}>
                                            <FormLabel>Other Driver First Name</FormLabel>
                                            <FormControl type="text" name="otherDriverFirstName" onChange={handleChange} value={formData.otherDriverFirstName || ''} />
                                        </Col>
                                        <Col md={4}>
                                            <FormLabel>Other Driver Last Name</FormLabel>
                                            <FormControl type="text" name="otherDriverLastName" onChange={handleChange} value={formData.otherDriverLastName || ''} />
                                        </Col>
                                        <Col md={4}>
                                            <FormLabel>Other Driver Phone</FormLabel>
                                            <FormControl type="text" name="otherDriverPhone" onChange={handleChange} value={formData.otherDriverPhone || ''} />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={4}>
                                            <FormLabel>Other Driver's License Number</FormLabel>
                                            <FormControl type="text" name="otherDriverLicense" onChange={handleChange} value={formData.otherDriverLicense || ''} />
                                        </Col>
                                        <Col md={4}>
                                            <FormLabel>Other Vehicle Year</FormLabel>
                                            <FormControl type="text" name="otherVehicleYear" onChange={handleChange} value={formData.otherVehicleYear || ''} />
                                        </Col>
                                        <Col md={4}>
                                            <FormLabel>Other Vehicle Make</FormLabel>
                                            <FormControl type="text" name="otherVehicleMake" onChange={handleChange} value={formData.otherVehicleMake || ''} />
                                        </Col>
                                    </Row>      

                                    <FormLabel>Other Vehicle VIN</FormLabel>
                                    <FormControl type="text" name="otherVehicleVin" onChange={handleChange} value={formData.otherVehicleVin || ''} />
                                </>
                            )}

                            <FormLabel>Injuries?</FormLabel>
                            <FormControl as="select" name="injuries" onChange={handleChange} value={formData.injuries || ''}>
                                <option value="" disabled selected>Select...</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </FormControl>

                            <FormLabel>If YES, please describe</FormLabel>
                            <FormControl 
                                as="textarea" 
                                name="injuriesDescription" 
                                onChange={handleChange} 
                                value={formData.injuriesDescription || ''} 
                                disabled={formData.injuries === 'No'}
                            />

                            <FormLabel>Witness available?</FormLabel>
                            <FormControl as="select" name="witness" onChange={handleChange} value={formData.witness || ''}>
                                <option value="" disabled selected>Select...</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </FormControl>

                            {formData.witness === 'Yes' && (
                                <Row>
                                    <Col md={4}>
                                        <FormLabel>Witness First Name</FormLabel>
                                        <FormControl type="text" name="witnessFirstName" onChange={handleChange} value={formData.witnessFirstName || ''} />
                                    </Col>
                                    <Col md={4}>
                                        <FormLabel>Witness Last Name</FormLabel>
                                        <FormControl type="text" name="witnessLastName" onChange={handleChange} value={formData.witnessLastName || ''} />
                                    </Col>
                                    <Col md={4}>
                                        <FormLabel>Witness Phone</FormLabel>
                                        <FormControl type="text" name="witnessPhone" onChange={handleChange} value={formData.witnessPhone || ''} />
                                    </Col>
                                </Row>
                            )}

                            <Form.Group controlId="reportingAgency">
                                <Form.Label>Reporting Agency</Form.Label>
                                <Form.Select name='reportingAgency' onChange={handleChange} value={formData.reportingAgency || ''}>
                                    <option value="" disabled selected>Select Reporting Agency</option>
                                    <option value="Local Police">Local Police</option>
                                    <option value="State Trooper">State Trooper</option>
                                    <option value="Other">Other</option>
                                </Form.Select>
                            </Form.Group>

                            <Row>
                                <Col md={6}>
                                    <FormLabel>Authority First Name</FormLabel>
                                    <FormControl type="text" name="authorityFirstName" onChange={handleChange} value={formData.authorityFirstName || ''} />
                                </Col>
                                <Col md={6}>
                                    <FormLabel>Authority Last Name</FormLabel>
                                    <FormControl type="text" name="authorityLastName" onChange={handleChange} value={formData.authorityLastName || ''} />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <FormLabel>Authority Phone</FormLabel>
                                    <FormControl type="text" name="authorityPhone" onChange={handleChange} value={formData.authorityPhone || ''} />
                                </Col>
                                <Col md={6}>
                                    <FormLabel>Authority Email</FormLabel>
                                    <FormControl type="text" name="authorityEmail" onChange={handleChange} value={formData.authorityEmail || ''} />
                                </Col>
                            </Row>

                            <FormLabel>Citations Issued?</FormLabel>
                            <FormControl as="select" name="citations" onChange={handleChange} value={formData.citations || ''}>
                                <option value="" disabled selected>Select...</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </FormControl>

                            <FormLabel>Were ANY vehicles towed from the accident?</FormLabel>
                            <FormControl as="select" name="tow" onChange={handleChange} value={formData.tow || ''}>
                                <option value="" disabled selected>Select...</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </FormControl>

                            <FormLabel>Was an Company Vehicle Towed?</FormLabel>
                            <FormControl as="select" name="companyTow" onChange={handleChange} value={formData.companyTow || ''}>
                                <option value="" disabled selected>Select...</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </FormControl>

                            {formData.companyTow === 'Yes' && (
                                <>
                                    <p>If Company Vehicle was towed, provide Towing Company info:</p>
                                    <Row>
                                        <Col md={6}>
                                            <FormLabel>Towing Company Name</FormLabel>
                                            <FormControl type="text" name="towCompanyName" onChange={handleChange} value={formData.towCompanyName || ''} />
                                        </Col>
                                        <Col md={6}>
                                            <FormLabel>Towing Company Phone</FormLabel>
                                            <FormControl type="text" name="towCompanyPhone" onChange={handleChange} value={formData.towCompanyPhone || ''} />
                                        </Col> 
                                    </Row>
                                </>
                            )}

                            <Form.Group controlId="driverSignatureCheckbox">
                                <Form.Check
                                    type="checkbox"
                                    id="driverSignatureCheckbox"
                                    label="I acknowledge that the information provided above is accurate and true."
                                    name="signature"
                                    onChange={handleCheckBoxChange}
                                    checked={formData.signature}
                                    style={{ marginTop: '10px' }}
                                />
                            </Form.Group>       
                        </Card.Body>
                    </Card>

                    {/* Amela's section */}

                    <Card border="secondary">
                        <Card.Header as="h4">To be filled out by supervisors/admin:</Card.Header>
                        <Card.Body>
                            <FormLabel>AccidentType</FormLabel>
                            <FormControl as="select" name="accidentType" onChange={handleChange} value={formData.accidentType || ''}>
                                <option value="" disabled selected>Select type...</option>
                                {accidentTypes.map((type, i) => <option value={type} key={i}>{type}</option>)}
                            </FormControl>

                            <FormLabel>Was accident preventable?</FormLabel>
                            <FormControl as="select" name="preventable" onChange={handleChange} value={formData.preventable || ''}>
                                <option value="" disabled selected>Select...</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </FormControl>

                            <FormLabel>DOT Recordable?</FormLabel>
                            <FormControl as="select" name="dotRecordable" onChange={handleChange} value={formData.dotRecordable || ''}>
                                <option value="" disabled selected>Select...</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="N/A">N/A</option>
                            </FormControl>

                            <FormLabel>Road/Property Type</FormLabel>
                            <FormControl as="select" name="roadType" onChange={handleChange} value={formData.roadType || ''}>
                                <option value="" disabled selected>Select type...</option>
                                <option value="HwyInterstate">Highway/Interstate</option>
                                <option value="USPS Lot">USPS Lot</option>
                                <option value="City Street">City Street</option>
                                <option value="Other">Other</option>
                            </FormControl>

                            <FormLabel>Root Cause</FormLabel>
                            <FormControl type='text' name='rootCause' onChange={handleChange} value={formData.rootCause || ''} />

                            <FormLabel>Camera footage available?</FormLabel>
                            <FormControl as="select" name="cameraFootage" onChange={handleChange} value={formData.cameraFootage || ''}>
                                <option value="" disabled selected>Select...</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </FormControl>
                            
                            {formData.cameraFootage === 'No' && (
                                <>
                                    <FormLabel>If no, explain</FormLabel>
                                    <FormControl as="textarea" name="cameraFootageExplanation" onChange={handleChange} value={formData.cameraFootageExplanation || ''} />                                
                                </>
                            )}

                            <FormLabel>Company Cost</FormLabel>
                            <FormControl type="text" name="companyCost" onChange={handleChange} value={formData.companyCost || ''} />

                            <FormLabel>Cost Type</FormLabel>
                            <FormControl as="select" name="costType" onChange={handleChange} value={formData.costType || ''}>
                                <option value="" disabled selected>Select...</option>
                                <option value="Deductible">Deductible</option>
                                <option value="Towing">Towing</option>
                                <option value="Out of Pocket">Out of Pocket</option>
                                <option value="Repair">Repair</option>
                                <option value="Other">Other</option>
                            </FormControl>

                            <FormLabel>Attorney represented?</FormLabel>
                            <FormControl as="select" name="attorney" onChange={handleChange} value={formData.attorney || ''}>
                                <option value="" disabled selected>Select...</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </FormControl>

                            <FormLabel>Driver Points to Assign</FormLabel>
                            <FormControl type="text" name="driverPoints" onChange={handleChange} value={formData.driverPoints || ''} />

                            <FormLabel>Claim Number</FormLabel>
                            <FormControl type="text" name="claimNumber" onChange={handleChange} value={formData.claimNumber || ''} />

                            <FormLabel>Claim Status</FormLabel>
                            <FormControl as="select" name="claimStatus" onChange={handleChange} value={formData.claimStatus || ''}>
                                <option value="" disabled selected>Select...</option>
                                <option value="Open">Open</option>
                                <option value="Closed">Closed</option>
                            </FormControl>

                            <FormLabel>Comments</FormLabel>
                            <FormControl as="textarea" name="comments" onChange={handleChange} value={formData.comments || ''} />   

                            <Button className="mt-2" variant="primary" type="button" onClick={handleSubmit}>Submit Report</Button>
                            <Button className="mt-2" variant="warning" type="button" onClick={handleResetForm} style={{ marginLeft: '5px'}}>Reset Form</Button>
                        </Card.Body>
                    </Card>
                </>
            }
        </Container>
    );
};

export default AccidentFormSection;
