import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Accordion, Alert, Badge, Button, Card, Col, Container, Form, Image, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, OverlayTrigger, Row, Spinner, Table, Tooltip } from "react-bootstrap";
import Api from "../../utils/Api";
import { useAuth } from "../../context/auth";
import { Link, useParams } from "react-router-dom";
import LogisticsLoading from "../../components/LogisticsLoading";
import AlertDisplay from "../../components/AlertDisplay";
import { findUSPSAddress, findUSPSNASSCode, findUSPSNassCodeTimezoneIANA, formatUSPSDateTime } from "../../context/uspsdata";
import { BiMailSend } from "react-icons/bi";
import GeofenceDriverDisplay from "../../components/GeofenceDriverDisplay";
import { Chart } from 'react-google-charts';
import DriverHOSLog from "../../components/DriverHOSLog";
import { FaMessage, FaXmark } from "react-icons/fa6";
import { useSettings } from "../../context/settings";
import { FaCopy, FaInfo, FaInfoCircle, FaMinus, FaNetworkWired, FaPaperPlane, FaPlus, FaWifi, FaWindowRestore, FaXing } from "react-icons/fa";
import { DateTime } from 'luxon';
import Tag from "../../components/TagButton";
import ConfirmationModal from "../../components/ConfirmationModal";
import PrintPDF from "../../components/PrintPDF";
import LoadTenderRateConfirmation from "./LoadTender.RateConfirmation";
import LoadTenderDriverConfirmation from "./LoadTender.DriverConfirmation";
import LoadTenderLoadConfirmation from "./LoadTender.LoadConfirmation";
import SignatureModal from "../../components/SignatureModal";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import LOGO from '../../assets/logo.png'
import ScrollToTopButton from "../../components/ScrollToTopButton";
import { EDI_214_SHIPMENT_STATUS_CODES } from "../../context/klienschmidtdata";
import { TbNetwork } from "react-icons/tb";
import ConfirmationDangerModal from "../../components/ConfirmationDangerModal";
import GeofenceBrokerDisplay from "../../components/GeofenceBrokerDisplay";
import { MdSignalWifiStatusbarNotConnected } from "react-icons/md";

function formatZuluToTZ(utcTime, ianaTz) {
    try {
        const date = new Date(utcTime);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short',
            timeZone: ianaTz,
            hourCycle: 'h23'
        };

        const formatter = new Intl.DateTimeFormat('en-US', options);
        const formatted = formatter.format(date);
        return formatted;
    } catch {
        return utcTime
    }

}

async function extractSamsaraHOSWaypoints(data) {
    const waypoints = [];

    for (const item of data) {
        try {
            const waypoint = {
                latitude: item.logRecordedLocation.latitude,
                longitude: item.logRecordedLocation.longitude,
                name: item.hosStatusType,
            };
            waypoints.push(waypoint);
        } catch (error) {
            continue;
        }
    }
    return waypoints;
}

function shipmentStopPunctualityScatterChart(linearizeLoadTenderAndLogs) {
    const goal = [0, 0, 'gold'];
    const chartData = [['Arrival', 'Arrival, Departure', { role: 'style' }], goal];

    for (const via of linearizeLoadTenderAndLogs) {
        const { eldOnPremiseArriveDiffMin, eldOnPremiseDepartDiffMin } = via;
        const arrive = Number(eldOnPremiseArriveDiffMin);
        const depart = Number(eldOnPremiseDepartDiffMin);

        // Check if arrive and depart are valid numbers
        if (!isNaN(arrive) && !isNaN(depart)) {
            // Determine the color based on arrive and depart values
            const color = getColorForPoint(arrive, depart);
            chartData.push([arrive, depart, color]);
        }
    }

    return chartData;
}

function shipmentStopPunctualityPieChart(linearizeLoadTenderAndLogs) {
    const headers = ["Appointments", "Ct", { role: "style" }];
    const data = [headers];
    let missing = 0, early = 0, late = 0;

    for (const via of linearizeLoadTenderAndLogs) {
        const arriveDiff = Number(via.eldOnPremiseArriveDiffMin);
        const departDiff = Number(via.eldOnPremiseDepartDiffMin);

        // Check if arriveDiff is a valid number
        if (!isNaN(arriveDiff)) {
            if (arriveDiff >= 0) early++;
            if (arriveDiff < 0) late++;
        } else {
            missing++;
        }

        // Check if departDiff is a valid number
        if (!isNaN(departDiff)) {
            if (departDiff >= 0) early++;
            if (departDiff < 0) late++;
        } else {
            missing++;
        }
    }

    data.push(['Missing', missing, 'grey']);
    data.push(['Early', early, 'green']);
    data.push(['Late', late, 'red']);

    return data;
}

function getColorForPoint(arrive, depart) {
    if (arrive >= 0 && depart >= 0) {
        return 'green'; // early, early
    } else if (arrive < 0 && depart < 0) {
        return 'red'; // late, late
    } else if (arrive >= 0 && depart < 0) {
        return 'blue'; // in early, out late
    } else if (arrive < 0 && depart >= 0) {
        return 'orange'; // in late, out early
    }
}

function StopPunctualityLegend() {
    return (
        <Card >
            <Card.Header>
                Stop Punctuality Legend
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col xs="auto">
                        <div style={{ backgroundColor: 'gold', width: '1.2em', height: '1.2em', borderRadius: '50%' }}></div>
                    </Col>
                    <Col>Origin (0, 0)</Col>
                </Row>
                <Row>
                    <Col xs="auto">
                        <div style={{ backgroundColor: 'green', width: '1.2em', height: '1.2em', borderRadius: '50%' }}></div>
                    </Col>
                    <Col>Early Arrivals & Early Departures</Col>
                </Row>
                <Row>
                    <Col xs="auto">
                        <Col style={{ backgroundColor: 'blue', width: '1.2em', height: '1.2em', borderRadius: '50%' }}> </Col>
                    </Col>
                    <Col>Early Arrivals & Late Departures</Col>
                </Row>
                <Row>
                    <Col xs="auto">
                        <Col style={{ backgroundColor: 'red', width: '1.2em', height: '1.2em', borderRadius: '50%' }}> </Col>
                    </Col>
                    <Col >Late Arrivals & Late Departures</Col>
                </Row>
                <Row>
                    <Col xs="auto">
                        <Col style={{ backgroundColor: 'orange', width: '1.2em', height: '1.2em', borderRadius: '50%' }}> </Col>
                    </Col>
                    <Col>Late Arrivals & Early Departures</Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

function millisecondsToHHMM(milliseconds) {
    const hours = Math.floor(milliseconds / 3600000);
    milliseconds %= 3600000;
    const minutes = Math.floor(milliseconds / 60000);
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
}

function objectToQueryString(obj) {
    return Object.keys(obj)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
        .join('&');
}

// type = request, accept, reject
const initalDriverConfirmation = {
    ShipmentId: '',
    response: '',
    type: '',
    email: '',
    phone: '',
    firstname: '',
    lastname: '',
    signature: '',
    requestorFirstName: '',
    requestorLastName: '',
    requestorSignature: '',
    equipment: '',
    date: '',
    miles: '',
    weight: '',
    stops: [],
    terms: '',
    billableAmount: '',
    deductions: [],
}

// type = request, accept, reject, canceled
const initalRateConfirmation = {
    ShipmentId: '',
    type: '',
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    equipment: '',
    date: '',
    miles: '',
    weight: '',
    stops: [],
    billToEmail: '',
    billToFirstName: '',
    billToLastName: '',
    signatureBillTo: '',
    billableAmount: '',
    deductions: [],
}



function LoadTenderUpdateV3({ backPath }) {
    const { settings } = useSettings();
    const { ShipmentId } = useParams();
    const { user, idToken } = useAuth();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    const [loadtenderLifeCycleStatus, setLoadTenderLifeCycleStatus] = useState('');
    const [loadRejected, setLoadRejected] = useState();
    const [set, didSet] = useState(false)
    const [didLoadData, setDidLoadData] = useState(false)
    const [loadtender, setLoadTender] = useState({});
    const [ratecons, setRateCons] = useState([]);

    const [signatureType, setSignatureType] = useState(null);
    const [showRequestorSignatureModal, setShowRequestorSignatureModal] = useState(false);
    const [showBillToSignatureModal, setShowBillToSignatureModal] = useState(false);


    const [newRateCon, setNewRateCon] = useState(initalRateConfirmation);
    const [newDriverCon, setNewDriverCon] = useState(initalDriverConfirmation);

    const [fetchedSamsaraServiceStatus, setFetchedSamsaraServiceStatus] = useState(false)
    const [fetchedMotiveServiceStatus, setFetchedMotiveServiceStatus] = useState(false)

    const [samsaraStatus, setSamsaraStatus] = useState();
    const [samsaraVehicles, setSamsaraVehicles] = useState();
    const [samsaraDrivers, setSamsaraDrivers] = useState();
    const [samsaraDriversByLocation, setSamsaraDriversByLocation] = useState();

    const [motiveStatus, setMotiveStatus] = useState();
    const [motiveDrivers, setMotiveDrivers] = useState();
    const [motiveDriversByLocation, setMotiveDriversByLocation] = useState();

    const [driverassign, setDriverAssign] = useState(false);
    const [driverremove, setDriverRemove] = useState(false);
    const [carriers, setCarriers] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [carrierassign, setCarrierAssign] = useState(false);
    const [carrierremove, setCarrierRemove] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [operatorlocation, setOperatorLocation] = useState('All');
    const [carrieroption, setCarrierOption] = useState();
    const [operatoroption, setOperatorOption] = useState();
    const [operatortype, setOperatorType] = useState();
    const [coverageoption, setCoverageOption] = useState('Assign');
    const [removeoption, setRemoveOption] = useState('Reassign');
    const [removecarrieroption, setRemoveCarrierOption] = useState('Reassign');

    const [toggleShowMap, setToggleShowMap] = useState(true)
    const [hosWaypoints, setHosWaypoints] = useState([]);

    const [isAuditLoading, setIsAuditLoading] = useState();
    const [loadtenderAudit, setLoadTenderAudit] = useState();
    const [geofences, setGeofences] = useState([]);
    const geofenceState = { geofences, setGeofences };
    const [circleGeofence, setCircleGeofence] = useState([]);
    const circleGeofenceState = { circleGeofence, setCircleGeofence };
    const [loadtenderWaypoints, setLoadTenderWaypoints] = useState([]);

    const [truckNumber, setTruckNumber] = useState('');
    const [show204Updates, setShow204Updates] = useState();
    const [show214Updates, setShow214Updates] = useState();
    const [show990Updates, setShow990Updates] = useState();
    const [show990Reject, setShow990Reject] = useState();
    const [showBrokered204Updates, setShowBrokered204Updates] = useState();
    const [showBrokered214Updates, setShowBrokered214Updates] = useState();
    const [showBrokered990Updates, setShowBrokered990Updates] = useState();
    const [showBrokeredTracking, setShowBrokeredTracking] = useState();
    const [showAllUpdates, setShowAllUpdates] = useState();
    const [showAllLocationUpdates, setShowAllLocationUpdates] = useState();
    const [showCancelEDI204, setShowCancelEDI204] = useState();

    const [shipmentNote, setShipmentNote] = useState('');

    const [tagoptions, setTagOptions] = useState([]);
    const [showtagmodal, setShowTagModal] = useState(false);
    const [shownotemodal, setShowNoteModal] = useState(false);

    const [untagtag, setUntagTag] = useState();
    const [showUntagModal, setShowUntagModal] = useState(false);
    const [showattachmentmodal, setShowAttachmentModal] = useState(false);

    const [selectedDriver, setSelectedDriver] = useState({});

    const [showdriverconformmodal, setShowDriverConFormModal] = useState(false);
    const [showdriverconfirmationmodal, setShowDriverConfirmationModal] = useState(false);
    const [showdriverrequestmodal, setShowDriverRequestModal] = useState(false);
    const [driverconfirmationindex, setDriverConfirmationIndex] = useState(false);
    const [showbilltoconfirmationmodal, setShowBillToConfirmationModal] = useState(false);
    const [showcarrierconfirmationmodal, setShowCarrierConfirmationModal] = useState(false);
    const [showloadtenderconfirmationsmodal, setShowLoadtenderConfirmationsModal] = useState(false);
    const [carrierconfirmationindex, setCarrierConfirmationIndex] = useState(false);
    const [shownewrateconfirmationmodal, setShowNewRateConfirmationModal] = useState(false);

    const [selectedCarrier, setSelectedCarrier] = useState();
    const [selectedContact, setSelectedContact] = useState();

    const [company, setCompany] = useState({});

    const [attachments, setAttachments] = useState([]);
    const [files, setFiles] = useState(null);
    const handleFileChange = (e) => {
        e.preventDefault();
        const selectedFiles = e.target.files;
        setFiles(selectedFiles);
    };

    const fetchSamsaraServiceStatus = async () => {
        try {
            const service = await Api.get('/samsara/statuscheck', idToken)
            setSamsaraStatus(service?.on)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occurred getting Samsara ELD integration status.' }])
        } finally {
            setFetchedSamsaraServiceStatus(true)
        }
    }

    const fetchMotiveServiceStatus = async () => {
        try {
            const service = await Api.get('/motive/statuscheck', idToken)
            setMotiveStatus(service?.on)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occurred getting Motive ELD integration status.' }])
        } finally {
            setFetchedMotiveServiceStatus(true)
        }
    }

    const fetchSamsaraDrivers = async () => {
        try {
            const driversdata = await Api.get(`/samsara/drivers-active`, idToken);
            const drivers = driversdata?.data;
            const groupedData = drivers.reduce((groups, record) => {
                const homeTerminalName = record?.carrierSettings?.homeTerminalName;
                if (!groups[homeTerminalName]) {
                    groups[homeTerminalName] = [];
                }
                groups[homeTerminalName].push(record);
                return groups;
            }, {});

            const sortedKeys = Object.keys(groupedData).sort();

            const sortedGroupedData = {};
            for (const key of sortedKeys) {
                sortedGroupedData[key] = groupedData[key];
            }

            setSamsaraDrivers(drivers)
            setSamsaraDriversByLocation(sortedGroupedData)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error fetching active drivers' }])
        }
    }

    const fetchSamsaraVehicles = async () => {
        try {
            const vehiclesdata = await Api.get(`/samsara/fleet`, idToken);
            const vehicles = vehiclesdata?.data;

            const sortedVehicles = vehicles.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });

            setSamsaraVehicles(sortedVehicles)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error fetching active drivers' }])
        }
    }

    const fetchMotiveDrivers = async () => {
        try {
            const driversdata = await Api.get(`/motive/drivers-active`, idToken);
            const drivers = driversdata;
            // const groupedData = drivers.reduce((groups, record) => {
            //     const homeTerminalName = record?.carrierSettings?.homeTerminalName;
            //     if (!groups[homeTerminalName]) {
            //         groups[homeTerminalName] = [];
            //     }
            //     groups[homeTerminalName].push(record);
            //     return groups;
            // }, {});

            // const sortedKeys = Object.keys(groupedData).sort();

            // const sortedGroupedData = {};
            // for (const key of sortedKeys) {
            //     sortedGroupedData[key] = groupedData[key];
            // }

            setMotiveDrivers(drivers)
            // setMotiveDriversByLocation(sortedGroupedData)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error fetching active drivers' }])
        }
    }

    const fetchMotiveVehicles = async () => {
        try {
            const vehiclesdata = await Api.get(`/samsara/fleet`, idToken);
            const vehicles = vehiclesdata?.data;

            const sortedVehicles = vehicles.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });

            setSamsaraVehicles(sortedVehicles)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error fetching active drivers' }])
        }
    }

    const fetchCompanies = async () => {
        try {
            const companiesData = await Api.get(`/companies/active`, idToken);
            setCompanies(companiesData);

        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error fetching active companies' }])
        }
    }

    const fetchCustomers = async () => {
        try {
            const customerData = await Api.get(`/customers/active`, idToken);
            setCustomers(customerData);

        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error fetching active customers' }])
        }
    }

    const fetch3PLCarriers = async () => {
        try {
            const carrierData = await Api.get(`/carriers/active`, idToken);
            setCarriers(carrierData);
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error fetching active carriers' }])
        }
    }

    const fetchTags = async () => {
        try {
            const tagData = await Api.get(`/edi/tags`, idToken);
            setTagOptions(tagData)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error fetching tags' }])
        }
    }



    const extractWaypoints = useCallback(async (data) => {
        async function getLatLngFromAddress(address) {
            try {
                const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
                const response = await fetch(url);

                if (!response.ok) {
                    throw new Error(`Geocoding request failed with status ${response.status}`);
                }

                const data = await response.json();

                if (data.results && data.results.length > 0) {
                    const location = data.results[0].geometry.location;
                    return {
                        latitude: location.lat,
                        longitude: location.lng,
                    };
                } else {
                    console.error('Geocoding request did not return valid data.');
                    return null;
                }
            } catch (error) {
                console.error('Error while fetching geocoding data:', error);
                return null;
            }
        }

        const waypoints = [];

        for (const item of data) {
            try {
                const address = findUSPSAddress(item.Name);
                const { latitude, longitude } = await getLatLngFromAddress(address);
                const waypoint = {
                    latitude: latitude,
                    longitude: longitude,
                    name: `${findUSPSNASSCode(item.Name)}`,
                };
                waypoints.push(waypoint);
            } catch (error) {
                continue;
            }
        }
        return waypoints;
    }, []);

    async function fetchLoadTender() {
        try {
            const load = await Api.get(`/loadtender/${ShipmentId}`, idToken)
            setLoadTender(load)

            const ltlcs = load?.edi204[0]?.loadtenderLifeCycleStatus;
            setLoadTenderLifeCycleStatus(ltlcs)

            const loadRejected = load.edi990.filter(e990 => e990.Response === 'Rejected')
            if (loadRejected.length > 0) {
                setLoadRejected(true)
            }

            const date = load?.edi204[0]?.Stops[0]?.RequestedDate;
            const yyymmdd = new Date(date).toISOString().slice(0, 10);
            const equipment = load?.edi204[0]?.Equipment;
            const weight = load?.edi204[0]?.Weight;
            const vias = load?.edi204[0]?.Stops.map(via => {
                return {
                    name: findUSPSNASSCode(via?.Name),
                    fullAddress: `${via?.Address1}${via?.Address2 ? ' ' + via?.Address2 : ''} ${via?.City} ${via?.State} ${via?.Zip} ${via?.Country}`,
                    arrive: via?.RequestedDate,
                    depart: via?.LastRequestedDate,
                }
            });

            setNewRateCon({
                ...newRateCon,
                equipment: equipment,
                weight: weight,
                date: yyymmdd,
                stops: vias,
                miles: '',
            })

            setNewDriverCon({
                ...newDriverCon,
                equipment: equipment,
                weight: weight,
                date: yyymmdd,
                stops: vias,
                miles: '',
            })

            const atttachments = load.edi204[0]?.attachments;
            const attach = [];
            if (atttachments) {
                for (const attachment of atttachments) {
                    if (typeof attachment === 'string') {
                        const { blob, contentType } = await Api.consumeFileStream(`/storage/file/${attachment}`, idToken);
                        const url = URL.createObjectURL(blob);
                        attach.push({ url: url, type: contentType });
                    } else if (Array.isArray(attachment)) {
                        for (const singleAttachment of attachment) {
                            const { blob, contentType } = await Api.consumeFileStream(`/storage/file/${singleAttachment}`, idToken);
                            const url = URL.createObjectURL(blob);
                            attach.push({ url: url, type: contentType });
                        }
                    }
                }
                setAttachments(attach);
            }

            // console.log(load.ediAudits)

            const waypoints = await extractWaypoints(load.edi204[0]?.Stops)
            setLoadTenderWaypoints(waypoints);
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching shipment.', }])
        } finally {
            didSet(true);
        }
    }

    const [searchTimeoutId, setSearchTimeoutId] = useState(null);
    const [appointmentTimeoutId, setAppointmentTimeoutId] = useState(null);

    const [searchTolerance, setSearchTolerance] = useState(1);
    const [appointmentTolerance, setAppointmentTolerance] = useState(1);

    async function fetchLoadTenderAudit() {
        try {
            const searchToleranceMin = searchTolerance * 60
            const appointmentToleranceMin = appointmentTolerance * 60
            const audit = await Api.get(`/loadtender/${ShipmentId}/audit/${searchToleranceMin}/${appointmentToleranceMin}`, idToken);
            if (audit) {
                setLoadTenderAudit(audit)
                const hosData = await Promise.all(
                    audit.map(async (report) => {
                        const driverLogs = await extractSamsaraHOSWaypoints(report?.hoslogs)
                        return {
                            driver: report?.operator?.name,
                            hoslogs: driverLogs
                        }
                    })
                );
                setHosWaypoints(hosData);
            }
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching audit.', }])
        } finally {
            setIsAuditLoading(false)
        }
    }

    async function handleCoverageAssign() {
        try {
            if (!operatoroption && !coverageoption) {
                throw new Error('To assign a driver, please select a Driver and Reason')
            }
            if (!operatoroption) {
                throw new Error('To assign a driver, please select a Driver')
            }
            if (!coverageoption) {
                throw new Error('To assign a driver, please select a Reason')
            }
            const edi204Update = {
                shipmentId: ShipmentId,
                operator: operatoroption,
                operatortype: operatortype,
                assignedby: user.email,
                assignedreason: coverageoption
            }
            await Api.patch(`/loadtender/${ShipmentId}/coverage-assign`, edi204Update, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Successfully assigned operator to Load Tender' }])
            didSet(false)
            setOperatorOption()
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Operatored assignment error' }])
        }
    }

    async function handleCoverageRemove() {
        try {
            if (!operatoroption && !removeoption) {
                throw new Error('To assign a driver, please select a Driver and remove Reason')
            }
            if (!operatoroption) {
                throw new Error('To assign a driver, please select a Driver')
            }
            if (!removeoption) {
                throw new Error('To assign a driver, please select a remove Reason')
            }
            const edi204Update = {
                shipmentId: ShipmentId,
                operator: JSON.parse(operatoroption),
                operatortype: 'samsara',
                removededby: user.email,
                removededreason: removeoption
            }

            await Api.patch(`/loadtender/${ShipmentId}/coverage-remove`, edi204Update, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Successfully removed operator from Load Tender' }])
            didSet(false)
            setOperatorOption()
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured removing operator from Load Tender.' }])
        }
    }

    async function handleCarrierAssign() {
        try {
            if (!carrieroption && !coverageoption) {
                throw new Error('To assign a driver, please select a Driver and Reason')
            }
            if (!carrieroption) {
                throw new Error('To assign a driver, please select a Driver')
            }
            if (!coverageoption) {
                throw new Error('To assign a driver, please select a Reason')
            }
            const edi204Update = {
                shipmentId: ShipmentId,
                carrier: carrieroption,
                carriertype: '',
                assignedby: user.email,
                assignedreason: coverageoption
            }
            await Api.patch(`/loadtender/${ShipmentId}/carrier-assign`, edi204Update, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Carrier Assign.' }])
            setCarrierOption()
            didSet(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured assigning carrier.' }])
        }
    }

    async function handleCarrierRemove() {
        try {
            if (!carrieroption && !removecarrieroption) {
                throw new Error('To remove a carrier, please select a Carrier and remove Reason')
            }

            if (!carrieroption) {
                throw new Error('To remove a carrier, please select a Carrier')
            }

            if (!removecarrieroption) {
                throw new Error('To remove a carrier, please select a remove Reason')
            }

            const edi204Update = {
                shipmentId: ShipmentId,
                carrier: carrieroption,
                carriertype: '',
                removededby: user.email,
                removededreason: removecarrieroption
            }

            await Api.patch(`/loadtender/${ShipmentId}/carrier-remove`, edi204Update, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Successfully removed carrier from Load Tender' }])
            didSet(false)
            setCarrierOption()
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: error.message }])
        }
    }

    const [pending204Response, setPending204Response] = useState();
    async function handleSendTradingPartner204(tradingPartner) {
        setPending204Response(true)
        try {
            const broker204 = {
                ShipmentId: ShipmentId,
                carrier: tradingPartner,
                sentBy: user.email,
            }

            const response = await Api.post(`/loadcnxn/outbound/204`, broker204, idToken);
            // console.log(response)
            setAlerts([...alerts, { variant: 'success', message: '204 sent successfully' }])
            fetchLoadTender();
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error sending 204' }])
        } finally {
            setPending204Response(false)
        }
    }

    async function handleSendTradingPartnerOriginal204(tradingPartner) {
        setPending204Response(true)
        try {
            const broker204 = {
                ShipmentId: ShipmentId,
                carrier: tradingPartner,
                sentBy: user.email,
            }

            const response = await Api.post(`/loadcnxn/outbound/204`, broker204, idToken);
            // console.log(response)
            setAlerts([...alerts, { variant: 'success', message: '204 sent successfully' }])
            fetchLoadTender();
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error sending 204' }])
        } finally {
            setPending204Response(false)
        }
    }

    async function handleCancelTradingPartner204(tradingPartner) {
        setPending204Response(true)
        setShowCancelEDI204(false)
        try {
            const cancel204 = {
                ShipmentId: ShipmentId,
                carrier: tradingPartner,
                sentBy: user.email,
            }

            const response = await Api.post(`/loadcnxn/outbound/204`, cancel204, idToken);
            // console.log(response)
            setAlerts([...alerts, { variant: 'success', message: 'Cancelation 204 sent successfully' }])
            fetchLoadTender();
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error sending 204' }])
        } finally {
            setPending204Response(false)
        }
    }

    async function handleAddNote() {
        try {
            if (shipmentNote.trim() !== '') {
                const newShipmentNote = {
                    shipmentNote: shipmentNote,
                    noteby: user.email,
                }
                await Api.patch(`/loadtender/${ShipmentId}/shipmentNote`, newShipmentNote, idToken)
                setAlerts([...alerts, { variant: 'success', message: 'Note created' }])
                setShipmentNote('')
                setShowNoteModal(false)
                fetchLoadTender();
            } else {
                setAlerts([...alerts, { variant: 'warning', message: 'The note cannot be empty.' }])
            }

        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: error.message }])
        }
    }

    async function handleTag(tag) {
        try {
            await Api.patch(`/loadtender/${ShipmentId}/tag`, { tag: tag }, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Tagged' }])
            fetchLoadTender();
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: error.message }])
        }
    }

    function handleUntagConfirm(tag) {
        setUntagTag(tag)
        setShowUntagModal(true)
    }

    async function handleUntag(tag) {
        try {
            await Api.patch(`/loadtender/${ShipmentId}/untag`, { tag: tag }, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Untagged' }])
            fetchLoadTender();
            setShowUntagModal(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: error.message }])
        }
    }

    async function handleUploadAttachment() {
        try {
            const formdata = new FormData();
            formdata.append('saveDir', `shipments/${ShipmentId}`);
            for (const file of files) {
                formdata.append('files', file);
            }

            const uploadResponse = await Api.uploadFiles('/storage/file', formdata, idToken);

            if (uploadResponse.ok) {
                const keys = uploadResponse.keys;
                const formResponse = await Api.patch(`/loadtender/${ShipmentId}/upload`, { attachments: keys }, idToken);
                if (formResponse.message) {
                    setAlerts([
                        ...alerts,
                        {
                            variant: "success",
                            message: `Success`,
                        },
                    ]);
                } else {
                    setAlerts([
                        ...alerts,
                        {
                            variant: "warning",
                            message: `Warning record did not save, please submit again.`,
                        },
                    ]);
                }
            } else {
                setAlerts([
                    ...alerts,
                    {
                        variant: "warning",
                        message: `${uploadResponse}`,
                    },
                ]);
            }
            setShowAttachmentModal(false)
            fetchLoadTender();
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured during upload.' }])
        }


    }

    // async function sendFourkitesUpdate() {
    //     try {
    //         const isTruckNumberValid = samsaraVehicles.some(vehicle => vehicle.name === truckNumber);
    //         if (truckNumber !== '' && isTruckNumberValid) {
    //             const res = await Api.post(`/loadtender/${ShipmentId}/fourkites-update`, { truckNumber: truckNumber }, idToken);
    //             setAlerts([...alerts, { variant: 'success', message: 'Update sent successfully.' + JSON.stringify(res) }])
    //             didSet(false)
    //         } else {
    //             setAlerts([...alerts, { variant: 'warning', message: 'Unit not detected' }])
    //         }
    //     } catch {
    //         setAlerts([...alerts, { variant: 'warning', message: 'An error occured sending update.' }])
    //     }
    // }

    const [response990Pending, setResponse990Pending] = useState(false)
    async function handleAcceptTender() {
        setResponse990Pending(true)
        try {
            const date = new Date();
            const utcTimestamp = date.toISOString();
            const accept990 = {
                "TransactionId": "990",
                "InternalTrackingNum": loadtender?.edi204[0]?.InternalTrackingNum,
                "Customer": loadtender?.edi204[0]?.Customer,
                "CustomerISA": loadtender?.edi204[0]?.CustomerISA,
                "SCAC": loadtender?.edi204[0]?.SCAC,
                "Response": "A",
                "ShipmentId": loadtender?.edi204[0]?.ShipmentId,
                "TxnNumber": loadtender?.edi204[0]?.TxnNumber,
                "Timestamp": utcTimestamp,
                "TimeZone": "UT",
                "Note": "",
                "RejectionReason": "",
                "References": ""
            }

            // implement switch/priority
            await Api.post(`/loadcnxn/outbound/990`, accept990, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Accepted!' }])
            fetchLoadTender();
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error while accepting. Try again.' }])
        } finally {
            setResponse990Pending(false)
        }
    }


    async function handleRejectTender() {
        setResponse990Pending(true)
        try {
            const date = new Date();
            const utcTimestamp = date.toISOString();
            const reject990 = {
                "TransactionId": "990",
                "InternalTrackingNum": loadtender?.edi204[0]?.InternalTrackingNum,
                "Customer": loadtender?.edi204[0]?.Customer,
                "CustomerISA": loadtender?.edi204[0]?.CustomerISA,
                "SCAC": loadtender?.edi204[0]?.SCAC,
                "Response": "D",
                "ShipmentId": loadtender?.edi204[0]?.ShipmentId,
                "TxnNumber": loadtender?.edi204[0]?.TxnNumber,
                "Timestamp": utcTimestamp,
                "TimeZone": "UT",
                "Note": "",
                "RejectionReason": "",
                "References": ""
            }

            // implement switch/priority
            await Api.post(`/loadcnxn/outbound/990`, reject990, idToken)
            // await Api.post(`/loadtender/${ShipmentId}/990reject`, reject990, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'Rejected!' }])
            setShow990Reject(false)
            fetchLoadTender();
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error while rejecting. Try again.' }])
        } finally {
            setResponse990Pending(false)
        }
    }

    // async function fetchWeatherForecast() {
    //     try {
    //         setAlerts([...alerts, { variant: 'success', message: 'Successfully fetched weather.' }])
    //     } catch {
    //         setAlerts([...alerts, { variant: 'warning', message: 'Unable to get weather.' }])
    //     }
    // }

    const filteredSamsaraDrivers = useMemo(() => {
        if (samsaraDrivers && samsaraDrivers.length > 0) {
            return samsaraDrivers.filter(item =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        } else {
            return [];
        }
    }, [samsaraDrivers, searchTerm]);

    const filteredMotiveDrivers = useMemo(() => {
        if (motiveDrivers && motiveDrivers.length > 0) {
            return motiveDrivers.filter(item =>
                item.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.last_name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        } else {
            return [];
        }
    }, [motiveDrivers, searchTerm]);


    async function runShipmentAudit() {
        setIsAuditLoading(true)
        try {
            const auditQuery = { SearchTolerance: searchTolerance, AppointmentTolerance: appointmentTolerance }
            const auditQueryStr = objectToQueryString(auditQuery);
            await Api.get(`/loadtender/${ShipmentId}/run-audit?${auditQueryStr}`, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'Shipment successfully audited.', }])
            didSet(false)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured running audit.', }])
        } finally {
            setIsAuditLoading(false)
        }
    }

    const [activeInput, setActiveInput] = useState({})
    function activateEdiInput(stop, action) {
        const stopIndex = stop - 1
        const ediStopTime = action === 'arrive'
            ? loadtender.edi204[0].Stops[stopIndex].RequestedDate
            : loadtender.edi204[0].Stops[stopIndex].LastRequestedDate;

        setEdiMessageStopUpdate({ timestamp: ediStopTime, stopnum: stop, action: action })
        if (activeInput.stop === stop && activeInput.action === action) {
            setActiveInput({})
        } else {
            setActiveInput({ stop, action })
        }
    }

    const [ediMessageStopUpdate, setEdiMessageStopUpdate] = useState({
        stopnum: '',
        action: '',
        timestamp: '',
    })

    const [response214Pending, setResponse214Pending] = useState();
    async function handleManualEdiStatusUpdate() {
        setResponse214Pending(true)
        setShowViaManualStatus([])
        try {
            // console.log(ediMessageStopUpdate)
            if (ediMessageStopUpdate?.action.trim() !== ''
                && ediMessageStopUpdate?.stopnum.trim() !== ''
                && ediMessageStopUpdate?.timestamp.trim() !== ''
            ) {
                const stopAction = ediMessageStopUpdate?.action;
                const stopTimestamp = ediMessageStopUpdate?.timestamp;
                const stopTimestampWsec = `${String(ediMessageStopUpdate?.timestamp)}`
                const stopDateTime = stopTimestamp.split('T')
                const stopDateCCYYMMDD = stopDateTime[0].split('-').join('');
                const stopTimeHHMM = stopDateTime[1].split(':').join('');

                const stopNum = Number(ediMessageStopUpdate?.stopnum);
                const edi204original = loadtender.edi204[0];
                const edi204newest = loadtender.edi204[loadtender.edi204.length - 1]

                let new214;
                let shipperstop, consigneestop, shipperindex, consigneeindex;
                let statusCode;
                const timeZoneAbbreviations = {
                    "America/New_York": "ET",
                    "America/Chicago": "CT",
                    "America/Denver": "MT",
                    "America/Los_Angeles": "PT"
                };
                const tzcode = timeZoneAbbreviations[settings.timezone];


                switch (stopAction) {
                    case ('eta'):
                        statusCode = stopNum === 1 ? 'AA' : 'AG'
                        break;
                    case ('arrive'):
                        statusCode = stopNum === 1 ? 'X3' : 'X1'
                        break;
                    case ('depart'):
                        statusCode = stopNum === 1 ? 'AF' : 'CD'
                        break;
                    default:
                        statusCode = '';
                }

                // console.log(statusCode)

                if (stopNum === 1) {
                    shipperstop = stopNum
                    consigneestop = stopNum + 1
                    shipperindex = stopNum - 1
                    consigneeindex = stopNum
                    new214 = {
                        "TransactionId": "214",
                        "InternalTrackingNum": edi204original?.InternalTrackingNum,
                        "Customer": edi204original?.CustomerISA,
                        "CustomerISA": edi204original?.CustomerISA,
                        "SCAC": edi204original?.SCAC,
                        "ShipmentId": edi204original?.ShipmentId,
                        "ApptNumber": shipperstop,
                        "StatusCode": statusCode,
                        "StatusDate": stopTimestampWsec,
                        "StatusTime": stopTimeHHMM,
                        "TimeZone": tzcode,
                        "City": edi204original?.BillTo.City,
                        "State": edi204original?.BillTo.State,
                        "Shipper": edi204newest?.Stops[shipperindex].Name,
                        // {
                        //     "StopNum": shipperstop,
                        //     "Name": edi204newest?.Stops[shipperindex].Name,
                        //     "Address1": edi204newest?.Stops[shipperindex].Address1,
                        //     "Address2": edi204newest?.Stops[shipperindex].Address2,
                        //     "City": edi204newest?.Stops[shipperindex].City,
                        //     "State": edi204newest?.Stops[shipperindex].State,
                        //     "Zip": edi204newest?.Stops[shipperindex].Zip,
                        //     "PickUp": true,
                        //     "DropOff": false,
                        //     "Reason": edi204newest?.Stops[shipperindex].Reason,
                        // },
                        "Consignee": edi204newest?.Stops[consigneeindex].Name
                        // {
                        //     "StopNum": consigneestop,
                        //     "Name": edi204newest?.Stops[consigneeindex].Name,
                        //     "Address1": edi204newest?.Stops[consigneeindex].Address1,
                        //     "Address2": edi204newest?.Stops[consigneeindex].Address2,
                        //     "City": edi204newest?.Stops[consigneeindex].City,
                        //     "State": edi204newest?.Stops[consigneeindex].State,
                        //     "Zip": edi204newest?.Stops[consigneeindex].Zip,
                        //     "PickUp": false,
                        //     "DropOff": true,
                        //     "Reason": edi204newest?.Stops[consigneeindex].Reason,
                        // }
                    }
                } else {
                    shipperstop = 1
                    consigneestop = stopNum
                    shipperindex = 0
                    consigneeindex = stopNum - 1
                    new214 = {
                        "TransactionId": "214",
                        "InternalTrackingNum": edi204original?.InternalTrackingNum,
                        "Customer": edi204original?.CustomerISA,
                        "CustomerISA": edi204original?.CustomerISA,
                        "SCAC": edi204original?.SCAC,
                        "ShipmentId": edi204original?.ShipmentId,
                        "ApptNumber": consigneestop,
                        "StatusCode": statusCode,
                        "StatusDate": stopTimestampWsec,
                        "StatusTime": stopTimeHHMM,
                        "TimeZone": tzcode,
                        "City": edi204original?.BillTo.City,
                        "State": edi204original?.BillTo.State,
                        "Shipper": edi204newest?.Stops[shipperindex].Name,
                        // {
                        //     "StopNum": shipperstop,
                        //     "Name": edi204newest?.Stops[shipperindex].Name,
                        //     "Address1": edi204newest?.Stops[shipperindex].Address1,
                        //     "Address2": edi204newest?.Stops[shipperindex].Address2,
                        //     "City": edi204newest?.Stops[shipperindex].City,
                        //     "State": edi204newest?.Stops[shipperindex].State,
                        //     "Zip": edi204newest?.Stops[shipperindex].Zip,
                        //     "PickUp": true,
                        //     "DropOff": false,
                        //     "Reason": edi204newest?.Stops[shipperindex].Reason,
                        // },
                        "Consignee": edi204newest?.Stops[consigneeindex].Name
                        // {
                        //     "StopNum": consigneestop,
                        //     "Name": edi204newest?.Stops[consigneeindex].Name,
                        //     "Address1": edi204newest?.Stops[consigneeindex].Address1,
                        //     "Address2": edi204newest?.Stops[consigneeindex].Address2,
                        //     "City": edi204newest?.Stops[consigneeindex].City,
                        //     "State": edi204newest?.Stops[consigneeindex].State,
                        //     "Zip": edi204newest?.Stops[consigneeindex].Zip,
                        //     "PickUp": false,
                        //     "DropOff": true,
                        //     "Reason": edi204newest?.Stops[consigneeindex].Reason,
                        // }
                    }
                }

                // implement switch/priority
                await Api.post(`/loadcnxn/outbound/214`, new214, idToken);
                setAlerts([...alerts, { variant: 'success', message: 'Status update sent successfully.', }])
                fetchLoadTender()
                fetchLoadTenderAudit();
            } else {
                setAlerts([...alerts, { variant: 'warning', message: 'Make sure you select ETA, Arrive, or Depart and select a date time.', }])
            }
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured sending manual status update.', error }])
        } finally {
            setResponse214Pending(false)
        }
    }

    function handleSearchToleranceChange(newTolerance) {
        if (newTolerance >= 0.25 && newTolerance <= 10) {
            setSearchTolerance(newTolerance);
            setAppointmentTolerance(newTolerance);

            clearTimeout(searchTimeoutId);
            const timeoutId = setTimeout(() => {
                fetchLoadTenderAudit();
            }, 1000);
            setSearchTimeoutId(timeoutId);
        }
    }

    function hoursToTime(hours) {
        let minutes = hours * 60;
        let timeStr = Math.floor(minutes / 60).toString().padStart(2, '0') + ':' + (minutes % 60).toString().padStart(2, '0');
        return timeStr;
    }

    function extractUniqueValues(arr) {
        const uniqueValues = new Set();

        arr.forEach(subArray => {
            subArray.forEach(value => {
                uniqueValues.add(value);
            });
        });

        return Array.from(uniqueValues);
    }

    function extractPrefixes(arr) {
        return arr.map(item => {
            const parts = item.split("_"); // Split the string into parts using the underscore character
            if (parts.length >= 2) {
                return parts.slice(0, 2).join("_"); // Combine the first two parts with an underscore
            } else {
                return item; // If there are fewer than 2 parts, return the original item
            }
        });
    }

    function extract204Contract(edi204) {
        const extractedReferences = edi204.Stops.map(via => via?.References.map(referance => referance?.Identifier));
        const extractedValues = extractUniqueValues(extractedReferences)
        const extractedData = extractPrefixes(extractedValues)
        const uniqueData = [...new Set(extractedData)];
        return uniqueData.join(', \n').replace('_', ' ')
    }


    function compare204Documents(doc1, doc2, keysToInclude = []) {
        const diffs = {};

        for (const key of keysToInclude) {
            if (typeof doc1[key] === 'object' && typeof doc2[key] === 'object') {
                const nestedDiffs = compare204Documents(doc1[key], doc2[key]);
                Object.assign(diffs, nestedDiffs);
            } else if (doc1[key] !== doc2[key]) {
                diffs[key] = [doc1[key], doc2[key]];
            }
        }

        return diffs;
    }

    function render204DocumentComparison(doc1, doc2, keysToInclude = []) {
        const diffs = compare204Documents(doc1, doc2, keysToInclude)


        if (Object.keys(diffs).length === 0) {
            return <i>Duplicate</i>
        }

        const { Rate, ...otherDiffs } = diffs

        return (
            <>
                {
                    Rate &&
                    <Row
                        style={(Number(Rate[0]) / 100).toFixed(2) > (Number(Rate[1]) / 100) ? { backgroundColor: '#bcdbbc' } : { backgroundColor: '#f39c9c' }}
                    >
                        <Col>Rate Change</Col>
                        <Col>Net Change: ${(Number(Rate[0]) / 100 - Number(Rate[1]) / 100).toFixed(2)}</Col>
                        <Col>Rate New: ${(Number(Rate[0]) / 100).toFixed(2)}</Col>
                        <Col>Rate Old: ${(Number(Rate[1]) / 100).toFixed(2)}</Col>
                    </Row>
                }
                <pre>
                    {Rate && JSON.stringify(Rate)}<br />
                    {Object.keys(otherDiffs).length > 0 && JSON.stringify(otherDiffs)}
                </pre>
            </>
        )
    }

    function handleSignatureClick(type) {
        setSignatureType(type)
        if (type === 'requestorSignature') {
            setShowRequestorSignatureModal(true);
        }
        if (type === 'signatureBillTo') {
            setShowBillToSignatureModal(true);
        }
    }

    function handleDriverConChange(e) {
        const { name, value } = e.target;

        setNewDriverCon(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    function handleDriverConInstructionChange(e, index) {
        const { name, value } = e.target;
        const theStops = newDriverCon.stops.filter(i => i !== index);
        const theStop = newDriverCon.stops[index]
        theStop[name] = value;
        setNewDriverCon(prevState => ({
            ...prevState,
            stops: [...theStops,]
        }));
    }


    function handleRateConInstructionChange(e, index) {
        const { name, value } = e.target;
        const updatedStops = [...newRateCon.stops];
        if (index >= 0 && index < updatedStops.length) {
            const updatedStop = { ...updatedStops[index] };
            updatedStop[name] = value;
            updatedStops[index] = updatedStop;
            setNewRateCon(prevState => ({
                ...prevState,
                stops: updatedStops
            }));
        }
    }

    async function requestDriverConfirmation() {
        try {
            const drivercon = {
                ShipmentId: ShipmentId,
                response: 'Created',
                phone: selectedDriver?.phone,
                email: selectedDriver?.email,
                firstname: selectedDriver?.firstname,
                lastname: selectedDriver?.lastname,
                equipment: newDriverCon?.equipment,
                billableAmount: newDriverCon?.billableAmount,
                date: newDriverCon?.date,
                miles: newDriverCon?.miles,
                weight: newDriverCon?.weight,
                stops: newDriverCon?.stops,
                terms: newDriverCon?.terms,
                requestorEmail: user?.email,
                requestorFirstName: newDriverCon?.requestorFirstName,
                requestorLastName: newDriverCon?.requestorLastName,
                requestorSignature: newDriverCon?.requestorSignature,
            }


            const result = await Api.post(`/loadtender/${ShipmentId}/driverconfirmation`, drivercon, idToken)
            setAlerts([...alerts, { variant: 'success', message: `Success` }])
            didSet(false)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured sending driver confirmation.', }])
        }
    }

    function handleRateConChange(e) {
        const { name, value } = e.target;

        setNewRateCon(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    async function requestRateConfirmation() {
        try {
            const ratecon = {
                ShipmentId: ShipmentId,
                response: 'Created',
                billableAmount: newRateCon?.billableAmount,
                carrier: newRateCon?.carrier,
                weight: newRateCon?.weight,
                stops: newRateCon?.stops,
                date: newRateCon?.date,
                miles: newRateCon?.miles,
                equipment: newRateCon?.equipment,
                billToEmail: user?.email,
                billToFirstName: newRateCon?.billToFirstName,
                billToLastName: newRateCon?.billToLastName,
                signatureBillTo: newRateCon?.signatureBillTo,
            }
            const result = await Api.post(`/loadtender/${ShipmentId}/rateconfirmation`, ratecon, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success', }])
            didSet(false)
            setShowNewRateConfirmationModal(false)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured sending rate confirmation.', }])
        }
    }

    const handleSaveRequestorSignature = (signatureDataURL, signatureType) => {
        setNewDriverCon(prevForm => ({
            ...prevForm,
            [signatureType]: signatureDataURL
        }));

        setShowRequestorSignatureModal(false);
    };

    const handleClearRequestorSignature = (signatureType) => {
        setNewRateCon(prevForm => ({
            ...prevForm,
            [signatureType]: ''
        }));
    };

    const handleSaveBillToSignature = (signatureDataURL, signatureType) => {
        setNewRateCon(prevForm => ({
            ...prevForm,
            [signatureType]: signatureDataURL
        }));

        setShowBillToSignatureModal(false);
    };

    const handleClearBillToSignature = (signatureType) => {
        setNewRateCon(prevForm => ({
            ...prevForm,
            [signatureType]: ''
        }));
    };


    useEffect(() => {
        if (!set) {
            fetchLoadTender();
            fetchLoadTenderAudit();
        }

    }, [set])

    useEffect(() => {
        if (!didLoadData) {
            fetchCustomers();
            fetchCompanies();
            fetch3PLCarriers();
            fetchTags();
        }

        return setDidLoadData(true);
    }, [didLoadData])

    useEffect(() => {
        if (!fetchedSamsaraServiceStatus) {
            fetchSamsaraServiceStatus();
        }

        if (fetchedSamsaraServiceStatus) {
            if (samsaraStatus) {
                fetchSamsaraDrivers();
                fetchSamsaraVehicles();
            }

        }
    }, [fetchedSamsaraServiceStatus])

    useEffect(() => {
        if (!fetchedMotiveServiceStatus) {
            fetchMotiveServiceStatus();
        }

        if (fetchedMotiveServiceStatus) {
            if (motiveStatus) {
                fetchMotiveDrivers();
                fetchMotiveVehicles();
            }
        }
    }, [fetchedMotiveServiceStatus])


    useEffect(() => {
        didSet(false)
    }, [ShipmentId])


    const LifeCycleStatusBadge = ({ loadtenderLifeCycleStatus }) => {
        const backgroundColor =
            loadtenderLifeCycleStatus === 'Accepted' ? '#bcdbbc' :
                loadtenderLifeCycleStatus === 'Brokered' ? '#f8f39c' :
                    loadtenderLifeCycleStatus === 'Rejected' ? 'red' : '#eee';

        const badgeStyle = {
            padding: '0.1em 0.25em',
            backgroundColor: backgroundColor,
            border: '1px solid #ccc'
        }

        return (
            <div style={badgeStyle}>
                {loadtenderLifeCycleStatus}
            </div>
        )
    }

    const PrintShipmentRoute = ({ stops }) => {
        return (
            <Table responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Scheduled Arrive</th>
                        <th>Scheduled Depart</th>
                    </tr>
                </thead>
                <tbody>
                    {stops && stops.map((via, i) => (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{findUSPSNASSCode(via.Name)}</td>
                            <td>{via.Address1}{via.Address2} {via.City} {via.State} {via.Zip}</td>
                            <td>{formatUSPSDateTime(via.Name, via.RequestedDate)}</td>
                            <td>{formatUSPSDateTime(via.Name, via.LastRequestedDate)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }

    const copyToClipboard = () => {
        if (loadtender.edi204[0]._id) {
            const url = `${process.env.REACT_APP_BASE_URL}/public/loadtender?q=${loadtender.edi204[0]._id}`;
            navigator.clipboard.writeText(url)
                .then(() => {
                    setAlerts([...alerts, { variant: 'success', message: 'Link copied to clipboard!' }]);
                })
                .catch(err => {
                    console.error('Failed to copy: ', err);
                });
        } else {
            setAlerts([...alerts, { variant: 'warning', message: 'Nothing to copy.' }]);
        }
    };

    const [showViaMannualStatus, setShowViaManualStatus] = useState([])

    function calculateDifferenceInMinutes(scheduled, actual) {
        if (!scheduled || !actual) {
            return null;
        }

        const sched = new Date(scheduled);
        const act = new Date(actual);

        if (isNaN(sched) || isNaN(act)) {
            return null;
        }

        const differenceInMs = sched - act;
        const differenceInMinutes = differenceInMs / 60000;
        if (differenceInMinutes >= 0) {
            return (
                <span style={{ backgroundColor: '#bcdbbc' }}>{differenceInMinutes}<sub>min</sub> On Time</span>
            )
        }
        if (differenceInMinutes < 0) {
            return (
                <span style={{ backgroundColor: '#f39c9c' }}>{differenceInMinutes}<sub>min</sub> Late</span>
            )
        }
        return
    }

    const [showMobileMessaging, setShowMobileMessaging] = useState([]);

    return (
        <Container>
            <AlertDisplay alertState={alertState} />
            {/* tag modal */}
            <Modal show={showtagmodal} size="lg">
                <ModalHeader onClose={() => setShowNoteModal(false)}>
                    <ModalTitle>Tag Shipment</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        {tagoptions.length > 0 ?
                            tagoptions?.map((tag, t) => (
                                <Col xs="auto" key={t}>
                                    <Tag
                                        name={tag.name}
                                        fontColorHEX={tag.fontColorHEX}
                                        backgroundColorHEX={tag.backgroundColorHEX}
                                        handleFunction={() => handleTag(tag)}
                                    />
                                </Col>
                            ))
                            :
                            <Col>No tags.</Col>
                        }
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button variant="secondary" onClick={() => setShowTagModal(false)}>Close</Button>
                </ModalFooter>
            </Modal>
            {/* note modal */}
            <Modal show={shownotemodal} size="lg">
                <ModalHeader>
                    <ModalTitle>Shipment Note</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Form.Control
                                as="textarea"
                                value={shipmentNote}
                                onChange={e => setShipmentNote(e.target.value)}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button variant="primary" onClick={() => handleAddNote()}>Add</Button>
                    <Button variant="secondary" onClick={() => setShowNoteModal(false)}>Close</Button>
                </ModalFooter>
            </Modal>
            {/* attachment modal */}
            <Modal show={showattachmentmodal} size="lg">
                <ModalHeader>
                    <ModalTitle>Attachment</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Card.Subtitle>
                                Allowed file types: jpg, jpeg, png, pdf
                            </Card.Subtitle>
                            <br />
                            <Form.Control
                                type="file"
                                name="attachment"
                                label={files ? files.length > 1 ? `${files.length} files selected` : files[0].name : "Choose file"}
                                onChange={handleFileChange}
                                accept=".jpg, .jpeg, .png, .pdf"
                                multiple
                                required
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button variant="primary" onClick={() => handleUploadAttachment()}>Upload</Button>
                    <Button variant="secondary" onClick={() => setShowAttachmentModal(false)}>Close</Button>
                </ModalFooter>
            </Modal>
            {/* New Driver Confirmation */}
            <Modal show={showdriverconformmodal} size="xl" onHide={() => setShowDriverConFormModal(false)}>
                <ModalHeader closeButton>
                    <ModalTitle>New Driver Confirmation</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <div className='header'>
                        <Row style={{ backgroundColor: '#eee', padding: 0, margin: 0 }}>
                            <h2>Driver's Shipping Instructions</h2>
                        </Row>
                    </div>
                    {/* Desktop Table View */}
                    <div className='d-none d-md-block'>
                        <Table bordered hover size='sm'>
                            <thead className="table-secondary">
                                <tr >
                                    <th className='small'>Driver</th>
                                    <th className='small'>Phone</th>
                                    <th className='small'>Email</th>
                                    <th className='small'>Equipment</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <Row>
                                            <Col xs="auto">
                                                <Form.Control
                                                    type="text"
                                                    value={selectedDriver?.firstname}
                                                    name="firstname"
                                                    readOnly="true"
                                                    onChange={handleDriverConChange}
                                                />
                                            </Col>
                                            <Col xs="auto">
                                                <Form.Control
                                                    type="text"
                                                    value={newDriverCon?.lastname}
                                                    name="lastname"
                                                    readOnly="true"
                                                    onChange={handleDriverConChange}
                                                />
                                            </Col>
                                        </Row>
                                    </td>
                                    <td>
                                        <Form.Control
                                            type="text"
                                            value={selectedDriver?.phone}
                                            name="phone"
                                            readOnly="true"
                                            onChange={handleDriverConChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type="text"
                                            name="email"
                                            value={selectedDriver?.email}
                                            readOnly="true"
                                            onChange={handleDriverConChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type="text"
                                            name="equipment"
                                            value={newDriverCon?.equipment}
                                            readOnly="true"
                                            onChange={handleDriverConChange}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                            <thead className="table-secondary">
                                <tr >
                                    <th className='small'>Shipment</th>
                                    <th className='small'>Date</th>
                                    <th className='small'>Miles</th>
                                    <th className='small'>Weight (lbs)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <Form.Control
                                            type='text'
                                            size='sm'
                                            name='ShipmentId'
                                            value={ShipmentId}
                                            onChange={handleDriverConChange}
                                            readOnly={true}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type='date'
                                            size='sm'
                                            name='date'
                                            value={newRateCon?.date}
                                            onChange={handleDriverConChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type='number'
                                            size='sm'
                                            name='miles'
                                            value={newRateCon?.miles}
                                            onChange={handleDriverConChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type='number'
                                            size='sm'
                                            name='weight'
                                            value={newRateCon?.weight}
                                            onChange={handleDriverConChange}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        {newRateCon?.stops.length > 0 && newRateCon?.stops.map((via, i) => (
                            <Table bordered hover size='sm' key={i} className={`small`} >
                                <thead className='table-secondary'>
                                    <tr>
                                        <th colSpan={3}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                                                <span>Stop {i + 1}</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={3}>
                                            <td>{via?.name} | <a target="_blank" rel="noreferrer" href={`https://www.google.com/maps/search/${via.fullAddress}`}>{via?.fullAddress}</a></td>
                                        </td>
                                    </tr>
                                </tbody>
                                <thead className='table-secondary' >
                                    <tr>
                                        <th>Arrival Date/Time</th>
                                        <th>Departure Date/Time</th>
                                        <th>Special Instructions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{via?.arrive}</td>
                                        <td>{via?.depart}</td>
                                        <td>
                                            <Form.Control
                                                as="textarea"
                                                name="specialInstructions"
                                                placeholder="additional information"
                                                value={via?.specialInstructions}
                                                onChange={(e) => handleDriverConInstructionChange(e, i)}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        ))}
                        {/* {tables} */}
                    </div>

                    {/* Card View for mobile */}
                    <div className='d-md-none'>
                        <Col>
                            <Table bordered hover size='sm' style={{ border: '1px solid grey' }}>
                                <tbody className='small'>
                                    <tr>
                                        <th className="table-secondary">Driver</th>
                                        <td>
                                            <Row>
                                                <Col>
                                                    <Form.Control type='text' size='sm'
                                                        value={newRateCon?.firstname}
                                                        readOnly={true}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Control type='text' size='sm'
                                                        value={newRateCon?.lastname}
                                                        readOnly={true}
                                                    />
                                                </Col>
                                            </Row>
                                            <pre>
                                                Company : {(selectedCarrier?.name)}<br />
                                                Address : {(selectedCarrier?.fullAddress)}<br />
                                                MC# : {(selectedCarrier?.mcNumber)}<br />
                                                DOT# : {(selectedCarrier?.dotNumber)}<br />
                                            </pre>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="table-secondary">Carrier Phone:</th>
                                        <td>
                                            <Form.Control type='text' size='sm'
                                                value={newRateCon?.phone}
                                                readOnly={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="table-secondary">Equipment:</th>
                                        <td>
                                            <Form.Control type='text' size='sm'
                                                value={newRateCon?.equipment}
                                                readOnly={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="table-secondary">Shipment Id</th>
                                        <td>
                                            <Form.Control type='text' size='sm'
                                                value={newRateCon?.ShipmentId}
                                                readOnly={true}

                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="table-secondary">Date</th>
                                        <td>
                                            <Form.Control type='date' size='sm'
                                                value={newRateCon?.date}
                                                readOnly={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="table-secondary">Miles</th>
                                        <td>
                                            <Form.Control type='number' size='sm'
                                                value={newRateCon?.miles}
                                                readOnly={true}
                                            /></td>
                                    </tr>
                                    <tr>
                                        <th className="table-secondary">Weight</th>
                                        <td><Form.Control type='number' size='sm'
                                            value={newRateCon?.weight}
                                            readOnly={true}

                                        />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Table bordered hover size='sm' style={{ border: '1px solid grey' }}>
                                <tbody className='small'>
                                    {newRateCon?.stops && newRateCon?.stops.map((via, i) => (
                                        <tr key={i}>
                                            <th className="table-secondary">
                                                <span>Stop {i + 1}</span>
                                            </th>
                                            <td>
                                                <p>{via?.name}</p>
                                                <p>{via?.fullAddress}</p>
                                                <p>{via?.arrive}</p>
                                                <p>{via?.depart}</p>
                                                <p>{via?.specialInstructions}</p>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </div>

                    {/* Terms */}
                    <div className='Terms my-2'>
                        <Table bordered hover size='sm'>
                            <thead className='table-secondary'>
                                <tr>
                                    <th>Terms:</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <Form.Control
                                            as='textarea'
                                            rows={3}
                                            name='terms'
                                            value={newDriverCon?.terms}
                                            onChange={handleDriverConChange}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <Row>
                        <>
                            <div className='mt-2'>
                                <Row>
                                    <Row>
                                        <Col>
                                            (Requestor)
                                        </Col>
                                    </Row>
                                    <Col sm={4}>
                                        <Form.Group>
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='requestorFirstName'
                                                value={newDriverCon?.requestorFirstName}
                                                placeholder='Enter first name'
                                                onChange={handleDriverConChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group>
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='requestorLastName'
                                                value={newDriverCon?.requestorLastName}
                                                placeholder='Enter last name'
                                                onChange={handleDriverConChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group controlId="signature">
                                            <Form.Label>Signature</Form.Label>
                                            <div style={{ height: '38px', display: 'flex', alignItems: 'center' }}>
                                                {newDriverCon?.requestorSignature ? (
                                                    <div
                                                        style={{ border: '1px solid #ced4da', borderRadius: '6px', height: '100%', width: '100%', cursor: 'pointer', display: 'flex', justifyContent: 'center' }}
                                                        onClick={() => handleSignatureClick('requestorSignature')}
                                                    >
                                                        <img
                                                            src={newDriverCon?.requestorSignature}
                                                            alt="Requestor Signature"
                                                            style={{ maxHeight: '100px', maxWidth: '100%' }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Click to sign"
                                                        onClick={() => handleSignatureClick('requestorSignature')}
                                                        value={newDriverCon?.requestorSignature}
                                                        readOnly
                                                    />
                                                )}
                                            </div>
                                        </Form.Group>

                                    </Col>
                                </Row>
                            </div>
                        </>
                    </Row>
                </ModalBody>
                <Modal.Footer>
                    <Row>
                        <Col>
                            <Button variant="secondary" onClick={() => setShowDriverConFormModal(false)}>Close</Button>
                        </Col>
                        {newDriverCon?.requestorSignature &&
                            <Col xs="auto">
                                <Button variant="primary" onClick={() => requestDriverConfirmation()}>Send</Button>
                            </Col>
                        }
                    </Row>
                </Modal.Footer>
            </Modal>
            {/* New Rate Confirmation */}
            <Modal show={shownewrateconfirmationmodal} size="xl" onHide={() => setShowNewRateConfirmationModal(false)}>
                <ModalHeader closeButton>
                    <ModalTitle>New Rate Confirmation</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <div className='header'>
                        <Row style={{ backgroundColor: '#eee', padding: 0, margin: 0 }}>
                            <h2>Carrier's Shipping Instructions & Rate Confirmation</h2>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control as="select" onChange={e => setCompany(JSON.parse(e.target.value))}>
                                    <option value="">Bill To:</option>
                                    {companies.map((company, i) => (
                                        <option key={i} value={JSON.stringify(company)}>{company.name}</option>
                                    ))}
                                </Form.Control>
                                <br />
                                <h4>Bill To</h4>
                                <p>
                                    {company?.name} <br />
                                    {company?.address} <br />
                                    {company?.website} <br />
                                </p>
                            </Col>
                            <Col xs="auto" style={{ padding: '1em', margin: 0 }}>
                                <Image src={LOGO} style={{ maxHeight: '200px', maxWidth: '100%' }} />
                            </Col>
                        </Row>
                    </div>

                    {/* Desktop Table View */}
                    <div className='d-none d-md-block'>
                        <Table bordered hover size='sm'>
                            <thead className="table-secondary">
                                <tr >
                                    <th className='small'>Carrier</th>
                                    <th className='small'>Contact</th>
                                    <th className='small'>Equipment</th>
                                    <th className='small'>Total Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <Form.Control as="select" onChange={(e) => setSelectedCarrier(JSON.parse(e.target.value))}>
                                            <option value="">Select Carrier</option>
                                            {carriers.map((carrier, i) => (
                                                <option key={i} value={JSON.stringify(carrier)}>{carrier.name}</option>
                                            ))}
                                        </Form.Control>
                                        <pre>
                                            POC : {(selectedCarrier?.firstname)} {(selectedCarrier?.lastname)}<br />
                                            Company : {(selectedCarrier?.name)}<br />
                                            Address : {(selectedCarrier?.address)}<br />
                                            MC# : {(selectedCarrier?.mcNumber)}<br />
                                            DOT# : {(selectedCarrier?.dotNumber)}<br />
                                        </pre>
                                    </td>
                                    <td>
                                        <Form.Control as="select" onChange={(e) => setSelectedContact(JSON.parse(e.target.value))}>
                                            <option value="">Select Carrier</option>
                                            {selectedCarrier?.contacts.map((contact, i) => (
                                                <option key={i} value={JSON.stringify(contact)}>{contact.name}</option>
                                            ))}
                                        </Form.Control>
                                        <pre>
                                            {JSON.stringify(selectedContact, "", 2)}
                                        </pre>
                                    </td>
                                    <td>
                                        <Form.Control
                                            as="select"
                                            name='equipment'
                                            value={newRateCon?.equipment}
                                            onChange={handleRateConChange}
                                        >
                                            <option value="">Select Equipment...</option>
                                        </Form.Control>
                                    </td>
                                    <td>
                                        <Form.Control
                                            type='number'
                                            name='billableAmount'
                                            step="0.01"
                                            value={newRateCon?.billableAmount}
                                            onChange={handleRateConChange}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                            <thead className="table-secondary">
                                <tr >
                                    <th className='small'>Shipment</th>
                                    <th className='small'>Date</th>
                                    <th className='small'>Miles</th>
                                    <th className='small'>Weight (lbs)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <Form.Control
                                            type='text'
                                            size='sm'
                                            name='ShipmentId'
                                            value={ShipmentId}
                                            onChange={handleRateConChange}
                                            readOnly={true}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type='date'
                                            size='sm'
                                            name='date'
                                            value={newRateCon?.date}
                                            onChange={handleRateConChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type='number'
                                            size='sm'
                                            name='miles'
                                            value={newRateCon?.miles}
                                            onChange={handleRateConChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type='number'
                                            size='sm'
                                            name='weight'
                                            value={newRateCon?.weight}
                                            onChange={handleRateConChange}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        {newRateCon?.stops.length > 0 && newRateCon?.stops.map((via, i) => (
                            <Table bordered hover size='sm' key={i} className={`small`} >
                                <thead className='table-secondary'>
                                    <tr>
                                        <th colSpan={3}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                                                <span>Stop {i + 1}</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={3}>
                                            <td>{via?.name} | <a target="_blank" href={`https://www.google.com/maps/search/${via.fullAddress}`}>{via?.fullAddress}</a></td>
                                        </td>
                                    </tr>
                                </tbody>
                                <thead className='table-secondary' >
                                    <tr>
                                        <th>Arrival Date/Time</th>
                                        <th>Departure Date/Time</th>
                                        <th>Special Instructions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{via?.arrive}</td>
                                        <td>{via?.depart}</td>
                                        <td>
                                            <Form.Control
                                                as="textarea"
                                                name="specialInstructions"
                                                placeholder="additional information"
                                                value={via?.specialInstructions}
                                                onChange={(e) => handleRateConInstructionChange(e, i)}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        ))}
                        {/* {tables} */}
                    </div>

                    {/* Card View for mobile */}
                    <div className='d-md-none'>
                        <Col>

                            <Table bordered hover size='sm' style={{ border: '1px solid grey' }}>
                                <tbody className='small'>
                                    <tr>
                                        <th className="table-secondary">Carrier</th>
                                        <td>
                                            <Row>
                                                <Col>
                                                    <Form.Control type='text' size='sm'
                                                        value={newRateCon?.firstname}
                                                        readOnly={true}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Control type='text' size='sm'
                                                        value={newRateCon?.lastname}
                                                        readOnly={true}
                                                    />
                                                </Col>
                                            </Row>
                                            <pre>
                                                Company : {(selectedCarrier?.name)}<br />
                                                Address : {(selectedCarrier?.fullAddress)}<br />
                                                MC# : {(selectedCarrier?.mcNumber)}<br />
                                                DOT# : {(selectedCarrier?.dotNumber)}<br />
                                            </pre>
                                            {/* <p>{JSON.stringify(Object.keys(selectedCarrier))}</p> */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="table-secondary">Carrier Phone:</th>
                                        <td>
                                            <Form.Control type='text' size='sm'
                                                value={newRateCon?.phone}
                                                readOnly={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="table-secondary">Equipment:</th>
                                        <td>
                                            <Form.Control type='text' size='sm'
                                                value={newRateCon?.equipment}
                                                readOnly={true}

                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="table-secondary">Shipment Id</th>
                                        <td>
                                            <Form.Control type='text' size='sm'
                                                value={newRateCon?.ShipmentId}
                                                readOnly={true}

                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="table-secondary">Date</th>
                                        <td>
                                            <Form.Control type='date' size='sm'
                                                value={newRateCon?.date}
                                                readOnly={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="table-secondary">Miles</th>
                                        <td>
                                            <Form.Control type='number' size='sm'
                                                value={newRateCon?.miles}
                                                readOnly={true}
                                            /></td>
                                    </tr>
                                    <tr>
                                        <th className="table-secondary">Weight</th>
                                        <td><Form.Control type='number' size='sm'
                                            value={newRateCon?.weight}
                                            readOnly={true}

                                        />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Table bordered hover size='sm' style={{ border: '1px solid grey' }}>
                                <tbody className='small'>
                                    {newRateCon?.stops && newRateCon?.stops.map((via, i) => (
                                        <tr key={i}>
                                            <th className="table-secondary">
                                                <span>Stop {i + 1}</span>
                                            </th>
                                            <td>
                                                <p>{via?.name}</p>
                                                <p>{via?.fullAddress}</p>
                                                <p>{via?.arrive}</p>
                                                <p>{via?.depart}</p>
                                                <p>{via?.specialInstructions || <i>Nothing provided.</i>}</p>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </div>

                    {/* Terms */}
                    <div className='Terms my-2'>
                        <Table bordered hover size='sm'>
                            <thead className='table-secondary'>
                                <tr>
                                    <th>Terms:</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <Form.Control
                                            as='textarea'
                                            rows={3}
                                            name='terms'
                                            value={newRateCon?.terms}
                                            onChange={handleRateConChange}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    <Row>
                        <>
                            <div className='mt-2'>
                                <Row>
                                    <Row>
                                        <Col>
                                            (Bill To Representative)
                                        </Col>
                                    </Row>
                                    <Col sm={4}>
                                        <Form.Group>
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='billToFirstName'
                                                value={newRateCon?.billToFirstName}
                                                placeholder='Enter first name'
                                                onChange={handleRateConChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group>
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='billToLastName'
                                                value={newRateCon?.billToLastName}
                                                placeholder='Enter last name'
                                                onChange={handleRateConChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group controlId="signature">
                                            <Form.Label>Signature</Form.Label>
                                            <div style={{ height: '38px', display: 'flex', alignItems: 'center' }}>
                                                {newRateCon?.signatureBillTo ? (
                                                    <div
                                                        style={{ border: '1px solid #ced4da', borderRadius: '6px', height: '100%', width: '100%', cursor: 'pointer', display: 'flex', justifyContent: 'center' }}
                                                        onClick={() => handleSignatureClick('signatureBillTo')}
                                                    >
                                                        <img
                                                            src={newRateCon?.signatureBillTo}
                                                            alt="Signature"
                                                            style={{ maxHeight: '100px', maxWidth: '100%' }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Click to sign"
                                                        readOnly
                                                        onClick={() => handleSignatureClick('signatureBillTo')}
                                                        value={newRateCon?.signatureBillTo}
                                                    />
                                                )}
                                            </div>
                                        </Form.Group>

                                    </Col>
                                </Row>
                            </div>
                        </>
                    </Row>

                </ModalBody>
                <Modal.Footer>
                    <Row>
                        <Col>
                            <Button variant="secondary" onClick={() => setShowNewRateConfirmationModal(false)}>Close</Button>
                        </Col>
                        {newRateCon?.signatureBillTo &&
                            <Col xs="auto">
                                <Button variant="primary" onClick={() => requestRateConfirmation()}>Send</Button>
                            </Col>
                        }
                    </Row>
                </Modal.Footer>
            </Modal>
            {/* driver request confirmation */}
            <Modal show={showdriverrequestmodal} size="xl" onHide={() => setShowDriverRequestModal(false)}>
                <ModalHeader closeButton>
                    <ModalTitle>Driver Request</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    {(set && loadtender && loadtender?.edi204 && loadtender?.edi204.length > 0 && loadtender?.edi204[0]?.driverconfirmations && driverconfirmationindex !== null) &&
                        <LoadTenderLoadConfirmation feature={'driver'} form={{ ...loadtender?.edi204[0]?.driverconfirmations[driverconfirmationindex] }} />
                    }
                </ModalBody>
                <ModalFooter>
                    <Button variant="secondary" onClick={() => setShowDriverRequestModal(false)}>Close</Button>
                </ModalFooter>
            </Modal>
            {/* driver confirmation */}
            <Modal show={showdriverconfirmationmodal} size="xl" onHide={() => setShowDriverConfirmationModal(false)}>
                <ModalHeader closeButton>
                    <ModalTitle>Driver Confirmation</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    {(set && loadtender && loadtender?.edi204 && loadtender?.edi204.length > 0 && loadtender?.edi204[0]?.driverconfirmations && driverconfirmationindex !== null) &&
                        <LoadTenderLoadConfirmation feature={'driver'} form={{ ...loadtender?.edi204[0]?.driverconfirmations[driverconfirmationindex] }} />
                    }
                </ModalBody>
                <ModalFooter>
                    <Button variant="secondary" onClick={() => setShowDriverConfirmationModal(false)}>Close</Button>
                </ModalFooter>
            </Modal>
            {/* Bill To Confirmation */}
            <Modal show={showbilltoconfirmationmodal} size="xl" onHide={() => setShowBillToConfirmationModal(false)}>
                <ModalHeader closeButton>
                    <ModalTitle>Bill To Confirmation</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    {(set && loadtender && loadtender?.edi204 && loadtender?.edi204.length > 0 && loadtender?.edi204[0]?.rateconfirmations && carrierconfirmationindex !== null) &&
                        <LoadTenderLoadConfirmation feature={'carrier'} form={{ ...loadtender?.edi204[0]?.rateconfirmations[carrierconfirmationindex] }} />
                    }
                </ModalBody>
                <ModalFooter>
                    <Button variant="secondary" onClick={() => setShowBillToConfirmationModal(false)}>Close</Button>
                </ModalFooter>
            </Modal>
            {/* Carrier Confirmation */}
            <Modal show={showcarrierconfirmationmodal} size="xl" onHide={() => setShowCarrierConfirmationModal(false)}>
                <ModalHeader closeButton>
                    <ModalTitle>Carrier's Rate Confirmation</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    {(set && loadtender && loadtender?.edi204 && loadtender?.edi204.length > 0 && loadtender?.edi204[0]?.rateconfirmations && carrierconfirmationindex) &&
                        <LoadTenderLoadConfirmation feature={'carrier'} form={{ ...loadtender?.edi204[0]?.rateconfirmations[carrierconfirmationindex] }} />
                    }
                </ModalBody>
                <ModalFooter>
                    <Button variant="secondary" onClick={() => setShowCarrierConfirmationModal(false)}>Close</Button>
                </ModalFooter>
            </Modal>
            {/* Remove tag */}
            <ConfirmationModal
                show={showUntagModal}
                onHide={() => setShowUntagModal(false)}
                onConfirm={() => handleUntag(untagtag)}
                message={`Remove tag: ${untagtag?.name}`}
            />
            { /* LifeCycleStatusUpdate */}
            <Modal show={showloadtenderconfirmationsmodal} sz="small" onHide={() => setShowLoadtenderConfirmationsModal(false)}>
                <ModalHeader closeButton>
                    <ModalTitle>Load Tender Confirmation</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            {response990Pending ?
                                <div xs="auto">
                                    <Spinner size="md" />
                                </div>
                                :
                                <>
                                    <div xs="auto">
                                        <Button variant="success" onClick={() => handleAcceptTender()}>
                                            Send Accept Load Tender
                                        </Button>
                                    </div>
                                    <br />
                                    <div xs="auto">
                                        <ConfirmationDangerModal
                                            show={show990Reject}
                                            onHide={() => setShow990Reject(false)}
                                            onConfirm={() => handleRejectTender()}
                                            message={'Reject the load tender. This means service will NOT be provide on the route. Be cautious when rejecting a load.'}
                                        />
                                        <Button variant="danger" onClick={() => setShow990Reject(true)}>
                                            Send Reject Load Tender
                                        </Button>
                                    </div>
                                </>
                            }
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button variant="secondary" onClick={() => setShowLoadtenderConfirmationsModal(false)}>Close</Button>
                </ModalFooter>
            </Modal>
            {/* Load Tender */}
            {!set ?
                <Row>
                    <Col style={{ textAlign: 'center' }}>
                        <LogisticsLoading message={`Loading Shipment : ${ShipmentId}`} />
                    </Col>
                </Row>
                :
                (loadtender?.edi204 && loadtender?.edi204.length > 0) ?
                    loadtender?.edi204[0].Type === '01' ?
                        <>
                            <Link to={backPath}>
                                <Button>
                                    Back to Dashboard
                                </Button>
                            </Link>
                            <br />
                            <h3>
                                Shipment <i><b><u>{ShipmentId}</u></b></i> was cancelled.
                            </h3>
                            <br />
                            <Card style={{ border: '1px solid #bbb', borderRadius: '0' }}>
                                <Card.Header style={{ backgroundColor: '#eee' }}>
                                    <Card.Title>
                                        <Row>
                                            <Col xs={'auto'}>
                                                <OverlayTrigger
                                                    placement="auto"
                                                    overlay={
                                                        <Tooltip id="tooltip-right">
                                                            {
                                                                loadtenderLifeCycleStatus === 'Accepted'
                                                                    ? 'Accepted shipments are run internally.'
                                                                    : 'Brokered shipments are run by third parties.'
                                                            }
                                                        </Tooltip>
                                                    }
                                                >
                                                    <LifeCycleStatusBadge loadtenderLifeCycleStatus={loadtenderLifeCycleStatus} />
                                                </OverlayTrigger>
                                            </Col>
                                            <Col xs={'auto'}>
                                                Shipment Id: <b>{ShipmentId}</b>
                                            </Col>
                                            <Col xs={'auto'}>
                                                Customer: <b>{loadtender?.edi204[0]?.CustomerISA}</b>
                                            </Col>
                                            <Col xs={'auto'}>
                                                Contract-Trip : <b>{extract204Contract(loadtender.edi204[0])}</b>
                                            </Col>
                                        </Row>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    {loadtender && <PrintShipmentRoute stops={loadtender.edi204[0].Stops} />}
                                </Card.Body>

                            </Card>
                        </>
                        :
                        <>
                            <PrintPDF
                                sourceName={`SYBRTMS-${ShipmentId}`}
                                header={
                                    <>
                                        <Link to={backPath}>
                                            <Button>
                                                Back to Dashboard
                                            </Button>
                                        </Link>
                                        {' '}
                                        <Badge
                                            style={{ cursor: 'pointer' }}
                                            bg="secondary"
                                            onClick={() => {
                                                copyToClipboard();
                                            }}>
                                            Copy Public Link <FaCopy />
                                        </Badge>
                                        {' '}
                                        <a
                                            href={`${process.env.REACT_APP_BASE_URL}/public/loadtender?q=${loadtender.edi204[0]._id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Go to Public Link <FaWindowRestore />
                                        </a>
                                    </>
                                }
                            >
                                <Row>
                                    <Col>
                                        <Card style={{ border: '1px solid #bbb', borderRadius: '0' }}>
                                            <Card.Header style={{ backgroundColor: '#eee' }}>
                                                <Card.Title>
                                                    <Row>
                                                        <Col xs={'auto'}>
                                                            <OverlayTrigger
                                                                placement="auto"
                                                                overlay={
                                                                    <Tooltip id="tooltip-right">
                                                                        {
                                                                            loadtenderLifeCycleStatus === 'Accepted'
                                                                                ? 'Accepted shipments are run internally.'
                                                                                : 'Brokered shipments are run by third parties.'
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <LifeCycleStatusBadge loadtenderLifeCycleStatus={loadtenderLifeCycleStatus} />
                                                            </OverlayTrigger>
                                                        </Col>
                                                        <Col></Col>
                                                        <Col xs={'auto'}>
                                                            <Button onClick={() => setShowLoadtenderConfirmationsModal(true)}>
                                                                Respond
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={'auto'}>
                                                            Shipment Id: <b>{ShipmentId}</b>
                                                        </Col>
                                                        <Col xs={'auto'}>
                                                            Customer: <b>{loadtender?.edi204[0]?.CustomerISA}</b>
                                                        </Col>
                                                        <Col xs={'auto'}>
                                                            Contract-Trip : <b>{extract204Contract(loadtender.edi204[0])}</b>
                                                        </Col>
                                                    </Row>
                                                </Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                {loadtender && <PrintShipmentRoute stops={loadtender.edi204[0].Stops} />}
                                            </Card.Body>

                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card style={{ border: '1px solid #bbb', borderRadius: '0' }}>
                                            <Card.Header style={{ backgroundColor: '#eee' }}>
                                                <Row>
                                                    <Col>
                                                        <b>Attachments, Notes & Tags</b>
                                                    </Col>
                                                    <Col xs="auto">
                                                        <Button onClick={() => setShowAttachmentModal(true)}>Add Attachment</Button>
                                                    </Col>
                                                    <Col xs="auto">
                                                        <Button onClick={() => setShowNoteModal(true)}>Add Note</Button>
                                                    </Col>
                                                    <Col xs="auto">
                                                        <Button onClick={() => setShowTagModal(true)}>Add Tag</Button>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    {(loadtender?.edi204[0]?.shipmentTags && loadtender?.edi204[0]?.shipmentTags.length > 0) ?
                                                        loadtender?.edi204[0]?.shipmentTags.map((tag, t) => (
                                                            <Col xs="auto" key={t}>
                                                                <Tag
                                                                    name={tag.name}
                                                                    fontColorHEX={tag.fontColorHEX}
                                                                    backgroundColorHEX={tag.backgroundColorHEX}
                                                                    handleFunction={() => handleUntagConfirm(tag)}
                                                                />
                                                            </Col>
                                                        ))
                                                        :
                                                        <Col style={{ margin: '0.5em 0em' }}>
                                                            <b>No tags.</b>
                                                        </Col>
                                                    }
                                                </Row>
                                                {(loadtender?.edi204[0]?.shipmentNotes && loadtender?.edi204[0]?.shipmentNotes.length > 0) ?
                                                    <Table responsive>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ backgroundColor: '#eee' }}>Date</th>
                                                                <th style={{ backgroundColor: '#eee' }}>By</th>
                                                                <th style={{ backgroundColor: '#eee' }}>Note</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {loadtender?.edi204[0]?.shipmentNotes.map((note, n) => (
                                                                <tr key={n}>
                                                                    <td style={{ width: 'auto' }}>
                                                                        {formatZuluToTZ(note?.created, settings.timezone)}
                                                                    </td>
                                                                    <td style={{ width: 'auto' }}>
                                                                        {note?.noteby}
                                                                    </td>
                                                                    <td style={{ width: 'auto' }}>
                                                                        {note?.shipmentNote}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    :
                                                    <Col style={{ margin: '0.5em 0em' }}>
                                                        <b>No notes.</b>
                                                    </Col>
                                                }
                                                <Row>
                                                    <Col style={{ margin: '0.5em 0em' }}>
                                                        <b>
                                                            Attachments : {attachments.length}
                                                        </b>
                                                        <Accordion>
                                                            {attachments.length > 0 && attachments.map((attachment, i) => {
                                                                let content;
                                                                const isImage = ['image/png', 'image/jpg', 'image/jpeg'].includes(attachment.type);
                                                                const isPDF = attachment.type === 'application/pdf';

                                                                if (isImage) {
                                                                    content = <img src={attachment.url} alt={`Attachment ${i}`} style={{ maxWidth: '600px' }} />;
                                                                } else if (isPDF) {
                                                                    content = <embed src={attachment.url} width="100%" height="600px" type="application/pdf" />;
                                                                } else {
                                                                    content = <p>Unsupported file type.</p>;
                                                                }

                                                                return (
                                                                    <Accordion.Item key={i} eventKey={i.toString()}>
                                                                        <Accordion.Header>{loadtender.edi204[0]?.attachments[i].split('/').pop()}</Accordion.Header>
                                                                        <Accordion.Body>
                                                                            {content}
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                );
                                                            })}
                                                        </Accordion>
                                                    </Col>
                                                </Row>

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col xs="auto">
                                        <h4>Coverage</h4>
                                    </Col>
                                    <Col xs="auto">
                                        <OverlayTrigger
                                            placement="auto"
                                            overlay={
                                                <Tooltip id="tooltip-right">
                                                    Who is running the trip?
                                                </Tooltip>
                                            }
                                        >
                                            <h4><FaInfoCircle /></h4>
                                        </OverlayTrigger>
                                    </Col>
                                </Row>

                                {((!loadtender.edi204[0]?.operators || loadtender.edi204[0]?.operators.length === 0)
                                    && (!loadtender.edi204[0]?.carriers || loadtender.edi204[0]?.carriers.length === 0))
                                    &&
                                    <Badge
                                        style={{ fontSize: '1em', width: '100%' }}
                                        bg={'warning'}>
                                        Assign a driver or carrier to this load tender.
                                    </Badge>
                                }
                                <Row>
                                    <Col xs="12" lg="6">
                                        <Card style={{ border: '1px solid #bbb', borderRadius: '0' }}>
                                            <Card.Header style={{ backgroundColor: '#eee' }}>
                                                <Row style={{ alignItems: 'center', fontWeight: 'bold' }}>
                                                    <Col xs="auto">
                                                        <b>Drivers</b>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Header>
                                                <Row>
                                                    <Col xs="auto">
                                                        <Form.Check checked={driverassign} label={'Assign Drivers'} onChange={() => setDriverAssign(!driverassign)} />
                                                    </Col>
                                                    <Col xs="auto">
                                                        <Form.Check checked={driverremove} label={'Remove Drivers'} onChange={() => setDriverRemove(!driverremove)} />
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            {driverassign &&
                                                <>
                                                    <Card.Header>
                                                        Assign Driver
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col>
                                                                Location
                                                                <Form.Select onChange={(e) => setOperatorLocation(e.target.value)}>
                                                                    <option>All</option>
                                                                    {samsaraDriversByLocation && Object.keys(samsaraDriversByLocation).map((key, k) => (
                                                                        <option key={k} value={key}>{key ? key : 'Undefined'}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs="auto">
                                                                Driver
                                                                {operatorlocation === 'All'
                                                                    ?
                                                                    <Row>
                                                                        <Col>
                                                                            <Form.Control
                                                                                type="text"
                                                                                placeholder="Search..."
                                                                                value={searchTerm}
                                                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                                            />
                                                                            {searchTerm.trim('') !== '' &&
                                                                                <div style={{ maxHeight: '200px', overflowY: 'auto', margin: '0.5em', padding: '0.1em' }}>
                                                                                    {filteredSamsaraDrivers.map((item, i) => (
                                                                                        <Row key={i} style={{ margin: '0.1em' }}>
                                                                                            <Button variant="secondary" onClick={() => {
                                                                                                setOperatorOption(item);
                                                                                                setSearchTerm(item.name);
                                                                                                setOperatorType('samsara')
                                                                                            }}
                                                                                            >{item.name}</Button>
                                                                                        </Row>
                                                                                    ))}

                                                                                    {filteredMotiveDrivers.map((item, i) => (
                                                                                        <Row key={i} style={{ margin: '0.1em' }}>
                                                                                            <Button variant="secondary" onClick={() => {
                                                                                                setOperatorOption(item);
                                                                                                setSearchTerm(`${item.first_name} ${item.last_name}`);
                                                                                                setOperatorType('motive')
                                                                                            }}
                                                                                            >{item.first_name} {item.last_name}</Button>
                                                                                        </Row>
                                                                                    ))}
                                                                                </div>
                                                                            }
                                                                        </Col>
                                                                        {searchTerm.trim('') !== '' &&
                                                                            <Col xs="auto">
                                                                                <Button variant="warning" onClick={() => setSearchTerm('')}>
                                                                                    X
                                                                                </Button>
                                                                            </Col>

                                                                        }

                                                                    </Row>
                                                                    :
                                                                    <Form.Select onChange={(e) => e.target.value !== '' && setOperatorOption(JSON.parse(e.target.value))}>
                                                                        <option value=''>Select...</option>
                                                                        {samsaraDriversByLocation[operatorlocation] && samsaraDriversByLocation[operatorlocation].sort((a, b) => a.name.localeCompare(b.name)).map((driver, i) => (
                                                                            <option key={i} value={JSON.stringify(driver)}>{driver.name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                }
                                                            </Col>
                                                            <Col>
                                                                Reason
                                                                <Form.Select onChange={(e) => setCoverageOption(e.target.value)}>
                                                                    <option>Assign</option>
                                                                    <option>Recovery</option>
                                                                </Form.Select>
                                                            </Col>
                                                            <Col>
                                                                <br />
                                                                <Button onClick={() => handleCoverageAssign()}>Assign</Button>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </>
                                            }
                                            {driverremove &&
                                                <>
                                                    <Card.Header>
                                                        Remove Driver
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col>
                                                                Driver
                                                                <Form.Select onChange={(e) => setOperatorOption(e.target.value)}>
                                                                    <option>Select...</option>
                                                                    {loadtender?.edi204[0]?.operators && loadtender?.edi204[0]?.operators.map((driver, i) => (
                                                                        <option key={i} value={JSON.stringify(driver)}>{driver.name}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Col>
                                                            <Col>
                                                                Remove Reason
                                                                <Form.Select value={removeoption} onChange={(e) => setRemoveOption(e.target.value)}>
                                                                    <option value="Reassign">Reassign</option>
                                                                    <option value="Call Off">Call Off</option>
                                                                    <option value="Double Booked">Double Booked</option>
                                                                </Form.Select>
                                                            </Col>
                                                            <Col>
                                                                <br />
                                                                <Button variant="warning" onClick={() => handleCoverageRemove()}>Remove</Button>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </>
                                            }
                                            {(loadtender.edi204[0]?.operators && loadtender.edi204[0]?.operators.length > 0 &&
                                                <>
                                                    <Card.Header>
                                                        <Card.Title>
                                                            Scheduled Drivers
                                                        </Card.Title>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        {loadtender.edi204[0]?.operators && loadtender.edi204[0]?.operators.flatMap((driver, i) => (
                                                            <>
                                                                {driver.operatortype === 'samsara' &&
                                                                    <Row key={i}>
                                                                        <Col xs="auto" >
                                                                            <h4>{driver.name}</h4>
                                                                        </Col>
                                                                        {/* <Col xs="auto" >
                                                            <Badge onClick={() => {
                                                                setSelectedDriver(driver);
                                                                setShowDriverConFormModal(true)
                                                            }}><FaPaperPlane /> Email</Badge>
                                                        </Col> */}
                                                                    </Row>
                                                                }

                                                                {driver.operatortype === 'motive' &&
                                                                    <Row key={i}>
                                                                        <Col xs="auto" >
                                                                            <h4>{driver.first_name} {driver.last_name}</h4>
                                                                        </Col>
                                                                        {/* <Col xs="auto" >
                                                                <Badge onClick={() => {
                                                                    setSelectedDriver(driver);
                                                                    setShowDriverConFormModal(true)
                                                                }}><FaPaperPlane /> Email</Badge>
                                                            </Col> */}
                                                                    </Row>
                                                                }
                                                            </>

                                                        ))}
                                                        <br />
                                                        Driver Messaging  (old-new) : {loadtender.edi204[0]?.driverconfirmations ? loadtender.edi204[0]?.driverconfirmations.length : 0}
                                                        {
                                                            loadtender.edi204[0]?.driverconfirmations && loadtender.edi204[0]?.driverconfirmations.flatMap((loadcon, i) => (
                                                                <Row key={i}>
                                                                    <Col>
                                                                        <Alert
                                                                            style={{ padding: '0.1em', margin: 0, borderRadius: 0, cursor: 'pointer' }}
                                                                            variant={
                                                                                loadcon?.response === 'Accepted' ? 'success'
                                                                                    : loadcon?.response === 'Created' ? 'warning'
                                                                                        : 'danger'
                                                                            }
                                                                            onClick={() => {
                                                                                if (loadcon?.response === 'Created') {
                                                                                    setShowDriverRequestModal(true);
                                                                                } else {
                                                                                    setShowDriverConfirmationModal(true);
                                                                                }
                                                                                setDriverConfirmationIndex(i)
                                                                            }}
                                                                        >
                                                                            {loadcon.createdUtcTimestamp} | {loadcon.email} | {loadcon.response}
                                                                        </Alert>
                                                                    </Col>
                                                                </Row>
                                                            ))
                                                        }
                                                    </Card.Body>
                                                </>
                                            )}
                                        </Card>
                                    </Col>
                                    <Col xs="12" lg="6">
                                        <Card style={{ border: '1px solid #bbb', borderRadius: '0' }}>
                                            <Card.Header style={{ backgroundColor: '#eee' }}>
                                                <Row style={{ alignItems: 'center', fontWeight: 'bold' }}>
                                                    <Col xs="auto">
                                                        <b>Carriers</b>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Header>
                                                <Row>
                                                    <Col xs="auto">
                                                        <Form.Check checked={carrierassign} label={'Assign Carrier'} onChange={() => setCarrierAssign(!carrierassign)} />
                                                    </Col>
                                                    <Col xs="auto">
                                                        <Form.Check checked={carrierremove} label={'Remove Carrier'} onChange={() => setCarrierRemove(!carrierremove)} />
                                                    </Col>
                                                </Row>
                                            </Card.Header>

                                            {carrierassign &&
                                                <>
                                                    <Card.Header>
                                                        Assign 3PL Carrier
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col xs={12} md={6} lg={4}>
                                                                3PL Carrier
                                                                <Form.Select onChange={(e) => e.target.value !== '' && setCarrierOption(JSON.parse(e.target.value))}>
                                                                    <option value=''>Select...</option>
                                                                    {carriers.sort((a, b) => a.name.localeCompare(b.name)).map((carrier, i) => (
                                                                        <option key={i} value={JSON.stringify(carrier)}>{carrier.name}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Col>
                                                            <Col xs={12} md={6} lg={4}>
                                                                Reason
                                                                <Form.Select onChange={(e) => setCoverageOption(e.target.value)}>
                                                                    <option>Assign</option>
                                                                    <option>Recovery</option>
                                                                </Form.Select>
                                                            </Col>
                                                            <Col xs={12} md={6} lg={4}>
                                                                <br />
                                                                <Button onClick={() => handleCarrierAssign()}>Assign</Button>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </>
                                            }
                                            {carrierremove &&
                                                <>
                                                    <Card.Header>
                                                        Remove 3PL Carrier
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col xs={12} md={6} lg={4}>
                                                                Carrier
                                                                <Form.Select onChange={(e) => setCarrierOption(e.target.value)}>
                                                                    <option value="">Select...</option>
                                                                    {loadtender?.edi204[0]?.carriers && loadtender?.edi204[0]?.carriers.map((carrier, i) => (
                                                                        <option key={i} value={JSON.stringify(carrier)}>{carrier.name}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Col>
                                                            <Col xs={12} md={6} lg={4}>
                                                                Remove Reason
                                                                <Form.Select onChange={(e) => setRemoveCarrierOption(e.target.value)}>
                                                                    <option value="Reassign">Reassign</option>
                                                                    <option value="Call Off">Call Off</option>
                                                                    <option value="No Show">No Show</option>
                                                                </Form.Select>
                                                            </Col>
                                                            <Col xs={12} md={6} lg={4}>
                                                                <br />
                                                                <Button variant="warning" onClick={() => handleCarrierRemove()}>Remove</Button>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </>
                                            }
                                            {(loadtender.edi204[0]?.carriers && loadtender.edi204[0]?.carriers.length > 0) &&
                                                <>

                                                    <Card.Header>
                                                        <Card.Title>
                                                            Scheduled Carriers
                                                        </Card.Title>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        {
                                                            loadtender.edi204[0]?.carriers.flatMap((carrier, i) => (
                                                                <Row key={i}>
                                                                    <Col xs="auto" >
                                                                        <h4>{carrier.name}</h4>
                                                                    </Col>
                                                                    <Col>
                                                                        {pending204Response ?
                                                                            <Spinner size="md" />
                                                                            :
                                                                            <Row>
                                                                                {carrier?.tradingPartner ?
                                                                                    <>
                                                                                        <Col xs="auto">
                                                                                            <Badge onClick={() => {
                                                                                                handleSendTradingPartnerOriginal204(carrier);
                                                                                            }}> <TbNetwork />Send Original</Badge>
                                                                                        </Col>
                                                                                        <Col xs="auto">
                                                                                            <Badge bg="warning" onClick={() => {
                                                                                                handleSendTradingPartner204(carrier);
                                                                                            }}> <TbNetwork />Send Most Recent</Badge>
                                                                                        </Col>
                                                                                        {loadtender?.brokered204 && loadtender?.brokered204.length > 0 &&
                                                                                            <Col xs="auto">
                                                                                                <ConfirmationDangerModal
                                                                                                    show={showCancelEDI204}
                                                                                                    onHide={() => setShowCancelEDI204()}
                                                                                                    onConfirm={() => handleCancelTradingPartner204(carrier)}
                                                                                                    message={'Are you sure you want to send a cancelation this carrier?'}
                                                                                                />
                                                                                                <Badge bg="danger" onClick={() => setShowCancelEDI204(true)}>
                                                                                                    <FaXmark /> Send Cancelation
                                                                                                </Badge>
                                                                                            </Col>
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <Col xs="auto" >
                                                                                        <Badge onClick={() => {
                                                                                            setShowNewRateConfirmationModal(true);
                                                                                            setSelectedCarrier(carrier)
                                                                                        }}> <FaPaperPlane /> Email Rate Confirmation </Badge>
                                                                                    </Col>
                                                                                }
                                                                            </Row>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            ))
                                                        }
                                                        <br />
                                                        Rate Confirmations (old-new) : {loadtender.edi204[0]?.rateconfirmations ? loadtender.edi204[0]?.rateconfirmations.length : 0}
                                                        {
                                                            loadtender.edi204[0]?.rateconfirmations && loadtender.edi204[0]?.rateconfirmations.flatMap((ratecon, i) => (
                                                                <Row key={i}>
                                                                    <Col>
                                                                        <Alert
                                                                            style={{ padding: '0.1em', margin: 0, borderRadius: 0, cursor: 'pointer' }}
                                                                            variant={
                                                                                ratecon?.response === 'Accepted' ? 'success'
                                                                                    : ratecon?.response === 'Created' ? 'warning'
                                                                                        : 'danger'
                                                                            }
                                                                            onClick={() => {
                                                                                if (ratecon?.response === 'Created') {
                                                                                    setShowBillToConfirmationModal(true);
                                                                                } else {
                                                                                    setShowCarrierConfirmationModal(true);
                                                                                }
                                                                                setCarrierConfirmationIndex(i);
                                                                            }}
                                                                        >{ratecon?.createdUtcTimestamp} | {ratecon?.response === 'Created' ? ratecon?.billToEmail : ratecon?.email} | {ratecon?.response}</Alert>
                                                                    </Col>
                                                                </Row>
                                                            ))
                                                        }
                                                    </Card.Body>

                                                </>
                                            }
                                        </Card>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col xs="auto">
                                        <h4>Mobile Messaging</h4>
                                    </Col>
                                    <Col xs="auto">
                                        <OverlayTrigger
                                            placement="auto"
                                            overlay={
                                                <Tooltip id="tooltip-right">
                                                    Messages sent to dispatch from public facing load tender.
                                                </Tooltip>
                                            }
                                        >
                                            <h4><FaInfoCircle /></h4>
                                        </OverlayTrigger>
                                    </Col>
                                </Row>
                                <Card style={{ borderRadius: '0' }}>
                                    <Card.Header style={{ backgroundColor: '#eeeeee' }}>
                                        <Row>
                                            <Col>
                                                <Card.Title>
                                                    <b>
                                                        Messages
                                                    </b>
                                                </Card.Title>
                                            </Col>
                                            <Col xs="auto">
                                                <Button onClick={() => setShowMobileMessaging(!showMobileMessaging)}>
                                                    {showMobileMessaging ? "Hide" : "Show"}
                                                </Button>
                                            </Col>
                                        </Row>

                                    </Card.Header>
                                    {showMobileMessaging &&
                                        <Card.Body>
                                            {loadtender && loadtender?.mobileTracking && loadtender?.mobileTracking.length > 0 ?
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>Stop Number</th>
                                                            <th>Status</th>
                                                            <th>Name</th>
                                                            <th>Unit Number</th>
                                                            <th>Timestamp</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {loadtender?.mobileTracking.map((record, r) => (
                                                            <tr key={r}>
                                                                <td>
                                                                    {record.StopNumber}
                                                                </td>
                                                                <td>
                                                                    {record.Status}
                                                                </td>
                                                                <td>
                                                                    {record.Name}
                                                                </td>
                                                                <td>
                                                                    {record.UnitNumber}
                                                                </td>
                                                                <td>
                                                                    {record.Timestamp}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                :
                                                <h4>
                                                    No messages.
                                                </h4>
                                            }
                                        </Card.Body>
                                    }

                                </Card>
                                <br />
                                {/* Stop Progress & Manual EDI Message */}
                                {(loadtender.edi204[0].operators && loadtender.edi204[0].operators.length > 0) &&
                                    <>
                                        <Row>
                                            <Col xs="auto">
                                                <h4>Driver Details</h4>
                                            </Col>
                                            <Col xs="auto">
                                                <OverlayTrigger
                                                    placement="auto"
                                                    overlay={
                                                        <Tooltip id="tooltip-right">
                                                            Operational Records
                                                        </Tooltip>
                                                    }
                                                >
                                                    <h4><FaInfoCircle /></h4>
                                                </OverlayTrigger>
                                            </Col>
                                        </Row>
                                        <Card style={{ borderRadius: '0px' }}>
                                            <Card.Header style={{ backgroundColor: '#eee' }}>
                                                <Row style={{ alignItems: 'center' }}>
                                                    <Col>
                                                        <b>Shipment Details
                                                            {(pending204Response || response214Pending || response990Pending) && <Spinner size="md" />}
                                                        </b>
                                                    </Col>
                                                    <Col xs="auto">
                                                        Search Tolerance
                                                        <span style={{ margin: '0 1em' }}>{hoursToTime(searchTolerance)}</span>
                                                        <Button variant="primary" onClick={() => handleSearchToleranceChange(searchTolerance - 0.25)}><FaMinus /></Button>{' '}
                                                        <Button variant="primary" onClick={() => handleSearchToleranceChange(searchTolerance + 0.25)}><FaPlus /></Button>
                                                    </Col>
                                                    <Col xs="auto">
                                                        <Button variant="primary" onClick={() => runShipmentAudit()}>Audit Operations</Button>
                                                    </Col>
                                                    <Col xs="auto">
                                                        <Button variant="primary" onClick={() => setToggleShowMap(!toggleShowMap)}>{toggleShowMap ? 'Hide' : 'Show'} Map</Button>
                                                    </Col>
                                                </Row>
                                            </Card.Header>

                                            <Card.Body>
                                                {
                                                    isAuditLoading ?
                                                        <Spinner />
                                                        :
                                                        <>
                                                            {(loadtenderAudit && loadtenderAudit.length > 0 && loadtenderAudit[0].linearizeLoadTenderAndLogs.length > 0) ?

                                                                <>
                                                                    <Table responsive>
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ backgroundColor: '#eee' }}>Stop</th>
                                                                                <th style={{ backgroundColor: '#eee' }}>Location</th>
                                                                                <th style={{ backgroundColor: '#eee' }}>Arrive</th>
                                                                                <th style={{ backgroundColor: '#eee' }}>Arrive Message</th>
                                                                                <th style={{ backgroundColor: '#eee' }}>Depart</th>
                                                                                <th style={{ backgroundColor: '#eee' }}>Depart Message</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {loadtenderAudit[0].linearizeLoadTenderAndLogs.map((via, j) => (
                                                                                <tr key={`${j}`}>
                                                                                    <td>{via?.StopNum}</td>
                                                                                    <td>
                                                                                        <Link to={`/data/location/usps/${via?.Name}`} target="_blank">
                                                                                            {findUSPSNASSCode(via?.Name)}
                                                                                        </Link>
                                                                                    </td>
                                                                                    <td>{formatUSPSDateTime(via?.Name, via?.RequestedDate)}</td>
                                                                                    <td>{(via?.ediStopArrived && !showViaMannualStatus.includes(`a${j}`)) ?
                                                                                        <>

                                                                                            {'✅ '}<sub>sent</sub>
                                                                                            <span style={{ padding: '0 0.25em' }}>
                                                                                                {calculateDifferenceInMinutes(via?.RequestedDate,
                                                                                                    ((Number(via?.StopNum) === 1)
                                                                                                        ?
                                                                                                        (loadtender?.edi214?.find(
                                                                                                            (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "X3"
                                                                                                        )?.StatusDate)
                                                                                                        :
                                                                                                        (loadtender?.edi214?.find(
                                                                                                            (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "X1"
                                                                                                        )?.StatusDate)
                                                                                                    )
                                                                                                )}
                                                                                            </span>

                                                                                            {/* {((Number(via?.StopNum) === 1)
                                                                                                ?
                                                                                                (loadtender?.edi214?.find(
                                                                                                    (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "X3"
                                                                                                )?.StatusDate)
                                                                                                :
                                                                                                (loadtender?.edi214?.find(
                                                                                                    (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "X1"
                                                                                                )?.StatusDate)
                                                                                            )} */}
                                                                                            <Badge bg="secondary" style={{ cursor: 'pointer' }} onClick={() => setShowViaManualStatus([...showViaMannualStatus, `a${j}`])}>Send Msg <FaMessage /></Badge>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <Button variant="secondary" onClick={() => activateEdiInput(via?.StopNum, 'arrive')}>Send Msg <FaMessage /></Button>
                                                                                            {activeInput.stop === via?.StopNum && activeInput.action === 'arrive' &&
                                                                                                <>
                                                                                                    {(response214Pending && via?.StopNum === activeInput.stop && activeInput.action === 'arrive') ?
                                                                                                        <Spinner size="md" />
                                                                                                        :
                                                                                                        <>
                                                                                                            <input
                                                                                                                type="datetime-local"
                                                                                                                value={ediMessageStopUpdate.timestamp}
                                                                                                                onChange={(e) => setEdiMessageStopUpdate({ timestamp: e.target.value, stopnum: via?.StopNum, action: 'arrive', })}
                                                                                                            />
                                                                                                            <Button variant="secondary" onClick={() => handleManualEdiStatusUpdate()}><FaPaperPlane /></Button>
                                                                                                        </>
                                                                                                    }
                                                                                                    <Badge bg="warning" style={{ cursor: 'pointer' }} onClick={() => setShowViaManualStatus([...showViaMannualStatus.filter(item => item !== `a${j}`)])}>X</Badge>
                                                                                                </>
                                                                                            }
                                                                                        </>
                                                                                    }</td>
                                                                                    <td>{formatUSPSDateTime(via?.Name, via?.LastRequestedDate)}</td>
                                                                                    <td>{(via?.ediStopDelivered && !showViaMannualStatus.includes(`d${j}`)) ?
                                                                                        <>
                                                                                            {'✅ '}<sub>sent</sub>
                                                                                            <span style={{ padding: '0 0.25em' }}>
                                                                                                {calculateDifferenceInMinutes(via?.LastRequestedDate,
                                                                                                    ((Number(via?.StopNum) === 1)
                                                                                                        ?
                                                                                                        (loadtender?.edi214?.find(
                                                                                                            (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "AF"
                                                                                                        )?.StatusDate)
                                                                                                        :
                                                                                                        (loadtender?.edi214?.find(
                                                                                                            (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "CD"
                                                                                                        )?.StatusDate)
                                                                                                    )
                                                                                                )}
                                                                                            </span>
                                                                                            {/* {((Number(via?.StopNum) === 1)
                                                                                                ?
                                                                                                (loadtender?.edi214?.find(
                                                                                                    (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "AF"
                                                                                                )?.StatusDate)
                                                                                                :
                                                                                                (loadtender?.edi214?.find(
                                                                                                    (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "CD"
                                                                                                )?.StatusDate)
                                                                                            )} */}
                                                                                            <Badge bg="secondary" style={{ cursor: 'pointer' }} onClick={() => setShowViaManualStatus([...showViaMannualStatus, `d${j}`])}>Send Again</Badge>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <Button variant="secondary" onClick={() => activateEdiInput(via?.StopNum, 'depart')}>Send Msg <FaMessage /></Button>
                                                                                            {activeInput.stop === via?.StopNum && activeInput.action === 'depart' &&
                                                                                                <>
                                                                                                    {(response214Pending && via?.StopNum === activeInput.stop && activeInput.action === 'depart') ?
                                                                                                        <Spinner size="md" />
                                                                                                        :
                                                                                                        <>
                                                                                                            <input
                                                                                                                type="datetime-local"
                                                                                                                value={ediMessageStopUpdate.timestamp}
                                                                                                                onChange={(e) => setEdiMessageStopUpdate({ timestamp: e.target.value, stopnum: via?.StopNum, action: 'depart', })}
                                                                                                            />
                                                                                                            <Button variant="secondary" onClick={() => handleManualEdiStatusUpdate()}><FaPaperPlane /></Button>
                                                                                                        </>
                                                                                                    }
                                                                                                    <Badge bg="warning" style={{ cursor: 'pointer' }} onClick={() => setShowViaManualStatus([...showViaMannualStatus.filter(item => item !== `d${j}`)])}>X</Badge>
                                                                                                </>
                                                                                            }
                                                                                        </>
                                                                                    }</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </Table>
                                                                    <br />
                                                                    {loadtenderAudit.map((report, i) => (
                                                                        <>
                                                                            <Card.Header style={{ backgroundColor: '#eeeeee' }}>
                                                                                <Card.Title>
                                                                                    <b>
                                                                                        {report?.operator?.name} - Shipment Details : Tracking, HOS, Equipment
                                                                                    </b>
                                                                                </Card.Title>
                                                                            </Card.Header>
                                                                            {(report?.linearizeLoadTenderAndLogs && report?.linearizeLoadTenderAndLogs.length > 0) ?
                                                                                <React.Fragment key={i}>

                                                                                    {report?.operator &&
                                                                                        <React.Fragment>
                                                                                            <React.Fragment>

                                                                                                <pre>
                                                                                                    <Table responsive>
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th style={{ backgroundColor: '#eee' }}>Stop</th>
                                                                                                                <th style={{ backgroundColor: '#eee' }}>Location</th>
                                                                                                                <th style={{ backgroundColor: '#eee' }}>In Geofence</th>
                                                                                                                <th style={{ backgroundColor: '#eee' }}>Sched. Arrive</th>
                                                                                                                <th style={{ backgroundColor: '#eee' }}>GPS Arrival</th>
                                                                                                                <th style={{ backgroundColor: '#eee' }}>(+/-)<sub>min</sub></th>
                                                                                                                <th style={{ backgroundColor: '#eee' }}>Sched. Depart</th>
                                                                                                                <th style={{ backgroundColor: '#eee' }}>GPS Departure</th>
                                                                                                                <th style={{ backgroundColor: '#eee' }}>(+/-)<sub>min</sub></th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {report.linearizeLoadTenderAndLogs.map((via, j) => (
                                                                                                                <tr key={`${j}`}>
                                                                                                                    <td>{via?.StopNum}</td>
                                                                                                                    <td>
                                                                                                                        <Link to={`/data/location/usps/${via?.Name}`} target="_blank">
                                                                                                                            {findUSPSNASSCode(via?.Name)}
                                                                                                                        </Link>
                                                                                                                    </td>
                                                                                                                    <td>{via?.eldOnPremiseCt}</td>
                                                                                                                    <td>{formatUSPSDateTime(via?.Name, via?.RequestedDate)}</td>
                                                                                                                    <td style={{ backgroundColor: '#eee' }}>
                                                                                                                        {via?.eldOnPremiseDataStart?.time &&
                                                                                                                            `${formatZuluToTZ(via?.eldOnPremiseDataStart?.time, findUSPSNassCodeTimezoneIANA(via?.Name))}`
                                                                                                                        }
                                                                                                                    </td>
                                                                                                                    <td style={
                                                                                                                        via?.eldOnPremiseArriveDiffMin > 0
                                                                                                                            ? { backgroundColor: '#bceebc' }
                                                                                                                            : { backgroundColor: '#f39c9c' }
                                                                                                                    }>{via?.eldOnPremiseArriveDiffMin}</td>
                                                                                                                    <td>{formatUSPSDateTime(via?.Name, via?.LastRequestedDate)}</td>
                                                                                                                    <td style={{ backgroundColor: '#efefef' }}>
                                                                                                                        {via?.eldOnPremiseDataEnd?.time &&
                                                                                                                            `${formatZuluToTZ(via?.eldOnPremiseDataEnd?.time, findUSPSNassCodeTimezoneIANA(via?.Name))}`
                                                                                                                        }
                                                                                                                    </td>
                                                                                                                    <td style={
                                                                                                                        via?.eldOnPremiseDepartDiffMin > 0
                                                                                                                            ? { backgroundColor: '#bceebc' }
                                                                                                                            : { backgroundColor: '#f39c9c' }
                                                                                                                    }>{via?.eldOnPremiseDepartDiffMin}</td>
                                                                                                                </tr>
                                                                                                            ))}
                                                                                                        </tbody>
                                                                                                    </Table>
                                                                                                </pre>
                                                                                                {/* <Row>
                                                                                        <Col sm={12} lg={4}>
                                                                                            <Chart
                                                                                                chartType={"PieChart"}
                                                                                                data={shipmentStopPunctualityPieChart(loadtenderAudit[0].linearizeLoadTenderAndLogs)}
                                                                                                options={{
                                                                                                    title: `Load Tender Stop Punctuality`,
                                                                                                    colors: ['grey', 'green', 'red'],
                                                                                                }}
                                                                                                width="100%"
                                                                                                height="500px"
                                                                                            />
                                                                                        </Col>
                                                                                        <Col sm={12} lg={4}>
                                                                                            <Chart
                                                                                                chartType="ScatterChart"
                                                                                                width="100%"
                                                                                                height="500px"
                                                                                                data={shipmentStopPunctualityScatterChart(loadtenderAudit[0].linearizeLoadTenderAndLogs)}
                                                                                                options={{
                                                                                                    title: "Stop Punctuality (+ Early | - Late)",
                                                                                                    hAxis: { title: "Arrival (minutes)" },
                                                                                                    vAxis: { title: "Departure (minutes)" },
                                                                                                    legend: "none",
                                                                                                }}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col sm={12} lg={4}>
                                                                                            <StopPunctualityLegend />
                                                                                        </Col>
                                                                                    </Row> */}
                                                                                            </React.Fragment>
                                                                                            <Row>
                                                                                                <Col xs={12} lg={6} style={{ order: 2 }}>
                                                                                                    <Table bordered responsive hover>
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th style={{ backgroundColor: '#f9f9f9' }}><Card.Title>Driver</Card.Title></th>
                                                                                                                <th style={{ backgroundColor: '#f9f9f9' }}><Card.Title>{report?.operator?.name}</Card.Title></th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <th>Contact Trip</th>
                                                                                                                <td>{report.uspsContractTrips}</td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <th>ShipmentId</th>
                                                                                                                <td>{report?.shipmentId}</td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <th>Search Start</th>
                                                                                                                <td>{report?.hoslogSearchStart}</td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <th>Search End</th>
                                                                                                                <td>{report?.hoslogSearchEnd}</td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <th>Total Search Time</th>
                                                                                                                <td>{millisecondsToHHMM(report?.hoslogTotalTime)}</td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <th>Vehicles</th>
                                                                                                                <td>
                                                                                                                    {report?.hoslogVehicleData?.data
                                                                                                                        ? report?.hoslogVehicleData?.data.map(d => d.name).join(', ')
                                                                                                                        : 'No vehicles'
                                                                                                                    }
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <th>Work Start</th>
                                                                                                                <td>{report?.hoslogWorkStart}</td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <th>Work End</th>
                                                                                                                <td>{report?.hoslogWorkEnd}</td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <th>Duty Status</th>
                                                                                                                <td>
                                                                                                                    {Object.keys(report?.hoslogStatusTotals).map((key, i) => (
                                                                                                                        <>
                                                                                                                            {key} : {millisecondsToHHMM(report?.hoslogStatusTotals[key])}<br />
                                                                                                                        </>
                                                                                                                    ))}
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <th>HOS Work Time</th>
                                                                                                                <td>{millisecondsToHHMM(report?.hoslogTimeWorked)}</td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </Table>
                                                                                                </Col>
                                                                                                <Col xs={12} lg={6} style={{ order: 1 }}>
                                                                                                    <Card style={{ borderRadius: 0, margin: 0, padding: 0 }} >
                                                                                                        <Card.Header>
                                                                                                            <Card.Title>
                                                                                                                {report?.operator?.name} Hours of Service Log
                                                                                                            </Card.Title>
                                                                                                        </Card.Header>
                                                                                                        <Card.Body>
                                                                                                            <DriverHOSLog hoslogs={report?.hoslogs} />
                                                                                                        </Card.Body>
                                                                                                    </Card>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </React.Fragment>
                                                                                    }
                                                                                </React.Fragment>
                                                                                :
                                                                                <React.Fragment key={i}>
                                                                                    <div style={{ width: '100%', backgroundColor: '#f8665f', textAlign: 'center' }}> <h3>NO GPS TRACKING</h3> </div>
                                                                                    <pre>
                                                                                        <Table>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th style={{ backgroundColor: '#eee' }}>Stop</th>
                                                                                                    <th style={{ backgroundColor: '#eee' }}>Location</th>
                                                                                                    <th style={{ backgroundColor: '#eee' }}>Sched. Arrive</th>
                                                                                                    <th style={{ backgroundColor: '#eee' }}>Arrive</th>
                                                                                                    <th style={{ backgroundColor: '#eee' }}>Sched. Depart</th>
                                                                                                    <th style={{ backgroundColor: '#eee' }}>Depart</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {report.linearLoadTender.map((via, j) => (
                                                                                                    <tr key={`${j}`}>
                                                                                                        <td>{via?.StopNum}</td>
                                                                                                        <td>
                                                                                                            <Link to={`/data/location/usps/${via?.Name}`} target="_blank">
                                                                                                                {findUSPSNASSCode(via?.Name)}
                                                                                                            </Link>
                                                                                                        </td>
                                                                                                        <td>{formatUSPSDateTime(via?.Name, via?.RequestedDate)}</td>
                                                                                                        <td>{
                                                                                                            (
                                                                                                                (Number(via?.StopNum) === 1)
                                                                                                                    ? (loadtender?.edi214.some((item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "X3") && !showViaMannualStatus.includes(`a${j}`))
                                                                                                                    : (loadtender?.edi214.some((item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "X1") && !showViaMannualStatus.includes(`a${j}`))
                                                                                                            )
                                                                                                                ?
                                                                                                                <>
                                                                                                                    {'✅ '}<sub>sent</sub>
                                                                                                                    <span style={{ padding: '0 0.25em' }}>
                                                                                                                        {calculateDifferenceInMinutes(via?.RequestedDate,
                                                                                                                            ((Number(via?.StopNum) === 1)
                                                                                                                                ?
                                                                                                                                (loadtender?.edi214?.find(
                                                                                                                                    (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "X3"
                                                                                                                                )?.StatusDate)
                                                                                                                                :
                                                                                                                                (loadtender?.edi214?.find(
                                                                                                                                    (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "X1"
                                                                                                                                )?.StatusDate)
                                                                                                                            )
                                                                                                                        )}
                                                                                                                    </span>
                                                                                                                    {/* {((Number(via?.StopNum) === 1)
                                                                                                                        ?
                                                                                                                        (loadtender?.edi214?.find(
                                                                                                                            (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "X3"
                                                                                                                        )?.StatusDate)
                                                                                                                        :
                                                                                                                        (loadtender?.edi214?.find(
                                                                                                                            (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "X1"
                                                                                                                        )?.StatusDate)
                                                                                                                    )} */}
                                                                                                                    <Badge bg="secondary" style={{ cursor: 'pointer' }} onClick={() => setShowViaManualStatus([...showViaMannualStatus, `a${j}`])}>Send Msg <FaMessage /></Badge>
                                                                                                                </>
                                                                                                                :
                                                                                                                <>
                                                                                                                    <Button variant="secondary" onClick={() => activateEdiInput(via?.StopNum, 'arrive')}>Send Msg <FaMessage /></Button>
                                                                                                                    {activeInput.stop === via?.StopNum && activeInput.action === 'arrive' &&
                                                                                                                        <>
                                                                                                                            <input
                                                                                                                                type="datetime-local"
                                                                                                                                value={ediMessageStopUpdate.timestamp}
                                                                                                                                onChange={(e) => setEdiMessageStopUpdate({ timestamp: e.target.value, stopnum: via?.StopNum, action: 'arrive', })}
                                                                                                                            />
                                                                                                                            <Button variant="secondary" onClick={() => handleManualEdiStatusUpdate()}><FaPaperPlane /></Button>
                                                                                                                        </>
                                                                                                                    }
                                                                                                                    <Badge bg="warning" style={{ cursor: 'pointer' }} onClick={() => setShowViaManualStatus([...showViaMannualStatus.filter(item => item !== `a${j}`)])}>X</Badge>
                                                                                                                </>
                                                                                                        }
                                                                                                        </td>
                                                                                                        <td>{formatUSPSDateTime(via?.Name, via?.LastRequestedDate)}</td>
                                                                                                        <td>{
                                                                                                            ((Number(via?.StopNum) === 1)
                                                                                                                ? (loadtender?.edi214.some((item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "AF") && !showViaMannualStatus.includes(`d${j}`))
                                                                                                                : (loadtender?.edi214.some((item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "CD") && !showViaMannualStatus.includes(`d${j}`))
                                                                                                            ) ?
                                                                                                                <>
                                                                                                                    {'✅ '}<sub>sent</sub>
                                                                                                                    <span style={{ padding: '0 0.25em' }}>
                                                                                                                        {calculateDifferenceInMinutes(via?.LastRequestedDate,
                                                                                                                            ((Number(via?.StopNum) === 1)
                                                                                                                                ?
                                                                                                                                (loadtender?.edi214?.find(
                                                                                                                                    (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "AF"
                                                                                                                                )?.StatusDate)
                                                                                                                                :
                                                                                                                                (loadtender?.edi214?.find(
                                                                                                                                    (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "CD"
                                                                                                                                )?.StatusDate)
                                                                                                                            )
                                                                                                                        )}
                                                                                                                    </span>
                                                                                                                    {/* {((Number(via?.StopNum) === 1)
                                                                                                                        ?
                                                                                                                        (loadtender?.edi214?.find(
                                                                                                                            (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "AF"
                                                                                                                        )?.StatusDate)
                                                                                                                        :
                                                                                                                        (loadtender?.edi214?.find(
                                                                                                                            (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "CD"
                                                                                                                        )?.StatusDate)
                                                                                                                    )} */}
                                                                                                                    <Badge bg="secondary" style={{ cursor: 'pointer' }} onClick={() => setShowViaManualStatus([...showViaMannualStatus, `d${j}`])}>Send Msg <FaMessage /></Badge>
                                                                                                                </>
                                                                                                                :
                                                                                                                <>
                                                                                                                    <Button variant="secondary" onClick={() => activateEdiInput(via?.StopNum, 'depart')}>Send Msg <FaMessage /></Button>
                                                                                                                    {activeInput.stop === via?.StopNum && activeInput.action === 'depart' &&
                                                                                                                        <>
                                                                                                                            <input
                                                                                                                                type="datetime-local"
                                                                                                                                value={ediMessageStopUpdate.timestamp}
                                                                                                                                onChange={(e) => setEdiMessageStopUpdate({ timestamp: e.target.value, stopnum: via?.StopNum, action: 'depart', })}
                                                                                                                            />
                                                                                                                            <Button variant="secondary" onClick={() => handleManualEdiStatusUpdate()}><FaPaperPlane /></Button>
                                                                                                                        </>
                                                                                                                    }
                                                                                                                    <Badge bg="warning" style={{ cursor: 'pointer' }} onClick={() => setShowViaManualStatus([...showViaMannualStatus.filter(item => item !== `d${j}`)])}>X</Badge>
                                                                                                                </>
                                                                                                        }</td>
                                                                                                    </tr>
                                                                                                ))}
                                                                                            </tbody>
                                                                                        </Table>
                                                                                    </pre>
                                                                                </React.Fragment>
                                                                            }
                                                                        </>
                                                                    ))}
                                                                </>
                                                                :
                                                                <React.Fragment>
                                                                    <div style={{ width: '100%', backgroundColor: '#f8665f', margin: '1em', }}> <h3>NO GPS TRACKING</h3> </div>
                                                                    <pre>
                                                                        <Table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{ backgroundColor: '#eee' }}>Stop</th>
                                                                                    <th style={{ backgroundColor: '#eee' }}>Location</th>
                                                                                    <th style={{ backgroundColor: '#eee' }}>Sched. Arrive</th>
                                                                                    <th style={{ backgroundColor: '#eee' }}>Arrive Message</th>
                                                                                    <th style={{ backgroundColor: '#eee' }}>Sched. Depart</th>
                                                                                    <th style={{ backgroundColor: '#eee' }}>Depart Message</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {loadtender.edi204[0].Stops.map((via, j) => (
                                                                                    <tr key={`${j}`}>
                                                                                        <td>{via?.StopNum}</td>
                                                                                        <td>
                                                                                            <Link to={`/data/location/usps/${via?.Name}`} target="_blank">
                                                                                                {findUSPSNASSCode(via?.Name)}
                                                                                            </Link>
                                                                                        </td>
                                                                                        <td>{formatUSPSDateTime(via?.Name, via?.RequestedDate)}</td>
                                                                                        <td>
                                                                                            {
                                                                                                (
                                                                                                    (Number(via?.StopNum) === 1)
                                                                                                        ? (loadtender?.edi214.some((item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "X3") && !showViaMannualStatus.includes(`a${j}`))
                                                                                                        : (loadtender?.edi214.some((item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "X1") && !showViaMannualStatus.includes(`a${j}`))
                                                                                                )
                                                                                                    ?
                                                                                                    <>
                                                                                                        {'✅ '}<sub>sent</sub>
                                                                                                        <span style={{ padding: '0 0.25em' }}>
                                                                                                            {calculateDifferenceInMinutes(via?.RequestedDate,
                                                                                                                ((Number(via?.StopNum) === 1)
                                                                                                                    ?
                                                                                                                    (loadtender?.edi214?.find(
                                                                                                                        (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "X3"
                                                                                                                    )?.StatusDate)
                                                                                                                    :
                                                                                                                    (loadtender?.edi214?.find(
                                                                                                                        (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "X1"
                                                                                                                    )?.StatusDate)
                                                                                                                )
                                                                                                            )}
                                                                                                        </span>
                                                                                                        {/* {((Number(via?.StopNum) === 1)
                                                                                                            ?
                                                                                                            (loadtender?.edi214?.find(
                                                                                                                (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "X3"
                                                                                                            )?.StatusDate)
                                                                                                            :
                                                                                                            (loadtender?.edi214?.find(
                                                                                                                (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "X1"
                                                                                                            )?.StatusDate)
                                                                                                        )} */}
                                                                                                        <Badge bg="secondary" style={{ cursor: 'pointer' }} onClick={() => setShowViaManualStatus([...showViaMannualStatus, `a${j}`])}>Send Msg <FaMessage /></Badge>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        <Button variant="secondary" onClick={() => activateEdiInput(via?.StopNum, 'arrive')}>Send Msg <FaMessage /></Button>
                                                                                                        {activeInput.stop === via?.StopNum && activeInput.action === 'arrive' &&
                                                                                                            <>
                                                                                                                <input
                                                                                                                    type="datetime-local"
                                                                                                                    value={ediMessageStopUpdate.timestamp}
                                                                                                                    onChange={(e) => setEdiMessageStopUpdate({ timestamp: e.target.value, stopnum: via?.StopNum, action: 'arrive', })}
                                                                                                                />
                                                                                                                <Button variant="secondary" onClick={() => handleManualEdiStatusUpdate()}><FaPaperPlane /></Button>
                                                                                                            </>
                                                                                                        }
                                                                                                        <Badge bg="warning" style={{ cursor: 'pointer' }} onClick={() => setShowViaManualStatus([...showViaMannualStatus.filter(item => item !== `a${j}`)])}>X</Badge>
                                                                                                    </>
                                                                                            }
                                                                                        </td>
                                                                                        <td>{formatUSPSDateTime(via?.Name, via?.LastRequestedDate)}</td>
                                                                                        <td>
                                                                                            {
                                                                                                ((Number(via?.StopNum) === 1)
                                                                                                    ? (loadtender?.edi214.some((item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "AF") && !showViaMannualStatus.includes(`d${j}`))
                                                                                                    : (loadtender?.edi214.some((item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "CD") && !showViaMannualStatus.includes(`d${j}`))
                                                                                                ) ?
                                                                                                    <>
                                                                                                        {'✅ '}<sub>sent</sub>
                                                                                                        <span style={{ padding: '0 0.25em' }}>
                                                                                                            {calculateDifferenceInMinutes(via?.LastRequestedDate,
                                                                                                                ((Number(via?.StopNum) === 1)
                                                                                                                    ?
                                                                                                                    (loadtender?.edi214?.find(
                                                                                                                        (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "AF"
                                                                                                                    )?.StatusDate)
                                                                                                                    :
                                                                                                                    (loadtender?.edi214?.find(
                                                                                                                        (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "CD"
                                                                                                                    )?.StatusDate)
                                                                                                                )
                                                                                                            )}
                                                                                                        </span>
                                                                                                        {/* {((Number(via?.StopNum) === 1)
                                                                                                            ?
                                                                                                            (loadtender?.edi214?.find(
                                                                                                                (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "AF"
                                                                                                            )?.StatusDate)
                                                                                                            :
                                                                                                            (loadtender?.edi214?.find(
                                                                                                                (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "CD"
                                                                                                            )?.StatusDate)
                                                                                                        )} */}
                                                                                                        <Badge bg="secondary" style={{ cursor: 'pointer' }} onClick={() => setShowViaManualStatus([...showViaMannualStatus, `d${j}`])}>Send Msg <FaMessage /></Badge>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        <Button variant="secondary" onClick={() => activateEdiInput(via?.StopNum, 'depart')}>Send Msg <FaMessage /></Button>
                                                                                                        {activeInput.stop === via?.StopNum && activeInput.action === 'depart' &&
                                                                                                            <>
                                                                                                                <input
                                                                                                                    type="datetime-local"
                                                                                                                    value={ediMessageStopUpdate.timestamp}
                                                                                                                    onChange={(e) => setEdiMessageStopUpdate({ timestamp: e.target.value, stopnum: via?.StopNum, action: 'depart', })}
                                                                                                                />
                                                                                                                <Button variant="secondary" onClick={() => handleManualEdiStatusUpdate()}><FaPaperPlane /></Button>
                                                                                                            </>
                                                                                                        }
                                                                                                        <Badge bg="warning" style={{ cursor: 'pointer' }} onClick={() => setShowViaManualStatus([...showViaMannualStatus.filter(item => item !== `d${j}`)])}>X</Badge>
                                                                                                    </>
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </Table>
                                                                    </pre>
                                                                </React.Fragment>
                                                            }
                                                        </>
                                                }
                                            </Card.Body>
                                            <hr />
                                            {toggleShowMap &&
                                                <Card.Body>
                                                    <i>* There is a bug when printing this map.</i>
                                                    <GeofenceDriverDisplay
                                                        geofenceState={geofenceState}
                                                        circleGeofenceState={circleGeofenceState}
                                                        loadtenderPlot={loadtenderWaypoints}
                                                        hosPlot={hosWaypoints}
                                                        gpsPlot={[]}
                                                    />
                                                </Card.Body>
                                            }
                                        </Card>
                                        <br />
                                    </>

                                }
                                {loadtender.edi204[0].carriers && loadtender.edi204[0].carriers.length > 0 &&
                                    <>
                                        <Row>
                                            <Col xs="auto">
                                                <h4>Carrier Details</h4>
                                            </Col>
                                            <Col xs="auto">
                                                <OverlayTrigger
                                                    placement="auto"
                                                    overlay={
                                                        <Tooltip id="tooltip-right">
                                                            Operational Records
                                                        </Tooltip>
                                                    }
                                                >
                                                    <h4><FaInfoCircle /></h4>
                                                </OverlayTrigger>
                                            </Col>
                                        </Row>
                                        <Card style={{ borderRadius: '0px' }}>
                                            <Card.Header style={{ backgroundColor: '#eee' }}>
                                                <Row>
                                                    <Col>
                                                        <b>Brokered Shipment Details</b>
                                                    </Col>
                                                    <Col xs="auto">
                                                        <h3> Tracking {loadtender.brokeredTracking.length > 0 ? <FaWifi /> : <MdSignalWifiStatusbarNotConnected />}</h3>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                {
                                                    <React.Fragment>
                                                        {(!loadtender.brokeredTracking || loadtender.brokeredTracking.length === 0) &&
                                                            <div style={{ width: '100%', backgroundColor: '#f8665f' }}> <h3>NO GPS TRACKING</h3> </div>
                                                        }
                                                        <pre>
                                                            <Table>
                                                                <thead>
                                                                    <tr>
                                                                        <th colSpan={'100%'}>
                                                                            Status Updates (x{loadtender?.edi214.length + loadtender?.brokered214.length})
                                                                            {response214Pending && <Spinner size="md" />}
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th style={{ backgroundColor: '#eee' }}>Stop</th>
                                                                        <th style={{ backgroundColor: '#eee' }}>Location</th>
                                                                        <th style={{ backgroundColor: '#eee' }}>Sched. Arrive</th>
                                                                        <th style={{ backgroundColor: '#eee' }}>Arrive Message</th>
                                                                        <th style={{ backgroundColor: '#eee' }}>Sched. Depart</th>
                                                                        <th style={{ backgroundColor: '#eee' }}>Depart Message</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {loadtender.edi204[0].Stops.map((via, j) => (
                                                                        <tr key={`${j}`}>
                                                                            <td>{via?.StopNum}</td>
                                                                            <td>
                                                                                <Link to={`/data/location/usps/${via?.Name}`} target="_blank">
                                                                                    {findUSPSNASSCode(via?.Name)}
                                                                                </Link>
                                                                            </td>
                                                                            <td>{formatUSPSDateTime(via?.Name, via?.RequestedDate)}</td>
                                                                            <td>
                                                                                {
                                                                                    (
                                                                                        (Number(via?.StopNum) === 1)
                                                                                            ? (loadtender?.edi214.some((item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "X3") && !showViaMannualStatus.includes(`a${j}`))
                                                                                            : (loadtender?.edi214.some((item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "X1") && !showViaMannualStatus.includes(`a${j}`))
                                                                                    )
                                                                                        ?
                                                                                        <>
                                                                                            {'✅ '}<sub>sent</sub>
                                                                                            <span style={{ padding: '0 0.25em' }}>
                                                                                                {calculateDifferenceInMinutes(via?.RequestedDate,
                                                                                                    ((Number(via?.StopNum) === 1)
                                                                                                        ?
                                                                                                        (loadtender?.edi214?.find(
                                                                                                            (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "X3"
                                                                                                        )?.StatusDate)
                                                                                                        :
                                                                                                        (loadtender?.edi214?.find(
                                                                                                            (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "X1"
                                                                                                        )?.StatusDate)
                                                                                                    )
                                                                                                )}
                                                                                            </span>
                                                                                            {/* {((Number(via?.StopNum) === 1)
                                                                                                ?
                                                                                                (loadtender?.edi214?.find(
                                                                                                    (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "X3"
                                                                                                )?.StatusDate)
                                                                                                :
                                                                                                (loadtender?.edi214?.find(
                                                                                                    (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "X1"
                                                                                                )?.StatusDate)
                                                                                            )} */}
                                                                                            <Badge bg="secondary" style={{ cursor: 'pointer' }} onClick={() => setShowViaManualStatus([...showViaMannualStatus, `a${j}`])}>Send Msg <FaMessage /></Badge>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <Button variant="secondary" onClick={() => activateEdiInput(via?.StopNum, 'arrive')}>Send Msg <FaMessage /></Button>
                                                                                            {activeInput.stop === via?.StopNum && activeInput.action === 'arrive' &&
                                                                                                <>
                                                                                                    <input
                                                                                                        type="datetime-local"
                                                                                                        value={ediMessageStopUpdate.timestamp}
                                                                                                        onChange={(e) => setEdiMessageStopUpdate({ timestamp: e.target.value, stopnum: via?.StopNum, action: 'arrive', })}
                                                                                                    />
                                                                                                    <Button variant="secondary" onClick={() => handleManualEdiStatusUpdate()}><FaPaperPlane /></Button>
                                                                                                </>
                                                                                            }
                                                                                            <Badge bg="warning" style={{ cursor: 'pointer' }} onClick={() => setShowViaManualStatus([...showViaMannualStatus.filter(item => item !== `a${j}`)])}>X</Badge>
                                                                                        </>
                                                                                }
                                                                            </td>
                                                                            <td>{formatUSPSDateTime(via?.Name, via?.LastRequestedDate)}</td>
                                                                            <td>
                                                                                {
                                                                                    ((Number(via?.StopNum) === 1)
                                                                                        ? (loadtender?.edi214.some((item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "AF") && !showViaMannualStatus.includes(`d${j}`))
                                                                                        : (loadtender?.edi214.some((item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "CD") && !showViaMannualStatus.includes(`d${j}`))
                                                                                    ) ?
                                                                                        <>

                                                                                            {'✅ '}<sub>sent</sub>
                                                                                            <span style={{ padding: '0 0.25em' }}>
                                                                                                {calculateDifferenceInMinutes(via?.LastRequestedDate,
                                                                                                    ((Number(via?.StopNum) === 1)
                                                                                                        ?
                                                                                                        (loadtender?.edi214?.find(
                                                                                                            (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "AF"
                                                                                                        )?.StatusDate)
                                                                                                        :
                                                                                                        (loadtender?.edi214?.find(
                                                                                                            (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "CD"
                                                                                                        )?.StatusDate)
                                                                                                    )
                                                                                                )}
                                                                                            </span>
                                                                                            {/* {((Number(via?.StopNum) === 1)
                                                                                                ?
                                                                                                (loadtender?.edi214?.find(
                                                                                                    (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "AF"
                                                                                                )?.StatusDate)
                                                                                                :
                                                                                                (loadtender?.edi214?.find(
                                                                                                    (item) => Number(item.ApptNumber) === Number(via?.StopNum) && item.StatusCode === "CD"
                                                                                                )?.StatusDate)
                                                                                            )} */}
                                                                                            <Badge bg="secondary" style={{ cursor: 'pointer' }} onClick={() => setShowViaManualStatus([...showViaMannualStatus, `d${j}`])}>Send Msg <FaMessage /></Badge>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <Button variant="secondary" onClick={() => activateEdiInput(via?.StopNum, 'depart')}>Send Msg <FaMessage /></Button>
                                                                                            {activeInput.stop === via?.StopNum && activeInput.action === 'depart' &&
                                                                                                <>
                                                                                                    <input
                                                                                                        type="datetime-local"
                                                                                                        value={ediMessageStopUpdate.timestamp}
                                                                                                        onChange={(e) => setEdiMessageStopUpdate({ timestamp: e.target.value, stopnum: via?.StopNum, action: 'depart', })}
                                                                                                    />
                                                                                                    <Button variant="secondary" onClick={() => handleManualEdiStatusUpdate()}><FaPaperPlane /></Button>
                                                                                                </>
                                                                                            }
                                                                                            <Badge bg="warning" style={{ cursor: 'pointer' }} onClick={() => setShowViaManualStatus([...showViaMannualStatus.filter(item => item !== `d${j}`)])}>X</Badge>
                                                                                        </>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        </pre>
                                                    </React.Fragment>
                                                }
                                            </Card.Body>
                                        </Card>
                                        <br />
                                    </>
                                }
                                {/* Inbound */}
                                <div>
                                    <Row>
                                        <Col xs="auto">
                                            <h4>Shipper Messaging</h4>
                                        </Col>
                                        <Col xs="auto">
                                            <OverlayTrigger
                                                placement="auto"
                                                overlay={
                                                    <Tooltip id="tooltip-right">
                                                        These messages are between the shipper and you.
                                                    </Tooltip>
                                                }
                                            >
                                                <h4><FaInfoCircle /></h4>
                                            </OverlayTrigger>
                                        </Col>
                                    </Row>
                                    <Card style={{ borderRadius: '0', border: '1px solid #bbb' }}>
                                        {/* 214 */}
                                        <Card.Header style={{ backgroundColor: '#eee' }}>
                                            <Row>
                                                <Col>
                                                    <b>Inbound 204 - Motor Carrier Load Tenders (x{loadtender?.edi204.length})</b>
                                                </Col>
                                                <Col xs="auto">
                                                    <Button variant="primary" onClick={() => setShow204Updates(!show204Updates)}>
                                                        {show204Updates ? 'Hide' : 'Show'}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Card.Header>
                                        {loadtender.edi204.length > 0 ?
                                            show204Updates &&
                                            <Card.Body>
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Received</th>
                                                            <th>Changes</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            loadtender.edi204 && loadtender.edi204.map((e204, i) => (
                                                                <tr key={i}>
                                                                    <td>
                                                                        {i + 1}
                                                                    </td>
                                                                    <td>
                                                                        <pre>{(e204.receivedUTC)}</pre>
                                                                    </td>
                                                                    <td>
                                                                        {(loadtender.edi204.length > 1 && i < loadtender?.edi204.length - 1)
                                                                            ?
                                                                            render204DocumentComparison(loadtender?.edi204[i], loadtender?.edi204[i + 1], [
                                                                                'Type',
                                                                                'TypeDesc',
                                                                                'RespondBy',
                                                                                'RespondByTimeZone',
                                                                                'ShipmentId',
                                                                                'Equipment',
                                                                                'EquipmentNumber',
                                                                                'TotalPiecesOfEquipment',
                                                                                'EquipLength',
                                                                                'TransportationMethod',
                                                                                'Pallets',
                                                                                'Rate',
                                                                                'Weight',
                                                                                'WeightUnit',
                                                                                'PaymentMethod',
                                                                                'MiscReferenceNum',
                                                                                'BillTo',
                                                                                'Contact',
                                                                                'Note',
                                                                                'References',
                                                                                'Stops',
                                                                            ])
                                                                            : 'Nothing to compare'
                                                                        }

                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                            :
                                            <Card.Body>
                                                No messages
                                            </Card.Body>
                                        }
                                        {/* EDI 214 Updates */}
                                        <Card.Header style={{ backgroundColor: '#eee' }}>
                                            <Row>
                                                <Col>
                                                    <b>Outbound 214 - Load Tender Status Update Messages (x{loadtender?.edi214.length})</b>
                                                </Col>
                                                <Col xs="auto">
                                                    <Button variant="primary" onClick={() => setShow214Updates(!show214Updates)}>
                                                        {show214Updates ? 'Hide' : 'Show'}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Card.Header>
                                        {loadtender.edi214.length > 0 &&
                                            show214Updates &&
                                            <Card.Body>
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Appointment</th>
                                                            <th>Status Code</th>
                                                            <th>Status Description</th>
                                                            <th>Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            loadtender.edi214 && loadtender.edi214.map((e214, i) => (
                                                                <tr key={i}>
                                                                    <td>{i + 1}</td>
                                                                    <td>
                                                                        <pre>{e214.ApptNumber}</pre>
                                                                    </td>
                                                                    <td>
                                                                        <pre>{(e214.StatusCode)}</pre>
                                                                    </td>
                                                                    <td>
                                                                        {EDI_214_SHIPMENT_STATUS_CODES.find(code => code?.Code === e214?.StatusCode)?.Description}
                                                                    </td>
                                                                    <td>
                                                                        <pre>{e214.StatusDate} {e214.TimeZone}</pre>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        }
                                        {/* 990 */}
                                        <Card.Header style={{ backgroundColor: '#eee' }}>
                                            <Row>
                                                <Col>
                                                    <b>Outbound 990 - Load Tender Responses (x{loadtender?.edi990.length})</b>
                                                </Col>
                                                <Col xs="auto">
                                                    <Button variant="primary" onClick={() => setShow990Updates(!show990Updates)}>
                                                        {show990Updates ? 'Hide' : 'Show'}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Card.Header>
                                        {loadtender.edi990.length > 0 &&
                                            show990Updates &&
                                            <Card.Body>
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Date</th>
                                                            <th>Customer</th>
                                                            <th>SCAC</th>
                                                            <th>Response</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {loadtender.edi990 && loadtender.edi990.length > 0 ?
                                                            loadtender.edi990.map((e990, i) => (
                                                                <tr key={i}>
                                                                    <td>{i + 1}</td>
                                                                    <td>
                                                                        {(e990?.CustomerISA)}
                                                                    </td>
                                                                    <td>
                                                                        {(e990?.SCAC)}
                                                                    </td>
                                                                    <td>{(e990?.InterchangeDateYYMMDD)}{' '}{(e990?.InterchangeTimeHHMM)} UTC</td>
                                                                    <td>{e990?.Response} </td>
                                                                </tr>
                                                            )) :
                                                            <tr>
                                                                <td colSpan={'100%'}>No messages</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        }
                                        {/* Manual Fourkites Update */}
                                        {/* <div>
                                            <Card style={{ borderRadius: '0', border: '1px solid #bbb' }}>
                                                <Card.Header style={{ backgroundColor: '#eee' }}>
                                                    <Row>
                                                        <Col>
                                                            <b>Fourkite Updates (x{loadtender?.fourkiteUpdates.length})</b>
                                                        </Col>
                                                        <Col xs="auto">
                                                            <input
                                                                type="text"
                                                                placeholder="Vehicle Unit Number..."
                                                                value={truckNumber}
                                                                onChange={(e) => setTruckNumber(e.target.value)}
                                                                list="vehicle-list"
                                                                style={{ border: '1px solid #111', borderRadius: '0.25em', padding: '0.25em', margin: '0 0.35em', alignItems: 'center' }}
                                                            />
                                                            <datalist id="vehicle-list">
                                                                {samsaraVehicles && samsaraVehicles.map((vehicle, i) => (
                                                                    <option key={i} value={vehicle.name}>{vehicle.name}</option>
                                                                ))}
                                                            </datalist>
                                                            <Button variant="primary" onClick={() => sendFourkitesUpdate()}>
                                                                Send Update
                                                            </Button>
                                                        </Col>
                                                        <Col xs="auto">
                                                            <Button variant="primary" onClick={() => setShowAllUpdates(!showAllUpdates)}>
                                                                {showAllUpdates ? 'Hide' : 'Show'}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Card.Header>
                                                {showAllUpdates &&
                                                    <Card.Body>
                                                        <Table responsive>
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Truck</th>
                                                                    <th>Message Time</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    loadtender.fourkiteUpdates && loadtender.fourkiteUpdates.map((fourkiteUpdate, i) => (
                                                                        <tr key={i}>
                                                                            <td>{i + 1}</td>
                                                                            <td>{fourkiteUpdate.truckNumber}</td>
                                                                            <td>{fourkiteUpdate.createdUtcTimestamp}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </Card.Body>
                                                }
                                            </Card>
                                        </div> */}
                                    </Card>
                                    <br />
                                    <Row>
                                        <Col xs="auto">
                                            <h4>Carrier Messaging</h4>
                                        </Col>
                                        <Col xs="auto">
                                            <OverlayTrigger
                                                placement="auto"
                                                overlay={
                                                    <Tooltip id="tooltip-right">
                                                        These messages are between the you and a third party carrier.
                                                    </Tooltip>
                                                }
                                            >
                                                <h4><FaInfoCircle /></h4>
                                            </OverlayTrigger>
                                        </Col>
                                    </Row>

                                    <Card style={{ borderRadius: '0', border: '1px solid #bbb' }}>
                                        {/* 204 */}
                                        <Card.Header style={{ backgroundColor: '#eee' }}>
                                            <Row>
                                                <Col>
                                                    <b>Outbound 204 - Brokered Motor Carrier Load Tenders (x{loadtender?.brokered204.length})</b>
                                                </Col>
                                                <Col xs="auto">
                                                    <Button variant="primary" onClick={() => setShowBrokered204Updates(!showBrokered204Updates)}>
                                                        {showBrokered204Updates ? 'Hide' : 'Show'}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Card.Header>
                                        {loadtender.brokered204.length > 0 &&
                                            showBrokered204Updates &&
                                            <Card.Body>
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Changes</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            loadtender.brokered204 && loadtender.brokered204.map((e204, i) => (
                                                                <tr key={i}>
                                                                    <td>{i + 1}</td>
                                                                    <td>
                                                                        {(loadtender.brokered204.length > 1 && i < loadtender?.brokered204.length - 1)
                                                                            ?
                                                                            render204DocumentComparison(loadtender?.brokered204[i], loadtender?.brokered204[i + 1], [
                                                                                'Type',
                                                                                'TypeDesc',
                                                                                'RespondBy',
                                                                                'RespondByTimeZone',
                                                                                'ShipmentId',
                                                                                'Equipment',
                                                                                'EquipmentNumber',
                                                                                'TotalPiecesOfEquipment',
                                                                                'EquipLength',
                                                                                'TransportationMethod',
                                                                                'Pallets',
                                                                                'Rate',
                                                                                'Weight',
                                                                                'WeightUnit',
                                                                                'PaymentMethod',
                                                                                'MiscReferenceNum',
                                                                                'BillTo',
                                                                                'Contact',
                                                                                'Note',
                                                                                'References',
                                                                                'Stops',
                                                                            ])
                                                                            : 'Nothing to compare'
                                                                        }

                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        }
                                        {/* 214 */}
                                        <Card.Header style={{ backgroundColor: '#eee' }}>
                                            <Row>
                                                <Col>
                                                    <b>Inbound 214 - Brokered Load Tender Status Updates (x{loadtender?.brokered214.length})</b>
                                                </Col>
                                                <Col xs="auto">
                                                    <Button variant="primary" onClick={() => setShowBrokered214Updates(!showBrokered214Updates)}>
                                                        {showBrokered214Updates ? 'Hide' : 'Show'}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Card.Header>
                                        {loadtender.brokered214.length > 0 &&
                                            showBrokered214Updates &&
                                            <Card.Body>
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Appointment</th>
                                                            <th>Status Code</th>
                                                            <th>Status Description</th>
                                                            <th>Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            loadtender.brokered214 ? loadtender.brokered214.map((e214, i) => (
                                                                <tr key={i}>
                                                                    <td>{i + 1}</td>
                                                                    <td>
                                                                        <pre>{e214.StopNumber}</pre>
                                                                    </td>
                                                                    <td>
                                                                        <pre>{(e214.StatusCode)}</pre>
                                                                    </td>
                                                                    <td>
                                                                        {EDI_214_SHIPMENT_STATUS_CODES.find(code => code?.Code === e214?.StatusCode)?.Description}
                                                                    </td>
                                                                    <td>
                                                                        <pre>{e214.StatusDate} {e214.TimeZone}</pre>
                                                                    </td>
                                                                </tr>
                                                            )) :
                                                                <tr>
                                                                    <td colSpan={'100%'}>No messages</td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        }
                                        {/* 990 */}
                                        <Card.Header style={{ backgroundColor: '#eee' }}>
                                            <Row>
                                                <Col>
                                                    <b>Inbound 990 - Broker Load Tender Responses (x{loadtender?.brokered990.length})</b>
                                                </Col>
                                                <Col xs="auto">
                                                    <Button variant="primary" onClick={() => setShowBrokered990Updates(!showBrokered990Updates)}>
                                                        {showBrokered990Updates ? 'Hide' : 'Show'}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Card.Header>
                                        {loadtender.brokered990.length > 0
                                            && showBrokered990Updates &&
                                            <Card.Body>
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Customer</th>
                                                            <th>Carrier</th>
                                                            <th>Timestamp</th>
                                                            <th>Response</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            loadtender.brokered990 ? loadtender.brokered990.map((e990, i) => (
                                                                <tr key={i}>
                                                                    <td>{i + 1}</td>
                                                                    <td>
                                                                        {(e990?.CustomerISA)}
                                                                    </td>
                                                                    <td>
                                                                        {(e990?.SCAC)}
                                                                    </td>
                                                                    <td>
                                                                        {(e990?.InterchangeDateYYMMDD)}{' '}
                                                                        {(e990?.InterchangeTimeHHMM)} UTC
                                                                    </td>
                                                                    <td>{e990?.Response} </td>
                                                                    {/* <td>
                                                                        <pre>{JSON.stringify(e990, "", 2)}</pre>
                                                                    </td> */}
                                                                </tr>
                                                            )) :
                                                                <tr>
                                                                    <td colSpan={'100%'}>No messages</td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        }
                                        {/* <div>
                                            <Card style={{ borderRadius: '0', border: '1px solid #bbb' }}>
                                                <Card.Header style={{ backgroundColor: '#eee' }}>
                                                    <Row>
                                                        <Col>
                                                            <b>Fourkite Updates (x{loadtender?.fourkiteLocationUpdates.length})</b>
                                                        </Col>
                                                        <Col xs="auto">
                                                            <Button variant="primary" onClick={() => setShowAllLocationUpdates(!showAllLocationUpdates)}>
                                                                {showAllUpdates ? 'Hide' : 'Show'}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Card.Header>
                                                {showAllLocationUpdates &&
                                                    <Card.Body>
                                                        <Table responsive>
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Timestamp</th>
                                                                    <th>Location</th>
                                                                    <th>Latitude</th>
                                                                    <th>Longitude</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    loadtender?.fourkiteLocationUpdates && loadtender?.fourkiteLocationUpdates.map((fourkiteLocationUpdate, i) => (

                                                                        <tr key={i}>
                                                                            <td>{i + 1}</td>
                                                                            <td>{fourkiteLocationUpdate.locatedAt}</td>
                                                                            <td>{fourkiteLocationUpdate.city}, {fourkiteLocationUpdate.state}</td>
                                                                            <td>{fourkiteLocationUpdate.latitude}</td>
                                                                            <td>{fourkiteLocationUpdate.longitude}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </Card.Body>
                                                }
                                            </Card>
                                        </div> */}
                                        <Card style={{ borderRadius: '0', border: '1px solid #bbb' }}>
                                            <Card.Header style={{ backgroundColor: '#eee' }}>
                                                <Row>
                                                    <Col>
                                                        <b>GPS Updates (x{loadtender?.brokeredTracking ? loadtender?.brokeredTracking.length : 0})</b>
                                                    </Col>
                                                    <Col xs="auto">
                                                        <Button variant="primary" onClick={() => setShowBrokeredTracking(!showBrokeredTracking)}>
                                                            {showBrokeredTracking ? 'Hide' : 'Show'}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            {(showBrokeredTracking && loadtender?.brokeredTracking && loadtender?.brokeredTracking.length > 0) &&
                                                <Card.Body>
                                                    <GeofenceBrokerDisplay
                                                        geofenceState={geofenceState}
                                                        circleGeofenceState={circleGeofenceState}
                                                        brokeredTracking={loadtender?.brokeredTracking}
                                                        loadtenderPlot={loadtenderWaypoints}
                                                        gpsPlot={[]}
                                                    />
                                                    <Table responsive>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Timestamp</th>
                                                                <th>Carrier</th>
                                                                <th>Unit</th>
                                                                <th>Location</th>
                                                                <th>Position</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                loadtender.brokeredTracking ? loadtender.brokeredTracking.map((record, i) => (
                                                                    <tr key={i}>
                                                                        <td>{i + 1}</td>
                                                                        <td>
                                                                            {(record.Timestamp)}
                                                                        </td>
                                                                        <td>
                                                                            {(record.SCAC)}
                                                                        </td>
                                                                        <td>
                                                                            {record.UnitNumber}
                                                                        </td>
                                                                        <td>
                                                                            {record.City}, {record.State}
                                                                        </td>
                                                                        <td>
                                                                            {record.Latitude}, {record.Longitude}
                                                                        </td>
                                                                    </tr>
                                                                )) :
                                                                    <tr>
                                                                        <td colSpan={'100%'}>No messages</td>
                                                                    </tr>
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            }
                                        </Card>
                                    </Card>
                                </div>
                                <br />
                            </PrintPDF>
                        </>
                    :
                    <>
                        <Link to={backPath}>
                            <Button>
                                Back to Dashboard
                            </Button>
                        </Link>
                        <br />
                        <h3>
                            Shipment <i><b><u>{ShipmentId}</u></b></i> does not exist.
                        </h3>
                    </>
            }
            <br />
            {/* signatures */}

            <SignatureModal
                show={showRequestorSignatureModal}
                onHide={() => setShowRequestorSignatureModal(false)}
                onSave={(signatureDataURL) => handleSaveRequestorSignature(signatureDataURL, 'requestorSignature')}
                onClear={() => handleClearRequestorSignature('requestorSignature')}
                savedSignature={newDriverCon?.requestorSignature}
            />

            <SignatureModal
                show={showBillToSignatureModal}
                onHide={() => setShowBillToSignatureModal(false)}
                onSave={(signatureDataURL) => handleSaveBillToSignature(signatureDataURL, 'signatureBillTo')}
                onClear={() => handleClearBillToSignature('signatureBillTo')}
                savedSignature={newRateCon?.signatureBillTo}
            />

        </Container >
    )
}


export default LoadTenderUpdateV3;