
class Api {
  static async query(endpoint, params, idToken) {
    let urlParams;
    if (params instanceof URLSearchParams) {
      urlParams = params;
    } else {
      urlParams = new URLSearchParams();
      Object.keys(params).forEach(key => urlParams.append(key, params[key]));
    }
    const queryString = urlParams.toString();
    const url = `${process.env.REACT_APP_API_BASE_URL}${endpoint}${queryString ? `?${queryString}` : ''}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      },
    });
    if (!response.ok) {
      const errorData = await response.json();
      if (response.status === 401) {
        window.location.reload();
        throw new Error('API Error: Unauthorized');
      }
      throw new Error(`API Error: ${response.status} - ${errorData.message}`);
    }
    return await response.json();
  }

  static async get(endpoint, idToken) {
    try {
      const url = process.env.REACT_APP_API_BASE_URL + endpoint;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          window.location.reload();
          throw new Error('API Error: Unauthorized');
        }
        const error = await response.json();
        throw error;
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async getAllPages(endpoint, query, idToken, callback) {
    let page = 1;
    let totalPages = 1;

    while (page <= totalPages) {
      try {
        query.page = page; // Set the current page in the query object

        // Convert query object to query string
        const queryString = new URLSearchParams(query).toString();

        // Construct the full URL with the query string
        const url = `${process.env.REACT_APP_API_BASE_URL}${endpoint}?${queryString}`;

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
          },
        });

        const result = await response.json();

        // If this is the first page, set totalPages based on the result
        if (page === 1) {
          totalPages = result.totalPages;
        }

        // Process the current batch (you could also send it to a callback for processing)
        if (callback && typeof callback === 'function') {
          callback(result.data, page, totalPages); // Pass data to the callback for processing
        }

        // Increment to fetch the next page
        page++;
      } catch (error) {
        console.error(`Error fetching page ${page}:`, error);
        break; // Exit loop if there's an error
      }
    }
  }

  // use \n delimiter
  static async stream(endpoint, idToken, processChunk) {
    try {
      const url = process.env.REACT_APP_API_BASE_URL + endpoint;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          window.location.reload();
          throw new Error('API Error: Unauthorized');
        }
        const error = await response.json();
        throw error;
      }

      // Process the response as a stream
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let receivedText = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          console.log('Stream is done.');
          break; // End of stream
        }

        receivedText += decoder.decode(value, { stream: true });

        // Split by newlines to handle NDJSON
        const parts = receivedText.split('\n');

        for (let i = 0; i < parts.length - 1; i++) {
          const chunk = parts[i].trim();
          if (chunk) {
            try {
              const parsedChunk = JSON.parse(chunk);
              processChunk(parsedChunk);
            } catch (err) {
              console.error('Error parsing chunk:', err);
              throw err; // Ensure the error bubbles up to the caller
            }
          }
        }

        // Keep the last unfinished part for the next iteration
        receivedText = parts[parts.length - 1];
      }
    } catch (error) {
      console.error('Stream error:', error);
      throw error; // Ensure any error is propagated to the caller
    }
  }

  static async post(endpoint, body, idToken) {
    try {
      const url = process.env.REACT_APP_API_BASE_URL + endpoint;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify(body),
      });
      if (!response.ok) {
        if (response.status === 401) {
          window.location.reload();
          throw new Error('API Error: Unauthorized');
        }
        const error = await response.json();
        throw error;
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async patch(endpoint, json, idToken) {
    try {
      const url = process.env.REACT_APP_API_BASE_URL + endpoint;
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify(json),
      });
      if (!response.ok) {
        if (response.status === 401) {
          window.location.reload();
          throw new Error('API Error: Unauthorized');
        }
        const error = await response.json();
        throw error;
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async delete(endpoint, idToken) {
    try {
      const url = process.env.REACT_APP_API_BASE_URL + endpoint;
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          window.location.reload();
          throw new Error('API Error: Unauthorized');
        }
        const error = await response.json();
        throw error;
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async uploadFiles(endpoint, formdata, idToken) {
    try {
      const response = await fetch(process.env.REACT_APP_API_BASE_URL + endpoint, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        body: formdata,
      });

      if (!response.ok) {
        throw new Error('Upload response was not OK');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // console.error('Error uploading files:', error);
      return error;
    }
  }

  static async consumeFileStream(endpoint, idToken) {
    try {
      const response = await fetch(process.env.REACT_APP_API_BASE_URL + endpoint, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      });


      if (!response.ok) {
        throw new Error('Network response was not OK');
      }

      const contentType = response.headers.get('content-type');
      const contentDisposition = response.headers.get('content-disposition');

      // Create a Blob from the response data
      const blob = await response.blob();
      return { blob, contentType, contentDisposition };
    } catch (error) {
      console.error('Error fetching and consuming file stream:', error);
      throw error;
    }
  }
}

export default Api;
