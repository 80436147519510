import React, { useState, useEffect } from 'react'
import { Container, Card, Button, Row, Col, Table, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useRole } from "../../context/role";
import { Link } from 'react-router-dom';
import { FaCarCrash, FaUpload, } from 'react-icons/fa';
import { MdOutlinePersonalInjury, } from 'react-icons/md';
import { GiPoliceBadge } from 'react-icons/gi';


function SafetyPage() {

    const { userRole, checkNavPermissions } = useRole();

    const [toggle, setToggle] = useState(false);
    useEffect(() => {
        const savedToggle = localStorage.getItem('@tms-featurepage-toggle');
        if (savedToggle !== null) {
            setToggle(JSON.parse(savedToggle));
        }
    }, []);
    useEffect(() => {
        localStorage.setItem('@tms-featurepage-toggle', JSON.stringify(toggle));
    }, [toggle]);


    const features = [
        { href: "/safety/dashboard", icon: <GiPoliceBadge />, text: "Safety", detail: 'Accidents & Injuries', },
        { href: '/safety/accidents/list-of-forms', icon: <FaCarCrash />, text: 'Accident List', detail: 'Reported accidents', },
        { href: '/safety/accidents/data-output', icon: <FaCarCrash />, text: 'Accident Graphs', detail: 'Accident data graphed', },
        { href: '/safety/accidents/new-form', icon: <FaCarCrash />, text: 'New Accident', detail: 'Report an accident', },
        { href: '/safety/accidents/upload', icon: <FaUpload />, text: 'Upload Accidents', detail: 'Upload accident data from a template' },
        { href: '/safety/injuries/list-of-forms', icon: <MdOutlinePersonalInjury />, text: 'Injury List', detail: 'Reported injuries', },
        { href: '/safety/injuries/data-output', icon: <MdOutlinePersonalInjury />, text: 'Injury Graphs', detail: 'Injury data graphed', },
        { href: '/safety/injuries/new-form', icon: <MdOutlinePersonalInjury />, text: 'New Injury', detail: 'Report and injury', },
        { href: '/safety/injuries/upload', icon: <FaUpload />, text: 'Upload Injuries', detail: 'Upload injuries from a template', },
    ]
    const allowedFeatures = checkNavPermissions(userRole, features)

    const cardStyle = {
        textAlign: 'center',
        width: '250px',
        height: '250px'
    }

    const colStyle = {
        margin: '0.25em',
    }

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <Card style={{ border: 0, borderRadius: 0 }}>
                <Card.Header>
                    <Row>
                        <Col>
                            <Card.Title>Safety</Card.Title>
                        </Col>
                        <Col xs="auto">
                            <Button variant='primary' onClick={() => setToggle(!toggle)}>Toggle {!toggle ? 'Card' : 'Table'}</Button>
                        </Col>
                    </Row>
                </Card.Header>
                {toggle ?
                    <Card.Body>
                        <Row style={{ justifyContent: 'space-evenly' }}>
                            {allowedFeatures.map((feature, i) => (
                                <Col xs="auto" style={colStyle}>
                                    <Card style={cardStyle}>
                                        <Card.Header>
                                            <b>{feature.text}</b>
                                        </Card.Header>
                                        <Card.Body key={i}>
                                            <Row>
                                                <Col style={{ marginBottom: '1em' }}>
                                                    <h1>{feature.icon}</h1>
                                                </Col>
                                            </Row>
                                            <Card.Text>
                                                {feature.detail}
                                            </Card.Text>
                                            <Link to={feature.href}>Go to {feature.text}</Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Card.Body>
                    :
                    <Card.Body>
                        <Table responsive>
                            <tbody>
                                {allowedFeatures.map((feature, i) => (
                                    <tr>
                                        <td>
                                            <h3>{feature.icon}</h3>
                                        </td>
                                        <td> <Link to={feature.href}>{feature.text}</Link></td>
                                        <td>{feature.text}</td>
                                        <td>{feature.detail}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                }
            </Card>
        </Container>
    )
}

export default SafetyPage;

