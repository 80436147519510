import { Button, Modal, } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';
import Signature from 'signature_pad';

const SignatureModal = ({ show, onHide, onSave, onClear, savedSignature }) => {
    const [signaturePad, setSignaturePad] = useState();
    const canvasRef = useRef(null)

    useEffect(() => {
        if (show || savedSignature) {
            initializeSignaturePad(savedSignature);
        }
    }, [show, savedSignature]);

    const initializeSignaturePad = (savedSignature) => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const rect = canvas.getBoundingClientRect();
        canvas.width = rect.width;
        canvas.height = rect.height;
        context.scale(1, 1);
        const tempSignaturePad = new Signature(canvas);
        if (savedSignature) {
            tempSignaturePad.fromDataURL(savedSignature);
        }
        setSignaturePad(tempSignaturePad);
    };

    const handleModalShow = () => {
        if (!savedSignature) {
            initializeSignaturePad();
        }
    };

    const handleModalHide = () => {
        if (signaturePad) {
            signaturePad.off();
        }
    };

    // const readyPad = () => {
    //     let wrapper = document.getElementById('signature-pad');
    //     let canvas = wrapper?.querySelector('canvas')
    //     canvas.getContext('2d').scale(1, 1);
    //     let tempSignaturePad = new Signature(canvas);
    //     setSignaturePad(tempSignaturePad);
    // }

    const handleClear = () => {
        if (signaturePad) {
            signaturePad.clear();
            onClear();
        }
    };

    const handleSave = () => {
        let signatureDataURL = '';
        if (signaturePad && !signaturePad.isEmpty()) {
            signatureDataURL = signaturePad.toDataURL();
        }
        onSave(signatureDataURL);
        onHide();
    };

    // useEffect(() => {
    //     // readyPad();
    //     // if (canvasRef.current) {
    //     //     const canvas = canvasRef.current;
    //     //     canvas.getContext('2d').scale(1,1);
    //     //     const tempSignaturePad = new Signature(canvas);
    //     //     setSignaturePad(tempSignaturePad);
    //     // }
    // }, [])



    return (
        <Modal show={show} onShow={handleModalShow} onExited={handleModalHide} size="xl">
            <Modal.Header onHide={onHide} closeButton>
                <Modal.Title>Sign Here</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div id='signature-pad'>
                    <canvas
                        className='signature-canvas'
                        style={{ width: '100%', height: '200px', border: '1px solid black' }}
                        ref={canvasRef}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClear}>Clear</Button>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SignatureModal;