import { Container, Card, Spinner, Col, Row, Badge, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import React, { useState, useEffect } from 'react'
import { FaPlusCircle } from 'react-icons/fa'
import { useAuth } from '../../context/auth';
import DataDisplay from "../../components/DataDisplay";
import { Link } from "react-router-dom";
import AlertDisplay from "../../components/AlertDisplay";
import DataDisplayV3 from "../../components/DataDisplayV3";

function SpecialOp({feature}) {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [specialops, setSpecialOps] = useState();
    const [alerts, setAlerts] = useState([])
    const alertState = { alerts, setAlerts }
    const [query, setQuery] = useState({
        isActive: true
    });
    useEffect(() => {
        const fetchData = async () => {
            try {
                const queryParams = new URLSearchParams(query);
                const data = await Api.query('/specialops', queryParams, idToken);
                if (!data || !Array.isArray(data)) {
                    throw new Error("Received invalid data from server.");
                }
                const cleanSpecialOps = data.map(specialop => {
                    const optype = (specialop.type === 'md')
                        ? `${specialop.month} ${specialop.day}`
                        : `${specialop.month}'s ${specialop.week} ${specialop.dow}`;

                    return {
                        _id: specialop._id,
                        'Name': specialop.name,
                        'Operation': optype,
                        'Active': specialop.isActive ? '✅' : '❌',
                    }
                })
                setSpecialOps(cleanSpecialOps)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    },[set,idToken,alerts,query])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{ borderRadius: 0, border:0 }}>
                <Card.Header>
                <Card.Title>
                        <Row>
                            <Col>Special Operations</Col>
                            <Col style={{textAlign:'center'}}>
                                <Badge
                                    bg={query.isActive ? 'success' : 'danger'}
                                    onClick={() => { setQuery({ ...query, isActive: !query.isActive }); didSet(false) }}
                                >
                                    {query.isActive ? 'Active' : 'Inactive'}
                                </Badge>
                            </Col>
                            <Col>
                            <Link className="float-end" to={`/${feature}/specialop-form`} ><FaPlusCircle /> Special Operation</Link>
                            </Col>
                        </Row>
                    </Card.Title>
                </Card.Header>
               
                {set ?
                    <Card.Body>
                        {specialops.length > 0 ?
                            <>
                                <DataDisplayV3
                                    dataSource={specialops}
                                    lsKey={'@mctms-specialops-display'}
                                    urlPath={`/${feature}/specialop/`}
                                    urlKey={'_id'}
                                    popKeys={['_id']}
                                />
                            </>
                            :
                            <>
                                No data.
                            </>
                        }
                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

            </Card>
        </Container>
    );
}

export default SpecialOp;