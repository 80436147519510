import { Container, Card, Spinner, Row, Col, Badge, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import React, { useState, useEffect } from 'react'
import { FaPlusCircle, } from 'react-icons/fa'
import { useAuth } from '../../context/auth';
import DataDisplay from "../../components/DataDisplay";
import { Link } from "react-router-dom";
import AlertDisplay from "../../components/AlertDisplay";
import DataDisplayV3 from "../../components/DataDisplayV3";
function Vehicle({feature}) {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [vehicles, setVehicles] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = {alerts, setAlerts}
    const [query, setQuery] = useState({
        isActive: true
    });
    useEffect(() => {
        const fetchData = async () => {
            try {
                const queryParams = new URLSearchParams(query);

                const data = await Api.query('/vehicles', queryParams, idToken);
                if (!data || !Array.isArray(data)) {
                    throw new Error("Received invalid data from server.");
                }
                const cleanVehicles = data.map(vehcile => {
                    return {
                        _id: vehcile._id,
                        'Unit': vehcile?.unitnumber,
                        'Category': vehcile?.category,
                        'Year': vehcile?.year,
                        'Make': vehcile?.make,
                        'Model': vehcile?.model,
                        'Assignment': vehcile?.assignment?.name,
                        'Domicile': vehcile?.domicile?.name,
                        'Provider':  vehcile?.provider,
                        'Active': vehcile?.isActive ? '✅' : '❌',
                    }
                })

                setVehicles(cleanVehicles)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }])
            }
            didSet(true)
        }

        fetchData()

    }, [set, idToken,alerts,query])

    return (
        <Container fluid style={{margin:0,padding:0}}>
            <Card style={{ borderRadius: 0, border:0 }}>
                <Card.Header>
                <Card.Title>
                        <Row>
                            <Col>Vehicle</Col>
                            <Col style={{ textAlign: 'center' }}>
                                <Badge
                                    bg={query.isActive ? 'success' : 'danger'}
                                    onClick={() => { setQuery({ ...query, isActive: !query.isActive }); didSet(false) }}
                                >
                                    {query.isActive ? 'Active' : 'Inactive'}
                                </Badge>
                            </Col>
                            <Col>
                                <Link className="float-end" to={`/${feature}/vehicle-form`}><FaPlusCircle /> Vehicle</Link>
                            </Col>
                        </Row>
                    </Card.Title>
                </Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                {set ?
                    <Card.Body>
                        {vehicles.length > 0 ?
                            <>
                                <DataDisplayV3
                                    dataSource={vehicles}
                                    lsKey={'@mctms-vehicles-display'}
                                    urlPath={`/${feature}/vehicle/`}
                                    urlKey={'_id'}
                                    popKeys={['_id']}
                                />
                            </>
                            :
                            <>
                                No data.
                            </>
                        }
                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

            </Card>
        </Container>
    );
}

export default Vehicle;