import { useState, useEffect } from "react";
import { Button, Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import Api from "../utils/Api";
import { useAuth } from "../context/auth";
import AlertDisplay from "../components/AlertDisplay";
import LogisticsLoading from "../components/LogisticsLoading";

function Tenant() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [tenant, setTenant] = useState({
        customer_scac: '',
        customer_dot: '',
        customer_mc: '',
        customer_coname: '',
        customer_firstname: '',
        customer_lastname: '',
        customer_phone: '',
        customer_email: '',
        customer_address1: '',
        customer_address2: '',
        customer_city: '',
        customer_state: '',
        customer_zip: '',
        customer_country: '',
    })
    const [editting, setEditting] = useState()
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const fetchTenant = async () => {
        try {
            const doc = await Api.get('/tenant', idToken)
            if (doc) {
                setTenant(doc)
            }
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured getting Tenant', }])
        } finally {
            didSet(true)
        }
    }

    const postTenant = async () => {
        try {
            await Api.post('/tenant', { ...tenant }, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Tenant upserted', }])
            didSet(false)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error posting Tenant', }])
        }
    }

    useEffect(() => {
        if (!set) {
            fetchTenant();
        }
    }, [set])

    return (
        <Container>
            <AlertDisplay alertState={alertState} />
            <br />
            {!set ?

                <LogisticsLoading message={'Loading Tenant Profile'} />

                :
                <Card>
                    <Card.Header>
                        <Card.Title>
                            SYBR TMS Tenant
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Table>
                            <tbody>
                                <tr>
                                    <th>Company Name</th>
                                    <td>
                                        {editting ?
                                            <Form.Control placeholder="Company name..." value={tenant.customer_coname} onChange={e => setTenant({ ...tenant, customer_coname: e.target.value })} />
                                            :
                                            tenant.customer_coname
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>Company Address</th>
                                    <td>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    {editting ?
                                                        <Form.Control placeholder="Address 1..." value={tenant.customer_address1} onChange={e => setTenant({ ...tenant, customer_address1: e.target.value })} />
                                                        :
                                                        tenant.customer_address1
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    {editting ?
                                                        <Form.Control placeholder="Address 2..." value={tenant.customer_address2} onChange={e => setTenant({ ...tenant, customer_address2: e.target.value })} />
                                                        :
                                                        tenant.customer_address2
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12" xl="3">
                                                    {editting ?
                                                        <Form.Control placeholder="City..." value={tenant.customer_city} onChange={e => setTenant({ ...tenant, customer_city: e.target.value })} />
                                                        :
                                                        tenant.customer_city
                                                    }
                                                </Col>
                                                <Col xs="12" xl="3">
                                                    {editting ?
                                                        <Form.Control placeholder="State..." value={tenant.customer_state} onChange={e => setTenant({ ...tenant, customer_state: e.target.value })} />
                                                        :
                                                        tenant.customer_state
                                                    }
                                                </Col>
                                                <Col xs="12" xl="3">
                                                    {editting ?
                                                        <Form.Control placeholder="Zip..." value={tenant.customer_zip} onChange={e => setTenant({ ...tenant, customer_zip: e.target.value })} />
                                                        :
                                                        tenant.customer_zip
                                                    }
                                                </Col>
                                                <Col xs="12" xl="3">
                                                    {editting ?
                                                        <Form.Control placeholder="Country..." value={tenant.customer_country} onChange={e => setTenant({ ...tenant, customer_country: e.target.value })} />
                                                        :
                                                        tenant.customer_country
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Primary Contact</th>
                                    <td>
                                        <Row>
                                            <Col xs="12" md="6">
                                                {editting ?
                                                    <Form.Control placeholder="First name..." value={tenant.customer_firstname} onChange={e => setTenant({ ...tenant, customer_firstname: e.target.value })} />
                                                    :
                                                    tenant.customer_firstname
                                                }
                                            </Col>
                                            <Col xs="12" md="6">
                                                {editting ?
                                                    <Form.Control placeholder="Last name..." value={tenant.customer_lastname} onChange={e => setTenant({ ...tenant, customer_lastname: e.target.value })} />
                                                    :
                                                    tenant.customer_lastname
                                                }
                                            </Col>
                                        </Row>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Email</th>
                                    <td>
                                        {editting ?
                                            <Form.Control placeholder="Email..." value={tenant.customer_email} onChange={e => setTenant({ ...tenant, customer_email: e.target.value })} />
                                            :
                                            tenant.customer_email
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>Phone</th>
                                    <td>
                                        {editting ?
                                            <Form.Control placeholder="Phone..." value={tenant.customer_phone} onChange={e => setTenant({ ...tenant, customer_phone: e.target.value })} />
                                            :
                                            tenant.customer_phone
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>SCAC</th>
                                    <td>
                                        {editting ?
                                            <Form.Control placeholder="SCAC..." value={tenant.customer_scac} onChange={e => setTenant({ ...tenant, customer_scac: e.target.value })} />
                                            :
                                            tenant.customer_scac
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>DOT</th>
                                    <td>
                                        {editting ?
                                            <Form.Control placeholder="DOT..." value={tenant.customer_dot} onChange={e => setTenant({ ...tenant, customer_dot: e.target.value })} />
                                            :
                                            tenant.customer_dot
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>MC</th>
                                    <td>
                                        {editting ?
                                            <Form.Control placeholder="MC..." value={tenant.customer_mc} onChange={e => setTenant({ ...tenant, customer_mc: e.target.value })} />
                                            :
                                            tenant.customer_mc
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card.Body>
                    <Card.Footer>
                        <Row>
                            <Col xs="auto">
                                {editting ?
                                    <Button variant="warning" onClick={() => setEditting()}>
                                        Read
                                    </Button>
                                    :
                                    <Button variant="warning" onClick={() => setEditting(true)}>
                                        Edit
                                    </Button>
                                }
                            </Col>
                            <Col></Col>
                            <Col xs="auto">
                                {editting &&
                                    <Button variant="primary" onClick={() => postTenant()}>
                                        Save
                                    </Button>
                                }
                            </Col>
                        </Row>


                    </Card.Footer>
                </Card>
            }

        </Container>
    )
}

export default Tenant;