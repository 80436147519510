import React from 'react';
import './TrafficSignal.css';

const TrafficSignal = ({ status }) => {
  return (
    <div className="traffic-signal">
      <div className={`light ${status === "true" ? 'green' : 'red'}`}>{status === "true" ? "ON" : "OFF"}</div>
    </div>
  );
};

export default TrafficSignal;
